import { APIProvider, Map } from '@vis.gl/react-google-maps';
import { appConfig } from 'config/app';
import { DashboardStatsOverview } from 'containers/dashboard/tabs/stats/DashboardStatsOverview';
import { DashboardTabProps } from 'containers/dashboard/tabs/types';
import queryString from 'query-string';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { ZipcodesOverlay } from './ZipcodesOverlay';

export function DashboardMapsTab(props: DashboardTabProps): React.ReactElement {
  const { data, isCurrentlyOnsiteVisible, loading } = props;
  const { zipcodeBreakdowns } = data || {};

  const location = useLocation();
  const params = queryString.parse(location.search);
  const { mapId } = params;
  const styledDataMapId = mapId?.toString() ?? 'acfdda107f82f635';

  // const { updateUrl } = usePageQueryParams();

  return (
    <div className="odin-gap-y-9">
      <div className="odin-flex odin-flex-col odin-gap-y-9">
        <DashboardStatsOverview
          workerReportsData={data}
          isCurrentlyOnsiteVisible={isCurrentlyOnsiteVisible}
          loading={loading}
        />
      </div>
      <div>
        <APIProvider apiKey={appConfig.googleMapsApiKey}>
          <Map
            style={{ width: '80vw', height: '60vh' }}
            defaultCenter={{ lat: 40.76, lng: -101.64 }} // US center
            mapId={styledDataMapId}
            defaultZoom={5}
            gestureHandling="greedy"
            disableDefaultUI
            zoomControl
            reuseMaps
          />
          <ZipcodesOverlay zipCodeBreakdowns={zipcodeBreakdowns} />
        </APIProvider>
      </div>
      {/* <div id="mapLegend" className="alert alert-info"> */}
      {/*  <h3>Legend</h3> */}
      {/* </div> */}
    </div>
  );
}
