import React from 'react';
import cn from 'classnames';
import { Alert, RadioGroupOption, SelectOptionElement } from '@odin-labs/components';
import { useGetJobsiteContractorsQuery } from 'apollo/generated/client-operations';
import { WrenchIcon } from 'components/icons';
import { FormInputTypes, GridColSpan, UseInputs, TypedFormInputs, UseFormMethods } from 'components/form';
import { getContractorsOptions } from 'containers/contractor/helpers';
import { ChangeContractorFormData, ChangeType } from './types';

export type ContractorChangeTypeOption = RadioGroupOption<ChangeType>;

export const contractorChangeTypeOptions: ContractorChangeTypeOption[] = [
  {
    value: ChangeType.FromBeginning,
    label: 'From the beginning of this assignment (e.g., the wrong contractor was entered)',
  },
  {
    value: ChangeType.Today,
    label: 'Today (e.g. working for a new contractor)',
  },
];

const TODAY_OPTION_ALERT =
  'This will create a new jobsite assignment: if you want to correct the existing assignment ' +
  'select the option above "From the beginning of this assignment".';

const getFormInputs = ({
  contractorOptions,
  selectedChangeTypeValue,
}: {
  contractorOptions: SelectOptionElement[];
  selectedChangeTypeValue: ChangeType;
}): TypedFormInputs<ChangeContractorFormData> => ({
  newContractorId: {
    element: FormInputTypes.Select,
    label: 'What kind of change would you like to make?',
    elementProps: {
      options: contractorOptions,
      icon: WrenchIcon,
      disabled: (contractorOptions?.length ?? 0) < 2,
    },
    validation: { required: true },
    layout: [GridColSpan.SpanFull],
  },
  changeType: {
    element: FormInputTypes.RadioGroup,
    label: 'When should this change take effect?',
    elementProps: {
      options: contractorChangeTypeOptions,
      // preventSubmitOnEnter: true,
    },
    layout: GridColSpan.SpanFull,
  },
  alert: {
    element: FormInputTypes.CustomContent,
    elementProps: {
      content: <Alert type="danger" text={TODAY_OPTION_ALERT} />,
    },
    layout: selectedChangeTypeValue === ChangeType.Today ? GridColSpan.SpanFull : cn('odin-hidden'),
  },
});

export const useContractorsOptions = ({
  jobsiteId,
  contractorId,
  skip,
}: {
  jobsiteId: string;
  contractorId?: string;
  skip?: boolean;
}): SelectOptionElement[] => {
  const { data: jobsiteContractors, loading } = useGetJobsiteContractorsQuery({
    fetchPolicy: 'no-cache',
    skip: !jobsiteId || skip,
    variables: { jobsiteId },
  });

  return React.useMemo(() => {
    if (loading) return [];

    const contractors = jobsiteContractors?.getJobsite.jobsiteContractors.edges
      .map(({ node }) => node.contractor)
      .filter((c) => c.contractorId !== contractorId);

    return getContractorsOptions(contractors);
  }, [jobsiteContractors, contractorId]);
};

export const getFormInputsHook =
  (args: { contractorOptions: SelectOptionElement[] }): UseInputs<ChangeContractorFormData> =>
  ({ watch }: UseFormMethods<ChangeContractorFormData>): TypedFormInputs<ChangeContractorFormData> => {
    const { contractorOptions } = args;
    const selectedChangeTypeOption = watch('changeType');
    const selectedChangeTypeValue = selectedChangeTypeOption?.value;

    return React.useMemo(() => {
      return getFormInputs({ contractorOptions, selectedChangeTypeValue });
    }, [contractorOptions, selectedChangeTypeValue]);
  };

export const getDefaultValues = (args: { contractorOptions: SelectOptionElement[] }): ChangeContractorFormData => {
  const { contractorOptions } = args;

  return {
    newContractorId: contractorOptions?.length === 1 ? contractorOptions[0] : null,
    changeType: { value: ChangeType.FromBeginning, label: null },
    alert: undefined,
  };
};
