import React from 'react';
import cn from 'classnames';
import { Avatar, getFaIcon } from '@odin-labs/components';
import { MapMarkerAltIcon, faWrench } from 'components/icons';
import { ContractorWithSubcontractors } from 'containers/contractor/types';

const WrenchIcon = getFaIcon({ icon: faWrench, className: cn('odin-text-odin-primary') });

const classes = {
  titleSkeleton: cn(
    'odin-text-transparent odin-text-2xl',
    'odin-animate-pulse odin-bg-gray-300 odin-rounded-md -odin-ml-1',
  ),
};

export function ContractorTitle({ contractor }: { contractor: ContractorWithSubcontractors }): React.ReactElement {
  const { addressCity, addressState, name } = contractor?.organization ?? {};
  const locationString = [addressCity, addressState].filter(Boolean).join(', ');

  return (
    <div className="odin-flex odin-gap-x-8">
      <Avatar icon={WrenchIcon} type="rounded" objectFit="cover" size="2xl" />
      <div className="odin-flex odin-flex-col odin-justify-around">
        <h1 className="odin-text-xl odin-font-bold odin-text-odin-black sm:odin-text-2xl sm:odin-truncate">
          {name ?? <span className={classes.titleSkeleton}>Loading Contractor Data</span>}
        </h1>
        {locationString && (
          <span className="odin-text-sm">
            <MapMarkerAltIcon className="odin-text-base odin-text-odin-primary odin-mr-2" />
            {locationString}
          </span>
        )}
      </div>
    </div>
  );
}
