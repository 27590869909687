import { isStringEnumValue } from 'utils';
import { SelfOnboardingStepKey, LanguageOption } from './types';

export const languageOptions: LanguageOption[] = [
  { label: 'English', value: 'en' },
  { label: 'Spanish', value: 'es' },
  { label: 'Chinese (Simplified)', value: 'zhCN' },
  { label: 'Chinese (Traditional)', value: 'zhTW' },
  { label: 'Hungarian', value: 'hu' },
  { label: 'Polish', value: 'pl' },
  { label: 'Russian', value: 'ru' },
];

export const defaultStep = SelfOnboardingStepKey.LanguageSelection;
export const defaultLanguage = languageOptions[0];

export const resolveSelfOnboardingStep = (step: string): SelfOnboardingStepKey => {
  return isStringEnumValue(SelfOnboardingStepKey, step) ? step : defaultStep;
};

export const getBaseRoute = (jobsiteInvitationId: string): string => `/self-onboarding/${jobsiteInvitationId}`;

export const generateSelfOnboardingInvitationLink = (jobsiteInvitationId: string): string => {
  if (!jobsiteInvitationId) return null;
  const { protocol, hostname, port } = window.location;
  return `${protocol}//${hostname}${port ? `:${port}` : ''}/self-onboarding/${jobsiteInvitationId}`;
};
