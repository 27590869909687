import React from 'react';
import { getPrettyFormattedDate } from 'utils/dates';
import { InfoRowProps } from 'components/infoRow/types';
import { useWorkerOnboardingLink, WorkerOnboardingLink } from 'components/workerOnboardingLink';
import { JobsiteInfo } from './types';

export type JobsiteInfoRowConfig = Omit<InfoRowProps, 'onEditClick' | 'allowEdit'>;

export type UseInfoRowsArgs = {
  jobsite: JobsiteInfo;
  loading: boolean;
};

export function useInfoRows({ jobsite, loading }: UseInfoRowsArgs): JobsiteInfoRowConfig[] {
  const {
    name: jobsiteName,
    objectHistory,
    addressLine1,
    addressLine2,
    addressCity,
    addressState,
    addressZipCode,
    defaultJobsiteContractor,
  } = jobsite ?? {};

  const {
    id: defaultJobsiteContractorId,
    contractor: defaultContractor,
    jobsiteInvitation: defaultInvitation,
  } = defaultJobsiteContractor ?? {};
  const defaultJobsiteInvitationId = defaultInvitation?.jobsiteInvitationId;
  const defaultContractorName = defaultContractor?.organization?.name;

  const jobsiteAddress = jobsite && (
    <>
      {addressLine1} {addressLine2} <br />
      {[addressCity, addressState].filter(Boolean).join(', ')} {addressZipCode}
    </>
  );

  const { onboardingLink, canGenerateOnboardingLink, generateOnboardingLink, isGeneratingOnboardingLink } =
    useWorkerOnboardingLink({
      jobsiteInvitationId: defaultJobsiteInvitationId,
      jobsiteContractorId: defaultJobsiteContractorId,
    });

  return [
    { label: 'Display Name', value: jobsite?.name },
    { label: 'Client Name', value: jobsite?.developer?.organization?.name },
    { label: 'Address', value: jobsiteAddress },
    { label: 'Created On', value: getPrettyFormattedDate(objectHistory?.createdAt), withBottomBorder: true },
    {
      loading: loading || isGeneratingOnboardingLink,
      label: 'Default Onboarding Link',
      value: (
        <WorkerOnboardingLink
          onboardingLink={onboardingLink}
          canGenerateOnboardingLink={canGenerateOnboardingLink}
          generateOnboardingLink={generateOnboardingLink}
          isGeneratingOnboardingLink={isGeneratingOnboardingLink}
          contractorName={defaultContractorName}
          jobsiteName={jobsiteName}
        />
      ),
    },
  ];
}
