import React from 'react';
import moment, { Moment } from 'moment';
import { SelectOptionElement, useDidUpdateEffect } from '@odin-labs/components';
import { useGetJobsiteContractorsQuery } from 'apollo/generated/client-operations';

import { JobsiteContractor } from 'containers/contractor/types';

import { FormInputTypes, GridColSpan, UseInputs, TypedFormInputs, UseFormMethods } from 'components/form';
import { JobsiteContractorOptionElement, getJobsiteContractorsOptions } from 'containers/contractor/helpers';
import { BriefcaseIcon, WrenchIcon } from 'components/icons';

export type AddWorkerFormData = {
  jobsiteId: SelectOptionElement;
  contractorId: JobsiteContractorOptionElement;
  orientationDate: Moment;
};

export type AddWorkerInputsArgs = {
  jobsiteContractor: JobsiteContractor;
  contractorId: string;
  contractorsOptions: SelectOptionElement[];
  jobsitesOptions: SelectOptionElement[];
};

const getFormInputs = ({
  contractorsOptions,
  jobsitesOptions,
  minOrientationDate,
}: {
  contractorsOptions: SelectOptionElement[];
  jobsitesOptions: SelectOptionElement[];
  minOrientationDate: Moment;
}): TypedFormInputs<AddWorkerFormData> => ({
  jobsiteId: {
    element: FormInputTypes.Select,
    label: 'Jobsite',
    elementProps: {
      placeholder: 'Select jobsite',
      options: jobsitesOptions,
      icon: BriefcaseIcon,
    },
    validation: {
      required: true,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
  },
  contractorId: {
    element: FormInputTypes.Select,
    label: 'Contractor',
    elementProps: {
      placeholder: 'Select contractor',
      options: contractorsOptions,
      icon: WrenchIcon,
      disabled: (contractorsOptions?.length ?? 0) < 1,
    },
    validation: {
      required: true,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
  },
  orientationDate: {
    element: FormInputTypes.DatePicker,
    label: 'Orientation Date',
    elementProps: {
      showDefaultIcon: true,
      numberOfMonths: 1,
      minDate: minOrientationDate,
      disabled: !minOrientationDate,
    },
    validation: {
      required: true,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
  },
});

export type JobsiteContractorForOption = {
  contractor: { contractorId: string; organization: { name: string } };
  startDate: Date;
};

export const useContractorsOptions = (args: {
  jobsiteId: string;
  skip?: boolean;
}): JobsiteContractorOptionElement[] => {
  const { jobsiteId, skip } = args;
  const { data: jobsiteContractors, loading } = useGetJobsiteContractorsQuery({
    fetchPolicy: 'no-cache',
    skip: !jobsiteId || skip,
    variables: { jobsiteId },
  });

  return React.useMemo(() => {
    if (loading) return [];
    const selectedJobsiteContractors = jobsiteContractors?.getJobsite.jobsiteContractors.edges.map(({ node }) => node);
    return getJobsiteContractorsOptions(selectedJobsiteContractors);
  }, [jobsiteContractors]);
};

export const getFormInputsHook =
  (args: { jobsitesOptions: SelectOptionElement[] }): UseInputs<AddWorkerFormData> =>
  ({ watch, setValue }: UseFormMethods<AddWorkerFormData>): TypedFormInputs<AddWorkerFormData> => {
    const { jobsitesOptions } = args;
    const selectedJobsiteOption = watch('jobsiteId');
    const selectedJobsiteId = selectedJobsiteOption?.value;

    const selectedContractorOption = watch('contractorId');
    const { startDate } = selectedContractorOption ?? {};

    const contractorsOptions = useContractorsOptions({ jobsiteId: selectedJobsiteId });

    useDidUpdateEffect(() => {
      setValue('contractorId', contractorsOptions?.length === 1 ? contractorsOptions[0] : null);
      setValue('orientationDate', moment().startOf('day'));
    }, [contractorsOptions]);

    return React.useMemo(
      () => getFormInputs({ jobsitesOptions, contractorsOptions, minOrientationDate: startDate && moment(startDate) }),
      [contractorsOptions, jobsitesOptions, startDate],
    );
  };

export const getDefaultValues = (args: {
  jobsiteId: string;
  jobsitesOptions: SelectOptionElement[];
  contractorsOptions: JobsiteContractorOptionElement[];
}): AddWorkerFormData => {
  const { jobsiteId, jobsitesOptions, contractorsOptions } = args;

  return {
    jobsiteId: jobsitesOptions?.find((option) => option.value === jobsiteId) ?? null,
    contractorId: contractorsOptions?.length === 1 ? contractorsOptions[0] : null,
    orientationDate: moment().startOf('day'),
  };
};
