import { ColorsDescriptor } from 'chartjs-plugin-autocolors';
import { Moment } from 'moment';

import { GetJobsiteWorkerDashboardQuery } from 'apollo/generated/client-operations';

import { PageSearchParams } from 'utils';

export type WorkerDashboardData = GetJobsiteWorkerDashboardQuery['getJobsiteWorkerDashboard'];
export type WorkerSession = WorkerDashboardData['results'][number];

export type DashboardFilters = Pick<
  Required<PageSearchParams>,
  'developerIds' | 'jobsiteIds' | 'contractor' | 'trade' | 'tradeClass' | 'compliant' | 'overnight'
> &
  Pick<PageSearchParams, 'currentlyOnsite'> & {
    dateRange: {
      startDate: Moment;
      endDate: Moment;
    };
    today: never;
  };

// these are the current Odin defined chart colors
export const odinChartColors: ColorsDescriptor[] = [
  { background: '#5F1F8A', border: '#F0F0FF' },
  { background: '#F55F3E', border: '#FFF3ED' },
  { background: '#3E5BFE', border: '#E3F0FF' },
  { background: '#F5C23E', border: '#FFFBED' },
  { background: '#00C673', border: '#EBFAF4' },
  { background: '#E63757', border: '#FFEEEE' },
  { background: '#27C4DC', border: '#E4F6F9' },
  { background: '#BF339A', border: '#FEF0FA' },
  { background: '#924F26', border: '#F4E9E2' },
  { background: '#5F5F5F', border: '#DFDFDF' },
];
