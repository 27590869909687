import React from 'react';
import { DeepMap } from 'react-hook-form';
import { SelectOptionElement, useDidUpdateEffect } from '@odin-labs/components';
import { HidProxyInstance, JobsiteUpdateInput } from 'apollo/generated/client-operations';
import { PrintIcon } from 'components/icons';
import {
  FormInputTypes,
  getUpdateInputValueFunction,
  GridColSpan,
  TypedFormInputs,
  UseFormMethods,
  UseInputs,
} from 'components/form';
import {
  BadgeTemplateOptionElement,
  EditJobsiteConfigurationFormData,
  HidFargoSelectOptionElement,
  Jobsite,
} from 'containers/jobsiteConfiguration/types';
import { getSelectOptionsFromStringEnum } from 'utils';

export type HidFargoConnectSectionInputsArgs = {
  organizationOptions: HidFargoSelectOptionElement[];
  locationOptions: HidFargoSelectOptionElement[];
  printerOptions: HidFargoSelectOptionElement[];
  proxyInstanceOptions: SelectOptionElement[];
  selectedProxyInstance?: SelectOptionElement;
  badgeTemplateOptions: BadgeTemplateOptionElement[];
  defaultBadgeTemplateOptions: BadgeTemplateOptionElement[];
};

export type HidFargoConnectSectionInputsHookArgs = Omit<
  HidFargoConnectSectionInputsArgs,
  'defaultBadgeTemplateOptions'
>;

export const hidProxyInstanceSelectOptions = getSelectOptionsFromStringEnum(HidProxyInstance);

export const getHidFargoConnectSectionInputs = ({
  proxyInstanceOptions,
  organizationOptions,
  locationOptions,
  printerOptions,
  selectedProxyInstance,
  badgeTemplateOptions,
  defaultBadgeTemplateOptions,
}: HidFargoConnectSectionInputsArgs): TypedFormInputs<EditJobsiteConfigurationFormData['hidFargoConnect']> => ({
  proxyInstance: {
    element: FormInputTypes.Select,
    label: 'Proxy Instance',
    elementProps: {
      options: proxyInstanceOptions,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
  },
  printerId: {
    element: FormInputTypes.Select,
    label: 'Printer',
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
    elementProps: {
      isMulti: false,
      clearToNull: true,
      disabled: !selectedProxyInstance,
      options: printerOptions ?? [],
      icon: PrintIcon,
    },
  },
  organizationId: {
    element: FormInputTypes.Select,
    label: 'Organization',
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
    elementProps: {
      isMulti: false,
      clearToNull: true,
      disabled: true,
      options: organizationOptions ?? [],
    },
  },
  locationId: {
    element: FormInputTypes.Select,
    label: 'Location',
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
    elementProps: {
      isMulti: false,
      clearToNull: true,
      disabled: true,
      options: locationOptions ?? [],
    },
  },
  jobsiteBadgeTemplates: {
    element: FormInputTypes.Select,
    label: 'Badge Templates',
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
    elementProps: {
      isMulti: true,
      clearToNull: true,
      options: badgeTemplateOptions ?? [],
    },
  },
  defaultJobsiteBadgeTemplate: {
    element: FormInputTypes.Select,
    label: 'Default Badge Template',
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
    elementProps: {
      isMulti: false,
      clearToNull: true,
      options: defaultBadgeTemplateOptions ?? [],
      disabled: !defaultBadgeTemplateOptions?.length,
    },
  },
});

export const filterCurrentHidFargoSelectOptionElements = (
  options: HidFargoSelectOptionElement[],
  selectedProxyInstance: SelectOptionElement,
): HidFargoSelectOptionElement[] => {
  return options.filter((option) => {
    const { entity } = option;
    return entity?.proxyInstance === selectedProxyInstance?.value;
  });
};

export const getHidFargoConnectSectionInputsHook =
  (args: HidFargoConnectSectionInputsHookArgs): UseInputs<EditJobsiteConfigurationFormData['hidFargoConnect']> =>
  (
    form: UseFormMethods<EditJobsiteConfigurationFormData['hidFargoConnect']>,
  ): TypedFormInputs<EditJobsiteConfigurationFormData['hidFargoConnect']> => {
    const { watch, setValue } = form;
    const { proxyInstanceOptions, organizationOptions, locationOptions, printerOptions, badgeTemplateOptions } = args;
    const selectedProxyInstance = watch('hidFargoConnect.proxyInstance') as SelectOptionElement;
    const selectedPrinter = watch('hidFargoConnect.printerId') as HidFargoSelectOptionElement;
    const currentBadgeSelections = watch('hidFargoConnect.jobsiteBadgeTemplates') as BadgeTemplateOptionElement[];
    const defaultBadgeTemplateSelection = watch(
      'hidFargoConnect.defaultJobsiteBadgeTemplate',
    ) as BadgeTemplateOptionElement;

    const { defaultBadgeTemplateOptions, shouldResetDefaultBadgeTemplate } = React.useMemo(() => {
      const reset =
        !!defaultBadgeTemplateSelection &&
        !currentBadgeSelections?.some((badge) => defaultBadgeTemplateSelection.value === badge.value);
      return { defaultBadgeTemplateOptions: currentBadgeSelections ?? [], shouldResetDefaultBadgeTemplate: reset };
    }, [currentBadgeSelections]);

    useDidUpdateEffect(() => {
      if (shouldResetDefaultBadgeTemplate) {
        setValue('hidFargoConnect.defaultJobsiteBadgeTemplate', null, { shouldDirty: true });
      }
    }, [shouldResetDefaultBadgeTemplate]);

    // reset the printer, organization, and location values when the proxy instance changes
    useDidUpdateEffect(() => {
      setValue('hidFargoConnect.printerId', null, { shouldDirty: true });
      setValue('hidFargoConnect.organizationId', null, { shouldDirty: true });
      setValue('hidFargoConnect.locationId', null, { shouldDirty: true });
    }, [selectedProxyInstance]);

    // reset the organization and location values when the printer changes
    useDidUpdateEffect(() => {
      const { organizationId, locationId } = selectedPrinter?.entity ?? {};
      setValue(
        'hidFargoConnect.organizationId',
        organizationOptions.find((opt) => opt.value === organizationId) ?? null,
        { shouldDirty: true },
      );
      setValue('hidFargoConnect.locationId', locationOptions.find((opt) => opt.value === locationId) ?? null, {
        shouldDirty: true,
      });
    }, [selectedPrinter]);

    return React.useMemo(() => {
      return getHidFargoConnectSectionInputs({
        proxyInstanceOptions,
        organizationOptions: filterCurrentHidFargoSelectOptionElements(organizationOptions, selectedProxyInstance),
        locationOptions: filterCurrentHidFargoSelectOptionElements(locationOptions, selectedProxyInstance),
        printerOptions: filterCurrentHidFargoSelectOptionElements(printerOptions, selectedProxyInstance),
        selectedProxyInstance,
        badgeTemplateOptions,
        defaultBadgeTemplateOptions,
      });
    }, [
      selectedProxyInstance,
      selectedPrinter,
      organizationOptions,
      locationOptions,
      printerOptions,
      badgeTemplateOptions,
      defaultBadgeTemplateOptions,
    ]);
  };

export const getHidFargoConnectSectionDefaultValues = (
  jobsite: Jobsite,
  hidProxyInstanceOptions: SelectOptionElement<HidProxyInstance>[],
  hidOrganizationOptions: HidFargoSelectOptionElement[],
  hidLocationOptions: HidFargoSelectOptionElement[],
  hidPrinterOptions: HidFargoSelectOptionElement[],
  badgeTemplateOptions: BadgeTemplateOptionElement[],
): EditJobsiteConfigurationFormData['hidFargoConnect'] => {
  const { hidPrinterId, fargoLocationId, fargoOrganizationId, hidProxyInstance, jobsiteBadgeTemplates } = jobsite ?? {};
  const currentBadgeTemplates = jobsiteBadgeTemplates?.edges?.map(({ node }) => node) ?? [];
  const currentDefaultJobsiteBadgeTemplate = currentBadgeTemplates.find((jbt) => jbt.isDefault) ?? null;
  const defaultJobsiteBadgeTemplate =
    badgeTemplateOptions.find((opt) => opt.badgeTemplate.id === currentDefaultJobsiteBadgeTemplate?.badgeTemplate.id) ??
    null;

  return {
    proxyInstance: hidProxyInstanceOptions?.find((opt) => opt.value === hidProxyInstance) ?? null,
    printerId: hidPrinterOptions.find((opt) => opt.value === hidPrinterId) ?? null,
    organizationId: hidOrganizationOptions.find((opt) => opt.value === fargoOrganizationId) ?? null,
    locationId: hidLocationOptions.find((opt) => opt.value === fargoLocationId) ?? null,
    jobsiteBadgeTemplates:
      badgeTemplateOptions.filter((opt) => {
        return currentBadgeTemplates.some((jbt) => jbt.badgeTemplate.id === opt.badgeTemplate.id);
      }) ?? null,
    defaultJobsiteBadgeTemplate,
  };
};

type HidFargoConnectSectionUpdateInput = Required<
  Pick<
    JobsiteUpdateInput,
    'hidPrinterId' | 'fargoLocationId' | 'fargoOrganizationId' | 'hidProxyInstance' | 'jobsiteBadgeTemplateInputs'
  >
>;

export const getHidFargoConnectSectionUpdateInput = (
  hidFargoConnect: EditJobsiteConfigurationFormData['hidFargoConnect'],
  dirtyFields: DeepMap<EditJobsiteConfigurationFormData['hidFargoConnect'], true>,
): HidFargoConnectSectionUpdateInput => {
  const getUpdateInputValue = getUpdateInputValueFunction(hidFargoConnect, dirtyFields);
  const updatedDefaultBadgeTemplateId = getUpdateInputValue('defaultJobsiteBadgeTemplate');
  const updatedJobsiteBadgeTemplateIds = getUpdateInputValue('jobsiteBadgeTemplates');

  let jobsiteBadgeTemplateInputs: HidFargoConnectSectionUpdateInput['jobsiteBadgeTemplateInputs'];
  if (updatedDefaultBadgeTemplateId !== undefined || updatedJobsiteBadgeTemplateIds !== undefined) {
    const jobsiteBadgeTemplateIds =
      updatedJobsiteBadgeTemplateIds ?? hidFargoConnect.jobsiteBadgeTemplates?.map((i) => i.value);
    const defaultBadgeTemplateId = updatedDefaultBadgeTemplateId ?? hidFargoConnect.defaultJobsiteBadgeTemplate?.value;

    jobsiteBadgeTemplateInputs =
      jobsiteBadgeTemplateIds?.map((badgeTemplateId) => ({
        badgeTemplateId,
        isDefault: badgeTemplateId === defaultBadgeTemplateId,
      })) ?? null;
  }

  return {
    hidPrinterId: getUpdateInputValue('printerId'),
    fargoLocationId: getUpdateInputValue('locationId'),
    fargoOrganizationId: getUpdateInputValue('organizationId'),
    hidProxyInstance: getUpdateInputValue('proxyInstance'),
    jobsiteBadgeTemplateInputs,
  };
};
