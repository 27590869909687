import React from 'react';
import { Button } from '@odin-labs/components';
import { AuthContext } from 'auth';
import { to } from 'acl';
import { CopyLink } from 'components/copyLink';
import { PdfInstructionsDownloadLink } from 'containers/contractor/components';

export type WorkerOnboardingLinkProps = {
  onboardingLink?: string;
  canGenerateOnboardingLink: boolean;
  generateOnboardingLink?: () => void;
  isGeneratingOnboardingLink?: boolean;
  jobsiteName: string;
  contractorName: string;
};

export function WorkerOnboardingLink(props: WorkerOnboardingLinkProps): React.ReactElement {
  const {
    onboardingLink,
    canGenerateOnboardingLink,
    generateOnboardingLink,
    isGeneratingOnboardingLink,
    jobsiteName,
    contractorName,
  } = props;

  const { currentUser: user } = React.useContext(AuthContext);

  if (onboardingLink) {
    return (
      <div className="odin-flex odin-gap-x-10">
        <CopyLink link={onboardingLink} />
        <PdfInstructionsDownloadLink
          onboardingLink={onboardingLink}
          jobsiteName={jobsiteName}
          contractorName={contractorName}
        />
      </div>
    );
  }

  if (isGeneratingOnboardingLink) {
    return <div className="odin-animate-pulse odin-bg-gray-200 odin-h-6 odin-rounded-md -odin-ml-1" />;
  }

  if (canGenerateOnboardingLink && user.isAllowed(to.manageWorkerOnboardingLinks)) {
    return <Button theme="secondary" size="sm" text="Generate" onClick={generateOnboardingLink} />;
  }

  return null;
}
