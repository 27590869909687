import moment from 'moment';
import { DeepMap } from 'react-hook-form';
import { SelectOptionElement } from '@odin-labs/components';
import { File as ServerFile, JobsiteWorkerDocument, AdditionalFieldValue } from 'apollo/generated/client-operations';
import { DropzoneProps } from 'components/dropzone';
import { dropzoneValidation } from 'components/dropzone/utils';
import { FormDefaultValue, FormInput, FormInputTypes, GridColSpan, TypedFormInputs } from 'components/form';
import {
  MedicalConditionsDataType,
  OnboardingStepKey,
  SignatureDataType,
  WorkerDocumentsDataType,
  JobsiteWorker,
  JwDocument,
} from 'containers/workerOnboarding/types';
import { textFieldInputMaxLengthDefault } from 'utils/constants';
import { getPrettyFormattedUtcDate } from 'utils/dates';
import { dateValidation } from 'utils/validation';
import { DocumentKey, byCreatedAtDescending } from 'containers/worker/utils';
import { ensureNonUndefinedFields } from 'utils';
import { getCompletedSteps } from './utils';

export type DocumentWithPreviewUrl = { previewUrl?: string; getPreviewUrl?: (documentKey: string) => string };
export type DocumentWithRequiredInputs = { areInputsRequired?: boolean };
export type DocumentWithExistingDocumentAlert = {
  showExistingDocumentAlert?: boolean;
  getShowExistingDocumentAlert?: (documentKey: string) => boolean;
};
export type DocumentWithConditionalRequiredInputs<TDocument extends Record<string, unknown> = WorkerDocumentsDataType> =
  {
    suppressRequiredWhenDocIsNotDirty?: boolean;
    dirtyFields?: DeepMap<TDocument, true>;
  };

export type JobsiteWorkerDocumentField = 'front' | 'back' | 'card-type' | 'expiration-date';

export const accept: DropzoneProps['accept'] = {
  'application/pdf': [],
  'image/*': ['.png', '.jpg', '.jpeg', '.heic', '.heif'],
};

export type JobsiteWorkerDocumentArgs<TDocument extends Record<string, unknown> = WorkerDocumentsDataType> =
  DocumentWithPreviewUrl &
    DocumentWithExistingDocumentAlert &
    DocumentWithConditionalRequiredInputs<TDocument> & {
      documentKey: string;
      label: string;
      hideLabel?: boolean;
      options?: SelectOptionElement[] | null;
      hasBack?: boolean;
      hasExpirationDate?: boolean;
      children?: TypedFormInputs<TDocument>;
      requiredFields?: JobsiteWorkerDocumentField[];
    };

/**
 * Suppresses all required fields if `suppressRequiredWhenDocIsNotDirty` is true
 * and the document has not dirty fields.
 */
export const withConditionalRequired = <TDocument extends Record<string, unknown> = WorkerDocumentsDataType>(
  args: Pick<JobsiteWorkerDocumentArgs<TDocument>, 'suppressRequiredWhenDocIsNotDirty' | 'dirtyFields'> & {
    document: FormInput<TDocument>;
  },
): FormInput<TDocument> => {
  const { document, suppressRequiredWhenDocIsNotDirty, dirtyFields } = args;
  const { children, files } = document;
  if (suppressRequiredWhenDocIsNotDirty && dirtyFields && (children || files)) {
    const inputNames = [
      ...(Array.isArray(children) ? children.map((c) => c.name) : Object.keys(children ?? {})),
      ...(files ?? []).map((f) => f.name),
    ];
    const isDocumentDirty = Object.keys(dirtyFields).some((df) => inputNames.includes(df));
    if (!isDocumentDirty) {
      const inputs = [...(Array.isArray(children) ? children : Object.values(children ?? {})), ...(files ?? [])];
      inputs.forEach((input: FormInput<TDocument>) => {
        const { required } = input.validation ?? {};
        if (required) {
          Object.assign(input.validation, { required: false });
        }
      });
    }
  }
  return document;
};

export const generateJobsiteWorkerDocument = <TDocument extends Record<string, unknown> = WorkerDocumentsDataType>(
  args: JobsiteWorkerDocumentArgs<TDocument>,
): FormInput<TDocument> => {
  const {
    documentKey,
    label,
    hideLabel,
    options,
    hasExpirationDate,
    hasBack,
    children,
    requiredFields = [],
    suppressRequiredWhenDocIsNotDirty,
    dirtyFields,
    previewUrl,
    getPreviewUrl,
    showExistingDocumentAlert,
    getShowExistingDocumentAlert,
  } = args;

  const frontFileLabel = `${label} ${hasBack ? 'front' : ''} upload`;
  type TName = FormInput<TDocument>['name'];

  const workerDocument: FormInput<TDocument> = {
    name: `${documentKey}-document` as TName,
    element: FormInputTypes.JobsiteWorkerDocument,
    elementProps: {
      label,
      hideLabel,
      previewUrl: previewUrl ?? getPreviewUrl?.(documentKey),
      showExistingDocumentAlert: showExistingDocumentAlert ?? getShowExistingDocumentAlert?.(documentKey),
    },
    layout: '',
    children:
      ensureNonUndefinedFields<TypedFormInputs<TDocument>>({
        ...(options?.length && {
          [`${documentKey}-card-type`]: {
            element: FormInputTypes.Select,
            label: 'Card type',
            elementProps: {
              placeholder: 'Select one',
              options,
            },
            validation: {
              required: requiredFields.includes('card-type'),
            },
            layout: GridColSpan.SpanFull,
          },
        }),
        ...(hasExpirationDate && {
          [`${documentKey}-expiration-date`]: {
            element: FormInputTypes.Field,
            label: 'Expiration date',
            elementProps: {
              fieldType: 'date',
              placeholder: 'Expiration date',
            },
            validation: {
              pattern: dateValidation,
              required: requiredFields.includes('expiration-date'),
            },
            layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
          },
        }),
        ...children,
      }) ?? [],
    files: [
      {
        name: `${documentKey}-front` as TName,
        element: FormInputTypes.Dropzone,
        label: frontFileLabel,
        elementProps: {
          accept,
          uploadEntirePdf: documentKey === DocumentKey.SiteSpecificOrientation,
          isMulti: documentKey === DocumentKey.SiteSpecificOrientation,
        },
        validation: {
          required: requiredFields.includes('front'),
          validate: dropzoneValidation,
        },
        layout: hasBack ? GridColSpan.Span6 : GridColSpan.SpanFull,
      },
    ],
  };

  if (hasBack) {
    const backFileLabel = `${label} back upload`;
    workerDocument.files = [
      ...workerDocument.files,
      {
        name: `${documentKey}-back` as TName,
        element: FormInputTypes.Dropzone,
        label: backFileLabel,
        elementProps: {
          accept,
          uploadEntirePdf: documentKey === DocumentKey.SiteSpecificOrientation,
        },
        validation: {
          required: requiredFields.includes('back'),
          validate: dropzoneValidation,
        },
        layout: GridColSpan.Span6,
      },
    ];
  }

  return withConditionalRequired({ document: workerDocument, suppressRequiredWhenDocIsNotDirty, dirtyFields });
};

export type JobsiteMedicalWorkerDocumentField =
  | 'result'
  | 'front'
  | 'back'
  | 'conditional-access-expiration-date'
  | 'date-signed';

export type JobsiteMedicalWorkerDocumentArgs = DocumentWithConditionalRequiredInputs & {
  documentKey: string;
  label: string;
  previewUrl: string;
  showExistingDocumentAlert: boolean;
  hideDocumentLabel?: boolean;
  hasBack?: boolean;
  showExpiration?: boolean;
  showDateSigned?: boolean;
  showReason?: boolean;
  showNotes?: boolean;
  selectOptions?: SelectOptionElement[];
  requiredFields?: JobsiteMedicalWorkerDocumentField[];
};

const testReasonsOptions = ['Random', 'Suspicion', 'Post accident'];

export const getDocumentPreviewUrl = (documentId: string): string => {
  return documentId && `/onboarding/document/${documentId}/print`;
};

export const generateJobsiteMedicalWorkerDocument = (
  args: JobsiteMedicalWorkerDocumentArgs,
): FormInput<WorkerDocumentsDataType> => {
  const {
    documentKey,
    label,
    previewUrl,
    showExistingDocumentAlert,
    selectOptions,
    hasBack = false,
    showExpiration = false,
    showDateSigned = false,
    hideDocumentLabel = false,
    showReason = false,
    showNotes = false,
    requiredFields = [],
    suppressRequiredWhenDocIsNotDirty,
    dirtyFields,
  } = args;

  const frontFileLabel = `${label} ${hasBack ? 'front' : ''} upload`;
  const workerDocument: FormInput<WorkerDocumentsDataType> = {
    name: `${documentKey}-document`,
    element: FormInputTypes.JobsiteWorkerDocument,
    label,
    elementProps: {
      hideLabel: hideDocumentLabel,
      previewUrl,
      showExistingDocumentAlert,
    },
    layout: '',
    children: selectOptions?.length
      ? [
          {
            name: `${documentKey}-result`,
            element: FormInputTypes.Select,
            label: 'Test result',
            elementProps: {
              placeholder: 'Select one',
              options: selectOptions,
            },
            validation: {
              required: requiredFields.includes('result'),
            },
            layout: [GridColSpan.SpanFull, GridColSpan.SmSpan5],
          },
        ]
      : [],
    files: [
      {
        name: `${documentKey}-front`,
        element: FormInputTypes.Dropzone,
        label: frontFileLabel,
        elementProps: {
          accept,
        },
        validation: {
          required: requiredFields.includes('front'),
          validate: dropzoneValidation,
        },
        layout: hasBack ? GridColSpan.Span6 : GridColSpan.SpanFull,
      },
    ],
  };

  if (showExpiration) {
    workerDocument.children = {
      ...workerDocument.children,
      [`${documentKey}-conditional-access-expiration-date`]: {
        element: FormInputTypes.Field,
        label: 'Conditional access expiration date',
        elementProps: {
          fieldType: 'date',
          placeholder: 'mm/dd/yyyy',
        },
        validation: { pattern: dateValidation, required: true },
        layout: [GridColSpan.SpanFull, GridColSpan.SmSpan7],
      },
    };
  }

  if (showDateSigned) {
    workerDocument.children = {
      ...workerDocument.children,
      [`${documentKey}-date-signed`]: {
        element: FormInputTypes.Field,
        label: 'Date signed',
        elementProps: {
          fieldType: 'date',
          placeholder: 'Date signed',
        },
        validation: {
          pattern: dateValidation,
          required: requiredFields.includes('date-signed'),
        },
        layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
      },
    };
  }

  if (showReason) {
    workerDocument.children = {
      ...workerDocument.children,
      [`${documentKey}-test-reason`]: {
        element: FormInputTypes.Select,
        label: 'Reason for test',
        elementProps: {
          placeholder: 'Select one',
          options: testReasonsOptions.map((option) => ({ label: option, value: option })),
        },
        validation: {
          required: true,
        },
        layout: [GridColSpan.SpanFull, GridColSpan.SmSpan7],
      },
    };
  }

  if (showNotes) {
    workerDocument.children = {
      ...workerDocument.children,
      [`${documentKey}-notes`]: {
        element: FormInputTypes.TextAreaField,
        label: 'Additional Notes (Max 150 Characters)',
        elementProps: {
          maxLength: textFieldInputMaxLengthDefault,
        },
        layout: GridColSpan.SpanFull,
      },
    };
  }

  if (hasBack) {
    const backFileLabel = `${label} back upload`;
    workerDocument.files = [
      ...workerDocument.files,
      {
        name: `${documentKey}-back`,
        element: FormInputTypes.Dropzone,
        label: backFileLabel,
        elementProps: {
          accept,
        },
        validation: {
          required: requiredFields.includes('back'),
          validate: dropzoneValidation,
        },
        layout: GridColSpan.Span6,
      },
    ];
  }

  return withConditionalRequired({ document: workerDocument, suppressRequiredWhenDocIsNotDirty, dirtyFields });
};

export const getDocumentsWithExistingDocumentAlerts = (args: {
  jobsiteWorker: JobsiteWorker;
  stepKey: OnboardingStepKey;
  documentKeys: readonly string[];
  documents: JwDocument[];
}): Record<string, boolean> => {
  const { stepKey, jobsiteWorker, documentKeys, documents } = args;
  const completedSteps = jobsiteWorker && getCompletedSteps(jobsiteWorker);
  const workerHasMultipleJobsites = jobsiteWorker?.contractorWorker.worker.jobsiteWorkers.count > 1;
  const isStepNotStarted = !completedSteps?.[stepKey];
  const existingDocumentKeys = documents.map(({ key }) => key);

  return Object.fromEntries(
    documentKeys.map((documentKey) => [
      documentKey,
      workerHasMultipleJobsites && isStepNotStarted && existingDocumentKeys.includes(documentKey),
    ]),
  );
};

export const medicalConditionsInputs = (): FormInput<MedicalConditionsDataType> => {
  return {
    name: 'medicalConditions',
    element: FormInputTypes.JobsiteWorkerDocument,
    label: 'Medical conditions',
    layout: '',
    children: {
      medicalNotes: {
        element: FormInputTypes.Field,
        label: 'Please enter any pre-existing conditions or other notes for this worker',
        layout: GridColSpan.SpanFull,
      },
    },
    files: [],
  };
};

export const signatureFormInput = (): FormInput<SignatureDataType> => {
  return {
    name: 'signature-front',
    element: FormInputTypes.Dropzone,
    label: 'Signature',
    elementProps: {
      accept,
      sourceType: 'fileAndStylus',
    },
    validation: {
      required: true,
    },
    layout: GridColSpan.SpanFull,
  };
};

export type JobsiteWorkerDocumentsData = {
  defaultFormValues: FormDefaultValue<WorkerDocumentsDataType>[];
  documents: JwDocument[];
};

export const getFilesData = (
  workerDocumentKey: string,
  files: ServerFile[],
): JobsiteWorkerDocumentsData['defaultFormValues'] => {
  if (workerDocumentKey === DocumentKey.SiteSpecificOrientation) {
    return [
      {
        name: `${workerDocumentKey}-front`,
        value: files ?? null,
      },

      {
        name: `${workerDocumentKey}-back`,
        value: null,
      },
    ];
  }

  const [frontFile, backFile] = files ?? [];
  return [
    {
      name: `${workerDocumentKey}-front`,
      value: frontFile ?? null,
    },
    {
      name: `${workerDocumentKey}-back`,
      value: backFile ?? null,
    },
  ];
};

const getDefaultValue = (additionalField: AdditionalFieldValue): unknown => {
  if (additionalField?.key?.includes('date')) {
    return getPrettyFormattedUtcDate(additionalField?.value);
  }
  if (additionalField?.key === 'acknowledgment-status') {
    return additionalField.value === 'confirmed';
  }
  return additionalField?.value;
};

export const getWorkerDocumentKey = (workerDocumentTypeKey: string, createdAt: string): string => {
  if (workerDocumentTypeKey === DocumentKey.Generic || workerDocumentTypeKey === DocumentKey.AdditionalCertifications) {
    const documentDiscriminator = moment(createdAt).format('YYYYMMDDHHmmssSSS');
    return `${workerDocumentTypeKey}-${documentDiscriminator}`;
  }
  return workerDocumentTypeKey;
};

export const getJobsiteWorkerDocumentsData = (
  jobsiteWorkerDocuments: JobsiteWorkerDocument[],
): JobsiteWorkerDocumentsData =>
  jobsiteWorkerDocuments.reduce(
    (result, jobsiteWorkerDocument) => {
      const { jobsiteWorkerDocumentVersions, jobsiteWorkerDocumentType, jobsiteWorkerDocumentId } =
        jobsiteWorkerDocument;

      const documentVersions = [...(jobsiteWorkerDocumentVersions ?? [])].sort(byCreatedAtDescending);
      const [documentLatestVersion] = documentVersions;

      const workerDocumentKey = getWorkerDocumentKey(
        jobsiteWorkerDocumentType?.workerDocumentType.key,
        jobsiteWorkerDocument.objectHistory.createdAt,
      );

      const { files } = documentLatestVersion ?? {};
      const additionalFields = documentLatestVersion?.additionalFieldValues ?? [];

      const filesData = getFilesData(workerDocumentKey, files);

      result.defaultFormValues.push(
        ...filesData,
        ...additionalFields.map((additionalField) => {
          return {
            name: `${workerDocumentKey}-${additionalField?.key}`,
            value: getDefaultValue(additionalField),
          };
        }),
      );

      result.documents.push({ key: workerDocumentKey, id: jobsiteWorkerDocumentId });

      return result;
    },
    {
      defaultFormValues: [],
      documents: [],
    } as JobsiteWorkerDocumentsData,
  );
