import { JobsiteFeaturesModuleInput } from 'apollo/generated/client-operations';
import { FormInputTypes, getUpdateInputValueFunction, GridColSpan, TypedFormInputs } from 'components/form';
import { getFeaturesModule } from 'containers/jobsiteConfiguration/helpers/utils';
import { EditJobsiteConfigurationFormData, Jobsite } from 'containers/jobsiteConfiguration/types';
import { DeepMap } from 'react-hook-form';
import { toggleBorderClasses } from './utils';

export const featuresSectionInputs: TypedFormInputs<EditJobsiteConfigurationFormData['features']> = {
  announcementsEnabled: {
    element: FormInputTypes.Toggle,
    label: 'Enable Announcements',
    layout: [GridColSpan.SpanFull, toggleBorderClasses],
    elementProps: { toggleAlignment: 'right-with-space' },
  },
  archivingAccessEventsEnabled: {
    element: FormInputTypes.Toggle,
    label: 'Enable Archiving Access Events',
    layout: [GridColSpan.SpanFull, toggleBorderClasses],
    elementProps: { toggleAlignment: 'right-with-space' },
  },
  dailyLog: {
    element: FormInputTypes.Panel,
    layout: [GridColSpan.SpanFull],
    children: {
      formsEnabled: {
        element: FormInputTypes.Toggle,
        label: 'Enable Forms',
        layout: [GridColSpan.SpanFull],
        elementProps: { toggleAlignment: 'right-with-space' },
      },
      addWorkersToDailyLog: {
        element: FormInputTypes.Toggle,
        label: 'Add Workers to Daily Log',
        layout: [GridColSpan.SpanFull],
      },
    },
  },
  doNotAllowToSkipSelfOnboardingSteps: {
    element: FormInputTypes.Toggle,
    label: 'Remove Ability to Skip Steps in Self Onboarding',
    layout: [GridColSpan.SpanFull, toggleBorderClasses],
    elementProps: { toggleAlignment: 'right-with-space' },
  },
  enforceValidContractorInSelfOnboarding: {
    element: FormInputTypes.Toggle,
    label: 'Enforce Valid Contractor in Self Onboarding',
    layout: [GridColSpan.SpanFull, toggleBorderClasses],
    elementProps: { toggleAlignment: 'right-with-space' },
  },
  workerAppEnabled: {
    element: FormInputTypes.Toggle,
    label: 'Enable Worker App in Self Onboarding',
    layout: [GridColSpan.SpanFull, toggleBorderClasses],
    elementProps: { toggleAlignment: 'right-with-space' },
  },
  workerAppSelfCheckInEnabled: {
    element: FormInputTypes.Toggle,
    label: 'Enable Worker App Self Check In',
    layout: [GridColSpan.SpanFull, toggleBorderClasses],
    elementProps: { toggleAlignment: 'right-with-space' },
  },
  workerAppAdminCheckInEnabled: {
    element: FormInputTypes.Toggle,
    label: 'Enable Worker App Admin Check In',
    layout: [GridColSpan.SpanFull, toggleBorderClasses],
    elementProps: { toggleAlignment: 'right-with-space' },
  },
  workerAppBadgeScanningEnabled: {
    element: FormInputTypes.Toggle,
    label: 'Enable Worker App Badge Scanning',
    layout: [GridColSpan.SpanFull, toggleBorderClasses],
    elementProps: { toggleAlignment: 'right-with-space' },
  },
  workerAppKioskCheckInEnabled: {
    element: FormInputTypes.Toggle,
    label: 'Enable Kiosk Access Events',
    layout: [GridColSpan.SpanFull, toggleBorderClasses],
    elementProps: { toggleAlignment: 'right-with-space' },
  },
  selfOnboardingAutoCompleteEnabled: {
    element: FormInputTypes.Toggle,
    label: 'Enable Auto Complete for self-onboarding',
    layout: [GridColSpan.SpanFull, toggleBorderClasses],
    elementProps: { toggleAlignment: 'right-with-space' },
  },
  workerAppKioskFrontCameraEnabled: {
    element: FormInputTypes.Toggle,
    label: 'Enable front facing camera in kiosk mode',
    layout: [GridColSpan.SpanFull, toggleBorderClasses],
    elementProps: { toggleAlignment: 'right-with-space' },
  },
};

// comment to trigger build for release/v2024.6
export const getFeaturesSectionDefaultValues = (jobsite: Jobsite): EditJobsiteConfigurationFormData['features'] => {
  const featuresModule = getFeaturesModule(jobsite?.modules);
  const {
    announcementsEnabled,
    archivingAccessEventsEnabled,
    formsEnabled,
    addWorkersToDailyLog,
    doNotAllowToSkipSelfOnboardingSteps,
    enforceValidContractorInSelfOnboarding,
    workerAppEnabled,
    workerAppSelfCheckInEnabled,
    workerAppAdminCheckInEnabled,
    workerAppBadgeScanningEnabled,
    workerAppKioskCheckInEnabled,
    selfOnboardingAutoCompleteEnabled,
    workerAppKioskFrontCameraEnabled,
  } = featuresModule ?? {};

  return {
    announcementsEnabled: !!announcementsEnabled,
    archivingAccessEventsEnabled: !!archivingAccessEventsEnabled,
    dailyLog: {
      formsEnabled: !!formsEnabled,
      addWorkersToDailyLog: !!addWorkersToDailyLog,
    },
    doNotAllowToSkipSelfOnboardingSteps: !!doNotAllowToSkipSelfOnboardingSteps,
    enforceValidContractorInSelfOnboarding: !!enforceValidContractorInSelfOnboarding,
    workerAppEnabled: !!workerAppEnabled,
    workerAppSelfCheckInEnabled: !!workerAppSelfCheckInEnabled,
    workerAppAdminCheckInEnabled: !!workerAppAdminCheckInEnabled,
    workerAppBadgeScanningEnabled: !!workerAppBadgeScanningEnabled,
    workerAppKioskCheckInEnabled: !!workerAppKioskCheckInEnabled,
    selfOnboardingAutoCompleteEnabled: !!selfOnboardingAutoCompleteEnabled,
    workerAppKioskFrontCameraEnabled: !!workerAppKioskFrontCameraEnabled,
  };
};

type FeaturesSectionUpdateInput = Required<
  Pick<
    JobsiteFeaturesModuleInput,
    | 'announcementsEnabled'
    | 'archivingAccessEventsEnabled'
    | 'formsEnabled'
    | 'addWorkersToDailyLog'
    | 'doNotAllowToSkipSelfOnboardingSteps'
    | 'enforceValidContractorInSelfOnboarding'
    | 'workerAppEnabled'
    | 'workerAppSelfCheckInEnabled'
    | 'workerAppAdminCheckInEnabled'
    | 'workerAppBadgeScanningEnabled'
    | 'workerAppKioskCheckInEnabled'
    | 'selfOnboardingAutoCompleteEnabled'
    | 'workerAppKioskFrontCameraEnabled'
  >
>;

export const getFeaturesSectionUpdateInput = (
  features: EditJobsiteConfigurationFormData['features'],
  dirtyFields: DeepMap<EditJobsiteConfigurationFormData['features'], true>,
): FeaturesSectionUpdateInput => {
  const getUpdateInputValue = getUpdateInputValueFunction(features, dirtyFields);
  const getDailyLogUpdateInputValue = getUpdateInputValueFunction(features.dailyLog, dirtyFields?.dailyLog);
  return {
    announcementsEnabled: getUpdateInputValue('announcementsEnabled'),
    archivingAccessEventsEnabled: getUpdateInputValue('archivingAccessEventsEnabled'),
    formsEnabled: getDailyLogUpdateInputValue('formsEnabled'),
    addWorkersToDailyLog: getDailyLogUpdateInputValue('addWorkersToDailyLog'),
    doNotAllowToSkipSelfOnboardingSteps: getUpdateInputValue('doNotAllowToSkipSelfOnboardingSteps'),
    enforceValidContractorInSelfOnboarding: getUpdateInputValue('enforceValidContractorInSelfOnboarding'),
    workerAppEnabled: getUpdateInputValue('workerAppEnabled'),
    workerAppSelfCheckInEnabled: getUpdateInputValue('workerAppSelfCheckInEnabled'),
    workerAppAdminCheckInEnabled: getUpdateInputValue('workerAppAdminCheckInEnabled'),
    workerAppBadgeScanningEnabled: getUpdateInputValue('workerAppBadgeScanningEnabled'),
    workerAppKioskCheckInEnabled: getUpdateInputValue('workerAppKioskCheckInEnabled'),
    selfOnboardingAutoCompleteEnabled: getUpdateInputValue('selfOnboardingAutoCompleteEnabled'),
    workerAppKioskFrontCameraEnabled: getUpdateInputValue('workerAppKioskFrontCameraEnabled'),
  };
};
