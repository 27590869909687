import React from 'react';
import { EllipsisDropdownButton, MenuItemProps, getFixedWidthFaIcon } from '@odin-labs/components';
import { InputFile } from 'types';
import { getUserFullName } from 'utils';
import { getPrettyFormattedDateTime } from 'utils/dates';
import { faEdit, faTrash } from 'components/icons';
import { Document } from 'components/document';
import { FormSubmissionDocumentProps } from './types';

export const classes = {
  container: 'odin-border odin-border-gray-200 odin-shadow-sm odin-rounded-lg odin-p-6 odin-space-y-2',
  documentInfoRow: 'odin-flex',
  documentDescription: 'odin-grow odin-text-sm odin-text-gray-900 odin-pr-3',
  documentCreatedByInfo: 'odin-text-xs odin-text-gray-600',
};

const EditIcon = getFixedWidthFaIcon({ icon: faEdit });
const TrashIcon = getFixedWidthFaIcon({ icon: faTrash });

export function FormSubmissionDocument(props: FormSubmissionDocumentProps): React.ReactElement {
  const { document, onEditClick, onDeleteClick } = props;
  const { description, files, objectHistory } = document;
  const file = files?.[0] as InputFile;

  const { createdBy, createdAt } = objectHistory ?? {};

  const menuItems: MenuItemProps[] = [
    {
      icon: EditIcon,
      text: 'Edit Attachment',
      theme: 'secondary',
      onClick: onEditClick,
    },
    {
      icon: TrashIcon,
      text: 'Delete Attachment',
      theme: 'danger',
      onClick: onDeleteClick,
    },
  ];

  return (
    <div className={classes.container}>
      <div>{file && <Document file={file} />}</div>
      <div className={classes.documentInfoRow}>
        <div className={classes.documentDescription}>{description}</div>
        <EllipsisDropdownButton menuItems={menuItems} />
      </div>
      {createdBy && (
        <span className={classes.documentCreatedByInfo}>
          Uploaded by {getUserFullName(createdBy)}, {getPrettyFormattedDateTime(createdAt)}
        </span>
      )}
    </div>
  );
}
