// eslint-disable @typescript-eslint/ban-ts-comment
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { usePageQueryParams } from 'utils/usePageQueryParams';
import { DashboardTabProps } from 'containers/dashboard/tabs/types';
import { Col } from 'components/container';
import {
  ArcElement,
  Chart,
  LineElement,
  LineController,
  BarElement,
  Colors,
  BubbleController,
  CategoryScale,
  Filler,
  LinearScale,
  Legend,
  PointElement,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip,
  TooltipItem,
  ChartEvent,
  ActiveElement,
  ChartTypeRegistry,
  Color as ChartColor,
} from 'chart.js';
import autocolors, { AutocolorsContext, ColorsDescriptor } from 'chartjs-plugin-autocolors';
import 'chartjs-adapter-date-fns';
// import zoomPlugin from 'chartjs-plugin-zoom';
// import chartDataLabels from 'chartjs-plugin-datalabels';
// this import needs to be different to fix an error - https://github.com/chartjs/chartjs-plugin-datalabels/issues/411
// eslint-disable-next-line import/extensions
import chartDataLabels from 'chartjs-plugin-datalabels/dist/chartjs-plugin-datalabels.esm.js';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Context as DataLabelsContext } from 'chartjs-plugin-datalabels';
import { Chart as ReactChart } from 'react-chartjs-2';
import { ElasticFacetEntry } from 'apollo/generated/client-operations';
import { DashboardStatsOverview } from 'containers/dashboard/tabs/stats/DashboardStatsOverview';
import { odinChartColors } from 'containers/dashboard/types';

export function DashboardGraphsTab(props: DashboardTabProps): React.ReactElement {
  const { data, jobsites, isCurrentlyOnsiteVisible, loading } = props;

  const location = useLocation();
  let { pie: pieChartType = 'pie' } = (queryString.parse(location.search) as { pie: keyof ChartTypeRegistry }) || {};
  if (!(pieChartType === 'pie' || pieChartType === 'doughnut')) {
    pieChartType = 'pie';
  }

  const { updateUrl } = usePageQueryParams();
  const {
    jobsiteBreakdowns,
    dailyBreakdowns,
    languageBreakdowns,
    raceBreakdowns,
    genderBreakdowns,
    onboardedBreakdowns,
    bannedBreakdowns,
    ageBreakdowns,
  } = data || {};

  Chart.register([
    ArcElement,
    BarElement,
    BubbleController,
    CategoryScale,
    Colors,
    Filler,
    LinearScale,
    LineController,
    LineElement,
    Legend,
    PointElement,
    TimeScale,
    TimeSeriesScale,
    Tooltip,
    Title,
    autocolors,
  ]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Chart.register(zoomPlugin);
      // this hack with dynamic import of this plugin is needed to avoid SSR issues
      import('chartjs-plugin-zoom').then((zoomModule) => {
        Chart.register(zoomModule.default);
      });
      // Chart.register(await React.lazy(() => ));
    }
  }, []);

  const dataColorsCustomizer = (context: AutocolorsContext): ColorsDescriptor => {
    const { dataIndex, datasetIndex } = context;
    const index = datasetIndex === undefined ? dataIndex + 1 : dataIndex;
    return odinChartColors[index % odinChartColors.length];
  };

  return (
    <div className="odin-flex odin-flex-col odin-gap-y-9">
      <div className="odin-flex odin-flex-col odin-gap-y-9">
        <DashboardStatsOverview
          workerReportsData={data}
          isCurrentlyOnsiteVisible={isCurrentlyOnsiteVisible}
          loading={loading}
        />
      </div>
      <ReactChart
        type="bar"
        plugins={[chartDataLabels]}
        options={{
          responsive: true,
          aspectRatio: 6,
          parsing: { xAxisKey: 'name', yAxisKey: 'count' },
          plugins: {
            datalabels: {
              align: 'top',
              anchor: 'end',
              backgroundColor: (): ChartColor => {
                // return context.dataset.backgroundColor as ChartColor;
                return odinChartColors[0].background as ChartColor;
              },
              borderRadius: 4,
              font: {
                weight: 'bold',
              },
              color: 'white',
              display: 'auto',
              formatter: (value: ElasticFacetEntry): string => {
                return value?.count?.toString() ?? '';
              },
            },
            tooltip: {
              enabled: true,
              callbacks: {
                label: (ctx: TooltipItem<'bar'> & { raw: ElasticFacetEntry }): string => `Manpower: ${ctx?.raw?.count}`,
              },
            },
            // zoom: {
            //   zoom: {
            //     pinch: {
            //       enabled: true,
            //     },
            //     drag: { enabled: true },
            //     mode: 'x',
            //   },
            // },
          },
          scales: {
            x: {
              grid: { display: false },
              type: 'time',
              time: {
                minUnit: 'day',
                tooltipFormat: 'MMM dd, yyyy',
                unit: 'day',
              },
            },
          },
        }}
        data={{
          labels: dailyBreakdowns?.map((j) => j.name) || [],
          datasets: [
            {
              label: 'Total manpower, per day',
              data: dailyBreakdowns || [],
              backgroundColor: odinChartColors[0].background,
            },
          ],
        }}
      />
      <ReactChart
        type="bar"
        plugins={[chartDataLabels]}
        options={{
          responsive: true,
          aspectRatio: 5,
          plugins: {
            datalabels: {
              align: 'top',
              anchor: 'end',
              color: 'white',
              // color: (context): ChartColor => {
              //   return context.dataset.borderColor as ChartColor;
              // },
              backgroundColor: (context): ChartColor => {
                return context.dataset.backgroundColor as ChartColor;
              },
              borderRadius: 4,
              font: {
                weight: 'bold',
              },
              display: 'auto',
              formatter: (value: ElasticFacetEntry): string => {
                return value?.count?.toString() ?? '';
              },
            },
            autocolors: {
              mode: 'data',
              customize: dataColorsCustomizer,
            },
            tooltip: {
              enabled: true,
              callbacks: {
                label: (ctx: TooltipItem<'bar'> & { raw: ElasticFacetEntry }): string => `Manpower: ${ctx.raw.count}`,
              },
            },
          },
          parsing: { xAxisKey: 'name', yAxisKey: 'count' },
          events: ['click', 'mousemove'],
          onHover: (event: ChartEvent, elements: ActiveElement[], chart: any): void => {
            if (elements.length) {
              // eslint-disable-next-line no-param-reassign
              chart.canvas.style.cursor = 'pointer';
            } else {
              // eslint-disable-next-line no-param-reassign
              chart.canvas.style.cursor = 'default';
            }
          },
          onClick: (e, item): void => {
            if (!item || !item.length) {
              return;
            }
            const { index } = item[0] || {};
            if (index > jobsiteBreakdowns.length) {
              return;
            }
            const current = jobsiteBreakdowns[index];
            const { name: jobsiteName } = current || {};
            const jobsite = jobsites.find((j) => j.name === jobsiteName);
            if (jobsite) {
              updateUrl({ jobsiteIds: [jobsite.jobsiteId] });
            }
          },
        }}
        data={{
          labels: jobsiteBreakdowns?.map((j) => j.name) || [],
          datasets: [
            {
              label: 'Total manpower, per jobsite',
              data: jobsiteBreakdowns || [],
            },
          ],
        }}
      />

      <ReactChart
        type="bar"
        plugins={[chartDataLabels]}
        options={{
          responsive: true,
          aspectRatio: 5,
          scales: {
            y: { beginAtZero: true },
            x: {
              grid: { display: false },
              type: 'time',
              time: {
                minUnit: 'day',
                tooltipFormat: 'MMM dd, yyyy',
                unit: 'day',
              },
            },
          },
          plugins: {
            datalabels: {
              align: 'top',
              anchor: 'end',
              color: 'white',
              backgroundColor: (context): ChartColor => {
                return context.dataset.backgroundColor as ChartColor;
              },
              borderRadius: 4,
              font: {
                weight: 'bold',
              },
              display: 'auto',
              formatter: (value: ElasticFacetEntry): string => {
                return value?.count?.toString() || '';
              },
            },
            tooltip: {
              enabled: true,
              callbacks: {
                label: (ctx: TooltipItem<'bar'> & { raw: ElasticFacetEntry }): string =>
                  `${ctx.dataset.label} Workers: ${ctx.raw.count}`,
              },
            },
          },
          parsing: { xAxisKey: 'name', yAxisKey: 'count' },
        }}
        data={{
          labels: onboardedBreakdowns?.map((j) => j.name) || [],
          datasets: [
            {
              label: 'Onboarded',
              backgroundColor: odinChartColors[0].background,
              borderColor: odinChartColors[0].border,
              data: onboardedBreakdowns || [],
            },
            {
              label: 'Banned',
              backgroundColor: odinChartColors[1].background,
              borderColor: odinChartColors[1].border,
              data: bannedBreakdowns || [],
            },
          ],
        }}
      />
      <div className="lg:odin-grid lg:odin-grid-cols-2 xl:odin-grid-cols-3 lg:odin-gap-9 odin-py-10">
        <Col>
          <ReactChart
            type="pie"
            plugins={[chartDataLabels]}
            options={{
              responsive: true,
              aspectRatio: 2,
              layout: { padding: 10 },
              // parsing: { key: 'count' },
              plugins: {
                datalabels: {
                  display: (context): boolean => {
                    return (context.dataset.data[context.dataIndex] as unknown as ElasticFacetEntry)?.percentage > 5;
                  },
                  backgroundColor: (context): string => {
                    return context.dataset.backgroundColor as string;
                  },
                  borderRadius: 4,
                  color: 'white',
                  align: 'center',
                  anchor: 'end',
                  formatter: (value: ElasticFacetEntry): string => {
                    return value?.percentage ? `${value.percentage}%` : '';
                  },
                },
                title: { display: true, text: 'Language breakdown', position: 'top', fullSize: true, align: 'start' },
                colors: {
                  enabled: false,
                  forceOverride: true,
                  // override: odinChartColors.map((c) => colorHelper(c.background)),
                },
                autocolors: {
                  mode: 'data',
                  customize: dataColorsCustomizer,
                },
                legend: { display: true, position: 'right' },
                tooltip: {
                  enabled: true,
                  callbacks: {
                    label: (ctx: TooltipItem<'pie'> & { raw: ElasticFacetEntry }): string =>
                      `${ctx.raw.count} - ${ctx.raw.percentage}%`,
                  },
                },
              },
            }}
            data={{
              labels: languageBreakdowns?.map((l) => l.name) || [],
              datasets: [
                {
                  // data: languageBreakdowns?.map((l) => l.count) || [],
                  data: languageBreakdowns || [],
                  parsing: { key: 'count' },
                },
              ],
            }}
          />
        </Col>
        <Col>
          <ReactChart
            type={pieChartType}
            plugins={[chartDataLabels]}
            options={{
              responsive: true,
              aspectRatio: 2,
              layout: { padding: 10 },
              plugins: {
                datalabels: {
                  display: (context): boolean => {
                    return (context.dataset.data[context.dataIndex] as unknown as ElasticFacetEntry)?.percentage > 5;
                  },
                  backgroundColor: (context): string => {
                    return context.dataset.backgroundColor as string;
                  },
                  borderRadius: 4,
                  color: 'white',
                  align: 'center',
                  anchor: 'end',
                  formatter: (value: ElasticFacetEntry): string => {
                    return value?.percentage ? `${value.percentage}%` : '';
                  },
                },
                title: { display: true, text: 'Race breakdown', position: 'top', fullSize: true, align: 'start' },
                autocolors: { mode: 'data', customize: dataColorsCustomizer },
                legend: { display: true, position: 'right' },
                tooltip: {
                  enabled: true,
                  callbacks: {
                    label: (ctx: TooltipItem<'pie' | 'doughnut'> & { raw: ElasticFacetEntry }): string =>
                      `${ctx.raw.count} - ${ctx.raw.percentage}%`,
                  },
                },
              },
            }}
            data={{
              labels: raceBreakdowns?.map((l) => l.name) || [],
              datasets: [
                {
                  data: raceBreakdowns || [],
                  parsing: { key: 'count' },
                },
              ],
            }}
          />
        </Col>
        <Col>
          <ReactChart
            type={pieChartType}
            plugins={[chartDataLabels]}
            options={{
              responsive: true,
              aspectRatio: 2,
              layout: { padding: 10 },
              plugins: {
                datalabels: {
                  display: (context): boolean => {
                    return (context.dataset.data[context.dataIndex] as unknown as ElasticFacetEntry)?.percentage > 5;
                  },
                  backgroundColor: (context): string => {
                    return context.dataset.backgroundColor as string;
                  },
                  borderRadius: 4,
                  color: 'white',
                  align: 'center',
                  anchor: 'end',
                  formatter: (value: ElasticFacetEntry): string => {
                    return value?.percentage ? `${value?.percentage}%` : '';
                  },
                },
                title: { display: true, text: 'Gender breakdown', position: 'top', fullSize: true, align: 'start' },
                autocolors: { mode: 'data', customize: dataColorsCustomizer },
                legend: { display: true, position: 'right' },
                tooltip: {
                  enabled: true,
                  callbacks: {
                    label: (ctx: TooltipItem<'pie' | 'doughnut'> & { raw: ElasticFacetEntry }): string =>
                      `${ctx.raw.count} - ${ctx.raw.percentage}%`,
                  },
                },
              },
            }}
            data={{
              labels: genderBreakdowns?.map((l) => l.name) || [],
              datasets: [
                {
                  data: genderBreakdowns || [],
                  parsing: { key: 'count' },
                },
              ],
            }}
          />
        </Col>
        <ReactChart
          type="line"
          plugins={[chartDataLabels]}
          options={{
            responsive: true,
            aspectRatio: 6,
            parsing: { xAxisKey: 'name', yAxisKey: 'count' },
            plugins: {
              datalabels: {
                color: 'white',
                backgroundColor: (context): ChartColor => {
                  return context.dataset.borderColor as ChartColor;
                  // return odinChartColors[0].background as ChartColor;
                },
                borderRadius: 32,
                font: {
                  weight: 'bold',
                },
                display: 'auto',
                formatter: (value: ElasticFacetEntry): string => {
                  return value?.count?.toString() || '';
                },
              },
              tooltip: {
                enabled: true,
                callbacks: {
                  label: (ctx: TooltipItem<'line'> & { raw: ElasticFacetEntry }): string => `Workers: ${ctx.raw.count}`,
                },
              },
            },
            interaction: {
              intersect: false,
            },
            scales: {
              x: {
                grid: { display: false },
              },
              y: {
                beginAtZero: true,
                // ticks: {
                //   stepSize: 1,
                // },
              },
            },
          }}
          data={{
            labels: ageBreakdowns?.slice(1, 8)?.map((j) => j.name) || [],
            datasets: [
              {
                label: 'Age',
                tension: 0.4,
                data: ageBreakdowns?.slice(1, 8) || [],
                fill: true,
                backgroundColor: odinChartColors[0].border,
                borderColor: odinChartColors[0].background,
              },
            ],
          }}
        />
      </div>
    </div>
  );
}
