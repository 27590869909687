import React, { ReactElement, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import {
  EllipsisDropdownButton,
  Field,
  Modal,
  Button,
  DatePickerField,
  MenuItemProps,
  Select,
  SelectOptionElement,
} from '@odin-labs/components';
import { JobsiteWorkerOrientationStatus, useUpdateJobsiteWorkerMutation } from 'apollo/generated/client-operations';
import { skipStepReasons } from 'utils/constants';
import { JobsiteWorkerOnboardingFooterNavbarProps } from 'containers/workerOnboarding/types';
import { useBoolean } from 'utils';
import { getCurrentISOFormattedDateTime } from 'utils/dates';
import { useQuery } from '@apollo/client';
import { QueryGetAppEnvironmentResponse, GET_APP_ENVIRONMENT } from 'graphql/client/general';
import { SaveIcon } from 'components/icons';
import { Navbar } from 'components/reactstrap';
import { RouterPrompt } from 'components/routerPrompt';

export function JobsiteWorkerOnboardingFooterNavbar(props: JobsiteWorkerOnboardingFooterNavbarProps): ReactElement {
  const {
    onSave,
    isFormDirty,
    hideSkipOptions,
    hideSave,
    onResetComplete,
    onForceComplete,
    showConditionalPassExpiration = false,
    showExemptionReason = false,
    jobsiteWorker,
    hasNoWorkerCards,
  } = props;

  const showSkipOptions = !hideSkipOptions;
  const showSaveButton = !hideSave;

  const {
    value: isNoWorkerCardWarningModalOpen,
    setTrue: openNoWorkerCardWarningModal,
    setFalse: closeNoWorkerCardWarningModal,
  } = useBoolean(false);
  const [showForceCompleteModal, setShowForceCompleteModal] = useState<boolean>(false);
  const [forceCompleteModalReason, setShowForceCompleteModalReason] = useState<string>(null);
  const [forceCompleteModalReasonCustom, setShowForceCompleteModalReasonCustom] = useState<string>(null);
  const [expirationDate, setExpirationDate] = useState<Moment>(null);
  const [exemptionReason, setExemptionReason] = useState<string>('');
  const [showSkipButton, setShowSkipButton] = useState<boolean>(false);

  const [updateJobsiteWorker] = useUpdateJobsiteWorkerMutation({
    fetchPolicy: 'no-cache',
    refetchQueries: ['GetJobsiteWorker'],
  });

  const {
    data: { environment },
  } = useQuery<QueryGetAppEnvironmentResponse>(GET_APP_ENVIRONMENT);

  useEffect(() => {
    if (environment !== 'main-prd') {
      setShowSkipButton(true);
    }
  }, [environment]);

  const completeOrientation = (): void => {
    const currentDateTime = getCurrentISOFormattedDateTime();
    updateJobsiteWorker({
      variables: {
        jobsiteWorkerId: jobsiteWorker?.jobsiteWorkerId,
        jobsiteWorkerInput: {
          profileCompletedAt: currentDateTime,
          badgingCompletedAt: currentDateTime,
          passedMedicalExamAt: currentDateTime,
          onboardingSignedAt: currentDateTime,
          signatureCompletedAt: currentDateTime,
          siteSpecificTrainingCompletedAt: currentDateTime,
          documentsCompletedAt: currentDateTime,
          orientationStatus: JobsiteWorkerOrientationStatus.Complete,
        },
      },
    });
  };

  const closeModal = (): void => {
    setShowForceCompleteModal(false);
    setShowForceCompleteModalReason(null);
    setShowForceCompleteModalReasonCustom(null);
  };

  const getActionButtonEnabled = (): boolean => {
    return !!forceCompleteModalReason;
  };

  const getForceCompleteReasons = (): Array<SelectOptionElement> => {
    const options = skipStepReasons.map((skipStepReason) => {
      return {
        label: skipStepReason,
        value: skipStepReason,
      };
    });

    if (showExemptionReason) {
      options.splice(options.length - 1, 0, {
        label: 'Exempt from having to provide documents',
        value: 'Exempt from having to provide documents',
      });
    }

    return options;
  };

  const save = React.useCallback(async (): Promise<void | boolean> => {
    if (hasNoWorkerCards) {
      return openNoWorkerCardWarningModal();
    }
    return onSave();
  }, [hasNoWorkerCards, openNoWorkerCardWarningModal, onSave]);

  const menuItems: MenuItemProps[] = [
    {
      text: 'Force complete status',
      theme: 'secondary',
      onClick: (): void => setShowForceCompleteModal(true),
    },
    {
      text: 'Reset status',
      theme: 'secondary',
      onClick: onResetComplete,
    },
  ];

  const forceCompleteReasonsOptions = getForceCompleteReasons();

  return (
    <Navbar
      light
      className="worker-onboarding-footer !odin-z-auto md:!odin-left-44 xl:!odin-left-74"
      fixed="bottom"
      withoutBorder
    >
      <RouterPrompt when={isFormDirty} onConfirm={save} />
      <div className="odin-flex odin-grow odin-justify-end odin-items-center odin-gap-x-1.5 odin-pr-4">
        {showSkipButton && <Button text="Force skip orientation" onClick={completeOrientation} />}
        {showSkipOptions && <EllipsisDropdownButton size="xl" menuItems={menuItems} popupWidthFitContent />}
        {showSaveButton && <Button text="Save information" icon={SaveIcon} onClick={save} hideTextOnMobile />}
      </div>
      <Modal
        title="Warning"
        titleAlignment="center"
        text="Warning -- This worker doesn't have a badge number assigned to them. If you have printed a badge,
        you will need to enter the proximity card number before leaving this page otherwise the worker will
        not be able to enter the jobsite."
        textAlignment="center"
        open={isNoWorkerCardWarningModalOpen}
        setOpen={closeNoWorkerCardWarningModal}
        actionText="OK"
        // actionColor="warning"
        onAction={(): void => {
          closeNoWorkerCardWarningModal();
          onSave();
        }}
      />
      <Modal
        title="Force skip this step?"
        open={showForceCompleteModal}
        setOpen={closeModal}
        onAction={(): void => {
          onForceComplete(forceCompleteModalReasonCustom || forceCompleteModalReason, expirationDate, exemptionReason);
          closeModal();
        }}
        actionButtonEnabled={getActionButtonEnabled()}
      >
        <div className="odin-flex odin-flex-col odin-gap-y-6">
          <Select
            name="forceCompleteReason"
            onChange={(option): void => {
              setShowForceCompleteModalReason(option.value);
            }}
            value={forceCompleteReasonsOptions.find((opt) => opt.value === forceCompleteModalReason)}
            placeholder="Select one"
            options={forceCompleteReasonsOptions}
            label="Reason for skipping this step"
          />
          {showConditionalPassExpiration && forceCompleteModalReason === 'Temporarily allowing to skip step' ? (
            <DatePickerField
              minDate={moment()}
              value={expirationDate}
              name="expirationDate"
              label="Due date to provide documentation (optional)"
              onChange={setExpirationDate}
            />
          ) : null}
          {showConditionalPassExpiration && forceCompleteModalReason === 'Exempt from having to provide documents' ? (
            <Field
              name="exemptionReason"
              label="Reason for exempt status"
              value={exemptionReason}
              onChange={setExemptionReason}
            />
          ) : null}
          {forceCompleteModalReason === 'Other' ? (
            <Field
              name="force-custom-reason"
              label="Reason"
              value={forceCompleteModalReasonCustom}
              onChange={setShowForceCompleteModalReasonCustom}
              placeholder="Skip notes"
            />
          ) : null}
        </div>
      </Modal>
    </Navbar>
  );
}
