import { SelectOptionElement } from '@odin-labs/components';
import {
  JobsiteBadgeTemplate,
  WorkerCitizenshipStatus,
  WorkerCardFormat,
  CCureEnvironment,
  WorkerDocumentType,
} from 'apollo/generated/client-operations';
import { pascalToTrainCase } from 'utils';
import { getSelectOptions } from 'components/utils';
import { JobsiteModule, JobsiteOnboardingModule, JobsiteAppCheckInModule } from 'containers/worker/types';

export const getOnboardingModule = (modules: JobsiteModule[]): JobsiteOnboardingModule =>
  modules?.find((m): m is JobsiteOnboardingModule => {
    return m.__typename === 'JobsiteOnboardingModule'; // eslint-disable-line no-underscore-dangle
  });

export const getAppCheckInModule = (modules: JobsiteModule[]): JobsiteAppCheckInModule =>
  modules?.find((m): m is JobsiteAppCheckInModule => {
    return m.__typename === 'JobsiteAppCheckInModule'; // eslint-disable-line no-underscore-dangle
  });

export type WorkerCardFormatForOption = Pick<
  WorkerCardFormat,
  'workerCardFormatId' | 'name' | 'facilityCode' | 'cardType'
>;
export type WorkerCardFormatOption = SelectOptionElement & Pick<WorkerCardFormat, 'cardType'>;

export const getWorkerCardFormatOptions = (workerCardFormats: WorkerCardFormatForOption[]): WorkerCardFormatOption[] =>
  workerCardFormats?.map(({ workerCardFormatId, name, facilityCode, cardType }) => ({
    value: workerCardFormatId,
    label: [name, facilityCode].filter(Boolean).join(' - '),
    cardType,
  }));

export type CCureEnvironmentForOption = Pick<CCureEnvironment, 'cCureEnvironmentId' | 'namespace'>;

export const getCCureEnvironmentsOptions = (cCureEnvironments: CCureEnvironmentForOption[]): SelectOptionElement[] =>
  cCureEnvironments?.map(({ cCureEnvironmentId: value, namespace: label }) => ({ value, label }));

export type WorkerDocumentTypeForOption = Pick<WorkerDocumentType, 'workerDocumentTypeId' | 'name' | 'key' | 'isPhi'>;
export type WorkerDocumentTypeOption = SelectOptionElement & Pick<WorkerDocumentType, 'key' | 'isPhi'>;
export const getWorkerDocumentTypesOptions = (
  workerDocumentTypes: WorkerDocumentTypeForOption[],
): WorkerDocumentTypeOption[] =>
  workerDocumentTypes?.map(({ workerDocumentTypeId, name, key, isPhi }) => ({
    value: workerDocumentTypeId,
    label: name,
    key,
    isPhi,
  }));

export type JobsiteBadgeTemplateForOption = Pick<JobsiteBadgeTemplate, 'id' | 'isDefault' | 'badgeTemplate'>;
export type JobsiteBadgeTemplateOption = SelectOptionElement & Pick<JobsiteBadgeTemplate, 'isDefault'>;
export const getJobsiteBadgeTemplatesOptions = (
  jobsiteBadgeTemplates: JobsiteBadgeTemplateForOption[],
): JobsiteBadgeTemplateOption[] =>
  jobsiteBadgeTemplates?.map(({ isDefault, badgeTemplate }) => ({
    value: badgeTemplate.templateName,
    label: badgeTemplate.displayName,
    isDefault,
  }));

export const citizenshipStatusOptions = getSelectOptions({
  values: Object.values(WorkerCitizenshipStatus),
  labelGetter: pascalToTrainCase,
});
