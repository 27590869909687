import React from 'react';
import { useJobsiteCreateInvitationMutation } from 'apollo/generated/client-operations';
import { getGraphQLError } from 'utils/error';
import { AlertService } from 'components/alertService';
import { generateSelfOnboardingInvitationLink } from 'containers/selfOnboarding/utils';
import { useResettableState } from 'utils';

export type UseWorkerOnboardingLinkArgs = {
  jobsiteInvitationId: string;
  jobsiteContractorId: string;
};

type UseWorkerOnboardingLinkResult = {
  jobsiteInvitationId: string;
  onboardingLink: string;
  canGenerateOnboardingLink: boolean;
  generateOnboardingLink: () => void;
  isGeneratingOnboardingLink: boolean;
  resetOnboardingLink: () => void;
};

export function useWorkerOnboardingLink(props: UseWorkerOnboardingLinkArgs): UseWorkerOnboardingLinkResult {
  const { jobsiteInvitationId: initialJobsiteInvitationId, jobsiteContractorId } = props;

  const {
    value: jobsiteInvitationId,
    setValue: setJobsiteInvitationId,
    resetValue: resetJobsiteInvitationId,
  } = useResettableState(initialJobsiteInvitationId, null);

  React.useEffect(() => {
    setJobsiteInvitationId(initialJobsiteInvitationId);
  }, [initialJobsiteInvitationId]);

  const onboardingLink = React.useMemo(
    () => generateSelfOnboardingInvitationLink(jobsiteInvitationId),
    [jobsiteInvitationId],
  );

  const [jobsiteCreateInvitation, { loading: isCreatingOnboardingLink }] = useJobsiteCreateInvitationMutation({
    variables: { input: { jobsiteContractorId } },
    onCompleted: (result): void => {
      setJobsiteInvitationId(result.jobsiteCreateInvitation.jobsiteInvitation.jobsiteInvitationId);
      AlertService.alert('success', 'Success', 'Worker onboarding link generated');
    },
    onError: (error): void => {
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
    },
  });

  return {
    jobsiteInvitationId,
    onboardingLink,
    canGenerateOnboardingLink: !!jobsiteContractorId,
    generateOnboardingLink: jobsiteCreateInvitation,
    isGeneratingOnboardingLink: isCreatingOnboardingLink,
    resetOnboardingLink: resetJobsiteInvitationId,
  };
}
