import React, { ReactElement, useState } from 'react';
import cn from 'classnames';
import {
  JobsiteClearanceType,
  JobsiteWorkerClearanceAssignmentInput,
  useGetJobsiteDetailsQuery,
  useGetWorkerCardsQuery,
  WorkerCardType,
  useUpdateJobsiteWorkerMutation,
} from 'apollo/generated/client-operations';
import { AuthContext } from 'auth';
import { Card, CardBody, CardHeader, CardHeaderTitle } from 'components/reactstrap';
import { AlertService } from 'components/alertService';
import { Header } from 'components/header';
import { LoadingError } from 'components/loadingError';
import { JobsiteWorkerOnboardingFooterNavbar } from 'containers/workerOnboarding/navbar/JobsiteWorkerOnboardingFooterNavbar';
import { useWorkerData } from 'containers/worker/data/useWorkerData';
import { WorkerBadges } from 'containers/worker/tabs';
import { getCurrentISOFormattedDateTime } from 'utils/dates';
import { getGraphQLError } from 'utils/error';
import { ClearanceChecklist } from 'components/clearanceChecklist/ClearanceChecklist';
import { ClearanceSelection } from 'components/clearanceChecklist/types';
import { useQueryParams } from 'utils/useQueryParams';
import { OnboardingStepProps } from 'containers/workerOnboarding/types';
import { StepLoading } from './StepLoading';

const workerCardTypes = [WorkerCardType.Proximity, WorkerCardType.QrCode, WorkerCardType.FacialRecognition];

export function BadgingStep(props: OnboardingStepProps): ReactElement {
  const { loading: parentLoading, jobsiteWorkerId, jobsiteWorker } = props;
  const { currentUser: user } = React.useContext(AuthContext);
  const [fetching, setFetching] = useState<boolean>(false);
  const [clearanceSelectionData, setClearanceSelectionData] = useState<ClearanceSelection[]>([]);

  const workerId = jobsiteWorker?.contractorWorker?.worker?.workerId;
  const jobsiteId = jobsiteWorker?.jobsiteContractor?.jobsite?.jobsiteId;

  const urlSearchParams = useQueryParams();
  const skipACSSync = !!urlSearchParams.get('skipacssync');

  const {
    data: jobsiteData,
    loading: getJobsiteDetailsLoading,
    error: getJobsiteDetailsError,
  } = useGetJobsiteDetailsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      jobsiteId,
    },
    skip: !jobsiteId,
  });

  const {
    worker,
    loading: useWorkerDataLoading,
    error: useWorkerDataError,
    jobsiteWorkers,
    jobsiteNames,
    refetch,
  } = useWorkerData(workerId);

  const {
    data: getWorkerCardsData,
    loading: getWorkerCardsLoading,
    error: getWorkerCardsError,
  } = useGetWorkerCardsQuery({
    variables: {
      workerId,
      includeArchived: true,
    },
    fetchPolicy: 'network-only',
    skip: workerId == null,
  });

  const hasWorkerCards = !!getWorkerCardsData?.getWorkerCards.some(
    (wc) => wc.cardNumber && workerCardTypes.includes(wc.workerCardFormat.cardType),
  );
  const hasNoWorkerCards = !hasWorkerCards;
  const jobsite = jobsiteData?.getJobsite;
  const { clearanceAssignmentEnabled } = jobsite ?? {};

  const [updateJobsiteWorker] = useUpdateJobsiteWorkerMutation({
    onError: (error) => {
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
    },
    refetchQueries: ['GetJobsiteWorker'],
  });

  const error = getWorkerCardsError || useWorkerDataError || getJobsiteDetailsError;
  if (error) {
    return <LoadingError error={error} />;
  }

  const loading =
    parentLoading || getWorkerCardsLoading || useWorkerDataLoading || getJobsiteDetailsLoading || fetching;

  const markBadgeStepAsCompleted = async (): Promise<void> => {
    if (fetching) return;
    setFetching(true);

    await updateJobsiteWorker({
      variables: {
        jobsiteWorkerId,
        jobsiteWorkerInput: {
          badgingCompletedAt: getCurrentISOFormattedDateTime(),
          badgingSkipReason: null,
        },
      },
    });
    setFetching(false);
  };

  React.useEffect(() => {
    if (
      hasWorkerCards &&
      jobsiteWorker &&
      !jobsiteWorker.badgingCompletedAt && // not completed
      !jobsiteWorker.bannedReason && // not failed
      !jobsiteWorker.badgingSkipReason // not skipped
    ) {
      markBadgeStepAsCompleted();
    }
  }, [jobsiteWorker, hasWorkerCards]);

  const onClearanceAssignmentChange = async (selections: ClearanceSelection[]): Promise<void> => {
    if (fetching || !selections) return;
    setFetching(true);
    setClearanceSelectionData(selections);

    const clearanceAssignments =
      selections
        ?.filter((cs) => cs.selected)
        .map((cs) => {
          return {
            id: cs.clearanceAssignment?.id ?? undefined,
            clearanceId: cs.clearance.cCureClearanceId,
            jobsiteWorkerId,
            clearanceType: JobsiteClearanceType.CCure,
          } as JobsiteWorkerClearanceAssignmentInput;
        }) ?? [];
    try {
      await updateJobsiteWorker({
        variables: {
          jobsiteWorkerId,
          jobsiteWorkerInput: { clearanceAssignments },
          skipACSSync,
        },
      });
    } catch (err) {
      AlertService.alert('danger', 'Something went wrong updating clearances!', getGraphQLError(error));
    }
    setFetching(false);
  };

  React.useEffect(() => {
    if (jobsiteWorker && jobsite) {
      const clearanceAssignments = jobsiteWorker?.clearanceAssignments.edges.map(({ node }) => node) ?? [];
      const jobsiteClearances = jobsite?.cCureClearances?.edges.map(({ node }) => node) ?? [];
      const useJobsiteDefaults = clearanceAssignments.length < 1;
      const clearanceSelections = jobsiteClearances.map((clearance) => {
        const clearanceAssignment = clearanceAssignments.find(
          (ca) => ca.clearance?.cCureClearanceId === clearance.cCureClearanceId,
        );
        return {
          clearance,
          clearanceAssignment,
          ...(clearanceAssignment
            ? { selected: true }
            : { selected: useJobsiteDefaults ? clearance.isDefault : false }),
        } as ClearanceSelection;
      });
      setClearanceSelectionData(clearanceSelections);
    }
  }, [jobsiteWorker, jobsite]);

  return (
    <>
      <div className="odin-relative">
        <fieldset disabled={user.isContractor}>
          <Header title="Worker badging" />
          <StepLoading loading={loading} />
          <div className={cn('odin-mb-6', !clearanceAssignmentEnabled && 'odin-hidden')}>
            <Card>
              <CardHeader>
                <CardHeaderTitle as="h3">Clearance Assignments</CardHeaderTitle>
              </CardHeader>
              <CardBody>
                <ClearanceChecklist
                  value={clearanceSelectionData}
                  onChange={onClearanceAssignmentChange}
                  clearanceEditingEnabled
                  hideLegend
                />
              </CardBody>
            </Card>
          </div>
          <WorkerBadges
            worker={worker}
            refetchWorkerData={refetch}
            jobsiteWorkers={jobsiteWorkers}
            jobsiteNames={jobsiteNames}
            showJobsiteAccessHistory={false}
            isLocked={false}
          />
        </fieldset>
      </div>
      <JobsiteWorkerOnboardingFooterNavbar
        jobsiteWorker={jobsiteWorker}
        isFormDirty={false}
        hasNoWorkerCards={hasNoWorkerCards}
        onSave={markBadgeStepAsCompleted}
        hideSkipOptions
        hideLockSession
        hideSave={user.isContractor}
      />
    </>
  );
}
