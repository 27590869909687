import { SelfOnboardingStepKey } from 'graphql/types/enums';
import { DocumentKey } from 'graphql/types/enums';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** Represents a date object with or without time */
  Date: { input: Date | string; output: string };
  /**
   * A Duration object represents a period of time, like '2 months' or '1 day, 1 hour'.
   * Represented in ISO 8601 duration format
   * https://www.digi.com/resources/documentation/digidocs//90001488-13/reference/r_iso_8601_duration_format.htm
   */
  Duration: { input: string; output: string };
  JSON: { input: any; output: any };
  JSONObject: { input: any; output: any };
  Upload: { input: Blob | File; output: Blob | File };
};

export type AclGrant = {
  __typename?: 'ACLGrant';
  action: Action;
  attributes: Array<Scalars['String']['output']>;
  possession: Possession;
};

export type AclObject = {
  __typename?: 'ACLObject';
  grants: Array<AclGrant>;
  objectName: ObjectName;
};

export type AclRole = {
  __typename?: 'ACLRole';
  objects: Array<AclObject>;
  role: UserRole;
};

export enum Action {
  Create = 'create',
  Delete = 'delete',
  Read = 'read',
  Update = 'update',
}

export type AdditionalFieldInput = {
  key: Scalars['String']['input'];
  name: Scalars['String']['input'];
  type: DocumentAdditionalFieldType;
};

export type AdditionalFieldValue = {
  __typename?: 'AdditionalFieldValue';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type AdditionalFieldValueInput = {
  key: Scalars['String']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['String']['input'];
};

export type AdditionalWorkerProfileData = {
  __typename?: 'AdditionalWorkerProfileData';
  cloudStorageBucket?: Maybe<Scalars['String']['output']>;
  cloudStorageUri?: Maybe<Scalars['String']['output']>;
  croppedCloudStorageBucket?: Maybe<Scalars['String']['output']>;
  croppedCloudStorageUri?: Maybe<Scalars['String']['output']>;
  croppedDownloadKey?: Maybe<Scalars['String']['output']>;
  downloadKey?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['Date']['output']>;
  orientationDate?: Maybe<Scalars['Date']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  quickCode?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['Date']['output']>;
  stickerNumber?: Maybe<Scalars['String']['output']>;
  userAccountId?: Maybe<Scalars['String']['output']>;
  workerId?: Maybe<Scalars['String']['output']>;
};

export enum AnnouncementStatus {
  Archived = 'Archived',
  Draft = 'Draft',
  Processing = 'Processing',
  Scheduled = 'Scheduled',
  Sent = 'Sent',
}

export enum AnnouncementType {
  Email = 'Email',
  Sms = 'Sms',
}

export enum AppErrorCode {
  AlcatrazImageRejected = 'ALCATRAZ_IMAGE_REJECTED',
  AlcatrazProfileAlreadyAssociated = 'ALCATRAZ_PROFILE_ALREADY_ASSOCIATED',
  AlcatrazProfileConflict = 'ALCATRAZ_PROFILE_CONFLICT',
  ArchivedEntity = 'ARCHIVED_ENTITY',
  BadInput = 'BAD_INPUT',
  HealthSurveyAlreadySubmitted = 'HEALTH_SURVEY_ALREADY_SUBMITTED',
  InternalServerError = 'INTERNAL_SERVER_ERROR',
  JobsiteAlreadyExists = 'JOBSITE_ALREADY_EXISTS',
  JobsiteAreaHasSwipes = 'JOBSITE_AREA_HAS_SWIPES',
  JobsiteContractorAlreadyExists = 'JOBSITE_CONTRACTOR_ALREADY_EXISTS',
  JobsiteMemberAlreadyExists = 'JOBSITE_MEMBER_ALREADY_EXISTS',
  JobsiteWorkerAlreadyHasSwipes = 'JOBSITE_WORKER_ALREADY_HAS_SWIPES',
  JobsiteWorkerOrientationIsNotSelfOnboarding = 'JOBSITE_WORKER_ORIENTATION_IS_NOT_SELF_ONBOARDING',
  MultipleResourcesFound = 'MULTIPLE_RESOURCES_FOUND',
  NoPhoneMatch = 'NO_PHONE_MATCH',
  OrganizationAlreadyExists = 'ORGANIZATION_ALREADY_EXISTS',
  RateLimitExceeded = 'RATE_LIMIT_EXCEEDED',
  ResourceAlreadyExists = 'RESOURCE_ALREADY_EXISTS',
  ResourceExpiredError = 'RESOURCE_EXPIRED_ERROR',
  ResourceNotFound = 'RESOURCE_NOT_FOUND',
  SsnAlreadyExists = 'SSN_ALREADY_EXISTS',
  StartPasswordlessLogin = 'START_PASSWORDLESS_LOGIN',
  StartPhoneNumberVerification = 'START_PHONE_NUMBER_VERIFICATION',
  UnlockWorkerProfileDataNoMatch = 'UNLOCK_WORKER_PROFILE_DATA_NO_MATCH',
  UserAlreadyAuthenticatedWithEmail = 'USER_ALREADY_AUTHENTICATED_WITH_EMAIL',
  UserAlreadyAuthenticatedWithPhone = 'USER_ALREADY_AUTHENTICATED_WITH_PHONE',
  WorkerAlreadyExists = 'WORKER_ALREADY_EXISTS',
  WorkerCardAlreadyExists = 'WORKER_CARD_ALREADY_EXISTS',
  WorkerCardFormatDefaultNotFound = 'WORKER_CARD_FORMAT_DEFAULT_NOT_FOUND',
  WorkerCardFormatInvalidForJobsite = 'WORKER_CARD_FORMAT_INVALID_FOR_JOBSITE',
  WorkerNotAllowedOnSite = 'WORKER_NOT_ALLOWED_ON_SITE',
  WorkerNotAssignedToSite = 'WORKER_NOT_ASSIGNED_TO_SITE',
  WorkerNotOnSite = 'WORKER_NOT_ON_SITE',
}

export type ArchiveJobsiteWorkerAccessEventInput = {
  accessEventId: Scalars['ID']['input'];
  jobsiteWorkerCheckInId?: InputMaybe<Scalars['ID']['input']>;
};

export type ArchiveJobsiteWorkerAccessEventResponse = {
  __typename?: 'ArchiveJobsiteWorkerAccessEventResponse';
  accessEventId: Scalars['ID']['output'];
  success: Success;
};

export type AssignContractorWorkerToJobsiteInput = {
  /** ID of the contractor to assign the worker to */
  contractorId: Scalars['ID']['input'];
  /**
   * If this flag is 'true' and the worker is already assigned to the specified jobsite and contractor,
   * then the assignment will not be performed and the returned payload will contain isAlreadyAssigned flag as 'true'
   * and the existing jobsite.
   */
  ignoreIfAlreadyAssigned?: InputMaybe<Scalars['Boolean']['input']>;
  /** ID of the jobsite to assign the worker to */
  jobsiteId: Scalars['ID']['input'];
  /** Input data for the worker */
  jobsiteWorkerInput: JobsiteWorkerInput;
  /** ID of the worker that should be assigned to the jobsite and contractor */
  workerId: Scalars['ID']['input'];
};

export type AssignContractorWorkerToJobsiteResponse = {
  __typename?: 'AssignContractorWorkerToJobsiteResponse';
  isAlreadyAssigned?: Maybe<Scalars['Boolean']['output']>;
  jobsiteWorker: JobsiteWorker;
};

/** Object that holds the current session and user based on the provided session token and/or credentials */
export type Auth = {
  __typename?: 'Auth';
  aclRoles: Array<AclRole>;
  /** The current session based on provided session token and/or credentials */
  session: UserSession;
  /** The current user based on provided session token and/or credentials */
  user: User;
};

/**
 * Indicates whether the authentication is provded via a cookie or bearer token. Only cookie is supported at this time,
 * but bearer may be added when we support native apps.
 */
export enum AuthType {
  Bearer = 'BEARER',
  Cookie = 'COOKIE',
}

export type AuthenticateLimitedTokenDetailsInput = {
  birthDate: Scalars['String']['input'];
  workerId: Scalars['ID']['input'];
};

export type AuthenticateLimitedTokenDetailsResponse = {
  __typename?: 'AuthenticateLimitedTokenDetailsResponse';
  token?: Maybe<Scalars['String']['output']>;
};

export type BadgeTemplate = {
  __typename?: 'BadgeTemplate';
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  templateName: Scalars['String']['output'];
};

export type BreakdownStat = {
  __typename?: 'BreakdownStat';
  compliantCount?: Maybe<Scalars['Float']['output']>;
  count: Scalars['Float']['output'];
  name?: Maybe<Scalars['String']['output']>;
  percentage?: Maybe<Scalars['Float']['output']>;
};

export type BulkAssignJobsiteWorkerInput = {
  contractorId: Scalars['ID']['input'];
  /** Update values */
  jobsiteWorkerInput: JobsiteWorkerInput;
  /** ID of the worker to update */
  workerId: Scalars['ID']['input'];
};

export type BulkJobsiteWorkerInput = {
  /** ID of the worker to update */
  jobsiteWorkerId: Scalars['ID']['input'];
  /** Update values */
  jobsiteWorkerInput: JobsiteWorkerInput;
};

export type CCureClearance = {
  __typename?: 'CCureClearance';
  cCureClearanceId: Scalars['ID']['output'];
  cCureClearanceObjectId: Scalars['Int']['output'];
  cCureEnvironment: CCureEnvironment;
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  isSelectable?: Maybe<Scalars['Boolean']['output']>;
  jobsite: Jobsite;
  name?: Maybe<Scalars['String']['output']>;
  objectHistory: ObjectHistory;
};

export type CCureEntityMapping = {
  __typename?: 'CCureEntityMapping';
  cCureEnvironment: CCureEnvironment;
  entityType: CCureEntityType;
  name?: Maybe<Scalars['String']['output']>;
  objectId: Scalars['Int']['output'];
};

export enum CCureEntityType {
  Clearance = 'Clearance',
  Controller = 'Controller',
  Door = 'Door',
  InArea = 'InArea',
  OutArea = 'OutArea',
}

export type CCureEnvironment = {
  __typename?: 'CCureEnvironment';
  cCureEnvironmentId: Scalars['ID']['output'];
  instanceSlug: Scalars['String']['output'];
  namespace: Scalars['String']['output'];
  partitionId: Scalars['Int']['output'];
};

export enum CCurePersonnelStatus {
  Active = 'Active',
  Disabled = 'Disabled',
}

export type CardPrintJob = {
  __typename?: 'CardPrintJob';
  cardPrintJobId: Scalars['ID']['output'];
  completion?: Maybe<CardPrintJobCompletion>;
  error?: Maybe<CardPrintJobError>;
  hidPrintJobId: Scalars['ID']['output'];
  jobsite: Jobsite;
  startedAt: Scalars['Date']['output'];
  status: CardPrintJobStatus;
  statusMessage: Scalars['String']['output'];
  worker: Worker;
  workerCard?: Maybe<WorkerCard>;
};

export type CardPrintJobCompletion = {
  __typename?: 'CardPrintJobCompletion';
  cardNumber?: Maybe<Scalars['String']['output']>;
  completedAt: Scalars['Date']['output'];
  facilityCode?: Maybe<Scalars['String']['output']>;
};

export type CardPrintJobError = {
  __typename?: 'CardPrintJobError';
  code: Scalars['Int']['output'];
  erroredAt: Scalars['Date']['output'];
  message: Scalars['String']['output'];
};

export enum CardPrintJobStatus {
  Deleted = 'Deleted',
  Failed = 'Failed',
  Printed = 'Printed',
  Submitted = 'Submitted',
}

export enum CardStatus {
  Active = 'Active',
  Failed = 'Failed',
  Inactive = 'Inactive',
  SentToWorker = 'SentToWorker',
}

export type ChangeContractorForJobsiteWorkersError = {
  __typename?: 'ChangeContractorForJobsiteWorkersError';
  errorMessage?: Maybe<Scalars['String']['output']>;
};

export type ChangeContractorForJobsiteWorkersInput = {
  /** IDs of the jobsite workers to update */
  jobsiteWorkerIds: Array<Scalars['ID']['input']>;
  /** Contractor ID to switch these jobsite workers to */
  newContractorId: Scalars['ID']['input'];
  /** Start Date for this these jobsite workers being updated */
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type ChangeContractorForJobsiteWorkersResponse = {
  __typename?: 'ChangeContractorForJobsiteWorkersResponse';
  changeContractorForJobsiteWorkersError: Array<Maybe<ChangeContractorForJobsiteWorkersError>>;
  changeContractorForJobsiteWorkersSuccess: Array<Maybe<ChangeContractorForJobsiteWorkersSuccess>>;
};

export type ChangeContractorForJobsiteWorkersSuccess = {
  __typename?: 'ChangeContractorForJobsiteWorkersSuccess';
  jobsiteWorker: JobsiteWorker;
};

export type ChangeJobsiteWorkersJobsiteAssignmentInput = {
  contractorId?: InputMaybe<Scalars['ID']['input']>;
  jobsiteId: Scalars['ID']['input'];
  jobsiteWorkerIds: Array<Scalars['ID']['input']>;
};

export type ChangeJobsiteWorkersJobsiteAssignmentResponse = {
  __typename?: 'ChangeJobsiteWorkersJobsiteAssignmentResponse';
  edges: Array<JobsiteWorker>;
  totalCount: Scalars['Int']['output'];
};

export enum ChangeType {
  Created = 'created',
  Removed = 'removed',
  Updated = 'updated',
}

export type CheckInJobsiteWorkersInput = {
  checkInAreaId: Scalars['ID']['input'];
  checkInDateTime?: InputMaybe<Scalars['Date']['input']>;
  checkInDirection: JobsiteWorkerCheckInDirection;
  checkInReason?: InputMaybe<Scalars['String']['input']>;
  checkInType: JobsiteWorkerCheckInType;
  jobsiteId: Scalars['ID']['input'];
  jobsiteWorkerIds: Array<Scalars['ID']['input']>;
  location?: InputMaybe<GpsCoordinateInput>;
};

export type CompletePasswordlessLoginInput = {
  loginCode: Scalars['String']['input'];
  loginId: Scalars['String']['input'];
};

export type CompletePhoneNumberVerificationAndSignInInput = {
  contractorId: Scalars['ID']['input'];
  jobsiteId: Scalars['ID']['input'];
  /**
   * The ID of the existing jobsite worker to complete the verification for.
   * It is required when 'selfOnboardingAction' is 'ResumeOnboarding'.
   */
  jobsiteWorkerId?: InputMaybe<Scalars['ID']['input']>;
  /**
   * When this is 'StartNewOnboarding', a new jobsite worker will be created
   * based on the provided 'workerId', 'jobsiteId', and 'contractorId'.
   */
  selfOnboardingAction: SelfOnboardingWorkerAction;
  verificationCode: Scalars['String']['input'];
  workerId: Scalars['ID']['input'];
};

export type CompletePhoneNumberVerificationAndSignInResponse = {
  __typename?: 'CompletePhoneNumberVerificationAndSignInResponse';
  auth: Auth;
  jobsiteWorker: JobsiteWorker;
  success: Scalars['Boolean']['output'];
};

export type CompletePhoneNumberVerificationInput = {
  verificationCode: Scalars['String']['input'];
  workerId: Scalars['ID']['input'];
};

export type CompletePhoneNumberVerificationResponse = {
  __typename?: 'CompletePhoneNumberVerificationResponse';
  success: Scalars['Boolean']['output'];
  worker?: Maybe<Worker>;
};

export type ContactInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ContactPerson = {
  __typename?: 'ContactPerson';
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type Contractor = {
  __typename?: 'Contractor';
  billingContact?: Maybe<ContactPerson>;
  /** Unique identifier for the contractor */
  contractorId: Scalars['ID']['output'];
  contractorMembers: ContractorContractorMembersConnection;
  contractorWorkers: ContractorContractorWorkersConnection;
  description?: Maybe<Scalars['String']['output']>;
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  isDefaultContractor?: Maybe<Scalars['Boolean']['output']>;
  jobsiteContractors: ContractorJobsiteContractorsConnection;
  /** History of the object */
  objectHistory: ObjectHistory;
  /** Full organization object */
  organization: Organization;
  stripeMappingDetails?: Maybe<StripeMappingData>;
  stripePaymentMethods?: Maybe<Array<StripePaymentMethod>>;
  websiteUrl?: Maybe<Scalars['String']['output']>;
};

export type ContractorContractorMembersArgs = {
  input?: InputMaybe<ContractorContractorMembersInput>;
};

export type ContractorContractorWorkersArgs = {
  input?: InputMaybe<ContractorContractorWorkersInput>;
};

export type ContractorJobsiteContractorsArgs = {
  input?: InputMaybe<ContractorJobsiteContractorsInput>;
};

export type ContractorAddJobsitesInput = {
  contractorId: Scalars['ID']['input'];
  jobsiteContractorInput: JobsiteContractorInput;
  jobsiteIds: Array<Scalars['ID']['input']>;
};

export type ContractorAddJobsitesPayload = {
  __typename?: 'ContractorAddJobsitesPayload';
  jobsiteContractors: Array<JobsiteContractor>;
};

export type ContractorArchiveInput = {
  contractorId: Scalars['ID']['input'];
};

export type ContractorArchivePayload = {
  __typename?: 'ContractorArchivePayload';
  success: Success;
};

export type ContractorContractorMembersConnection = {
  __typename?: 'ContractorContractorMembersConnection';
  count: Scalars['Int']['output'];
  edges: Array<ContractorContractorMembersEdge>;
};

export type ContractorContractorMembersEdge = {
  __typename?: 'ContractorContractorMembersEdge';
  node: ContractorMember;
};

export type ContractorContractorMembersInput = {
  orderBy?: InputMaybe<Array<InputMaybe<GetUsersOrderBy>>>;
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type ContractorContractorWorkersConnection = {
  __typename?: 'ContractorContractorWorkersConnection';
  count: Scalars['Int']['output'];
  edges: Array<ContractorContractorWorkersEdge>;
};

export type ContractorContractorWorkersEdge = {
  __typename?: 'ContractorContractorWorkersEdge';
  node: ContractorWorker;
};

export type ContractorContractorWorkersInput = {
  jobsiteIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type ContractorCreateInput = {
  billingContact?: InputMaybe<ContactInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** Must provide either organizationId or organizationCreateInput but not both */
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  organizationInput?: InputMaybe<OrganizationInput>;
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
};

export type ContractorCreatePayload = {
  __typename?: 'ContractorCreatePayload';
  contractor: Contractor;
};

/** Input for Contractor Mutations */
export type ContractorInput = {
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  organizationInput?: InputMaybe<OrganizationInput>;
};

export type ContractorJobsiteContractorsConnection = {
  __typename?: 'ContractorJobsiteContractorsConnection';
  count: Scalars['Int']['output'];
  edges: Array<ContractorJobsiteContractorsEdge>;
};

export type ContractorJobsiteContractorsEdge = {
  __typename?: 'ContractorJobsiteContractorsEdge';
  node: JobsiteContractor;
};

export type ContractorJobsiteContractorsInput = {
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
};

/**
 * ContractorMember return type. A member is any non-worker who is employed by the contractor, such as an owner,
 * manager, or back office employee.
 */
export type ContractorMember = {
  __typename?: 'ContractorMember';
  /** The full contractor object that the member is at */
  contractor: Contractor;
  id: Scalars['ID']['output'];
  /** History of the object */
  objectHistory: ObjectHistory;
  /** Title of the member at the contractor. */
  title?: Maybe<Scalars['String']['output']>;
  /** The full user object of the member */
  user: User;
};

export type ContractorRemoveWorkerInput = {
  contractorWorkerId: Scalars['ID']['input'];
};

export type ContractorRemoveWorkerPayload = {
  __typename?: 'ContractorRemoveWorkerPayload';
  success: Success;
};

export type ContractorUpdateInput = {
  billingContact?: InputMaybe<ContactInput>;
  contractorId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  organizationInput: OrganizationInput;
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
};

export type ContractorUpdatePayload = {
  __typename?: 'ContractorUpdatePayload';
  contractor: Contractor;
};

/**
 * ContractorWorker return type. A worker is any non-worker who is employed by the contractor, such as an owner,
 * manager, or back office employee.
 */
export type ContractorWorker = {
  __typename?: 'ContractorWorker';
  /** The full contractor object that the worker is at */
  contractor: Contractor;
  /** Date the worker ended at the contractor */
  endDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  jobsiteWorkers: ContractorWorkerJobsiteWorkersConnection;
  /** History of the object */
  objectHistory: ObjectHistory;
  /** Payroll ID of the worker at the contractor */
  payrollId?: Maybe<Scalars['ID']['output']>;
  /** Date the worker started at the contractor */
  startDate?: Maybe<Scalars['Date']['output']>;
  /** The full worker object */
  worker: Worker;
};

/**
 * ContractorWorker return type. A worker is any non-worker who is employed by the contractor, such as an owner,
 * manager, or back office employee.
 */
export type ContractorWorkerJobsiteWorkersArgs = {
  input?: InputMaybe<ContractorWorkerJobsiteWorkersInput>;
};

export type ContractorWorkerConnection = {
  __typename?: 'ContractorWorkerConnection';
  contractorWorkerConnectionId?: Maybe<Scalars['String']['output']>;
  edges: Array<ContractorWorker>;
  facets?: Maybe<ContractorWorkerConnectionFacets>;
  totalCount: Scalars['Int']['output'];
};

export type ContractorWorkerConnectionFacets = {
  __typename?: 'ContractorWorkerConnectionFacets';
  jobTitles?: Maybe<Array<Maybe<FacetCount>>>;
  trades?: Maybe<Array<Maybe<FacetCount>>>;
};

export type ContractorWorkerInput = {
  /** Worker end date */
  endDate?: InputMaybe<Scalars['Date']['input']>;
  /** Worker's payroll ID */
  payrollId?: InputMaybe<Scalars['ID']['input']>;
  /** Worker start date */
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type ContractorWorkerJobsiteWorkersConnection = {
  __typename?: 'ContractorWorkerJobsiteWorkersConnection';
  count: Scalars['Int']['output'];
  edges: Array<ContractorWorkerJobsiteWorkersEdge>;
};

export type ContractorWorkerJobsiteWorkersEdge = {
  __typename?: 'ContractorWorkerJobsiteWorkersEdge';
  node: JobsiteWorker;
};

export type ContractorWorkerJobsiteWorkersInput = {
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
  jobsiteIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
};

/** Input for creating a new contractor */
export type CreateContractorInput = {
  createUserInput?: InputMaybe<CreateUserInput>;
  organizationInput: OrganizationInput;
};

export type CreateContractorStripeInvoiceInput = {
  contractorId: Scalars['ID']['input'];
  jobsiteContractorId: Scalars['ID']['input'];
  paymentMethodId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateContractorStripeInvoicePayload = {
  __typename?: 'CreateContractorStripeInvoicePayload';
  customerId: Scalars['String']['output'];
  invoiceId: Scalars['String']['output'];
  status?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type CreateContractorWorkerInput = {
  /** Birthdate of worker */
  birthDate: Scalars['Date']['input'];
  /** Contractor ID to assign worker to */
  contractorId: Scalars['ID']['input'];
  contractorWorkerInput?: InputMaybe<ContractorWorkerInput>;
  createdMethod?: InputMaybe<WorkerCreatedMethod>;
  email?: InputMaybe<Scalars['String']['input']>;
  /** First name of worker */
  firstName: Scalars['String']['input'];
  /** Force creation of new worker if none is found */
  forceCreate: Scalars['Boolean']['input'];
  /** Last name of worker */
  lastName: Scalars['String']['input'];
  middleInitial?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** Last four digits of worker's SSN */
  ssnLastFour?: InputMaybe<Scalars['String']['input']>;
  suffix?: InputMaybe<Scalars['String']['input']>;
  /** Optional User Account ID to use when creating a worker. Required when forceCreate is true */
  userAccountId?: InputMaybe<Scalars['ID']['input']>;
  /** Optional worker public ID to use when assigning worker to contractor */
  workerId?: InputMaybe<Scalars['ID']['input']>;
};

/** Input for creating a new worker that does not already have an account */
export type CreateJobsiteWorkerInput = {
  /** Input fields for creating a new user account */
  createUserInput: CreateUserInput;
  /** Input fields for creating a new jobsite worker */
  jobsiteWorkerInput: JobsiteWorkerInput;
};

/**
 * Do not remove for now. Even though it's not used by a query or mutation,
 * it is used by 'queryLookerForJobsiteWorkerReports' helper function.
 */
export type CreateLookerJobsiteWorkerReportsResponse = {
  __typename?: 'CreateLookerJobsiteWorkerReportsResponse';
  activeBreakdowns?: Maybe<Array<ElasticFacetEntry>>;
  compliantBreakdowns?: Maybe<Array<ElasticFacetEntry>>;
  contractorBreakdowns: Array<ElasticFacetEntry>;
  dailyBreakdowns?: Maybe<Array<ElasticFacetEntry>>;
  jobsiteBreakdowns?: Maybe<Array<ElasticFacetEntry>>;
  jobsites: Array<Jobsite>;
  overviewBreakdowns?: Maybe<Array<ElasticFacetEntry>>;
  results: Array<ILookerWorkerSessionData>;
  totalCount?: Maybe<Scalars['Int']['output']>;
  tradeBreakdowns: Array<ElasticFacetEntry>;
  tradeClassBreakdowns?: Maybe<Array<ElasticFacetEntry>>;
};

export type CreatePayrollDocumentInput = {
  contractorId: Scalars['ID']['input'];
  jobsiteId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  payrollDocumentTypeId: Scalars['ID']['input'];
  payrollEndDate?: InputMaybe<Scalars['Date']['input']>;
  payrollStartDate?: InputMaybe<Scalars['Date']['input']>;
};

export type CreatePayrollDocumentVersionInput = {
  fileId: Scalars['ID']['input'];
  submittedAt: Scalars['Date']['input'];
};

/** Input type for creating a new user account for another user */
export type CreateUserInput = {
  /** Email of the new user */
  email?: InputMaybe<Scalars['String']['input']>;
  /** First name of the new user */
  firstName: Scalars['String']['input'];
  /** Last name of the new user */
  lastName: Scalars['String']['input'];
  /** Phone number of the new user */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

/** Input for creating a worker that does not already have a user account */
export type CreateWorkerInput = {
  /** Input fields for creating a new user account */
  createUserInput: CreateUserInput;
  /** Input fields for creating a new worker */
  workerInput: WorkerInput;
};

export type DashboardJobsiteWorkerSession = {
  __typename?: 'DashboardJobsiteWorkerSession';
  contractorOrganizationName?: Maybe<Scalars['String']['output']>;
  croppedDownloadKey?: Maybe<Scalars['String']['output']>;
  jobsiteName: Scalars['String']['output'];
  jobsiteWorkerJobsiteWorkerId: Scalars['String']['output'];
  jobsiteWorkerStatus?: Maybe<Scalars['String']['output']>;
  profile?: Maybe<Scalars['String']['output']>;
  publicUrl?: Maybe<Scalars['String']['output']>;
  swipeSessionsCompliant?: Maybe<Scalars['String']['output']>;
  swipeSessionsDisplayValueSpanHours?: Maybe<Scalars['String']['output']>;
  swipeSessionsFirstInboundSwipeTimeOfDay?: Maybe<Scalars['String']['output']>;
  swipeSessionsJobsiteWorkerId: Scalars['String']['output'];
  swipeSessionsLastOutboundSwipeTimeOfDay?: Maybe<Scalars['String']['output']>;
  swipeSessionsNetTotalSpanHours?: Maybe<Scalars['String']['output']>;
  swipeSessionsOvernightSession?: Maybe<Scalars['String']['output']>;
  swipeSessionsReads?: Maybe<Scalars['String']['output']>;
  swipeSessionsReadsInbound?: Maybe<Scalars['String']['output']>;
  swipeSessionsReadsOutbound?: Maybe<Scalars['String']['output']>;
  swipeSessionsSessionDate: Scalars['String']['output'];
  swipeSessionsSessionId: Scalars['String']['output'];
  userIdentityUserFullname: Scalars['String']['output'];
  workerJobTitle?: Maybe<Scalars['String']['output']>;
  workerProfilePicturesViewCroppedCloudStorageBucket?: Maybe<Scalars['String']['output']>;
  workerProfilePicturesViewCroppedCloudStorageUri?: Maybe<Scalars['String']['output']>;
  workerProfilePicturesViewCroppedDownloadKey?: Maybe<Scalars['String']['output']>;
  workerQuickCode?: Maybe<Scalars['String']['output']>;
  workerTrade?: Maybe<Scalars['String']['output']>;
  workerUnionAffiliation?: Maybe<Scalars['String']['output']>;
  workerWorkerId: Scalars['String']['output'];
};

export type DataExtractionInput = {
  extractionType: ExtractionProcessorType;
  fileId: Scalars['ID']['input'];
};

/**
 * Developer return type. A developer represents a real estate development company, as opposed to an individual working
 * either for or at the developer.
 */
export type Developer = {
  __typename?: 'Developer';
  /** Unique identifier for the developer */
  developerId: Scalars['ID']['output'];
  developerMembers: DeveloperDeveloperMembersConnection;
  jobsites: DeveloperJobsitesConnection;
  name: Scalars['String']['output'];
  /** History of the object */
  objectHistory: ObjectHistory;
  /** Full organization object */
  organization: Organization;
  stripeMappingDetails?: Maybe<StripeMappingData>;
  stripePaymentMethods?: Maybe<Array<StripePaymentMethod>>;
};

/**
 * Developer return type. A developer represents a real estate development company, as opposed to an individual working
 * either for or at the developer.
 */
export type DeveloperDeveloperMembersArgs = {
  input?: InputMaybe<DeveloperDeveloperMembersInput>;
};

/**
 * Developer return type. A developer represents a real estate development company, as opposed to an individual working
 * either for or at the developer.
 */
export type DeveloperJobsitesArgs = {
  input?: InputMaybe<DeveloperJobsitesInput>;
};

export type DeveloperCreateInput = {
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  organizationInput?: InputMaybe<OrganizationInput>;
};

export type DeveloperCreatePayload = {
  __typename?: 'DeveloperCreatePayload';
  developer: Developer;
};

export type DeveloperDeveloperMembersConnection = {
  __typename?: 'DeveloperDeveloperMembersConnection';
  count: Scalars['Int']['output'];
  edges: Array<DeveloperDeveloperMembersEdge>;
};

export type DeveloperDeveloperMembersEdge = {
  __typename?: 'DeveloperDeveloperMembersEdge';
  node: DeveloperMember;
};

export type DeveloperDeveloperMembersInput = {
  orderBy?: InputMaybe<Array<InputMaybe<GetUsersOrderBy>>>;
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type DeveloperJobsitesConnection = {
  __typename?: 'DeveloperJobsitesConnection';
  count: Scalars['Int']['output'];
  edges: Array<DeveloperJobsitesEdge>;
};

export type DeveloperJobsitesEdge = {
  __typename?: 'DeveloperJobsitesEdge';
  node: Jobsite;
};

export type DeveloperJobsitesInput = {
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
};

/**
 * DeveloperMember return type. A member is any non-worker who is employed by the developer, such as an owner,
 * manager, or back office employee.
 */
export type DeveloperMember = {
  __typename?: 'DeveloperMember';
  /** The full developer object that the member is at */
  developer: Developer;
  id: Scalars['ID']['output'];
  /** History of the object */
  objectHistory: ObjectHistory;
  /** Title of the member at the developer. */
  title?: Maybe<Scalars['String']['output']>;
  /** The full user object of the member */
  user: User;
};

export type DeveloperUpdateInput = {
  developerId: Scalars['ID']['input'];
  organizationInput?: InputMaybe<OrganizationInput>;
};

export type DeveloperUpdatePayload = {
  __typename?: 'DeveloperUpdatePayload';
  developer: Developer;
};

export enum DocumentAdditionalFieldType {
  Bool = 'bool',
  Date = 'date',
  DateTime = 'dateTime',
  Float = 'float',
  Int = 'int',
  String = 'string',
}

export { DocumentKey };

export type EditableFields = {
  __typename?: 'EditableFields';
  email: Scalars['Boolean']['output'];
  phone: Scalars['Boolean']['output'];
};

export type ElasticFacetEntry = {
  __typename?: 'ElasticFacetEntry';
  compliantCount?: Maybe<Scalars['Float']['output']>;
  count: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  percentage?: Maybe<Scalars['Float']['output']>;
};

/** Input for creating a worker if it doesn't exist and starting passwordless login */
export type EnsureWorkerAndStartPasswordlessLoginInput = {
  ensureWorkerInput?: InputMaybe<EnsureWorkerInput>;
  startPasswordlessLoginInput: StartPasswordlessLoginInput;
};

export type EnsureWorkerAndStartPasswordlessLoginResponse = {
  __typename?: 'EnsureWorkerAndStartPasswordlessLoginResponse';
  success: Scalars['Boolean']['output'];
  worker?: Maybe<WorkerAccount>;
};

/** Input for creating a worker if it doesn't exist. Either 'workerId' or 'createWorkerInput' should be specified */
export type EnsureWorkerInput = {
  /** Input fields for creating a new worker and user account. If 'workerId' is truthy no worker will be created */
  createWorkerInput?: InputMaybe<CreateWorkerInput>;
  workerId?: InputMaybe<Scalars['ID']['input']>;
};

export type EntityChange = {
  __typename?: 'EntityChange';
  apiDbHandler: Scalars['String']['output'];
  entityDbId?: Maybe<Scalars['Int']['output']>;
  entityId: Scalars['ID']['output'];
  entityUpdatedAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  objectHistory: ObjectHistory;
  tableName: Scalars['String']['output'];
  values?: Maybe<Scalars['JSONObject']['output']>;
};

export type EntityChangesEdge = {
  __typename?: 'EntityChangesEdge';
  node: EntityChange;
};

export enum ExternalIntegrationType {
  Avigilon = 'Avigilon',
  Procore = 'Procore',
  Stripe = 'Stripe',
}

export enum ExtractionProcessorType {
  IdCard = 'IDCard',
  OshaCard = 'OSHACard',
  SstCard = 'SSTCard',
}

export type FacetCount = {
  __typename?: 'FacetCount';
  count: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};

/** File return type. A file represents an actual file stored in the server along with its metadata. */
export type File = {
  __typename?: 'File';
  /** The url to actually download the image */
  downloadUrl: Scalars['String']['output'];
  /** Auto generated ID */
  fileId: Scalars['ID']['output'];
  /** Type of file -- image, video, etc. */
  fileType?: Maybe<FileType>;
  /** Specify if File can be downloaded as an attachment */
  isAttachment?: Maybe<Scalars['Boolean']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  /** Original name of the file */
  originalFileName?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  /** Timestamp of when the file was uploaded */
  uploadedAt: Scalars['Date']['output'];
};

export type FileChangeInput = {
  changeType: ChangeType;
  fileId?: InputMaybe<Scalars['ID']['input']>;
  fileInput?: InputMaybe<FileInput>;
};

/**
 * Because the downloadUrl in the File object requires authentication, the file server must make a request to the API
 * to check the user's ACL. If the ACL passes, this object is returned with a downloadUrl that the client should be
 * redirected to.
 */
export type FileDownloadRedirect = {
  __typename?: 'FileDownloadRedirect';
  downloadRedirectUrl: Scalars['String']['output'];
  file: File;
};

export type FileInput = {
  isPublic: Scalars['Boolean']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
  saveOriginalFileName?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  uploadData?: InputMaybe<Scalars['Upload']['input']>;
};

export enum FileType {
  Csv = 'CSV',
  Html = 'HTML',
  Image = 'IMAGE',
  Pdf = 'PDF',
  Video = 'VIDEO',
  Xlxs = 'XLXS',
}

export type FilterInput = {
  entityIds: Array<Scalars['ID']['input']>;
  tableName: Scalars['String']['input'];
};

export type FilterOption = {
  __typename?: 'FilterOption';
  count?: Maybe<Scalars['Int']['output']>;
  label: Scalars['String']['output'];
  value: Scalars['ID']['output'];
};

export type Form = {
  __typename?: 'Form';
  /** Json in a tree shape containing the form definition */
  content: Scalars['JSONObject']['output'];
  /** Unique identifier for the form */
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** History of the object */
  objectHistory: ObjectHistory;
};

export type FormArchiveInput = {
  id: Scalars['ID']['input'];
};

export type FormArchivePayload = {
  __typename?: 'FormArchivePayload';
  success: Success;
};

export type FormCreateInput = {
  content: Scalars['JSONObject']['input'];
  key: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type FormCreatePayload = {
  __typename?: 'FormCreatePayload';
  form: Form;
};

export type FormSubmissionsReportInput = {
  contractorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  endDate: Scalars['Date']['input'];
  formId: Scalars['ID']['input'];
  hasAttachments?: InputMaybe<Scalars['Boolean']['input']>;
  jobsiteIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  orderBy?: InputMaybe<Array<GetJobsiteFormSubmissionsOrderBy>>;
  startDate: Scalars['Date']['input'];
};

export type FormUpdateInput = {
  content?: InputMaybe<Scalars['JSONObject']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type FormUpdatePayload = {
  __typename?: 'FormUpdatePayload';
  form: Form;
};

export type GpsCoordinate = {
  __typename?: 'GPSCoordinate';
  lat: Scalars['Float']['output'];
  lng: Scalars['Float']['output'];
};

export type GpsCoordinateInput = {
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
};

export enum GeofencingEnforcementType {
  Hard = 'hard',
  None = 'none',
}

/** Response object for getAllContractors */
export type GetAllContractorsResponse = {
  __typename?: 'GetAllContractorsResponse';
  count: Scalars['Int']['output'];
  results: Array<Contractor>;
};

export type GetAllOrganizationsConnection = {
  __typename?: 'GetAllOrganizationsConnection';
  count: Scalars['Int']['output'];
  edges: Array<GetAllOrganizationsEdge>;
};

export type GetAllOrganizationsEdge = {
  __typename?: 'GetAllOrganizationsEdge';
  node?: Maybe<Organization>;
};

export type GetAllOrganizationsInput = {
  paginationInput: LimitOffsetPaginationInput;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type GetContractorMemberByMemberAndContractorInput = {
  contractorId: Scalars['ID']['input'];
  memberUserAccountId: Scalars['ID']['input'];
};

export type GetContractorMemberInput = {
  contractorMemberId?: InputMaybe<Scalars['ID']['input']>;
  memberAndContractor?: InputMaybe<GetContractorMemberByMemberAndContractorInput>;
};

export type GetDeveloperMemberByMemberAndDeveloperInput = {
  developerId: Scalars['ID']['input'];
  memberUserAccountId: Scalars['ID']['input'];
};

export type GetDeveloperMemberInput = {
  developerMemberId?: InputMaybe<Scalars['ID']['input']>;
  memberAndDeveloper?: InputMaybe<GetDeveloperMemberByMemberAndDeveloperInput>;
};

export type GetEntityChangesInput = {
  filters: Array<FilterInput>;
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
};

export type GetEntityChangesResult = {
  __typename?: 'GetEntityChangesResult';
  count: Scalars['Int']['output'];
  edges: Array<EntityChangesEdge>;
};

export type GetJobsiteAnnouncementsInput = {
  announcementType?: InputMaybe<AnnouncementType>;
  jobsiteIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
  status?: InputMaybe<AnnouncementStatus>;
};

export type GetJobsiteContractorsResponse = {
  __typename?: 'GetJobsiteContractorsResponse';
  count: Scalars['Int']['output'];
  results: Array<JobsiteContractor>;
};

export type GetJobsiteEquipmentDataResponse = {
  __typename?: 'GetJobsiteEquipmentDataResponse';
  count: Scalars['Int']['output'];
  results: Array<JobsiteEquipmentData>;
};

export type GetJobsiteEquipmentHistoryResponse = {
  __typename?: 'GetJobsiteEquipmentHistoryResponse';
  count: Scalars['Int']['output'];
  results: Array<JobsiteEquipmentEvent>;
};

export type GetJobsiteFormSubmissionsOrderBy = {
  direction?: InputMaybe<OrderByDirection>;
  field?: InputMaybe<GetJobsiteFormSubmissionsOrderByField>;
};

export enum GetJobsiteFormSubmissionsOrderByField {
  CreatedBy = 'created_by',
  Date = 'date',
  EndTime = 'end_time',
  Manpower = 'manpower',
  Name = 'name',
  StartTime = 'start_time',
}

export type GetJobsitePacsGatewaysResponse = {
  __typename?: 'GetJobsitePacsGatewaysResponse';
  count: Scalars['Int']['output'];
  results?: Maybe<Array<Maybe<JobsitePacsGateway>>>;
};

export type GetJobsiteWorkerDashboardResponse = {
  __typename?: 'GetJobsiteWorkerDashboardResponse';
  activeBreakdowns?: Maybe<Array<ElasticFacetEntry>>;
  ageBreakdowns?: Maybe<Array<ElasticFacetEntry>>;
  bannedBreakdowns?: Maybe<Array<ElasticFacetEntry>>;
  compliantBreakdowns?: Maybe<Array<ElasticFacetEntry>>;
  contractorBreakdowns?: Maybe<Array<ElasticFacetEntry>>;
  dailyBreakdowns?: Maybe<Array<ElasticFacetEntry>>;
  genderBreakdowns?: Maybe<Array<ElasticFacetEntry>>;
  jobsiteBreakdowns?: Maybe<Array<ElasticFacetEntry>>;
  jobsites: Array<Jobsite>;
  languageBreakdowns?: Maybe<Array<ElasticFacetEntry>>;
  onboardedBreakdowns?: Maybe<Array<ElasticFacetEntry>>;
  overviewBreakdowns?: Maybe<Array<ElasticFacetEntry>>;
  raceBreakdowns?: Maybe<Array<ElasticFacetEntry>>;
  results: Array<DashboardJobsiteWorkerSession>;
  totalCount?: Maybe<Scalars['Int']['output']>;
  tradeBreakdowns?: Maybe<Array<ElasticFacetEntry>>;
  tradeClassBreakdowns?: Maybe<Array<ElasticFacetEntry>>;
  zipcodeBreakdowns?: Maybe<Array<ElasticFacetEntry>>;
};

export enum GetJobsiteWorkerReportsTimeBreakdown {
  Day = 'DAY',
  Hour = 'HOUR',
  Month = 'MONTH',
  Week = 'WEEK',
}

export type GetJobsiteWorkersOrderBy = {
  direction?: InputMaybe<OrderByDirection>;
  field?: InputMaybe<GetJobsiteWorkersOrderByField>;
};

export enum GetJobsiteWorkersOrderByField {
  BirthDate = 'BIRTH_DATE',
  CompletedBadgingAt = 'COMPLETED_BADGING_AT',
  CompletedSiteSpecificTrainingAt = 'COMPLETED_SITE_SPECIFIC_TRAINING_AT',
  Contractor = 'CONTRACTOR',
  JobsiteName = 'JOBSITE_NAME',
  JobTitle = 'JOB_TITLE',
  Name = 'NAME',
  OrientationDate = 'ORIENTATION_DATE',
  OrientationStatus = 'ORIENTATION_STATUS',
  SiteSpecificStatus = 'SITE_SPECIFIC_STATUS',
  StartDate = 'START_DATE',
  Trade = 'TRADE',
}

export type GetMemberJobsitesResponse = {
  __typename?: 'GetMemberJobsitesResponse';
  count: Scalars['Int']['output'];
  jobsiteMembers: Array<JobsiteMember>;
  selectedJobsiteMember?: Maybe<JobsiteMember>;
  user: User;
};

export type GetOrganizationsOrderBy = {
  direction?: InputMaybe<OrderByDirection>;
  field?: InputMaybe<GetOrganizationsOrderByField>;
};

export enum GetOrganizationsOrderByField {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME',
  Slug = 'SLUG',
}

export type GetPayrollDocumentsInput = {
  contractorId?: InputMaybe<Scalars['ID']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  jobsiteIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  payrollDocumentTypeId?: InputMaybe<Scalars['ID']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type GetUserRolesOrderBy = {
  direction?: InputMaybe<OrderByDirection>;
  field?: InputMaybe<GetUserRolesOrderByField>;
};

export enum GetUserRolesOrderByField {
  CreatedAt = 'created_at',
  Jobsite = 'jobsite',
  Title = 'title',
  UpdatedAt = 'updated_at',
}

export type GetUsersOrderBy = {
  direction?: InputMaybe<OrderByDirection>;
  field?: InputMaybe<GetUsersOrderByField>;
};

export enum GetUsersOrderByField {
  AppVersion = 'app_version',
  Email = 'email',
  LastActiveAt = 'last_active_at',
  Name = 'name',
  PhoneNumber = 'phone_number',
}

export type GetWorkerDocumentsResponse = {
  __typename?: 'GetWorkerDocumentsResponse';
  workerDocuments: Array<JobsiteWorkerDocument>;
};

export type HidEntityOption = {
  __typename?: 'HidEntityOption';
  entityType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  locationId?: Maybe<Scalars['String']['output']>;
  locationName?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  organizationId?: Maybe<Scalars['String']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  proxyInstance: HidProxyInstance;
};

export enum HidProxyInstance {
  Odin = 'odin',
  Related = 'related',
}

/**
 * Note:Not to be removed
 * Used by generateLookerJobsiteWorkerReports
 */
export type ILookerWorkerSessionData = {
  __typename?: 'ILookerWorkerSessionData';
  contractorOrganizationName?: Maybe<Scalars['String']['output']>;
  croppedDownloadKey?: Maybe<Scalars['String']['output']>;
  jobsiteName: Scalars['String']['output'];
  jobsiteWorkerJobsiteWorkerId: Scalars['String']['output'];
  jobsiteWorkerStatus?: Maybe<Scalars['String']['output']>;
  profile?: Maybe<Scalars['String']['output']>;
  publicUrl?: Maybe<Scalars['String']['output']>;
  swipeSessionsCompliant?: Maybe<Scalars['String']['output']>;
  swipeSessionsDisplayValueSpanHours?: Maybe<Scalars['String']['output']>;
  swipeSessionsFirstInboundSwipeTimeOfDay?: Maybe<Scalars['String']['output']>;
  swipeSessionsJobsiteWorkerId: Scalars['String']['output'];
  swipeSessionsLastOutboundSwipeTimeOfDay?: Maybe<Scalars['String']['output']>;
  swipeSessionsNetTotalSpanHours?: Maybe<Scalars['String']['output']>;
  swipeSessionsOvernightSession?: Maybe<Scalars['String']['output']>;
  swipeSessionsReads?: Maybe<Scalars['String']['output']>;
  swipeSessionsReadsInbound?: Maybe<Scalars['String']['output']>;
  swipeSessionsReadsOutbound?: Maybe<Scalars['String']['output']>;
  swipeSessionsSessionDate: Scalars['String']['output'];
  swipeSessionsSessionId: Scalars['String']['output'];
  userIdentityUserFullname: Scalars['String']['output'];
  workerJobTitle?: Maybe<Scalars['String']['output']>;
  workerProfilePicturesViewCroppedCloudStorageBucket?: Maybe<Scalars['String']['output']>;
  workerProfilePicturesViewCroppedCloudStorageUri?: Maybe<Scalars['String']['output']>;
  workerProfilePicturesViewCroppedDownloadKey?: Maybe<Scalars['String']['output']>;
  workerQuickCode?: Maybe<Scalars['String']['output']>;
  workerTrade?: Maybe<Scalars['String']['output']>;
  workerUnionAffiliation?: Maybe<Scalars['String']['output']>;
  workerWorkerId: Scalars['String']['output'];
};

export type IdCardData = {
  __typename?: 'IdCardData';
  address?: Maybe<Scalars['String']['output']>;
  dateOfBirth?: Maybe<Scalars['String']['output']>;
  expirationDate?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  idType?: Maybe<Scalars['String']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  issueDate?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type IdCardDataExtractionResult = {
  __typename?: 'IdCardDataExtractionResult';
  extractedData?: Maybe<IdCardData>;
  file?: Maybe<File>;
};

export type InitiateStripePaymentSetupSessionInput = {
  contractorId: Scalars['ID']['input'];
};

export type InitiateStripePaymentSetupSessionPayload = {
  __typename?: 'InitiateStripePaymentSetupSessionPayload';
  clientSecret: Scalars['String']['output'];
  customerId: Scalars['String']['output'];
  publicKey: Scalars['String']['output'];
  sessionId: Scalars['String']['output'];
};

export type IntegrationDetails = {
  __typename?: 'IntegrationDetails';
  integrationType: ExternalIntegrationType;
  url: Scalars['String']['output'];
};

/**
 * Jobsite return type. A jobsite is an individual project within a developmentProject. Smaller sites will have one
 * project per developmentProject, but larger sites may have many projects in a single developmentProject.
 */
export type Jobsite = {
  __typename?: 'Jobsite';
  accessChangeEmails?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** City of the jobsite */
  addressCity?: Maybe<Scalars['String']['output']>;
  /** Address of the jobsite */
  addressLine1?: Maybe<Scalars['String']['output']>;
  /** Address of the jobsite */
  addressLine2?: Maybe<Scalars['String']['output']>;
  /** State of the jobsite */
  addressState?: Maybe<StateAbbreviation>;
  /** Zip code of the jobsite */
  addressZipCode?: Maybe<Scalars['String']['output']>;
  allowOvernightSessions?: Maybe<Scalars['Boolean']['output']>;
  allowsMobileCheckIn?: Maybe<Scalars['Boolean']['output']>;
  appCheckInModule?: Maybe<JobsiteAppCheckInModule>;
  assignmentChangeEmails?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  avigilonEnabled?: Maybe<Scalars['Boolean']['output']>;
  cCureClearances: JobsiteCCureClearancesConnection;
  cCureEntityMappings: JobsiteCCureEntityMappingsConnection;
  clearanceAssignmentEnabled?: Maybe<Scalars['Boolean']['output']>;
  dailyAccessReportEmails?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  defaultJobsiteContractor?: Maybe<JobsiteContractor>;
  /** Description of the jobsite */
  description?: Maybe<Scalars['String']['output']>;
  /** The full developer object */
  developer?: Maybe<Developer>;
  deviceStatusChangeEmails?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  documentTypes: JobsiteWorkerDocumentTypesConnection;
  /** End date of the jobsite */
  endDate?: Maybe<Scalars['Date']['output']>;
  escortChangeEmails?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  fargoLocationId?: Maybe<Scalars['String']['output']>;
  fargoOrganizationId?: Maybe<Scalars['String']['output']>;
  featuresModule?: Maybe<JobsiteFeaturesModule>;
  hidCardMappingNonUnion?: Maybe<Scalars['String']['output']>;
  hidCardMappingTest?: Maybe<Scalars['String']['output']>;
  hidCardMappingUnion?: Maybe<Scalars['String']['output']>;
  /** Printer ID for jobsite */
  hidPrinterId?: Maybe<Scalars['String']['output']>;
  hidProxyInstance?: Maybe<Scalars['String']['output']>;
  isTemplate?: Maybe<Scalars['Boolean']['output']>;
  jobsiteBadgeTemplates: JobsiteBadgeTemplatesConnection;
  jobsiteCardFormats: JobsiteCardFormatsConnection;
  jobsiteContractors: JobsiteJobsiteContractorsConnection;
  jobsiteForms: JobsiteFormsConnection;
  /** Unique indetifier for the jobsite */
  jobsiteId: Scalars['ID']['output'];
  jobsiteMembers: JobsiteJobsiteMembersConnection;
  jobsiteWorkers: JobsiteJobsiteWorkersConnection;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  maximumSessionLength?: Maybe<Scalars['Duration']['output']>;
  modules?: Maybe<Array<ValidModule>>;
  /** Name of the jobsite */
  name: Scalars['String']['output'];
  /** History of the object */
  objectHistory: ObjectHistory;
  onboardingGracePeriod?: Maybe<Scalars['Duration']['output']>;
  parentId?: Maybe<Scalars['ID']['output']>;
  procoreCompanyId?: Maybe<Scalars['String']['output']>;
  procoreProjectId?: Maybe<Scalars['String']['output']>;
  rejectedSwipeEmails?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  reportingTimeSlot?: Maybe<Scalars['String']['output']>;
  sessionDisplayColumn?: Maybe<SessionDisplayColumns>;
  /** Start date of the jobsite */
  startDate?: Maybe<Scalars['Date']['output']>;
  swipeInactivityThreshold?: Maybe<Scalars['Duration']['output']>;
  templateId?: Maybe<Scalars['ID']['output']>;
  timeZone?: Maybe<Scalars['String']['output']>;
  weeklyAccessReportEmails?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  workerAccessEnabled?: Maybe<Scalars['Boolean']['output']>;
};

/**
 * Jobsite return type. A jobsite is an individual project within a developmentProject. Smaller sites will have one
 * project per developmentProject, but larger sites may have many projects in a single developmentProject.
 */
export type JobsiteCCureClearancesArgs = {
  input?: InputMaybe<JobsiteCCureClearancesInput>;
};

/**
 * Jobsite return type. A jobsite is an individual project within a developmentProject. Smaller sites will have one
 * project per developmentProject, but larger sites may have many projects in a single developmentProject.
 */
export type JobsiteCCureEntityMappingsArgs = {
  input?: InputMaybe<JobsiteCCureEntityMappingsInput>;
};

/**
 * Jobsite return type. A jobsite is an individual project within a developmentProject. Smaller sites will have one
 * project per developmentProject, but larger sites may have many projects in a single developmentProject.
 */
export type JobsiteDocumentTypesArgs = {
  input?: InputMaybe<JobsiteWorkerDocumentTypesInput>;
};

/**
 * Jobsite return type. A jobsite is an individual project within a developmentProject. Smaller sites will have one
 * project per developmentProject, but larger sites may have many projects in a single developmentProject.
 */
export type JobsiteJobsiteBadgeTemplatesArgs = {
  input?: InputMaybe<JobsiteBadgeTemplatesInput>;
};

/**
 * Jobsite return type. A jobsite is an individual project within a developmentProject. Smaller sites will have one
 * project per developmentProject, but larger sites may have many projects in a single developmentProject.
 */
export type JobsiteJobsiteCardFormatsArgs = {
  input?: InputMaybe<JobsiteCardFormatsInput>;
};

/**
 * Jobsite return type. A jobsite is an individual project within a developmentProject. Smaller sites will have one
 * project per developmentProject, but larger sites may have many projects in a single developmentProject.
 */
export type JobsiteJobsiteContractorsArgs = {
  input?: InputMaybe<JobsiteJobsiteContractorsInput>;
};

/**
 * Jobsite return type. A jobsite is an individual project within a developmentProject. Smaller sites will have one
 * project per developmentProject, but larger sites may have many projects in a single developmentProject.
 */
export type JobsiteJobsiteFormsArgs = {
  input?: InputMaybe<JobsiteFormsInput>;
};

/**
 * Jobsite return type. A jobsite is an individual project within a developmentProject. Smaller sites will have one
 * project per developmentProject, but larger sites may have many projects in a single developmentProject.
 */
export type JobsiteJobsiteMembersArgs = {
  input?: InputMaybe<JobsiteJobsiteMembersInput>;
};

/**
 * Jobsite return type. A jobsite is an individual project within a developmentProject. Smaller sites will have one
 * project per developmentProject, but larger sites may have many projects in a single developmentProject.
 */
export type JobsiteJobsiteWorkersArgs = {
  input?: InputMaybe<JobsiteJobsiteWorkersInput>;
};

export type JobsiteAccessEvent = {
  __typename?: 'JobsiteAccessEvent';
  credentialId?: Maybe<Scalars['String']['output']>;
  direction?: Maybe<Scalars['String']['output']>;
  eventOccurredAt: Scalars['String']['output'];
  eventType?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  gateway?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  jobsite?: Maybe<Jobsite>;
  jobsiteWorker?: Maybe<JobsiteWorker>;
  localizedDate?: Maybe<Scalars['String']['output']>;
  localizedTime?: Maybe<Scalars['String']['output']>;
  locationStatus?: Maybe<JobsiteWorkerCheckInLocationStatus>;
  message: Scalars['String']['output'];
  overnightSession?: Maybe<Scalars['Boolean']['output']>;
  rejectionReason?: Maybe<Scalars['String']['output']>;
  sessionDate: Scalars['String']['output'];
  status: JobsiteAccessEventStatus;
  timeStamp: Scalars['Date']['output'];
  timeZone?: Maybe<Scalars['String']['output']>;
  type: JobsiteAccessEventMessageType;
  worker?: Maybe<Worker>;
};

export enum JobsiteAccessEventDirection {
  OffSite = 'OffSite',
  OnSite = 'OnSite',
}

export enum JobsiteAccessEventMessageType {
  CardAdmitted = 'CardAdmitted',
  CardRejected = 'CardRejected',
}

export enum JobsiteAccessEventRejectionReason {
  AdministrativeAction = 'AdministrativeAction',
  Admit = 'Admit',
  Clearance = 'Clearance',
  Disabled = 'Disabled',
  Expired = 'Expired',
  FacilityCode = 'FacilityCode',
  GapbPassbackCommFailure = 'GAPBPassbackCommFailure',
  IllegalRejectCode = 'IllegalRejectCode',
  JobsiteDoesNotAllowKioskAccessEvents = 'JobsiteDoesNotAllowKioskAccessEvents',
  JobsiteDoesNotAllowMobileAdminCheckIn = 'JobsiteDoesNotAllowMobileAdminCheckIn',
  JobsiteDoesNotAllowMobileBadgeScanning = 'JobsiteDoesNotAllowMobileBadgeScanning',
  JobsiteDoesNotAllowMobileSelfCheckIn = 'JobsiteDoesNotAllowMobileSelfCheckIn',
  Misread = 'Misread',
  NotActivated = 'NotActivated',
  Passback = 'Passback',
  SiteCode = 'SiteCode',
  TimedAntipassback = 'TimedAntipassback',
  UnknownCard = 'UnknownCard',
  WorkerNotAllowedOnSite = 'WorkerNotAllowedOnSite',
  WorkerNotAssignedToSite = 'WorkerNotAssignedToSite',
  WorkerNotOnSite = 'WorkerNotOnSite',
}

export enum JobsiteAccessEventStatus {
  Admit = 'Admit',
  Reject = 'Reject',
  Archived = 'archived',
}

export type JobsiteAccessSession = {
  __typename?: 'JobsiteAccessSession';
  active?: Maybe<Scalars['Boolean']['output']>;
  balanced?: Maybe<Scalars['Boolean']['output']>;
  balancedGrossSpan?: Maybe<Scalars['Int']['output']>;
  compliant?: Maybe<Scalars['Boolean']['output']>;
  createdAt: Scalars['Date']['output'];
  displayValueSpan?: Maybe<Scalars['Int']['output']>;
  firstDirection?: Maybe<Scalars['String']['output']>;
  firstInboundSwipe?: Maybe<Scalars['Date']['output']>;
  firstOutboundSwipe?: Maybe<Scalars['Date']['output']>;
  firstSwipe?: Maybe<Scalars['Date']['output']>;
  jobsiteWorker: JobsiteWorker;
  jobsiteWorkerId?: Maybe<Scalars['String']['output']>;
  lastDirection?: Maybe<Scalars['String']['output']>;
  lastInboundSwipe?: Maybe<Scalars['Date']['output']>;
  lastOutboundSwipe?: Maybe<Scalars['Date']['output']>;
  lastSwipe?: Maybe<Scalars['Date']['output']>;
  naiveGrossSpan?: Maybe<Scalars['Int']['output']>;
  netOffsiteSpan?: Maybe<Scalars['Int']['output']>;
  netOnsiteSpan?: Maybe<Scalars['Int']['output']>;
  netTotalSpan?: Maybe<Scalars['Int']['output']>;
  overnightSession?: Maybe<Scalars['Boolean']['output']>;
  proximitySession: ProximitySessionResult;
  reads?: Maybe<Scalars['Int']['output']>;
  readsInbound?: Maybe<Scalars['Int']['output']>;
  readsOutbound?: Maybe<Scalars['Int']['output']>;
  sessionDate?: Maybe<Scalars['String']['output']>;
  sessionId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type JobsiteAccessSessionBreakdown = {
  __typename?: 'JobsiteAccessSessionBreakdown';
  stats?: Maybe<Array<Maybe<BreakdownStat>>>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<JobsiteAccessSessionBreakdownType>;
};

export enum JobsiteAccessSessionBreakdownType {
  Active = 'Active',
  Age = 'Age',
  Banned = 'Banned',
  Compliant = 'Compliant',
  Contractor = 'Contractor',
  Daily = 'Daily',
  Gender = 'Gender',
  Jobsite = 'Jobsite',
  Language = 'Language',
  Onboarded = 'Onboarded',
  Overview = 'Overview',
  Race = 'Race',
  Trade = 'Trade',
  TradeClass = 'TradeClass',
  Zipcode = 'Zipcode',
}

export type JobsiteAccessSessionEdge = {
  __typename?: 'JobsiteAccessSessionEdge';
  node?: Maybe<JobsiteAccessSession>;
};

export enum JobsiteAccessStatusType {
  AccessAllowed = 'AccessAllowed',
  CurrentlyOnsite = 'CurrentlyOnsite',
  RecentlyOnsite = 'RecentlyOnsite',
}

export type JobsiteAddContractorsInput = {
  contractorIds: Array<Scalars['ID']['input']>;
  jobsiteContractorInput: JobsiteContractorInput;
  jobsiteId: Scalars['ID']['input'];
};

export type JobsiteAddContractorsPayload = {
  __typename?: 'JobsiteAddContractorsPayload';
  jobsiteContractors: Array<JobsiteContractor>;
};

export type JobsiteAddFormInput = {
  formId: Scalars['ID']['input'];
  jobsiteId: Scalars['ID']['input'];
};

export type JobsiteAddFormPayload = {
  __typename?: 'JobsiteAddFormPayload';
  jobsiteForm: JobsiteForm;
};

/** JobsiteAnnouncement return type. */
export type JobsiteAnnouncement = {
  __typename?: 'JobsiteAnnouncement';
  /** Number of days within which worker has been 'active' that the announcement is sent to */
  accessWithinDayRange?: Maybe<Scalars['Int']['output']>;
  /** Type of announcement */
  announcementType: AnnouncementType;
  /**
   * CandidateRecipientsEdge objects that the announcement might be sent to
   * (Not currently implemented: Spec'd for future use with draft announcements)
   */
  candidateRecipientsEdge?: Maybe<JobsiteAnnouncementCandidateRecipientsConnection>;
  /** Contractors used as a filter for where the announcement is sent to */
  contractors: JobsiteAnnouncementContractorsConnection;
  /** Who created the announcement */
  createdBy?: Maybe<User>;
  endAt?: Maybe<Scalars['Date']['output']>;
  isRepeatable?: Maybe<Scalars['Boolean']['output']>;
  /** Jobsite access status that the announcement is sent to */
  jobsiteAccessStatus?: Maybe<JobsiteAccessStatusType>;
  /** Unique identifier for the announcement */
  jobsiteAnnouncementId: Scalars['ID']['output'];
  /** Jobsites used as a filter for where the announcement will be sent to */
  jobsites: JobsiteAnnouncementJobsitesConnection;
  /** Languages used to filter the announcement recipients */
  languages?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Message of the announcement */
  message?: Maybe<Scalars['String']['output']>;
  repeatableSchedule?: Maybe<Scalars['String']['output']>;
  resultData?: Maybe<JobsiteAnnouncementResultData>;
  /** When the announcement was sent */
  sentAt?: Maybe<Scalars['Date']['output']>;
  /** Who sent the announcement */
  sentBy?: Maybe<User>;
  startAt?: Maybe<Scalars['Date']['output']>;
  /** Status of the announcement */
  status?: Maybe<AnnouncementStatus>;
  /** Subject of the announcement */
  subject?: Maybe<Scalars['String']['output']>;
  timeZone?: Maybe<Scalars['String']['output']>;
  /** Title identifiers that the announcement is sent to */
  titles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Trade identifiers that the announcement is sent to */
  trades?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

/** JobsiteAnnouncement return type. */
export type JobsiteAnnouncementContractorsArgs = {
  input?: InputMaybe<JobsiteAnnouncementContractorsInput>;
};

/** JobsiteAnnouncement return type. */
export type JobsiteAnnouncementJobsitesArgs = {
  input?: InputMaybe<JobsiteAnnouncementJobsitesInput>;
};

export type JobsiteAnnouncementCandidateRecipientEdge = {
  __typename?: 'JobsiteAnnouncementCandidateRecipientEdge';
  node: Worker;
};

export type JobsiteAnnouncementCandidateRecipientsConnection = {
  __typename?: 'JobsiteAnnouncementCandidateRecipientsConnection';
  edges: Array<JobsiteAnnouncementCandidateRecipientEdge>;
  totalCount: Scalars['Int']['output'];
  totalReachableCount: Scalars['Int']['output'];
};

export type JobsiteAnnouncementConnection = {
  __typename?: 'JobsiteAnnouncementConnection';
  count: Scalars['Int']['output'];
  edges: Array<JobsiteAnnouncementEdge>;
};

export type JobsiteAnnouncementContractorsConnection = {
  __typename?: 'JobsiteAnnouncementContractorsConnection';
  count: Scalars['Int']['output'];
  edges: Array<JobsiteAnnouncementContractorsEdge>;
};

export type JobsiteAnnouncementContractorsEdge = {
  __typename?: 'JobsiteAnnouncementContractorsEdge';
  node: Contractor;
};

export type JobsiteAnnouncementContractorsInput = {
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
};

export type JobsiteAnnouncementEdge = {
  __typename?: 'JobsiteAnnouncementEdge';
  node: JobsiteAnnouncement;
};

/** Input for CandidateRecipientsEdge Query */
export type JobsiteAnnouncementFilterSpecification = {
  /** Type of announcement */
  announcementType: AnnouncementType;
  /** Number of days within which worker has been active to send announcement to */
  filterAccessWithinDayRange?: InputMaybe<Scalars['Int']['input']>;
  /** contractorIds to send announcement to */
  filterContractorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Jobsite access status to send announcement to */
  filterJobsiteAccessStatus?: InputMaybe<JobsiteAccessStatusType>;
  /** jobsiteIds to send announcement to */
  filterJobsiteIds: Array<Scalars['ID']['input']>;
  /** Language identifiers to filter by */
  filterLanguages?: InputMaybe<Array<Scalars['String']['input']>>;
  /** title identifiers to send announcement to */
  filterTitles?: InputMaybe<Array<Scalars['String']['input']>>;
  /** trade identifiers to send announcement to */
  filterTrades?: InputMaybe<Array<Scalars['String']['input']>>;
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
};

/** Input for JobsiteAnnouncement Mutations */
export type JobsiteAnnouncementInput = {
  filterSpecification: JobsiteAnnouncementFilterSpecification;
  /** Message of the announcement */
  message?: InputMaybe<Scalars['String']['input']>;
  startAt?: InputMaybe<Scalars['Date']['input']>;
  /** Status of the announcement */
  status: AnnouncementStatus;
  /** Subject of the announcement */
  subject?: InputMaybe<Scalars['String']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
};

export type JobsiteAnnouncementJobsitesConnection = {
  __typename?: 'JobsiteAnnouncementJobsitesConnection';
  count: Scalars['Int']['output'];
  edges: Array<JobsiteAnnouncementJobsitesEdge>;
};

export type JobsiteAnnouncementJobsitesEdge = {
  __typename?: 'JobsiteAnnouncementJobsitesEdge';
  node: Jobsite;
};

export type JobsiteAnnouncementJobsitesInput = {
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
};

export type JobsiteAnnouncementResultData = {
  __typename?: 'JobsiteAnnouncementResultData';
  delivered?: Maybe<JobsiteAnnouncementResultSectionData>;
  published?: Maybe<JobsiteAnnouncementResultSectionData>;
};

export type JobsiteAnnouncementResultSectionData = {
  __typename?: 'JobsiteAnnouncementResultSectionData';
  at?: Maybe<Scalars['String']['output']>;
  workerIds?: Maybe<Array<Scalars['ID']['output']>>;
};

export type JobsiteAppCheckInModule = JobsiteModule & {
  __typename?: 'JobsiteAppCheckInModule';
  areas: Array<JobsiteAppCheckInModuleArea>;
  crewGeofencingEnforcementType?: Maybe<GeofencingEnforcementType>;
  geofencingEnforcementType?: Maybe<GeofencingEnforcementType>;
  isEnabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type JobsiteAppCheckInModuleArea = JobsiteWorkerCheckInArea & {
  __typename?: 'JobsiteAppCheckInModuleArea';
  checkInCoordinate: GpsCoordinate;
  checkInRadius: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  isInboundEnabled: Scalars['Boolean']['output'];
  isOutboundEnabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type JobsiteAppCheckInModuleAreaInput = {
  changeType: ChangeType;
  checkInCoordinate: GpsCoordinateInput;
  checkInRadius: Scalars['Float']['input'];
  id: Scalars['ID']['input'];
  isInboundEnabled: Scalars['Boolean']['input'];
  isOutboundEnabled: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

export type JobsiteAppCheckInModuleInput = {
  areas?: InputMaybe<Array<JobsiteAppCheckInModuleAreaInput>>;
  crewGeofencingEnforcementType?: InputMaybe<GeofencingEnforcementType>;
  geofencingEnforcementType?: InputMaybe<GeofencingEnforcementType>;
  isEnabled: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  slug: Scalars['String']['input'];
};

export type JobsiteArchiveFormSubmissionInput = {
  id: Scalars['ID']['input'];
};

export type JobsiteArchiveFormSubmissionPayload = {
  __typename?: 'JobsiteArchiveFormSubmissionPayload';
  success: Success;
};

export type JobsiteArchiveInvitationInput = {
  jobsiteInvitationId: Scalars['ID']['input'];
};

export type JobsiteArchiveInvitationPayload = {
  __typename?: 'JobsiteArchiveInvitationPayload';
  success: Success;
};

export type JobsiteBadgeTemplate = {
  __typename?: 'JobsiteBadgeTemplate';
  badgeTemplate: BadgeTemplate;
  id: Scalars['ID']['output'];
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  jobsiteId: Scalars['ID']['output'];
};

export type JobsiteBadgeTemplateInput = {
  badgeTemplateId: Scalars['ID']['input'];
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type JobsiteBadgeTemplatesConnection = {
  __typename?: 'JobsiteBadgeTemplatesConnection';
  count: Scalars['Int']['output'];
  edges: Array<JobsiteBadgeTemplatesEdge>;
};

export type JobsiteBadgeTemplatesEdge = {
  __typename?: 'JobsiteBadgeTemplatesEdge';
  node: JobsiteBadgeTemplate;
};

export type JobsiteBadgeTemplatesInput = {
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
};

export type JobsiteCCureClearanceInput = {
  cCureClearanceId?: InputMaybe<Scalars['ID']['input']>;
  cCureClearanceObjectId: Scalars['Int']['input'];
  cCureEnvironmentId: Scalars['ID']['input'];
  changeType: ChangeType;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  isSelectable?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
};

export type JobsiteCCureClearancesConnection = {
  __typename?: 'JobsiteCCureClearancesConnection';
  count: Scalars['Int']['output'];
  edges: Array<JobsiteCCureClearancesEdge>;
};

export type JobsiteCCureClearancesEdge = {
  __typename?: 'JobsiteCCureClearancesEdge';
  node: CCureClearance;
};

export type JobsiteCCureClearancesInput = {
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
};

export type JobsiteCCureEntityMappingInput = {
  entityType: CCureEntityType;
  environmentId: Scalars['ID']['input'];
  objectId: Scalars['Int']['input'];
};

export type JobsiteCCureEntityMappingsConnection = {
  __typename?: 'JobsiteCCureEntityMappingsConnection';
  count: Scalars['Int']['output'];
  edges: Array<JobsiteCCureEntityMappingsEdge>;
};

export type JobsiteCCureEntityMappingsEdge = {
  __typename?: 'JobsiteCCureEntityMappingsEdge';
  node: CCureEntityMapping;
};

export type JobsiteCCureEntityMappingsInput = {
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
};

export type JobsiteCardFormat = {
  __typename?: 'JobsiteCardFormat';
  isDefaultForCardType?: Maybe<WorkerCardType>;
  jobsiteCardFormatId: Scalars['ID']['output'];
  jobsiteId: Scalars['ID']['output'];
  workerCardFormat: WorkerCardFormat;
};

export type JobsiteCardFormatsConnection = {
  __typename?: 'JobsiteCardFormatsConnection';
  count: Scalars['Int']['output'];
  edges: Array<JobsiteCardFormatsEdge>;
};

export type JobsiteCardFormatsEdge = {
  __typename?: 'JobsiteCardFormatsEdge';
  node: JobsiteCardFormat;
};

export type JobsiteCardFormatsInput = {
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
};

export enum JobsiteClearanceType {
  CCure = 'CCure',
}

export type JobsiteCloseOutInput = {
  endDate: Scalars['Date']['input'];
  jobsiteId: Scalars['ID']['input'];
};

export type JobsiteCloseOutPayload = {
  __typename?: 'JobsiteCloseOutPayload';
  jobsite: Jobsite;
};

export type JobsiteContractor = {
  __typename?: 'JobsiteContractor';
  contact?: Maybe<ContactPerson>;
  /** The full contractor object */
  contractor: Contractor;
  /** End date of contractor at this jobsite/project */
  endDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  /** The full jobsite object */
  jobsite: Jobsite;
  jobsiteInvitation?: Maybe<JobsiteInvitation>;
  jobsiteWorkers: JobsiteContractorJobsiteWorkersConnection;
  /** History of the object */
  objectHistory: ObjectHistory;
  /** Parent contractor if this is a subContractor */
  parentJobsiteContractor?: Maybe<JobsiteContractor>;
  /** Start date of contractor at this jobsite/project */
  startDate: Scalars['Date']['output'];
  stripePaymentMethodId?: Maybe<Scalars['ID']['output']>;
  subcontractedJobsiteContractors: SubcontractedJobsiteContractorsConnection;
};

export type JobsiteContractorJobsiteWorkersArgs = {
  input?: InputMaybe<JobsiteContractorJobsiteWorkersInput>;
};

export type JobsiteContractorSubcontractedJobsiteContractorsArgs = {
  input?: InputMaybe<SubcontractedJobsiteContractorsInput>;
};

/** Input for assigning a jobsite to a contractor or updating their info at the contractor */
export type JobsiteContractorInput = {
  contactInput?: InputMaybe<ContactInput>;
  /** End date of contractor at this jobsite/project */
  endDate?: InputMaybe<Scalars['Date']['input']>;
  parentContractorId?: InputMaybe<Scalars['ID']['input']>;
  procoreProjectVendorId?: InputMaybe<Scalars['String']['input']>;
  /** Start date of contractor at this jobsite/project */
  startDate: Scalars['Date']['input'];
};

export type JobsiteContractorJobsiteWorkersConnection = {
  __typename?: 'JobsiteContractorJobsiteWorkersConnection';
  count: Scalars['Int']['output'];
  edges: Array<JobsiteContractorJobsiteWorkersEdge>;
};

export type JobsiteContractorJobsiteWorkersEdge = {
  __typename?: 'JobsiteContractorJobsiteWorkersEdge';
  node: JobsiteWorker;
};

export type JobsiteContractorJobsiteWorkersInput = {
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
};

export type JobsiteContractorUpdateStripePaymentMethodInput = {
  jobsiteContractorId: Scalars['ID']['input'];
  stripePaymentMethodId?: InputMaybe<Scalars['ID']['input']>;
};

export type JobsiteContractorUpdateStripePaymentMethodPayload = {
  __typename?: 'JobsiteContractorUpdateStripePaymentMethodPayload';
  success: Success;
};

/** Input for submitting a jobsite form */
export type JobsiteCreateFormSubmissionInput = {
  documents?: InputMaybe<Array<JobsiteFormSubmissionDocumentInput>>;
  endAt?: InputMaybe<Scalars['Date']['input']>;
  extraData?: InputMaybe<Scalars['JSONObject']['input']>;
  jobsiteContractors?: InputMaybe<Array<JobsiteFormSubmissionContractorInput>>;
  jobsiteFormId: Scalars['ID']['input'];
  jobsiteWorkers?: InputMaybe<Array<JobsiteFormSubmissionWorkerInput>>;
  startAt: Scalars['Date']['input'];
};

export type JobsiteCreateFormSubmissionPayload = {
  __typename?: 'JobsiteCreateFormSubmissionPayload';
  jobsiteFormSubmission: JobsiteFormSubmission;
};

/** Input for Jobsite Mutations */
export type JobsiteCreateInput = {
  addressCity?: InputMaybe<Scalars['String']['input']>;
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  addressState?: InputMaybe<StateAbbreviation>;
  addressZipCode?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  developerId?: InputMaybe<Scalars['ID']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  isTemplate?: InputMaybe<Scalars['Boolean']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['ID']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  templateId?: InputMaybe<Scalars['ID']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
};

export type JobsiteCreateInvitationInput = {
  expirationDate?: InputMaybe<Scalars['Date']['input']>;
  jobsiteContractorId: Scalars['ID']['input'];
};

export type JobsiteCreateInvitationPayload = {
  __typename?: 'JobsiteCreateInvitationPayload';
  jobsiteInvitation: JobsiteInvitation;
};

export type JobsiteCreatePayload = {
  __typename?: 'JobsiteCreatePayload';
  jobsite: Jobsite;
};

export type JobsiteEquipmentData = {
  __typename?: 'JobsiteEquipmentData';
  description: Scalars['String']['output'];
  guid: Scalars['ID']['output'];
  instanceSlug: Scalars['String']['output'];
  jobsite: Jobsite;
  lastOfflineDate?: Maybe<Scalars['Date']['output']>;
  lastOnlineDate?: Maybe<Scalars['Date']['output']>;
  name: Scalars['String']['output'];
  status: JobsiteEquipmentStatusType;
  type: Scalars['String']['output'];
};

export type JobsiteEquipmentEvent = {
  __typename?: 'JobsiteEquipmentEvent';
  deviceDescription: Scalars['String']['output'];
  deviceGuid: Scalars['ID']['output'];
  deviceName: Scalars['String']['output'];
  deviceStatus: JobsiteEquipmentStatusType;
  deviceType: Scalars['String']['output'];
  eventDate: Scalars['Date']['output'];
  instanceSlug: Scalars['String']['output'];
  jobsiteId: Scalars['ID']['output'];
};

export enum JobsiteEquipmentStatusType {
  Offline = 'Offline',
  Online = 'Online',
}

export type JobsiteFeaturesModule = JobsiteModule & {
  __typename?: 'JobsiteFeaturesModule';
  addWorkersToDailyLog?: Maybe<Scalars['Boolean']['output']>;
  announcementsEnabled?: Maybe<Scalars['Boolean']['output']>;
  archivingAccessEventsEnabled?: Maybe<Scalars['Boolean']['output']>;
  doNotAllowToSkipSelfOnboardingSteps?: Maybe<Scalars['Boolean']['output']>;
  enforceValidContractorInSelfOnboarding?: Maybe<Scalars['Boolean']['output']>;
  formsEnabled?: Maybe<Scalars['Boolean']['output']>;
  isEnabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  selfOnboardingAutoCompleteEnabled?: Maybe<Scalars['Boolean']['output']>;
  slug: Scalars['String']['output'];
  workerAppAdminCheckInEnabled?: Maybe<Scalars['Boolean']['output']>;
  workerAppBadgeScanningEnabled?: Maybe<Scalars['Boolean']['output']>;
  workerAppEnabled?: Maybe<Scalars['Boolean']['output']>;
  workerAppKioskCheckInEnabled?: Maybe<Scalars['Boolean']['output']>;
  workerAppKioskFrontCameraEnabled?: Maybe<Scalars['Boolean']['output']>;
  workerAppSelfCheckInEnabled?: Maybe<Scalars['Boolean']['output']>;
};

export type JobsiteFeaturesModuleInput = {
  addWorkersToDailyLog?: InputMaybe<Scalars['Boolean']['input']>;
  announcementsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  archivingAccessEventsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  doNotAllowToSkipSelfOnboardingSteps?: InputMaybe<Scalars['Boolean']['input']>;
  enforceValidContractorInSelfOnboarding?: InputMaybe<Scalars['Boolean']['input']>;
  formsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  selfOnboardingAutoCompleteEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  workerAppAdminCheckInEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  workerAppBadgeScanningEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  workerAppEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  workerAppKioskCheckInEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  workerAppKioskFrontCameraEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  workerAppSelfCheckInEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type JobsiteForm = {
  __typename?: 'JobsiteForm';
  form: Form;
  id: Scalars['ID']['output'];
  jobsite: Jobsite;
  /** History of the object */
  objectHistory: ObjectHistory;
};

export type JobsiteFormSubmission = {
  __typename?: 'JobsiteFormSubmission';
  availableWorkers: JobsiteFormSubmissionAvailableWorkersConnection;
  documents: JobsiteFormSubmissionDocumentsConnection;
  endAt?: Maybe<Scalars['Date']['output']>;
  extraData?: Maybe<Scalars['JSONObject']['output']>;
  id: Scalars['ID']['output'];
  isStartTimeSpecified: Scalars['Boolean']['output'];
  jobsiteContractors: JobsiteFormSubmissionContractorsConnection;
  jobsiteForm: JobsiteForm;
  jobsiteWorkers: JobsiteFormSubmissionWorkersConnection;
  /** History of the object */
  objectHistory: ObjectHistory;
  startAt?: Maybe<Scalars['Date']['output']>;
  timeZone?: Maybe<Scalars['String']['output']>;
  weatherConditions?: Maybe<Array<WeatherConditions>>;
};

export type JobsiteFormSubmissionAvailableWorkersArgs = {
  input?: InputMaybe<JobsiteFormSubmissionAvailableWorkersInput>;
};

export type JobsiteFormSubmissionDocumentsArgs = {
  input?: InputMaybe<JobsiteFormSubmissionDocumentsInput>;
};

export type JobsiteFormSubmissionJobsiteContractorsArgs = {
  input?: InputMaybe<JobsiteFormSubmissionContractorsInput>;
};

export type JobsiteFormSubmissionJobsiteWorkersArgs = {
  input?: InputMaybe<JobsiteFormSubmissionWorkersInput>;
};

export type JobsiteFormSubmissionAvailableWorkerEdge = {
  __typename?: 'JobsiteFormSubmissionAvailableWorkerEdge';
  node: Worker;
};

export type JobsiteFormSubmissionAvailableWorkersConnection = {
  __typename?: 'JobsiteFormSubmissionAvailableWorkersConnection';
  count: Scalars['Int']['output'];
  edges: Array<JobsiteFormSubmissionAvailableWorkerEdge>;
};

export type JobsiteFormSubmissionAvailableWorkersInput = {
  includeSiblingJobsites?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type JobsiteFormSubmissionContractor = {
  __typename?: 'JobsiteFormSubmissionContractor';
  associationType: Scalars['String']['output'];
  extraData?: Maybe<Scalars['JSONObject']['output']>;
  id: Scalars['ID']['output'];
  jobsiteContractor: JobsiteContractor;
};

export type JobsiteFormSubmissionContractorInput = {
  associationType: Scalars['String']['input'];
  changeType: ChangeType;
  extraData?: InputMaybe<Scalars['JSONObject']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  jobsiteContractorId?: InputMaybe<Scalars['ID']['input']>;
};

export type JobsiteFormSubmissionContractorsConnection = {
  __typename?: 'JobsiteFormSubmissionContractorsConnection';
  count: Scalars['Int']['output'];
  edges: Array<JobsiteFormSubmissionContractorsEdge>;
};

export type JobsiteFormSubmissionContractorsEdge = {
  __typename?: 'JobsiteFormSubmissionContractorsEdge';
  node: JobsiteFormSubmissionContractor;
};

export type JobsiteFormSubmissionContractorsInput = {
  associationTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
};

export type JobsiteFormSubmissionDocument = {
  __typename?: 'JobsiteFormSubmissionDocument';
  description?: Maybe<Scalars['String']['output']>;
  files: Array<File>;
  id: Scalars['ID']['output'];
  jobsiteFormSubmission: JobsiteFormSubmission;
  objectHistory: ObjectHistory;
};

export type JobsiteFormSubmissionDocumentInput = {
  changeType: ChangeType;
  description: Scalars['String']['input'];
  extraData?: InputMaybe<Scalars['JSONObject']['input']>;
  files?: InputMaybe<Array<FileChangeInput>>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type JobsiteFormSubmissionDocumentsConnection = {
  __typename?: 'JobsiteFormSubmissionDocumentsConnection';
  count: Scalars['Int']['output'];
  edges: Array<JobsiteFormSubmissionDocumentsEdge>;
};

export type JobsiteFormSubmissionDocumentsEdge = {
  __typename?: 'JobsiteFormSubmissionDocumentsEdge';
  node: JobsiteFormSubmissionDocument;
};

export type JobsiteFormSubmissionDocumentsInput = {
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
};

export type JobsiteFormSubmissionWorker = {
  __typename?: 'JobsiteFormSubmissionWorker';
  associationType: Scalars['String']['output'];
  extraData?: Maybe<Scalars['JSONObject']['output']>;
  historicalData: JobsiteFormSubmissionWorkerHistoricalData;
  id: Scalars['ID']['output'];
  jobsiteWorker: JobsiteWorker;
  objectHistory: ObjectHistory;
};

export type JobsiteFormSubmissionWorkerHistoricalData = {
  __typename?: 'JobsiteFormSubmissionWorkerHistoricalData';
  jobsiteWorkerAccess?: Maybe<JobsiteWorkerAccess>;
};

export type JobsiteFormSubmissionWorkerInput = {
  associationType: Scalars['String']['input'];
  changeType: ChangeType;
  extraData?: InputMaybe<Scalars['JSONObject']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  jobsiteWorkerId?: InputMaybe<Scalars['ID']['input']>;
};

export type JobsiteFormSubmissionWorkersConnection = {
  __typename?: 'JobsiteFormSubmissionWorkersConnection';
  count: Scalars['Int']['output'];
  edges: Array<JobsiteFormSubmissionWorkersEdge>;
};

export type JobsiteFormSubmissionWorkersEdge = {
  __typename?: 'JobsiteFormSubmissionWorkersEdge';
  node: JobsiteFormSubmissionWorker;
};

export type JobsiteFormSubmissionWorkersInput = {
  associationTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
  reverseChronological?: InputMaybe<Scalars['Boolean']['input']>;
};

export type JobsiteFormsConnection = {
  __typename?: 'JobsiteFormsConnection';
  count: Scalars['Int']['output'];
  edges: Array<JobsiteFormsEdge>;
};

export type JobsiteFormsEdge = {
  __typename?: 'JobsiteFormsEdge';
  node: JobsiteForm;
};

export type JobsiteFormsInput = {
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
};

export type JobsiteInvitation = {
  __typename?: 'JobsiteInvitation';
  createdBy: User;
  expirationDate?: Maybe<Scalars['Date']['output']>;
  jobsiteContractor: JobsiteContractor;
  jobsiteInvitationId: Scalars['ID']['output'];
};

export type JobsiteJobsiteContractorsConnection = {
  __typename?: 'JobsiteJobsiteContractorsConnection';
  count: Scalars['Int']['output'];
  edges: Array<JobsiteJobsiteContractorsEdge>;
};

export type JobsiteJobsiteContractorsEdge = {
  __typename?: 'JobsiteJobsiteContractorsEdge';
  node: JobsiteContractor;
};

export type JobsiteJobsiteContractorsInput = {
  contractorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type JobsiteJobsiteMembersConnection = {
  __typename?: 'JobsiteJobsiteMembersConnection';
  count: Scalars['Int']['output'];
  edges: Array<JobsiteJobsiteMembersEdge>;
};

export type JobsiteJobsiteMembersEdge = {
  __typename?: 'JobsiteJobsiteMembersEdge';
  node: JobsiteMember;
};

export type JobsiteJobsiteMembersInput = {
  orderBy?: InputMaybe<Array<InputMaybe<GetUsersOrderBy>>>;
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type JobsiteJobsiteWorkersConnection = {
  __typename?: 'JobsiteJobsiteWorkersConnection';
  count: Scalars['Int']['output'];
  edges: Array<JobsiteJobsiteWorkersEdge>;
};

export type JobsiteJobsiteWorkersEdge = {
  __typename?: 'JobsiteJobsiteWorkersEdge';
  node: JobsiteWorker;
};

export type JobsiteJobsiteWorkersInput = {
  contractorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  onSite?: InputMaybe<Scalars['Boolean']['input']>;
  onSiteDate?: InputMaybe<Scalars['Date']['input']>;
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  tradeClasses?: InputMaybe<Array<Scalars['String']['input']>>;
  trades?: InputMaybe<Array<Scalars['String']['input']>>;
};

/**
 * JobsiteMember return type. A member is any non-worker who is employed by the jobsite, such as an owner,
 * manager, or back office employee.
 */
export type JobsiteMember = {
  __typename?: 'JobsiteMember';
  id: Scalars['ID']['output'];
  /** The full jobsite object that the member is at */
  jobsite: Jobsite;
  /** History of the object */
  objectHistory: ObjectHistory;
  /** Title of the member at the jobsite. */
  title?: Maybe<Scalars['String']['output']>;
  /** The full user object of the member */
  user: User;
};

export type JobsiteModule = {
  isEnabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export enum JobsiteModuleType {
  JobsiteAppCheckInModule = 'JobsiteAppCheckInModule',
  JobsiteFeaturesModule = 'JobsiteFeaturesModule',
  JobsiteOnboardingModule = 'JobsiteOnboardingModule',
  JobsitePreOnboardingModule = 'JobsitePreOnboardingModule',
}

export type JobsiteOnboardingDocument = {
  __typename?: 'JobsiteOnboardingDocument';
  expiration?: Maybe<Scalars['Duration']['output']>;
  fields?: Maybe<Array<JobsiteOnboardingField>>;
  gracePeriod?: Maybe<Scalars['Duration']['output']>;
  index?: Maybe<Scalars['Int']['output']>;
  isRequired?: Maybe<Scalars['Boolean']['output']>;
  isVisible?: Maybe<Scalars['Boolean']['output']>;
  key: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type JobsiteOnboardingDocumentFileInput = {
  documentTypeKey: Scalars['String']['input'];
  files: Array<FileChangeInput>;
};

export type JobsiteOnboardingDocumentInput = {
  expiration?: InputMaybe<Scalars['Duration']['input']>;
  fields?: InputMaybe<Array<JobsiteOnboardingFieldInput>>;
  gracePeriod?: InputMaybe<Scalars['Duration']['input']>;
  index?: InputMaybe<Scalars['Int']['input']>;
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  isVisible?: InputMaybe<Scalars['Boolean']['input']>;
  key: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type JobsiteOnboardingField = {
  __typename?: 'JobsiteOnboardingField';
  index?: Maybe<Scalars['Int']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  isRequired?: Maybe<Scalars['Boolean']['output']>;
  key: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type JobsiteOnboardingFieldInput = {
  index?: InputMaybe<Scalars['Int']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  key: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type JobsiteOnboardingModule = JobsiteModule & {
  __typename?: 'JobsiteOnboardingModule';
  badgeAssignmentAllowed?: Maybe<Scalars['Boolean']['output']>;
  badgePrintingAllowed?: Maybe<Scalars['Boolean']['output']>;
  documents?: Maybe<Array<JobsiteOnboardingDocument>>;
  isEnabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  steps?: Maybe<Array<JobsiteOnboardingStep>>;
};

export type JobsiteOnboardingModuleInput = {
  badgeAssignmentAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  badgePrintingAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  documents?: InputMaybe<Array<JobsiteOnboardingDocumentInput>>;
  isEnabled: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  slug: Scalars['String']['input'];
  steps?: InputMaybe<Array<JobsiteOnboardingStepInput>>;
};

export type JobsiteOnboardingStep = {
  __typename?: 'JobsiteOnboardingStep';
  fields?: Maybe<Array<JobsiteOnboardingField>>;
  index?: Maybe<Scalars['Int']['output']>;
  isRequired?: Maybe<Scalars['Boolean']['output']>;
  isVisible?: Maybe<Scalars['Boolean']['output']>;
  key: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type JobsiteOnboardingStepInput = {
  fields?: InputMaybe<Array<JobsiteOnboardingFieldInput>>;
  index?: InputMaybe<Scalars['Int']['input']>;
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  isVisible?: InputMaybe<Scalars['Boolean']['input']>;
  key: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type JobsitePacsGateway = {
  __typename?: 'JobsitePacsGateway';
  id: Scalars['ID']['output'];
  jobsite: Jobsite;
  name: Scalars['String']['output'];
};

export type JobsitePreOnboardingField = {
  __typename?: 'JobsitePreOnboardingField';
  index?: Maybe<Scalars['Int']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  isRequired?: Maybe<Scalars['Boolean']['output']>;
  key: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type JobsitePreOnboardingFieldInput = {
  index?: InputMaybe<Scalars['Int']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  key: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type JobsitePreOnboardingModule = JobsiteModule & {
  __typename?: 'JobsitePreOnboardingModule';
  isEnabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  steps?: Maybe<Array<JobsitePreOnboardingStep>>;
};

export type JobsitePreOnboardingModuleInput = {
  isEnabled: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  slug: Scalars['String']['input'];
  steps?: InputMaybe<Array<JobsitePreOnboardingStepInput>>;
};

export type JobsitePreOnboardingStep = {
  __typename?: 'JobsitePreOnboardingStep';
  fields?: Maybe<Array<JobsitePreOnboardingField>>;
  index?: Maybe<Scalars['Int']['output']>;
  isRequired?: Maybe<Scalars['Boolean']['output']>;
  isVisible?: Maybe<Scalars['Boolean']['output']>;
  key: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type JobsitePreOnboardingStepInput = {
  fields?: InputMaybe<Array<JobsitePreOnboardingFieldInput>>;
  index?: InputMaybe<Scalars['Int']['input']>;
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  isVisible?: InputMaybe<Scalars['Boolean']['input']>;
  key: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type JobsiteRemoveContractorInput = {
  jobsiteContractorId: Scalars['ID']['input'];
};

export type JobsiteRemoveContractorPayload = {
  __typename?: 'JobsiteRemoveContractorPayload';
  success: Success;
};

export type JobsiteRemoveFormInput = {
  jobsiteFormId: Scalars['ID']['input'];
};

export type JobsiteRemoveFormPayload = {
  __typename?: 'JobsiteRemoveFormPayload';
  success: Success;
};

export type JobsiteStats = {
  __typename?: 'JobsiteStats';
  jobsite?: Maybe<Jobsite>;
  numberOfActiveWorkers: Scalars['Int']['output'];
  numberOfOrientationWorkersCompleted: Scalars['Int']['output'];
  numberOfOrientationWorkersInProgress: Scalars['Int']['output'];
  numberOfOrientationWorkersNeedingBadging: Scalars['Int']['output'];
  numberOfOrientationWorkersNeedingMedical: Scalars['Int']['output'];
  numberOfOrientationWorkersSelfOnboarding: Scalars['Int']['output'];
  numberOfOrientationWorkersTotal: Scalars['Int']['output'];
  numberOfWorkers: Scalars['Int']['output'];
};

export type JobsiteUpdateContractorInput = {
  jobsiteContractorId: Scalars['ID']['input'];
  jobsiteContractorInput: JobsiteContractorInput;
};

export type JobsiteUpdateContractorPayload = {
  __typename?: 'JobsiteUpdateContractorPayload';
  jobsiteContractor: JobsiteContractor;
};

/** Input for updating a jobsite form submission */
export type JobsiteUpdateFormSubmissionInput = {
  documents?: InputMaybe<Array<JobsiteFormSubmissionDocumentInput>>;
  endAt?: InputMaybe<Scalars['Date']['input']>;
  extraData?: InputMaybe<Scalars['JSONObject']['input']>;
  id: Scalars['ID']['input'];
  jobsiteContractors?: InputMaybe<Array<JobsiteFormSubmissionContractorInput>>;
  jobsiteWorkers?: InputMaybe<Array<JobsiteFormSubmissionWorkerInput>>;
  startAt?: InputMaybe<Scalars['Date']['input']>;
};

export type JobsiteUpdateFormSubmissionPayload = {
  __typename?: 'JobsiteUpdateFormSubmissionPayload';
  jobsiteFormSubmission: JobsiteFormSubmission;
};

/** Input to update a jobsite */
export type JobsiteUpdateInput = {
  accessChangeEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  addressCity?: InputMaybe<Scalars['String']['input']>;
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  addressState?: InputMaybe<StateAbbreviation>;
  addressZipCode?: InputMaybe<Scalars['String']['input']>;
  allowOvernightSessions?: InputMaybe<Scalars['Boolean']['input']>;
  appCheckInModule?: InputMaybe<JobsiteAppCheckInModuleInput>;
  assignmentChangeEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  avigilonEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  cCureClearances?: InputMaybe<Array<JobsiteCCureClearanceInput>>;
  cCureEntityMappings?: InputMaybe<Array<JobsiteCCureEntityMappingInput>>;
  dailyAccessReportEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  defaultProximityCardId?: InputMaybe<Scalars['ID']['input']>;
  defaultQrCodeId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  developerId?: InputMaybe<Scalars['ID']['input']>;
  deviceStatusChangeEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  escortChangeEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  fargoLocationId?: InputMaybe<Scalars['String']['input']>;
  fargoOrganizationId?: InputMaybe<Scalars['String']['input']>;
  featuresModule?: InputMaybe<JobsiteFeaturesModuleInput>;
  formIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  hidCardMappingTest?: InputMaybe<Scalars['String']['input']>;
  hidCardMappingUnion?: InputMaybe<Scalars['String']['input']>;
  hidPrinterId?: InputMaybe<Scalars['String']['input']>;
  hidProxyInstance?: InputMaybe<HidProxyInstance>;
  hideFromContractorUsers?: InputMaybe<Scalars['Boolean']['input']>;
  jobsiteBadgeTemplateInputs?: InputMaybe<Array<JobsiteBadgeTemplateInput>>;
  jobsiteId: Scalars['ID']['input'];
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  maximumSessionLength?: InputMaybe<Scalars['Duration']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  onboardingDocumentFiles?: InputMaybe<Array<JobsiteOnboardingDocumentFileInput>>;
  onboardingGracePeriod?: InputMaybe<Scalars['Duration']['input']>;
  onboardingModule?: InputMaybe<JobsiteOnboardingModuleInput>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
  preOnboardingModule?: InputMaybe<JobsitePreOnboardingModuleInput>;
  procoreCompanyId?: InputMaybe<Scalars['String']['input']>;
  procoreProjectId?: InputMaybe<Scalars['String']['input']>;
  rejectedSwipeEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  reportingTimeSlot?: InputMaybe<Scalars['String']['input']>;
  sessionDisplayColumn?: InputMaybe<SessionDisplayColumns>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  supportedCardFormatIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  swipeInactivityThreshold?: InputMaybe<Scalars['Duration']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
  weeklyAccessReportEmails?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type JobsiteUpdatePayload = {
  __typename?: 'JobsiteUpdatePayload';
  jobsite: Jobsite;
};

/**
 * JobsiteWorker return type. A worker is any non-worker who is employed by the jobsite, such as an owner,
 * manager, or back office employee.
 */
export type JobsiteWorker = {
  __typename?: 'JobsiteWorker';
  /** Completion of badging */
  badgingCompletedAt?: Maybe<Scalars['Date']['output']>;
  /** Reason for skipping badging */
  badgingSkipReason?: Maybe<Scalars['String']['output']>;
  /** The reason the worker is banned from the jobsite */
  banCategory?: Maybe<Scalars['String']['output']>;
  /** When the temp ban is set to expire */
  banExpiresAt?: Maybe<Scalars['Date']['output']>;
  banRemovedReason?: Maybe<Scalars['String']['output']>;
  /** When the worker was banned from the jobsite */
  bannedAt?: Maybe<Scalars['Date']['output']>;
  /** Notes regarding why the worker is banned from the jobsite */
  bannedReason?: Maybe<Scalars['String']['output']>;
  breathTestConditionalPassExpiresAt?: Maybe<Scalars['Date']['output']>;
  breathTestLastTakenAt?: Maybe<Scalars['Date']['output']>;
  breathTestStatus?: Maybe<JobsiteWorkerTestStatus>;
  clearanceAssignments?: Maybe<JobsiteWorkerClearanceAssignmentsConnection>;
  contractorWorker: ContractorWorker;
  currentAccess: JobsiteWorkerCurrentAccess;
  documentExemptionReason?: Maybe<Scalars['String']['output']>;
  documents: JobsiteWorkerDocumentsConnection;
  /** DateTime jobsite worker documents signed/uploaded */
  documentsCompletedAt?: Maybe<Scalars['Date']['output']>;
  /** Date that reason for skipping documents expires */
  documentsCompletedSkipExpiresAt?: Maybe<Scalars['Date']['output']>;
  /** Reason for skipping documents signing */
  documentsCompletedSkipReason?: Maybe<Scalars['String']['output']>;
  /** When the worker stops working at the site */
  endDate?: Maybe<Scalars['Date']['output']>;
  isAntipassbackExempt?: Maybe<Scalars['Boolean']['output']>;
  /** Indicate whether the worker is banned from the jobsite */
  isBanned?: Maybe<Scalars['Boolean']['output']>;
  isExemptFromSiteSpecificRefresher?: Maybe<Scalars['Boolean']['output']>;
  isExemptFromSwipeInactivity?: Maybe<Scalars['Boolean']['output']>;
  isOnSite?: Maybe<Scalars['Boolean']['output']>;
  /** Completion of Jobite Specific Training */
  isSiteSpecificTrainingExpired?: Maybe<Scalars['Boolean']['output']>;
  jobsiteAccessSessions?: Maybe<JobsiteWorkerJobsiteAccessSessionsConnection>;
  jobsiteContractor: JobsiteContractor;
  jobsiteWorkerId: Scalars['ID']['output'];
  latestAccessEvent?: Maybe<JobsiteAccessEvent>;
  /** Date that reason for skipping medical exam expires */
  medicalExamSkipExpiresAt?: Maybe<Scalars['Date']['output']>;
  /** Reason for skipping medical exam */
  medicalExamSkipReason?: Maybe<Scalars['String']['output']>;
  medicalNotes?: Maybe<Scalars['String']['output']>;
  nycSstExemptionReason?: Maybe<Scalars['String']['output']>;
  /** History of the object */
  objectHistory: ObjectHistory;
  /** DateTime jobsite worker onboarding signed */
  onboardingSignedAt?: Maybe<Scalars['Date']['output']>;
  /** Date that reason for skipping onboarding expires */
  onboardingSkipExpiresAt?: Maybe<Scalars['Date']['output']>;
  /** Reason for skip signing of onboarding documents */
  onboardingSkipReason?: Maybe<Scalars['String']['output']>;
  /** Date of the worker's orientation */
  orientationDate?: Maybe<Scalars['Date']['output']>;
  /** Status of the worker's orientation */
  orientationStatus?: Maybe<JobsiteWorkerOrientationStatus>;
  oshaCardExpiresAt?: Maybe<Scalars['Date']['output']>;
  oshaCardType?: Maybe<Scalars['String']['output']>;
  /** DateTime jobsite worker passsing medical examination */
  passedMedicalExamAt?: Maybe<Scalars['Date']['output']>;
  /** Payroll ID of the worker at the contractor */
  payrollId?: Maybe<Scalars['String']['output']>;
  /** Reason for skipping the completion of profile */
  profileCompleteSkipReason?: Maybe<Scalars['String']['output']>;
  /** DateTime jobsite worker onboarding profile is done */
  profileCompletedAt?: Maybe<Scalars['Date']['output']>;
  /** Completion of Signature */
  signatureCompletedAt?: Maybe<Scalars['Date']['output']>;
  /** Reason for skipping signature */
  signatureSkipReason?: Maybe<Scalars['String']['output']>;
  /** Name of person who approved site access change */
  siteAccessChangeApprovedBy?: Maybe<Scalars['String']['output']>;
  /** Completion of Jobite Specific Training */
  siteSpecificTrainingCompletedAt?: Maybe<Scalars['Date']['output']>;
  /** Date that reason for skipping site specific training expires */
  siteSpecificTrainingSkipExpiresAt?: Maybe<Scalars['Date']['output']>;
  /** Reason for skipping site specific training */
  siteSpecificTrainingSkipReason?: Maybe<Scalars['String']['output']>;
  sstExpiresAt?: Maybe<Scalars['Date']['output']>;
  sstType?: Maybe<Scalars['String']['output']>;
  /** When the worker started at the site */
  startDate?: Maybe<Scalars['Date']['output']>;
  /** Number on the worker's jobsite hardhat sticker */
  stickerNumber?: Maybe<Scalars['Int']['output']>;
  urineTestConditionalPassExpiresAt?: Maybe<Scalars['Date']['output']>;
  urineTestLastTakenAt?: Maybe<Scalars['Date']['output']>;
  urineTestStatus?: Maybe<JobsiteWorkerTestStatus>;
};

/**
 * JobsiteWorker return type. A worker is any non-worker who is employed by the jobsite, such as an owner,
 * manager, or back office employee.
 */
export type JobsiteWorkerDocumentsArgs = {
  input?: InputMaybe<JobsiteWorkerDocumentsInput>;
};

/**
 * JobsiteWorker return type. A worker is any non-worker who is employed by the jobsite, such as an owner,
 * manager, or back office employee.
 */
export type JobsiteWorkerJobsiteAccessSessionsArgs = {
  input?: InputMaybe<JobsiteWorkerJobsiteAccessSessionsInput>;
};

export type JobsiteWorkerAccess = {
  __typename?: 'JobsiteWorkerAccess';
  /** The last date the worker is allowed on site */
  endDate: Scalars['Date']['output'];
  isAllowedNow: Scalars['Boolean']['output'];
  isAntipassbackExempt?: Maybe<Scalars['Boolean']['output']>;
  /** When the worker is allowed back to this site. If null, worker is disabled indefinitely */
  jobsiteAccessReadmissionDate?: Maybe<Scalars['Date']['output']>;
  /** When the worker's access was disabled to this site. */
  jobsiteDisabledAccessDate?: Maybe<Scalars['Date']['output']>;
  /** Reason worker is not allowed on this site */
  jobsiteDisabledAccessReason?: Maybe<Scalars['String']['output']>;
  /** Indicates whether the worker has access disabled to this site. */
  jobsiteIsDisabledAccess?: Maybe<Scalars['Boolean']['output']>;
  jobsiteWorker: JobsiteWorker;
  notAllowedReason?: Maybe<Scalars['String']['output']>;
  nycSstCardType?: Maybe<Scalars['String']['output']>;
  nycSstExemptionReason?: Maybe<Scalars['String']['output']>;
  objectHistory?: Maybe<ObjectHistory>;
  orientationMedicalCompletedAt?: Maybe<Scalars['Date']['output']>;
  orientationMedicalExpiredAt?: Maybe<Scalars['Date']['output']>;
  orientationMedicalSkipReason?: Maybe<Scalars['String']['output']>;
  orientationSafetyTrainingCompletedAt?: Maybe<Scalars['Date']['output']>;
  orientationSafetyTrainingExpiredAt?: Maybe<Scalars['Date']['output']>;
  orientationSafetyTrainingSkipReason?: Maybe<Scalars['String']['output']>;
  safetyDocumentStatus?: Maybe<SafetyDocumentStatus>;
  safetyDocumentsCompletedAt?: Maybe<Scalars['Date']['output']>;
  safetyDocumentsExpiredAt?: Maybe<Scalars['Date']['output']>;
  safetyDocumentsSkipReason?: Maybe<Scalars['String']['output']>;
  siteSpecificTrainingCompletedAt?: Maybe<Scalars['Date']['output']>;
  siteSpecificTrainingExpiredAt?: Maybe<Scalars['Date']['output']>;
  siteSpecificTrainingSkipReason?: Maybe<Scalars['String']['output']>;
  /** The first date the worker is allowed on site */
  startDate: Scalars['Date']['output'];
  /** When the worker is allowed back to all sites. If null, worker is disabled indefinitely */
  workerAccessReadmissionDate?: Maybe<Scalars['Date']['output']>;
  /** When the worker's access was disabled to all sites. */
  workerDisabledAccessDate?: Maybe<Scalars['Date']['output']>;
  /** Reason worker is not allowed on any site */
  workerDisabledAccessReason?: Maybe<Scalars['String']['output']>;
  /** Indicates whether the worker has access disabled to all sites. */
  workerIsDisabledAccess?: Maybe<Scalars['Boolean']['output']>;
};

export type JobsiteWorkerCheckIn = {
  __typename?: 'JobsiteWorkerCheckIn';
  area: JobsiteAppCheckInModuleArea;
  checkInDateTime: Scalars['Date']['output'];
  checkInStatus?: Maybe<JobsiteWorkerCheckInStatus>;
  checkInStatusNotes?: Maybe<JobsiteAccessEventRejectionReason>;
  direction?: Maybe<JobsiteWorkerCheckInDirection>;
  flags?: Maybe<Array<JobsiteWorkerCheckInFlag>>;
  id: Scalars['ID']['output'];
  jobsite: Jobsite;
  jobsiteWorker?: Maybe<JobsiteWorker>;
  jobsiteWorkerCheckInId: Scalars['ID']['output'];
  location?: Maybe<GpsCoordinate>;
  locationStatus?: Maybe<JobsiteWorkerCheckInLocationStatus>;
  reason?: Maybe<Scalars['String']['output']>;
  source?: Maybe<JobsiteWorkerCheckInSource>;
  type: JobsiteWorkerCheckInType;
  worker?: Maybe<Worker>;
};

export type JobsiteWorkerCheckInAppFarFromAreaFlag = JobsiteWorkerCheckInFlag & {
  __typename?: 'JobsiteWorkerCheckInAppFarFromAreaFlag';
  accuracy: Scalars['Float']['output'];
  coordinate: GpsCoordinate;
  message: Scalars['String']['output'];
};

export type JobsiteWorkerCheckInArea = {
  id: Scalars['ID']['output'];
  isInboundEnabled: Scalars['Boolean']['output'];
  isOutboundEnabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type JobsiteWorkerCheckInConnection = {
  __typename?: 'JobsiteWorkerCheckInConnection';
  edges: Array<JobsiteWorkerCheckIn>;
  totalCount: Scalars['Int']['output'];
};

export enum JobsiteWorkerCheckInDirection {
  Inbound = 'Inbound',
  Outbound = 'Outbound',
}

export type JobsiteWorkerCheckInFlag = {
  message: Scalars['String']['output'];
};

export enum JobsiteWorkerCheckInLocationStatus {
  LocationNotAvailable = 'LocationNotAvailable',
  Nearby = 'Nearby',
  Offsite = 'Offsite',
  Onsite = 'Onsite',
}

export type JobsiteWorkerCheckInManualOverrideFlag = JobsiteWorkerCheckInFlag & {
  __typename?: 'JobsiteWorkerCheckInManualOverrideFlag';
  message: Scalars['String']['output'];
  overrideReason?: Maybe<Scalars['String']['output']>;
  overrideUser: User;
};

export enum JobsiteWorkerCheckInSource {
  /** Check-in was done using admin-check-in feature */
  AdminCheckIn = 'AdminCheckIn',
  /** Check-in was done using badge-scanning feature */
  BadgeScanning = 'BadgeScanning',
  /** Check-in was done using kiosk-mode */
  KioskMode = 'KioskMode',
  /** Check-in was done using self-check-in feature */
  SelfCheckIn = 'SelfCheckIn',
}

export enum JobsiteWorkerCheckInStatus {
  Admit = 'Admit',
  Reject = 'Reject',
}

export type JobsiteWorkerCheckInSuggestion = {
  __typename?: 'JobsiteWorkerCheckInSuggestion';
  area: JobsiteAppCheckInModuleArea;
  direction: JobsiteWorkerCheckInDirection;
  flags?: Maybe<Array<JobsiteWorkerCheckInFlag>>;
  id: Scalars['ID']['output'];
  jobsiteWorker: JobsiteWorker;
};

export type JobsiteWorkerCheckInSuggestionConnection = {
  __typename?: 'JobsiteWorkerCheckInSuggestionConnection';
  edges: Array<JobsiteWorkerCheckInSuggestion>;
  totalCount: Scalars['Int']['output'];
};

export enum JobsiteWorkerCheckInType {
  BluetoothPing = 'BluetoothPing',
  CardSwipe = 'CardSwipe',
  MobileAppCheckIn = 'MobileAppCheckIn',
  WebAppCheckIn = 'WebAppCheckIn',
}

export type JobsiteWorkerClearanceAssignment = {
  __typename?: 'JobsiteWorkerClearanceAssignment';
  clearance: CCureClearance;
  clearanceType?: Maybe<JobsiteClearanceType>;
  id?: Maybe<Scalars['String']['output']>;
  jobsiteWorker: JobsiteWorker;
  objectHistory: ObjectHistory;
};

export type JobsiteWorkerClearanceAssignmentEdge = {
  __typename?: 'JobsiteWorkerClearanceAssignmentEdge';
  node?: Maybe<JobsiteWorkerClearanceAssignment>;
};

export type JobsiteWorkerClearanceAssignmentInput = {
  clearanceId: Scalars['ID']['input'];
  clearanceType?: InputMaybe<JobsiteClearanceType>;
  id?: InputMaybe<Scalars['String']['input']>;
  jobsiteWorkerId: Scalars['ID']['input'];
};

export type JobsiteWorkerClearanceAssignmentsConnection = {
  __typename?: 'JobsiteWorkerClearanceAssignmentsConnection';
  count: Scalars['Int']['output'];
  edges: Array<Maybe<JobsiteWorkerClearanceAssignmentEdge>>;
};

/**
 * JobsiteWorkerCompact is a lightweight version of the JobsiteWorker data, with only the relevant fields
 * This is a reflection of the worker object stored/enriched in elastic
 */
export type JobsiteWorkerCompact = {
  __typename?: 'JobsiteWorkerCompact';
  contractorId?: Maybe<Scalars['String']['output']>;
  contractorName?: Maybe<Scalars['String']['output']>;
  croppedDownloadKey?: Maybe<Scalars['String']['output']>;
  downloadKey?: Maybe<Scalars['String']['output']>;
  fitoutTenant?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  jobsiteId?: Maybe<Scalars['String']['output']>;
  jobsiteName?: Maybe<Scalars['String']['output']>;
  jobsiteWorkerId?: Maybe<Scalars['String']['output']>;
  profile?: Maybe<AdditionalWorkerProfileData>;
  status?: Maybe<Scalars['String']['output']>;
  trade?: Maybe<Scalars['String']['output']>;
  tradeClass?: Maybe<Scalars['String']['output']>;
  tradeSubclass?: Maybe<Scalars['String']['output']>;
  unionAffiliation?: Maybe<Scalars['String']['output']>;
  workType?: Maybe<Scalars['String']['output']>;
  workerCardId?: Maybe<Scalars['String']['output']>;
  workerId?: Maybe<Scalars['String']['output']>;
  workerStatus?: Maybe<Scalars['String']['output']>;
};

export type JobsiteWorkerConnection = {
  __typename?: 'JobsiteWorkerConnection';
  edges: Array<JobsiteWorkerAccess>;
  jobsiteStats?: Maybe<JobsiteStats>;
  totalCount: Scalars['Int']['output'];
};

export type JobsiteWorkerCurrentAccess = {
  __typename?: 'JobsiteWorkerCurrentAccess';
  endDate: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  isAllowed: Scalars['Boolean']['output'];
  notAllowedReason?: Maybe<Scalars['String']['output']>;
  startDate: Scalars['Date']['output'];
};

/** JobsiteWorkerDocument return type. */
export type JobsiteWorkerDocument = {
  __typename?: 'JobsiteWorkerDocument';
  /** Optional JobsiteWorker Associated with Document */
  jobsiteWorker?: Maybe<JobsiteWorker>;
  /** ID of the jobsite worker document */
  jobsiteWorkerDocumentId: Scalars['ID']['output'];
  /** Document Type */
  jobsiteWorkerDocumentType: JobsiteWorkerDocumentType;
  /** Available versions of the specific document */
  jobsiteWorkerDocumentVersions: Array<JobsiteWorkerDocumentVersion>;
  /** Unique human readable ID of document */
  key: Scalars['String']['output'];
  latestVersion: JobsiteWorkerDocumentVersion;
  objectHistory: ObjectHistory;
  /** Worker Associated with Document */
  worker: Worker;
};

export type JobsiteWorkerDocumentInput = {
  forceCreate?: InputMaybe<Scalars['Boolean']['input']>;
  jobsiteWorkerDocumentTypeId: Scalars['ID']['input'];
  jobsiteWorkerId: Scalars['ID']['input'];
  /** Unique human readable id for given document */
  key: Scalars['String']['input'];
};

/** JobsiteWorkerDocumentType return type. */
export type JobsiteWorkerDocumentType = {
  __typename?: 'JobsiteWorkerDocumentType';
  additionalFields: Array<WorkerDocumentAdditionalField>;
  expiration?: Maybe<Scalars['Duration']['output']>;
  files: Array<File>;
  /** ID of the jobsite worker document */
  id: Scalars['ID']['output'];
  /** The full jobsite object */
  jobsite: Jobsite;
  workerDocumentType: WorkerDocumentType;
};

export type JobsiteWorkerDocumentTypesConnection = {
  __typename?: 'JobsiteWorkerDocumentTypesConnection';
  count: Scalars['Int']['output'];
  edges: Array<JobsiteWorkerDocumentTypesEdge>;
};

export type JobsiteWorkerDocumentTypesEdge = {
  __typename?: 'JobsiteWorkerDocumentTypesEdge';
  node: JobsiteWorkerDocumentType;
};

export type JobsiteWorkerDocumentTypesInput = {
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
};

export type JobsiteWorkerDocumentUpsertInput = {
  additionalFieldValues?: InputMaybe<Array<AdditionalFieldValueInput>>;
  files?: InputMaybe<Array<FileChangeInput>>;
  jobsiteWorkerDocumentTypeId: Scalars['ID']['input'];
  jobsiteWorkerDocumentVersionId?: InputMaybe<Scalars['ID']['input']>;
  jobsiteWorkerId: Scalars['ID']['input'];
  key: Scalars['String']['input'];
};

export type JobsiteWorkerDocumentVersion = {
  __typename?: 'JobsiteWorkerDocumentVersion';
  /** Optional additional field values */
  additionalFieldValues?: Maybe<Array<AdditionalFieldValue>>;
  /** Optional expiration date of the document */
  expirationDate: Scalars['Date']['output'];
  /** The files associated with the document. */
  files: Array<File>;
  /** ID of the jobsite worker document */
  jobsiteWorkerDocumentVersionId: Scalars['ID']['output'];
  objectHistory: ObjectHistory;
};

export type JobsiteWorkerDocumentVersionInput = {
  additionalFieldValues?: InputMaybe<Array<AdditionalFieldValueInput>>;
  expiresAt?: InputMaybe<Scalars['Date']['input']>;
  fileIds: Array<Scalars['ID']['input']>;
};

export type JobsiteWorkerDocumentsConnection = {
  __typename?: 'JobsiteWorkerDocumentsConnection';
  count: Scalars['Int']['output'];
  edges: Array<JobsiteWorkerDocumentsEdge>;
};

export type JobsiteWorkerDocumentsEdge = {
  __typename?: 'JobsiteWorkerDocumentsEdge';
  node: JobsiteWorkerDocument;
};

export type JobsiteWorkerDocumentsInput = {
  includePhi?: InputMaybe<Scalars['Boolean']['input']>;
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
};

/** Input for saving jobsite specific information for a worker as part of onboarding */
export type JobsiteWorkerInformationInput = {
  contractorId?: InputMaybe<Scalars['ID']['input']>;
  /** When the worker stops working at the site */
  endDate?: InputMaybe<Scalars['Date']['input']>;
  jobsiteWorkerId: Scalars['ID']['input'];
  /** Payroll ID of the worker at the contractor */
  payrollId?: InputMaybe<Scalars['String']['input']>;
  /** When the worker started at the site */
  startDate?: InputMaybe<Scalars['Date']['input']>;
  /** Number on the worker's jobsite hardhat sticker */
  stickerNumber?: InputMaybe<Scalars['Int']['input']>;
};

/** Input for assigning a worker to a jobsite or updating their info at the jobsite */
export type JobsiteWorkerInput = {
  /** Completion of badging */
  badgingCompletedAt?: InputMaybe<Scalars['Date']['input']>;
  /** Reason for skipping badging */
  badgingSkipReason?: InputMaybe<Scalars['String']['input']>;
  /** The reason the worker is banned from the jobsite */
  banCategory?: InputMaybe<Scalars['String']['input']>;
  /** When the temp ban is set to expire */
  banExpiresAt?: InputMaybe<Scalars['Date']['input']>;
  banRemovedReason?: InputMaybe<Scalars['String']['input']>;
  /** When the worker was banned from the jobsite */
  bannedAt?: InputMaybe<Scalars['Date']['input']>;
  /** Notes regarding why the worker is banned from the jobsite */
  bannedReason?: InputMaybe<Scalars['String']['input']>;
  clearanceAssignments?: InputMaybe<Array<JobsiteWorkerClearanceAssignmentInput>>;
  /** Date that document exemption expires */
  documentExemptionReason?: InputMaybe<Scalars['String']['input']>;
  /** DateTime jobsite worker documents signed/uploaded */
  documentsCompletedAt?: InputMaybe<Scalars['Date']['input']>;
  /** Date that reason for skipping documents expires */
  documentsCompletedSkipExpiresAt?: InputMaybe<Scalars['Date']['input']>;
  /** Reason for skipping documents signing */
  documentsCompletedSkipReason?: InputMaybe<Scalars['String']['input']>;
  /** When the worker stops working at the site */
  endDate?: InputMaybe<Scalars['Date']['input']>;
  fitoutTenant?: InputMaybe<Scalars['String']['input']>;
  isAntipassbackExempt?: InputMaybe<Scalars['Boolean']['input']>;
  /** Indicate whether the worker is banned from the jobsite */
  isBanned?: InputMaybe<Scalars['Boolean']['input']>;
  isExemptFromSiteSpecificRefresher?: InputMaybe<Scalars['Boolean']['input']>;
  isExemptFromSwipeInactivity?: InputMaybe<Scalars['Boolean']['input']>;
  /** Date that reason for skipping medical exam expires */
  medicalExamSkipExpiresAt?: InputMaybe<Scalars['Date']['input']>;
  /** Reason for skipping medical exam */
  medicalExamSkipReason?: InputMaybe<Scalars['String']['input']>;
  medicalNotes?: InputMaybe<Scalars['String']['input']>;
  nycSstExemptionReason?: InputMaybe<Scalars['String']['input']>;
  /** DateTime jobsite worker onboarding signed */
  onboardingSignedAt?: InputMaybe<Scalars['Date']['input']>;
  /** Date that reason for skipping onboarding expires */
  onboardingSkipExpiresAt?: InputMaybe<Scalars['Date']['input']>;
  /** Reason for skip signing of onboarding documents */
  onboardingSkipReason?: InputMaybe<Scalars['String']['input']>;
  /** Date of the worker's orientation */
  orientationDate?: InputMaybe<Scalars['Date']['input']>;
  /** Status of the worker's orientation */
  orientationStatus?: InputMaybe<JobsiteWorkerOrientationStatus>;
  /** DateTime jobsite worker passsing medical examination */
  passedMedicalExamAt?: InputMaybe<Scalars['Date']['input']>;
  /** Payroll ID of the worker at the contractor */
  payrollId?: InputMaybe<Scalars['String']['input']>;
  /** Reason for skipping the completion of profile */
  profileCompleteSkipReason?: InputMaybe<Scalars['String']['input']>;
  /** DateTime jobsite worker onboarding profile is done */
  profileCompletedAt?: InputMaybe<Scalars['Date']['input']>;
  /** Completion of Signature */
  signatureCompletedAt?: InputMaybe<Scalars['Date']['input']>;
  /** Reason for skipping signature */
  signatureSkipReason?: InputMaybe<Scalars['String']['input']>;
  /** Name of person who approved site access change */
  siteAccessChangeApprovedBy?: InputMaybe<Scalars['String']['input']>;
  /** Completion of Jobite Specific Training */
  siteSpecificTrainingCompletedAt?: InputMaybe<Scalars['Date']['input']>;
  /** Date that reason for skipping site specific training expires */
  siteSpecificTrainingSkipExpiresAt?: InputMaybe<Scalars['Date']['input']>;
  /** Reason for skipping site specific training */
  siteSpecificTrainingSkipReason?: InputMaybe<Scalars['String']['input']>;
  /** When the worker started at the site */
  startDate?: InputMaybe<Scalars['Date']['input']>;
  /** Number on the worker's jobsite hardhat sticker */
  stickerNumber?: InputMaybe<Scalars['Int']['input']>;
  /** Title of the worker at the jobsite. */
  title?: InputMaybe<Scalars['String']['input']>;
  urineTestConditionalPassExpiresAt?: InputMaybe<Scalars['Date']['input']>;
  workType?: InputMaybe<Scalars['String']['input']>;
};

export type JobsiteWorkerJobsiteAccessSessionsConnection = {
  __typename?: 'JobsiteWorkerJobsiteAccessSessionsConnection';
  count: Scalars['Int']['output'];
  edges: Array<Maybe<JobsiteAccessSessionEdge>>;
};

export type JobsiteWorkerJobsiteAccessSessionsInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  compliant?: InputMaybe<Scalars['Boolean']['input']>;
  contractor?: InputMaybe<Scalars['String']['input']>;
  contractorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  endDate: Scalars['Date']['input'];
  jobsiteIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
  startDate: Scalars['Date']['input'];
  trade?: InputMaybe<Scalars['String']['input']>;
  tradeClass?: InputMaybe<Scalars['String']['input']>;
};

export enum JobsiteWorkerOrientationStatus {
  Complete = 'COMPLETE',
  InProgress = 'IN_PROGRESS',
  NotStarted = 'NOT_STARTED',
  SelfOnboarding = 'SELF_ONBOARDING',
}

export enum JobsiteWorkerOrientationStatusFilter {
  AtBadging = 'AT_BADGING',
  Complete = 'COMPLETE',
  InProgress = 'IN_PROGRESS',
  NeedsMedical = 'NEEDS_MEDICAL',
  NotStarted = 'NOT_STARTED',
  SelfOnboarding = 'SELF_ONBOARDING',
}

export type JobsiteWorkerReportContractorBreakdown = {
  __typename?: 'JobsiteWorkerReportContractorBreakdown';
  contractor: Scalars['String']['output'];
  count: Scalars['Int']['output'];
  percentage?: Maybe<Scalars['Float']['output']>;
};

export type JobsiteWorkerReportResult = {
  __typename?: 'JobsiteWorkerReportResult';
  jobsiteWorker: JobsiteWorker;
};

export type JobsiteWorkerReportTradeBreakdown = {
  __typename?: 'JobsiteWorkerReportTradeBreakdown';
  count: Scalars['Int']['output'];
  percentage?: Maybe<Scalars['Float']['output']>;
  trade: Scalars['String']['output'];
};

export enum JobsiteWorkerTestStatus {
  ConditionalPass = 'ConditionalPass',
  Fail = 'Fail',
  Pass = 'Pass',
}

export enum JobsiteWorkerWorkType {
  Construction = 'CONSTRUCTION',
  FitOut = 'FIT_OUT',
}

export type JobsitesStats = {
  __typename?: 'JobsitesStats';
  jobsites?: Maybe<Array<Jobsite>>;
  numberOfActiveWorkers: Scalars['Int']['output'];
  numberOfOrientationWorkersCompleted: Scalars['Int']['output'];
  numberOfOrientationWorkersInProgress: Scalars['Int']['output'];
  numberOfOrientationWorkersNeedingBadging: Scalars['Int']['output'];
  numberOfOrientationWorkersNeedingMedical: Scalars['Int']['output'];
  numberOfOrientationWorkersSelfOnboarding: Scalars['Int']['output'];
  numberOfOrientationWorkersTotal: Scalars['Int']['output'];
  numberOfWorkers: Scalars['Int']['output'];
};

export enum Language {
  EnUs = 'EN_US',
  Es = 'ES',
  Hu = 'HU',
  Pl = 'PL',
  Ru = 'RU',
  ZhCn = 'ZH_CN',
}

export type LimitOffsetPaginationInput = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type ManageContractorStripePaymentMethodInput = {
  contractorId: Scalars['ID']['input'];
  paymentMethodId: Scalars['ID']['input'];
};

export type MobileDashboardForWorkerResponse = {
  __typename?: 'MobileDashboardForWorkerResponse';
  jobsiteWorkerCheckInSuggestions: Array<JobsiteWorkerCheckInSuggestion>;
  mobileDashboardMessages: Array<MobileDashboardMessage>;
};

export type MobileDashboardMessage = {
  __typename?: 'MobileDashboardMessage';
  messageType: MobileDashboardMessageType;
};

export enum MobileDashboardMessageType {
  BeforeYouArriveOnsite = 'BeforeYouArriveOnsite',
  WelcomeToOdin = 'WelcomeToOdin',
}

/** The root mutation object for all mutations */
export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['String']['output']>;
  addAndIssueFacialRecognitionBadge?: Maybe<WorkerCard>;
  addAndIssueMobileBadge: WorkerCard;
  addCardToWorker: WorkerCard;
  /** Delete a jobsite. Returns an error if jobsiteId is not found. */
  archiveJobsite: Success;
  archiveJobsiteWorkerAccessEvent?: Maybe<ArchiveJobsiteWorkerAccessEventResponse>;
  /** Archive's a worker and all nested objects such as jobsiteWorker, contractorWorker, and all documents */
  archiveWorker: Worker;
  /**
   * Assign worker to jobsite. If worker is already associated, it returns error if 'ignoreIfAlreadyAssigned' is falsy
   * or existing jobsite worker if the flag is truthy.
   * This operation should be used either by a contractor or jobsite member to assign a worker to the jobsite.
   */
  assignContractorWorkerToJobsite: AssignContractorWorkerToJobsiteResponse;
  assignWorkerToContractor: ContractorWorker;
  authenticateLimitedTokenDetails: AuthenticateLimitedTokenDetailsResponse;
  /** Update multiple jobsite workers objects. Returns error if any worker not found at jobsite. */
  bulkUpdateJobsiteWorkers: Array<Maybe<JobsiteWorker>>;
  changeContractorForJobsiteWorkers: ChangeContractorForJobsiteWorkersResponse;
  changeJobsiteWorkersJobsiteAssignment: ChangeJobsiteWorkersJobsiteAssignmentResponse;
  checkInJobsiteWorkers: JobsiteWorkerCheckInConnection;
  checkInWorkers?: Maybe<WorkersCheckInConnection>;
  /** Completes a passwordless login based on the given code */
  completePasswordlessLogin: Auth;
  /** Completes a phone number verification request */
  completePhoneNumberVerification: CompletePhoneNumberVerificationResponse;
  completePhoneNumberVerificationAndSignIn: CompletePhoneNumberVerificationAndSignInResponse;
  completeSelfOnBoarding: JobsiteWorker;
  contractorAddJobsites: ContractorAddJobsitesPayload;
  contractorArchive: ContractorArchivePayload;
  contractorCreate: ContractorCreatePayload;
  contractorRemoveWorker: ContractorRemoveWorkerPayload;
  contractorUpdate: ContractorUpdatePayload;
  createCardPrintJob: CardPrintJob;
  createContractorStripeInvoice: CreateContractorStripeInvoicePayload;
  createContractorWorker?: Maybe<ContractorWorker>;
  /** Create a jobsite announcement. */
  createJobsiteAnnouncement: JobsiteAnnouncement;
  createJobsiteWorkerDocument: JobsiteWorkerDocument;
  createJobsiteWorkerDocumentVersion: JobsiteWorkerDocumentVersion;
  /** Creates a new PayrollDocument with it's initial version. */
  createPayrollDocument: PayrollDocument;
  createPayrollDocumentVersion: PayrollDocumentVersion;
  /**
   * Create a worker. This will send a welcome email with an invite link.
   * Returns an error if worker with same email already exists.
   */
  createWorker: Worker;
  /** Create a new worker comment */
  createWorkerComment: WorkerComment;
  /** Delete the FusionAuth user for the current session */
  deleteFusionAuthUser: Success;
  /** Delete a single jobsite announcement by ID. Returns error if announcement doesn't exist */
  deleteJobsiteAnnouncement: Success;
  deleteJobsiteWorkerDocument: Success;
  deletePayrollDocument?: Maybe<Success>;
  /**
   * Mutation to create a new developer. Takes in either a name or an organizationId
   * If the organization exists already then use than for the new developer
   * If the organization does not exist then use the inputted name to make a new organization
   */
  developerCreate: DeveloperCreatePayload;
  developerUpdate: DeveloperUpdatePayload;
  enableJobsiteWorkerSelfOnboarding: JobsiteWorker;
  /** Creates a worker if it doesn't exist and initiates a passwordless login using phone number */
  ensureWorkerAndStartPasswordlessLogin: EnsureWorkerAndStartPasswordlessLoginResponse;
  extractIdDataFromFile: IdCardDataExtractionResult;
  formArchive: FormArchivePayload;
  formCreate: FormCreatePayload;
  formUpdate: FormUpdatePayload;
  generateFormSubmissionsReport: File;
  /**
   * Not to be removed. This is used by the jobsite worker report to get the
   * downloadable file and by the automatically mailed report task
   */
  generateLookerJobsiteWorkerReport: File;
  initiateStripePaymentSetupSession: InitiateStripePaymentSetupSessionPayload;
  jobsiteAddContractors: JobsiteAddContractorsPayload;
  jobsiteAddForm: JobsiteAddFormPayload;
  jobsiteArchiveFormSubmission: JobsiteArchiveFormSubmissionPayload;
  jobsiteArchiveInvitation: JobsiteArchiveInvitationPayload;
  jobsiteCloseOut: JobsiteCloseOutPayload;
  jobsiteContractorUpdateStripePaymentMethod: JobsiteContractorUpdateStripePaymentMethodPayload;
  /** Create a jobsite. Returns an error if developer, development project or CM does not exist. */
  jobsiteCreate: JobsiteCreatePayload;
  jobsiteCreateFormSubmission: JobsiteCreateFormSubmissionPayload;
  jobsiteCreateInvitation: JobsiteCreateInvitationPayload;
  jobsiteRemoveContractor: JobsiteRemoveContractorPayload;
  jobsiteRemoveForm: JobsiteRemoveFormPayload;
  jobsiteUpdate: JobsiteUpdatePayload;
  jobsiteUpdateContractor: JobsiteUpdateContractorPayload;
  jobsiteUpdateFormSubmission: JobsiteUpdateFormSubmissionPayload;
  /** Logout current user from FusionAuth */
  logoutCurrentUser: Success;
  manuallyEnterCardNumberForPrintJob: WorkerCard;
  organizationDelete: OrganizationDeletePayload;
  reactivateWorkerCard: Success;
  /** Refresh a FusionAuth provided session */
  refreshFusionAuthSession: Auth;
  registerWorkerForFacialRecognitionBadge: WorkerCard;
  reissueMobileBadge: WorkerCard;
  removeCardFromWorker: Success;
  removeStripePaymentMethod?: Maybe<Array<StripePaymentMethod>>;
  /**
   * Remove a worker from a jobsite. Returns an error if there will be no more workers of the jobsite or if
   * the workerId is not a worker of the jobsite.
   */
  removeWorkerFromJobsite: RemoveWorkerFromJobsiteResponse;
  saveJobsiteWorkerInformation: JobsiteWorker;
  savePersonalInformation: Worker;
  saveUserSearchQuery: Success;
  selfOnboardingWorkerCreate: SelfOnboardingWorkerCreatePayload;
  selfOnboardingWorkerUpdate: SelfOnboardingWorkerUpdatePayload;
  /** Send password reset email */
  sendPasswordResetEmail?: Maybe<Scalars['String']['output']>;
  setDefaultStripePaymentMethod?: Maybe<Array<StripePaymentMethod>>;
  /** Initiates a passwordless login using phone number */
  startPasswordlessLogin: StartPasswordlessLoginResponse;
  /** Initiates a phone number verification request */
  startPhoneNumberVerification: StartPhoneNumberVerificationResponse;
  syncCCureObjects: Success;
  /** Track handing off the user to one of the integrations */
  trackIntegrationHandoff: IntegrationDetails;
  /** Track the current session. */
  trackSession: Success;
  updateContractorWorker: ContractorWorker;
  updateJobsiteAnnouncement: JobsiteAnnouncement;
  /** Update jobsite worker object using domain objects. Returns error if no worker found at jobsite. */
  updateJobsiteWorker: JobsiteWorker;
  updateJobsiteWorkerDocumentVersion: JobsiteWorkerDocumentVersion;
  updatePayrollDocumentVersionStatus: PayrollDocumentVersion;
  /** Update the app version stored in the user's session. */
  updateSessionAppVersion: Auth;
  /** Update a worker. Returns an error if workerId is not found or if input is invalid. */
  updateWorker: Worker;
  updateWorkerCard: WorkerCard;
  uploadSingleFile: File;
  /**
   * Upload an image file that needs to be cropped/resized as well as uploaded.
   * Returns the original and cropped file ID's
   */
  uploadSingleImage: Array<File>;
  upsertJobsiteWorkerDocument: JobsiteWorkerDocumentVersion;
  /** Upsert a PayrollDocument - create or make new version. */
  upsertPayrollDocument: PayrollDocument;
  /** Assign a user to either jobsites or contractors */
  userAssign: UserPayload;
  userCancelPendingChange: Success;
  userConfirmPendingChange: UserPayload;
  /** Create a user and assign them to either jobsites or contractors. */
  userCreate: UserPayload;
  /** Update jobsite or contractor user assignment */
  userRemoveAssignment: UserPayload;
  /** Update jobsite or contractor user assignment */
  userUpdateAssignment: UserPayload;
  userUpdateIdentity: UserPayload;
  workerUnlockProfile: WorkerUnlockProfilePayload;
};

/** The root mutation object for all mutations */
export type MutationAddAndIssueFacialRecognitionBadgeArgs = {
  jobsiteId: Scalars['ID']['input'];
  newEmail?: InputMaybe<Scalars['String']['input']>;
  newPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  workerCardInput: WorkerCardInput;
  workerId: Scalars['ID']['input'];
};

/** The root mutation object for all mutations */
export type MutationAddAndIssueMobileBadgeArgs = {
  jobsiteId: Scalars['ID']['input'];
  newPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  workerCardInput: WorkerCardInput;
  workerId: Scalars['ID']['input'];
};

/** The root mutation object for all mutations */
export type MutationAddCardToWorkerArgs = {
  forceCreate?: InputMaybe<Scalars['Boolean']['input']>;
  jobsiteId: Scalars['ID']['input'];
  workerCardInput: WorkerCardInput;
  workerId: Scalars['ID']['input'];
};

/** The root mutation object for all mutations */
export type MutationArchiveJobsiteArgs = {
  jobsiteId: Scalars['ID']['input'];
};

/** The root mutation object for all mutations */
export type MutationArchiveJobsiteWorkerAccessEventArgs = {
  input?: InputMaybe<ArchiveJobsiteWorkerAccessEventInput>;
};

/** The root mutation object for all mutations */
export type MutationArchiveWorkerArgs = {
  workerId: Scalars['ID']['input'];
};

/** The root mutation object for all mutations */
export type MutationAssignContractorWorkerToJobsiteArgs = {
  input: AssignContractorWorkerToJobsiteInput;
};

/** The root mutation object for all mutations */
export type MutationAssignWorkerToContractorArgs = {
  contractorId: Scalars['ID']['input'];
  contractorWorkerInput: ContractorWorkerInput;
  workerId: Scalars['ID']['input'];
};

/** The root mutation object for all mutations */
export type MutationAuthenticateLimitedTokenDetailsArgs = {
  input?: InputMaybe<AuthenticateLimitedTokenDetailsInput>;
};

/** The root mutation object for all mutations */
export type MutationBulkUpdateJobsiteWorkersArgs = {
  jobsiteWorkerInputs: Array<BulkJobsiteWorkerInput>;
};

/** The root mutation object for all mutations */
export type MutationChangeContractorForJobsiteWorkersArgs = {
  input?: InputMaybe<ChangeContractorForJobsiteWorkersInput>;
};

/** The root mutation object for all mutations */
export type MutationChangeJobsiteWorkersJobsiteAssignmentArgs = {
  input: ChangeJobsiteWorkersJobsiteAssignmentInput;
};

/** The root mutation object for all mutations */
export type MutationCheckInJobsiteWorkersArgs = {
  input: CheckInJobsiteWorkersInput;
};

/** The root mutation object for all mutations */
export type MutationCheckInWorkersArgs = {
  input?: InputMaybe<WorkersCheckInInput>;
};

/** The root mutation object for all mutations */
export type MutationCompletePasswordlessLoginArgs = {
  input: CompletePasswordlessLoginInput;
};

/** The root mutation object for all mutations */
export type MutationCompletePhoneNumberVerificationArgs = {
  input?: InputMaybe<CompletePhoneNumberVerificationInput>;
};

/** The root mutation object for all mutations */
export type MutationCompletePhoneNumberVerificationAndSignInArgs = {
  input?: InputMaybe<CompletePhoneNumberVerificationAndSignInInput>;
};

/** The root mutation object for all mutations */
export type MutationCompleteSelfOnBoardingArgs = {
  jobsiteWorkerId: Scalars['ID']['input'];
};

/** The root mutation object for all mutations */
export type MutationContractorAddJobsitesArgs = {
  input: ContractorAddJobsitesInput;
};

/** The root mutation object for all mutations */
export type MutationContractorArchiveArgs = {
  input: ContractorArchiveInput;
};

/** The root mutation object for all mutations */
export type MutationContractorCreateArgs = {
  input: ContractorCreateInput;
};

/** The root mutation object for all mutations */
export type MutationContractorRemoveWorkerArgs = {
  input: ContractorRemoveWorkerInput;
};

/** The root mutation object for all mutations */
export type MutationContractorUpdateArgs = {
  input: ContractorUpdateInput;
};

/** The root mutation object for all mutations */
export type MutationCreateCardPrintJobArgs = {
  cardTemplateName?: InputMaybe<Scalars['String']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  jobsiteId: Scalars['ID']['input'];
  trade?: InputMaybe<Scalars['String']['input']>;
  workerId: Scalars['ID']['input'];
};

/** The root mutation object for all mutations */
export type MutationCreateContractorStripeInvoiceArgs = {
  input: CreateContractorStripeInvoiceInput;
};

/** The root mutation object for all mutations */
export type MutationCreateContractorWorkerArgs = {
  createContractorWorkerInput?: InputMaybe<CreateContractorWorkerInput>;
};

/** The root mutation object for all mutations */
export type MutationCreateJobsiteAnnouncementArgs = {
  jobsiteAnnouncementInput: JobsiteAnnouncementInput;
};

/** The root mutation object for all mutations */
export type MutationCreateJobsiteWorkerDocumentArgs = {
  jobsiteWorkerDocumentInput: JobsiteWorkerDocumentInput;
  jobsiteWorkerDocumentVersionInput: JobsiteWorkerDocumentVersionInput;
};

/** The root mutation object for all mutations */
export type MutationCreateJobsiteWorkerDocumentVersionArgs = {
  jobsiteWorkerDocumentId: Scalars['ID']['input'];
  jobsiteWorkerDocumentVersionInput: JobsiteWorkerDocumentVersionInput;
};

/** The root mutation object for all mutations */
export type MutationCreatePayrollDocumentArgs = {
  createPayrollDocumentInput?: InputMaybe<CreatePayrollDocumentInput>;
  createPayrollDocumentVersionInput?: InputMaybe<CreatePayrollDocumentVersionInput>;
};

/** The root mutation object for all mutations */
export type MutationCreatePayrollDocumentVersionArgs = {
  createPayrollDocumentVersionInput: CreatePayrollDocumentVersionInput;
  payrollDocumentId: Scalars['ID']['input'];
};

/** The root mutation object for all mutations */
export type MutationCreateWorkerArgs = {
  input: CreateWorkerInput;
};

/** The root mutation object for all mutations */
export type MutationCreateWorkerCommentArgs = {
  workerCommentInput: WorkerCommentInput;
};

/** The root mutation object for all mutations */
export type MutationDeleteJobsiteAnnouncementArgs = {
  jobsiteAnnouncementId: Scalars['ID']['input'];
};

/** The root mutation object for all mutations */
export type MutationDeleteJobsiteWorkerDocumentArgs = {
  jobsiteWorkerDocumentId: Scalars['ID']['input'];
};

/** The root mutation object for all mutations */
export type MutationDeletePayrollDocumentArgs = {
  contractorId: Scalars['ID']['input'];
  payrollDocumentId: Scalars['ID']['input'];
};

/** The root mutation object for all mutations */
export type MutationDeveloperCreateArgs = {
  input: DeveloperCreateInput;
};

/** The root mutation object for all mutations */
export type MutationDeveloperUpdateArgs = {
  input: DeveloperUpdateInput;
};

/** The root mutation object for all mutations */
export type MutationEnableJobsiteWorkerSelfOnboardingArgs = {
  jobsiteWorkerId: Scalars['ID']['input'];
};

/** The root mutation object for all mutations */
export type MutationEnsureWorkerAndStartPasswordlessLoginArgs = {
  input: EnsureWorkerAndStartPasswordlessLoginInput;
};

/** The root mutation object for all mutations */
export type MutationExtractIdDataFromFileArgs = {
  extractionInput: DataExtractionInput;
};

/** The root mutation object for all mutations */
export type MutationFormArchiveArgs = {
  input: FormArchiveInput;
};

/** The root mutation object for all mutations */
export type MutationFormCreateArgs = {
  input: FormCreateInput;
};

/** The root mutation object for all mutations */
export type MutationFormUpdateArgs = {
  input: FormUpdateInput;
};

/** The root mutation object for all mutations */
export type MutationGenerateFormSubmissionsReportArgs = {
  input?: InputMaybe<FormSubmissionsReportInput>;
};

/** The root mutation object for all mutations */
export type MutationGenerateLookerJobsiteWorkerReportArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  compliant?: InputMaybe<Scalars['Boolean']['input']>;
  contractor?: InputMaybe<Scalars['String']['input']>;
  endDate: Scalars['Date']['input'];
  export?: InputMaybe<Scalars['Boolean']['input']>;
  jobsiteIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  realtime?: InputMaybe<Scalars['Boolean']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
  startDate: Scalars['Date']['input'];
  trade?: InputMaybe<Scalars['String']['input']>;
  tradeClass?: InputMaybe<Scalars['String']['input']>;
};

/** The root mutation object for all mutations */
export type MutationInitiateStripePaymentSetupSessionArgs = {
  input: InitiateStripePaymentSetupSessionInput;
};

/** The root mutation object for all mutations */
export type MutationJobsiteAddContractorsArgs = {
  input: JobsiteAddContractorsInput;
};

/** The root mutation object for all mutations */
export type MutationJobsiteAddFormArgs = {
  input: JobsiteAddFormInput;
};

/** The root mutation object for all mutations */
export type MutationJobsiteArchiveFormSubmissionArgs = {
  input: JobsiteArchiveFormSubmissionInput;
};

/** The root mutation object for all mutations */
export type MutationJobsiteArchiveInvitationArgs = {
  input: JobsiteArchiveInvitationInput;
};

/** The root mutation object for all mutations */
export type MutationJobsiteCloseOutArgs = {
  input: JobsiteCloseOutInput;
};

/** The root mutation object for all mutations */
export type MutationJobsiteContractorUpdateStripePaymentMethodArgs = {
  input: JobsiteContractorUpdateStripePaymentMethodInput;
};

/** The root mutation object for all mutations */
export type MutationJobsiteCreateArgs = {
  input: JobsiteCreateInput;
};

/** The root mutation object for all mutations */
export type MutationJobsiteCreateFormSubmissionArgs = {
  input: JobsiteCreateFormSubmissionInput;
};

/** The root mutation object for all mutations */
export type MutationJobsiteCreateInvitationArgs = {
  input: JobsiteCreateInvitationInput;
};

/** The root mutation object for all mutations */
export type MutationJobsiteRemoveContractorArgs = {
  input: JobsiteRemoveContractorInput;
};

/** The root mutation object for all mutations */
export type MutationJobsiteRemoveFormArgs = {
  input: JobsiteRemoveFormInput;
};

/** The root mutation object for all mutations */
export type MutationJobsiteUpdateArgs = {
  input: JobsiteUpdateInput;
};

/** The root mutation object for all mutations */
export type MutationJobsiteUpdateContractorArgs = {
  input: JobsiteUpdateContractorInput;
};

/** The root mutation object for all mutations */
export type MutationJobsiteUpdateFormSubmissionArgs = {
  input: JobsiteUpdateFormSubmissionInput;
};

/** The root mutation object for all mutations */
export type MutationManuallyEnterCardNumberForPrintJobArgs = {
  cardNumber: Scalars['String']['input'];
  cardPrintJobId: Scalars['ID']['input'];
  forceCreate?: InputMaybe<Scalars['Boolean']['input']>;
  workerCardFormatId: Scalars['ID']['input'];
};

/** The root mutation object for all mutations */
export type MutationOrganizationDeleteArgs = {
  organizationId: Scalars['ID']['input'];
};

/** The root mutation object for all mutations */
export type MutationReactivateWorkerCardArgs = {
  forceCreate?: InputMaybe<Scalars['Boolean']['input']>;
  workerCardIds: Array<Scalars['String']['input']>;
};

/** The root mutation object for all mutations */
export type MutationRefreshFusionAuthSessionArgs = {
  refreshToken: Scalars['String']['input'];
};

/** The root mutation object for all mutations */
export type MutationRegisterWorkerForFacialRecognitionBadgeArgs = {
  newPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  workerCardId: Scalars['ID']['input'];
  workerId: Scalars['ID']['input'];
};

/** The root mutation object for all mutations */
export type MutationReissueMobileBadgeArgs = {
  newPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  workerCardId: Scalars['ID']['input'];
};

/** The root mutation object for all mutations */
export type MutationRemoveCardFromWorkerArgs = {
  deactivateCardReason?: InputMaybe<Scalars['String']['input']>;
  workerCardIds: Array<Scalars['String']['input']>;
};

/** The root mutation object for all mutations */
export type MutationRemoveStripePaymentMethodArgs = {
  input: ManageContractorStripePaymentMethodInput;
};

/** The root mutation object for all mutations */
export type MutationRemoveWorkerFromJobsiteArgs = {
  input: RemoveWorkerFromJobsiteInput;
};

/** The root mutation object for all mutations */
export type MutationSaveJobsiteWorkerInformationArgs = {
  jobsiteWorkerInformationInput: JobsiteWorkerInformationInput;
};

/** The root mutation object for all mutations */
export type MutationSavePersonalInformationArgs = {
  personalInformationInput: PersonalInformationInput;
};

/** The root mutation object for all mutations */
export type MutationSaveUserSearchQueryArgs = {
  input: UserSearchQueryInput;
};

/** The root mutation object for all mutations */
export type MutationSelfOnboardingWorkerCreateArgs = {
  input: SelfOnboardingWorkerAccountCreateInput;
};

/** The root mutation object for all mutations */
export type MutationSelfOnboardingWorkerUpdateArgs = {
  input: SelfOnboardingWorkerAccountUpdateInput;
};

/** The root mutation object for all mutations */
export type MutationSendPasswordResetEmailArgs = {
  email: Scalars['String']['input'];
  sendForgotPasswordEmail?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The root mutation object for all mutations */
export type MutationSetDefaultStripePaymentMethodArgs = {
  input: ManageContractorStripePaymentMethodInput;
};

/** The root mutation object for all mutations */
export type MutationStartPasswordlessLoginArgs = {
  input: StartPasswordlessLoginInput;
};

/** The root mutation object for all mutations */
export type MutationStartPhoneNumberVerificationArgs = {
  input?: InputMaybe<StartPhoneNumberVerificationInput>;
};

/** The root mutation object for all mutations */
export type MutationSyncCCureObjectsArgs = {
  backfillPersonnelGuids?: InputMaybe<Scalars['Boolean']['input']>;
  fullSync?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The root mutation object for all mutations */
export type MutationTrackIntegrationHandoffArgs = {
  integrationType: ExternalIntegrationType;
};

/** The root mutation object for all mutations */
export type MutationTrackSessionArgs = {
  sessionType: SessionType;
};

/** The root mutation object for all mutations */
export type MutationUpdateContractorWorkerArgs = {
  contractorId: Scalars['ID']['input'];
  contractorWorkerInput: ContractorWorkerInput;
  workerId: Scalars['ID']['input'];
};

/** The root mutation object for all mutations */
export type MutationUpdateJobsiteAnnouncementArgs = {
  jobsiteAnnouncementId: Scalars['ID']['input'];
  jobsiteAnnouncementInput: JobsiteAnnouncementInput;
};

/** The root mutation object for all mutations */
export type MutationUpdateJobsiteWorkerArgs = {
  jobsiteWorkerId: Scalars['ID']['input'];
  jobsiteWorkerInput: JobsiteWorkerInput;
  skipACSSync?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The root mutation object for all mutations */
export type MutationUpdateJobsiteWorkerDocumentVersionArgs = {
  jobsiteWorkerDocumentVersionId: Scalars['ID']['input'];
  jobsiteWorkerDocumentVersionInput: JobsiteWorkerDocumentVersionInput;
};

/** The root mutation object for all mutations */
export type MutationUpdatePayrollDocumentVersionStatusArgs = {
  payrollDocumentVersionId: Scalars['ID']['input'];
  rejectionReason?: InputMaybe<Scalars['String']['input']>;
  status: PayrollStatus;
};

/** The root mutation object for all mutations */
export type MutationUpdateSessionAppVersionArgs = {
  appVersion: Scalars['String']['input'];
};

/** The root mutation object for all mutations */
export type MutationUpdateWorkerArgs = {
  workerId: Scalars['ID']['input'];
  workerInput: WorkerInput;
};

/** The root mutation object for all mutations */
export type MutationUpdateWorkerCardArgs = {
  workerCardId: Scalars['ID']['input'];
  workerCardInput: WorkerCardInput;
};

/** The root mutation object for all mutations */
export type MutationUploadSingleFileArgs = {
  fileInput: FileInput;
};

/** The root mutation object for all mutations */
export type MutationUploadSingleImageArgs = {
  fileInput: FileInput;
  sizeInput?: InputMaybe<SizeInput>;
};

/** The root mutation object for all mutations */
export type MutationUpsertJobsiteWorkerDocumentArgs = {
  input: JobsiteWorkerDocumentUpsertInput;
};

/** The root mutation object for all mutations */
export type MutationUpsertPayrollDocumentArgs = {
  createPayrollDocumentInput?: InputMaybe<CreatePayrollDocumentInput>;
  createPayrollDocumentVersionInput?: InputMaybe<CreatePayrollDocumentVersionInput>;
};

/** The root mutation object for all mutations */
export type MutationUserAssignArgs = {
  input: UserAssignInput;
};

/** The root mutation object for all mutations */
export type MutationUserCancelPendingChangeArgs = {
  input: UserCancelPendingChangeInput;
};

/** The root mutation object for all mutations */
export type MutationUserConfirmPendingChangeArgs = {
  input: UserConfirmPendingChangeInput;
};

/** The root mutation object for all mutations */
export type MutationUserCreateArgs = {
  input: UserCreateInput;
};

/** The root mutation object for all mutations */
export type MutationUserRemoveAssignmentArgs = {
  input: UserRemoveAssignmentInput;
};

/** The root mutation object for all mutations */
export type MutationUserUpdateAssignmentArgs = {
  input: UserUpdateAssignmentInput;
};

/** The root mutation object for all mutations */
export type MutationUserUpdateIdentityArgs = {
  input: UserUpdateIdentityInput;
};

/** The root mutation object for all mutations */
export type MutationWorkerUnlockProfileArgs = {
  input: WorkerUnlockProfileInput;
};

export type ObjectHistory = {
  __typename?: 'ObjectHistory';
  /** Created date */
  createdAt: Scalars['Date']['output'];
  /** Created by user */
  createdBy?: Maybe<User>;
  /** Deleted date */
  deletedAt?: Maybe<Scalars['Date']['output']>;
  /** Deleted by user */
  deletedBy?: Maybe<User>;
  /** Last updated date */
  updatedAt?: Maybe<Scalars['Date']['output']>;
  /** Updated by user */
  updatedBy?: Maybe<User>;
};

export enum ObjectName {
  AclGrant = 'ACLGrant',
  AclObject = 'ACLObject',
  AclRole = 'ACLRole',
  AdditionalFieldValue = 'AdditionalFieldValue',
  AdditionalWorkerProfileData = 'AdditionalWorkerProfileData',
  AppUserConnection = 'AppUserConnection',
  Auth = 'Auth',
  BadgeTemplate = 'BadgeTemplate',
  CCureObjectSync = 'CCureObjectSync',
  CardPrintJob = 'CardPrintJob',
  CardPrintJobCompletion = 'CardPrintJobCompletion',
  CardPrintJobError = 'CardPrintJobError',
  ChangeContractorForJobsiteWorkersError = 'ChangeContractorForJobsiteWorkersError',
  ChangeContractorForJobsiteWorkersResponse = 'ChangeContractorForJobsiteWorkersResponse',
  ChangeContractorForJobsiteWorkersSuccess = 'ChangeContractorForJobsiteWorkersSuccess',
  ChangeJobsiteWorkersJobsiteAssignmentResponse = 'ChangeJobsiteWorkersJobsiteAssignmentResponse',
  Contractor = 'Contractor',
  ContractorMember = 'ContractorMember',
  ContractorWorker = 'ContractorWorker',
  ContractorWorkerConnection = 'ContractorWorkerConnection',
  ContractorWorkerConnectionFacets = 'ContractorWorkerConnectionFacets',
  CreateJobsiteInvitationResponse = 'CreateJobsiteInvitationResponse',
  CreateLookerJobsiteWorkerReportsResponse = 'CreateLookerJobsiteWorkerReportsResponse',
  Developer = 'Developer',
  DeveloperMember = 'DeveloperMember',
  ElasticFacetEntry = 'ElasticFacetEntry',
  ExternalIntegrationMapping = 'ExternalIntegrationMapping',
  FacetCount = 'FacetCount',
  File = 'File',
  FileDownloadRedirect = 'FileDownloadRedirect',
  Form = 'Form',
  GpsCoordinate = 'GPSCoordinate',
  GetAllContractorsResponse = 'GetAllContractorsResponse',
  GetJobsiteContractorsResponse = 'GetJobsiteContractorsResponse',
  GetJobsiteEquipmentDataResponse = 'GetJobsiteEquipmentDataResponse',
  GetJobsiteEquipmentHistoryResponse = 'GetJobsiteEquipmentHistoryResponse',
  GetJobsiteInvitationsDataResponse = 'GetJobsiteInvitationsDataResponse',
  GetJobsitePacsGatewaysResponse = 'GetJobsitePacsGatewaysResponse',
  GetJobsiteWorkerReportsResponse = 'GetJobsiteWorkerReportsResponse',
  GetMemberJobsitesResponse = 'GetMemberJobsitesResponse',
  GetWorkerAccessEventsResponse = 'GetWorkerAccessEventsResponse',
  GetWorkerDocumentsResponse = 'GetWorkerDocumentsResponse',
  HistoricalJobsiteWorker = 'HistoricalJobsiteWorker',
  ILookerWorkerSessionData = 'ILookerWorkerSessionData',
  Jobsite = 'Jobsite',
  JobsiteAccessEvent = 'JobsiteAccessEvent',
  JobsiteAnnouncement = 'JobsiteAnnouncement',
  JobsiteAppCheckInModule = 'JobsiteAppCheckInModule',
  JobsiteAppCheckInModuleArea = 'JobsiteAppCheckInModuleArea',
  JobsiteBadgeTemplate = 'JobsiteBadgeTemplate',
  JobsiteCardFormat = 'JobsiteCardFormat',
  JobsiteContractor = 'JobsiteContractor',
  JobsiteEquipmentData = 'JobsiteEquipmentData',
  JobsiteEquipmentEvent = 'JobsiteEquipmentEvent',
  JobsiteFeaturesModule = 'JobsiteFeaturesModule',
  JobsiteForm = 'JobsiteForm',
  JobsiteFormSubmission = 'JobsiteFormSubmission',
  JobsiteFormSubmissionContractor = 'JobsiteFormSubmissionContractor',
  JobsiteFormSubmissionDocument = 'JobsiteFormSubmissionDocument',
  JobsiteFormSubmissionWorker = 'JobsiteFormSubmissionWorker',
  JobsiteInvitation = 'JobsiteInvitation',
  JobsiteMember = 'JobsiteMember',
  JobsiteOnboardingDocument = 'JobsiteOnboardingDocument',
  JobsiteOnboardingField = 'JobsiteOnboardingField',
  JobsiteOnboardingModule = 'JobsiteOnboardingModule',
  JobsiteOnboardingStep = 'JobsiteOnboardingStep',
  JobsitePacsGateway = 'JobsitePacsGateway',
  JobsitePreOnboardingField = 'JobsitePreOnboardingField',
  JobsitePreOnboardingModule = 'JobsitePreOnboardingModule',
  JobsitePreOnboardingStep = 'JobsitePreOnboardingStep',
  JobsiteStats = 'JobsiteStats',
  JobsiteWorker = 'JobsiteWorker',
  JobsiteWorkerAccess = 'JobsiteWorkerAccess',
  JobsiteWorkerCheckIn = 'JobsiteWorkerCheckIn',
  JobsiteWorkerCheckInConnection = 'JobsiteWorkerCheckInConnection',
  JobsiteWorkerCheckInSuggestion = 'JobsiteWorkerCheckInSuggestion',
  JobsiteWorkerCheckInSuggestionConnection = 'JobsiteWorkerCheckInSuggestionConnection',
  JobsiteWorkerCompact = 'JobsiteWorkerCompact',
  JobsiteWorkerConnection = 'JobsiteWorkerConnection',
  JobsiteWorkerDocument = 'JobsiteWorkerDocument',
  JobsiteWorkerDocumentType = 'JobsiteWorkerDocumentType',
  JobsiteWorkerDocumentVersion = 'JobsiteWorkerDocumentVersion',
  JobsiteWorkerReportContractorBreakdown = 'JobsiteWorkerReportContractorBreakdown',
  JobsiteWorkerReportResult = 'JobsiteWorkerReportResult',
  JobsiteWorkerReportTradeBreakdown = 'JobsiteWorkerReportTradeBreakdown',
  JobsitesStats = 'JobsitesStats',
  MobileDashboardForWorkerResponse = 'MobileDashboardForWorkerResponse',
  MobileDashboardMessage = 'MobileDashboardMessage',
  ObjectFile = 'ObjectFile',
  ObjectHistory = 'ObjectHistory',
  Organization = 'Organization',
  OrganizationMember = 'OrganizationMember',
  PayrollDocument = 'PayrollDocument',
  PayrollDocumentType = 'PayrollDocumentType',
  PayrollDocumentVersion = 'PayrollDocumentVersion',
  PreExistingWorkerResponse = 'PreExistingWorkerResponse',
  ProximitySessionLocaleData = 'ProximitySessionLocaleData',
  ProximitySessionLocaleTimeData = 'ProximitySessionLocaleTimeData',
  ProximitySessionReadsCountData = 'ProximitySessionReadsCountData',
  ProximitySessionReadsData = 'ProximitySessionReadsData',
  ProximitySessionReadsDirectionData = 'ProximitySessionReadsDirectionData',
  ProximitySessionResult = 'ProximitySessionResult',
  ProximitySessionSpanData = 'ProximitySessionSpanData',
  RemoveWorkerFromJobsiteResponse = 'RemoveWorkerFromJobsiteResponse',
  Role = 'Role',
  Success = 'Success',
  User = 'User',
  UserAccount = 'UserAccount',
  UserIdentity = 'UserIdentity',
  UserJobsiteAccessEventsConnection = 'UserJobsiteAccessEventsConnection',
  UserRole = 'UserRole',
  UserRoleObject = 'UserRoleObject',
  UserSearchQuery = 'UserSearchQuery',
  UserSearchQueryResponse = 'UserSearchQueryResponse',
  UserSession = 'UserSession',
  WeatherConditions = 'WeatherConditions',
  Worker = 'Worker',
  WorkerAccessEvent = 'WorkerAccessEvent',
  WorkerAccessHistory = 'WorkerAccessHistory',
  WorkerAuth = 'WorkerAuth',
  WorkerCCureData = 'WorkerCCureData',
  WorkerCard = 'WorkerCard',
  WorkerCardFormat = 'WorkerCardFormat',
  WorkerComment = 'WorkerComment',
  WorkerCommentConnection = 'WorkerCommentConnection',
  WorkerDocumentType = 'WorkerDocumentType',
  WorkerJobsiteBan = 'WorkerJobsiteBan',
  WorkerPhi = 'WorkerPHI',
  WorkerSearchResponse = 'WorkerSearchResponse',
  WorkerSearchResult = 'WorkerSearchResult',
}

export enum OrderByDirection {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING',
}

/** Organization return type. */
export type Organization = {
  __typename?: 'Organization';
  /** City of the organization */
  addressCity?: Maybe<Scalars['String']['output']>;
  /** Address of the organization */
  addressLine1?: Maybe<Scalars['String']['output']>;
  /** Address of the organization */
  addressLine2?: Maybe<Scalars['String']['output']>;
  /** State of the organization */
  addressState?: Maybe<StateAbbreviation>;
  /** Zip code of the organization */
  addressZipCode?: Maybe<Scalars['String']['output']>;
  contractors?: Maybe<OrganizationContractorsConnection>;
  developers?: Maybe<OrganizationDevelopersConnection>;
  /** Email for primary point of contact at organization */
  email?: Maybe<Scalars['String']['output']>;
  /** Legal name of the organization */
  legalName?: Maybe<Scalars['String']['output']>;
  /** Name of the organization */
  name: Scalars['String']['output'];
  /** History of the object */
  objectHistory: ObjectHistory;
  /** Unique indetifier for the organization */
  organizationId: Scalars['ID']['output'];
  /** Phone number for primary point of contact at organization */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /**
   * Slug of the organization. Short, unique, human-readable identifier.
   * Can only contain numbers, letters, and hyphens.
   */
  slug: Scalars['String']['output'];
};

/** Organization return type. */
export type OrganizationContractorsArgs = {
  input?: InputMaybe<OrganizationContractorsInput>;
};

/** Organization return type. */
export type OrganizationDevelopersArgs = {
  input?: InputMaybe<OrganizationDevelopersInput>;
};

export type OrganizationContractorsConnection = {
  __typename?: 'OrganizationContractorsConnection';
  count: Scalars['Int']['output'];
  edges: Array<OrganizationContractorsEdge>;
};

export type OrganizationContractorsEdge = {
  __typename?: 'OrganizationContractorsEdge';
  node: Contractor;
};

export type OrganizationContractorsInput = {
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
};

export type OrganizationDeletePayload = {
  __typename?: 'OrganizationDeletePayload';
  success: Success;
};

export type OrganizationDevelopersConnection = {
  __typename?: 'OrganizationDevelopersConnection';
  count: Scalars['Int']['output'];
  edges: Array<OrganizationDevelopersEdge>;
};

export type OrganizationDevelopersEdge = {
  __typename?: 'OrganizationDevelopersEdge';
  node: Developer;
};

export type OrganizationDevelopersInput = {
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
};

export type OrganizationInput = {
  /** City of the organization */
  addressCity?: InputMaybe<Scalars['String']['input']>;
  /** Address of the organization */
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  /** Address of the organization */
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  /** State of the organization */
  addressState?: InputMaybe<StateAbbreviation>;
  /** Zip code of the organization */
  addressZipCode?: InputMaybe<Scalars['String']['input']>;
  /** Email for primary point of contact at organization */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Legal Name */
  legalName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** Phone number for primary point of contact at organization */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /**
   * Slug of the organization. Short, unique, human-readable identifier.
   * Can only contain numbers, letters, and hyphens.
   */
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type OrganizationUpdatePayload = {
  __typename?: 'OrganizationUpdatePayload';
  organization?: Maybe<OrganizationUpdatePayload>;
};

/** PayrollDocument return type. */
export type PayrollDocument = {
  __typename?: 'PayrollDocument';
  contractor: Contractor;
  id: Scalars['ID']['output'];
  jobsite: Jobsite;
  name: Scalars['String']['output'];
  /** Type of payroll document */
  payrollDocumentType?: Maybe<PayrollDocumentType>;
  /** List of document versions sorted by most recent version first */
  payrollDocumentVersions: Array<PayrollDocumentVersion>;
  /** End date for payroll pay period */
  payrollEndDate?: Maybe<Scalars['Date']['output']>;
  payrollStartDate?: Maybe<Scalars['Date']['output']>;
};

export enum PayrollDocumentDateInputType {
  DateRange = 'DateRange',
  SingleDate = 'SingleDate',
}

/** PayrollDocumentType return type. */
export type PayrollDocumentType = {
  __typename?: 'PayrollDocumentType';
  dateInputType?: Maybe<PayrollDocumentDateInputType>;
  hasApprovalFlow?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  supportedFileTypes: Array<Scalars['String']['output']>;
};

/** PayrollDocumentVersion return type. */
export type PayrollDocumentVersion = {
  __typename?: 'PayrollDocumentVersion';
  file: File;
  id: Scalars['ID']['output'];
  objectHistory?: Maybe<ObjectHistory>;
  payrollRejectedReason?: Maybe<Scalars['String']['output']>;
  payrollStatus: PayrollStatus;
  submittedAt: Scalars['Date']['output'];
};

/** Possible status for a given payroll document */
export enum PayrollStatus {
  Accepted = 'Accepted',
  InReview = 'InReview',
  Rejected = 'Rejected',
}

export type PersonalInformationInput = {
  /** City of the worker */
  addressCity?: InputMaybe<Scalars['String']['input']>;
  /** Address of the worker */
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  /** Address of the worker */
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  /** State of the worker */
  addressState?: InputMaybe<StateAbbreviation>;
  /** Zip code of the worker */
  addressZipCode?: InputMaybe<Scalars['String']['input']>;
  /** Birth date for worker */
  birthDate?: InputMaybe<Scalars['Date']['input']>;
  /** Email of the user */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Email of worker's emergency contact */
  emergencyContactEmail?: InputMaybe<Scalars['String']['input']>;
  /** Name of worker's emergency contact */
  emergencyContactName?: InputMaybe<Scalars['String']['input']>;
  /** Optional Note regarding worker's emergency contact */
  emergencyContactNote?: InputMaybe<Scalars['String']['input']>;
  /** Phone number of worker's emergency contact */
  emergencyContactPhone?: InputMaybe<Scalars['String']['input']>;
  /** Relation of emergency contact to worker */
  emergencyContactRelationship?: InputMaybe<Scalars['String']['input']>;
  /** First name of the user */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Gender of the worker */
  gender?: InputMaybe<Scalars['String']['input']>;
  /** Whether the worker is a skilled worker */
  isSkilled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether the worker is a supervisor */
  isSupervisor?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether the worker is a veteran */
  isVeteran?: InputMaybe<Scalars['Boolean']['input']>;
  /** Job title of the worker */
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  /** Job title class of the worker */
  jobTitleClass?: InputMaybe<Scalars['String']['input']>;
  /** Last name of the user */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** Middle Initial of the worker */
  middleInitial?: InputMaybe<Scalars['String']['input']>;
  /** User's phone */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** Primary language of the worker */
  primaryLanguage?: InputMaybe<Scalars['String']['input']>;
  /** Race of the worker */
  race?: InputMaybe<Scalars['String']['input']>;
  /** last four of SSN for worker */
  ssnLastFour?: InputMaybe<Scalars['String']['input']>;
  /** Suffix of the worker */
  suffix?: InputMaybe<Scalars['String']['input']>;
  /** Trade of the worker */
  trade?: InputMaybe<Scalars['String']['input']>;
  /** Union affiliation of the worker */
  unionAffiliation?: InputMaybe<Scalars['String']['input']>;
};

export enum Possession {
  Any = 'any',
  Own = 'own',
}

export type PreExistingWorker = {
  __typename?: 'PreExistingWorker';
  firstName: Scalars['String']['output'];
  /** Tells whether this worker has completed onboarding at any jobsite */
  hasCompletedOnboarding: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  workerId: Scalars['ID']['output'];
};

export type PreExistingWorkerResponse = {
  __typename?: 'PreExistingWorkerResponse';
  preExistingWorker?: Maybe<PreExistingWorker>;
};

export type PreOnboardWorkerMatchInput = {
  birthDate: Scalars['Date']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type ProximitySessionLocaleData = {
  __typename?: 'ProximitySessionLocaleData';
  day?: Maybe<Scalars['String']['output']>;
  time?: Maybe<ProximitySessionLocaleTimeData>;
};

export type ProximitySessionLocaleTimeData = {
  __typename?: 'ProximitySessionLocaleTimeData';
  beginning?: Maybe<Scalars['String']['output']>;
  end?: Maybe<Scalars['String']['output']>;
  finish?: Maybe<Scalars['String']['output']>;
  start?: Maybe<Scalars['String']['output']>;
};

export type ProximitySessionReadsCountData = {
  __typename?: 'ProximitySessionReadsCountData';
  inbound?: Maybe<Scalars['Int']['output']>;
  outbound?: Maybe<Scalars['Int']['output']>;
};

export type ProximitySessionReadsData = {
  __typename?: 'ProximitySessionReadsData';
  count?: Maybe<Scalars['Int']['output']>;
  counts?: Maybe<ProximitySessionReadsCountData>;
  direction?: Maybe<ProximitySessionReadsDirectionData>;
  first?: Maybe<Scalars['String']['output']>;
  last?: Maybe<Scalars['String']['output']>;
};

export type ProximitySessionReadsDirectionData = {
  __typename?: 'ProximitySessionReadsDirectionData';
  first?: Maybe<Scalars['String']['output']>;
  last?: Maybe<Scalars['String']['output']>;
};

export type ProximitySessionResult = {
  __typename?: 'ProximitySessionResult';
  active: Scalars['Boolean']['output'];
  closed: Scalars['Boolean']['output'];
  compliant: Scalars['Boolean']['output'];
  day: Scalars['String']['output'];
  end?: Maybe<Scalars['Date']['output']>;
  id: Scalars['String']['output'];
  locale?: Maybe<ProximitySessionLocaleData>;
  reads?: Maybe<ProximitySessionReadsData>;
  span?: Maybe<ProximitySessionSpanData>;
  start?: Maybe<Scalars['Date']['output']>;
};

export type ProximitySessionSpanData = {
  __typename?: 'ProximitySessionSpanData';
  beginning?: Maybe<Scalars['Int']['output']>;
  finish?: Maybe<Scalars['Int']['output']>;
  hours?: Maybe<Scalars['Float']['output']>;
  milliseconds?: Maybe<Scalars['Int']['output']>;
  minutes?: Maybe<Scalars['Float']['output']>;
};

/** The root query object for all queries */
export type Query = {
  __typename?: 'Query';
  _empty?: Maybe<Scalars['String']['output']>;
  /** Returns false if a user with the provided email already exists */
  checkEmail: Success;
  /**
   * Gets all contractors a given worker is assigned to. Returns an error if the worker is not found
   * and returns an empty array if the worker is not assigned to any contractors.
   */
  getAllContractorsForWorker: Array<ContractorWorker>;
  getAllDocumentsForJobsiteWorker: Array<JobsiteWorkerDocument>;
  getAllJobsiteWorkerDocumentTypes: Array<JobsiteWorkerDocumentType>;
  /** Get all jobsites in the system */
  getAllJobsites: Array<Jobsite>;
  /**
   * Gets all workers for a given contractor ID. Returns an error if the contractor is not found
   * and returns an empty array if no workers assigned to contractor.
   */
  getAllWorkersForContractor: ContractorWorkerConnection;
  /** Get the available badge templates */
  getBadgeTemplates: Array<BadgeTemplate>;
  getCardPrintJob: CardPrintJob;
  getCardPrintJobs: Array<CardPrintJob>;
  /** Get all jobsites for a construction manager */
  getConstructionManagerJobsites: Array<Jobsite>;
  /** Get a single contractor by ID. Returns error if contractor doesn't exist */
  getContractor: Contractor;
  getContractorMember: ContractorMember;
  /**
   * Get a contractor worker by contractor id and worker id.
   * Returns an error if neither worker or contractor is found,
   * or if given worker is not assigned to the contractor.
   */
  getContractorWorker: ContractorWorker;
  getCurrentCardPrintJob?: Maybe<CardPrintJob>;
  /**
   * Get the current session and user based on the provided session token
   * When 'includeComputedRoles' is 'true', the 'user.roles' field, besides the currently assigned roles,
   * it will also contain some computed roles. The computed roles are jobsite roles which are projected
   * on the contractors assigned to the jobsites where the current user has a role.
   * i.e. if the user has an 'Editor' role on 'Jobsite A' and 'Jobsite A' is assigned to 'Contractor A',
   * then computed 'user.roles' will contain 'Editor' role for 'Contractor A' as well
   */
  getCurrentSession?: Maybe<Auth>;
  /** Get the current worker and session */
  getCurrentWorkerAuth: WorkerAuth;
  getDeveloper: Developer;
  getDeveloperMember: DeveloperMember;
  /** Get all jobsites in a developmentProject */
  getDevelopmentProjectJobsites: Array<Jobsite>;
  getEntityChanges: GetEntityChangesResult;
  getFileDownloadRedirect: FileDownloadRedirect;
  getForm: Form;
  /** Get a single jobsite by ID. Returns error if jobsite doesn't exist */
  getJobsite: Jobsite;
  /** Get a single jobsite announcement by ID. Returns error if announcement doesn't exist */
  getJobsiteAnnouncement: JobsiteAnnouncement;
  /** Get Jobsite Announcement Candidate Recipients */
  getJobsiteAnnouncementCandidateRecipients: JobsiteAnnouncementCandidateRecipientsConnection;
  /** Get all announcements on jobsite. */
  getJobsiteAnnouncements: JobsiteAnnouncementConnection;
  /** Get the badge templates associated with a Jobsite */
  getJobsiteBadgeTemplates: Array<JobsiteBadgeTemplate>;
  /** Get the worker card formats associated with a Jobsite */
  getJobsiteCardFormats: Array<JobsiteCardFormat>;
  /**
   * Get all contractors of a jobsite. One of jobsiteIds or contractorId is required.
   * @deprecated Use jobsite.contractors instead
   */
  getJobsiteContractors: GetJobsiteContractorsResponse;
  /**
   * Get CCure controller status information, filterable by list of jobsite IDs or status value.
   * If no filters specified, return info for all jobsites and statuses.
   */
  getJobsiteEquipmentData: GetJobsiteEquipmentDataResponse;
  /**
   * Get an optionally filtered list of historical status change events for CCure controllers, sorted by date.
   * By default, list of events will be sorted by descending date and limited to 100 results.
   */
  getJobsiteEquipmentHistory: GetJobsiteEquipmentHistoryResponse;
  getJobsiteFormSubmission: JobsiteFormSubmission;
  getJobsiteInvitation: JobsiteInvitation;
  /** Get a member of a jobsite. Returns error if association is not found. */
  getJobsiteMember: JobsiteMember;
  getJobsitePacsGateways: GetJobsitePacsGatewaysResponse;
  /** Get the stats for a jobsite. Returns error if jobsite doesn't exist */
  getJobsiteStats: JobsiteStats;
  /**
   * "
   * Get a single JobsiteWorker object. Returns an error if worker or jobsite is not found. Returns null if
   * worker is not on jobsite.
   */
  getJobsiteWorker?: Maybe<JobsiteWorker>;
  getJobsiteWorkerCheckInSuggestions: JobsiteWorkerCheckInSuggestionConnection;
  getJobsiteWorkerCheckIns: JobsiteWorkerCheckInConnection;
  getJobsiteWorkerDashboard?: Maybe<GetJobsiteWorkerDashboardResponse>;
  getJobsiteWorkerDocument: JobsiteWorkerDocument;
  getJobsiteWorkerDocumentType: JobsiteWorkerDocumentType;
  getJobsiteWorkerDocumentVersion: JobsiteWorkerDocumentVersion;
  /** Get all workers of a jobsite. Returns an error if jobsiteId is not found. */
  getJobsiteWorkers: JobsiteWorkerConnection;
  getJobsiteWorkersByDocumentCompliance: JobsiteWorkerConnection;
  /** Get jobsites by city */
  getJobsitesByCity: Array<Jobsite>;
  getJobsitesStats: JobsitesStats;
  /**
   * Get all jobsites for member. Returns an error if memberUserAccountId is not found. If memberUserAccountId is not
   * provided, then the requesting user account ID is assumed.
   */
  getMemberJobsites: GetMemberJobsitesResponse;
  getMobileDashboardForWorker: MobileDashboardForWorkerResponse;
  getOrganization: Organization;
  getPayrollDocument: PayrollDocument;
  /** Get all available payroll document types. */
  getPayrollDocumentTypes: Array<PayrollDocumentType>;
  getPayrollDocuments: Array<PayrollDocument>;
  getPreExistingWorker: PreExistingWorkerResponse;
  /**
   * Get 'count' most recent, unique search queries executed by the current user.  Defaults to returning the 5 most
   * recent results, if 'count' not specified.
   */
  getRecentUserSearchQueries: UserSearchQueryResponse;
  getRoles: Array<Role>;
  getUserPendingChange: UserPendingChangePayload;
  /** Get a single worker by ID. Returns error if worker doesn't exist */
  getWorker: Worker;
  /**
   * Get a single worker by userAccountId. Returns error if worker doesn't exist. If userAccountId is not
   * provided, then the requesting user account ID is assumed.
   */
  getWorkerByUserAccountId: Worker;
  getWorkerCCureData: WorkerCCureData;
  /** Must provide either workerCardId or cardNumber */
  getWorkerCard: WorkerCard;
  getWorkerCards: Array<WorkerCard>;
  getWorkerCheckInInfo?: Maybe<WorkerCheckInInfo>;
  /** Gets a worker comment */
  getWorkerComment: WorkerComment;
  /** Get all comments for worker */
  getWorkerComments?: Maybe<WorkerCommentConnection>;
  getWorkerDocuments: GetWorkerDocumentsResponse;
  isWorkerCardAssigned: Success;
  /**
   * Search workers accross name, quick code, and email
   * Returns an empty list if none are found.
   */
  searchWorkers: WorkerSearchResponse;
};

/** The root query object for all queries */
export type QueryCheckEmailArgs = {
  email: Scalars['String']['input'];
};

/** The root query object for all queries */
export type QueryGetAllContractorsForWorkerArgs = {
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
  workerId: Scalars['ID']['input'];
};

/** The root query object for all queries */
export type QueryGetAllDocumentsForJobsiteWorkerArgs = {
  includePhi?: InputMaybe<Scalars['Boolean']['input']>;
  jobsiteWorkerId: Scalars['ID']['input'];
};

/** The root query object for all queries */
export type QueryGetAllJobsiteWorkerDocumentTypesArgs = {
  jobsiteId: Scalars['ID']['input'];
};

/** The root query object for all queries */
export type QueryGetAllWorkersForContractorArgs = {
  contractorId: Scalars['ID']['input'];
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  trade?: InputMaybe<Scalars['String']['input']>;
};

/** The root query object for all queries */
export type QueryGetCardPrintJobArgs = {
  cardPrintJobId: Scalars['ID']['input'];
};

/** The root query object for all queries */
export type QueryGetCardPrintJobsArgs = {
  jobsiteId?: InputMaybe<Scalars['ID']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<CardPrintJobStatus>>>;
  workerId?: InputMaybe<Scalars['ID']['input']>;
};

/** The root query object for all queries */
export type QueryGetConstructionManagerJobsitesArgs = {
  constructionManagerId: Scalars['ID']['input'];
};

/** The root query object for all queries */
export type QueryGetContractorArgs = {
  contractorId: Scalars['ID']['input'];
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The root query object for all queries */
export type QueryGetContractorMemberArgs = {
  input: GetContractorMemberInput;
};

/** The root query object for all queries */
export type QueryGetContractorWorkerArgs = {
  contractorId: Scalars['ID']['input'];
  workerId: Scalars['ID']['input'];
};

/** The root query object for all queries */
export type QueryGetCurrentCardPrintJobArgs = {
  workerId: Scalars['ID']['input'];
};

/** The root query object for all queries */
export type QueryGetCurrentSessionArgs = {
  includeComputedRoles?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The root query object for all queries */
export type QueryGetDeveloperArgs = {
  developerId: Scalars['ID']['input'];
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The root query object for all queries */
export type QueryGetDeveloperMemberArgs = {
  input: GetDeveloperMemberInput;
};

/** The root query object for all queries */
export type QueryGetDevelopmentProjectJobsitesArgs = {
  developmentProjectId: Scalars['ID']['input'];
};

/** The root query object for all queries */
export type QueryGetEntityChangesArgs = {
  input?: InputMaybe<GetEntityChangesInput>;
};

/** The root query object for all queries */
export type QueryGetFileDownloadRedirectArgs = {
  downloadUrl: Scalars['String']['input'];
};

/** The root query object for all queries */
export type QueryGetFormArgs = {
  formId: Scalars['ID']['input'];
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The root query object for all queries */
export type QueryGetJobsiteArgs = {
  jobsiteId: Scalars['ID']['input'];
};

/** The root query object for all queries */
export type QueryGetJobsiteAnnouncementArgs = {
  jobsiteAnnouncementId: Scalars['ID']['input'];
};

/** The root query object for all queries */
export type QueryGetJobsiteAnnouncementCandidateRecipientsArgs = {
  input: JobsiteAnnouncementFilterSpecification;
};

/** The root query object for all queries */
export type QueryGetJobsiteAnnouncementsArgs = {
  input?: InputMaybe<GetJobsiteAnnouncementsInput>;
};

/** The root query object for all queries */
export type QueryGetJobsiteBadgeTemplatesArgs = {
  jobsiteId: Scalars['ID']['input'];
};

/** The root query object for all queries */
export type QueryGetJobsiteCardFormatsArgs = {
  cardType?: InputMaybe<WorkerCardType>;
  isDefaultForCardType?: InputMaybe<Scalars['Boolean']['input']>;
  jobsiteId: Scalars['ID']['input'];
};

/** The root query object for all queries */
export type QueryGetJobsiteContractorsArgs = {
  contractorId?: InputMaybe<Scalars['ID']['input']>;
  jobsiteIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** The root query object for all queries */
export type QueryGetJobsiteEquipmentDataArgs = {
  jobsiteIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  status?: InputMaybe<JobsiteEquipmentStatusType>;
};

/** The root query object for all queries */
export type QueryGetJobsiteEquipmentHistoryArgs = {
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  jobsiteIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortDir?: InputMaybe<OrderByDirection>;
  status?: InputMaybe<JobsiteEquipmentStatusType>;
  toDate?: InputMaybe<Scalars['Date']['input']>;
};

/** The root query object for all queries */
export type QueryGetJobsiteFormSubmissionArgs = {
  id: Scalars['ID']['input'];
};

/** The root query object for all queries */
export type QueryGetJobsiteInvitationArgs = {
  jobsiteInvitationId: Scalars['ID']['input'];
};

/** The root query object for all queries */
export type QueryGetJobsiteMemberArgs = {
  jobsiteId: Scalars['ID']['input'];
  memberUserAccountId: Scalars['ID']['input'];
};

/** The root query object for all queries */
export type QueryGetJobsitePacsGatewaysArgs = {
  jobsiteIds: Array<Scalars['ID']['input']>;
};

/** The root query object for all queries */
export type QueryGetJobsiteStatsArgs = {
  jobsiteId: Scalars['ID']['input'];
  submissionDate?: InputMaybe<Scalars['Date']['input']>;
  submissionEndDate?: InputMaybe<Scalars['Date']['input']>;
  submissionStartDate?: InputMaybe<Scalars['Date']['input']>;
  timezoneName?: InputMaybe<Scalars['String']['input']>;
};

/** The root query object for all queries */
export type QueryGetJobsiteWorkerArgs = {
  jobsiteWorkerId: Scalars['ID']['input'];
};

/** The root query object for all queries */
export type QueryGetJobsiteWorkerCheckInSuggestionsArgs = {
  hideNotAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** The root query object for all queries */
export type QueryGetJobsiteWorkerCheckInsArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  jobsiteIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  jobsiteWorkerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  workerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** The root query object for all queries */
export type QueryGetJobsiteWorkerDashboardArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  compliant?: InputMaybe<Scalars['Boolean']['input']>;
  contractor?: InputMaybe<Scalars['String']['input']>;
  endDate: Scalars['Date']['input'];
  export?: InputMaybe<Scalars['Boolean']['input']>;
  fitoutTenant?: InputMaybe<Scalars['String']['input']>;
  jobsiteIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  overnight?: InputMaybe<Scalars['Boolean']['input']>;
  realtime?: InputMaybe<Scalars['Boolean']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
  startDate: Scalars['Date']['input'];
  timeBreakdown?: InputMaybe<GetJobsiteWorkerReportsTimeBreakdown>;
  trade?: InputMaybe<Scalars['String']['input']>;
  tradeClass?: InputMaybe<Scalars['String']['input']>;
  workerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** The root query object for all queries */
export type QueryGetJobsiteWorkerDocumentArgs = {
  jobsiteWorkerDocumentId: Scalars['ID']['input'];
};

/** The root query object for all queries */
export type QueryGetJobsiteWorkerDocumentTypeArgs = {
  jobsiteWorkerDocumentTypeId: Scalars['ID']['input'];
};

/** The root query object for all queries */
export type QueryGetJobsiteWorkerDocumentVersionArgs = {
  jobsiteWorkerDocumentId: Scalars['ID']['input'];
  jobsiteWorkerDocumentVersionId: Scalars['ID']['input'];
};

/** The root query object for all queries */
export type QueryGetJobsiteWorkersArgs = {
  activeAsOf?: InputMaybe<Scalars['Date']['input']>;
  activeOnly?: InputMaybe<Scalars['Boolean']['input']>;
  completedSiteSpecificTraining?: InputMaybe<Scalars['Boolean']['input']>;
  completedSiteSpecificTrainingDate?: InputMaybe<Scalars['Date']['input']>;
  contractorId?: InputMaybe<Scalars['ID']['input']>;
  contractorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  hideBanned?: InputMaybe<Scalars['Boolean']['input']>;
  jobsiteId?: InputMaybe<Scalars['ID']['input']>;
  jobsiteIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  needsMedical?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<GetJobsiteWorkersOrderBy>>>;
  orientationDate?: InputMaybe<Scalars['Date']['input']>;
  orientationEndDate?: InputMaybe<Scalars['Date']['input']>;
  orientationStartDate?: InputMaybe<Scalars['Date']['input']>;
  orientationStatus?: InputMaybe<JobsiteWorkerOrientationStatusFilter>;
  showDuplicateWorkersPerJobsite?: InputMaybe<Scalars['Boolean']['input']>;
  siteSpecificOrientationStatus?: InputMaybe<SiteSpecificOrientationStatus>;
  withContractorOnly?: InputMaybe<Scalars['Boolean']['input']>;
  workerId?: InputMaybe<Scalars['ID']['input']>;
};

/** The root query object for all queries */
export type QueryGetJobsiteWorkersByDocumentComplianceArgs = {
  jobsiteId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  safetyDocumentStatus?: InputMaybe<Scalars['String']['input']>;
  safetyDocumentType?: InputMaybe<Scalars['String']['input']>;
};

/** The root query object for all queries */
export type QueryGetJobsitesByCityArgs = {
  city: Scalars['String']['input'];
  state: StateAbbreviation;
};

/** The root query object for all queries */
export type QueryGetJobsitesStatsArgs = {
  jobsiteIds: Array<Scalars['ID']['input']>;
  submissionDate?: InputMaybe<Scalars['Date']['input']>;
  submissionEndDate?: InputMaybe<Scalars['Date']['input']>;
  submissionStartDate?: InputMaybe<Scalars['Date']['input']>;
  timezoneName?: InputMaybe<Scalars['String']['input']>;
};

/** The root query object for all queries */
export type QueryGetMemberJobsitesArgs = {
  memberUserAccountId?: InputMaybe<Scalars['ID']['input']>;
};

/** The root query object for all queries */
export type QueryGetOrganizationArgs = {
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId: Scalars['ID']['input'];
};

/** The root query object for all queries */
export type QueryGetPayrollDocumentArgs = {
  payrollDocumentId: Scalars['ID']['input'];
};

/** The root query object for all queries */
export type QueryGetPayrollDocumentsArgs = {
  getPayrollDocumentsInput: GetPayrollDocumentsInput;
};

/** The root query object for all queries */
export type QueryGetPreExistingWorkerArgs = {
  input: PreOnboardWorkerMatchInput;
};

/** The root query object for all queries */
export type QueryGetRecentUserSearchQueriesArgs = {
  count?: InputMaybe<Scalars['Int']['input']>;
  searchQueryType?: InputMaybe<SearchQueryType>;
};

/** The root query object for all queries */
export type QueryGetUserPendingChangeArgs = {
  changeType: UserPendingChangeType;
  workerId: Scalars['ID']['input'];
};

/** The root query object for all queries */
export type QueryGetWorkerArgs = {
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
  workerId: Scalars['ID']['input'];
};

/** The root query object for all queries */
export type QueryGetWorkerByUserAccountIdArgs = {
  userAccountId?: InputMaybe<Scalars['ID']['input']>;
};

/** The root query object for all queries */
export type QueryGetWorkerCCureDataArgs = {
  workerId: Scalars['ID']['input'];
};

/** The root query object for all queries */
export type QueryGetWorkerCardArgs = {
  cardNumber?: InputMaybe<Scalars['String']['input']>;
  cardType?: InputMaybe<WorkerCardType>;
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
  workerCardId?: InputMaybe<Scalars['ID']['input']>;
};

/** The root query object for all queries */
export type QueryGetWorkerCardsArgs = {
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
  workerId: Scalars['ID']['input'];
};

/** The root query object for all queries */
export type QueryGetWorkerCheckInInfoArgs = {
  input?: InputMaybe<WorkerCheckInInfoInput>;
};

/** The root query object for all queries */
export type QueryGetWorkerCommentArgs = {
  workerCommentId: Scalars['ID']['input'];
};

/** The root query object for all queries */
export type QueryGetWorkerCommentsArgs = {
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  workerId?: InputMaybe<Scalars['ID']['input']>;
};

/** The root query object for all queries */
export type QueryGetWorkerDocumentsArgs = {
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
  includePhi?: InputMaybe<Scalars['Boolean']['input']>;
  workerId: Scalars['ID']['input'];
};

/** The root query object for all queries */
export type QueryIsWorkerCardAssignedArgs = {
  cardNumber: Scalars['String']['input'];
  cardType: WorkerCardType;
};

/** The root query object for all queries */
export type QuerySearchWorkersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  searchString: Scalars['String']['input'];
};

export type RemoveWorkerFromJobsiteInput = {
  jobsiteWorkerId: Scalars['ID']['input'];
};

export type RemoveWorkerFromJobsiteResponse = {
  __typename?: 'RemoveWorkerFromJobsiteResponse';
  success: Success;
};

/** A role without a user association */
export type Role = {
  __typename?: 'Role';
  createdAt: Scalars['Date']['output'];
  /** Display name of the role */
  displayName: Scalars['String']['output'];
  /** Indicates whether the role is a super-user role */
  isSuper: Scalars['Boolean']['output'];
  /** Human-readable, all lower-case, snake-case identifier for this role. This is unique to this role. */
  key: UserRoleKey;
  /** When the role itself was updated */
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export enum SafetyDocumentStatus {
  Exempt = 'Exempt',
  NotOnFile = 'NotOnFile',
  NycSstExpired = 'NycSstExpired',
  NycSstNotOnFile = 'NycSstNotOnFile',
  NycSstOnFile = 'NycSstOnFile',
  OshaOnFile = 'OshaOnFile',
}

export enum SearchQueryType {
  Worker = 'Worker',
}

export { SelfOnboardingStepKey };

/** Input for creating a worker that does not already have a user account */
export type SelfOnboardingWorkerAccountCreateInput = {
  contractorId: Scalars['ID']['input'];
  /** Input fields for creating a new user account */
  createUserInput: CreateUserInput;
  jobsiteId: Scalars['ID']['input'];
  /** Input fields for creating a new worker */
  workerInput: SelfOnboardingWorkerCreateInput;
};

export type SelfOnboardingWorkerAccountUpdateInput = {
  userInput: UpdateUserInput;
  workerId: Scalars['ID']['input'];
  workerInput: SelfOnboardingWorkerUpdateInput;
};

export enum SelfOnboardingWorkerAction {
  AbortOnboarding = 'AbortOnboarding',
  ResumeOnboarding = 'ResumeOnboarding',
  StartNewOnboarding = 'StartNewOnboarding',
}

/** Input for SelfOnboardingWorkerCreate Mutation */
export type SelfOnboardingWorkerCreateInput = {
  jobsiteInvitationId?: InputMaybe<Scalars['ID']['input']>;
  /** Middle Initial of the worker */
  middleInitial?: InputMaybe<Scalars['String']['input']>;
  /** Primary language of the worker */
  primaryLanguage?: InputMaybe<Scalars['String']['input']>;
  selfOnboardingCurrentStep?: InputMaybe<Scalars['String']['input']>;
  /** Suffix of the worker */
  suffix?: InputMaybe<Scalars['String']['input']>;
  /** Base info for the worker */
  workerBaseInfoInput?: InputMaybe<WorkerBaseInfoInput>;
};

export type SelfOnboardingWorkerCreatePayload = {
  __typename?: 'SelfOnboardingWorkerCreatePayload';
  auth: Auth;
  jobsiteWorker: JobsiteWorker;
};

export type SelfOnboardingWorkerInfo = {
  __typename?: 'SelfOnboardingWorkerInfo';
  continueAction: SelfOnboardingWorkerAction;
  jobsiteWorkerId?: Maybe<Scalars['ID']['output']>;
};

/** Input for SelfOnboardingWorkerUpdate Mutation */
export type SelfOnboardingWorkerUpdateInput = {
  middleInitial?: InputMaybe<Scalars['String']['input']>;
  selfOnboardingCurrentStep?: InputMaybe<Scalars['String']['input']>;
  suffix?: InputMaybe<Scalars['String']['input']>;
  workerBaseInfoInput?: InputMaybe<WorkerBaseInfoInput>;
};

export type SelfOnboardingWorkerUpdatePayload = {
  __typename?: 'SelfOnboardingWorkerUpdatePayload';
  worker: Worker;
};

export enum SessionDisplayColumns {
  BalancedGross = 'balanced_gross',
  NaiveGross = 'naive_gross',
  NetOffsite = 'net_offsite',
  NetOnsite = 'net_onsite',
  NetTotal = 'net_total',
}

/** This is deprecated. */
export enum SessionType {
  Admin = 'ADMIN',
  Contractor = 'CONTRACTOR',
  Fusionauth = 'FUSIONAUTH',
  LimitedWorkerToken = 'LIMITED_WORKER_TOKEN',
  Organization = 'ORGANIZATION',
  Password = 'PASSWORD',
  Passwordless = 'PASSWORDLESS',
  RefreshToken = 'REFRESH_TOKEN',
  WalletToken = 'WALLET_TOKEN',
  Worker = 'WORKER',
}

export enum SiteSpecificOrientationStatus {
  All = 'ALL',
  Complete = 'COMPLETE',
  Expired = 'EXPIRED',
  NotComplete = 'NOT_COMPLETE',
}

export type SizeInput = {
  height?: InputMaybe<Scalars['Int']['input']>;
  width: Scalars['Int']['input'];
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type SortInput = {
  __typename?: 'SortInput';
  direction?: Maybe<SortDirection>;
  field?: Maybe<Scalars['String']['output']>;
};

export type StartPasswordlessLoginInput = {
  loginId: Scalars['String']['input'];
};

export type StartPasswordlessLoginResponse = {
  __typename?: 'StartPasswordlessLoginResponse';
  success: Scalars['Boolean']['output'];
};

export type StartPhoneNumberVerificationInput = {
  phoneNumber: Scalars['String']['input'];
  workerId: Scalars['ID']['input'];
};

export type StartPhoneNumberVerificationResponse = {
  __typename?: 'StartPhoneNumberVerificationResponse';
  success: Scalars['Boolean']['output'];
};

/** Represents all valid state abbreviations */
export enum StateAbbreviation {
  Ak = 'AK',
  Al = 'AL',
  Ar = 'AR',
  Az = 'AZ',
  Ca = 'CA',
  Co = 'CO',
  Ct = 'CT',
  Dc = 'DC',
  De = 'DE',
  Fl = 'FL',
  Ga = 'GA',
  Hi = 'HI',
  Ia = 'IA',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Ma = 'MA',
  Md = 'MD',
  Me = 'ME',
  Mi = 'MI',
  Mn = 'MN',
  Mo = 'MO',
  Ms = 'MS',
  Mt = 'MT',
  Nc = 'NC',
  Nd = 'ND',
  Ne = 'NE',
  Nh = 'NH',
  Nj = 'NJ',
  Nm = 'NM',
  Nv = 'NV',
  Ny = 'NY',
  Oh = 'OH',
  Ok = 'OK',
  Or = 'OR',
  Pa = 'PA',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Tn = 'TN',
  Tx = 'TX',
  Ut = 'UT',
  Va = 'VA',
  Vt = 'VT',
  Wa = 'WA',
  Wi = 'WI',
  Wv = 'WV',
  Wy = 'WY',
}

export type StripeMappingData = {
  __typename?: 'StripeMappingData';
  clientSecret?: Maybe<Scalars['String']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  publicKey?: Maybe<Scalars['String']['output']>;
};

export type StripePaymentMethod = {
  __typename?: 'StripePaymentMethod';
  brand?: Maybe<Scalars['String']['output']>;
  expMonth?: Maybe<Scalars['Int']['output']>;
  expYear?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  last4?: Maybe<Scalars['String']['output']>;
};

export type SubcontractedJobsiteContractorEdge = {
  __typename?: 'SubcontractedJobsiteContractorEdge';
  node: JobsiteContractor;
};

export type SubcontractedJobsiteContractorsConnection = {
  __typename?: 'SubcontractedJobsiteContractorsConnection';
  count: Scalars['Int']['output'];
  edges: Array<SubcontractedJobsiteContractorEdge>;
};

export type SubcontractedJobsiteContractorsInput = {
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
};

export type Success = {
  __typename?: 'Success';
  success: Scalars['Boolean']['output'];
};

export type UpdateUserInput = {
  /** Email of the new user */
  email?: InputMaybe<Scalars['String']['input']>;
  /** First name of the new user */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Last name of the new user */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** Phone number of the new user */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateWorkerCardFormatInput = {
  cardType: WorkerCardType;
  description?: InputMaybe<Scalars['String']['input']>;
  facilityCode?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateWorkerCardInput = {
  cardIssueReason?: InputMaybe<Scalars['String']['input']>;
  cardNumber?: InputMaybe<Scalars['String']['input']>;
  deactivatedReason?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  isReplacement?: InputMaybe<Scalars['Boolean']['input']>;
  isTemporary?: InputMaybe<Scalars['Boolean']['input']>;
  matchingWorkerCardId?: InputMaybe<Scalars['ID']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  temporaryAssignReason?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated type is now on workerCardFormat */
  type?: InputMaybe<WorkerCardType>;
  workerCardFormatId?: InputMaybe<Scalars['ID']['input']>;
};

/**
 * Represents a user and all of its underlying properties. A user can be in one of three states:
 * 1. Anonymous - The user has no identity or auth. The user can still interact with endpoints as if it were a normal
 * user, but they will not be able to fetch that data once the session has expired.
 * 2. Identified - The user has an identity but no auth. This is usually the state of an invited user.
 * 3. Web Account - The user has both an identity and an authentication mechanism. The can use the endpoints fully and
 * can always login with their credentials.
 */
export type User = {
  __typename?: 'User';
  /** Available jobsites or contractors to assign roles on */
  availableRoleObjects: UserAvailableRoleObjectsConnection;
  badgeTemplates: UserBadgeTemplatesConnection;
  cCureEntityMappingOptions: UserCCureEntityMappingOptionsConnection;
  cCureEnvironments: UserCCureEnvironmentsConnection;
  contractorMembers: UserContractorMembersConnection;
  /** All of the contractors that the user has access to, either directly or via subcontracting */
  contractors: UserContractorsConnection;
  /** When the user was created */
  createdAt: Scalars['Date']['output'];
  developers: UserDevelopersConnection;
  forms: UserFormsConnection;
  hasAnnouncementsEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether the user has an authentication method, such as email/password */
  hasAuth: Scalars['Boolean']['output'];
  hasCamerasEnabled?: Maybe<Scalars['Boolean']['output']>;
  hasFormsEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates if a user has paid or free access. */
  hasPaidAccess?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether the user has a urlLockCode set */
  hasUrlLockCode: Scalars['Boolean']['output'];
  hidEntityOptions: UserHidEntityOptionsConnection;
  /** The optional identity of the user. If this is null, the user is anonymous */
  identity?: Maybe<UserIdentity>;
  /** Used for determining whether this user has used the mobile log in flow */
  isMobileUser?: Maybe<Scalars['Boolean']['output']>;
  jobsiteAccessEvents?: Maybe<UserJobsiteAccessEventsConnection>;
  jobsiteAccessSessions?: Maybe<UserJobsiteAccessSessionsConnection>;
  jobsiteFormSubmissions: UserJobsiteFormSubmissionsConnection;
  jobsiteForms: UserJobsiteFormsConnection;
  jobsiteMembers: UserJobsiteMembersConnection;
  /** All of the jobsites that the user has access to */
  jobsites: UserJobsitesConnection;
  /** Most recent user session */
  lastSession?: Maybe<UserSession>;
  organizations: UserOrganizationsConnection;
  /** User profile picture */
  profilePicture?: Maybe<File>;
  /** User cropped profile picture */
  profilePictureCropped?: Maybe<File>;
  /** Indicates which roles the user is a member of */
  roles: Array<UserRole>;
  /** Unique ID of the user */
  userAccountId: Scalars['ID']['output'];
  users: UserUsersConnection;
  worker?: Maybe<Worker>;
  workerCardFormats: UserWorkerCardFormatsConnection;
  workerDocumentTypes: UserWorkerDocumentTypesConnection;
  /** All workers that the user has access to, whether via a contractor or jobsite */
  workers: UserWorkersConnection;
};

/**
 * Represents a user and all of its underlying properties. A user can be in one of three states:
 * 1. Anonymous - The user has no identity or auth. The user can still interact with endpoints as if it were a normal
 * user, but they will not be able to fetch that data once the session has expired.
 * 2. Identified - The user has an identity but no auth. This is usually the state of an invited user.
 * 3. Web Account - The user has both an identity and an authentication mechanism. The can use the endpoints fully and
 * can always login with their credentials.
 */
export type UserAvailableRoleObjectsArgs = {
  input?: InputMaybe<UserAvailableRoleObjectsInput>;
};

/**
 * Represents a user and all of its underlying properties. A user can be in one of three states:
 * 1. Anonymous - The user has no identity or auth. The user can still interact with endpoints as if it were a normal
 * user, but they will not be able to fetch that data once the session has expired.
 * 2. Identified - The user has an identity but no auth. This is usually the state of an invited user.
 * 3. Web Account - The user has both an identity and an authentication mechanism. The can use the endpoints fully and
 * can always login with their credentials.
 */
export type UserCCureEntityMappingOptionsArgs = {
  input?: InputMaybe<UserCCureEnvironmentsInput>;
};

/**
 * Represents a user and all of its underlying properties. A user can be in one of three states:
 * 1. Anonymous - The user has no identity or auth. The user can still interact with endpoints as if it were a normal
 * user, but they will not be able to fetch that data once the session has expired.
 * 2. Identified - The user has an identity but no auth. This is usually the state of an invited user.
 * 3. Web Account - The user has both an identity and an authentication mechanism. The can use the endpoints fully and
 * can always login with their credentials.
 */
export type UserCCureEnvironmentsArgs = {
  input?: InputMaybe<UserCCureEnvironmentsInput>;
};

/**
 * Represents a user and all of its underlying properties. A user can be in one of three states:
 * 1. Anonymous - The user has no identity or auth. The user can still interact with endpoints as if it were a normal
 * user, but they will not be able to fetch that data once the session has expired.
 * 2. Identified - The user has an identity but no auth. This is usually the state of an invited user.
 * 3. Web Account - The user has both an identity and an authentication mechanism. The can use the endpoints fully and
 * can always login with their credentials.
 */
export type UserContractorMembersArgs = {
  input?: InputMaybe<UserContractorMembersInput>;
};

/**
 * Represents a user and all of its underlying properties. A user can be in one of three states:
 * 1. Anonymous - The user has no identity or auth. The user can still interact with endpoints as if it were a normal
 * user, but they will not be able to fetch that data once the session has expired.
 * 2. Identified - The user has an identity but no auth. This is usually the state of an invited user.
 * 3. Web Account - The user has both an identity and an authentication mechanism. The can use the endpoints fully and
 * can always login with their credentials.
 */
export type UserContractorsArgs = {
  input?: InputMaybe<UserContractorsInput>;
};

/**
 * Represents a user and all of its underlying properties. A user can be in one of three states:
 * 1. Anonymous - The user has no identity or auth. The user can still interact with endpoints as if it were a normal
 * user, but they will not be able to fetch that data once the session has expired.
 * 2. Identified - The user has an identity but no auth. This is usually the state of an invited user.
 * 3. Web Account - The user has both an identity and an authentication mechanism. The can use the endpoints fully and
 * can always login with their credentials.
 */
export type UserDevelopersArgs = {
  input?: InputMaybe<UserDevelopersInput>;
};

/**
 * Represents a user and all of its underlying properties. A user can be in one of three states:
 * 1. Anonymous - The user has no identity or auth. The user can still interact with endpoints as if it were a normal
 * user, but they will not be able to fetch that data once the session has expired.
 * 2. Identified - The user has an identity but no auth. This is usually the state of an invited user.
 * 3. Web Account - The user has both an identity and an authentication mechanism. The can use the endpoints fully and
 * can always login with their credentials.
 */
export type UserFormsArgs = {
  input?: InputMaybe<UserFormsInput>;
};

/**
 * Represents a user and all of its underlying properties. A user can be in one of three states:
 * 1. Anonymous - The user has no identity or auth. The user can still interact with endpoints as if it were a normal
 * user, but they will not be able to fetch that data once the session has expired.
 * 2. Identified - The user has an identity but no auth. This is usually the state of an invited user.
 * 3. Web Account - The user has both an identity and an authentication mechanism. The can use the endpoints fully and
 * can always login with their credentials.
 */
export type UserJobsiteAccessEventsArgs = {
  input?: InputMaybe<UserJobsiteAccessEventsInput>;
};

/**
 * Represents a user and all of its underlying properties. A user can be in one of three states:
 * 1. Anonymous - The user has no identity or auth. The user can still interact with endpoints as if it were a normal
 * user, but they will not be able to fetch that data once the session has expired.
 * 2. Identified - The user has an identity but no auth. This is usually the state of an invited user.
 * 3. Web Account - The user has both an identity and an authentication mechanism. The can use the endpoints fully and
 * can always login with their credentials.
 */
export type UserJobsiteAccessSessionsArgs = {
  input?: InputMaybe<UserJobsiteAccessSessionsInput>;
};

/**
 * Represents a user and all of its underlying properties. A user can be in one of three states:
 * 1. Anonymous - The user has no identity or auth. The user can still interact with endpoints as if it were a normal
 * user, but they will not be able to fetch that data once the session has expired.
 * 2. Identified - The user has an identity but no auth. This is usually the state of an invited user.
 * 3. Web Account - The user has both an identity and an authentication mechanism. The can use the endpoints fully and
 * can always login with their credentials.
 */
export type UserJobsiteFormSubmissionsArgs = {
  input?: InputMaybe<UserJobsiteFormSubmissionsInput>;
};

/**
 * Represents a user and all of its underlying properties. A user can be in one of three states:
 * 1. Anonymous - The user has no identity or auth. The user can still interact with endpoints as if it were a normal
 * user, but they will not be able to fetch that data once the session has expired.
 * 2. Identified - The user has an identity but no auth. This is usually the state of an invited user.
 * 3. Web Account - The user has both an identity and an authentication mechanism. The can use the endpoints fully and
 * can always login with their credentials.
 */
export type UserJobsiteFormsArgs = {
  input?: InputMaybe<UserJobsiteFormsInput>;
};

/**
 * Represents a user and all of its underlying properties. A user can be in one of three states:
 * 1. Anonymous - The user has no identity or auth. The user can still interact with endpoints as if it were a normal
 * user, but they will not be able to fetch that data once the session has expired.
 * 2. Identified - The user has an identity but no auth. This is usually the state of an invited user.
 * 3. Web Account - The user has both an identity and an authentication mechanism. The can use the endpoints fully and
 * can always login with their credentials.
 */
export type UserJobsiteMembersArgs = {
  input?: InputMaybe<UserJobsiteMembersInput>;
};

/**
 * Represents a user and all of its underlying properties. A user can be in one of three states:
 * 1. Anonymous - The user has no identity or auth. The user can still interact with endpoints as if it were a normal
 * user, but they will not be able to fetch that data once the session has expired.
 * 2. Identified - The user has an identity but no auth. This is usually the state of an invited user.
 * 3. Web Account - The user has both an identity and an authentication mechanism. The can use the endpoints fully and
 * can always login with their credentials.
 */
export type UserJobsitesArgs = {
  input?: InputMaybe<UserJobsitesInput>;
};

/**
 * Represents a user and all of its underlying properties. A user can be in one of three states:
 * 1. Anonymous - The user has no identity or auth. The user can still interact with endpoints as if it were a normal
 * user, but they will not be able to fetch that data once the session has expired.
 * 2. Identified - The user has an identity but no auth. This is usually the state of an invited user.
 * 3. Web Account - The user has both an identity and an authentication mechanism. The can use the endpoints fully and
 * can always login with their credentials.
 */
export type UserOrganizationsArgs = {
  input?: InputMaybe<GetAllOrganizationsInput>;
};

/**
 * Represents a user and all of its underlying properties. A user can be in one of three states:
 * 1. Anonymous - The user has no identity or auth. The user can still interact with endpoints as if it were a normal
 * user, but they will not be able to fetch that data once the session has expired.
 * 2. Identified - The user has an identity but no auth. This is usually the state of an invited user.
 * 3. Web Account - The user has both an identity and an authentication mechanism. The can use the endpoints fully and
 * can always login with their credentials.
 */
export type UserRolesArgs = {
  input?: InputMaybe<UserRolesInput>;
};

/**
 * Represents a user and all of its underlying properties. A user can be in one of three states:
 * 1. Anonymous - The user has no identity or auth. The user can still interact with endpoints as if it were a normal
 * user, but they will not be able to fetch that data once the session has expired.
 * 2. Identified - The user has an identity but no auth. This is usually the state of an invited user.
 * 3. Web Account - The user has both an identity and an authentication mechanism. The can use the endpoints fully and
 * can always login with their credentials.
 */
export type UserUsersArgs = {
  input?: InputMaybe<UserUsersInput>;
};

/**
 * Represents a user and all of its underlying properties. A user can be in one of three states:
 * 1. Anonymous - The user has no identity or auth. The user can still interact with endpoints as if it were a normal
 * user, but they will not be able to fetch that data once the session has expired.
 * 2. Identified - The user has an identity but no auth. This is usually the state of an invited user.
 * 3. Web Account - The user has both an identity and an authentication mechanism. The can use the endpoints fully and
 * can always login with their credentials.
 */
export type UserWorkerCardFormatsArgs = {
  input?: InputMaybe<UserWorkerCardFormatsInput>;
};

/**
 * Represents a user and all of its underlying properties. A user can be in one of three states:
 * 1. Anonymous - The user has no identity or auth. The user can still interact with endpoints as if it were a normal
 * user, but they will not be able to fetch that data once the session has expired.
 * 2. Identified - The user has an identity but no auth. This is usually the state of an invited user.
 * 3. Web Account - The user has both an identity and an authentication mechanism. The can use the endpoints fully and
 * can always login with their credentials.
 */
export type UserWorkerDocumentTypesArgs = {
  input?: InputMaybe<UserWorkerDocumentTypesInput>;
};

/**
 * Represents a user and all of its underlying properties. A user can be in one of three states:
 * 1. Anonymous - The user has no identity or auth. The user can still interact with endpoints as if it were a normal
 * user, but they will not be able to fetch that data once the session has expired.
 * 2. Identified - The user has an identity but no auth. This is usually the state of an invited user.
 * 3. Web Account - The user has both an identity and an authentication mechanism. The can use the endpoints fully and
 * can always login with their credentials.
 */
export type UserWorkersArgs = {
  input?: InputMaybe<UserWorkersInput>;
};

export type UserAssignInput = {
  memberUserAccountId: Scalars['ID']['input'];
  userAssignmentInput: UserAssignmentInput;
};

export type UserAssignmentInput = {
  /** Title of the member at the jobsite. */
  title?: InputMaybe<Scalars['String']['input']>;
  /** IDs of the jobsites or contractors to assign the member to, based on 'userAssignmentType'. */
  userAssignmentObjectIds: Array<Scalars['ID']['input']>;
  /** Specifies if a user is assigned to a jobsite or a contractor */
  userAssignmentType: UserAssignmentType;
  /** The user role to assign the user to */
  userRoleKey: UserRoleKey;
};

export enum UserAssignmentType {
  Contractor = 'Contractor',
  Developer = 'Developer',
  Jobsite = 'Jobsite',
}

export type UserAvailableRoleObjectsConnection = {
  __typename?: 'UserAvailableRoleObjectsConnection';
  contractors: Array<Contractor>;
  jobsites: Array<Jobsite>;
};

export type UserAvailableRoleObjectsInput = {
  /** If true, includes the jobsites or contractors for which the user already has a role assigned. */
  includeAssignedObjects?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserBadgeTemplatesConnection = {
  __typename?: 'UserBadgeTemplatesConnection';
  count: Scalars['Int']['output'];
  edges?: Maybe<Array<UserBadgeTemplatesEdge>>;
};

export type UserBadgeTemplatesEdge = {
  __typename?: 'UserBadgeTemplatesEdge';
  node: BadgeTemplate;
};

export type UserCCureEntityMappingOptionsConnection = {
  __typename?: 'UserCCureEntityMappingOptionsConnection';
  count: Scalars['Int']['output'];
  edges?: Maybe<Array<UserCCureEntityMappingOptionsEdge>>;
};

export type UserCCureEntityMappingOptionsEdge = {
  __typename?: 'UserCCureEntityMappingOptionsEdge';
  node: CCureEntityMapping;
};

export type UserCCureEntityMappingOptionsInput = {
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
};

export type UserCCureEnvironmentsConnection = {
  __typename?: 'UserCCureEnvironmentsConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserCCureEnvironmentsEdge>;
};

export type UserCCureEnvironmentsEdge = {
  __typename?: 'UserCCureEnvironmentsEdge';
  node: CCureEnvironment;
};

export type UserCCureEnvironmentsInput = {
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
};

export type UserCancelPendingChangeInput = {
  changeType: UserPendingChangeType;
  workerId: Scalars['ID']['input'];
};

export type UserConfirmPendingChangeInput = {
  changeType: UserPendingChangeType;
  workerId: Scalars['ID']['input'];
};

export type UserContractorMembersConnection = {
  __typename?: 'UserContractorMembersConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserContractorMembersEdge>;
};

export type UserContractorMembersEdge = {
  __typename?: 'UserContractorMembersEdge';
  node: ContractorMember;
};

export type UserContractorMembersInput = {
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
};

export type UserContractorsConnection = {
  __typename?: 'UserContractorsConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserContractorsEdge>;
};

export type UserContractorsEdge = {
  __typename?: 'UserContractorsEdge';
  node: Contractor;
};

export type UserContractorsInput = {
  jobsiteIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
  search?: InputMaybe<Scalars['String']['input']>;
};

/** Input for Jobsite Mutations */
export type UserCreateInput = {
  /** If an email invite should be sent */
  sendInviteEmail?: InputMaybe<Scalars['Boolean']['input']>;
  /** Input fields for assigning a user to jobsites or contractors */
  userAssignmentInput: UserAssignmentInput;
  /** Input fields for creating a new user account */
  userInviteInput: UserInviteInput;
};

export type UserDevelopersConnection = {
  __typename?: 'UserDevelopersConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserDevelopersEdge>;
};

export type UserDevelopersEdge = {
  __typename?: 'UserDevelopersEdge';
  node: Developer;
};

export type UserDevelopersInput = {
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export enum UserErrorCode {
  EmailAlreadyExists = 'EMAIL_ALREADY_EXISTS',
  FailedLogin = 'FAILED_LOGIN',
  IncorrectBirthdate = 'INCORRECT_BIRTHDATE',
  InvalidAuthentication = 'INVALID_AUTHENTICATION',
  InvalidEmail = 'INVALID_EMAIL',
  NotAuthenticated = 'NOT_AUTHENTICATED',
  NotAuthorized = 'NOT_AUTHORIZED',
  NoEmailOrPhone = 'NO_EMAIL_OR_PHONE',
  PhoneAlreadyExists = 'PHONE_ALREADY_EXISTS',
  SessionExpired = 'SESSION_EXPIRED',
  UserRoleNotFound = 'USER_ROLE_NOT_FOUND',
}

export type UserFormsConnection = {
  __typename?: 'UserFormsConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserFormsEdge>;
};

export type UserFormsEdge = {
  __typename?: 'UserFormsEdge';
  node: Form;
};

export type UserFormsInput = {
  includeAllForms?: InputMaybe<Scalars['Boolean']['input']>;
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
};

export type UserHidEntityOptionsConnection = {
  __typename?: 'UserHidEntityOptionsConnection';
  count: Scalars['Int']['output'];
  edges?: Maybe<Array<UserHidEntityOptionsEdge>>;
};

export type UserHidEntityOptionsEdge = {
  __typename?: 'UserHidEntityOptionsEdge';
  node: HidEntityOption;
};

/** The identity of a user */
export type UserIdentity = {
  __typename?: 'UserIdentity';
  /** When the identity was created */
  createdAt: Scalars['Date']['output'];
  /** Email address of the user. This is unique to the user. */
  email?: Maybe<Scalars['String']['output']>;
  /** First name of the user */
  firstName?: Maybe<Scalars['String']['output']>;
  /** FusionAuth id of the user */
  fusionAuthId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Last name of the user */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Phone or email pending changes */
  pendingChanges?: Maybe<UserIdentityPendingChanges>;
  /** Phone number of the user */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** When the identity was updated */
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type UserIdentityInput = {
  /** Email of the user */
  email?: InputMaybe<Scalars['String']['input']>;
  /** First name of the user */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Last name of the user */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** Phone or email pending changes */
  pendingChanges?: InputMaybe<UserIdentityPendingChangesInput>;
  /** User's phone */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UserIdentityPendingChange = {
  __typename?: 'UserIdentityPendingChange';
  expiresAt: Scalars['Date']['output'];
  newValue: Scalars['String']['output'];
  submittedBy: User;
};

export type UserIdentityPendingChanges = {
  __typename?: 'UserIdentityPendingChanges';
  email?: Maybe<UserIdentityPendingChange>;
  phoneNumber?: Maybe<UserIdentityPendingChange>;
};

export type UserIdentityPendingChangesInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UserInviteInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UserJobsiteAccessEventsConnection = {
  __typename?: 'UserJobsiteAccessEventsConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserJobsiteAccessEventsEdge>;
  filtersOptions?: Maybe<UserJobsiteAccessEventsFilters>;
};

export type UserJobsiteAccessEventsEdge = {
  __typename?: 'UserJobsiteAccessEventsEdge';
  node: JobsiteAccessEvent;
};

export type UserJobsiteAccessEventsFilters = {
  __typename?: 'UserJobsiteAccessEventsFilters';
  contractors: Array<FilterOption>;
  gateways: Array<FilterOption>;
  jobsites: Array<FilterOption>;
  locationStatuses: Array<Maybe<FilterOption>>;
  rejectionReasons: Array<Maybe<FilterOption>>;
};

export type UserJobsiteAccessEventsInput = {
  contractorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  endDateTime: Scalars['Date']['input'];
  gatewayIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  jobsiteIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  locationStatuses?: InputMaybe<Array<JobsiteWorkerCheckInLocationStatus>>;
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
  rejectionReasons?: InputMaybe<Array<JobsiteAccessEventRejectionReason>>;
  search?: InputMaybe<Scalars['String']['input']>;
  startDateTime: Scalars['Date']['input'];
  status?: InputMaybe<JobsiteAccessEventStatus>;
};

export type UserJobsiteAccessSessionsConnection = {
  __typename?: 'UserJobsiteAccessSessionsConnection';
  breakdowns?: Maybe<Array<Maybe<JobsiteAccessSessionBreakdown>>>;
  count: Scalars['Int']['output'];
  edges: Array<Maybe<JobsiteAccessSessionEdge>>;
};

export type UserJobsiteAccessSessionsInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  breakdowns?: InputMaybe<Array<JobsiteAccessSessionBreakdownType>>;
  compliant?: InputMaybe<Scalars['Boolean']['input']>;
  contractor?: InputMaybe<Scalars['String']['input']>;
  contractorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  endDate: Scalars['Date']['input'];
  jobsiteIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
  startDate: Scalars['Date']['input'];
  trade?: InputMaybe<Scalars['String']['input']>;
  tradeClass?: InputMaybe<Scalars['String']['input']>;
  workerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type UserJobsiteFormSubmissionsConnection = {
  __typename?: 'UserJobsiteFormSubmissionsConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserJobsiteFormSubmissionsEdge>;
  filtersOptions?: Maybe<UserJobsiteFormSubmissionsFilters>;
};

export type UserJobsiteFormSubmissionsEdge = {
  __typename?: 'UserJobsiteFormSubmissionsEdge';
  node: JobsiteFormSubmission;
};

export type UserJobsiteFormSubmissionsFilters = {
  __typename?: 'UserJobsiteFormSubmissionsFilters';
  contractors: Array<FilterOption>;
  createdByUsers: Array<FilterOption>;
  forms: Array<FilterOption>;
};

export type UserJobsiteFormSubmissionsInput = {
  contractorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  endDate: Scalars['Date']['input'];
  formIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  hasAttachments?: InputMaybe<Scalars['Boolean']['input']>;
  jobsiteIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  orderBy?: InputMaybe<Array<GetJobsiteFormSubmissionsOrderBy>>;
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
  startDate: Scalars['Date']['input'];
  workerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type UserJobsiteFormsConnection = {
  __typename?: 'UserJobsiteFormsConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserJobsiteFormsEdge>;
};

export type UserJobsiteFormsEdge = {
  __typename?: 'UserJobsiteFormsEdge';
  node: JobsiteForm;
};

export type UserJobsiteFormsInput = {
  formId?: InputMaybe<Scalars['ID']['input']>;
  formKey?: InputMaybe<Scalars['String']['input']>;
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
};

export type UserJobsiteMembersConnection = {
  __typename?: 'UserJobsiteMembersConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserJobsiteMembersEdge>;
};

export type UserJobsiteMembersEdge = {
  __typename?: 'UserJobsiteMembersEdge';
  node: JobsiteMember;
};

export type UserJobsiteMembersInput = {
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
};

export type UserJobsitesConnection = {
  __typename?: 'UserJobsitesConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserJobsitesEdge>;
  filtersOptions?: Maybe<UserJobsitesFilters>;
  templatesCount: Scalars['Int']['output'];
};

export type UserJobsitesEdge = {
  __typename?: 'UserJobsitesEdge';
  node: Jobsite;
};

export type UserJobsitesFilters = {
  __typename?: 'UserJobsitesFilters';
  cities: Array<FilterOption>;
  developers: Array<FilterOption>;
  states: Array<FilterOption>;
};

export type UserJobsitesInput = {
  cities?: InputMaybe<Array<Scalars['String']['input']>>;
  developerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /**
   * If true and the user is admin, return all jobsites that the user has a access to
   * as opposed to the sites they are assigned to
   */
  includeAllJobsites?: InputMaybe<Scalars['Boolean']['input']>;
  isTemplate?: InputMaybe<Scalars['Boolean']['input']>;
  jobsiteIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  states?: InputMaybe<Array<StateAbbreviation>>;
};

export type UserOrganizationsConnection = {
  __typename?: 'UserOrganizationsConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserOrganizationsEdge>;
};

export type UserOrganizationsEdge = {
  __typename?: 'UserOrganizationsEdge';
  node: Organization;
};

export type UserPayload = {
  __typename?: 'UserPayload';
  user: User;
};

export type UserPendingChange = {
  __typename?: 'UserPendingChange';
  currentValue: Scalars['String']['output'];
  expiresAt: Scalars['Date']['output'];
  newValue: Scalars['String']['output'];
  submittedBy: User;
};

export type UserPendingChangePayload = {
  __typename?: 'UserPendingChangePayload';
  change?: Maybe<UserPendingChange>;
};

export enum UserPendingChangeType {
  Email = 'email',
  Phone = 'phone',
}

export type UserRemoveAssignmentInput = {
  /** ID of the jobsiteMember or contractorMember, based on 'userAssignmentType'. */
  userAssignmentId: Scalars['ID']['input'];
  /** Specifies if a user is assigned to a jobsite or a contractor */
  userAssignmentType: UserAssignmentType;
};

/** A role for a user. A user can be associated with multiple roles. */
export type UserRole = {
  __typename?: 'UserRole';
  /** Display name of the role */
  displayName: Scalars['String']['output'];
  /** Indicates whether the role is a super-user role */
  isSuper: Scalars['Boolean']['output'];
  /** Human-readable, all lower-case, snake-case identifier for this role. This is unique to this role. */
  key: UserRoleKey;
  mappingCreatedAt: Scalars['Date']['output'];
  /** When the mapping of the user to the role was created */
  mappingId?: Maybe<Scalars['ID']['output']>;
  /** When the mapping of the user to the role was updated */
  mappingUpdatedAt: Scalars['Date']['output'];
  object?: Maybe<UserRoleObject>;
  /** When the role itself was created */
  roleCreatedAt: Scalars['Date']['output'];
  /** When the role itself was updated */
  roleUpdatedAt?: Maybe<Scalars['Date']['output']>;
};

export type UserRoleAssignInput = {
  key: UserRoleKey;
  object?: InputMaybe<UserRoleObjectInput>;
};

export enum UserRoleKey {
  Admin = 'admin',
  ClientAdmin = 'client_admin',
  ContractorAdmin = 'contractor_admin',
  ContractorEditor = 'contractor_editor',
  ContractorForeman = 'contractor_foreman',
  ContractorReader = 'contractor_reader',
  JobsiteAdmin = 'jobsite_admin',
  JobsiteDirectoryReader = 'jobsite_directory_reader',
  JobsiteEditor = 'jobsite_editor',
  JobsiteMedicSupervisor = 'jobsite_medic_supervisor',
  JobsiteReader = 'jobsite_reader',
  JobsiteSecurity = 'jobsite_security',
  JobsiteSecuritySupervisor = 'jobsite_security_supervisor',
  JobsiteSiteSafetyManager = 'jobsite_site_safety_manager',
  JobsiteSiteSafetyManagerFitout = 'jobsite_site_safety_manager_fitout',
  Super = 'super',
  Worker = 'worker',
}

export type UserRoleObject = {
  __typename?: 'UserRoleObject';
  /** Details of the object the role is attached to */
  objectDetails?: Maybe<UserRoleObjectDetails>;
  /** Public ID of the object the role is attached to */
  objectId?: Maybe<Scalars['ID']['output']>;
  /** Type of the object the role is attached to */
  objectName?: Maybe<Scalars['String']['output']>;
};

export type UserRoleObjectDetails = {
  __typename?: 'UserRoleObjectDetails';
  /** Public ID of the object the role is attached to */
  id?: Maybe<Scalars['ID']['output']>;
  /** Name of the object the role is attached to */
  name?: Maybe<Scalars['String']['output']>;
  /** 'jobsiteMemberId' or 'contractorMemberId' based on type */
  objectMemberId?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  /** Type of the object the role is attached to */
  type?: Maybe<Scalars['String']['output']>;
};

export type UserRoleObjectInput = {
  objectId: Scalars['ID']['input'];
  objectName: Scalars['String']['input'];
};

export type UserRolesInput = {
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
  objectId?: InputMaybe<Scalars['ID']['input']>;
  objectName?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<GetUserRolesOrderBy>>>;
};

export type UserSearchQuery = {
  __typename?: 'UserSearchQuery';
  createdAt: Scalars['Date']['output'];
  searchQuery: Scalars['String']['output'];
  searchQueryType: SearchQueryType;
  userAccountId: Scalars['ID']['output'];
};

/** Input for saving a user search query */
export type UserSearchQueryInput = {
  /** Text of the user search query */
  searchQuery: Scalars['String']['input'];
  /** Type of search query */
  searchQueryType: SearchQueryType;
};

export type UserSearchQueryResponse = {
  __typename?: 'UserSearchQueryResponse';
  results: Array<UserSearchQuery>;
};

/** Information about a user's session */
export type UserSession = {
  __typename?: 'UserSession';
  /** The user's current app version */
  appVersion?: Maybe<Scalars['String']['output']>;
  /** The authentication mechanism, whether bearer or cookie */
  authType: AuthType;
  /** When the session was created */
  createdAt: Scalars['Date']['output'];
  /** Last time this token was active. This helps determine when to expire the token. */
  lastActiveAt: Scalars['Date']['output'];
  /** Optional refresh token for migrated FusionAuth users */
  refreshToken?: Maybe<Scalars['String']['output']>;
  /** Token to use for all authenticated requests */
  sessionToken?: Maybe<Scalars['String']['output']>;
  /**
   * The type of session
   * @deprecated No longer using sessionType for anything
   */
  sessionType?: Maybe<SessionType>;
  /** When the session was updated */
  updatedAt?: Maybe<Scalars['Date']['output']>;
  /** URL this session is locked to */
  urlLock?: Maybe<Scalars['String']['output']>;
};

export type UserUpdateAssignmentInput = {
  /** Title of the member at the jobsite. */
  title?: InputMaybe<Scalars['String']['input']>;
  /** ID of the jobsiteMember or contractorMember, based on 'userAssignmentType'. */
  userAssignmentId: Scalars['ID']['input'];
  /** Specifies if a user is assigned to a jobsite or a contractor */
  userAssignmentType: UserAssignmentType;
  /** The user role to assign the user to */
  userRoleKey?: InputMaybe<UserRoleKey>;
};

export type UserUpdateIdentityInput = {
  userAccountId: Scalars['ID']['input'];
  userIdentityInput: UserIdentityInput;
};

export type UserUsersConnection = {
  __typename?: 'UserUsersConnection';
  contractorUsersCount: Scalars['Int']['output'];
  developerUsersCount: Scalars['Int']['output'];
  edges: Array<UserUsersEdge>;
  jobsiteUsersCount: Scalars['Int']['output'];
};

export type UserUsersEdge = {
  __typename?: 'UserUsersEdge';
  node: User;
};

export type UserUsersInput = {
  contractorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  developerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  jobsiteIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  orderBy?: InputMaybe<Array<InputMaybe<GetUsersOrderBy>>>;
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  /** Specifies if a user is assigned to a jobsite or a contractor */
  userAssignmentType: UserAssignmentType;
  userRoleKeys?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserWorkerCardFormatsConnection = {
  __typename?: 'UserWorkerCardFormatsConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserWorkerCardFormatsEdge>;
};

export type UserWorkerCardFormatsEdge = {
  __typename?: 'UserWorkerCardFormatsEdge';
  node: WorkerCardFormat;
};

export type UserWorkerCardFormatsInput = {
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
};

export type UserWorkerDocumentTypesConnection = {
  __typename?: 'UserWorkerDocumentTypesConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserWorkerDocumentTypesEdge>;
};

export type UserWorkerDocumentTypesEdge = {
  __typename?: 'UserWorkerDocumentTypesEdge';
  node: WorkerDocumentType;
};

export type UserWorkerDocumentTypesInput = {
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
};

export type UserWorkerProfileAccess = {
  __typename?: 'UserWorkerProfileAccess';
  id: Scalars['ID']['output'];
  isUnlocked: Scalars['Boolean']['output'];
  /** History of the object */
  objectHistory: ObjectHistory;
  unlockedWith: WorkerUnlockProfileType;
  /** The full user object of the user who unlocked the worker profile */
  user: User;
  /** The full worker object */
  worker: Worker;
};

export type UserWorkersConnection = {
  __typename?: 'UserWorkersConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserWorkersEdge>;
  filtersOptions?: Maybe<UserWorkersFilters>;
};

export type UserWorkersEdge = {
  __typename?: 'UserWorkersEdge';
  node: Worker;
};

export type UserWorkersFilters = {
  __typename?: 'UserWorkersFilters';
  tradeClasses: Array<FilterOption>;
  trades: Array<FilterOption>;
};

export type UserWorkersInput = {
  contractorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  jobsiteIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  tradeClasses?: InputMaybe<Array<Scalars['String']['input']>>;
  trades?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ValidModule =
  | JobsiteAppCheckInModule
  | JobsiteFeaturesModule
  | JobsiteOnboardingModule
  | JobsitePreOnboardingModule;

export type WeatherConditions = {
  __typename?: 'WeatherConditions';
  description?: Maybe<Scalars['String']['output']>;
  humidity?: Maybe<Scalars['Int']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  temp?: Maybe<Scalars['Float']['output']>;
  tempHigh?: Maybe<Scalars['Float']['output']>;
  tempLow?: Maybe<Scalars['Float']['output']>;
  timestamp?: Maybe<Scalars['Date']['output']>;
  visibility?: Maybe<Scalars['Int']['output']>;
  windDirectionCardinal?: Maybe<Scalars['String']['output']>;
  windDirectionDegrees?: Maybe<Scalars['Float']['output']>;
  windSpeed?: Maybe<Scalars['Float']['output']>;
};

/**
 * Worker return type. A worker represents a Construction Manager company, as opposed to an
 * individual working either for or at the worker.
 */
export type Worker = {
  __typename?: 'Worker';
  /** Worker access history across jobsites */
  accessHistory?: Maybe<Array<WorkerAccessHistory>>;
  /** City of the worker */
  addressCity?: Maybe<Scalars['String']['output']>;
  /** Address of the worker */
  addressLine1?: Maybe<Scalars['String']['output']>;
  /** Address of the worker */
  addressLine2?: Maybe<Scalars['String']['output']>;
  /** State of the worker */
  addressState?: Maybe<StateAbbreviation>;
  /** Zip code of the worker */
  addressZipCode?: Maybe<Scalars['String']['output']>;
  /** Birth date of the worker */
  birthDate?: Maybe<Scalars['Date']['output']>;
  citizenshipStatus?: Maybe<WorkerCitizenshipStatus>;
  contractorWorkers: WorkerContractorWorkersConnection;
  createdMethod?: Maybe<WorkerCreatedMethod>;
  documents: WorkerDocumentsConnection;
  editableFields: EditableFields;
  /** Email of worker's emergency contact */
  emergencyContactEmail?: Maybe<Scalars['String']['output']>;
  /** Name of worker's emergency contact */
  emergencyContactName?: Maybe<Scalars['String']['output']>;
  /** Optional Note regarding worker's emergency contact */
  emergencyContactNote?: Maybe<Scalars['String']['output']>;
  /** Phone number of worker's emergency contact */
  emergencyContactPhone?: Maybe<Scalars['String']['output']>;
  /** Relation of emergency contact to worker */
  emergencyContactRelationship?: Maybe<Scalars['String']['output']>;
  /** Gender of the worker */
  gender?: Maybe<Scalars['String']['output']>;
  /** Tells whether this worker has been archived */
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  isAssignedToMultipleJobsites?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the worker is a skilled worker */
  isSkilled?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the worker is a veteran */
  isVeteran?: Maybe<Scalars['Boolean']['output']>;
  /** Job title of the worker */
  jobTitle?: Maybe<Scalars['String']['output']>;
  /** Job title class of the worker */
  jobTitleClass?: Maybe<Scalars['String']['output']>;
  /** Jobsite access events for the worker */
  jobsiteAccessEvents?: Maybe<WorkerJobsiteAccessEventsConnection>;
  /** Jobsite access sessions for the worker */
  jobsiteAccessSessions?: Maybe<WorkerJobsiteAccessSessionsConnection>;
  jobsiteWorkers: WorkerJobsiteWorkersConnection;
  /** Middle Initial of the worker */
  middleInitial?: Maybe<Scalars['String']['output']>;
  /** History of the object */
  objectHistory: ObjectHistory;
  /** Primary language of the worker */
  primaryLanguage?: Maybe<Scalars['String']['output']>;
  profileAccess?: Maybe<UserWorkerProfileAccess>;
  /** Worker profile picture */
  profilePicture?: Maybe<File>;
  /** Worker cropped profile picture */
  profilePictureCropped?: Maybe<File>;
  quickCode?: Maybe<Scalars['String']['output']>;
  /** Race of the worker */
  race?: Maybe<Scalars['String']['output']>;
  selfOnboardingCurrentStep?: Maybe<Scalars['String']['output']>;
  /** SSN of the worker */
  ssn?: Maybe<Scalars['String']['output']>;
  /** Last four digits SSN of the worker */
  ssnLastFour?: Maybe<Scalars['String']['output']>;
  /** Suffix of the worker */
  suffix?: Maybe<Scalars['String']['output']>;
  /** Trade of the worker */
  trade?: Maybe<Scalars['String']['output']>;
  /** Union affiliation of the worker */
  unionAffiliation?: Maybe<Scalars['String']['output']>;
  /** Full user object of the worker */
  user: User;
  workerCards: WorkerWorkerCardsConnection;
  /** Comments for worker */
  workerComments: WorkerWorkerCommentsConnection;
  /** Unique ID for the worker */
  workerId: Scalars['ID']['output'];
};

/**
 * Worker return type. A worker represents a Construction Manager company, as opposed to an
 * individual working either for or at the worker.
 */
export type WorkerContractorWorkersArgs = {
  input?: InputMaybe<WorkerContractorWorkersInput>;
};

/**
 * Worker return type. A worker represents a Construction Manager company, as opposed to an
 * individual working either for or at the worker.
 */
export type WorkerDocumentsArgs = {
  input?: InputMaybe<WorkerDocumentsInput>;
};

/**
 * Worker return type. A worker represents a Construction Manager company, as opposed to an
 * individual working either for or at the worker.
 */
export type WorkerJobsiteAccessEventsArgs = {
  input?: InputMaybe<WorkerJobsiteAccessEventsInput>;
};

/**
 * Worker return type. A worker represents a Construction Manager company, as opposed to an
 * individual working either for or at the worker.
 */
export type WorkerJobsiteAccessSessionsArgs = {
  input?: InputMaybe<WorkerJobsiteAccessSessionsInput>;
};

/**
 * Worker return type. A worker represents a Construction Manager company, as opposed to an
 * individual working either for or at the worker.
 */
export type WorkerJobsiteWorkersArgs = {
  input?: InputMaybe<WorkerJobsiteWorkersInput>;
};

/**
 * Worker return type. A worker represents a Construction Manager company, as opposed to an
 * individual working either for or at the worker.
 */
export type WorkerWorkerCardsArgs = {
  input?: InputMaybe<WorkerWorkerCardsInput>;
};

/**
 * Worker return type. A worker represents a Construction Manager company, as opposed to an
 * individual working either for or at the worker.
 */
export type WorkerWorkerCommentsArgs = {
  input?: InputMaybe<WorkerWorkerCommentsInput>;
};

export type WorkerAccessHistory = {
  __typename?: 'WorkerAccessHistory';
  banEndDate?: Maybe<Scalars['Date']['output']>;
  changeApprovedBy: Scalars['String']['output'];
  changeCategory?: Maybe<Scalars['String']['output']>;
  changeDate: Scalars['Date']['output'];
  changeReason: Scalars['String']['output'];
  changeToAccess: Scalars['String']['output'];
  jobsiteName: Scalars['String']['output'];
  updatedBy: Scalars['String']['output'];
  workerAccessHistoryId: Scalars['ID']['output'];
};

export type WorkerAccount = {
  __typename?: 'WorkerAccount';
  email?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  fusionAuthId?: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  userAccountId: Scalars['String']['output'];
  userIdentityId: Scalars['String']['output'];
  workerId: Scalars['ID']['output'];
};

export type WorkerAuth = {
  __typename?: 'WorkerAuth';
  session: UserSession;
  worker: Worker;
};

export type WorkerBaseInfoInput = {
  /** Birth date for worker */
  birthDate?: InputMaybe<Scalars['Date']['input']>;
  /** last four of SSN for worker */
  ssnLastFour?: InputMaybe<Scalars['String']['input']>;
};

export type WorkerCCureData = {
  __typename?: 'WorkerCCureData';
  data?: Maybe<Scalars['JSONObject']['output']>;
};

export type WorkerCard = {
  __typename?: 'WorkerCard';
  cardIssueReason?: Maybe<Scalars['String']['output']>;
  cardNumber?: Maybe<Scalars['String']['output']>;
  cardStatus?: Maybe<CardStatus>;
  deactivatedAt?: Maybe<Scalars['Date']['output']>;
  deactivatedReason?: Maybe<Scalars['String']['output']>;
  expiredAt?: Maybe<Scalars['Date']['output']>;
  isReplacement?: Maybe<Scalars['Boolean']['output']>;
  isTemporary?: Maybe<Scalars['Boolean']['output']>;
  issuedAt?: Maybe<Scalars['Date']['output']>;
  matchingWorkerCardId?: Maybe<Scalars['ID']['output']>;
  temporaryAssignReason?: Maybe<Scalars['String']['output']>;
  /** @deprecated type is now on workerCardFormat */
  type?: Maybe<WorkerCardType>;
  worker: Worker;
  workerCardFormat: WorkerCardFormat;
  workerCardId?: Maybe<Scalars['ID']['output']>;
};

export type WorkerCardFormat = {
  __typename?: 'WorkerCardFormat';
  cardType: WorkerCardType;
  description?: Maybe<Scalars['String']['output']>;
  facilityCode?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  workerCardFormatId?: Maybe<Scalars['ID']['output']>;
};

export type WorkerCardFormatInput = {
  cardType: WorkerCardType;
  description?: InputMaybe<Scalars['String']['input']>;
  facilityCode?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
};

export type WorkerCardInput = {
  cardIssueReason?: InputMaybe<Scalars['String']['input']>;
  cardNumber?: InputMaybe<Scalars['String']['input']>;
  deactivatedReason?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  isReplacement?: InputMaybe<Scalars['Boolean']['input']>;
  isTemporary?: InputMaybe<Scalars['Boolean']['input']>;
  matchingWorkerCardId?: InputMaybe<Scalars['ID']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  temporaryAssignReason?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated type is now on workerCardFormat */
  type?: InputMaybe<WorkerCardType>;
  workerCardFormatId?: InputMaybe<Scalars['ID']['input']>;
};

export enum WorkerCardStatus {
  Active = 'Active',
  Disabled = 'Disabled',
  Lost = 'Lost',
  Stolen = 'Stolen',
}

export enum WorkerCardType {
  Bluetooth = 'Bluetooth',
  FacialRecognition = 'FacialRecognition',
  MobileBadge = 'MobileBadge',
  Proximity = 'Proximity',
  QrCode = 'QrCode',
}

export type WorkerCheckInError = {
  __typename?: 'WorkerCheckInError';
  code: AppErrorCode;
  contractor?: Maybe<Contractor>;
  currentAccess?: Maybe<JobsiteWorkerCurrentAccess>;
  message?: Maybe<Scalars['String']['output']>;
  worker?: Maybe<Worker>;
};

export type WorkerCheckInInfo = {
  __typename?: 'WorkerCheckInInfo';
  contractor?: Maybe<Contractor>;
  currentAccess?: Maybe<JobsiteWorkerCurrentAccess>;
  jobsite?: Maybe<Jobsite>;
  /** Either workerId or qrCode */
  key: Scalars['String']['output'];
  rejectionReason?: Maybe<JobsiteAccessEventRejectionReason>;
  worker?: Maybe<Worker>;
};

/** Input for getting a worker checking in info based on scanned code */
export type WorkerCheckInInfoInput = {
  areaId: Scalars['ID']['input'];
  direction: JobsiteWorkerCheckInDirection;
  jobsiteId: Scalars['ID']['input'];
  /** Either workerId or qrCode */
  key: Scalars['String']['input'];
  location?: InputMaybe<GpsCoordinateInput>;
  qrCode?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<JobsiteWorkerCheckInSource>;
  workerId?: InputMaybe<Scalars['ID']['input']>;
};

export type WorkerCheckInInput = {
  checkedInAt?: InputMaybe<Scalars['Date']['input']>;
  key: Scalars['String']['input'];
  qrCode?: InputMaybe<Scalars['String']['input']>;
  workerId?: InputMaybe<Scalars['ID']['input']>;
};

export type WorkerCheckInResult = {
  __typename?: 'WorkerCheckInResult';
  checkIn?: Maybe<JobsiteWorkerCheckIn>;
  error?: Maybe<WorkerCheckInError>;
  key: Scalars['String']['output'];
};

export enum WorkerCitizenshipStatus {
  Citizen = 'Citizen',
  NonCitizen = 'NonCitizen',
}

export type WorkerComment = {
  __typename?: 'WorkerComment';
  comment: Scalars['String']['output'];
  jobsite?: Maybe<Jobsite>;
  objectHistory?: Maybe<ObjectHistory>;
  worker?: Maybe<Worker>;
  workerCommentId: Scalars['ID']['output'];
};

export type WorkerCommentConnection = {
  __typename?: 'WorkerCommentConnection';
  edges: Array<Maybe<WorkerComment>>;
  totalCount: Scalars['Int']['output'];
};

export type WorkerCommentInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  jobsiteId?: InputMaybe<Scalars['ID']['input']>;
  workerId: Scalars['ID']['input'];
};

export type WorkerContractorWorkersConnection = {
  __typename?: 'WorkerContractorWorkersConnection';
  count: Scalars['Int']['output'];
  edges: Array<WorkerContractorWorkersEdge>;
};

export type WorkerContractorWorkersEdge = {
  __typename?: 'WorkerContractorWorkersEdge';
  node: ContractorWorker;
};

export type WorkerContractorWorkersInput = {
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
};

export enum WorkerCreatedMethod {
  ContractorApi = 'ContractorApi',
  MobileApp = 'MobileApp',
  SelfOnboarding = 'SelfOnboarding',
  WebApp = 'WebApp',
}

export type WorkerDocumentAdditionalField = {
  __typename?: 'WorkerDocumentAdditionalField';
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export enum WorkerDocumentLevel {
  Jobsite = 'Jobsite',
  Organization = 'Organization',
  Worker = 'Worker',
}

export type WorkerDocumentType = {
  __typename?: 'WorkerDocumentType';
  documentLevel?: Maybe<WorkerDocumentLevel>;
  expirationDate?: Maybe<Scalars['Date']['output']>;
  isGlobal: Scalars['Boolean']['output'];
  isPhi: Scalars['Boolean']['output'];
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  workerDocumentTypeId: Scalars['ID']['output'];
};

export type WorkerDocumentsConnection = {
  __typename?: 'WorkerDocumentsConnection';
  count: Scalars['Int']['output'];
  edges: Array<WorkerDocumentsEdge>;
};

export type WorkerDocumentsEdge = {
  __typename?: 'WorkerDocumentsEdge';
  node: JobsiteWorkerDocument;
};

export type WorkerDocumentsInput = {
  includePhi?: InputMaybe<Scalars['Boolean']['input']>;
  jobsiteIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
};

export type WorkerErrorInfo = {
  __typename?: 'WorkerErrorInfo';
  birthDate?: Maybe<Scalars['Date']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  middleInitial?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  quickCode?: Maybe<Scalars['String']['output']>;
  selfOnboardingInfo?: Maybe<SelfOnboardingWorkerInfo>;
  ssnLastFour?: Maybe<Scalars['String']['output']>;
  suffix?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  trade: Scalars['String']['output'];
  unionAffiliation?: Maybe<Scalars['String']['output']>;
  workerId: Scalars['ID']['output'];
};

export enum WorkerFileField {
  ProfilePicture = 'profilePicture',
  ProfilePictureCropped = 'profilePictureCropped',
}

/** Input for Worker Mutations */
export type WorkerInput = {
  /** City of the worker */
  addressCity?: InputMaybe<Scalars['String']['input']>;
  /** Address of the worker */
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  /** Address of the worker */
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  /** State of the worker */
  addressState?: InputMaybe<StateAbbreviation>;
  /** Zip code of the worker */
  addressZipCode?: InputMaybe<Scalars['String']['input']>;
  citizenshipStatus?: InputMaybe<WorkerCitizenshipStatus>;
  createdMethod?: InputMaybe<WorkerCreatedMethod>;
  /** Email of worker's emergency contact */
  emergencyContactEmail?: InputMaybe<Scalars['String']['input']>;
  /** Name of worker's emergency contact */
  emergencyContactName?: InputMaybe<Scalars['String']['input']>;
  /** Optional Note regarding worker's emergency contact */
  emergencyContactNote?: InputMaybe<Scalars['String']['input']>;
  /** Phone number of worker's emergency contact */
  emergencyContactPhone?: InputMaybe<Scalars['String']['input']>;
  /** Relation of emergency contact to worker */
  emergencyContactRelationship?: InputMaybe<Scalars['String']['input']>;
  /** Gender of the worker */
  gender?: InputMaybe<Scalars['String']['input']>;
  /** Whether the worker is a skilled worker */
  isSkilled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether the worker is a supervisor */
  isSupervisor?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether the worker is a veteran */
  isVeteran?: InputMaybe<Scalars['Boolean']['input']>;
  /** Job title of the worker */
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  /** Job title class of the worker */
  jobTitleClass?: InputMaybe<Scalars['String']['input']>;
  jobsiteInvitationId?: InputMaybe<Scalars['ID']['input']>;
  /** Middle Initial of the worker */
  middleInitial?: InputMaybe<Scalars['String']['input']>;
  /** Primary language of the worker */
  primaryLanguage?: InputMaybe<Scalars['String']['input']>;
  /** ID of the uploaded profile picture */
  profilePictureFileId?: InputMaybe<Scalars['ID']['input']>;
  quickCode?: InputMaybe<Scalars['String']['input']>;
  /** Race of the worker */
  race?: InputMaybe<Scalars['String']['input']>;
  selfOnboardingCurrentStep?: InputMaybe<Scalars['String']['input']>;
  /** SSN of the worker */
  ssn?: InputMaybe<Scalars['String']['input']>;
  /** Suffix of the worker */
  suffix?: InputMaybe<Scalars['String']['input']>;
  /** Trade of the worker */
  trade?: InputMaybe<Scalars['String']['input']>;
  /** Union affiliation of the worker */
  unionAffiliation?: InputMaybe<Scalars['String']['input']>;
  /** Base info for the worker */
  workerBaseInfoInput?: InputMaybe<WorkerBaseInfoInput>;
};

export type WorkerJobsiteAccessEventsConnection = {
  __typename?: 'WorkerJobsiteAccessEventsConnection';
  count: Scalars['Int']['output'];
  edges: Array<WorkerJobsiteAccessEventsEdge>;
  filtersOptions?: Maybe<WorkerJobsiteAccessEventsFilters>;
};

export type WorkerJobsiteAccessEventsEdge = {
  __typename?: 'WorkerJobsiteAccessEventsEdge';
  node: JobsiteAccessEvent;
};

export type WorkerJobsiteAccessEventsFilters = {
  __typename?: 'WorkerJobsiteAccessEventsFilters';
  contractors: Array<FilterOption>;
  gateways: Array<FilterOption>;
  jobsites: Array<FilterOption>;
  locationStatuses: Array<Maybe<FilterOption>>;
  rejectionReasons: Array<Maybe<FilterOption>>;
};

export type WorkerJobsiteAccessEventsInput = {
  contractorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  endDateTime: Scalars['Date']['input'];
  gatewayIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  jobsiteIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  locationStatuses?: InputMaybe<Array<JobsiteWorkerCheckInLocationStatus>>;
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
  rejectionReasons?: InputMaybe<Array<JobsiteAccessEventRejectionReason>>;
  startDateTime: Scalars['Date']['input'];
  status?: InputMaybe<JobsiteAccessEventStatus>;
};

export type WorkerJobsiteAccessSessionsConnection = {
  __typename?: 'WorkerJobsiteAccessSessionsConnection';
  count: Scalars['Int']['output'];
  edges: Array<Maybe<JobsiteAccessSessionEdge>>;
  filtersOptions?: Maybe<WorkerJobsiteAccessSessionsFilters>;
};

export type WorkerJobsiteAccessSessionsFilters = {
  __typename?: 'WorkerJobsiteAccessSessionsFilters';
  contractors: Array<FilterOption>;
  jobsites: Array<FilterOption>;
};

export type WorkerJobsiteAccessSessionsInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  compliant?: InputMaybe<Scalars['Boolean']['input']>;
  contractor?: InputMaybe<Scalars['String']['input']>;
  contractorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  endDate: Scalars['Date']['input'];
  jobsiteIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
  startDate: Scalars['Date']['input'];
  trade?: InputMaybe<Scalars['String']['input']>;
  tradeClass?: InputMaybe<Scalars['String']['input']>;
};

export type WorkerJobsiteBan = {
  __typename?: 'WorkerJobsiteBan';
  /** When the worker is allowed back to the site */
  accessReadmissionDate?: Maybe<Scalars['Date']['output']>;
  /** When the worker's access was disabled */
  disabledAccessDate?: Maybe<Scalars['Date']['output']>;
  /** Indicates whether the worker has access temporarily disabled. */
  isDisabledAccess?: Maybe<Scalars['Boolean']['output']>;
  jobsite: Jobsite;
};

export type WorkerJobsiteWorkersConnection = {
  __typename?: 'WorkerJobsiteWorkersConnection';
  count: Scalars['Int']['output'];
  edges: Array<WorkerJobsiteWorkersEdge>;
};

export type WorkerJobsiteWorkersEdge = {
  __typename?: 'WorkerJobsiteWorkersEdge';
  node: JobsiteWorker;
};

export type WorkerJobsiteWorkersInput = {
  contractorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
  jobsiteIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
};

export type WorkerSearchResponse = {
  __typename?: 'WorkerSearchResponse';
  count: Scalars['Int']['output'];
  results: Array<WorkerSearchResult>;
};

export type WorkerSearchResult = {
  __typename?: 'WorkerSearchResult';
  worker: Worker;
};

export type WorkerUnlockProfileInput = {
  birthDate?: InputMaybe<Scalars['Date']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  ssnLastFour?: InputMaybe<Scalars['String']['input']>;
  unlockType: WorkerUnlockProfileType;
  workerId: Scalars['ID']['input'];
};

export type WorkerUnlockProfilePayload = {
  __typename?: 'WorkerUnlockProfilePayload';
  userWorkerProfileAccess: UserWorkerProfileAccess;
};

export enum WorkerUnlockProfileType {
  BirthDate = 'birthDate',
  Email = 'email',
  PhoneNumber = 'phoneNumber',
  SsnLastFour = 'ssnLastFour',
}

export type WorkerWorkerCardsConnection = {
  __typename?: 'WorkerWorkerCardsConnection';
  count: Scalars['Int']['output'];
  edges: Array<WorkerWorkerCardsEdge>;
};

export type WorkerWorkerCardsEdge = {
  __typename?: 'WorkerWorkerCardsEdge';
  node: WorkerCard;
};

export type WorkerWorkerCardsInput = {
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
};

export type WorkerWorkerCommentsConnection = {
  __typename?: 'WorkerWorkerCommentsConnection';
  count: Scalars['Int']['output'];
  edges: Array<WorkerWorkerCommentsEdge>;
};

export type WorkerWorkerCommentsEdge = {
  __typename?: 'WorkerWorkerCommentsEdge';
  node: WorkerComment;
};

export type WorkerWorkerCommentsInput = {
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
  paginationInput?: InputMaybe<LimitOffsetPaginationInput>;
};

export type WorkersCheckInConnection = {
  __typename?: 'WorkersCheckInConnection';
  count: Scalars['Int']['output'];
  edges: Array<WorkersCheckInEdge>;
};

export type WorkersCheckInEdge = {
  __typename?: 'WorkersCheckInEdge';
  node: WorkerCheckInResult;
};

/** Input for checking in multiple workers based on scanned codes */
export type WorkersCheckInInput = {
  areaId: Scalars['ID']['input'];
  checkInType?: InputMaybe<JobsiteWorkerCheckInType>;
  checkIns: Array<WorkerCheckInInput>;
  direction: JobsiteWorkerCheckInDirection;
  jobsiteId: Scalars['ID']['input'];
  location?: InputMaybe<GpsCoordinateInput>;
  source?: InputMaybe<JobsiteWorkerCheckInSource>;
};

export type TrackIntegrationHandoffMutationVariables = Exact<{
  integrationType: ExternalIntegrationType;
}>;

export type TrackIntegrationHandoffMutation = { __typename?: 'Mutation' } & {
  trackIntegrationHandoff: { __typename?: 'IntegrationDetails' } & Pick<IntegrationDetails, 'integrationType' | 'url'>;
};

export type TrackSessionMutationVariables = Exact<{
  sessionType: SessionType;
}>;

export type TrackSessionMutation = { __typename?: 'Mutation' } & {
  trackSession: { __typename?: 'Success' } & Pick<Success, 'success'>;
};

export type GetCurrentSessionQueryVariables = Exact<{
  includeComputedRoles?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetCurrentSessionQuery = { __typename?: 'Query' } & {
  getCurrentSession?: Maybe<
    { __typename?: 'Auth' } & {
      user: { __typename?: 'User' } & Pick<
        User,
        | 'userAccountId'
        | 'createdAt'
        | 'hasUrlLockCode'
        | 'hasPaidAccess'
        | 'hasAnnouncementsEnabled'
        | 'hasFormsEnabled'
        | 'hasCamerasEnabled'
      > & {
          identity?: Maybe<
            { __typename?: 'UserIdentity' } & Pick<
              UserIdentity,
              'email' | 'firstName' | 'lastName' | 'createdAt' | 'updatedAt'
            >
          >;
          roles: Array<
            { __typename?: 'UserRole' } & Pick<UserRole, 'key'> & {
                object?: Maybe<{ __typename?: 'UserRoleObject' } & Pick<UserRoleObject, 'objectName' | 'objectId'>>;
              }
          >;
          profilePictureCropped?: Maybe<{ __typename?: 'File' } & Pick<File, 'downloadUrl' | 'fileId' | 'fileType'>>;
          worker?: Maybe<{ __typename?: 'Worker' } & Pick<Worker, 'workerId'>>;
        };
      session: { __typename?: 'UserSession' } & Pick<
        UserSession,
        'sessionToken' | 'authType' | 'createdAt' | 'updatedAt' | 'lastActiveAt' | 'urlLock' | 'appVersion'
      >;
    }
  >;
};

export type SearchWorkersFromSearchBoxQueryVariables = Exact<{
  searchString: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type SearchWorkersFromSearchBoxQuery = { __typename?: 'Query' } & {
  searchWorkers: { __typename?: 'WorkerSearchResponse' } & Pick<WorkerSearchResponse, 'count'> & {
      results: Array<
        { __typename?: 'WorkerSearchResult' } & {
          worker: { __typename?: 'Worker' } & Pick<
            Worker,
            'workerId' | 'birthDate' | 'quickCode' | 'trade' | 'jobTitle' | 'unionAffiliation'
          > & {
              user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
                  identity?: Maybe<
                    { __typename?: 'UserIdentity' } & Pick<
                      UserIdentity,
                      'firstName' | 'lastName' | 'email' | 'phoneNumber'
                    >
                  >;
                };
              profilePicture?: Maybe<{ __typename?: 'File' } & Pick<File, 'downloadUrl'>>;
              profilePictureCropped?: Maybe<{ __typename?: 'File' } & Pick<File, 'downloadUrl'>>;
              contractorWorkers: { __typename?: 'WorkerContractorWorkersConnection' } & {
                edges: Array<
                  { __typename?: 'WorkerContractorWorkersEdge' } & {
                    node: { __typename?: 'ContractorWorker' } & {
                      contractor: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId'> & {
                          organization: { __typename?: 'Organization' } & Pick<Organization, 'name' | 'slug'>;
                        };
                      jobsiteWorkers: { __typename?: 'ContractorWorkerJobsiteWorkersConnection' } & {
                        edges: Array<
                          { __typename?: 'ContractorWorkerJobsiteWorkersEdge' } & {
                            node: { __typename?: 'JobsiteWorker' } & Pick<
                              JobsiteWorker,
                              'jobsiteWorkerId' | 'stickerNumber'
                            > & {
                                currentAccess: { __typename?: 'JobsiteWorkerCurrentAccess' } & Pick<
                                  JobsiteWorkerCurrentAccess,
                                  'isAllowed'
                                >;
                                jobsiteContractor: { __typename?: 'JobsiteContractor' } & {
                                  jobsite: { __typename?: 'Jobsite' } & Pick<Jobsite, 'name' | 'jobsiteId'>;
                                };
                              };
                          }
                        >;
                      };
                    };
                  }
                >;
              };
            };
        }
      >;
    };
};

export type GetJobsiteAnnouncementQueryVariables = Exact<{
  announcementId: Scalars['ID']['input'];
}>;

export type GetJobsiteAnnouncementQuery = { __typename?: 'Query' } & {
  getJobsiteAnnouncement: { __typename?: 'JobsiteAnnouncement' } & Pick<
    JobsiteAnnouncement,
    | 'announcementType'
    | 'jobsiteAnnouncementId'
    | 'subject'
    | 'status'
    | 'message'
    | 'sentAt'
    | 'startAt'
    | 'endAt'
    | 'timeZone'
    | 'jobsiteAccessStatus'
    | 'accessWithinDayRange'
    | 'trades'
    | 'titles'
    | 'languages'
  > & {
      jobsites: { __typename?: 'JobsiteAnnouncementJobsitesConnection' } & Pick<
        JobsiteAnnouncementJobsitesConnection,
        'count'
      > & {
          edges: Array<
            { __typename?: 'JobsiteAnnouncementJobsitesEdge' } & {
              node: { __typename?: 'Jobsite' } & Pick<Jobsite, 'jobsiteId' | 'name'>;
            }
          >;
        };
      contractors: { __typename?: 'JobsiteAnnouncementContractorsConnection' } & Pick<
        JobsiteAnnouncementContractorsConnection,
        'count'
      > & {
          edges: Array<
            { __typename?: 'JobsiteAnnouncementContractorsEdge' } & {
              node: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId'> & {
                  organization: { __typename?: 'Organization' } & Pick<Organization, 'name'>;
                };
            }
          >;
        };
      sentBy?: Maybe<
        { __typename?: 'User' } & {
          profilePictureCropped?: Maybe<{ __typename?: 'File' } & Pick<File, 'downloadUrl'>>;
          identity?: Maybe<{ __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName'>>;
        }
      >;
    };
};

export type CreateJobsiteAnnouncementMutationVariables = Exact<{
  jobsiteAnnouncementInput: JobsiteAnnouncementInput;
}>;

export type CreateJobsiteAnnouncementMutation = { __typename?: 'Mutation' } & {
  createJobsiteAnnouncement: { __typename?: 'JobsiteAnnouncement' } & Pick<
    JobsiteAnnouncement,
    | 'announcementType'
    | 'jobsiteAnnouncementId'
    | 'subject'
    | 'status'
    | 'message'
    | 'sentAt'
    | 'startAt'
    | 'endAt'
    | 'timeZone'
    | 'jobsiteAccessStatus'
    | 'accessWithinDayRange'
    | 'trades'
    | 'titles'
    | 'languages'
  > & {
      jobsites: { __typename?: 'JobsiteAnnouncementJobsitesConnection' } & Pick<
        JobsiteAnnouncementJobsitesConnection,
        'count'
      > & {
          edges: Array<
            { __typename?: 'JobsiteAnnouncementJobsitesEdge' } & {
              node: { __typename?: 'Jobsite' } & Pick<Jobsite, 'jobsiteId' | 'name'>;
            }
          >;
        };
    };
};

export type DeleteJobsiteAnnouncementMutationVariables = Exact<{
  jobsiteAnnouncementId: Scalars['ID']['input'];
}>;

export type DeleteJobsiteAnnouncementMutation = { __typename?: 'Mutation' } & {
  deleteJobsiteAnnouncement: { __typename: 'Success' } & Pick<Success, 'success'>;
};

export type GetJobsiteAnnouncementCandidateCountsQueryVariables = Exact<{
  input: JobsiteAnnouncementFilterSpecification;
}>;

export type GetJobsiteAnnouncementCandidateCountsQuery = { __typename?: 'Query' } & {
  getJobsiteAnnouncementCandidateRecipients: { __typename?: 'JobsiteAnnouncementCandidateRecipientsConnection' } & Pick<
    JobsiteAnnouncementCandidateRecipientsConnection,
    'totalCount' | 'totalReachableCount'
  >;
};

export type GetJobsiteAnnouncementsQueryVariables = Exact<{
  getJobsiteAnnouncementsInput?: InputMaybe<GetJobsiteAnnouncementsInput>;
}>;

export type GetJobsiteAnnouncementsQuery = { __typename?: 'Query' } & {
  getJobsiteAnnouncements: { __typename?: 'JobsiteAnnouncementConnection' } & Pick<
    JobsiteAnnouncementConnection,
    'count'
  > & {
      edges: Array<
        { __typename?: 'JobsiteAnnouncementEdge' } & {
          node: { __typename?: 'JobsiteAnnouncement' } & Pick<
            JobsiteAnnouncement,
            | 'announcementType'
            | 'jobsiteAnnouncementId'
            | 'subject'
            | 'status'
            | 'message'
            | 'sentAt'
            | 'startAt'
            | 'endAt'
            | 'timeZone'
            | 'jobsiteAccessStatus'
            | 'accessWithinDayRange'
            | 'trades'
            | 'titles'
          > & {
              jobsites: { __typename?: 'JobsiteAnnouncementJobsitesConnection' } & Pick<
                JobsiteAnnouncementJobsitesConnection,
                'count'
              > & {
                  edges: Array<
                    { __typename?: 'JobsiteAnnouncementJobsitesEdge' } & {
                      node: { __typename?: 'Jobsite' } & Pick<Jobsite, 'jobsiteId' | 'name'>;
                    }
                  >;
                };
            };
        }
      >;
    };
};

export type GetJobsiteAnnouncementsContainerContractorsSessionQueryVariables = Exact<{
  userContractorsInput?: InputMaybe<UserContractorsInput>;
}>;

export type GetJobsiteAnnouncementsContainerContractorsSessionQuery = { __typename?: 'Query' } & {
  getCurrentSession?: Maybe<
    { __typename?: 'Auth' } & {
      user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
          contractors: { __typename?: 'UserContractorsConnection' } & Pick<UserContractorsConnection, 'count'> & {
              edges: Array<
                { __typename?: 'UserContractorsEdge' } & {
                  node: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId'> & {
                      organization: { __typename?: 'Organization' } & Pick<Organization, 'name'>;
                    };
                }
              >;
            };
        };
    }
  >;
};

export type ContractorAddJobsitesMutationVariables = Exact<{
  input: ContractorAddJobsitesInput;
}>;

export type ContractorAddJobsitesMutation = { __typename?: 'Mutation' } & {
  contractorAddJobsites: { __typename?: 'ContractorAddJobsitesPayload' } & {
    jobsiteContractors: Array<{ __typename?: 'JobsiteContractor' } & Pick<JobsiteContractor, 'id'>>;
  };
};

export type ContractorRemoveWorkerMutationVariables = Exact<{
  input: ContractorRemoveWorkerInput;
}>;

export type ContractorRemoveWorkerMutation = { __typename?: 'Mutation' } & {
  contractorRemoveWorker: { __typename?: 'ContractorRemoveWorkerPayload' } & {
    success: { __typename?: 'Success' } & Pick<Success, 'success'>;
  };
};

export type CreateContractorStripeInvoiceMutationVariables = Exact<{
  input: CreateContractorStripeInvoiceInput;
}>;

export type CreateContractorStripeInvoiceMutation = { __typename?: 'Mutation' } & {
  createContractorStripeInvoice: { __typename?: 'CreateContractorStripeInvoicePayload' } & Pick<
    CreateContractorStripeInvoicePayload,
    'invoiceId' | 'customerId' | 'url' | 'status'
  >;
};

export type InitiateStripePaymentSetupSessionMutationVariables = Exact<{
  input: InitiateStripePaymentSetupSessionInput;
}>;

export type InitiateStripePaymentSetupSessionMutation = { __typename?: 'Mutation' } & {
  initiateStripePaymentSetupSession: { __typename?: 'InitiateStripePaymentSetupSessionPayload' } & Pick<
    InitiateStripePaymentSetupSessionPayload,
    'clientSecret' | 'customerId' | 'publicKey' | 'sessionId'
  >;
};

export type JobsiteContractorUpdateStripePaymentMethodMutationVariables = Exact<{
  input: JobsiteContractorUpdateStripePaymentMethodInput;
}>;

export type JobsiteContractorUpdateStripePaymentMethodMutation = { __typename?: 'Mutation' } & {
  jobsiteContractorUpdateStripePaymentMethod: { __typename?: 'JobsiteContractorUpdateStripePaymentMethodPayload' } & {
    success: { __typename?: 'Success' } & Pick<Success, 'success'>;
  };
};

export type RemoveStripePaymentMethodMutationVariables = Exact<{
  input: ManageContractorStripePaymentMethodInput;
}>;

export type RemoveStripePaymentMethodMutation = { __typename?: 'Mutation' } & {
  removeStripePaymentMethod?: Maybe<
    Array<
      { __typename?: 'StripePaymentMethod' } & Pick<
        StripePaymentMethod,
        'id' | 'brand' | 'last4' | 'expMonth' | 'expYear' | 'isDefault'
      >
    >
  >;
};

export type SetDefaultStripePaymentMethodMutationVariables = Exact<{
  input: ManageContractorStripePaymentMethodInput;
}>;

export type SetDefaultStripePaymentMethodMutation = { __typename?: 'Mutation' } & {
  setDefaultStripePaymentMethod?: Maybe<
    Array<
      { __typename?: 'StripePaymentMethod' } & Pick<
        StripePaymentMethod,
        'id' | 'brand' | 'last4' | 'expMonth' | 'expYear' | 'isDefault'
      >
    >
  >;
};

export type GetContractorStripePaymentMethodsDetailsQueryVariables = Exact<{
  contractorId: Scalars['ID']['input'];
  includeArchived: Scalars['Boolean']['input'];
}>;

export type GetContractorStripePaymentMethodsDetailsQuery = { __typename?: 'Query' } & {
  getContractor: { __typename?: 'Contractor' } & Pick<
    Contractor,
    'contractorId' | 'isArchived' | 'websiteUrl' | 'description'
  > & {
      stripeMappingDetails?: Maybe<
        { __typename?: 'StripeMappingData' } & Pick<StripeMappingData, 'customerId' | 'publicKey' | 'clientSecret'>
      >;
      organization: { __typename?: 'Organization' } & Pick<
        Organization,
        | 'organizationId'
        | 'name'
        | 'legalName'
        | 'email'
        | 'addressLine1'
        | 'addressLine2'
        | 'addressCity'
        | 'addressState'
        | 'addressZipCode'
      >;
      billingContact?: Maybe<
        { __typename?: 'ContactPerson' } & Pick<ContactPerson, 'firstName' | 'lastName' | 'email' | 'phoneNumber'>
      >;
      objectHistory: { __typename?: 'ObjectHistory' } & Pick<ObjectHistory, 'createdAt'> & {
          createdBy?: Maybe<
            { __typename?: 'User' } & {
              identity?: Maybe<{ __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName'>>;
            }
          >;
        };
    };
};

export type GetJobsitesAndContractorsQueryVariables = Exact<{
  userJobsitesInput: UserJobsitesInput;
}>;

export type GetJobsitesAndContractorsQuery = { __typename?: 'Query' } & {
  getCurrentSession?: Maybe<
    { __typename?: 'Auth' } & {
      user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
          jobsites: { __typename?: 'UserJobsitesConnection' } & Pick<UserJobsitesConnection, 'count'> & {
              edges: Array<
                { __typename?: 'UserJobsitesEdge' } & {
                  node: { __typename?: 'Jobsite' } & Pick<Jobsite, 'jobsiteId'> & {
                      jobsiteContractors: { __typename?: 'JobsiteJobsiteContractorsConnection' } & Pick<
                        JobsiteJobsiteContractorsConnection,
                        'count'
                      > & {
                          edges: Array<
                            { __typename?: 'JobsiteJobsiteContractorsEdge' } & {
                              node: { __typename?: 'JobsiteContractor' } & Pick<
                                JobsiteContractor,
                                'id' | 'startDate'
                              > & {
                                  contractor: { __typename?: 'Contractor' } & Pick<
                                    Contractor,
                                    'contractorId' | 'isDefaultContractor'
                                  > & {
                                      organization: { __typename?: 'Organization' } & Pick<
                                        Organization,
                                        'name' | 'slug' | 'legalName'
                                      >;
                                    };
                                };
                            }
                          >;
                        };
                    };
                }
              >;
            };
        };
    }
  >;
};

export type GetContractorContainerContractorInfoQueryVariables = Exact<{
  contractorId: Scalars['ID']['input'];
  includeArchived: Scalars['Boolean']['input'];
}>;

export type GetContractorContainerContractorInfoQuery = { __typename?: 'Query' } & {
  getContractor: { __typename?: 'Contractor' } & Pick<
    Contractor,
    'contractorId' | 'isArchived' | 'websiteUrl' | 'description'
  > & {
      organization: { __typename?: 'Organization' } & Pick<
        Organization,
        | 'organizationId'
        | 'slug'
        | 'name'
        | 'legalName'
        | 'email'
        | 'addressLine1'
        | 'addressLine2'
        | 'addressCity'
        | 'addressState'
        | 'addressZipCode'
      >;
      billingContact?: Maybe<
        { __typename?: 'ContactPerson' } & Pick<ContactPerson, 'firstName' | 'lastName' | 'email' | 'phoneNumber'>
      >;
      objectHistory: { __typename?: 'ObjectHistory' } & Pick<ObjectHistory, 'createdAt'> & {
          createdBy?: Maybe<
            { __typename?: 'User' } & {
              identity?: Maybe<{ __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName'>>;
            }
          >;
        };
    };
};

export type GetContractorContainerContractorJobsitesQueryVariables = Exact<{
  contractorId: Scalars['ID']['input'];
  includeArchived: Scalars['Boolean']['input'];
}>;

export type GetContractorContainerContractorJobsitesQuery = { __typename?: 'Query' } & {
  getContractor: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId' | 'isArchived'> & {
      organization: { __typename?: 'Organization' } & Pick<Organization, 'organizationId' | 'name'>;
      jobsiteContractors: { __typename?: 'ContractorJobsiteContractorsConnection' } & Pick<
        ContractorJobsiteContractorsConnection,
        'count'
      > & {
          edges: Array<
            { __typename?: 'ContractorJobsiteContractorsEdge' } & {
              node: { __typename?: 'JobsiteContractor' } & Pick<
                JobsiteContractor,
                'id' | 'stripePaymentMethodId' | 'startDate' | 'endDate'
              > & {
                  jobsite: { __typename?: 'Jobsite' } & Pick<
                    Jobsite,
                    'name' | 'jobsiteId' | 'addressCity' | 'addressState'
                  >;
                  parentJobsiteContractor?: Maybe<
                    { __typename?: 'JobsiteContractor' } & {
                      contractor: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId'> & {
                          organization: { __typename?: 'Organization' } & Pick<Organization, 'name'>;
                        };
                    }
                  >;
                  contact?: Maybe<
                    { __typename?: 'ContactPerson' } & Pick<
                      ContactPerson,
                      'firstName' | 'lastName' | 'phoneNumber' | 'email' | 'title'
                    >
                  >;
                  jobsiteWorkers: { __typename?: 'JobsiteContractorJobsiteWorkersConnection' } & Pick<
                    JobsiteContractorJobsiteWorkersConnection,
                    'count'
                  >;
                  subcontractedJobsiteContractors: { __typename?: 'SubcontractedJobsiteContractorsConnection' } & Pick<
                    SubcontractedJobsiteContractorsConnection,
                    'count'
                  >;
                  jobsiteInvitation?: Maybe<
                    { __typename?: 'JobsiteInvitation' } & Pick<JobsiteInvitation, 'jobsiteInvitationId'>
                  >;
                };
            }
          >;
        };
    };
  getCurrentSession?: Maybe<
    { __typename?: 'Auth' } & {
      user: { __typename?: 'User' } & {
        jobsites: { __typename?: 'UserJobsitesConnection' } & Pick<UserJobsitesConnection, 'count'> & {
            edges: Array<
              { __typename?: 'UserJobsitesEdge' } & {
                node: { __typename?: 'Jobsite' } & Pick<Jobsite, 'jobsiteId' | 'name' | 'parentId'>;
              }
            >;
          };
      };
    }
  >;
};

export type GetContractorContainerContractorSubcontractorsQueryVariables = Exact<{
  contractorId: Scalars['ID']['input'];
  includeArchived: Scalars['Boolean']['input'];
  contractorContractorWorkersInput?: InputMaybe<ContractorContractorWorkersInput>;
}>;

export type GetContractorContainerContractorSubcontractorsQuery = { __typename?: 'Query' } & {
  getContractor: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId' | 'isArchived'> & {
      organization: { __typename?: 'Organization' } & Pick<Organization, 'name' | 'addressCity' | 'addressState'>;
      jobsiteContractors: { __typename?: 'ContractorJobsiteContractorsConnection' } & Pick<
        ContractorJobsiteContractorsConnection,
        'count'
      > & {
          edges: Array<
            { __typename?: 'ContractorJobsiteContractorsEdge' } & {
              node: { __typename?: 'JobsiteContractor' } & {
                jobsite: { __typename?: 'Jobsite' } & Pick<Jobsite, 'jobsiteId' | 'name'>;
                subcontractedJobsiteContractors: { __typename?: 'SubcontractedJobsiteContractorsConnection' } & Pick<
                  SubcontractedJobsiteContractorsConnection,
                  'count'
                > & {
                    edges: Array<
                      { __typename?: 'SubcontractedJobsiteContractorEdge' } & {
                        node: { __typename?: 'JobsiteContractor' } & Pick<JobsiteContractor, 'endDate'> & {
                            contractor: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId'> & {
                                organization: { __typename?: 'Organization' } & Pick<Organization, 'name'>;
                              };
                            jobsite: { __typename?: 'Jobsite' } & Pick<Jobsite, 'jobsiteId' | 'name'>;
                            jobsiteWorkers: { __typename?: 'JobsiteContractorJobsiteWorkersConnection' } & Pick<
                              JobsiteContractorJobsiteWorkersConnection,
                              'count'
                            >;
                          };
                      }
                    >;
                  };
              };
            }
          >;
        };
      contractorWorkers: { __typename?: 'ContractorContractorWorkersConnection' } & Pick<
        ContractorContractorWorkersConnection,
        'count'
      >;
      contractorMembers: { __typename?: 'ContractorContractorMembersConnection' } & Pick<
        ContractorContractorMembersConnection,
        'count'
      >;
    };
};

export type GetContractorContainerContractorUsersQueryVariables = Exact<{
  contractorId: Scalars['ID']['input'];
  includeArchived: Scalars['Boolean']['input'];
  contractorContractorMemberInput?: InputMaybe<ContractorContractorMembersInput>;
  userRolesInput?: InputMaybe<UserRolesInput>;
}>;

export type GetContractorContainerContractorUsersQuery = { __typename?: 'Query' } & {
  getContractor: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId' | 'isArchived'> & {
      organization: { __typename?: 'Organization' } & Pick<Organization, 'organizationId' | 'name'>;
      contractorMembers: { __typename?: 'ContractorContractorMembersConnection' } & Pick<
        ContractorContractorMembersConnection,
        'count'
      > & {
          edges: Array<
            { __typename?: 'ContractorContractorMembersEdge' } & {
              node: { __typename?: 'ContractorMember' } & Pick<ContractorMember, 'id'> & {
                  user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
                      roles: Array<
                        { __typename?: 'UserRole' } & Pick<UserRole, 'key' | 'displayName'> & {
                            object?: Maybe<
                              { __typename?: 'UserRoleObject' } & Pick<UserRoleObject, 'objectId'> & {
                                  objectDetails?: Maybe<
                                    { __typename?: 'UserRoleObjectDetails' } & Pick<UserRoleObjectDetails, 'name'>
                                  >;
                                }
                            >;
                          }
                      >;
                      identity?: Maybe<
                        { __typename?: 'UserIdentity' } & Pick<
                          UserIdentity,
                          'firstName' | 'lastName' | 'email' | 'phoneNumber'
                        >
                      >;
                      worker?: Maybe<
                        { __typename?: 'Worker' } & Pick<Worker, 'workerId'> & {
                            profilePictureCropped?: Maybe<{ __typename?: 'File' } & Pick<File, 'downloadUrl'>>;
                          }
                      >;
                      lastSession?: Maybe<
                        { __typename?: 'UserSession' } & Pick<UserSession, 'createdAt' | 'appVersion'>
                      >;
                    };
                };
            }
          >;
        };
    };
};

export type GetContractorContainerContractorWorkersQueryVariables = Exact<{
  contractorId: Scalars['ID']['input'];
  includeArchived: Scalars['Boolean']['input'];
  contractorContractorWorkersInput?: InputMaybe<ContractorContractorWorkersInput>;
}>;

export type GetContractorContainerContractorWorkersQuery = { __typename?: 'Query' } & {
  getContractor: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId' | 'isArchived'> & {
      organization: { __typename?: 'Organization' } & Pick<Organization, 'organizationId' | 'name'>;
      jobsiteContractors: { __typename?: 'ContractorJobsiteContractorsConnection' } & {
        edges: Array<
          { __typename?: 'ContractorJobsiteContractorsEdge' } & {
            node: { __typename?: 'JobsiteContractor' } & {
              jobsite: { __typename?: 'Jobsite' } & Pick<Jobsite, 'jobsiteId' | 'name'>;
            };
          }
        >;
      };
      contractorWorkers: { __typename?: 'ContractorContractorWorkersConnection' } & Pick<
        ContractorContractorWorkersConnection,
        'count'
      > & {
          edges: Array<
            { __typename?: 'ContractorContractorWorkersEdge' } & {
              node: { __typename?: 'ContractorWorker' } & Pick<ContractorWorker, 'id'> & {
                  worker: { __typename?: 'Worker' } & Pick<Worker, 'workerId' | 'birthDate' | 'quickCode' | 'trade'> & {
                      user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
                          identity?: Maybe<
                            { __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName' | 'email'>
                          >;
                        };
                      profilePicture?: Maybe<
                        { __typename?: 'File' } & Pick<File, 'downloadUrl' | 'uploadedAt' | 'fileId'>
                      >;
                      profilePictureCropped?: Maybe<
                        { __typename?: 'File' } & Pick<File, 'downloadUrl' | 'uploadedAt' | 'fileId' | 'fileType'>
                      >;
                      workerCards: { __typename?: 'WorkerWorkerCardsConnection' } & {
                        edges: Array<
                          { __typename?: 'WorkerWorkerCardsEdge' } & {
                            node: { __typename?: 'WorkerCard' } & Pick<
                              WorkerCard,
                              'workerCardId' | 'cardNumber' | 'cardStatus'
                            >;
                          }
                        >;
                      };
                    };
                  jobsiteWorkers: { __typename?: 'ContractorWorkerJobsiteWorkersConnection' } & Pick<
                    ContractorWorkerJobsiteWorkersConnection,
                    'count'
                  > & {
                      edges: Array<
                        { __typename?: 'ContractorWorkerJobsiteWorkersEdge' } & {
                          node: { __typename?: 'JobsiteWorker' } & Pick<JobsiteWorker, 'jobsiteWorkerId'> & {
                              jobsiteContractor: { __typename?: 'JobsiteContractor' } & {
                                jobsite: { __typename?: 'Jobsite' } & Pick<Jobsite, 'jobsiteId' | 'name'>;
                                contractor: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId'> & {
                                    organization: { __typename?: 'Organization' } & Pick<Organization, 'name'>;
                                  };
                              };
                              currentAccess: { __typename?: 'JobsiteWorkerCurrentAccess' } & Pick<
                                JobsiteWorkerCurrentAccess,
                                'isAllowed' | 'notAllowedReason' | 'startDate' | 'endDate'
                              >;
                            };
                        }
                      >;
                    };
                };
            }
          >;
        };
    };
};

export type GetContractorContainerPaymentMethodsQueryVariables = Exact<{
  contractorId: Scalars['ID']['input'];
  includeArchived: Scalars['Boolean']['input'];
}>;

export type GetContractorContainerPaymentMethodsQuery = { __typename?: 'Query' } & {
  getContractor: { __typename?: 'Contractor' } & Pick<
    Contractor,
    'contractorId' | 'isArchived' | 'websiteUrl' | 'description'
  > & {
      stripeMappingDetails?: Maybe<
        { __typename?: 'StripeMappingData' } & Pick<StripeMappingData, 'customerId' | 'publicKey'>
      >;
      stripePaymentMethods?: Maybe<
        Array<
          { __typename?: 'StripePaymentMethod' } & Pick<
            StripePaymentMethod,
            'id' | 'brand' | 'last4' | 'expMonth' | 'expYear' | 'isDefault'
          >
        >
      >;
      organization: { __typename?: 'Organization' } & Pick<
        Organization,
        | 'organizationId'
        | 'name'
        | 'legalName'
        | 'email'
        | 'addressLine1'
        | 'addressLine2'
        | 'addressCity'
        | 'addressState'
        | 'addressZipCode'
      >;
      billingContact?: Maybe<
        { __typename?: 'ContactPerson' } & Pick<ContactPerson, 'firstName' | 'lastName' | 'email' | 'phoneNumber'>
      >;
      objectHistory: { __typename?: 'ObjectHistory' } & Pick<ObjectHistory, 'createdAt'> & {
          createdBy?: Maybe<
            { __typename?: 'User' } & {
              identity?: Maybe<{ __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName'>>;
            }
          >;
        };
      jobsiteContractors: { __typename?: 'ContractorJobsiteContractorsConnection' } & Pick<
        ContractorJobsiteContractorsConnection,
        'count'
      > & {
          edges: Array<
            { __typename?: 'ContractorJobsiteContractorsEdge' } & {
              node: { __typename?: 'JobsiteContractor' } & Pick<JobsiteContractor, 'id' | 'stripePaymentMethodId'> & {
                  jobsite: { __typename?: 'Jobsite' } & Pick<
                    Jobsite,
                    'name' | 'jobsiteId' | 'addressCity' | 'addressState' | 'startDate' | 'endDate'
                  >;
                };
            }
          >;
        };
    };
};

export type GetJobsiteContractorsQueryVariables = Exact<{
  jobsiteId: Scalars['ID']['input'];
}>;

export type GetJobsiteContractorsQuery = { __typename?: 'Query' } & {
  getJobsite: { __typename?: 'Jobsite' } & {
    jobsiteContractors: { __typename?: 'JobsiteJobsiteContractorsConnection' } & Pick<
      JobsiteJobsiteContractorsConnection,
      'count'
    > & {
        edges: Array<
          { __typename?: 'JobsiteJobsiteContractorsEdge' } & {
            node: { __typename?: 'JobsiteContractor' } & Pick<
              JobsiteContractor,
              'id' | 'startDate' | 'stripePaymentMethodId'
            > & {
                contractor: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId' | 'isDefaultContractor'> & {
                    organization: { __typename?: 'Organization' } & Pick<
                      Organization,
                      'organizationId' | 'name' | 'slug' | 'legalName'
                    >;
                  };
              };
          }
        >;
      };
  };
};

export type ContractorCreateMutationVariables = Exact<{
  input: ContractorCreateInput;
}>;

export type ContractorCreateMutation = { __typename?: 'Mutation' } & {
  contractorCreate: { __typename?: 'ContractorCreatePayload' } & {
    contractor: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId'>;
  };
};

export type ContractorUpdateMutationVariables = Exact<{
  input: ContractorUpdateInput;
}>;

export type ContractorUpdateMutation = { __typename?: 'Mutation' } & {
  contractorUpdate: { __typename?: 'ContractorUpdatePayload' } & {
    contractor: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId'>;
  };
};

export type GetContractorsContainerCurrentSessionQueryVariables = Exact<{
  userContractorsInput?: InputMaybe<UserContractorsInput>;
}>;

export type GetContractorsContainerCurrentSessionQuery = { __typename?: 'Query' } & {
  getCurrentSession?: Maybe<
    { __typename?: 'Auth' } & {
      user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
          contractors: { __typename?: 'UserContractorsConnection' } & Pick<UserContractorsConnection, 'count'> & {
              edges: Array<
                { __typename?: 'UserContractorsEdge' } & {
                  node: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId'> & {
                      organization: { __typename?: 'Organization' } & Pick<
                        Organization,
                        'name' | 'addressCity' | 'addressState'
                      >;
                      jobsiteContractors: { __typename?: 'ContractorJobsiteContractorsConnection' } & {
                        edges: Array<
                          { __typename?: 'ContractorJobsiteContractorsEdge' } & {
                            node: { __typename?: 'JobsiteContractor' } & Pick<
                              JobsiteContractor,
                              'startDate' | 'endDate'
                            > & {
                                jobsite: { __typename?: 'Jobsite' } & Pick<Jobsite, 'name' | 'jobsiteId'>;
                                jobsiteWorkers: { __typename?: 'JobsiteContractorJobsiteWorkersConnection' } & Pick<
                                  JobsiteContractorJobsiteWorkersConnection,
                                  'count'
                                >;
                              };
                          }
                        >;
                      };
                    };
                }
              >;
            };
        };
    }
  >;
};

export type GenerateLookerJobsiteWorkerReportMutationVariables = Exact<{
  jobsiteIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  startDate: Scalars['Date']['input'];
  endDate: Scalars['Date']['input'];
  realtime?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
  export?: InputMaybe<Scalars['Boolean']['input']>;
  trade?: InputMaybe<Scalars['String']['input']>;
  tradeClass?: InputMaybe<Scalars['String']['input']>;
  contractor?: InputMaybe<Scalars['String']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  compliant?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GenerateLookerJobsiteWorkerReportMutation = { __typename?: 'Mutation' } & {
  generateLookerJobsiteWorkerReport: { __typename?: 'File' } & Pick<
    File,
    'fileId' | 'fileType' | 'uploadedAt' | 'downloadUrl' | 'originalFileName'
  >;
};

export type GetJobsiteWorkerDashboardQueryVariables = Exact<{
  jobsiteIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  startDate: Scalars['Date']['input'];
  endDate: Scalars['Date']['input'];
  timeBreakdown?: InputMaybe<GetJobsiteWorkerReportsTimeBreakdown>;
  realtime?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
  export?: InputMaybe<Scalars['Boolean']['input']>;
  trade?: InputMaybe<Scalars['String']['input']>;
  tradeClass?: InputMaybe<Scalars['String']['input']>;
  contractor?: InputMaybe<Scalars['String']['input']>;
  fitoutTenant?: InputMaybe<Scalars['String']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  compliant?: InputMaybe<Scalars['Boolean']['input']>;
  overnight?: InputMaybe<Scalars['Boolean']['input']>;
  workerIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;

export type GetJobsiteWorkerDashboardQuery = { __typename?: 'Query' } & {
  getJobsiteWorkerDashboard?: Maybe<
    { __typename?: 'GetJobsiteWorkerDashboardResponse' } & Pick<GetJobsiteWorkerDashboardResponse, 'totalCount'> & {
        jobsites: Array<{ __typename?: 'Jobsite' } & Pick<Jobsite, 'jobsiteId' | 'name'>>;
        contractorBreakdowns?: Maybe<
          Array<{ __typename?: 'ElasticFacetEntry' } & Pick<ElasticFacetEntry, 'name' | 'count' | 'percentage'>>
        >;
        tradeBreakdowns?: Maybe<
          Array<{ __typename?: 'ElasticFacetEntry' } & Pick<ElasticFacetEntry, 'name' | 'count' | 'percentage'>>
        >;
        tradeClassBreakdowns?: Maybe<
          Array<{ __typename?: 'ElasticFacetEntry' } & Pick<ElasticFacetEntry, 'name' | 'count' | 'percentage'>>
        >;
        activeBreakdowns?: Maybe<
          Array<{ __typename?: 'ElasticFacetEntry' } & Pick<ElasticFacetEntry, 'name' | 'count' | 'percentage'>>
        >;
        compliantBreakdowns?: Maybe<
          Array<{ __typename?: 'ElasticFacetEntry' } & Pick<ElasticFacetEntry, 'name' | 'count' | 'percentage'>>
        >;
        jobsiteBreakdowns?: Maybe<
          Array<{ __typename?: 'ElasticFacetEntry' } & Pick<ElasticFacetEntry, 'name' | 'count' | 'percentage'>>
        >;
        overviewBreakdowns?: Maybe<
          Array<
            { __typename?: 'ElasticFacetEntry' } & Pick<
              ElasticFacetEntry,
              'name' | 'count' | 'compliantCount' | 'percentage'
            >
          >
        >;
        dailyBreakdowns?: Maybe<
          Array<{ __typename?: 'ElasticFacetEntry' } & Pick<ElasticFacetEntry, 'name' | 'count'>>
        >;
        languageBreakdowns?: Maybe<
          Array<{ __typename?: 'ElasticFacetEntry' } & Pick<ElasticFacetEntry, 'name' | 'count' | 'percentage'>>
        >;
        genderBreakdowns?: Maybe<
          Array<{ __typename?: 'ElasticFacetEntry' } & Pick<ElasticFacetEntry, 'name' | 'count' | 'percentage'>>
        >;
        raceBreakdowns?: Maybe<
          Array<{ __typename?: 'ElasticFacetEntry' } & Pick<ElasticFacetEntry, 'name' | 'count' | 'percentage'>>
        >;
        zipcodeBreakdowns?: Maybe<
          Array<{ __typename?: 'ElasticFacetEntry' } & Pick<ElasticFacetEntry, 'name' | 'count' | 'percentage'>>
        >;
        ageBreakdowns?: Maybe<
          Array<{ __typename?: 'ElasticFacetEntry' } & Pick<ElasticFacetEntry, 'name' | 'count' | 'percentage'>>
        >;
        onboardedBreakdowns?: Maybe<
          Array<{ __typename?: 'ElasticFacetEntry' } & Pick<ElasticFacetEntry, 'name' | 'count' | 'percentage'>>
        >;
        bannedBreakdowns?: Maybe<
          Array<{ __typename?: 'ElasticFacetEntry' } & Pick<ElasticFacetEntry, 'name' | 'count' | 'percentage'>>
        >;
        results: Array<
          { __typename?: 'DashboardJobsiteWorkerSession' } & Pick<
            DashboardJobsiteWorkerSession,
            | 'swipeSessionsSessionId'
            | 'swipeSessionsJobsiteWorkerId'
            | 'contractorOrganizationName'
            | 'jobsiteName'
            | 'profile'
            | 'swipeSessionsFirstInboundSwipeTimeOfDay'
            | 'swipeSessionsLastOutboundSwipeTimeOfDay'
            | 'swipeSessionsNetTotalSpanHours'
            | 'swipeSessionsDisplayValueSpanHours'
            | 'swipeSessionsOvernightSession'
            | 'swipeSessionsSessionDate'
            | 'userIdentityUserFullname'
            | 'workerWorkerId'
            | 'workerJobTitle'
            | 'workerTrade'
            | 'workerUnionAffiliation'
            | 'workerQuickCode'
            | 'croppedDownloadKey'
            | 'publicUrl'
          >
        >;
      }
  >;
};

export type GetDeveloperContainerDeveloperInfoQueryVariables = Exact<{
  developerId: Scalars['ID']['input'];
  includeArchived: Scalars['Boolean']['input'];
}>;

export type GetDeveloperContainerDeveloperInfoQuery = { __typename?: 'Query' } & {
  getDeveloper: { __typename?: 'Developer' } & Pick<Developer, 'developerId'> & {
      organization: { __typename?: 'Organization' } & Pick<
        Organization,
        | 'organizationId'
        | 'name'
        | 'slug'
        | 'legalName'
        | 'email'
        | 'phoneNumber'
        | 'addressLine1'
        | 'addressLine2'
        | 'addressCity'
        | 'addressState'
        | 'addressZipCode'
      > & {
          objectHistory: { __typename?: 'ObjectHistory' } & Pick<ObjectHistory, 'createdAt' | 'updatedAt'> & {
              createdBy?: Maybe<
                { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
                    identity?: Maybe<{ __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName'>>;
                  }
              >;
              updatedBy?: Maybe<
                { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
                    identity?: Maybe<{ __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName'>>;
                  }
              >;
            };
        };
      jobsites: { __typename?: 'DeveloperJobsitesConnection' } & Pick<DeveloperJobsitesConnection, 'count'> & {
          edges: Array<
            { __typename?: 'DeveloperJobsitesEdge' } & {
              node: { __typename?: 'Jobsite' } & Pick<
                Jobsite,
                | 'name'
                | 'parentId'
                | 'jobsiteId'
                | 'addressLine1'
                | 'addressLine2'
                | 'addressCity'
                | 'addressState'
                | 'addressZipCode'
                | 'startDate'
                | 'endDate'
                | 'workerAccessEnabled'
                | 'avigilonEnabled'
              > & {
                  jobsiteWorkers: { __typename?: 'JobsiteJobsiteWorkersConnection' } & Pick<
                    JobsiteJobsiteWorkersConnection,
                    'count'
                  >;
                  featuresModule?: Maybe<
                    { __typename?: 'JobsiteFeaturesModule' } & Pick<
                      JobsiteFeaturesModule,
                      'announcementsEnabled' | 'archivingAccessEventsEnabled'
                    >
                  >;
                  developer?: Maybe<{ __typename?: 'Developer' } & Pick<Developer, 'developerId' | 'name'>>;
                  jobsiteCardFormats: { __typename: 'JobsiteCardFormatsConnection' } & Pick<
                    JobsiteCardFormatsConnection,
                    'count'
                  > & {
                      edges: Array<
                        { __typename?: 'JobsiteCardFormatsEdge' } & {
                          node: { __typename?: 'JobsiteCardFormat' } & Pick<
                            JobsiteCardFormat,
                            'jobsiteCardFormatId' | 'isDefaultForCardType'
                          > & {
                              workerCardFormat: { __typename?: 'WorkerCardFormat' } & Pick<
                                WorkerCardFormat,
                                'workerCardFormatId' | 'name' | 'description' | 'cardType' | 'facilityCode'
                              >;
                            };
                        }
                      >;
                    };
                  jobsiteMembers: { __typename?: 'JobsiteJobsiteMembersConnection' } & Pick<
                    JobsiteJobsiteMembersConnection,
                    'count'
                  >;
                };
            }
          >;
        };
      objectHistory: { __typename?: 'ObjectHistory' } & Pick<ObjectHistory, 'createdAt'> & {
          createdBy?: Maybe<
            { __typename?: 'User' } & {
              identity?: Maybe<{ __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName'>>;
            }
          >;
        };
    };
};

export type GetDeveloperWorkersContainerCurrentSessionQueryVariables = Exact<{
  userWorkersInput?: InputMaybe<UserWorkersInput>;
}>;

export type GetDeveloperWorkersContainerCurrentSessionQuery = { __typename?: 'Query' } & {
  getCurrentSession?: Maybe<
    { __typename?: 'Auth' } & {
      user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
          workers: { __typename?: 'UserWorkersConnection' } & Pick<UserWorkersConnection, 'count'> & {
              edges: Array<
                { __typename?: 'UserWorkersEdge' } & {
                  node: { __typename?: 'Worker' } & Pick<Worker, 'workerId' | 'birthDate' | 'quickCode' | 'trade'> & {
                      user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
                          identity?: Maybe<
                            { __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName' | 'email'>
                          >;
                        };
                      profilePictureCropped?: Maybe<
                        { __typename?: 'File' } & Pick<File, 'downloadUrl' | 'uploadedAt' | 'fileId' | 'fileType'>
                      >;
                      workerCards: { __typename?: 'WorkerWorkerCardsConnection' } & Pick<
                        WorkerWorkerCardsConnection,
                        'count'
                      > & {
                          edges: Array<
                            { __typename?: 'WorkerWorkerCardsEdge' } & {
                              node: { __typename?: 'WorkerCard' } & Pick<
                                WorkerCard,
                                'cardNumber' | 'cardStatus' | 'workerCardId'
                              >;
                            }
                          >;
                        };
                      jobsiteWorkers: { __typename?: 'WorkerJobsiteWorkersConnection' } & {
                        edges: Array<
                          { __typename?: 'WorkerJobsiteWorkersEdge' } & {
                            node: { __typename?: 'JobsiteWorker' } & Pick<JobsiteWorker, 'jobsiteWorkerId'> & {
                                jobsiteContractor: { __typename?: 'JobsiteContractor' } & {
                                  jobsite: { __typename?: 'Jobsite' } & Pick<Jobsite, 'jobsiteId' | 'name'>;
                                  contractor: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId'> & {
                                      organization: { __typename?: 'Organization' } & Pick<Organization, 'name'>;
                                    };
                                };
                                currentAccess: { __typename?: 'JobsiteWorkerCurrentAccess' } & Pick<
                                  JobsiteWorkerCurrentAccess,
                                  'isAllowed'
                                >;
                              };
                          }
                        >;
                      };
                    };
                }
              >;
            };
        };
    }
  >;
};

export type DeveloperCreateMutationVariables = Exact<{
  input: DeveloperCreateInput;
}>;

export type DeveloperCreateMutation = { __typename?: 'Mutation' } & {
  developerCreate: { __typename?: 'DeveloperCreatePayload' } & {
    developer: { __typename?: 'Developer' } & Pick<Developer, 'name' | 'developerId'> & {
        organization: { __typename?: 'Organization' } & Pick<Organization, 'name' | 'slug' | 'organizationId'>;
      };
  };
};

export type DeveloperUpdateMutationVariables = Exact<{
  input: DeveloperUpdateInput;
}>;

export type DeveloperUpdateMutation = { __typename?: 'Mutation' } & {
  developerUpdate: { __typename?: 'DeveloperUpdatePayload' } & {
    developer: { __typename?: 'Developer' } & Pick<Developer, 'name' | 'developerId'> & {
        organization: { __typename?: 'Organization' } & Pick<Organization, 'name' | 'slug' | 'organizationId'>;
      };
  };
};

export type GetDevelopersContainerCurrentSessionQueryVariables = Exact<{
  userDevelopersInput?: InputMaybe<UserDevelopersInput>;
}>;

export type GetDevelopersContainerCurrentSessionQuery = { __typename?: 'Query' } & {
  getCurrentSession?: Maybe<
    { __typename?: 'Auth' } & {
      user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
          developers: { __typename?: 'UserDevelopersConnection' } & Pick<UserDevelopersConnection, 'count'> & {
              edges: Array<
                { __typename?: 'UserDevelopersEdge' } & {
                  node: { __typename?: 'Developer' } & Pick<Developer, 'developerId'> & {
                      organization: { __typename?: 'Organization' } & Pick<
                        Organization,
                        'organizationId' | 'name' | 'addressState' | 'addressCity'
                      >;
                      jobsites: { __typename?: 'DeveloperJobsitesConnection' } & Pick<
                        DeveloperJobsitesConnection,
                        'count'
                      > & {
                          edges: Array<
                            { __typename?: 'DeveloperJobsitesEdge' } & {
                              node: { __typename?: 'Jobsite' } & Pick<
                                Jobsite,
                                'jobsiteId' | 'name' | 'startDate' | 'endDate'
                              > & {
                                  jobsiteWorkers: { __typename?: 'JobsiteJobsiteWorkersConnection' } & Pick<
                                    JobsiteJobsiteWorkersConnection,
                                    'count'
                                  >;
                                };
                            }
                          >;
                        };
                    };
                }
              >;
            };
        };
    }
  >;
};

export type GetChangeWorkerQueryVariables = Exact<{
  workerId: Scalars['ID']['input'];
}>;

export type GetChangeWorkerQuery = { __typename?: 'Query' } & {
  getWorker: { __typename?: 'Worker' } & Pick<Worker, 'workerId' | 'quickCode' | 'createdMethod' | 'isArchived'> & {
      objectHistory: { __typename?: 'ObjectHistory' } & Pick<ObjectHistory, 'createdAt' | 'updatedAt'> & {
          createdBy?: Maybe<
            { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
                identity?: Maybe<
                  { __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName' | 'email'>
                >;
              }
          >;
          updatedBy?: Maybe<
            { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
                identity?: Maybe<
                  { __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName' | 'email'>
                >;
              }
          >;
        };
      user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
          identity?: Maybe<
            { __typename?: 'UserIdentity' } & Pick<UserIdentity, 'id' | 'firstName' | 'lastName' | 'email'>
          >;
          jobsiteMembers: { __typename?: 'UserJobsiteMembersConnection' } & {
            edges: Array<
              { __typename?: 'UserJobsiteMembersEdge' } & {
                node: { __typename?: 'JobsiteMember' } & Pick<JobsiteMember, 'id' | 'title'> & {
                    jobsite: { __typename?: 'Jobsite' } & Pick<Jobsite, 'jobsiteId' | 'name' | 'timeZone'>;
                  };
              }
            >;
          };
          roles: Array<
            { __typename?: 'UserRole' } & Pick<UserRole, 'key' | 'mappingId' | 'displayName'> & {
                object?: Maybe<
                  { __typename?: 'UserRoleObject' } & Pick<UserRoleObject, 'objectId' | 'objectName'> & {
                      objectDetails?: Maybe<
                        { __typename?: 'UserRoleObjectDetails' } & Pick<
                          UserRoleObjectDetails,
                          'objectMemberId' | 'type' | 'name' | 'title'
                        >
                      >;
                    }
                >;
              }
          >;
        };
      jobsiteWorkers: { __typename?: 'WorkerJobsiteWorkersConnection' } & {
        edges: Array<
          { __typename?: 'WorkerJobsiteWorkersEdge' } & {
            node: { __typename?: 'JobsiteWorker' } & Pick<JobsiteWorker, 'jobsiteWorkerId'> & {
                jobsiteContractor: { __typename?: 'JobsiteContractor' } & {
                  jobsite: { __typename?: 'Jobsite' } & Pick<Jobsite, 'jobsiteId' | 'name' | 'timeZone'>;
                  contractor: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId'> & {
                      organization: { __typename?: 'Organization' } & Pick<Organization, 'organizationId' | 'name'>;
                    };
                };
                currentAccess: { __typename?: 'JobsiteWorkerCurrentAccess' } & Pick<
                  JobsiteWorkerCurrentAccess,
                  'id' | 'isAllowed' | 'notAllowedReason' | 'endDate'
                >;
              };
          }
        >;
      };
      documents: { __typename?: 'WorkerDocumentsConnection' } & {
        edges: Array<
          { __typename?: 'WorkerDocumentsEdge' } & {
            node: { __typename?: 'JobsiteWorkerDocument' } & Pick<JobsiteWorkerDocument, 'jobsiteWorkerDocumentId'> & {
                jobsiteWorkerDocumentType: { __typename?: 'JobsiteWorkerDocumentType' } & {
                  jobsite: { __typename?: 'Jobsite' } & Pick<Jobsite, 'name' | 'jobsiteId'> & {
                      developer?: Maybe<{ __typename?: 'Developer' } & Pick<Developer, 'name' | 'developerId'>>;
                    };
                  workerDocumentType: { __typename?: 'WorkerDocumentType' } & Pick<
                    WorkerDocumentType,
                    'name' | 'key' | 'expirationDate' | 'documentLevel' | 'isGlobal' | 'isPhi'
                  >;
                };
                latestVersion: { __typename?: 'JobsiteWorkerDocumentVersion' } & Pick<
                  JobsiteWorkerDocumentVersion,
                  'jobsiteWorkerDocumentVersionId'
                > & {
                    files: Array<{ __typename?: 'File' } & Pick<File, 'fileId' | 'fileType' | 'downloadUrl'>>;
                    additionalFieldValues?: Maybe<
                      Array<{ __typename?: 'AdditionalFieldValue' } & Pick<AdditionalFieldValue, 'key' | 'value'>>
                    >;
                  };
              };
          }
        >;
      };
      workerCards: { __typename?: 'WorkerWorkerCardsConnection' } & {
        edges: Array<
          { __typename?: 'WorkerWorkerCardsEdge' } & {
            node: { __typename?: 'WorkerCard' } & Pick<WorkerCard, 'workerCardId' | 'cardNumber'>;
          }
        >;
      };
    };
};

export type GetEntityChangesQueryVariables = Exact<{
  input?: InputMaybe<GetEntityChangesInput>;
}>;

export type GetEntityChangesQuery = { __typename?: 'Query' } & {
  getEntityChanges: { __typename?: 'GetEntityChangesResult' } & Pick<GetEntityChangesResult, 'count'> & {
      edges: Array<
        { __typename?: 'EntityChangesEdge' } & {
          node: { __typename?: 'EntityChange' } & Pick<
            EntityChange,
            'id' | 'tableName' | 'entityId' | 'entityDbId' | 'apiDbHandler' | 'values'
          > & {
              objectHistory: { __typename?: 'ObjectHistory' } & Pick<ObjectHistory, 'createdAt'> & {
                  createdBy?: Maybe<
                    { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
                        identity?: Maybe<
                          { __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName' | 'email'>
                        >;
                      }
                  >;
                };
            };
        }
      >;
    };
};

export type RegisterWorkerForFacialRecognitionBadgeMutationVariables = Exact<{
  workerId: Scalars['ID']['input'];
  workerCardId: Scalars['ID']['input'];
  newPhoneNumber?: InputMaybe<Scalars['String']['input']>;
}>;

export type RegisterWorkerForFacialRecognitionBadgeMutation = { __typename?: 'Mutation' } & {
  registerWorkerForFacialRecognitionBadge: { __typename?: 'WorkerCard' } & Pick<
    WorkerCard,
    'workerCardId' | 'cardStatus' | 'cardNumber'
  > & {
      workerCardFormat: { __typename?: 'WorkerCardFormat' } & Pick<
        WorkerCardFormat,
        'name' | 'cardType' | 'facilityCode'
      >;
    };
};

export type GenerateFormSubmissionsReportMutationVariables = Exact<{
  input: FormSubmissionsReportInput;
}>;

export type GenerateFormSubmissionsReportMutation = { __typename?: 'Mutation' } & {
  generateFormSubmissionsReport: { __typename?: 'File' } & Pick<
    File,
    'fileId' | 'fileType' | 'uploadedAt' | 'downloadUrl' | 'originalFileName'
  >;
};

export type JobsiteArchiveFormSubmissionMutationVariables = Exact<{
  input: JobsiteArchiveFormSubmissionInput;
}>;

export type JobsiteArchiveFormSubmissionMutation = { __typename?: 'Mutation' } & {
  jobsiteArchiveFormSubmission: { __typename?: 'JobsiteArchiveFormSubmissionPayload' } & {
    success: { __typename?: 'Success' } & Pick<Success, 'success'>;
  };
};

export type JobsiteCreateFormSubmissionMutationVariables = Exact<{
  input: JobsiteCreateFormSubmissionInput;
}>;

export type JobsiteCreateFormSubmissionMutation = { __typename?: 'Mutation' } & {
  jobsiteCreateFormSubmission: { __typename?: 'JobsiteCreateFormSubmissionPayload' } & {
    jobsiteFormSubmission: { __typename?: 'JobsiteFormSubmission' } & Pick<JobsiteFormSubmission, 'id'>;
  };
};

export type GetFormsQueryVariables = Exact<{
  formsInput?: InputMaybe<UserFormsInput>;
}>;

export type GetFormsQuery = { __typename?: 'Query' } & {
  getCurrentSession?: Maybe<
    { __typename?: 'Auth' } & {
      user: { __typename?: 'User' } & {
        forms: { __typename?: 'UserFormsConnection' } & {
          edges: Array<
            { __typename?: 'UserFormsEdge' } & {
              node: { __typename?: 'Form' } & Pick<Form, 'id' | 'key' | 'name' | 'content'>;
            }
          >;
        };
      };
    }
  >;
};

export type GetJobsiteFormSubmissionsQueryVariables = Exact<{
  jobsiteFormSubmissionsInput?: InputMaybe<UserJobsiteFormSubmissionsInput>;
}>;

export type GetJobsiteFormSubmissionsQuery = { __typename?: 'Query' } & {
  getCurrentSession?: Maybe<
    { __typename?: 'Auth' } & {
      user: { __typename?: 'User' } & {
        jobsiteFormSubmissions: { __typename?: 'UserJobsiteFormSubmissionsConnection' } & Pick<
          UserJobsiteFormSubmissionsConnection,
          'count'
        > & {
            edges: Array<
              { __typename?: 'UserJobsiteFormSubmissionsEdge' } & {
                node: { __typename?: 'JobsiteFormSubmission' } & Pick<
                  JobsiteFormSubmission,
                  'id' | 'startAt' | 'isStartTimeSpecified' | 'endAt' | 'timeZone' | 'extraData'
                > & {
                    objectHistory: { __typename?: 'ObjectHistory' } & Pick<ObjectHistory, 'createdAt'> & {
                        createdBy?: Maybe<
                          { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
                              identity?: Maybe<
                                { __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName'>
                              >;
                              worker?: Maybe<
                                { __typename?: 'Worker' } & Pick<Worker, 'workerId'> & {
                                    profilePictureCropped?: Maybe<{ __typename?: 'File' } & Pick<File, 'downloadUrl'>>;
                                  }
                              >;
                            }
                        >;
                      };
                    jobsiteForm: { __typename?: 'JobsiteForm' } & Pick<JobsiteForm, 'id'> & {
                        jobsite: { __typename?: 'Jobsite' } & Pick<Jobsite, 'jobsiteId' | 'name'>;
                        form: { __typename?: 'Form' } & Pick<Form, 'id' | 'key' | 'name'>;
                      };
                    jobsiteContractors: { __typename?: 'JobsiteFormSubmissionContractorsConnection' } & {
                      edges: Array<
                        { __typename?: 'JobsiteFormSubmissionContractorsEdge' } & {
                          node: { __typename?: 'JobsiteFormSubmissionContractor' } & Pick<
                            JobsiteFormSubmissionContractor,
                            'id' | 'associationType'
                          > & {
                              jobsiteContractor: { __typename?: 'JobsiteContractor' } & Pick<
                                JobsiteContractor,
                                'id'
                              > & {
                                  contractor: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId'> & {
                                      organization: { __typename?: 'Organization' } & Pick<
                                        Organization,
                                        'organizationId' | 'name'
                                      >;
                                    };
                                };
                            };
                        }
                      >;
                    };
                    jobsiteWorkers: { __typename?: 'JobsiteFormSubmissionWorkersConnection' } & Pick<
                      JobsiteFormSubmissionWorkersConnection,
                      'count'
                    > & {
                        edges: Array<
                          { __typename?: 'JobsiteFormSubmissionWorkersEdge' } & {
                            node: { __typename?: 'JobsiteFormSubmissionWorker' } & Pick<
                              JobsiteFormSubmissionWorker,
                              'id' | 'extraData' | 'associationType'
                            > & {
                                jobsiteWorker: { __typename?: 'JobsiteWorker' } & Pick<
                                  JobsiteWorker,
                                  'jobsiteWorkerId'
                                > & {
                                    contractorWorker: { __typename?: 'ContractorWorker' } & {
                                      worker: { __typename?: 'Worker' } & Pick<Worker, 'workerId'> & {
                                          user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
                                              identity?: Maybe<
                                                { __typename?: 'UserIdentity' } & Pick<
                                                  UserIdentity,
                                                  'firstName' | 'lastName'
                                                >
                                              >;
                                            };
                                          profilePictureCropped?: Maybe<
                                            { __typename?: 'File' } & Pick<File, 'downloadUrl'>
                                          >;
                                        };
                                    };
                                  };
                              };
                          }
                        >;
                      };
                  };
              }
            >;
            filtersOptions?: Maybe<
              { __typename?: 'UserJobsiteFormSubmissionsFilters' } & {
                forms: Array<{ __typename?: 'FilterOption' } & Pick<FilterOption, 'value' | 'label'>>;
                contractors: Array<{ __typename?: 'FilterOption' } & Pick<FilterOption, 'value' | 'label'>>;
                createdByUsers: Array<{ __typename?: 'FilterOption' } & Pick<FilterOption, 'value' | 'label'>>;
              }
            >;
          };
      };
    }
  >;
};

export type GetJobsiteFormsQueryVariables = Exact<{
  jobsiteFormsInput?: InputMaybe<UserJobsiteFormsInput>;
}>;

export type GetJobsiteFormsQuery = { __typename?: 'Query' } & {
  getCurrentSession?: Maybe<
    { __typename?: 'Auth' } & {
      user: { __typename?: 'User' } & {
        jobsiteForms: { __typename?: 'UserJobsiteFormsConnection' } & {
          edges: Array<
            { __typename?: 'UserJobsiteFormsEdge' } & {
              node: { __typename?: 'JobsiteForm' } & Pick<JobsiteForm, 'id'> & {
                  jobsite: { __typename?: 'Jobsite' } & Pick<Jobsite, 'jobsiteId' | 'name' | 'timeZone'>;
                };
            }
          >;
        };
      };
    }
  >;
};

export type GetCurrentSessionHomeContainerQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentSessionHomeContainerQuery = { __typename?: 'Query' } & {
  getCurrentSession?: Maybe<
    { __typename?: 'Auth' } & {
      user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
          jobsiteMembers: { __typename?: 'UserJobsiteMembersConnection' } & Pick<UserJobsiteMembersConnection, 'count'>;
          contractorMembers: { __typename?: 'UserContractorMembersConnection' } & Pick<
            UserContractorMembersConnection,
            'count'
          >;
        };
    }
  >;
};

export type UpdateContractorWorkerMutationVariables = Exact<{
  workerId: Scalars['ID']['input'];
  workerInput: WorkerInput;
  assignContractorWorkerToJobsiteInput: AssignContractorWorkerToJobsiteInput;
}>;

export type UpdateContractorWorkerMutation = { __typename?: 'Mutation' } & {
  updateWorker: { __typename?: 'Worker' } & Pick<Worker, 'birthDate' | 'ssnLastFour' | 'workerId'> & {
      user: { __typename?: 'User' } & Pick<User, 'userAccountId' | 'createdAt'> & {
          identity?: Maybe<
            { __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName' | 'createdAt' | 'updatedAt'>
          >;
          roles: Array<
            { __typename?: 'UserRole' } & Pick<
              UserRole,
              'key' | 'displayName' | 'roleCreatedAt' | 'roleUpdatedAt' | 'mappingCreatedAt'
            >
          >;
        };
    };
  assignContractorWorkerToJobsite: { __typename?: 'AssignContractorWorkerToJobsiteResponse' } & {
    jobsiteWorker: { __typename?: 'JobsiteWorker' } & Pick<
      JobsiteWorker,
      'jobsiteWorkerId' | 'orientationStatus' | 'startDate' | 'endDate'
    > & {
        contractorWorker: { __typename?: 'ContractorWorker' } & {
          worker: { __typename?: 'Worker' } & Pick<Worker, 'birthDate' | 'ssnLastFour' | 'workerId'> & {
              user: { __typename?: 'User' } & Pick<User, 'userAccountId' | 'createdAt'> & {
                  identity?: Maybe<
                    { __typename?: 'UserIdentity' } & Pick<
                      UserIdentity,
                      'email' | 'firstName' | 'lastName' | 'createdAt' | 'updatedAt'
                    >
                  >;
                  roles: Array<
                    { __typename?: 'UserRole' } & Pick<
                      UserRole,
                      'key' | 'displayName' | 'roleCreatedAt' | 'roleUpdatedAt' | 'mappingCreatedAt'
                    >
                  >;
                };
            };
        };
        jobsiteContractor: { __typename?: 'JobsiteContractor' } & {
          jobsite: { __typename?: 'Jobsite' } & Pick<Jobsite, 'description' | 'jobsiteId' | 'name'>;
        };
      };
  };
};

export type GetIssueTempBadgeJobsiteQueryVariables = Exact<{
  jobsiteId: Scalars['ID']['input'];
}>;

export type GetIssueTempBadgeJobsiteQuery = { __typename?: 'Query' } & {
  getJobsite: { __typename?: 'Jobsite' } & Pick<Jobsite, 'jobsiteId' | 'name'> & {
      modules?: Maybe<
        Array<
          | { __typename?: 'JobsiteAppCheckInModule' }
          | { __typename?: 'JobsiteFeaturesModule' }
          | ({ __typename?: 'JobsiteOnboardingModule' } & Pick<JobsiteOnboardingModule, 'name'> & {
                steps?: Maybe<
                  Array<
                    { __typename?: 'JobsiteOnboardingStep' } & Pick<
                      JobsiteOnboardingStep,
                      'key' | 'index' | 'isRequired' | 'isVisible'
                    > & {
                        fields?: Maybe<
                          Array<
                            { __typename?: 'JobsiteOnboardingField' } & Pick<
                              JobsiteOnboardingField,
                              'key' | 'name' | 'index' | 'isRequired' | 'isHidden'
                            >
                          >
                        >;
                      }
                  >
                >;
              })
          | { __typename?: 'JobsitePreOnboardingModule' }
        >
      >;
      jobsiteContractors: { __typename?: 'JobsiteJobsiteContractorsConnection' } & {
        edges: Array<
          { __typename?: 'JobsiteJobsiteContractorsEdge' } & {
            node: { __typename?: 'JobsiteContractor' } & {
              contractor: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId' | 'isDefaultContractor'> & {
                  organization: { __typename?: 'Organization' } & Pick<Organization, 'name'>;
                };
            };
          }
        >;
      };
    };
};

export type JobsiteAddContractorsMutationVariables = Exact<{
  input: JobsiteAddContractorsInput;
}>;

export type JobsiteAddContractorsMutation = { __typename?: 'Mutation' } & {
  jobsiteAddContractors: { __typename?: 'JobsiteAddContractorsPayload' } & {
    jobsiteContractors: Array<{ __typename?: 'JobsiteContractor' } & Pick<JobsiteContractor, 'id'>>;
  };
};

export type JobsiteRemoveContractorMutationVariables = Exact<{
  input: JobsiteRemoveContractorInput;
}>;

export type JobsiteRemoveContractorMutation = { __typename?: 'Mutation' } & {
  jobsiteRemoveContractor: { __typename?: 'JobsiteRemoveContractorPayload' } & {
    success: { __typename?: 'Success' } & Pick<Success, 'success'>;
  };
};

export type JobsiteUpdateContractorMutationVariables = Exact<{
  input: JobsiteUpdateContractorInput;
}>;

export type JobsiteUpdateContractorMutation = { __typename?: 'Mutation' } & {
  jobsiteUpdateContractor: { __typename?: 'JobsiteUpdateContractorPayload' } & {
    jobsiteContractor: { __typename?: 'JobsiteContractor' } & Pick<JobsiteContractor, 'id'>;
  };
};

export type GetContractorsAndJobsitesQueryVariables = Exact<{
  userContractorsInput: UserContractorsInput;
}>;

export type GetContractorsAndJobsitesQuery = { __typename?: 'Query' } & {
  getCurrentSession?: Maybe<
    { __typename?: 'Auth' } & {
      user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
          contractors: { __typename?: 'UserContractorsConnection' } & Pick<UserContractorsConnection, 'count'> & {
              edges: Array<
                { __typename?: 'UserContractorsEdge' } & {
                  node: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId'> & {
                      jobsiteContractors: { __typename?: 'ContractorJobsiteContractorsConnection' } & Pick<
                        ContractorJobsiteContractorsConnection,
                        'count'
                      > & {
                          edges: Array<
                            { __typename?: 'ContractorJobsiteContractorsEdge' } & {
                              node: { __typename?: 'JobsiteContractor' } & Pick<
                                JobsiteContractor,
                                'id' | 'startDate'
                              > & {
                                  contractor: { __typename?: 'Contractor' } & Pick<
                                    Contractor,
                                    'contractorId' | 'isDefaultContractor'
                                  > & {
                                      organization: { __typename?: 'Organization' } & Pick<
                                        Organization,
                                        'name' | 'slug' | 'legalName'
                                      >;
                                    };
                                };
                            }
                          >;
                        };
                    };
                }
              >;
            };
        };
    }
  >;
};

export type GetJobsiteContainerQueryVariables = Exact<{
  jobsiteId: Scalars['ID']['input'];
}>;

export type GetJobsiteContainerQuery = { __typename?: 'Query' } & {
  getJobsite: { __typename?: 'Jobsite' } & Pick<
    Jobsite,
    | 'name'
    | 'jobsiteId'
    | 'addressCity'
    | 'addressState'
    | 'addressLine1'
    | 'addressLine2'
    | 'addressZipCode'
    | 'startDate'
    | 'endDate'
  > & {
      jobsiteWorkers: { __typename?: 'JobsiteJobsiteWorkersConnection' } & Pick<
        JobsiteJobsiteWorkersConnection,
        'count'
      >;
      jobsiteMembers: { __typename?: 'JobsiteJobsiteMembersConnection' } & Pick<
        JobsiteJobsiteMembersConnection,
        'count'
      >;
      developer?: Maybe<
        { __typename?: 'Developer' } & { organization: { __typename?: 'Organization' } & Pick<Organization, 'name'> }
      >;
      jobsiteContractors: { __typename?: 'JobsiteJobsiteContractorsConnection' } & Pick<
        JobsiteJobsiteContractorsConnection,
        'count'
      > & {
          edges: Array<
            { __typename?: 'JobsiteJobsiteContractorsEdge' } & {
              node: { __typename?: 'JobsiteContractor' } & Pick<JobsiteContractor, 'id'> & {
                  contractor: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId'> & {
                      organization: { __typename?: 'Organization' } & Pick<Organization, 'name'>;
                    };
                };
            }
          >;
        };
    };
};

export type GetJobsiteDetailsQueryVariables = Exact<{
  jobsiteId: Scalars['ID']['input'];
}>;

export type GetJobsiteDetailsQuery = { __typename?: 'Query' } & {
  getJobsite: { __typename?: 'Jobsite' } & Pick<Jobsite, 'jobsiteId' | 'name' | 'clearanceAssignmentEnabled'> & {
      cCureClearances: { __typename?: 'JobsiteCCureClearancesConnection' } & {
        edges: Array<
          { __typename?: 'JobsiteCCureClearancesEdge' } & {
            node: { __typename?: 'CCureClearance' } & Pick<
              CCureClearance,
              'cCureClearanceId' | 'name' | 'cCureClearanceObjectId' | 'isDefault' | 'isSelectable'
            > & {
                cCureEnvironment: { __typename: 'CCureEnvironment' } & Pick<
                  CCureEnvironment,
                  'cCureEnvironmentId' | 'namespace' | 'partitionId' | 'instanceSlug'
                >;
              };
          }
        >;
      };
    };
};

export type GetJobsiteContainerContractorsQueryVariables = Exact<{
  jobsiteId: Scalars['ID']['input'];
  jobsiteJobsiteContractorsInput?: InputMaybe<JobsiteJobsiteContractorsInput>;
}>;

export type GetJobsiteContainerContractorsQuery = { __typename?: 'Query' } & {
  getJobsite: { __typename?: 'Jobsite' } & Pick<Jobsite, 'jobsiteId' | 'name' | 'startDate'> & {
      jobsiteContractors: { __typename?: 'JobsiteJobsiteContractorsConnection' } & Pick<
        JobsiteJobsiteContractorsConnection,
        'count'
      > & {
          edges: Array<
            { __typename?: 'JobsiteJobsiteContractorsEdge' } & {
              node: { __typename?: 'JobsiteContractor' } & Pick<JobsiteContractor, 'id' | 'startDate' | 'endDate'> & {
                  contractor: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId'> & {
                      organization: { __typename?: 'Organization' } & Pick<Organization, 'name'>;
                    };
                  jobsite: { __typename?: 'Jobsite' } & Pick<
                    Jobsite,
                    'name' | 'jobsiteId' | 'addressCity' | 'addressState' | 'startDate'
                  > & {
                      developer?: Maybe<
                        { __typename?: 'Developer' } & {
                          organization: { __typename?: 'Organization' } & Pick<Organization, 'name'>;
                        }
                      >;
                    };
                  parentJobsiteContractor?: Maybe<
                    { __typename?: 'JobsiteContractor' } & {
                      contractor: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId'> & {
                          organization: { __typename?: 'Organization' } & Pick<Organization, 'name'>;
                        };
                    }
                  >;
                  subcontractedJobsiteContractors: { __typename?: 'SubcontractedJobsiteContractorsConnection' } & Pick<
                    SubcontractedJobsiteContractorsConnection,
                    'count'
                  > & {
                      edges: Array<
                        { __typename?: 'SubcontractedJobsiteContractorEdge' } & {
                          node: { __typename?: 'JobsiteContractor' } & {
                            contractor: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId'> & {
                                organization: { __typename?: 'Organization' } & Pick<Organization, 'name'>;
                              };
                            jobsiteWorkers: { __typename?: 'JobsiteContractorJobsiteWorkersConnection' } & Pick<
                              JobsiteContractorJobsiteWorkersConnection,
                              'count'
                            >;
                          };
                        }
                      >;
                    };
                  contact?: Maybe<
                    { __typename?: 'ContactPerson' } & Pick<
                      ContactPerson,
                      'firstName' | 'lastName' | 'phoneNumber' | 'email' | 'title'
                    >
                  >;
                  jobsiteWorkers: { __typename?: 'JobsiteContractorJobsiteWorkersConnection' } & Pick<
                    JobsiteContractorJobsiteWorkersConnection,
                    'count'
                  >;
                };
            }
          >;
        };
    };
};

export type GetJobsiteContainerEquipmentTabQueryVariables = Exact<{
  jobsiteId: Scalars['ID']['input'];
  status?: InputMaybe<JobsiteEquipmentStatusType>;
}>;

export type GetJobsiteContainerEquipmentTabQuery = { __typename?: 'Query' } & {
  getJobsiteEquipmentData: { __typename?: 'GetJobsiteEquipmentDataResponse' } & Pick<
    GetJobsiteEquipmentDataResponse,
    'count'
  > & {
      results: Array<
        { __typename?: 'JobsiteEquipmentData' } & Pick<
          JobsiteEquipmentData,
          'instanceSlug' | 'status' | 'name' | 'description' | 'guid' | 'type' | 'lastOnlineDate' | 'lastOfflineDate'
        > & { jobsite: { __typename?: 'Jobsite' } & Pick<Jobsite, 'jobsiteId' | 'name'> }
      >;
    };
};

export type GetJobsiteContainerEquipmentHistoryTabQueryVariables = Exact<{
  jobsiteId: Scalars['ID']['input'];
  status?: InputMaybe<JobsiteEquipmentStatusType>;
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  toDate?: InputMaybe<Scalars['Date']['input']>;
  sortDir?: InputMaybe<OrderByDirection>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetJobsiteContainerEquipmentHistoryTabQuery = { __typename?: 'Query' } & {
  getJobsiteEquipmentHistory: { __typename?: 'GetJobsiteEquipmentHistoryResponse' } & Pick<
    GetJobsiteEquipmentHistoryResponse,
    'count'
  > & {
      results: Array<
        { __typename?: 'JobsiteEquipmentEvent' } & Pick<
          JobsiteEquipmentEvent,
          | 'instanceSlug'
          | 'deviceGuid'
          | 'deviceType'
          | 'deviceStatus'
          | 'deviceName'
          | 'deviceDescription'
          | 'jobsiteId'
          | 'eventDate'
        >
      >;
    };
};

export type GetJobsiteContainerJobsiteInfoQueryVariables = Exact<{
  jobsiteId: Scalars['ID']['input'];
}>;

export type GetJobsiteContainerJobsiteInfoQuery = { __typename?: 'Query' } & {
  getJobsite: { __typename?: 'Jobsite' } & Pick<
    Jobsite,
    | 'name'
    | 'jobsiteId'
    | 'addressCity'
    | 'addressState'
    | 'addressLine1'
    | 'addressLine2'
    | 'addressZipCode'
    | 'startDate'
    | 'endDate'
  > & {
      developer?: Maybe<
        { __typename?: 'Developer' } & {
          organization: { __typename?: 'Organization' } & Pick<
            Organization,
            | 'organizationId'
            | 'name'
            | 'legalName'
            | 'email'
            | 'addressLine1'
            | 'addressLine2'
            | 'addressCity'
            | 'addressState'
            | 'addressZipCode'
          >;
        }
      >;
      defaultJobsiteContractor?: Maybe<
        { __typename?: 'JobsiteContractor' } & Pick<JobsiteContractor, 'id'> & {
            contractor: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId'> & {
                organization: { __typename?: 'Organization' } & Pick<Organization, 'organizationId' | 'name'>;
              };
            jobsiteInvitation?: Maybe<
              { __typename?: 'JobsiteInvitation' } & Pick<JobsiteInvitation, 'jobsiteInvitationId'>
            >;
          }
      >;
      objectHistory: { __typename?: 'ObjectHistory' } & Pick<ObjectHistory, 'createdAt'> & {
          createdBy?: Maybe<
            { __typename?: 'User' } & {
              identity?: Maybe<{ __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName'>>;
            }
          >;
        };
    };
};

export type GetJobsiteContainerMembersQueryVariables = Exact<{
  jobsiteId: Scalars['ID']['input'];
  jobsiteJobsiteMembersInput?: InputMaybe<JobsiteJobsiteMembersInput>;
  userRolesInput?: InputMaybe<UserRolesInput>;
}>;

export type GetJobsiteContainerMembersQuery = { __typename?: 'Query' } & {
  getJobsite: { __typename?: 'Jobsite' } & Pick<Jobsite, 'jobsiteId'> & {
      jobsiteMembers: { __typename?: 'JobsiteJobsiteMembersConnection' } & Pick<
        JobsiteJobsiteMembersConnection,
        'count'
      > & {
          edges: Array<
            { __typename?: 'JobsiteJobsiteMembersEdge' } & {
              node: { __typename?: 'JobsiteMember' } & Pick<JobsiteMember, 'id'> & {
                  user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
                      roles: Array<
                        { __typename?: 'UserRole' } & Pick<UserRole, 'key' | 'displayName'> & {
                            object?: Maybe<
                              { __typename?: 'UserRoleObject' } & Pick<UserRoleObject, 'objectId'> & {
                                  objectDetails?: Maybe<
                                    { __typename?: 'UserRoleObjectDetails' } & Pick<UserRoleObjectDetails, 'name'>
                                  >;
                                }
                            >;
                          }
                      >;
                      identity?: Maybe<
                        { __typename?: 'UserIdentity' } & Pick<
                          UserIdentity,
                          'firstName' | 'lastName' | 'email' | 'phoneNumber'
                        >
                      >;
                      worker?: Maybe<
                        { __typename?: 'Worker' } & Pick<Worker, 'workerId'> & {
                            profilePictureCropped?: Maybe<{ __typename?: 'File' } & Pick<File, 'downloadUrl'>>;
                          }
                      >;
                      lastSession?: Maybe<
                        { __typename?: 'UserSession' } & Pick<UserSession, 'createdAt' | 'appVersion'>
                      >;
                    };
                };
            }
          >;
        };
    };
};

export type GetJobsiteContainerWorkersQueryVariables = Exact<{
  jobsiteId: Scalars['ID']['input'];
  jobsiteJobsiteWorkersInput?: InputMaybe<JobsiteJobsiteWorkersInput>;
}>;

export type GetJobsiteContainerWorkersQuery = { __typename?: 'Query' } & {
  getJobsite: { __typename?: 'Jobsite' } & Pick<Jobsite, 'jobsiteId'> & {
      jobsiteWorkers: { __typename?: 'JobsiteJobsiteWorkersConnection' } & Pick<
        JobsiteJobsiteWorkersConnection,
        'count'
      > & {
          edges: Array<
            { __typename?: 'JobsiteJobsiteWorkersEdge' } & {
              node: { __typename?: 'JobsiteWorker' } & Pick<JobsiteWorker, 'jobsiteWorkerId'> & {
                  currentAccess: { __typename?: 'JobsiteWorkerCurrentAccess' } & Pick<
                    JobsiteWorkerCurrentAccess,
                    'isAllowed' | 'notAllowedReason' | 'startDate' | 'endDate'
                  >;
                  contractorWorker: { __typename?: 'ContractorWorker' } & {
                    worker: { __typename?: 'Worker' } & Pick<
                      Worker,
                      'workerId' | 'birthDate' | 'quickCode' | 'trade'
                    > & {
                        user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
                            identity?: Maybe<
                              { __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName' | 'email'>
                            >;
                          };
                        profilePicture?: Maybe<
                          { __typename?: 'File' } & Pick<File, 'downloadUrl' | 'uploadedAt' | 'fileId'>
                        >;
                        profilePictureCropped?: Maybe<
                          { __typename?: 'File' } & Pick<File, 'downloadUrl' | 'uploadedAt' | 'fileId' | 'fileType'>
                        >;
                        workerCards: { __typename?: 'WorkerWorkerCardsConnection' } & {
                          edges: Array<
                            { __typename?: 'WorkerWorkerCardsEdge' } & {
                              node: { __typename?: 'WorkerCard' } & Pick<
                                WorkerCard,
                                'workerCardId' | 'cardNumber' | 'cardStatus'
                              >;
                            }
                          >;
                        };
                        jobsiteWorkers: { __typename?: 'WorkerJobsiteWorkersConnection' } & Pick<
                          WorkerJobsiteWorkersConnection,
                          'count'
                        > & {
                            edges: Array<
                              { __typename?: 'WorkerJobsiteWorkersEdge' } & {
                                node: { __typename?: 'JobsiteWorker' } & Pick<JobsiteWorker, 'jobsiteWorkerId'> & {
                                    jobsiteContractor: { __typename?: 'JobsiteContractor' } & {
                                      jobsite: { __typename?: 'Jobsite' } & Pick<Jobsite, 'jobsiteId' | 'name'>;
                                      contractor: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId'> & {
                                          organization: { __typename?: 'Organization' } & Pick<Organization, 'name'>;
                                        };
                                    };
                                    currentAccess: { __typename?: 'JobsiteWorkerCurrentAccess' } & Pick<
                                      JobsiteWorkerCurrentAccess,
                                      'isAllowed' | 'notAllowedReason' | 'startDate' | 'endDate'
                                    >;
                                  };
                              }
                            >;
                          };
                      };
                  };
                };
            }
          >;
        };
    };
};

export type GetJobsiteAccessActivityCurrentSessionQueryVariables = Exact<{
  userJobsiteAccessEventsInput?: InputMaybe<UserJobsiteAccessEventsInput>;
}>;

export type GetJobsiteAccessActivityCurrentSessionQuery = { __typename?: 'Query' } & {
  getCurrentSession?: Maybe<
    { __typename?: 'Auth' } & {
      user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
          jobsiteAccessEvents?: Maybe<
            { __typename?: 'UserJobsiteAccessEventsConnection' } & Pick<UserJobsiteAccessEventsConnection, 'count'> & {
                edges: Array<
                  { __typename?: 'UserJobsiteAccessEventsEdge' } & {
                    node: { __typename?: 'JobsiteAccessEvent' } & Pick<
                      JobsiteAccessEvent,
                      | 'id'
                      | 'type'
                      | 'status'
                      | 'credentialId'
                      | 'eventOccurredAt'
                      | 'localizedDate'
                      | 'localizedTime'
                      | 'timeZone'
                      | 'rejectionReason'
                      | 'message'
                      | 'gateway'
                      | 'overnightSession'
                      | 'direction'
                      | 'externalId'
                      | 'eventType'
                    > & {
                        jobsite?: Maybe<{ __typename?: 'Jobsite' } & Pick<Jobsite, 'jobsiteId' | 'name'>>;
                        jobsiteWorker?: Maybe<
                          { __typename?: 'JobsiteWorker' } & Pick<JobsiteWorker, 'jobsiteWorkerId'> & {
                              jobsiteContractor: { __typename?: 'JobsiteContractor' } & Pick<
                                JobsiteContractor,
                                'id'
                              > & {
                                  contractor: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId'> & {
                                      organization: { __typename?: 'Organization' } & Pick<
                                        Organization,
                                        'organizationId' | 'name'
                                      >;
                                    };
                                };
                              currentAccess: { __typename?: 'JobsiteWorkerCurrentAccess' } & Pick<
                                JobsiteWorkerCurrentAccess,
                                'isAllowed'
                              >;
                            }
                        >;
                        worker?: Maybe<
                          { __typename?: 'Worker' } & Pick<Worker, 'workerId'> & {
                              user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
                                  identity?: Maybe<
                                    { __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName'>
                                  >;
                                };
                              profilePictureCropped?: Maybe<{ __typename?: 'File' } & Pick<File, 'downloadUrl'>>;
                            }
                        >;
                      };
                  }
                >;
                filtersOptions?: Maybe<
                  { __typename?: 'UserJobsiteAccessEventsFilters' } & {
                    jobsites: Array<{ __typename?: 'FilterOption' } & Pick<FilterOption, 'value' | 'label' | 'count'>>;
                    contractors: Array<
                      { __typename?: 'FilterOption' } & Pick<FilterOption, 'value' | 'label' | 'count'>
                    >;
                    gateways: Array<{ __typename?: 'FilterOption' } & Pick<FilterOption, 'value' | 'label' | 'count'>>;
                    rejectionReasons: Array<
                      Maybe<{ __typename?: 'FilterOption' } & Pick<FilterOption, 'value' | 'label' | 'count'>>
                    >;
                    locationStatuses: Array<
                      Maybe<{ __typename?: 'FilterOption' } & Pick<FilterOption, 'value' | 'label' | 'count'>>
                    >;
                  }
                >;
              }
          >;
        };
    }
  >;
};

export type ArchiveJobsiteMutationVariables = Exact<{
  jobsiteId: Scalars['ID']['input'];
}>;

export type ArchiveJobsiteMutation = { __typename?: 'Mutation' } & {
  archiveJobsite: { __typename?: 'Success' } & Pick<Success, 'success'>;
};

export type JobsiteUpdateMutationVariables = Exact<{
  input: JobsiteUpdateInput;
}>;

export type JobsiteUpdateMutation = { __typename?: 'Mutation' } & {
  jobsiteUpdate: { __typename?: 'JobsiteUpdatePayload' } & {
    jobsite: { __typename?: 'Jobsite' } & Pick<Jobsite, 'jobsiteId'>;
  };
};

export type GetJobsiteConfigurationQueryVariables = Exact<{
  jobsiteId: Scalars['ID']['input'];
  documentTypesInput?: InputMaybe<JobsiteWorkerDocumentTypesInput>;
  userFormsInput?: InputMaybe<UserFormsInput>;
}>;

export type GetJobsiteConfigurationQuery = { __typename?: 'Query' } & {
  getJobsite: { __typename?: 'Jobsite' } & Pick<
    Jobsite,
    | 'jobsiteId'
    | 'name'
    | 'parentId'
    | 'isTemplate'
    | 'templateId'
    | 'addressLine1'
    | 'addressLine2'
    | 'addressCity'
    | 'addressState'
    | 'addressZipCode'
    | 'timeZone'
    | 'latitude'
    | 'longitude'
    | 'startDate'
    | 'endDate'
    | 'onboardingGracePeriod'
    | 'swipeInactivityThreshold'
    | 'maximumSessionLength'
    | 'allowOvernightSessions'
    | 'sessionDisplayColumn'
    | 'accessChangeEmails'
    | 'escortChangeEmails'
    | 'deviceStatusChangeEmails'
    | 'assignmentChangeEmails'
    | 'rejectedSwipeEmails'
    | 'dailyAccessReportEmails'
    | 'weeklyAccessReportEmails'
    | 'reportingTimeSlot'
    | 'hidPrinterId'
    | 'fargoLocationId'
    | 'fargoOrganizationId'
    | 'hidProxyInstance'
    | 'hidCardMappingUnion'
    | 'hidCardMappingNonUnion'
    | 'hidCardMappingTest'
    | 'procoreCompanyId'
    | 'procoreProjectId'
    | 'avigilonEnabled'
  > & {
      developer?: Maybe<{ __typename?: 'Developer' } & Pick<Developer, 'developerId'>>;
      cCureClearances: { __typename?: 'JobsiteCCureClearancesConnection' } & {
        edges: Array<
          { __typename?: 'JobsiteCCureClearancesEdge' } & {
            node: { __typename?: 'CCureClearance' } & Pick<
              CCureClearance,
              'cCureClearanceId' | 'name' | 'cCureClearanceObjectId' | 'isDefault' | 'isSelectable'
            > & {
                cCureEnvironment: { __typename?: 'CCureEnvironment' } & Pick<
                  CCureEnvironment,
                  'cCureEnvironmentId' | 'namespace'
                >;
              };
          }
        >;
      };
      cCureEntityMappings: { __typename?: 'JobsiteCCureEntityMappingsConnection' } & {
        edges: Array<
          { __typename?: 'JobsiteCCureEntityMappingsEdge' } & {
            node: { __typename?: 'CCureEntityMapping' } & Pick<CCureEntityMapping, 'objectId' | 'entityType'> & {
                cCureEnvironment: { __typename?: 'CCureEnvironment' } & Pick<
                  CCureEnvironment,
                  'cCureEnvironmentId' | 'namespace'
                >;
              };
          }
        >;
      };
      jobsiteCardFormats: { __typename?: 'JobsiteCardFormatsConnection' } & {
        edges: Array<
          { __typename?: 'JobsiteCardFormatsEdge' } & {
            node: { __typename?: 'JobsiteCardFormat' } & Pick<
              JobsiteCardFormat,
              'jobsiteCardFormatId' | 'isDefaultForCardType'
            > & {
                workerCardFormat: { __typename?: 'WorkerCardFormat' } & Pick<WorkerCardFormat, 'workerCardFormatId'>;
              };
          }
        >;
      };
      jobsiteBadgeTemplates: { __typename?: 'JobsiteBadgeTemplatesConnection' } & {
        edges: Array<
          { __typename?: 'JobsiteBadgeTemplatesEdge' } & {
            node: { __typename?: 'JobsiteBadgeTemplate' } & Pick<
              JobsiteBadgeTemplate,
              'id' | 'jobsiteId' | 'isDefault'
            > & {
                badgeTemplate: { __typename?: 'BadgeTemplate' } & Pick<
                  BadgeTemplate,
                  'id' | 'templateName' | 'displayName'
                >;
              };
          }
        >;
      };
      documentTypes: { __typename?: 'JobsiteWorkerDocumentTypesConnection' } & {
        edges: Array<
          { __typename?: 'JobsiteWorkerDocumentTypesEdge' } & {
            node: { __typename?: 'JobsiteWorkerDocumentType' } & Pick<JobsiteWorkerDocumentType, 'id'> & {
                workerDocumentType: { __typename?: 'WorkerDocumentType' } & Pick<
                  WorkerDocumentType,
                  'workerDocumentTypeId' | 'key'
                >;
                files: Array<
                  { __typename?: 'File' } & Pick<File, 'fileId' | 'fileType' | 'downloadUrl' | 'language' | 'title'>
                >;
              };
          }
        >;
      };
      jobsiteForms: { __typename?: 'JobsiteFormsConnection' } & {
        edges: Array<
          { __typename?: 'JobsiteFormsEdge' } & {
            node: { __typename?: 'JobsiteForm' } & Pick<JobsiteForm, 'id'> & {
                form: { __typename?: 'Form' } & Pick<Form, 'id' | 'key' | 'name'>;
              };
          }
        >;
      };
      modules?: Maybe<
        Array<
          | ({ __typename?: 'JobsiteAppCheckInModule' } & Pick<
              JobsiteAppCheckInModule,
              'geofencingEnforcementType' | 'crewGeofencingEnforcementType' | 'isEnabled' | 'name' | 'slug'
            > & {
                areas: Array<
                  { __typename?: 'JobsiteAppCheckInModuleArea' } & Pick<
                    JobsiteAppCheckInModuleArea,
                    'id' | 'name' | 'checkInRadius' | 'isInboundEnabled' | 'isOutboundEnabled'
                  > & { checkInCoordinate: { __typename?: 'GPSCoordinate' } & Pick<GpsCoordinate, 'lat' | 'lng'> }
                >;
              })
          | ({ __typename?: 'JobsiteFeaturesModule' } & Pick<
              JobsiteFeaturesModule,
              | 'announcementsEnabled'
              | 'archivingAccessEventsEnabled'
              | 'formsEnabled'
              | 'addWorkersToDailyLog'
              | 'doNotAllowToSkipSelfOnboardingSteps'
              | 'enforceValidContractorInSelfOnboarding'
              | 'workerAppEnabled'
              | 'workerAppSelfCheckInEnabled'
              | 'workerAppAdminCheckInEnabled'
              | 'workerAppBadgeScanningEnabled'
              | 'workerAppKioskCheckInEnabled'
              | 'selfOnboardingAutoCompleteEnabled'
              | 'workerAppKioskFrontCameraEnabled'
              | 'isEnabled'
              | 'name'
              | 'slug'
            >)
          | ({ __typename?: 'JobsiteOnboardingModule' } & Pick<
              JobsiteOnboardingModule,
              'name' | 'badgePrintingAllowed' | 'badgeAssignmentAllowed'
            > & {
                steps?: Maybe<
                  Array<
                    { __typename?: 'JobsiteOnboardingStep' } & Pick<
                      JobsiteOnboardingStep,
                      'key' | 'index' | 'isRequired' | 'isVisible'
                    > & {
                        fields?: Maybe<
                          Array<
                            { __typename?: 'JobsiteOnboardingField' } & Pick<
                              JobsiteOnboardingField,
                              'key' | 'name' | 'index' | 'isRequired' | 'isHidden'
                            >
                          >
                        >;
                      }
                  >
                >;
                documents?: Maybe<
                  Array<
                    { __typename?: 'JobsiteOnboardingDocument' } & Pick<
                      JobsiteOnboardingDocument,
                      'key' | 'name' | 'index' | 'isRequired' | 'isVisible' | 'expiration' | 'gracePeriod'
                    > & {
                        fields?: Maybe<
                          Array<
                            { __typename?: 'JobsiteOnboardingField' } & Pick<
                              JobsiteOnboardingField,
                              'key' | 'name' | 'index' | 'isRequired' | 'isHidden'
                            >
                          >
                        >;
                      }
                  >
                >;
              })
          | { __typename?: 'JobsitePreOnboardingModule' }
        >
      >;
    };
  getCurrentSession?: Maybe<
    { __typename?: 'Auth' } & {
      user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
          developers: { __typename?: 'UserDevelopersConnection' } & {
            edges: Array<
              { __typename?: 'UserDevelopersEdge' } & {
                node: { __typename?: 'Developer' } & Pick<Developer, 'developerId'> & {
                    organization: { __typename?: 'Organization' } & Pick<Organization, 'organizationId' | 'name'>;
                  };
              }
            >;
          };
          jobsites: { __typename?: 'UserJobsitesConnection' } & {
            edges: Array<
              { __typename?: 'UserJobsitesEdge' } & {
                node: { __typename?: 'Jobsite' } & Pick<Jobsite, 'jobsiteId' | 'name' | 'parentId'>;
              }
            >;
          };
          workerCardFormats: { __typename?: 'UserWorkerCardFormatsConnection' } & {
            edges: Array<
              { __typename?: 'UserWorkerCardFormatsEdge' } & {
                node: { __typename?: 'WorkerCardFormat' } & Pick<
                  WorkerCardFormat,
                  'workerCardFormatId' | 'name' | 'facilityCode' | 'cardType'
                >;
              }
            >;
          };
          cCureEnvironments: { __typename?: 'UserCCureEnvironmentsConnection' } & {
            edges: Array<
              { __typename?: 'UserCCureEnvironmentsEdge' } & {
                node: { __typename?: 'CCureEnvironment' } & Pick<CCureEnvironment, 'cCureEnvironmentId' | 'namespace'>;
              }
            >;
          };
          cCureEntityMappingOptions: { __typename?: 'UserCCureEntityMappingOptionsConnection' } & {
            edges?: Maybe<
              Array<
                { __typename?: 'UserCCureEntityMappingOptionsEdge' } & {
                  node: { __typename?: 'CCureEntityMapping' } & Pick<
                    CCureEntityMapping,
                    'entityType' | 'objectId' | 'name'
                  > & {
                      cCureEnvironment: { __typename?: 'CCureEnvironment' } & Pick<
                        CCureEnvironment,
                        'namespace' | 'cCureEnvironmentId' | 'instanceSlug'
                      >;
                    };
                }
              >
            >;
          };
          hidEntityOptions: { __typename?: 'UserHidEntityOptionsConnection' } & {
            edges?: Maybe<
              Array<
                { __typename?: 'UserHidEntityOptionsEdge' } & {
                  node: { __typename?: 'HidEntityOption' } & Pick<
                    HidEntityOption,
                    | 'id'
                    | 'name'
                    | 'organizationId'
                    | 'organizationName'
                    | 'locationId'
                    | 'locationName'
                    | 'entityType'
                    | 'proxyInstance'
                  >;
                }
              >
            >;
          };
          badgeTemplates: { __typename?: 'UserBadgeTemplatesConnection' } & {
            edges?: Maybe<
              Array<
                { __typename?: 'UserBadgeTemplatesEdge' } & {
                  node: { __typename?: 'BadgeTemplate' } & Pick<BadgeTemplate, 'id' | 'templateName' | 'displayName'>;
                }
              >
            >;
          };
          workerDocumentTypes: { __typename?: 'UserWorkerDocumentTypesConnection' } & {
            edges: Array<
              { __typename?: 'UserWorkerDocumentTypesEdge' } & {
                node: { __typename?: 'WorkerDocumentType' } & Pick<
                  WorkerDocumentType,
                  'workerDocumentTypeId' | 'name' | 'key' | 'isGlobal' | 'isPhi' | 'documentLevel'
                >;
              }
            >;
          };
          forms: { __typename?: 'UserFormsConnection' } & {
            edges: Array<
              { __typename?: 'UserFormsEdge' } & { node: { __typename?: 'Form' } & Pick<Form, 'id' | 'key' | 'name'> }
            >;
          };
        };
    }
  >;
};

export type GetAddJobsiteContractorWorkerJobsiteQueryVariables = Exact<{
  jobsiteId: Scalars['ID']['input'];
}>;

export type GetAddJobsiteContractorWorkerJobsiteQuery = { __typename?: 'Query' } & {
  getJobsite: { __typename?: 'Jobsite' } & Pick<Jobsite, 'jobsiteId' | 'name'> & {
      modules?: Maybe<
        Array<
          | { __typename?: 'JobsiteAppCheckInModule' }
          | { __typename?: 'JobsiteFeaturesModule' }
          | ({ __typename?: 'JobsiteOnboardingModule' } & Pick<JobsiteOnboardingModule, 'name'> & {
                steps?: Maybe<
                  Array<
                    { __typename?: 'JobsiteOnboardingStep' } & Pick<
                      JobsiteOnboardingStep,
                      'key' | 'index' | 'isRequired' | 'isVisible'
                    > & {
                        fields?: Maybe<
                          Array<
                            { __typename?: 'JobsiteOnboardingField' } & Pick<
                              JobsiteOnboardingField,
                              'key' | 'name' | 'index' | 'isRequired' | 'isHidden'
                            >
                          >
                        >;
                      }
                  >
                >;
              })
          | { __typename?: 'JobsitePreOnboardingModule' }
        >
      >;
    };
};

export type GetAvailableJobsiteWorkersQueryVariables = Exact<{
  jobsiteId: Scalars['ID']['input'];
  jobsiteJobsiteWorkersInput?: InputMaybe<JobsiteJobsiteWorkersInput>;
}>;

export type GetAvailableJobsiteWorkersQuery = { __typename?: 'Query' } & {
  getJobsite: { __typename?: 'Jobsite' } & Pick<Jobsite, 'jobsiteId'> & {
      jobsiteWorkers: { __typename?: 'JobsiteJobsiteWorkersConnection' } & Pick<
        JobsiteJobsiteWorkersConnection,
        'count'
      > & {
          edges: Array<
            { __typename?: 'JobsiteJobsiteWorkersEdge' } & {
              node: { __typename?: 'JobsiteWorker' } & Pick<
                JobsiteWorker,
                'jobsiteWorkerId' | 'stickerNumber' | 'isOnSite'
              > & {
                  currentAccess: { __typename?: 'JobsiteWorkerCurrentAccess' } & Pick<
                    JobsiteWorkerCurrentAccess,
                    'isAllowed' | 'notAllowedReason' | 'startDate' | 'endDate'
                  >;
                  contractorWorker: { __typename?: 'ContractorWorker' } & Pick<ContractorWorker, 'id'> & {
                      contractor: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId'> & {
                          organization: { __typename?: 'Organization' } & Pick<Organization, 'organizationId' | 'name'>;
                        };
                      worker: { __typename?: 'Worker' } & Pick<
                        Worker,
                        'workerId' | 'birthDate' | 'quickCode' | 'trade' | 'jobTitle'
                      > & {
                          user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
                              identity?: Maybe<
                                { __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName' | 'email'>
                              >;
                            };
                          profilePicture?: Maybe<{ __typename?: 'File' } & Pick<File, 'downloadUrl'>>;
                        };
                    };
                };
            }
          >;
        };
    };
};

export type GetFormSubmissionAvailableWorkersQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  availableWorkersInput: JobsiteFormSubmissionAvailableWorkersInput;
}>;

export type GetFormSubmissionAvailableWorkersQuery = { __typename?: 'Query' } & {
  getJobsiteFormSubmission: { __typename?: 'JobsiteFormSubmission' } & Pick<JobsiteFormSubmission, 'id'> & {
      jobsiteForm: { __typename?: 'JobsiteForm' } & Pick<JobsiteForm, 'id'> & {
          jobsite: { __typename?: 'Jobsite' } & Pick<Jobsite, 'jobsiteId' | 'name'>;
        };
      availableWorkers: { __typename?: 'JobsiteFormSubmissionAvailableWorkersConnection' } & {
        edges: Array<
          { __typename?: 'JobsiteFormSubmissionAvailableWorkerEdge' } & {
            node: { __typename?: 'Worker' } & Pick<
              Worker,
              'workerId' | 'birthDate' | 'quickCode' | 'trade' | 'jobTitle' | 'unionAffiliation'
            > & {
                user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
                    identity?: Maybe<
                      { __typename?: 'UserIdentity' } & Pick<
                        UserIdentity,
                        'firstName' | 'lastName' | 'email' | 'phoneNumber'
                      >
                    >;
                  };
                profilePicture?: Maybe<{ __typename?: 'File' } & Pick<File, 'downloadUrl' | 'uploadedAt' | 'fileId'>>;
                profilePictureCropped?: Maybe<
                  { __typename?: 'File' } & Pick<File, 'downloadUrl' | 'uploadedAt' | 'fileId' | 'fileType'>
                >;
                jobsiteWorkers: { __typename?: 'WorkerJobsiteWorkersConnection' } & {
                  edges: Array<
                    { __typename?: 'WorkerJobsiteWorkersEdge' } & {
                      node: { __typename?: 'JobsiteWorker' } & Pick<
                        JobsiteWorker,
                        'jobsiteWorkerId' | 'stickerNumber'
                      > & {
                          currentAccess: { __typename?: 'JobsiteWorkerCurrentAccess' } & Pick<
                            JobsiteWorkerCurrentAccess,
                            'isAllowed' | 'notAllowedReason' | 'startDate' | 'endDate'
                          >;
                          jobsiteContractor: { __typename?: 'JobsiteContractor' } & Pick<JobsiteContractor, 'id'> & {
                              jobsite: { __typename?: 'Jobsite' } & Pick<Jobsite, 'jobsiteId' | 'name'>;
                              contractor: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId'> & {
                                  organization: { __typename?: 'Organization' } & Pick<
                                    Organization,
                                    'organizationId' | 'name'
                                  >;
                                };
                            };
                        };
                    }
                  >;
                };
              };
          }
        >;
      };
    };
};

export type GetFormSubmissionJobsiteContractorsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetFormSubmissionJobsiteContractorsQuery = { __typename?: 'Query' } & {
  getJobsiteFormSubmission: { __typename?: 'JobsiteFormSubmission' } & Pick<JobsiteFormSubmission, 'id'> & {
      jobsiteContractors: { __typename?: 'JobsiteFormSubmissionContractorsConnection' } & {
        edges: Array<
          { __typename?: 'JobsiteFormSubmissionContractorsEdge' } & {
            node: { __typename?: 'JobsiteFormSubmissionContractor' } & Pick<
              JobsiteFormSubmissionContractor,
              'id' | 'extraData' | 'associationType'
            > & {
                jobsiteContractor: { __typename?: 'JobsiteContractor' } & Pick<JobsiteContractor, 'id'> & {
                    contractor: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId'> & {
                        organization: { __typename?: 'Organization' } & Pick<Organization, 'organizationId' | 'name'>;
                      };
                  };
              };
          }
        >;
      };
    };
};

export type GetFormSubmissionJobsiteWorkersQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  jfsInput?: InputMaybe<JobsiteFormSubmissionWorkersInput>;
}>;

export type GetFormSubmissionJobsiteWorkersQuery = { __typename?: 'Query' } & {
  getJobsiteFormSubmission: { __typename?: 'JobsiteFormSubmission' } & Pick<JobsiteFormSubmission, 'id'> & {
      jobsiteForm: { __typename?: 'JobsiteForm' } & Pick<JobsiteForm, 'id'> & {
          jobsite: { __typename?: 'Jobsite' } & Pick<Jobsite, 'jobsiteId' | 'name'>;
        };
      jobsiteWorkers: { __typename?: 'JobsiteFormSubmissionWorkersConnection' } & {
        edges: Array<
          { __typename?: 'JobsiteFormSubmissionWorkersEdge' } & {
            node: { __typename?: 'JobsiteFormSubmissionWorker' } & Pick<
              JobsiteFormSubmissionWorker,
              'id' | 'extraData' | 'associationType'
            > & {
                historicalData: { __typename?: 'JobsiteFormSubmissionWorkerHistoricalData' } & {
                  jobsiteWorkerAccess?: Maybe<
                    { __typename?: 'JobsiteWorkerAccess' } & Pick<JobsiteWorkerAccess, 'isAllowedNow'>
                  >;
                };
                jobsiteWorker: { __typename?: 'JobsiteWorker' } & Pick<
                  JobsiteWorker,
                  'jobsiteWorkerId' | 'stickerNumber' | 'isOnSite'
                > & {
                    currentAccess: { __typename?: 'JobsiteWorkerCurrentAccess' } & Pick<
                      JobsiteWorkerCurrentAccess,
                      'isAllowed' | 'notAllowedReason' | 'startDate' | 'endDate'
                    >;
                    jobsiteContractor: { __typename?: 'JobsiteContractor' } & Pick<JobsiteContractor, 'id'> & {
                        jobsite: { __typename?: 'Jobsite' } & Pick<Jobsite, 'jobsiteId' | 'name'>;
                        contractor: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId'> & {
                            organization: { __typename?: 'Organization' } & Pick<
                              Organization,
                              'organizationId' | 'name'
                            >;
                          };
                      };
                    contractorWorker: { __typename?: 'ContractorWorker' } & Pick<ContractorWorker, 'id'> & {
                        worker: { __typename?: 'Worker' } & Pick<
                          Worker,
                          'workerId' | 'birthDate' | 'quickCode' | 'trade' | 'jobTitle' | 'unionAffiliation'
                        > & {
                            user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
                                identity?: Maybe<
                                  { __typename?: 'UserIdentity' } & Pick<
                                    UserIdentity,
                                    'firstName' | 'lastName' | 'email' | 'phoneNumber'
                                  >
                                >;
                              };
                            profilePicture?: Maybe<{ __typename?: 'File' } & Pick<File, 'downloadUrl'>>;
                          };
                      };
                  };
              };
          }
        >;
      };
    };
};

export type GetJobsiteWorkerDashboardReportQueryVariables = Exact<{
  jobsiteIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  startDate: Scalars['Date']['input'];
  endDate: Scalars['Date']['input'];
  timeBreakdown?: InputMaybe<GetJobsiteWorkerReportsTimeBreakdown>;
  realtime?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
  export?: InputMaybe<Scalars['Boolean']['input']>;
  trade?: InputMaybe<Scalars['String']['input']>;
  tradeClass?: InputMaybe<Scalars['String']['input']>;
  contractor?: InputMaybe<Scalars['String']['input']>;
  fitoutTenant?: InputMaybe<Scalars['String']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  compliant?: InputMaybe<Scalars['Boolean']['input']>;
  workerIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;

export type GetJobsiteWorkerDashboardReportQuery = { __typename?: 'Query' } & {
  getJobsiteWorkerDashboard?: Maybe<
    { __typename?: 'GetJobsiteWorkerDashboardResponse' } & {
      jobsites: Array<{ __typename?: 'Jobsite' } & Pick<Jobsite, 'jobsiteId' | 'name'>>;
      overviewBreakdowns?: Maybe<
        Array<
          { __typename?: 'ElasticFacetEntry' } & Pick<
            ElasticFacetEntry,
            'name' | 'count' | 'compliantCount' | 'percentage'
          >
        >
      >;
    }
  >;
};

export type GetJobsiteFormSubmissionQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetJobsiteFormSubmissionQuery = { __typename?: 'Query' } & {
  getJobsiteFormSubmission: { __typename?: 'JobsiteFormSubmission' } & Pick<
    JobsiteFormSubmission,
    'id' | 'startAt' | 'isStartTimeSpecified' | 'endAt' | 'timeZone' | 'extraData'
  > & {
      weatherConditions?: Maybe<
        Array<
          { __typename?: 'WeatherConditions' } & Pick<
            WeatherConditions,
            'timestamp' | 'description' | 'icon' | 'temp' | 'windSpeed' | 'windDirectionCardinal'
          >
        >
      >;
      jobsiteForm: { __typename?: 'JobsiteForm' } & Pick<JobsiteForm, 'id'> & {
          form: { __typename?: 'Form' } & Pick<Form, 'id' | 'key' | 'content'>;
          jobsite: { __typename?: 'Jobsite' } & Pick<Jobsite, 'jobsiteId' | 'name'> & {
              modules?: Maybe<
                Array<
                  | { __typename?: 'JobsiteAppCheckInModule' }
                  | ({ __typename?: 'JobsiteFeaturesModule' } & Pick<
                      JobsiteFeaturesModule,
                      'formsEnabled' | 'addWorkersToDailyLog' | 'isEnabled' | 'name' | 'slug'
                    >)
                  | { __typename?: 'JobsiteOnboardingModule' }
                  | { __typename?: 'JobsitePreOnboardingModule' }
                >
              >;
            };
        };
      jobsiteContractors: { __typename?: 'JobsiteFormSubmissionContractorsConnection' } & {
        edges: Array<
          { __typename?: 'JobsiteFormSubmissionContractorsEdge' } & {
            node: { __typename?: 'JobsiteFormSubmissionContractor' } & Pick<
              JobsiteFormSubmissionContractor,
              'id' | 'extraData' | 'associationType'
            > & {
                jobsiteContractor: { __typename?: 'JobsiteContractor' } & Pick<JobsiteContractor, 'id'> & {
                    contractor: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId'> & {
                        organization: { __typename?: 'Organization' } & Pick<Organization, 'organizationId' | 'name'>;
                      };
                  };
              };
          }
        >;
      };
      jobsiteWorkers: { __typename?: 'JobsiteFormSubmissionWorkersConnection' } & Pick<
        JobsiteFormSubmissionWorkersConnection,
        'count'
      > & {
          edges: Array<
            { __typename?: 'JobsiteFormSubmissionWorkersEdge' } & {
              node: { __typename?: 'JobsiteFormSubmissionWorker' } & Pick<
                JobsiteFormSubmissionWorker,
                'id' | 'extraData' | 'associationType'
              > & { jobsiteWorker: { __typename?: 'JobsiteWorker' } & Pick<JobsiteWorker, 'jobsiteWorkerId'> };
            }
          >;
        };
      documents: { __typename?: 'JobsiteFormSubmissionDocumentsConnection' } & Pick<
        JobsiteFormSubmissionDocumentsConnection,
        'count'
      > & {
          edges: Array<
            { __typename?: 'JobsiteFormSubmissionDocumentsEdge' } & {
              node: { __typename?: 'JobsiteFormSubmissionDocument' } & Pick<
                JobsiteFormSubmissionDocument,
                'id' | 'description'
              > & {
                  files: Array<
                    { __typename?: 'File' } & Pick<File, 'fileId' | 'fileType' | 'downloadUrl' | 'originalFileName'>
                  >;
                  objectHistory: { __typename?: 'ObjectHistory' } & Pick<ObjectHistory, 'createdAt'> & {
                      createdBy?: Maybe<
                        { __typename?: 'User' } & {
                          identity?: Maybe<
                            { __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName'>
                          >;
                        }
                      >;
                    };
                };
            }
          >;
        };
    };
};

export type GetJobsiteFormSubmissionHistoricalDataQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetJobsiteFormSubmissionHistoricalDataQuery = { __typename?: 'Query' } & {
  getJobsiteFormSubmission: { __typename?: 'JobsiteFormSubmission' } & Pick<JobsiteFormSubmission, 'id'> & {
      jobsiteWorkers: { __typename?: 'JobsiteFormSubmissionWorkersConnection' } & Pick<
        JobsiteFormSubmissionWorkersConnection,
        'count'
      > & {
          edges: Array<
            { __typename?: 'JobsiteFormSubmissionWorkersEdge' } & {
              node: { __typename?: 'JobsiteFormSubmissionWorker' } & Pick<
                JobsiteFormSubmissionWorker,
                'id' | 'extraData'
              > & {
                  objectHistory: { __typename?: 'ObjectHistory' } & Pick<ObjectHistory, 'createdAt'>;
                  historicalData: { __typename?: 'JobsiteFormSubmissionWorkerHistoricalData' } & {
                    jobsiteWorkerAccess?: Maybe<
                      { __typename?: 'JobsiteWorkerAccess' } & Pick<
                        JobsiteWorkerAccess,
                        | 'isAllowedNow'
                        | 'notAllowedReason'
                        | 'jobsiteIsDisabledAccess'
                        | 'jobsiteAccessReadmissionDate'
                        | 'endDate'
                        | 'isAntipassbackExempt'
                        | 'siteSpecificTrainingCompletedAt'
                        | 'siteSpecificTrainingExpiredAt'
                        | 'siteSpecificTrainingSkipReason'
                        | 'safetyDocumentsCompletedAt'
                        | 'safetyDocumentsExpiredAt'
                        | 'safetyDocumentsSkipReason'
                        | 'orientationMedicalCompletedAt'
                        | 'orientationMedicalExpiredAt'
                        | 'orientationMedicalSkipReason'
                        | 'nycSstExemptionReason'
                        | 'safetyDocumentStatus'
                      > & {
                          jobsiteWorker: { __typename?: 'JobsiteWorker' } & Pick<
                            JobsiteWorker,
                            | 'jobsiteWorkerId'
                            | 'isBanned'
                            | 'bannedReason'
                            | 'bannedAt'
                            | 'banRemovedReason'
                            | 'startDate'
                            | 'endDate'
                            | 'payrollId'
                            | 'siteSpecificTrainingCompletedAt'
                            | 'stickerNumber'
                            | 'orientationDate'
                            | 'orientationStatus'
                            | 'documentsCompletedSkipExpiresAt'
                            | 'urineTestConditionalPassExpiresAt'
                            | 'banExpiresAt'
                            | 'badgingCompletedAt'
                            | 'medicalNotes'
                            | 'nycSstExemptionReason'
                            | 'siteAccessChangeApprovedBy'
                            | 'isExemptFromSwipeInactivity'
                            | 'isExemptFromSiteSpecificRefresher'
                          > & {
                              jobsiteContractor: { __typename?: 'JobsiteContractor' } & {
                                jobsite: { __typename?: 'Jobsite' } & Pick<
                                  Jobsite,
                                  'name' | 'jobsiteId' | 'parentId'
                                > & {
                                    developer?: Maybe<
                                      { __typename?: 'Developer' } & Pick<Developer, 'developerId' | 'name'>
                                    >;
                                  };
                              };
                              contractorWorker: { __typename?: 'ContractorWorker' } & {
                                worker: { __typename?: 'Worker' } & Pick<Worker, 'workerId' | 'trade' | 'jobTitle'> & {
                                    documents: { __typename?: 'WorkerDocumentsConnection' } & {
                                      edges: Array<
                                        { __typename?: 'WorkerDocumentsEdge' } & {
                                          node: { __typename?: 'JobsiteWorkerDocument' } & Pick<
                                            JobsiteWorkerDocument,
                                            'jobsiteWorkerDocumentId'
                                          > & {
                                              jobsiteWorkerDocumentType: {
                                                __typename?: 'JobsiteWorkerDocumentType';
                                              } & Pick<JobsiteWorkerDocumentType, 'expiration'> & {
                                                  jobsite: { __typename?: 'Jobsite' } & Pick<
                                                    Jobsite,
                                                    'name' | 'jobsiteId'
                                                  > & {
                                                      developer?: Maybe<
                                                        { __typename?: 'Developer' } & Pick<
                                                          Developer,
                                                          'name' | 'developerId'
                                                        >
                                                      >;
                                                    };
                                                  workerDocumentType: { __typename?: 'WorkerDocumentType' } & Pick<
                                                    WorkerDocumentType,
                                                    'name' | 'key' | 'expirationDate' | 'isGlobal' | 'documentLevel'
                                                  >;
                                                };
                                              jobsiteWorker?: Maybe<
                                                { __typename?: 'JobsiteWorker' } & Pick<
                                                  JobsiteWorker,
                                                  'jobsiteWorkerId'
                                                > & {
                                                    jobsiteContractor: { __typename?: 'JobsiteContractor' } & {
                                                      jobsite: { __typename?: 'Jobsite' } & Pick<Jobsite, 'jobsiteId'>;
                                                    };
                                                  }
                                              >;
                                              latestVersion: { __typename?: 'JobsiteWorkerDocumentVersion' } & Pick<
                                                JobsiteWorkerDocumentVersion,
                                                'jobsiteWorkerDocumentVersionId'
                                              > & {
                                                  files: Array<
                                                    { __typename?: 'File' } & Pick<
                                                      File,
                                                      'fileId' | 'fileType' | 'downloadUrl'
                                                    >
                                                  >;
                                                  additionalFieldValues?: Maybe<
                                                    Array<
                                                      { __typename?: 'AdditionalFieldValue' } & Pick<
                                                        AdditionalFieldValue,
                                                        'key' | 'value'
                                                      >
                                                    >
                                                  >;
                                                  objectHistory: { __typename?: 'ObjectHistory' } & Pick<
                                                    ObjectHistory,
                                                    'createdAt' | 'updatedAt'
                                                  > & {
                                                      createdBy?: Maybe<
                                                        { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
                                                            identity?: Maybe<
                                                              { __typename?: 'UserIdentity' } & Pick<
                                                                UserIdentity,
                                                                'firstName' | 'lastName' | 'email'
                                                              >
                                                            >;
                                                          }
                                                      >;
                                                      updatedBy?: Maybe<
                                                        { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
                                                            identity?: Maybe<
                                                              { __typename?: 'UserIdentity' } & Pick<
                                                                UserIdentity,
                                                                'firstName' | 'lastName' | 'email'
                                                              >
                                                            >;
                                                          }
                                                      >;
                                                    };
                                                };
                                            };
                                        }
                                      >;
                                    };
                                    jobsiteAccessEvents?: Maybe<
                                      { __typename?: 'WorkerJobsiteAccessEventsConnection' } & {
                                        edges: Array<
                                          { __typename?: 'WorkerJobsiteAccessEventsEdge' } & {
                                            node: { __typename?: 'JobsiteAccessEvent' } & Pick<
                                              JobsiteAccessEvent,
                                              | 'id'
                                              | 'status'
                                              | 'timeStamp'
                                              | 'eventOccurredAt'
                                              | 'timeZone'
                                              | 'overnightSession'
                                              | 'gateway'
                                              | 'direction'
                                              | 'eventType'
                                              | 'credentialId'
                                            >;
                                          }
                                        >;
                                      }
                                    >;
                                    objectHistory: { __typename?: 'ObjectHistory' } & Pick<
                                      ObjectHistory,
                                      'createdAt' | 'updatedAt'
                                    > & {
                                        createdBy?: Maybe<
                                          { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
                                              identity?: Maybe<
                                                { __typename?: 'UserIdentity' } & Pick<
                                                  UserIdentity,
                                                  'firstName' | 'lastName' | 'email'
                                                >
                                              >;
                                            }
                                        >;
                                        updatedBy?: Maybe<
                                          { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
                                              identity?: Maybe<
                                                { __typename?: 'UserIdentity' } & Pick<
                                                  UserIdentity,
                                                  'firstName' | 'lastName' | 'email'
                                                >
                                              >;
                                            }
                                        >;
                                      };
                                  };
                                contractor: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId'> & {
                                    organization: { __typename?: 'Organization' } & Pick<
                                      Organization,
                                      'organizationId' | 'name'
                                    >;
                                  };
                              };
                              objectHistory: { __typename?: 'ObjectHistory' } & Pick<
                                ObjectHistory,
                                'createdAt' | 'updatedAt'
                              > & {
                                  createdBy?: Maybe<
                                    { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
                                        identity?: Maybe<
                                          { __typename?: 'UserIdentity' } & Pick<
                                            UserIdentity,
                                            'firstName' | 'lastName' | 'email'
                                          >
                                        >;
                                      }
                                  >;
                                  updatedBy?: Maybe<
                                    { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
                                        identity?: Maybe<
                                          { __typename?: 'UserIdentity' } & Pick<
                                            UserIdentity,
                                            'firstName' | 'lastName' | 'email'
                                          >
                                        >;
                                      }
                                  >;
                                };
                            };
                        }
                    >;
                  };
                };
            }
          >;
        };
    };
};

export type GetJobsiteFormSubmissionReportQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetJobsiteFormSubmissionReportQuery = { __typename?: 'Query' } & {
  getJobsiteFormSubmission: { __typename?: 'JobsiteFormSubmission' } & Pick<
    JobsiteFormSubmission,
    'id' | 'startAt' | 'isStartTimeSpecified' | 'endAt' | 'timeZone' | 'extraData'
  > & {
      weatherConditions?: Maybe<
        Array<
          { __typename?: 'WeatherConditions' } & Pick<
            WeatherConditions,
            'timestamp' | 'description' | 'icon' | 'temp' | 'windSpeed' | 'windDirectionCardinal'
          >
        >
      >;
      jobsiteForm: { __typename?: 'JobsiteForm' } & Pick<JobsiteForm, 'id'> & {
          form: { __typename?: 'Form' } & Pick<Form, 'id' | 'key' | 'name' | 'content'>;
          jobsite: { __typename?: 'Jobsite' } & Pick<
            Jobsite,
            'jobsiteId' | 'name' | 'addressLine1' | 'addressLine2' | 'addressCity' | 'addressState' | 'addressZipCode'
          >;
        };
      jobsiteContractors: { __typename?: 'JobsiteFormSubmissionContractorsConnection' } & {
        edges: Array<
          { __typename?: 'JobsiteFormSubmissionContractorsEdge' } & {
            node: { __typename?: 'JobsiteFormSubmissionContractor' } & Pick<
              JobsiteFormSubmissionContractor,
              'id' | 'extraData' | 'associationType'
            > & {
                jobsiteContractor: { __typename?: 'JobsiteContractor' } & Pick<JobsiteContractor, 'id'> & {
                    contractor: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId'> & {
                        organization: { __typename?: 'Organization' } & Pick<
                          Organization,
                          | 'organizationId'
                          | 'name'
                          | 'addressLine1'
                          | 'addressLine2'
                          | 'addressCity'
                          | 'addressState'
                          | 'addressZipCode'
                        >;
                      };
                  };
              };
          }
        >;
      };
      jobsiteWorkers: { __typename?: 'JobsiteFormSubmissionWorkersConnection' } & Pick<
        JobsiteFormSubmissionWorkersConnection,
        'count'
      > & {
          edges: Array<
            { __typename?: 'JobsiteFormSubmissionWorkersEdge' } & {
              node: { __typename?: 'JobsiteFormSubmissionWorker' } & Pick<
                JobsiteFormSubmissionWorker,
                'id' | 'extraData' | 'associationType'
              > & {
                  jobsiteWorker: { __typename?: 'JobsiteWorker' } & Pick<JobsiteWorker, 'jobsiteWorkerId'> & {
                      contractorWorker: { __typename?: 'ContractorWorker' } & Pick<ContractorWorker, 'id'> & {
                          contractor: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId'> & {
                              organization: { __typename?: 'Organization' } & Pick<
                                Organization,
                                'organizationId' | 'name'
                              >;
                            };
                          worker: { __typename?: 'Worker' } & Pick<
                            Worker,
                            'workerId' | 'birthDate' | 'quickCode' | 'trade' | 'jobTitle'
                          > & {
                              user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
                                  identity?: Maybe<
                                    { __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName'>
                                  >;
                                };
                              profilePictureCropped?: Maybe<{ __typename?: 'File' } & Pick<File, 'downloadUrl'>>;
                            };
                        };
                    };
                };
            }
          >;
        };
      documents: { __typename?: 'JobsiteFormSubmissionDocumentsConnection' } & Pick<
        JobsiteFormSubmissionDocumentsConnection,
        'count'
      > & {
          edges: Array<
            { __typename?: 'JobsiteFormSubmissionDocumentsEdge' } & {
              node: { __typename?: 'JobsiteFormSubmissionDocument' } & Pick<
                JobsiteFormSubmissionDocument,
                'id' | 'description'
              > & {
                  files: Array<
                    { __typename?: 'File' } & Pick<File, 'fileId' | 'fileType' | 'downloadUrl' | 'originalFileName'>
                  >;
                  objectHistory: { __typename?: 'ObjectHistory' } & Pick<ObjectHistory, 'createdAt'> & {
                      createdBy?: Maybe<
                        { __typename?: 'User' } & {
                          identity?: Maybe<
                            { __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName'>
                          >;
                        }
                      >;
                    };
                };
            }
          >;
        };
      objectHistory: { __typename?: 'ObjectHistory' } & Pick<ObjectHistory, 'createdAt' | 'updatedAt'> & {
          createdBy?: Maybe<
            { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
                identity?: Maybe<
                  { __typename?: 'UserIdentity' } & Pick<
                    UserIdentity,
                    'firstName' | 'lastName' | 'phoneNumber' | 'email'
                  >
                >;
                worker?: Maybe<
                  { __typename?: 'Worker' } & Pick<Worker, 'workerId' | 'trade' | 'jobTitle'> & {
                      profilePictureCropped?: Maybe<{ __typename?: 'File' } & Pick<File, 'downloadUrl'>>;
                    }
                >;
              }
          >;
          updatedBy?: Maybe<
            { __typename?: 'User' } & {
              identity?: Maybe<{ __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName'>>;
              worker?: Maybe<
                { __typename?: 'Worker' } & Pick<Worker, 'workerId'> & {
                    profilePictureCropped?: Maybe<{ __typename?: 'File' } & Pick<File, 'downloadUrl'>>;
                  }
              >;
            }
          >;
        };
    };
};

export type FormUpdateMutationVariables = Exact<{
  input: FormUpdateInput;
}>;

export type FormUpdateMutation = { __typename?: 'Mutation' } & {
  formUpdate: { __typename?: 'FormUpdatePayload' } & { form: { __typename?: 'Form' } & Pick<Form, 'id'> };
};

export type JobsiteUpdateFormSubmissionMutationVariables = Exact<{
  input: JobsiteUpdateFormSubmissionInput;
}>;

export type JobsiteUpdateFormSubmissionMutation = { __typename?: 'Mutation' } & {
  jobsiteUpdateFormSubmission: { __typename?: 'JobsiteUpdateFormSubmissionPayload' } & {
    jobsiteFormSubmission: { __typename?: 'JobsiteFormSubmission' } & Pick<JobsiteFormSubmission, 'id'>;
  };
};

export type JobsiteCloseOutMutationVariables = Exact<{
  input: JobsiteCloseOutInput;
}>;

export type JobsiteCloseOutMutation = { __typename?: 'Mutation' } & {
  jobsiteCloseOut: { __typename?: 'JobsiteCloseOutPayload' } & {
    jobsite: { __typename?: 'Jobsite' } & Pick<Jobsite, 'jobsiteId'>;
  };
};

export type JobsiteCreateMutationVariables = Exact<{
  input: JobsiteCreateInput;
}>;

export type JobsiteCreateMutation = { __typename?: 'Mutation' } & {
  jobsiteCreate: { __typename?: 'JobsiteCreatePayload' } & {
    jobsite: { __typename?: 'Jobsite' } & Pick<Jobsite, 'jobsiteId'>;
  };
};

export type GetJobsiteTemplatesQueryVariables = Exact<{ [key: string]: never }>;

export type GetJobsiteTemplatesQuery = { __typename?: 'Query' } & {
  getCurrentSession?: Maybe<
    { __typename?: 'Auth' } & {
      user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
          jobsites: { __typename?: 'UserJobsitesConnection' } & {
            edges: Array<
              { __typename?: 'UserJobsitesEdge' } & {
                node: { __typename?: 'Jobsite' } & Pick<Jobsite, 'jobsiteId' | 'name'> & {
                    developer?: Maybe<{ __typename?: 'Developer' } & Pick<Developer, 'developerId'>>;
                  };
              }
            >;
          };
        };
    }
  >;
};

export type GetJobsitesContainerCurrentSessionQueryVariables = Exact<{
  userJobsitesInput?: InputMaybe<UserJobsitesInput>;
}>;

export type GetJobsitesContainerCurrentSessionQuery = { __typename?: 'Query' } & {
  getCurrentSession?: Maybe<
    { __typename?: 'Auth' } & {
      user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
          jobsites: { __typename?: 'UserJobsitesConnection' } & Pick<
            UserJobsitesConnection,
            'count' | 'templatesCount'
          > & {
              edges: Array<
                { __typename?: 'UserJobsitesEdge' } & {
                  node: { __typename?: 'Jobsite' } & Pick<
                    Jobsite,
                    'jobsiteId' | 'name' | 'addressCity' | 'addressState' | 'startDate' | 'endDate'
                  > & {
                      developer?: Maybe<
                        { __typename?: 'Developer' } & Pick<Developer, 'developerId'> & {
                            organization: { __typename?: 'Organization' } & Pick<
                              Organization,
                              'organizationId' | 'name'
                            >;
                          }
                      >;
                    };
                }
              >;
              filtersOptions?: Maybe<
                { __typename?: 'UserJobsitesFilters' } & {
                  developers: Array<{ __typename?: 'FilterOption' } & Pick<FilterOption, 'value' | 'label' | 'count'>>;
                  cities: Array<{ __typename?: 'FilterOption' } & Pick<FilterOption, 'value' | 'label' | 'count'>>;
                  states: Array<{ __typename?: 'FilterOption' } & Pick<FilterOption, 'value' | 'label' | 'count'>>;
                }
              >;
            };
        };
    }
  >;
};

export type AuthenticateLimitedTokenDetailsMutationVariables = Exact<{
  input: AuthenticateLimitedTokenDetailsInput;
}>;

export type AuthenticateLimitedTokenDetailsMutation = { __typename?: 'Mutation' } & {
  authenticateLimitedTokenDetails: { __typename?: 'AuthenticateLimitedTokenDetailsResponse' } & Pick<
    AuthenticateLimitedTokenDetailsResponse,
    'token'
  >;
};

export type GetRecentUserSearchQueriesQueryVariables = Exact<{
  searchQueryType?: InputMaybe<SearchQueryType>;
  count?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetRecentUserSearchQueriesQuery = { __typename?: 'Query' } & {
  getRecentUserSearchQueries: { __typename?: 'UserSearchQueryResponse' } & {
    results: Array<
      { __typename?: 'UserSearchQuery' } & Pick<UserSearchQuery, 'searchQuery' | 'searchQueryType' | 'createdAt'>
    >;
  };
};

export type SaveUserSearchQueryMutationVariables = Exact<{
  input: UserSearchQueryInput;
}>;

export type SaveUserSearchQueryMutation = { __typename?: 'Mutation' } & {
  saveUserSearchQuery: { __typename?: 'Success' } & Pick<Success, 'success'>;
};

export type DeleteOrganizationMutationVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;

export type DeleteOrganizationMutation = { __typename?: 'Mutation' } & {
  organizationDelete: { __typename?: 'OrganizationDeletePayload' } & {
    success: { __typename?: 'Success' } & Pick<Success, 'success'>;
  };
};

export type GetOrganizationContainerOrganizationInfoQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
  includeArchived: Scalars['Boolean']['input'];
}>;

export type GetOrganizationContainerOrganizationInfoQuery = { __typename?: 'Query' } & {
  getOrganization: { __typename?: 'Organization' } & Pick<
    Organization,
    | 'organizationId'
    | 'name'
    | 'slug'
    | 'legalName'
    | 'email'
    | 'phoneNumber'
    | 'addressLine1'
    | 'addressLine2'
    | 'addressCity'
    | 'addressState'
    | 'addressZipCode'
  > & {
      objectHistory: { __typename?: 'ObjectHistory' } & Pick<ObjectHistory, 'createdAt' | 'updatedAt'> & {
          createdBy?: Maybe<
            { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
                identity?: Maybe<{ __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName'>>;
              }
          >;
          updatedBy?: Maybe<
            { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
                identity?: Maybe<{ __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName'>>;
              }
          >;
        };
      developers?: Maybe<
        { __typename: 'OrganizationDevelopersConnection' } & Pick<OrganizationDevelopersConnection, 'count'> & {
            edges: Array<
              { __typename?: 'OrganizationDevelopersEdge' } & {
                node: { __typename: 'Developer' } & Pick<Developer, 'developerId' | 'name'>;
              }
            >;
          }
      >;
      contractors?: Maybe<
        { __typename: 'OrganizationContractorsConnection' } & Pick<OrganizationContractorsConnection, 'count'> & {
            edges: Array<
              { __typename: 'OrganizationContractorsEdge' } & {
                node: { __typename: 'Contractor' } & Pick<Contractor, 'contractorId' | 'description' | 'websiteUrl'> & {
                    jobsiteContractors: { __typename: 'ContractorJobsiteContractorsConnection' } & Pick<
                      ContractorJobsiteContractorsConnection,
                      'count'
                    >;
                  };
              }
            >;
          }
      >;
    };
};

export type GetOrganizationsContainerCurrentSessionQueryVariables = Exact<{
  userOrganizationsInput?: InputMaybe<GetAllOrganizationsInput>;
}>;

export type GetOrganizationsContainerCurrentSessionQuery = { __typename?: 'Query' } & {
  getCurrentSession?: Maybe<
    { __typename?: 'Auth' } & {
      user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
          organizations: { __typename?: 'UserOrganizationsConnection' } & Pick<UserOrganizationsConnection, 'count'> & {
              edges: Array<
                { __typename?: 'UserOrganizationsEdge' } & {
                  node: { __typename?: 'Organization' } & Pick<
                    Organization,
                    | 'name'
                    | 'legalName'
                    | 'organizationId'
                    | 'slug'
                    | 'addressLine1'
                    | 'addressLine2'
                    | 'addressCity'
                    | 'addressState'
                    | 'addressZipCode'
                    | 'email'
                    | 'phoneNumber'
                  > & {
                      contractors?: Maybe<
                        { __typename: 'OrganizationContractorsConnection' } & Pick<
                          OrganizationContractorsConnection,
                          'count'
                        > & {
                            edges: Array<
                              { __typename?: 'OrganizationContractorsEdge' } & {
                                node: { __typename: 'Contractor' } & Pick<Contractor, 'contractorId'>;
                              }
                            >;
                          }
                      >;
                      developers?: Maybe<
                        { __typename: 'OrganizationDevelopersConnection' } & Pick<
                          OrganizationDevelopersConnection,
                          'count'
                        > & {
                            edges: Array<
                              { __typename?: 'OrganizationDevelopersEdge' } & {
                                node: { __typename: 'Developer' } & Pick<Developer, 'developerId'>;
                              }
                            >;
                          }
                      >;
                    };
                }
              >;
            };
        };
    }
  >;
};

export type GetPrintWorkerDataQueryVariables = Exact<{
  workerId: Scalars['ID']['input'];
}>;

export type GetPrintWorkerDataQuery = { __typename?: 'Query' } & {
  getWorker: { __typename?: 'Worker' } & Pick<
    Worker,
    | 'workerId'
    | 'middleInitial'
    | 'suffix'
    | 'addressLine1'
    | 'addressLine2'
    | 'addressCity'
    | 'addressState'
    | 'addressZipCode'
    | 'birthDate'
    | 'emergencyContactName'
    | 'emergencyContactPhone'
    | 'emergencyContactRelationship'
    | 'gender'
    | 'jobTitle'
    | 'primaryLanguage'
    | 'race'
    | 'isSkilled'
    | 'ssnLastFour'
    | 'trade'
    | 'unionAffiliation'
    | 'isVeteran'
    | 'quickCode'
  > & {
      profilePicture?: Maybe<{ __typename?: 'File' } & Pick<File, 'downloadUrl' | 'uploadedAt'>>;
      profilePictureCropped?: Maybe<
        { __typename?: 'File' } & Pick<File, 'downloadUrl' | 'uploadedAt' | 'fileId' | 'fileType'>
      >;
      user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
          identity?: Maybe<
            { __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName' | 'email' | 'phoneNumber'>
          >;
        };
      contractorWorkers: { __typename?: 'WorkerContractorWorkersConnection' } & {
        edges: Array<
          { __typename?: 'WorkerContractorWorkersEdge' } & {
            node: { __typename?: 'ContractorWorker' } & Pick<
              ContractorWorker,
              'id' | 'startDate' | 'endDate' | 'payrollId'
            > & {
                contractor: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId'> & {
                    organization: { __typename?: 'Organization' } & Pick<Organization, 'organizationId' | 'name'>;
                  };
              };
          }
        >;
      };
      jobsiteWorkers: { __typename?: 'WorkerJobsiteWorkersConnection' } & {
        edges: Array<
          { __typename?: 'WorkerJobsiteWorkersEdge' } & {
            node: { __typename?: 'JobsiteWorker' } & Pick<
              JobsiteWorker,
              | 'jobsiteWorkerId'
              | 'isBanned'
              | 'bannedReason'
              | 'bannedAt'
              | 'startDate'
              | 'endDate'
              | 'payrollId'
              | 'stickerNumber'
              | 'orientationDate'
              | 'orientationStatus'
            > & {
                jobsiteContractor: { __typename?: 'JobsiteContractor' } & {
                  jobsite: { __typename?: 'Jobsite' } & Pick<Jobsite, 'name' | 'jobsiteId'>;
                  contractor: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId'> & {
                      organization: { __typename?: 'Organization' } & Pick<Organization, 'name'>;
                    };
                };
                currentAccess: { __typename?: 'JobsiteWorkerCurrentAccess' } & Pick<
                  JobsiteWorkerCurrentAccess,
                  'endDate' | 'isAllowed'
                >;
              };
          }
        >;
      };
      workerComments: { __typename?: 'WorkerWorkerCommentsConnection' } & {
        edges: Array<
          { __typename?: 'WorkerWorkerCommentsEdge' } & {
            node: { __typename?: 'WorkerComment' } & Pick<WorkerComment, 'workerCommentId' | 'comment'> & {
                jobsite?: Maybe<{ __typename?: 'Jobsite' } & Pick<Jobsite, 'name' | 'jobsiteId'>>;
                objectHistory?: Maybe<
                  { __typename?: 'ObjectHistory' } & Pick<ObjectHistory, 'createdAt' | 'updatedAt'> & {
                      createdBy?: Maybe<
                        { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
                            identity?: Maybe<
                              { __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName' | 'email'>
                            >;
                          }
                      >;
                      updatedBy?: Maybe<
                        { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
                            identity?: Maybe<
                              { __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName' | 'email'>
                            >;
                          }
                      >;
                    }
                >;
              };
          }
        >;
      };
    };
};

export type SearchWorkersQueryVariables = Exact<{
  searchString: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type SearchWorkersQuery = { __typename?: 'Query' } & {
  searchWorkers: { __typename?: 'WorkerSearchResponse' } & Pick<WorkerSearchResponse, 'count'> & {
      results: Array<
        { __typename?: 'WorkerSearchResult' } & {
          worker: { __typename?: 'Worker' } & Pick<
            Worker,
            'workerId' | 'quickCode' | 'trade' | 'jobTitle' | 'unionAffiliation'
          > & {
              user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
                  identity?: Maybe<{ __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName'>>;
                };
              profilePictureCropped?: Maybe<{ __typename?: 'File' } & Pick<File, 'downloadUrl'>>;
            };
        }
      >;
    };
};

export type CompletePhoneNumberVerificationMutationVariables = Exact<{
  input: CompletePhoneNumberVerificationInput;
}>;

export type CompletePhoneNumberVerificationMutation = { __typename?: 'Mutation' } & {
  completePhoneNumberVerification: { __typename?: 'CompletePhoneNumberVerificationResponse' } & Pick<
    CompletePhoneNumberVerificationResponse,
    'success'
  > & {
      worker?: Maybe<
        { __typename?: 'Worker' } & Pick<Worker, 'workerId'> & {
            editableFields: { __typename?: 'EditableFields' } & Pick<EditableFields, 'phone' | 'email'>;
          }
      >;
    };
};

export type CompletePhoneNumberVerificationAndSignInMutationVariables = Exact<{
  input: CompletePhoneNumberVerificationAndSignInInput;
}>;

export type CompletePhoneNumberVerificationAndSignInMutation = { __typename?: 'Mutation' } & {
  completePhoneNumberVerificationAndSignIn: { __typename?: 'CompletePhoneNumberVerificationAndSignInResponse' } & Pick<
    CompletePhoneNumberVerificationAndSignInResponse,
    'success'
  > & {
      jobsiteWorker: { __typename?: 'JobsiteWorker' } & Pick<JobsiteWorker, 'jobsiteWorkerId'> & {
          contractorWorker: { __typename?: 'ContractorWorker' } & {
            worker: { __typename?: 'Worker' } & Pick<Worker, 'workerId'> & {
                editableFields: { __typename?: 'EditableFields' } & Pick<EditableFields, 'phone' | 'email'>;
              };
          };
        };
      auth: { __typename?: 'Auth' } & {
        user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
            identity?: Maybe<{ __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName'>>;
          };
        session: { __typename?: 'UserSession' } & Pick<UserSession, 'sessionToken'>;
      };
    };
};

export type SelfOnboardingWorkerCreateMutationVariables = Exact<{
  input: SelfOnboardingWorkerAccountCreateInput;
}>;

export type SelfOnboardingWorkerCreateMutation = { __typename?: 'Mutation' } & {
  selfOnboardingWorkerCreate: { __typename?: 'SelfOnboardingWorkerCreatePayload' } & {
    jobsiteWorker: { __typename?: 'JobsiteWorker' } & Pick<JobsiteWorker, 'jobsiteWorkerId'> & {
        contractorWorker: { __typename?: 'ContractorWorker' } & {
          worker: { __typename?: 'Worker' } & Pick<Worker, 'workerId' | 'quickCode'>;
        };
      };
    auth: { __typename?: 'Auth' } & {
      user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
          identity?: Maybe<{ __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName'>>;
        };
      session: { __typename?: 'UserSession' } & Pick<UserSession, 'sessionToken'>;
    };
  };
};

export type SelfOnboardingWorkerUpdateMutationVariables = Exact<{
  input: SelfOnboardingWorkerAccountUpdateInput;
}>;

export type SelfOnboardingWorkerUpdateMutation = { __typename?: 'Mutation' } & {
  selfOnboardingWorkerUpdate: { __typename?: 'SelfOnboardingWorkerUpdatePayload' } & {
    worker: { __typename?: 'Worker' } & Pick<Worker, 'workerId'>;
  };
};

export type StartPhoneNumberVerificationMutationVariables = Exact<{
  input: StartPhoneNumberVerificationInput;
}>;

export type StartPhoneNumberVerificationMutation = { __typename?: 'Mutation' } & {
  startPhoneNumberVerification: { __typename?: 'StartPhoneNumberVerificationResponse' } & Pick<
    StartPhoneNumberVerificationResponse,
    'success'
  >;
};

export type UpsertJobsiteWorkerDocumentMutationVariables = Exact<{
  input: JobsiteWorkerDocumentUpsertInput;
}>;

export type UpsertJobsiteWorkerDocumentMutation = { __typename?: 'Mutation' } & {
  upsertJobsiteWorkerDocument: { __typename?: 'JobsiteWorkerDocumentVersion' } & Pick<
    JobsiteWorkerDocumentVersion,
    'jobsiteWorkerDocumentVersionId'
  > & {
      files: Array<
        { __typename?: 'File' } & Pick<File, 'fileId' | 'fileType' | 'downloadUrl' | 'originalFileName' | 'uploadedAt'>
      >;
      additionalFieldValues?: Maybe<
        Array<{ __typename?: 'AdditionalFieldValue' } & Pick<AdditionalFieldValue, 'key' | 'value'>>
      >;
    };
};

export type GetSelfOnboardingContainerSessionQueryVariables = Exact<{
  jobsiteWorkersInput: WorkerJobsiteWorkersInput;
  workerDocumentsInput: WorkerDocumentsInput;
}>;

export type GetSelfOnboardingContainerSessionQuery = { __typename?: 'Query' } & {
  getCurrentSession?: Maybe<
    { __typename?: 'Auth' } & {
      user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
          identity?: Maybe<
            { __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName' | 'email' | 'phoneNumber'>
          >;
          worker?: Maybe<
            { __typename?: 'Worker' } & Pick<
              Worker,
              | 'workerId'
              | 'quickCode'
              | 'middleInitial'
              | 'suffix'
              | 'birthDate'
              | 'ssnLastFour'
              | 'addressLine1'
              | 'addressLine2'
              | 'addressCity'
              | 'addressState'
              | 'addressZipCode'
              | 'primaryLanguage'
              | 'citizenshipStatus'
              | 'race'
              | 'gender'
              | 'isVeteran'
              | 'trade'
              | 'jobTitle'
              | 'unionAffiliation'
              | 'emergencyContactName'
              | 'emergencyContactRelationship'
              | 'emergencyContactPhone'
              | 'selfOnboardingCurrentStep'
              | 'isAssignedToMultipleJobsites'
            > & {
                profilePicture?: Maybe<
                  { __typename?: 'File' } & Pick<File, 'fileId' | 'fileType' | 'downloadUrl' | 'uploadedAt'>
                >;
                editableFields: { __typename?: 'EditableFields' } & Pick<EditableFields, 'email' | 'phone'>;
                jobsiteWorkers: { __typename?: 'WorkerJobsiteWorkersConnection' } & {
                  edges: Array<
                    { __typename?: 'WorkerJobsiteWorkersEdge' } & {
                      node: { __typename?: 'JobsiteWorker' } & Pick<JobsiteWorker, 'jobsiteWorkerId'> & {
                          jobsiteContractor: { __typename?: 'JobsiteContractor' } & Pick<JobsiteContractor, 'id'> & {
                              contractor: { __typename?: 'Contractor' } & Pick<
                                Contractor,
                                'contractorId' | 'isDefaultContractor'
                              > & {
                                  organization: { __typename?: 'Organization' } & Pick<
                                    Organization,
                                    'organizationId' | 'name'
                                  >;
                                };
                            };
                        };
                    }
                  >;
                };
                documents: { __typename?: 'WorkerDocumentsConnection' } & {
                  edges: Array<
                    { __typename?: 'WorkerDocumentsEdge' } & {
                      node: { __typename?: 'JobsiteWorkerDocument' } & Pick<
                        JobsiteWorkerDocument,
                        'jobsiteWorkerDocumentId'
                      > & {
                          jobsiteWorkerDocumentType: { __typename?: 'JobsiteWorkerDocumentType' } & Pick<
                            JobsiteWorkerDocumentType,
                            'id'
                          > & {
                              workerDocumentType: { __typename?: 'WorkerDocumentType' } & Pick<
                                WorkerDocumentType,
                                'key'
                              >;
                            };
                          latestVersion: { __typename?: 'JobsiteWorkerDocumentVersion' } & Pick<
                            JobsiteWorkerDocumentVersion,
                            'jobsiteWorkerDocumentVersionId'
                          > & {
                              additionalFieldValues?: Maybe<
                                Array<
                                  { __typename?: 'AdditionalFieldValue' } & Pick<AdditionalFieldValue, 'key' | 'value'>
                                >
                              >;
                              files: Array<{ __typename?: 'File' } & Pick<File, 'fileId' | 'fileType' | 'downloadUrl'>>;
                            };
                        };
                    }
                  >;
                };
              }
          >;
        };
    }
  >;
};

export type GetSelfOnboardingCurrentStepQueryVariables = Exact<{ [key: string]: never }>;

export type GetSelfOnboardingCurrentStepQuery = { __typename?: 'Query' } & {
  getCurrentSession?: Maybe<
    { __typename?: 'Auth' } & {
      user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
          worker?: Maybe<{ __typename?: 'Worker' } & Pick<Worker, 'workerId' | 'selfOnboardingCurrentStep'>>;
        };
    }
  >;
};

export type UserCancelPendingChangeMutationVariables = Exact<{
  input: UserCancelPendingChangeInput;
}>;

export type UserCancelPendingChangeMutation = { __typename?: 'Mutation' } & {
  userCancelPendingChange: { __typename?: 'Success' } & Pick<Success, 'success'>;
};

export type UserConfirmPendingChangeMutationVariables = Exact<{
  input: UserConfirmPendingChangeInput;
}>;

export type UserConfirmPendingChangeMutation = { __typename?: 'Mutation' } & {
  userConfirmPendingChange: { __typename?: 'UserPayload' } & {
    user: { __typename?: 'User' } & Pick<User, 'userAccountId'>;
  };
};

export type GetUserPendingChangeQueryVariables = Exact<{
  workerId: Scalars['ID']['input'];
  changeType: UserPendingChangeType;
}>;

export type GetUserPendingChangeQuery = { __typename?: 'Query' } & {
  getUserPendingChange: { __typename?: 'UserPendingChangePayload' } & {
    change?: Maybe<
      { __typename?: 'UserPendingChange' } & Pick<UserPendingChange, 'currentValue' | 'newValue' | 'expiresAt'> & {
          submittedBy: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
              identity?: Maybe<{ __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName'>>;
            };
        }
    >;
  };
};

export type UserAssignMutationVariables = Exact<{
  input: UserAssignInput;
}>;

export type UserAssignMutation = { __typename?: 'Mutation' } & {
  userAssign: { __typename?: 'UserPayload' } & { user: { __typename?: 'User' } & Pick<User, 'userAccountId'> };
};

export type UserCreateMutationVariables = Exact<{
  input: UserCreateInput;
}>;

export type UserCreateMutation = { __typename?: 'Mutation' } & {
  userCreate: { __typename?: 'UserPayload' } & { user: { __typename?: 'User' } & Pick<User, 'userAccountId'> };
};

export type UserRemoveAssignmentMutationVariables = Exact<{
  input: UserRemoveAssignmentInput;
}>;

export type UserRemoveAssignmentMutation = { __typename?: 'Mutation' } & {
  userRemoveAssignment: { __typename?: 'UserPayload' } & {
    user: { __typename?: 'User' } & Pick<User, 'userAccountId'>;
  };
};

export type UserUpdateAssignmentMutationVariables = Exact<{
  input: UserUpdateAssignmentInput;
}>;

export type UserUpdateAssignmentMutation = { __typename?: 'Mutation' } & {
  userUpdateAssignment: { __typename?: 'UserPayload' } & {
    user: { __typename?: 'User' } & Pick<User, 'userAccountId'>;
  };
};

export type GetUserRoleModalContractorsQueryVariables = Exact<{
  workerId: Scalars['ID']['input'];
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
  availableRoleObjectsInput?: InputMaybe<UserAvailableRoleObjectsInput>;
}>;

export type GetUserRoleModalContractorsQuery = { __typename?: 'Query' } & {
  getWorker: { __typename?: 'Worker' } & {
    user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
        availableRoleObjects: { __typename?: 'UserAvailableRoleObjectsConnection' } & {
          contractors: Array<
            { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId'> & {
                organization: { __typename?: 'Organization' } & Pick<Organization, 'organizationId' | 'name'>;
              }
          >;
        };
      };
  };
};

export type GetUserRoleModalJobsitesQueryVariables = Exact<{
  workerId: Scalars['ID']['input'];
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
  availableRoleObjectsInput?: InputMaybe<UserAvailableRoleObjectsInput>;
}>;

export type GetUserRoleModalJobsitesQuery = { __typename?: 'Query' } & {
  getWorker: { __typename?: 'Worker' } & {
    user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
        availableRoleObjects: { __typename?: 'UserAvailableRoleObjectsConnection' } & {
          jobsites: Array<{ __typename?: 'Jobsite' } & Pick<Jobsite, 'jobsiteId' | 'name'>>;
        };
      };
  };
};

export type GetUsersQueryVariables = Exact<{
  usersInput?: InputMaybe<UserUsersInput>;
}>;

export type GetUsersQuery = { __typename?: 'Query' } & {
  getCurrentSession?: Maybe<
    { __typename?: 'Auth' } & {
      user: { __typename?: 'User' } & {
        users: { __typename?: 'UserUsersConnection' } & Pick<
          UserUsersConnection,
          'jobsiteUsersCount' | 'contractorUsersCount' | 'developerUsersCount'
        > & {
            edges: Array<
              { __typename?: 'UserUsersEdge' } & {
                node: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
                    roles: Array<
                      { __typename?: 'UserRole' } & Pick<UserRole, 'key' | 'displayName'> & {
                          object?: Maybe<
                            { __typename?: 'UserRoleObject' } & Pick<UserRoleObject, 'objectId'> & {
                                objectDetails?: Maybe<
                                  { __typename?: 'UserRoleObjectDetails' } & Pick<UserRoleObjectDetails, 'name'>
                                >;
                              }
                          >;
                        }
                    >;
                    identity?: Maybe<
                      { __typename?: 'UserIdentity' } & Pick<
                        UserIdentity,
                        'firstName' | 'lastName' | 'email' | 'phoneNumber'
                      >
                    >;
                    worker?: Maybe<
                      { __typename?: 'Worker' } & Pick<Worker, 'workerId'> & {
                          profilePictureCropped?: Maybe<{ __typename?: 'File' } & Pick<File, 'downloadUrl'>>;
                        }
                    >;
                    lastSession?: Maybe<{ __typename?: 'UserSession' } & Pick<UserSession, 'createdAt' | 'appVersion'>>;
                  };
              }
            >;
          };
      };
    }
  >;
};

export type ArchiveJobsiteWorkerAccessEventMutationVariables = Exact<{
  input: ArchiveJobsiteWorkerAccessEventInput;
}>;

export type ArchiveJobsiteWorkerAccessEventMutation = { __typename?: 'Mutation' } & {
  archiveJobsiteWorkerAccessEvent?: Maybe<
    { __typename?: 'ArchiveJobsiteWorkerAccessEventResponse' } & Pick<
      ArchiveJobsiteWorkerAccessEventResponse,
      'accessEventId'
    > & { success: { __typename?: 'Success' } & Pick<Success, 'success'> }
  >;
};

export type ChangeContractorForJobsiteWorkersMutationVariables = Exact<{
  input?: InputMaybe<ChangeContractorForJobsiteWorkersInput>;
}>;

export type ChangeContractorForJobsiteWorkersMutation = { __typename?: 'Mutation' } & {
  changeContractorForJobsiteWorkers: { __typename?: 'ChangeContractorForJobsiteWorkersResponse' } & {
    changeContractorForJobsiteWorkersSuccess: Array<
      Maybe<
        { __typename?: 'ChangeContractorForJobsiteWorkersSuccess' } & {
          jobsiteWorker: { __typename?: 'JobsiteWorker' } & Pick<JobsiteWorker, 'jobsiteWorkerId' | 'startDate'> & {
              contractorWorker: { __typename?: 'ContractorWorker' } & {
                worker: { __typename?: 'Worker' } & Pick<Worker, 'workerId'> & {
                    user: { __typename?: 'User' } & {
                      identity?: Maybe<{ __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName'>>;
                    };
                  };
              };
              jobsiteContractor: { __typename?: 'JobsiteContractor' } & {
                jobsite: { __typename?: 'Jobsite' } & Pick<Jobsite, 'name'>;
              };
            };
        }
      >
    >;
    changeContractorForJobsiteWorkersError: Array<
      Maybe<
        { __typename?: 'ChangeContractorForJobsiteWorkersError' } & Pick<
          ChangeContractorForJobsiteWorkersError,
          'errorMessage'
        >
      >
    >;
  };
};

export type ChangeJobsiteWorkersJobsiteAssignmentMutationVariables = Exact<{
  input: ChangeJobsiteWorkersJobsiteAssignmentInput;
}>;

export type ChangeJobsiteWorkersJobsiteAssignmentMutation = { __typename?: 'Mutation' } & {
  changeJobsiteWorkersJobsiteAssignment: { __typename?: 'ChangeJobsiteWorkersJobsiteAssignmentResponse' } & Pick<
    ChangeJobsiteWorkersJobsiteAssignmentResponse,
    'totalCount'
  > & {
      edges: Array<
        { __typename?: 'JobsiteWorker' } & Pick<JobsiteWorker, 'jobsiteWorkerId'> & {
            jobsiteContractor: { __typename?: 'JobsiteContractor' } & {
              jobsite: { __typename?: 'Jobsite' } & Pick<Jobsite, 'name'>;
            };
          }
      >;
    };
};

export type CheckInJobsiteWorkersMutationVariables = Exact<{
  input: CheckInJobsiteWorkersInput;
}>;

export type CheckInJobsiteWorkersMutation = { __typename?: 'Mutation' } & {
  checkInJobsiteWorkers: { __typename?: 'JobsiteWorkerCheckInConnection' } & Pick<
    JobsiteWorkerCheckInConnection,
    'totalCount'
  > & {
      edges: Array<
        { __typename?: 'JobsiteWorkerCheckIn' } & Pick<
          JobsiteWorkerCheckIn,
          'checkInDateTime' | 'jobsiteWorkerCheckInId'
        > & {
            area: { __typename?: 'JobsiteAppCheckInModuleArea' } & Pick<JobsiteAppCheckInModuleArea, 'id' | 'name'>;
            jobsiteWorker?: Maybe<
              { __typename?: 'JobsiteWorker' } & Pick<JobsiteWorker, 'jobsiteWorkerId'> & {
                  jobsiteContractor: { __typename?: 'JobsiteContractor' } & {
                    jobsite: { __typename?: 'Jobsite' } & Pick<Jobsite, 'name' | 'jobsiteId'>;
                  };
                  contractorWorker: { __typename?: 'ContractorWorker' } & {
                    worker: { __typename?: 'Worker' } & Pick<Worker, 'workerId'>;
                  };
                }
            >;
          }
      >;
    };
};

export type CompleteSelfOnBoardingMutationVariables = Exact<{
  jobsiteWorkerId: Scalars['ID']['input'];
}>;

export type CompleteSelfOnBoardingMutation = { __typename?: 'Mutation' } & {
  completeSelfOnBoarding: { __typename?: 'JobsiteWorker' } & Pick<
    JobsiteWorker,
    'jobsiteWorkerId' | 'orientationStatus' | 'orientationDate'
  > & {
      contractorWorker: { __typename?: 'ContractorWorker' } & {
        worker: { __typename?: 'Worker' } & Pick<Worker, 'workerId' | 'selfOnboardingCurrentStep'>;
      };
    };
};

export type EnableJobsiteWorkerSelfOnboardingMutationVariables = Exact<{
  jobsiteWorkerId: Scalars['ID']['input'];
}>;

export type EnableJobsiteWorkerSelfOnboardingMutation = { __typename?: 'Mutation' } & {
  enableJobsiteWorkerSelfOnboarding: { __typename?: 'JobsiteWorker' } & Pick<
    JobsiteWorker,
    'jobsiteWorkerId' | 'orientationStatus'
  >;
};

export type ManuallyEnterCardNumberForPrintJobMutationVariables = Exact<{
  cardPrintJobId: Scalars['ID']['input'];
  cardNumber: Scalars['String']['input'];
  workerCardFormatId: Scalars['ID']['input'];
  forceCreate?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ManuallyEnterCardNumberForPrintJobMutation = { __typename?: 'Mutation' } & {
  manuallyEnterCardNumberForPrintJob: { __typename?: 'WorkerCard' } & Pick<
    WorkerCard,
    'workerCardId' | 'cardNumber'
  > & { workerCardFormat: { __typename?: 'WorkerCardFormat' } & Pick<WorkerCardFormat, 'workerCardFormatId' | 'name'> };
};

export type ReactivateWorkerCardMutationVariables = Exact<{
  workerCardIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  forceCreate?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ReactivateWorkerCardMutation = { __typename?: 'Mutation' } & {
  reactivateWorkerCard: { __typename?: 'Success' } & Pick<Success, 'success'>;
};

export type RemoveWorkerFromJobsiteMutationVariables = Exact<{
  input: RemoveWorkerFromJobsiteInput;
}>;

export type RemoveWorkerFromJobsiteMutation = { __typename?: 'Mutation' } & {
  removeWorkerFromJobsite: { __typename?: 'RemoveWorkerFromJobsiteResponse' } & {
    success: { __typename?: 'Success' } & Pick<Success, 'success'>;
  };
};

export type UpdateJobsiteWorkerMutationVariables = Exact<{
  jobsiteWorkerId: Scalars['ID']['input'];
  jobsiteWorkerInput: JobsiteWorkerInput;
  skipACSSync?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type UpdateJobsiteWorkerMutation = { __typename?: 'Mutation' } & {
  updateJobsiteWorker: { __typename?: 'JobsiteWorker' } & Pick<JobsiteWorker, 'orientationDate'>;
};

export type UpdateWorkerMutationVariables = Exact<{
  workerId: Scalars['ID']['input'];
  workerInput: WorkerInput;
}>;

export type UpdateWorkerMutation = { __typename?: 'Mutation' } & {
  updateWorker: { __typename?: 'Worker' } & Pick<
    Worker,
    'birthDate' | 'ssnLastFour' | 'workerId' | 'trade' | 'jobTitle'
  > & {
      profilePictureCropped?: Maybe<{ __typename?: 'File' } & Pick<File, 'downloadUrl'>>;
      user: { __typename?: 'User' } & Pick<User, 'userAccountId' | 'createdAt'> & {
          identity?: Maybe<
            { __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName' | 'createdAt' | 'updatedAt'>
          >;
          roles: Array<
            { __typename?: 'UserRole' } & Pick<
              UserRole,
              'key' | 'displayName' | 'roleCreatedAt' | 'roleUpdatedAt' | 'mappingCreatedAt'
            >
          >;
        };
    };
};

export type UserUpdateIdentityMutationVariables = Exact<{
  input: UserUpdateIdentityInput;
}>;

export type UserUpdateIdentityMutation = { __typename?: 'Mutation' } & {
  userUpdateIdentity: { __typename?: 'UserPayload' } & {
    user: { __typename?: 'User' } & {
      identity?: Maybe<{ __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName' | 'email'>>;
    };
  };
};

export type WorkerUnlockProfileMutationVariables = Exact<{
  input: WorkerUnlockProfileInput;
}>;

export type WorkerUnlockProfileMutation = { __typename?: 'Mutation' } & {
  workerUnlockProfile: { __typename?: 'WorkerUnlockProfilePayload' } & {
    userWorkerProfileAccess: { __typename?: 'UserWorkerProfileAccess' } & Pick<
      UserWorkerProfileAccess,
      'id' | 'isUnlocked'
    >;
  };
};

export type ExtractIdDataFromFileMutationVariables = Exact<{
  extractionInput: DataExtractionInput;
}>;

export type ExtractIdDataFromFileMutation = { __typename?: 'Mutation' } & {
  extractIdDataFromFile: { __typename?: 'IdCardDataExtractionResult' } & {
    file?: Maybe<{ __typename?: 'File' } & Pick<File, 'fileId'>>;
    extractedData?: Maybe<
      { __typename?: 'IdCardData' } & Pick<
        IdCardData,
        | 'identifier'
        | 'idType'
        | 'issueDate'
        | 'expirationDate'
        | 'dateOfBirth'
        | 'fullName'
        | 'firstName'
        | 'lastName'
        | 'address'
        | 'title'
      >
    >;
  };
};

export type GetJobsiteAccessSessionsQueryVariables = Exact<{
  userJobsiteAccessSessionsInput?: InputMaybe<UserJobsiteAccessSessionsInput>;
}>;

export type GetJobsiteAccessSessionsQuery = { __typename?: 'Query' } & {
  getCurrentSession?: Maybe<
    { __typename?: 'Auth' } & {
      user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
          jobsiteAccessSessions?: Maybe<
            { __typename?: 'UserJobsiteAccessSessionsConnection' } & Pick<
              UserJobsiteAccessSessionsConnection,
              'count'
            > & {
                edges: Array<
                  Maybe<
                    { __typename?: 'JobsiteAccessSessionEdge' } & {
                      node?: Maybe<
                        { __typename?: 'JobsiteAccessSession' } & Pick<JobsiteAccessSession, 'sessionId'> & {
                            jobsiteWorker: { __typename?: 'JobsiteWorker' } & {
                              contractorWorker: { __typename?: 'ContractorWorker' } & {
                                worker: { __typename?: 'Worker' } & Pick<Worker, 'workerId'> & {
                                    user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
                                        identity?: Maybe<
                                          { __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName'>
                                        >;
                                      };
                                    profilePictureCropped?: Maybe<{ __typename?: 'File' } & Pick<File, 'downloadUrl'>>;
                                  };
                              };
                              jobsiteContractor: { __typename?: 'JobsiteContractor' } & Pick<
                                JobsiteContractor,
                                'id'
                              > & { jobsite: { __typename?: 'Jobsite' } & Pick<Jobsite, 'jobsiteId' | 'name'> };
                            };
                            proximitySession: { __typename?: 'ProximitySessionResult' } & Pick<
                              ProximitySessionResult,
                              'id' | 'day' | 'start' | 'end' | 'active' | 'compliant' | 'closed'
                            > & {
                                locale?: Maybe<
                                  { __typename?: 'ProximitySessionLocaleData' } & {
                                    time?: Maybe<
                                      { __typename?: 'ProximitySessionLocaleTimeData' } & Pick<
                                        ProximitySessionLocaleTimeData,
                                        'start' | 'end'
                                      >
                                    >;
                                  }
                                >;
                                span?: Maybe<
                                  { __typename?: 'ProximitySessionSpanData' } & Pick<ProximitySessionSpanData, 'hours'>
                                >;
                                reads?: Maybe<
                                  { __typename?: 'ProximitySessionReadsData' } & Pick<
                                    ProximitySessionReadsData,
                                    'count'
                                  > & {
                                      counts?: Maybe<
                                        { __typename?: 'ProximitySessionReadsCountData' } & Pick<
                                          ProximitySessionReadsCountData,
                                          'inbound' | 'outbound'
                                        >
                                      >;
                                    }
                                >;
                              };
                          }
                      >;
                    }
                  >
                >;
              }
          >;
        };
    }
  >;
};

export type GetWorkerCCureDataQueryVariables = Exact<{
  workerId: Scalars['ID']['input'];
}>;

export type GetWorkerCCureDataQuery = { __typename?: 'Query' } & {
  getWorkerCCureData: { __typename?: 'WorkerCCureData' } & Pick<WorkerCCureData, 'data'>;
};

export type GetWorkerDataQueryVariables = Exact<{
  workerId: Scalars['ID']['input'];
  includeArchivedWorker?: InputMaybe<Scalars['Boolean']['input']>;
  jobsiteWorkersInput?: InputMaybe<WorkerJobsiteWorkersInput>;
}>;

export type GetWorkerDataQuery = { __typename?: 'Query' } & {
  getWorker: { __typename?: 'Worker' } & Pick<
    Worker,
    | 'workerId'
    | 'middleInitial'
    | 'suffix'
    | 'addressLine1'
    | 'addressLine2'
    | 'addressCity'
    | 'addressState'
    | 'addressZipCode'
    | 'birthDate'
    | 'emergencyContactName'
    | 'emergencyContactPhone'
    | 'emergencyContactRelationship'
    | 'gender'
    | 'jobTitle'
    | 'primaryLanguage'
    | 'race'
    | 'isSkilled'
    | 'ssnLastFour'
    | 'trade'
    | 'unionAffiliation'
    | 'citizenshipStatus'
    | 'isVeteran'
    | 'quickCode'
    | 'selfOnboardingCurrentStep'
    | 'createdMethod'
    | 'isArchived'
  > & {
      objectHistory: { __typename?: 'ObjectHistory' } & Pick<ObjectHistory, 'createdAt' | 'updatedAt'> & {
          createdBy?: Maybe<
            { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
                identity?: Maybe<
                  { __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName' | 'email'>
                >;
              }
          >;
          updatedBy?: Maybe<
            { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
                identity?: Maybe<
                  { __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName' | 'email'>
                >;
              }
          >;
        };
      accessHistory?: Maybe<
        Array<
          { __typename?: 'WorkerAccessHistory' } & Pick<
            WorkerAccessHistory,
            | 'changeCategory'
            | 'changeReason'
            | 'changeApprovedBy'
            | 'changeDate'
            | 'changeToAccess'
            | 'updatedBy'
            | 'banEndDate'
            | 'jobsiteName'
          >
        >
      >;
      profilePicture?: Maybe<{ __typename?: 'File' } & Pick<File, 'downloadUrl' | 'uploadedAt'>>;
      profilePictureCropped?: Maybe<
        { __typename?: 'File' } & Pick<File, 'downloadUrl' | 'uploadedAt' | 'fileId' | 'fileType'>
      >;
      user: { __typename?: 'User' } & Pick<User, 'userAccountId' | 'isMobileUser'> & {
          identity?: Maybe<
            { __typename?: 'UserIdentity' } & Pick<
              UserIdentity,
              'firstName' | 'lastName' | 'email' | 'phoneNumber' | 'fusionAuthId'
            > & {
                pendingChanges?: Maybe<
                  { __typename?: 'UserIdentityPendingChanges' } & {
                    phoneNumber?: Maybe<
                      { __typename?: 'UserIdentityPendingChange' } & Pick<
                        UserIdentityPendingChange,
                        'newValue' | 'expiresAt'
                      > & { submittedBy: { __typename?: 'User' } & Pick<User, 'userAccountId'> }
                    >;
                    email?: Maybe<
                      { __typename?: 'UserIdentityPendingChange' } & Pick<
                        UserIdentityPendingChange,
                        'newValue' | 'expiresAt'
                      > & { submittedBy: { __typename?: 'User' } & Pick<User, 'userAccountId'> }
                    >;
                  }
                >;
              }
          >;
          lastSession?: Maybe<{ __typename?: 'UserSession' } & Pick<UserSession, 'createdAt' | 'appVersion'>>;
        };
      profileAccess?: Maybe<
        { __typename?: 'UserWorkerProfileAccess' } & Pick<UserWorkerProfileAccess, 'id' | 'isUnlocked'>
      >;
      editableFields: { __typename?: 'EditableFields' } & Pick<EditableFields, 'email' | 'phone'>;
      workerComments: { __typename?: 'WorkerWorkerCommentsConnection' } & {
        edges: Array<
          { __typename?: 'WorkerWorkerCommentsEdge' } & {
            node: { __typename?: 'WorkerComment' } & Pick<WorkerComment, 'workerCommentId' | 'comment'> & {
                jobsite?: Maybe<{ __typename?: 'Jobsite' } & Pick<Jobsite, 'name' | 'jobsiteId'>>;
                objectHistory?: Maybe<
                  { __typename?: 'ObjectHistory' } & Pick<ObjectHistory, 'createdAt' | 'updatedAt'> & {
                      createdBy?: Maybe<
                        { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
                            identity?: Maybe<
                              { __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName' | 'email'>
                            >;
                          }
                      >;
                      updatedBy?: Maybe<
                        { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
                            identity?: Maybe<
                              { __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName' | 'email'>
                            >;
                          }
                      >;
                    }
                >;
              };
          }
        >;
      };
      jobsiteWorkers: { __typename?: 'WorkerJobsiteWorkersConnection' } & {
        edges: Array<
          { __typename?: 'WorkerJobsiteWorkersEdge' } & {
            node: { __typename?: 'JobsiteWorker' } & Pick<
              JobsiteWorker,
              | 'jobsiteWorkerId'
              | 'isBanned'
              | 'banCategory'
              | 'bannedReason'
              | 'bannedAt'
              | 'banRemovedReason'
              | 'startDate'
              | 'endDate'
              | 'payrollId'
              | 'siteSpecificTrainingCompletedAt'
              | 'siteSpecificTrainingSkipReason'
              | 'siteSpecificTrainingSkipExpiresAt'
              | 'stickerNumber'
              | 'orientationDate'
              | 'orientationStatus'
              | 'documentsCompletedAt'
              | 'documentsCompletedSkipExpiresAt'
              | 'documentsCompletedSkipReason'
              | 'urineTestConditionalPassExpiresAt'
              | 'banExpiresAt'
              | 'badgingCompletedAt'
              | 'medicalNotes'
              | 'nycSstExemptionReason'
              | 'siteAccessChangeApprovedBy'
              | 'isExemptFromSwipeInactivity'
              | 'isExemptFromSiteSpecificRefresher'
              | 'passedMedicalExamAt'
              | 'medicalExamSkipReason'
              | 'medicalExamSkipExpiresAt'
              | 'isAntipassbackExempt'
              | 'documentExemptionReason'
            > & {
                clearanceAssignments?: Maybe<
                  { __typename?: 'JobsiteWorkerClearanceAssignmentsConnection' } & Pick<
                    JobsiteWorkerClearanceAssignmentsConnection,
                    'count'
                  > & {
                      edges: Array<
                        Maybe<
                          { __typename?: 'JobsiteWorkerClearanceAssignmentEdge' } & {
                            node?: Maybe<
                              { __typename?: 'JobsiteWorkerClearanceAssignment' } & Pick<
                                JobsiteWorkerClearanceAssignment,
                                'id'
                              > & {
                                  clearance: { __typename?: 'CCureClearance' } & Pick<
                                    CCureClearance,
                                    'cCureClearanceId' | 'name'
                                  > & {
                                      cCureEnvironment: { __typename?: 'CCureEnvironment' } & Pick<
                                        CCureEnvironment,
                                        'cCureEnvironmentId' | 'namespace' | 'instanceSlug'
                                      >;
                                    };
                                }
                            >;
                          }
                        >
                      >;
                    }
                >;
                objectHistory: { __typename?: 'ObjectHistory' } & Pick<ObjectHistory, 'createdAt' | 'updatedAt'> & {
                    createdBy?: Maybe<
                      { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
                          identity?: Maybe<
                            { __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName' | 'email'>
                          >;
                        }
                    >;
                    updatedBy?: Maybe<
                      { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
                          identity?: Maybe<
                            { __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName' | 'email'>
                          >;
                        }
                    >;
                  };
                jobsiteContractor: { __typename?: 'JobsiteContractor' } & {
                  jobsite: { __typename?: 'Jobsite' } & Pick<
                    Jobsite,
                    'name' | 'jobsiteId' | 'timeZone' | 'parentId' | 'clearanceAssignmentEnabled'
                  > & {
                      developer?: Maybe<{ __typename?: 'Developer' } & Pick<Developer, 'developerId' | 'name'>>;
                      modules?: Maybe<
                        Array<
                          | ({ __typename?: 'JobsiteAppCheckInModule' } & Pick<
                              JobsiteAppCheckInModule,
                              'isEnabled' | 'name' | 'slug'
                            > & {
                                areas: Array<
                                  { __typename?: 'JobsiteAppCheckInModuleArea' } & Pick<
                                    JobsiteAppCheckInModuleArea,
                                    'checkInRadius' | 'id' | 'isInboundEnabled' | 'isOutboundEnabled'
                                  > & {
                                      checkInCoordinate: { __typename?: 'GPSCoordinate' } & Pick<
                                        GpsCoordinate,
                                        'lat' | 'lng'
                                      >;
                                    }
                                >;
                              })
                          | ({ __typename?: 'JobsiteFeaturesModule' } & Pick<
                              JobsiteFeaturesModule,
                              'announcementsEnabled' | 'archivingAccessEventsEnabled' | 'isEnabled' | 'name' | 'slug'
                            >)
                          | ({ __typename?: 'JobsiteOnboardingModule' } & Pick<
                              JobsiteOnboardingModule,
                              'name' | 'badgePrintingAllowed' | 'badgeAssignmentAllowed'
                            >)
                          | { __typename?: 'JobsitePreOnboardingModule' }
                        >
                      >;
                    };
                  contractor: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId'> & {
                      organization: { __typename?: 'Organization' } & Pick<Organization, 'organizationId' | 'name'>;
                    };
                };
                currentAccess: { __typename?: 'JobsiteWorkerCurrentAccess' } & Pick<
                  JobsiteWorkerCurrentAccess,
                  'isAllowed' | 'notAllowedReason' | 'endDate'
                >;
              };
          }
        >;
      };
    };
};

export type GetWorkerJobsiteAccessActivityQueryVariables = Exact<{
  workerId: Scalars['ID']['input'];
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
  workerJobsiteAccessEventsInput?: InputMaybe<WorkerJobsiteAccessEventsInput>;
}>;

export type GetWorkerJobsiteAccessActivityQuery = { __typename?: 'Query' } & {
  getWorker: { __typename?: 'Worker' } & Pick<Worker, 'quickCode'> & {
      jobsiteAccessEvents?: Maybe<
        { __typename?: 'WorkerJobsiteAccessEventsConnection' } & Pick<WorkerJobsiteAccessEventsConnection, 'count'> & {
            edges: Array<
              { __typename?: 'WorkerJobsiteAccessEventsEdge' } & {
                node: { __typename?: 'JobsiteAccessEvent' } & Pick<
                  JobsiteAccessEvent,
                  | 'id'
                  | 'type'
                  | 'status'
                  | 'credentialId'
                  | 'eventOccurredAt'
                  | 'localizedDate'
                  | 'localizedTime'
                  | 'sessionDate'
                  | 'timeZone'
                  | 'rejectionReason'
                  | 'message'
                  | 'gateway'
                  | 'overnightSession'
                  | 'direction'
                  | 'externalId'
                  | 'eventType'
                  | 'locationStatus'
                > & {
                    jobsite?: Maybe<{ __typename?: 'Jobsite' } & Pick<Jobsite, 'jobsiteId' | 'name'>>;
                    jobsiteWorker?: Maybe<
                      { __typename?: 'JobsiteWorker' } & Pick<JobsiteWorker, 'jobsiteWorkerId'> & {
                          jobsiteContractor: { __typename?: 'JobsiteContractor' } & Pick<JobsiteContractor, 'id'>;
                          contractorWorker: { __typename?: 'ContractorWorker' } & Pick<ContractorWorker, 'id'> & {
                              contractor: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId'> & {
                                  organization: { __typename?: 'Organization' } & Pick<
                                    Organization,
                                    'organizationId' | 'name'
                                  >;
                                };
                            };
                          currentAccess: { __typename?: 'JobsiteWorkerCurrentAccess' } & Pick<
                            JobsiteWorkerCurrentAccess,
                            'isAllowed'
                          >;
                        }
                    >;
                  };
              }
            >;
            filtersOptions?: Maybe<
              { __typename?: 'WorkerJobsiteAccessEventsFilters' } & {
                jobsites: Array<{ __typename?: 'FilterOption' } & Pick<FilterOption, 'value' | 'label' | 'count'>>;
                contractors: Array<{ __typename?: 'FilterOption' } & Pick<FilterOption, 'value' | 'label' | 'count'>>;
                gateways: Array<{ __typename?: 'FilterOption' } & Pick<FilterOption, 'value' | 'label' | 'count'>>;
                rejectionReasons: Array<
                  Maybe<{ __typename?: 'FilterOption' } & Pick<FilterOption, 'value' | 'label' | 'count'>>
                >;
                locationStatuses: Array<
                  Maybe<{ __typename?: 'FilterOption' } & Pick<FilterOption, 'value' | 'label' | 'count'>>
                >;
              }
            >;
          }
      >;
    };
};

export type GetWorkerJobsiteAccessSessionsQueryVariables = Exact<{
  workerId: Scalars['ID']['input'];
  workerJobsiteAccessSessionsInput?: InputMaybe<WorkerJobsiteAccessSessionsInput>;
}>;

export type GetWorkerJobsiteAccessSessionsQuery = { __typename?: 'Query' } & {
  getWorker: { __typename?: 'Worker' } & {
    jobsiteAccessSessions?: Maybe<
      { __typename?: 'WorkerJobsiteAccessSessionsConnection' } & Pick<
        WorkerJobsiteAccessSessionsConnection,
        'count'
      > & {
          edges: Array<
            Maybe<
              { __typename?: 'JobsiteAccessSessionEdge' } & {
                node?: Maybe<
                  { __typename?: 'JobsiteAccessSession' } & Pick<
                    JobsiteAccessSession,
                    'sessionId' | 'overnightSession'
                  > & {
                      jobsiteWorker: { __typename?: 'JobsiteWorker' } & {
                        contractorWorker: { __typename?: 'ContractorWorker' } & {
                          contractor: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId'> & {
                              organization: { __typename?: 'Organization' } & Pick<
                                Organization,
                                'organizationId' | 'name'
                              >;
                            };
                        };
                        jobsiteContractor: { __typename?: 'JobsiteContractor' } & Pick<JobsiteContractor, 'id'> & {
                            jobsite: { __typename?: 'Jobsite' } & Pick<Jobsite, 'jobsiteId' | 'name'>;
                          };
                        currentAccess: { __typename?: 'JobsiteWorkerCurrentAccess' } & Pick<
                          JobsiteWorkerCurrentAccess,
                          'isAllowed'
                        >;
                      };
                      proximitySession: { __typename?: 'ProximitySessionResult' } & Pick<
                        ProximitySessionResult,
                        'id' | 'day' | 'start' | 'end' | 'active' | 'compliant' | 'closed'
                      > & {
                          locale?: Maybe<
                            { __typename?: 'ProximitySessionLocaleData' } & {
                              time?: Maybe<
                                { __typename?: 'ProximitySessionLocaleTimeData' } & Pick<
                                  ProximitySessionLocaleTimeData,
                                  'start' | 'end'
                                >
                              >;
                            }
                          >;
                          span?: Maybe<
                            { __typename?: 'ProximitySessionSpanData' } & Pick<ProximitySessionSpanData, 'hours'>
                          >;
                          reads?: Maybe<
                            { __typename?: 'ProximitySessionReadsData' } & Pick<ProximitySessionReadsData, 'count'> & {
                                counts?: Maybe<
                                  { __typename?: 'ProximitySessionReadsCountData' } & Pick<
                                    ProximitySessionReadsCountData,
                                    'inbound' | 'outbound'
                                  >
                                >;
                              }
                          >;
                        };
                    }
                >;
              }
            >
          >;
          filtersOptions?: Maybe<
            { __typename?: 'WorkerJobsiteAccessSessionsFilters' } & {
              jobsites: Array<{ __typename?: 'FilterOption' } & Pick<FilterOption, 'value' | 'label' | 'count'>>;
              contractors: Array<{ __typename?: 'FilterOption' } & Pick<FilterOption, 'value' | 'label' | 'count'>>;
            }
          >;
        }
    >;
  };
};

export type GetWorkerUserRolesQueryVariables = Exact<{
  workerId: Scalars['ID']['input'];
  includeArchivedWorker?: InputMaybe<Scalars['Boolean']['input']>;
  userRolesInput?: InputMaybe<UserRolesInput>;
}>;

export type GetWorkerUserRolesQuery = { __typename?: 'Query' } & {
  getWorker: { __typename?: 'Worker' } & Pick<Worker, 'workerId'> & {
      user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
          roles: Array<
            { __typename?: 'UserRole' } & Pick<
              UserRole,
              'key' | 'displayName' | 'mappingCreatedAt' | 'mappingUpdatedAt'
            > & {
                object?: Maybe<
                  { __typename?: 'UserRoleObject' } & Pick<UserRoleObject, 'objectId' | 'objectName'> & {
                      objectDetails?: Maybe<
                        { __typename?: 'UserRoleObjectDetails' } & Pick<
                          UserRoleObjectDetails,
                          'name' | 'title' | 'objectMemberId'
                        >
                      >;
                    }
                >;
              }
          >;
        };
    };
};

export type WorkerOnboardingUpdateUserIdentityMutationVariables = Exact<{
  input: UserUpdateIdentityInput;
}>;

export type WorkerOnboardingUpdateUserIdentityMutation = { __typename?: 'Mutation' } & {
  userUpdateIdentity: { __typename?: 'UserPayload' } & {
    user: { __typename?: 'User' } & {
      identity?: Maybe<{ __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName'>>;
    };
  };
};

export type WorkerOnboardingUpdateWorkerMutationVariables = Exact<{
  workerId: Scalars['ID']['input'];
  workerInput: WorkerInput;
}>;

export type WorkerOnboardingUpdateWorkerMutation = { __typename?: 'Mutation' } & {
  updateWorker: { __typename?: 'Worker' } & Pick<Worker, 'middleInitial'> & {
      user: { __typename?: 'User' } & {
        identity?: Maybe<{ __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName'>>;
      };
    };
};

export type GetWorkerOnboardingDocumentReportQueryVariables = Exact<{
  jobsiteWorkerDocumentId: Scalars['ID']['input'];
}>;

export type GetWorkerOnboardingDocumentReportQuery = { __typename?: 'Query' } & {
  getJobsiteWorkerDocument: { __typename?: 'JobsiteWorkerDocument' } & Pick<
    JobsiteWorkerDocument,
    'jobsiteWorkerDocumentId'
  > & {
      jobsiteWorkerDocumentType: { __typename?: 'JobsiteWorkerDocumentType' } & {
        jobsite: { __typename?: 'Jobsite' } & Pick<Jobsite, 'name' | 'jobsiteId'> & {
            developer?: Maybe<{ __typename?: 'Developer' } & Pick<Developer, 'name' | 'developerId'>>;
          };
        workerDocumentType: { __typename?: 'WorkerDocumentType' } & Pick<
          WorkerDocumentType,
          'name' | 'key' | 'expirationDate' | 'isGlobal' | 'documentLevel'
        >;
      };
      latestVersion: { __typename?: 'JobsiteWorkerDocumentVersion' } & Pick<
        JobsiteWorkerDocumentVersion,
        'jobsiteWorkerDocumentVersionId'
      > & {
          files: Array<{ __typename?: 'File' } & Pick<File, 'fileId' | 'fileType' | 'downloadUrl'>>;
          additionalFieldValues?: Maybe<
            Array<{ __typename?: 'AdditionalFieldValue' } & Pick<AdditionalFieldValue, 'key' | 'value'>>
          >;
          objectHistory: { __typename?: 'ObjectHistory' } & Pick<ObjectHistory, 'createdAt' | 'updatedAt'> & {
              createdBy?: Maybe<
                { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
                    identity?: Maybe<
                      { __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName' | 'email'>
                    >;
                  }
              >;
              updatedBy?: Maybe<
                { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
                    identity?: Maybe<
                      { __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName' | 'email'>
                    >;
                  }
              >;
            };
        };
    };
};

export type GetJobsiteWorkerQueryVariables = Exact<{
  jobsiteWorkerId: Scalars['ID']['input'];
}>;

export type GetJobsiteWorkerQuery = { __typename?: 'Query' } & {
  getJobsiteWorker?: Maybe<
    { __typename?: 'JobsiteWorker' } & Pick<
      JobsiteWorker,
      | 'jobsiteWorkerId'
      | 'isBanned'
      | 'bannedReason'
      | 'bannedAt'
      | 'startDate'
      | 'endDate'
      | 'payrollId'
      | 'stickerNumber'
      | 'orientationDate'
      | 'orientationStatus'
      | 'documentsCompletedAt'
      | 'medicalExamSkipReason'
      | 'passedMedicalExamAt'
      | 'documentsCompletedSkipReason'
      | 'badgingCompletedAt'
      | 'badgingSkipReason'
      | 'signatureCompletedAt'
      | 'signatureSkipReason'
      | 'onboardingSignedAt'
      | 'onboardingSkipReason'
      | 'profileCompletedAt'
      | 'profileCompleteSkipReason'
      | 'siteSpecificTrainingCompletedAt'
      | 'siteSpecificTrainingSkipReason'
      | 'medicalNotes'
      | 'isAntipassbackExempt'
      | 'nycSstExemptionReason'
    > & {
        clearanceAssignments?: Maybe<
          { __typename?: 'JobsiteWorkerClearanceAssignmentsConnection' } & Pick<
            JobsiteWorkerClearanceAssignmentsConnection,
            'count'
          > & {
              edges: Array<
                Maybe<
                  { __typename?: 'JobsiteWorkerClearanceAssignmentEdge' } & {
                    node?: Maybe<
                      { __typename?: 'JobsiteWorkerClearanceAssignment' } & Pick<
                        JobsiteWorkerClearanceAssignment,
                        'id'
                      > & {
                          clearance: { __typename?: 'CCureClearance' } & Pick<
                            CCureClearance,
                            'cCureClearanceId' | 'name'
                          > & {
                              cCureEnvironment: { __typename?: 'CCureEnvironment' } & Pick<
                                CCureEnvironment,
                                'cCureEnvironmentId' | 'namespace' | 'instanceSlug'
                              >;
                            };
                        }
                    >;
                  }
                >
              >;
            }
        >;
        jobsiteContractor: { __typename?: 'JobsiteContractor' } & {
          contractor: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId'> & {
              organization: { __typename?: 'Organization' } & Pick<Organization, 'name'>;
            };
          jobsite: { __typename?: 'Jobsite' } & Pick<Jobsite, 'jobsiteId' | 'name' | 'clearanceAssignmentEnabled'> & {
              developer?: Maybe<{ __typename?: 'Developer' } & Pick<Developer, 'name'>>;
              modules?: Maybe<
                Array<
                  | { __typename?: 'JobsiteAppCheckInModule' }
                  | { __typename?: 'JobsiteFeaturesModule' }
                  | ({ __typename?: 'JobsiteOnboardingModule' } & Pick<JobsiteOnboardingModule, 'name'> & {
                        steps?: Maybe<
                          Array<
                            { __typename?: 'JobsiteOnboardingStep' } & Pick<
                              JobsiteOnboardingStep,
                              'key' | 'index' | 'name' | 'isRequired' | 'isVisible'
                            > & {
                                fields?: Maybe<
                                  Array<
                                    { __typename?: 'JobsiteOnboardingField' } & Pick<
                                      JobsiteOnboardingField,
                                      'key' | 'name' | 'index' | 'isRequired' | 'isHidden'
                                    >
                                  >
                                >;
                              }
                          >
                        >;
                        documents?: Maybe<
                          Array<
                            { __typename?: 'JobsiteOnboardingDocument' } & Pick<
                              JobsiteOnboardingDocument,
                              'key' | 'name' | 'index' | 'isRequired' | 'isVisible'
                            > & {
                                fields?: Maybe<
                                  Array<
                                    { __typename?: 'JobsiteOnboardingField' } & Pick<
                                      JobsiteOnboardingField,
                                      'key' | 'name' | 'index' | 'isRequired' | 'isHidden'
                                    >
                                  >
                                >;
                              }
                          >
                        >;
                      })
                  | { __typename?: 'JobsitePreOnboardingModule' }
                >
              >;
            };
        };
        contractorWorker: { __typename?: 'ContractorWorker' } & {
          contractor: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId'> & {
              organization: { __typename?: 'Organization' } & Pick<Organization, 'name'>;
            };
          worker: { __typename?: 'Worker' } & Pick<
            Worker,
            | 'workerId'
            | 'middleInitial'
            | 'suffix'
            | 'addressLine1'
            | 'addressLine2'
            | 'addressCity'
            | 'addressState'
            | 'addressZipCode'
            | 'quickCode'
            | 'birthDate'
            | 'emergencyContactName'
            | 'emergencyContactPhone'
            | 'emergencyContactRelationship'
            | 'gender'
            | 'jobTitle'
            | 'primaryLanguage'
            | 'race'
            | 'isSkilled'
            | 'ssnLastFour'
            | 'trade'
            | 'unionAffiliation'
            | 'citizenshipStatus'
            | 'isVeteran'
          > & {
              profilePicture?: Maybe<
                { __typename?: 'File' } & Pick<File, 'downloadUrl' | 'uploadedAt' | 'fileId' | 'fileType'>
              >;
              profilePictureCropped?: Maybe<
                { __typename?: 'File' } & Pick<File, 'downloadUrl' | 'uploadedAt' | 'fileId' | 'fileType'>
              >;
              editableFields: { __typename?: 'EditableFields' } & Pick<EditableFields, 'email' | 'phone'>;
              user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
                  identity?: Maybe<
                    { __typename?: 'UserIdentity' } & Pick<
                      UserIdentity,
                      'firstName' | 'lastName' | 'email' | 'phoneNumber'
                    >
                  >;
                };
              jobsiteWorkers: { __typename?: 'WorkerJobsiteWorkersConnection' } & Pick<
                WorkerJobsiteWorkersConnection,
                'count'
              >;
            };
        };
      }
  >;
};

export type GetOnboardingJobsiteWorkersQueryVariables = Exact<{
  userContractorsInput?: InputMaybe<UserContractorsInput>;
  jobsiteIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  contractorIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  activeOnly?: InputMaybe<Scalars['Boolean']['input']>;
  orientationDate?: InputMaybe<Scalars['Date']['input']>;
  orientationStartDate?: InputMaybe<Scalars['Date']['input']>;
  orientationEndDate?: InputMaybe<Scalars['Date']['input']>;
  orientationStatus?: InputMaybe<JobsiteWorkerOrientationStatusFilter>;
  needsMedical?: InputMaybe<Scalars['Boolean']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<GetJobsiteWorkersOrderBy>> | InputMaybe<GetJobsiteWorkersOrderBy>>;
}>;

export type GetOnboardingJobsiteWorkersQuery = { __typename?: 'Query' } & {
  getCurrentSession?: Maybe<
    { __typename?: 'Auth' } & {
      user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
          contractors: { __typename?: 'UserContractorsConnection' } & {
            edges: Array<
              { __typename?: 'UserContractorsEdge' } & {
                node: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId'> & {
                    organization: { __typename?: 'Organization' } & Pick<Organization, 'name'>;
                  };
              }
            >;
          };
        };
    }
  >;
  getJobsiteWorkers: { __typename?: 'JobsiteWorkerConnection' } & Pick<JobsiteWorkerConnection, 'totalCount'> & {
      jobsiteStats?: Maybe<
        { __typename?: 'JobsiteStats' } & Pick<
          JobsiteStats,
          | 'numberOfOrientationWorkersTotal'
          | 'numberOfOrientationWorkersSelfOnboarding'
          | 'numberOfOrientationWorkersInProgress'
          | 'numberOfOrientationWorkersCompleted'
          | 'numberOfOrientationWorkersNeedingMedical'
          | 'numberOfOrientationWorkersNeedingBadging'
        >
      >;
      edges: Array<
        { __typename?: 'JobsiteWorkerAccess' } & Pick<JobsiteWorkerAccess, 'endDate' | 'isAllowedNow'> & {
            jobsiteWorker: { __typename?: 'JobsiteWorker' } & Pick<
              JobsiteWorker,
              | 'jobsiteWorkerId'
              | 'orientationStatus'
              | 'orientationDate'
              | 'medicalExamSkipReason'
              | 'passedMedicalExamAt'
              | 'documentsCompletedAt'
              | 'documentsCompletedSkipReason'
              | 'badgingCompletedAt'
              | 'badgingSkipReason'
              | 'signatureCompletedAt'
              | 'signatureSkipReason'
              | 'onboardingSignedAt'
              | 'onboardingSkipReason'
              | 'profileCompletedAt'
              | 'profileCompleteSkipReason'
              | 'banCategory'
              | 'bannedReason'
              | 'siteSpecificTrainingCompletedAt'
              | 'siteSpecificTrainingSkipReason'
            > & {
                jobsiteContractor: { __typename?: 'JobsiteContractor' } & {
                  jobsite: { __typename?: 'Jobsite' } & Pick<Jobsite, 'jobsiteId' | 'name'> & {
                      modules?: Maybe<
                        Array<
                          | { __typename?: 'JobsiteAppCheckInModule' }
                          | { __typename?: 'JobsiteFeaturesModule' }
                          | ({ __typename?: 'JobsiteOnboardingModule' } & Pick<JobsiteOnboardingModule, 'name'> & {
                                steps?: Maybe<
                                  Array<
                                    { __typename?: 'JobsiteOnboardingStep' } & Pick<
                                      JobsiteOnboardingStep,
                                      'key' | 'index' | 'isVisible'
                                    >
                                  >
                                >;
                              })
                          | { __typename?: 'JobsitePreOnboardingModule' }
                        >
                      >;
                    };
                };
                contractorWorker: { __typename?: 'ContractorWorker' } & {
                  worker: { __typename?: 'Worker' } & Pick<Worker, 'trade' | 'workerId'> & {
                      profilePicture?: Maybe<
                        { __typename?: 'File' } & Pick<File, 'downloadUrl' | 'uploadedAt' | 'fileId'>
                      >;
                      profilePictureCropped?: Maybe<
                        { __typename?: 'File' } & Pick<File, 'downloadUrl' | 'uploadedAt' | 'fileId' | 'fileType'>
                      >;
                      user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
                          identity?: Maybe<
                            { __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName'>
                          >;
                        };
                    };
                  contractor: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId'> & {
                      organization: { __typename?: 'Organization' } & Pick<Organization, 'name'>;
                    };
                };
              };
          }
      >;
    };
};

export type UpdateOnboardingJobsiteWorkerMutationVariables = Exact<{
  jobsiteWorkerId: Scalars['ID']['input'];
  jobsiteWorkerInput: JobsiteWorkerInput;
}>;

export type UpdateOnboardingJobsiteWorkerMutation = { __typename?: 'Mutation' } & {
  updateJobsiteWorker: { __typename?: 'JobsiteWorker' } & Pick<JobsiteWorker, 'orientationDate'>;
};

export type GetSiteSafetyOrientationJobsiteWorkersQueryVariables = Exact<{
  userContractorsInput?: InputMaybe<UserContractorsInput>;
  jobsiteIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  contractorIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<GetJobsiteWorkersOrderBy>> | InputMaybe<GetJobsiteWorkersOrderBy>>;
  activeOnly?: InputMaybe<Scalars['Boolean']['input']>;
  orientationStartDate?: InputMaybe<Scalars['Date']['input']>;
  orientationEndDate?: InputMaybe<Scalars['Date']['input']>;
  withContractorOnly?: InputMaybe<Scalars['Boolean']['input']>;
  orientationStatus?: InputMaybe<JobsiteWorkerOrientationStatusFilter>;
  needsMedical?: InputMaybe<Scalars['Boolean']['input']>;
  hideBanned?: InputMaybe<Scalars['Boolean']['input']>;
  completedSiteSpecificTraining?: InputMaybe<Scalars['Boolean']['input']>;
  completedSiteSpecificTrainingDate?: InputMaybe<Scalars['Date']['input']>;
  siteSpecificOrientationStatus?: InputMaybe<SiteSpecificOrientationStatus>;
}>;

export type GetSiteSafetyOrientationJobsiteWorkersQuery = { __typename?: 'Query' } & {
  getCurrentSession?: Maybe<
    { __typename?: 'Auth' } & {
      user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
          contractors: { __typename?: 'UserContractorsConnection' } & Pick<UserContractorsConnection, 'count'> & {
              edges: Array<
                { __typename?: 'UserContractorsEdge' } & {
                  node: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId'> & {
                      organization: { __typename?: 'Organization' } & Pick<Organization, 'name'>;
                    };
                }
              >;
            };
        };
    }
  >;
  getJobsiteWorkers: { __typename?: 'JobsiteWorkerConnection' } & Pick<JobsiteWorkerConnection, 'totalCount'> & {
      edges: Array<
        { __typename?: 'JobsiteWorkerAccess' } & Pick<JobsiteWorkerAccess, 'endDate' | 'isAllowedNow'> & {
            jobsiteWorker: { __typename?: 'JobsiteWorker' } & Pick<
              JobsiteWorker,
              | 'jobsiteWorkerId'
              | 'orientationStatus'
              | 'orientationDate'
              | 'medicalExamSkipReason'
              | 'passedMedicalExamAt'
              | 'documentsCompletedAt'
              | 'documentsCompletedSkipReason'
              | 'badgingCompletedAt'
              | 'badgingSkipReason'
              | 'signatureCompletedAt'
              | 'signatureSkipReason'
              | 'onboardingSignedAt'
              | 'onboardingSkipReason'
              | 'profileCompletedAt'
              | 'profileCompleteSkipReason'
              | 'siteSpecificTrainingCompletedAt'
              | 'isSiteSpecificTrainingExpired'
              | 'bannedReason'
            > & {
                jobsiteContractor: { __typename?: 'JobsiteContractor' } & {
                  jobsite: { __typename?: 'Jobsite' } & Pick<Jobsite, 'name'>;
                };
                contractorWorker: { __typename?: 'ContractorWorker' } & {
                  worker: { __typename?: 'Worker' } & Pick<Worker, 'trade' | 'workerId' | 'jobTitle'> & {
                      profilePicture?: Maybe<
                        { __typename?: 'File' } & Pick<File, 'downloadUrl' | 'uploadedAt' | 'fileId'>
                      >;
                      profilePictureCropped?: Maybe<
                        { __typename?: 'File' } & Pick<File, 'downloadUrl' | 'uploadedAt' | 'fileId' | 'fileType'>
                      >;
                      user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
                          identity?: Maybe<
                            { __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName'>
                          >;
                        };
                    };
                  contractor: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId'> & {
                      organization: { __typename?: 'Organization' } & Pick<Organization, 'name'>;
                    };
                };
              };
          }
      >;
    };
};

export type GetWorkersContainerCurrentSessionQueryVariables = Exact<{
  userWorkersInput?: InputMaybe<UserWorkersInput>;
  userContractorsInput?: InputMaybe<UserContractorsInput>;
}>;

export type GetWorkersContainerCurrentSessionQuery = { __typename?: 'Query' } & {
  getCurrentSession?: Maybe<
    { __typename?: 'Auth' } & {
      user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
          workers: { __typename?: 'UserWorkersConnection' } & Pick<UserWorkersConnection, 'count'> & {
              edges: Array<
                { __typename?: 'UserWorkersEdge' } & {
                  node: { __typename?: 'Worker' } & Pick<
                    Worker,
                    'workerId' | 'birthDate' | 'quickCode' | 'trade' | 'jobTitle'
                  > & {
                      user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
                          identity?: Maybe<
                            { __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName' | 'email'>
                          >;
                        };
                      profilePictureCropped?: Maybe<
                        { __typename?: 'File' } & Pick<File, 'downloadUrl' | 'uploadedAt' | 'fileId' | 'fileType'>
                      >;
                      jobsiteWorkers: { __typename?: 'WorkerJobsiteWorkersConnection' } & {
                        edges: Array<
                          { __typename?: 'WorkerJobsiteWorkersEdge' } & {
                            node: { __typename?: 'JobsiteWorker' } & Pick<JobsiteWorker, 'jobsiteWorkerId'> & {
                                jobsiteContractor: { __typename?: 'JobsiteContractor' } & {
                                  jobsite: { __typename?: 'Jobsite' } & Pick<Jobsite, 'jobsiteId' | 'name'>;
                                  contractor: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId'> & {
                                      organization: { __typename?: 'Organization' } & Pick<Organization, 'name'>;
                                    };
                                };
                                currentAccess: { __typename?: 'JobsiteWorkerCurrentAccess' } & Pick<
                                  JobsiteWorkerCurrentAccess,
                                  'isAllowed'
                                >;
                              };
                          }
                        >;
                      };
                    };
                }
              >;
              filtersOptions?: Maybe<
                { __typename?: 'UserWorkersFilters' } & {
                  trades: Array<{ __typename?: 'FilterOption' } & Pick<FilterOption, 'value' | 'label' | 'count'>>;
                  tradeClasses: Array<
                    { __typename?: 'FilterOption' } & Pick<FilterOption, 'value' | 'label' | 'count'>
                  >;
                }
              >;
            };
          contractors: { __typename?: 'UserContractorsConnection' } & {
            edges: Array<
              { __typename?: 'UserContractorsEdge' } & {
                node: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId'> & {
                    organization: { __typename?: 'Organization' } & Pick<Organization, 'name'>;
                  };
              }
            >;
          };
        };
    }
  >;
};

export type AddAndIssueFacialRecognitionBadgeMutationVariables = Exact<{
  workerId: Scalars['ID']['input'];
  jobsiteId: Scalars['ID']['input'];
  workerCardInput: WorkerCardInput;
  newPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  newEmail?: InputMaybe<Scalars['String']['input']>;
}>;

export type AddAndIssueFacialRecognitionBadgeMutation = { __typename?: 'Mutation' } & {
  addAndIssueFacialRecognitionBadge?: Maybe<
    { __typename?: 'WorkerCard' } & Pick<WorkerCard, 'workerCardId' | 'cardNumber'>
  >;
};

export type AddAndIssueMobileBadgeMutationVariables = Exact<{
  workerId: Scalars['ID']['input'];
  jobsiteId: Scalars['ID']['input'];
  workerCardInput: WorkerCardInput;
  newPhoneNumber?: InputMaybe<Scalars['String']['input']>;
}>;

export type AddAndIssueMobileBadgeMutation = { __typename?: 'Mutation' } & {
  addAndIssueMobileBadge: { __typename?: 'WorkerCard' } & Pick<WorkerCard, 'workerCardId' | 'cardNumber'>;
};

export type AddCardToWorkerMutationVariables = Exact<{
  workerId: Scalars['ID']['input'];
  jobsiteId: Scalars['ID']['input'];
  workerCardInput: WorkerCardInput;
  forceCreate?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type AddCardToWorkerMutation = { __typename?: 'Mutation' } & {
  addCardToWorker: { __typename?: 'WorkerCard' } & Pick<
    WorkerCard,
    'workerCardId' | 'cardNumber' | 'matchingWorkerCardId' | 'cardStatus'
  > & { worker: { __typename?: 'Worker' } & Pick<Worker, 'workerId'> };
};

export type AssignContractorWorkerToJobsiteMutationVariables = Exact<{
  input: AssignContractorWorkerToJobsiteInput;
}>;

export type AssignContractorWorkerToJobsiteMutation = { __typename?: 'Mutation' } & {
  assignContractorWorkerToJobsite: { __typename?: 'AssignContractorWorkerToJobsiteResponse' } & Pick<
    AssignContractorWorkerToJobsiteResponse,
    'isAlreadyAssigned'
  > & {
      jobsiteWorker: { __typename?: 'JobsiteWorker' } & Pick<JobsiteWorker, 'jobsiteWorkerId' | 'orientationStatus'> & {
          contractorWorker: { __typename?: 'ContractorWorker' } & Pick<ContractorWorker, 'id'> & {
              worker: { __typename?: 'Worker' } & Pick<Worker, 'workerId' | 'isAssignedToMultipleJobsites'>;
            };
          jobsiteContractor: { __typename?: 'JobsiteContractor' } & Pick<JobsiteContractor, 'id'> & {
              contractor: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId' | 'isDefaultContractor'> & {
                  organization: { __typename?: 'Organization' } & Pick<Organization, 'organizationId' | 'name'>;
                };
            };
        };
    };
};

export type CreateCardPrintJobMutationVariables = Exact<{
  workerId: Scalars['ID']['input'];
  jobsiteId: Scalars['ID']['input'];
  cardTemplateName?: InputMaybe<Scalars['String']['input']>;
  trade?: InputMaybe<Scalars['String']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
}>;

export type CreateCardPrintJobMutation = { __typename?: 'Mutation' } & {
  createCardPrintJob: { __typename?: 'CardPrintJob' } & Pick<
    CardPrintJob,
    'cardPrintJobId' | 'status' | 'statusMessage' | 'startedAt'
  > & {
      completion?: Maybe<
        { __typename?: 'CardPrintJobCompletion' } & Pick<
          CardPrintJobCompletion,
          'cardNumber' | 'facilityCode' | 'completedAt'
        >
      >;
      jobsite: { __typename?: 'Jobsite' } & Pick<Jobsite, 'jobsiteId' | 'name'>;
      worker: { __typename?: 'Worker' } & Pick<Worker, 'workerId'> & {
          user: { __typename?: 'User' } & {
            identity?: Maybe<{ __typename?: 'UserIdentity' } & Pick<UserIdentity, 'firstName' | 'lastName'>>;
          };
        };
    };
};

export type CreateJobsiteWorkerDocumentMutationVariables = Exact<{
  jobsiteWorkerDocumentInput: JobsiteWorkerDocumentInput;
  jobsiteWorkerDocumentVersionInput: JobsiteWorkerDocumentVersionInput;
}>;

export type CreateJobsiteWorkerDocumentMutation = { __typename?: 'Mutation' } & {
  createJobsiteWorkerDocument: { __typename?: 'JobsiteWorkerDocument' } & {
    jobsiteWorkerDocumentType: { __typename?: 'JobsiteWorkerDocumentType' } & {
      workerDocumentType: { __typename?: 'WorkerDocumentType' } & Pick<WorkerDocumentType, 'workerDocumentTypeId'>;
    };
  };
};

export type CreateJobsiteWorkerDocumentVersionMutationVariables = Exact<{
  jobsiteWorkerDocumentId: Scalars['ID']['input'];
  jobsiteWorkerDocumentVersionInput: JobsiteWorkerDocumentVersionInput;
}>;

export type CreateJobsiteWorkerDocumentVersionMutation = { __typename?: 'Mutation' } & {
  createJobsiteWorkerDocumentVersion: { __typename?: 'JobsiteWorkerDocumentVersion' } & Pick<
    JobsiteWorkerDocumentVersion,
    'jobsiteWorkerDocumentVersionId'
  >;
};

export type JobsiteArchiveInvitationMutationVariables = Exact<{
  input: JobsiteArchiveInvitationInput;
}>;

export type JobsiteArchiveInvitationMutation = { __typename?: 'Mutation' } & {
  jobsiteArchiveInvitation: { __typename?: 'JobsiteArchiveInvitationPayload' } & {
    success: { __typename?: 'Success' } & Pick<Success, 'success'>;
  };
};

export type JobsiteCreateInvitationMutationVariables = Exact<{
  input: JobsiteCreateInvitationInput;
}>;

export type JobsiteCreateInvitationMutation = { __typename?: 'Mutation' } & {
  jobsiteCreateInvitation: { __typename?: 'JobsiteCreateInvitationPayload' } & {
    jobsiteInvitation: { __typename?: 'JobsiteInvitation' } & Pick<
      JobsiteInvitation,
      'jobsiteInvitationId' | 'expirationDate'
    >;
  };
};

export type ReissueMobileBadgeMutationVariables = Exact<{
  workerCardId: Scalars['ID']['input'];
  newPhoneNumber?: InputMaybe<Scalars['String']['input']>;
}>;

export type ReissueMobileBadgeMutation = { __typename?: 'Mutation' } & {
  reissueMobileBadge: { __typename?: 'WorkerCard' } & Pick<WorkerCard, 'workerCardId' | 'cardNumber'>;
};

export type SendPasswordResetEmailMutationVariables = Exact<{
  email: Scalars['String']['input'];
  sendForgotPasswordEmail?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type SendPasswordResetEmailMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'sendPasswordResetEmail'>;

export type UpdateJobsiteWorkerDocumentVersionMutationVariables = Exact<{
  jobsiteWorkerDocumentVersionId: Scalars['ID']['input'];
  jobsiteWorkerDocumentVersionInput: JobsiteWorkerDocumentVersionInput;
}>;

export type UpdateJobsiteWorkerDocumentVersionMutation = { __typename?: 'Mutation' } & {
  updateJobsiteWorkerDocumentVersion: { __typename?: 'JobsiteWorkerDocumentVersion' } & Pick<
    JobsiteWorkerDocumentVersion,
    'jobsiteWorkerDocumentVersionId'
  >;
};

export type UploadSingleFileMutationVariables = Exact<{
  fileInput: FileInput;
}>;

export type UploadSingleFileMutation = { __typename?: 'Mutation' } & {
  uploadSingleFile: { __typename?: 'File' } & Pick<File, 'fileId' | 'fileType' | 'downloadUrl'>;
};

export type GetContractorsQueryVariables = Exact<{
  userContractorsInput?: InputMaybe<UserContractorsInput>;
}>;

export type GetContractorsQuery = { __typename?: 'Query' } & {
  getCurrentSession?: Maybe<
    { __typename?: 'Auth' } & {
      user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
          contractors: { __typename?: 'UserContractorsConnection' } & {
            edges: Array<
              { __typename?: 'UserContractorsEdge' } & {
                node: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId'> & {
                    organization: { __typename?: 'Organization' } & Pick<Organization, 'name'>;
                  };
              }
            >;
          };
        };
    }
  >;
};

export type GetDevelopersQueryVariables = Exact<{ [key: string]: never }>;

export type GetDevelopersQuery = { __typename?: 'Query' } & {
  getCurrentSession?: Maybe<
    { __typename?: 'Auth' } & {
      user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
          developers: { __typename?: 'UserDevelopersConnection' } & Pick<UserDevelopersConnection, 'count'> & {
              edges: Array<
                { __typename?: 'UserDevelopersEdge' } & {
                  node: { __typename?: 'Developer' } & Pick<Developer, 'developerId'> & {
                      organization: { __typename?: 'Organization' } & Pick<Organization, 'organizationId' | 'name'>;
                    };
                }
              >;
            };
        };
    }
  >;
};

export type GetJobsiteCardFormatsQueryVariables = Exact<{
  jobsiteId: Scalars['ID']['input'];
  cardType?: InputMaybe<WorkerCardType>;
  isDefaultForCardType?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetJobsiteCardFormatsQuery = { __typename?: 'Query' } & {
  getJobsiteCardFormats: Array<
    { __typename?: 'JobsiteCardFormat' } & Pick<JobsiteCardFormat, 'jobsiteId' | 'isDefaultForCardType'> & {
        workerCardFormat: { __typename?: 'WorkerCardFormat' } & Pick<
          WorkerCardFormat,
          'workerCardFormatId' | 'name' | 'description' | 'cardType' | 'facilityCode'
        >;
      }
  >;
};

export type GetJobsiteInvitationQueryVariables = Exact<{
  jobsiteInvitationId: Scalars['ID']['input'];
}>;

export type GetJobsiteInvitationQuery = { __typename?: 'Query' } & {
  getJobsiteInvitation: { __typename?: 'JobsiteInvitation' } & Pick<JobsiteInvitation, 'jobsiteInvitationId'> & {
      jobsiteContractor: { __typename?: 'JobsiteContractor' } & {
        jobsite: { __typename?: 'Jobsite' } & Pick<Jobsite, 'jobsiteId' | 'name'> & {
            jobsiteCardFormats: { __typename?: 'JobsiteCardFormatsConnection' } & Pick<
              JobsiteCardFormatsConnection,
              'count'
            > & {
                edges: Array<
                  { __typename?: 'JobsiteCardFormatsEdge' } & {
                    node: { __typename?: 'JobsiteCardFormat' } & {
                      workerCardFormat: { __typename?: 'WorkerCardFormat' } & Pick<
                        WorkerCardFormat,
                        'cardType' | 'facilityCode' | 'name' | 'workerCardFormatId'
                      >;
                    };
                  }
                >;
              };
            modules?: Maybe<
              Array<
                | { __typename?: 'JobsiteAppCheckInModule' }
                | ({ __typename?: 'JobsiteFeaturesModule' } & Pick<
                    JobsiteFeaturesModule,
                    | 'name'
                    | 'slug'
                    | 'workerAppEnabled'
                    | 'doNotAllowToSkipSelfOnboardingSteps'
                    | 'enforceValidContractorInSelfOnboarding'
                  >)
                | ({ __typename?: 'JobsiteOnboardingModule' } & Pick<JobsiteOnboardingModule, 'name'> & {
                      steps?: Maybe<
                        Array<
                          { __typename?: 'JobsiteOnboardingStep' } & Pick<
                            JobsiteOnboardingStep,
                            'key' | 'index' | 'isRequired' | 'isVisible'
                          > & {
                              fields?: Maybe<
                                Array<
                                  { __typename?: 'JobsiteOnboardingField' } & Pick<
                                    JobsiteOnboardingField,
                                    'key' | 'name' | 'index' | 'isRequired' | 'isHidden'
                                  >
                                >
                              >;
                            }
                        >
                      >;
                      documents?: Maybe<
                        Array<
                          { __typename?: 'JobsiteOnboardingDocument' } & Pick<
                            JobsiteOnboardingDocument,
                            'key' | 'name' | 'index' | 'isRequired' | 'isVisible' | 'expiration' | 'gracePeriod'
                          > & {
                              fields?: Maybe<
                                Array<
                                  { __typename?: 'JobsiteOnboardingField' } & Pick<
                                    JobsiteOnboardingField,
                                    'key' | 'name' | 'index' | 'isRequired' | 'isHidden'
                                  >
                                >
                              >;
                            }
                        >
                      >;
                    })
                | ({ __typename?: 'JobsitePreOnboardingModule' } & Pick<JobsitePreOnboardingModule, 'name'> & {
                      steps?: Maybe<
                        Array<
                          { __typename?: 'JobsitePreOnboardingStep' } & Pick<
                            JobsitePreOnboardingStep,
                            'key' | 'name' | 'index' | 'isRequired' | 'isVisible'
                          > & {
                              fields?: Maybe<
                                Array<
                                  { __typename?: 'JobsitePreOnboardingField' } & Pick<
                                    JobsitePreOnboardingField,
                                    'key' | 'name' | 'index' | 'isRequired' | 'isHidden'
                                  >
                                >
                              >;
                            }
                        >
                      >;
                    })
              >
            >;
            documentTypes: { __typename?: 'JobsiteWorkerDocumentTypesConnection' } & {
              edges: Array<
                { __typename?: 'JobsiteWorkerDocumentTypesEdge' } & {
                  node: { __typename?: 'JobsiteWorkerDocumentType' } & Pick<JobsiteWorkerDocumentType, 'id'> & {
                      workerDocumentType: { __typename?: 'WorkerDocumentType' } & Pick<
                        WorkerDocumentType,
                        'workerDocumentTypeId' | 'key'
                      >;
                      files: Array<
                        { __typename?: 'File' } & Pick<
                          File,
                          'fileId' | 'fileType' | 'downloadUrl' | 'language' | 'title'
                        >
                      >;
                    };
                }
              >;
            };
            jobsiteContractors: { __typename?: 'JobsiteJobsiteContractorsConnection' } & {
              edges: Array<
                { __typename?: 'JobsiteJobsiteContractorsEdge' } & {
                  node: { __typename?: 'JobsiteContractor' } & Pick<JobsiteContractor, 'id' | 'startDate'> & {
                      contractor: { __typename?: 'Contractor' } & Pick<
                        Contractor,
                        'contractorId' | 'isDefaultContractor'
                      > & {
                          organization: { __typename?: 'Organization' } & Pick<Organization, 'organizationId' | 'name'>;
                        };
                    };
                }
              >;
            };
          };
        contractor: { __typename?: 'Contractor' } & Pick<Contractor, 'contractorId' | 'isDefaultContractor'> & {
            organization: { __typename?: 'Organization' } & Pick<Organization, 'organizationId' | 'name'>;
          };
      };
    };
};

export type GetJobsiteWithBadgeTemplatesQueryVariables = Exact<{
  jobsiteId: Scalars['ID']['input'];
}>;

export type GetJobsiteWithBadgeTemplatesQuery = { __typename?: 'Query' } & {
  getJobsite: { __typename?: 'Jobsite' } & {
    jobsiteBadgeTemplates: { __typename?: 'JobsiteBadgeTemplatesConnection' } & {
      edges: Array<
        { __typename?: 'JobsiteBadgeTemplatesEdge' } & {
          node: { __typename?: 'JobsiteBadgeTemplate' } & Pick<JobsiteBadgeTemplate, 'id' | 'isDefault'> & {
              badgeTemplate: { __typename?: 'BadgeTemplate' } & Pick<
                BadgeTemplate,
                'id' | 'templateName' | 'displayName'
              >;
            };
        }
      >;
    };
  };
};

export type GetJobsitesQueryVariables = Exact<{
  userJobsitesInput: UserJobsitesInput;
}>;

export type GetJobsitesQuery = { __typename?: 'Query' } & {
  getCurrentSession?: Maybe<
    { __typename?: 'Auth' } & {
      user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
          roles: Array<{ __typename?: 'UserRole' } & Pick<UserRole, 'key' | 'displayName'>>;
          jobsites: { __typename?: 'UserJobsitesConnection' } & {
            edges: Array<
              { __typename?: 'UserJobsitesEdge' } & {
                node: { __typename?: 'Jobsite' } & Pick<
                  Jobsite,
                  | 'jobsiteId'
                  | 'name'
                  | 'parentId'
                  | 'startDate'
                  | 'workerAccessEnabled'
                  | 'avigilonEnabled'
                  | 'clearanceAssignmentEnabled'
                  | 'timeZone'
                > & {
                    featuresModule?: Maybe<
                      { __typename?: 'JobsiteFeaturesModule' } & Pick<
                        JobsiteFeaturesModule,
                        'announcementsEnabled' | 'archivingAccessEventsEnabled'
                      >
                    >;
                    developer?: Maybe<{ __typename?: 'Developer' } & Pick<Developer, 'developerId' | 'name'>>;
                    jobsiteCardFormats: { __typename?: 'JobsiteCardFormatsConnection' } & {
                      edges: Array<
                        { __typename?: 'JobsiteCardFormatsEdge' } & {
                          node: { __typename?: 'JobsiteCardFormat' } & Pick<
                            JobsiteCardFormat,
                            'jobsiteCardFormatId' | 'isDefaultForCardType'
                          > & {
                              workerCardFormat: { __typename?: 'WorkerCardFormat' } & Pick<
                                WorkerCardFormat,
                                'workerCardFormatId' | 'name' | 'description' | 'cardType' | 'facilityCode'
                              >;
                            };
                        }
                      >;
                    };
                  };
              }
            >;
          };
        };
    }
  >;
};

export type GetRolesQueryVariables = Exact<{ [key: string]: never }>;

export type GetRolesQuery = { __typename?: 'Query' } & {
  getRoles: Array<{ __typename?: 'Role' } & Pick<Role, 'key' | 'displayName' | 'isSuper' | 'createdAt' | 'updatedAt'>>;
};

export type GetWorkerCardQueryVariables = Exact<{
  workerCardId?: InputMaybe<Scalars['ID']['input']>;
  cardNumber?: InputMaybe<Scalars['String']['input']>;
  cardType?: InputMaybe<WorkerCardType>;
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetWorkerCardQuery = { __typename?: 'Query' } & {
  getWorkerCard: { __typename?: 'WorkerCard' } & Pick<WorkerCard, 'workerCardId' | 'cardNumber'> & {
      worker: { __typename?: 'Worker' } & Pick<Worker, 'workerId'> & {
          user: { __typename?: 'User' } & {
            identity?: Maybe<{ __typename?: 'UserIdentity' } & Pick<UserIdentity, 'phoneNumber'>>;
          };
        };
      workerCardFormat: { __typename?: 'WorkerCardFormat' } & Pick<
        WorkerCardFormat,
        'name' | 'workerCardFormatId' | 'cardType' | 'facilityCode'
      >;
    };
};

export type GetWorkerCardsQueryVariables = Exact<{
  workerId: Scalars['ID']['input'];
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetWorkerCardsQuery = { __typename?: 'Query' } & {
  getWorkerCards: Array<
    { __typename?: 'WorkerCard' } & Pick<
      WorkerCard,
      'workerCardId' | 'cardNumber' | 'matchingWorkerCardId' | 'cardStatus' | 'isTemporary' | 'expiredAt' | 'issuedAt'
    > & {
        worker: { __typename?: 'Worker' } & Pick<Worker, 'workerId'> & {
            user: { __typename?: 'User' } & {
              identity?: Maybe<{ __typename?: 'UserIdentity' } & Pick<UserIdentity, 'phoneNumber'>>;
            };
          };
        workerCardFormat: { __typename?: 'WorkerCardFormat' } & Pick<
          WorkerCardFormat,
          'name' | 'workerCardFormatId' | 'cardType' | 'facilityCode'
        >;
      }
  >;
};

export type GetAppLayoutCurrentSessionQueryVariables = Exact<{ [key: string]: never }>;

export type GetAppLayoutCurrentSessionQuery = { __typename?: 'Query' } & {
  getCurrentSession?: Maybe<
    { __typename?: 'Auth' } & {
      user: { __typename?: 'User' } & Pick<User, 'userAccountId'> & {
          roles: Array<{ __typename?: 'UserRole' } & Pick<UserRole, 'key'>>;
        };
      session: { __typename?: 'UserSession' } & Pick<UserSession, 'urlLock' | 'appVersion'>;
    }
  >;
};

export type UpdateSessionAppVersionMutationVariables = Exact<{
  appVersion: Scalars['String']['input'];
}>;

export type UpdateSessionAppVersionMutation = { __typename?: 'Mutation' } & {
  updateSessionAppVersion: { __typename?: 'Auth' } & {
    session: { __typename?: 'UserSession' } & Pick<
      UserSession,
      'authType' | 'createdAt' | 'updatedAt' | 'lastActiveAt' | 'appVersion'
    >;
  };
};

export const TrackIntegrationHandoffDocument = gql`
  mutation TrackIntegrationHandoff($integrationType: ExternalIntegrationType!) {
    trackIntegrationHandoff(integrationType: $integrationType) {
      integrationType
      url
    }
  }
`;
export type TrackIntegrationHandoffMutationFn = Apollo.MutationFunction<
  TrackIntegrationHandoffMutation,
  TrackIntegrationHandoffMutationVariables
>;

/**
 * __useTrackIntegrationHandoffMutation__
 *
 * To run a mutation, you first call `useTrackIntegrationHandoffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackIntegrationHandoffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackIntegrationHandoffMutation, { data, loading, error }] = useTrackIntegrationHandoffMutation({
 *   variables: {
 *      integrationType: // value for 'integrationType'
 *   },
 * });
 */
export function useTrackIntegrationHandoffMutation(
  baseOptions?: Apollo.MutationHookOptions<TrackIntegrationHandoffMutation, TrackIntegrationHandoffMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TrackIntegrationHandoffMutation, TrackIntegrationHandoffMutationVariables>(
    TrackIntegrationHandoffDocument,
    options,
  );
}
export type TrackIntegrationHandoffMutationHookResult = ReturnType<typeof useTrackIntegrationHandoffMutation>;
export type TrackIntegrationHandoffMutationResult = Apollo.MutationResult<TrackIntegrationHandoffMutation>;
export type TrackIntegrationHandoffMutationOptions = Apollo.BaseMutationOptions<
  TrackIntegrationHandoffMutation,
  TrackIntegrationHandoffMutationVariables
>;
export const TrackSessionDocument = gql`
  mutation TrackSession($sessionType: SessionType!) {
    trackSession(sessionType: $sessionType) {
      success
    }
  }
`;
export type TrackSessionMutationFn = Apollo.MutationFunction<TrackSessionMutation, TrackSessionMutationVariables>;

/**
 * __useTrackSessionMutation__
 *
 * To run a mutation, you first call `useTrackSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackSessionMutation, { data, loading, error }] = useTrackSessionMutation({
 *   variables: {
 *      sessionType: // value for 'sessionType'
 *   },
 * });
 */
export function useTrackSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<TrackSessionMutation, TrackSessionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TrackSessionMutation, TrackSessionMutationVariables>(TrackSessionDocument, options);
}
export type TrackSessionMutationHookResult = ReturnType<typeof useTrackSessionMutation>;
export type TrackSessionMutationResult = Apollo.MutationResult<TrackSessionMutation>;
export type TrackSessionMutationOptions = Apollo.BaseMutationOptions<
  TrackSessionMutation,
  TrackSessionMutationVariables
>;
export const GetCurrentSessionDocument = gql`
  query GetCurrentSession($includeComputedRoles: Boolean) {
    getCurrentSession(includeComputedRoles: $includeComputedRoles) {
      user {
        userAccountId
        createdAt
        hasUrlLockCode
        identity {
          email
          firstName
          lastName
          createdAt
          updatedAt
        }
        hasPaidAccess
        hasAnnouncementsEnabled
        hasFormsEnabled
        hasCamerasEnabled
        roles {
          key
          object {
            objectName
            objectId
          }
        }
        profilePictureCropped {
          downloadUrl
          fileId
          fileType
        }
        worker {
          workerId
        }
      }
      session {
        sessionToken
        authType
        createdAt
        updatedAt
        lastActiveAt
        urlLock
        appVersion
      }
    }
  }
`;

/**
 * __useGetCurrentSessionQuery__
 *
 * To run a query within a React component, call `useGetCurrentSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentSessionQuery({
 *   variables: {
 *      includeComputedRoles: // value for 'includeComputedRoles'
 *   },
 * });
 */
export function useGetCurrentSessionQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCurrentSessionQuery, GetCurrentSessionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentSessionQuery, GetCurrentSessionQueryVariables>(GetCurrentSessionDocument, options);
}
export function useGetCurrentSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentSessionQuery, GetCurrentSessionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentSessionQuery, GetCurrentSessionQueryVariables>(
    GetCurrentSessionDocument,
    options,
  );
}
export function useGetCurrentSessionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetCurrentSessionQuery, GetCurrentSessionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCurrentSessionQuery, GetCurrentSessionQueryVariables>(
    GetCurrentSessionDocument,
    options,
  );
}
export type GetCurrentSessionQueryHookResult = ReturnType<typeof useGetCurrentSessionQuery>;
export type GetCurrentSessionLazyQueryHookResult = ReturnType<typeof useGetCurrentSessionLazyQuery>;
export type GetCurrentSessionSuspenseQueryHookResult = ReturnType<typeof useGetCurrentSessionSuspenseQuery>;
export type GetCurrentSessionQueryResult = Apollo.QueryResult<GetCurrentSessionQuery, GetCurrentSessionQueryVariables>;
export const SearchWorkersFromSearchBoxDocument = gql`
  query SearchWorkersFromSearchBox($searchString: String!, $limit: Int, $offset: Int) {
    searchWorkers(searchString: $searchString, limit: $limit, offset: $offset) {
      count
      results {
        worker {
          workerId
          birthDate
          quickCode
          trade
          jobTitle
          unionAffiliation
          user {
            userAccountId
            identity {
              firstName
              lastName
              email
              phoneNumber
            }
          }
          profilePicture {
            downloadUrl
          }
          profilePictureCropped {
            downloadUrl
          }
          contractorWorkers {
            edges {
              node {
                contractor {
                  contractorId
                  organization {
                    name
                    slug
                  }
                }
                jobsiteWorkers {
                  edges {
                    node {
                      jobsiteWorkerId
                      stickerNumber
                      currentAccess {
                        isAllowed
                      }
                      jobsiteContractor {
                        jobsite {
                          name
                          jobsiteId
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSearchWorkersFromSearchBoxQuery__
 *
 * To run a query within a React component, call `useSearchWorkersFromSearchBoxQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchWorkersFromSearchBoxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchWorkersFromSearchBoxQuery({
 *   variables: {
 *      searchString: // value for 'searchString'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useSearchWorkersFromSearchBoxQuery(
  baseOptions: Apollo.QueryHookOptions<SearchWorkersFromSearchBoxQuery, SearchWorkersFromSearchBoxQueryVariables> &
    ({ variables: SearchWorkersFromSearchBoxQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchWorkersFromSearchBoxQuery, SearchWorkersFromSearchBoxQueryVariables>(
    SearchWorkersFromSearchBoxDocument,
    options,
  );
}
export function useSearchWorkersFromSearchBoxLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchWorkersFromSearchBoxQuery, SearchWorkersFromSearchBoxQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchWorkersFromSearchBoxQuery, SearchWorkersFromSearchBoxQueryVariables>(
    SearchWorkersFromSearchBoxDocument,
    options,
  );
}
export function useSearchWorkersFromSearchBoxSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SearchWorkersFromSearchBoxQuery,
    SearchWorkersFromSearchBoxQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SearchWorkersFromSearchBoxQuery, SearchWorkersFromSearchBoxQueryVariables>(
    SearchWorkersFromSearchBoxDocument,
    options,
  );
}
export type SearchWorkersFromSearchBoxQueryHookResult = ReturnType<typeof useSearchWorkersFromSearchBoxQuery>;
export type SearchWorkersFromSearchBoxLazyQueryHookResult = ReturnType<typeof useSearchWorkersFromSearchBoxLazyQuery>;
export type SearchWorkersFromSearchBoxSuspenseQueryHookResult = ReturnType<
  typeof useSearchWorkersFromSearchBoxSuspenseQuery
>;
export type SearchWorkersFromSearchBoxQueryResult = Apollo.QueryResult<
  SearchWorkersFromSearchBoxQuery,
  SearchWorkersFromSearchBoxQueryVariables
>;
export const GetJobsiteAnnouncementDocument = gql`
  query GetJobsiteAnnouncement($announcementId: ID!) {
    getJobsiteAnnouncement(jobsiteAnnouncementId: $announcementId) {
      announcementType
      jobsiteAnnouncementId
      subject
      status
      message
      sentAt
      startAt
      endAt
      timeZone
      jobsites {
        count
        edges {
          node {
            jobsiteId
            name
          }
        }
      }
      contractors {
        count
        edges {
          node {
            contractorId
            organization {
              name
            }
          }
        }
      }
      jobsiteAccessStatus
      accessWithinDayRange
      trades
      titles
      languages
      sentBy {
        profilePictureCropped {
          downloadUrl
        }
        identity {
          firstName
          lastName
        }
      }
    }
  }
`;

/**
 * __useGetJobsiteAnnouncementQuery__
 *
 * To run a query within a React component, call `useGetJobsiteAnnouncementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobsiteAnnouncementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobsiteAnnouncementQuery({
 *   variables: {
 *      announcementId: // value for 'announcementId'
 *   },
 * });
 */
export function useGetJobsiteAnnouncementQuery(
  baseOptions: Apollo.QueryHookOptions<GetJobsiteAnnouncementQuery, GetJobsiteAnnouncementQueryVariables> &
    ({ variables: GetJobsiteAnnouncementQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetJobsiteAnnouncementQuery, GetJobsiteAnnouncementQueryVariables>(
    GetJobsiteAnnouncementDocument,
    options,
  );
}
export function useGetJobsiteAnnouncementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetJobsiteAnnouncementQuery, GetJobsiteAnnouncementQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetJobsiteAnnouncementQuery, GetJobsiteAnnouncementQueryVariables>(
    GetJobsiteAnnouncementDocument,
    options,
  );
}
export function useGetJobsiteAnnouncementSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetJobsiteAnnouncementQuery, GetJobsiteAnnouncementQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetJobsiteAnnouncementQuery, GetJobsiteAnnouncementQueryVariables>(
    GetJobsiteAnnouncementDocument,
    options,
  );
}
export type GetJobsiteAnnouncementQueryHookResult = ReturnType<typeof useGetJobsiteAnnouncementQuery>;
export type GetJobsiteAnnouncementLazyQueryHookResult = ReturnType<typeof useGetJobsiteAnnouncementLazyQuery>;
export type GetJobsiteAnnouncementSuspenseQueryHookResult = ReturnType<typeof useGetJobsiteAnnouncementSuspenseQuery>;
export type GetJobsiteAnnouncementQueryResult = Apollo.QueryResult<
  GetJobsiteAnnouncementQuery,
  GetJobsiteAnnouncementQueryVariables
>;
export const CreateJobsiteAnnouncementDocument = gql`
  mutation CreateJobsiteAnnouncement($jobsiteAnnouncementInput: JobsiteAnnouncementInput!) {
    createJobsiteAnnouncement(jobsiteAnnouncementInput: $jobsiteAnnouncementInput) {
      announcementType
      jobsiteAnnouncementId
      subject
      status
      message
      sentAt
      startAt
      endAt
      timeZone
      jobsites {
        count
        edges {
          node {
            jobsiteId
            name
          }
        }
      }
      jobsiteAccessStatus
      accessWithinDayRange
      trades
      titles
      languages
    }
  }
`;
export type CreateJobsiteAnnouncementMutationFn = Apollo.MutationFunction<
  CreateJobsiteAnnouncementMutation,
  CreateJobsiteAnnouncementMutationVariables
>;

/**
 * __useCreateJobsiteAnnouncementMutation__
 *
 * To run a mutation, you first call `useCreateJobsiteAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJobsiteAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJobsiteAnnouncementMutation, { data, loading, error }] = useCreateJobsiteAnnouncementMutation({
 *   variables: {
 *      jobsiteAnnouncementInput: // value for 'jobsiteAnnouncementInput'
 *   },
 * });
 */
export function useCreateJobsiteAnnouncementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateJobsiteAnnouncementMutation,
    CreateJobsiteAnnouncementMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateJobsiteAnnouncementMutation, CreateJobsiteAnnouncementMutationVariables>(
    CreateJobsiteAnnouncementDocument,
    options,
  );
}
export type CreateJobsiteAnnouncementMutationHookResult = ReturnType<typeof useCreateJobsiteAnnouncementMutation>;
export type CreateJobsiteAnnouncementMutationResult = Apollo.MutationResult<CreateJobsiteAnnouncementMutation>;
export type CreateJobsiteAnnouncementMutationOptions = Apollo.BaseMutationOptions<
  CreateJobsiteAnnouncementMutation,
  CreateJobsiteAnnouncementMutationVariables
>;
export const DeleteJobsiteAnnouncementDocument = gql`
  mutation DeleteJobsiteAnnouncement($jobsiteAnnouncementId: ID!) {
    deleteJobsiteAnnouncement(jobsiteAnnouncementId: $jobsiteAnnouncementId) {
      __typename
      success
    }
  }
`;
export type DeleteJobsiteAnnouncementMutationFn = Apollo.MutationFunction<
  DeleteJobsiteAnnouncementMutation,
  DeleteJobsiteAnnouncementMutationVariables
>;

/**
 * __useDeleteJobsiteAnnouncementMutation__
 *
 * To run a mutation, you first call `useDeleteJobsiteAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJobsiteAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteJobsiteAnnouncementMutation, { data, loading, error }] = useDeleteJobsiteAnnouncementMutation({
 *   variables: {
 *      jobsiteAnnouncementId: // value for 'jobsiteAnnouncementId'
 *   },
 * });
 */
export function useDeleteJobsiteAnnouncementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteJobsiteAnnouncementMutation,
    DeleteJobsiteAnnouncementMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteJobsiteAnnouncementMutation, DeleteJobsiteAnnouncementMutationVariables>(
    DeleteJobsiteAnnouncementDocument,
    options,
  );
}
export type DeleteJobsiteAnnouncementMutationHookResult = ReturnType<typeof useDeleteJobsiteAnnouncementMutation>;
export type DeleteJobsiteAnnouncementMutationResult = Apollo.MutationResult<DeleteJobsiteAnnouncementMutation>;
export type DeleteJobsiteAnnouncementMutationOptions = Apollo.BaseMutationOptions<
  DeleteJobsiteAnnouncementMutation,
  DeleteJobsiteAnnouncementMutationVariables
>;
export const GetJobsiteAnnouncementCandidateCountsDocument = gql`
  query GetJobsiteAnnouncementCandidateCounts($input: JobsiteAnnouncementFilterSpecification!) {
    getJobsiteAnnouncementCandidateRecipients(input: $input) {
      totalCount
      totalReachableCount
    }
  }
`;

/**
 * __useGetJobsiteAnnouncementCandidateCountsQuery__
 *
 * To run a query within a React component, call `useGetJobsiteAnnouncementCandidateCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobsiteAnnouncementCandidateCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobsiteAnnouncementCandidateCountsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetJobsiteAnnouncementCandidateCountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetJobsiteAnnouncementCandidateCountsQuery,
    GetJobsiteAnnouncementCandidateCountsQueryVariables
  > &
    ({ variables: GetJobsiteAnnouncementCandidateCountsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetJobsiteAnnouncementCandidateCountsQuery,
    GetJobsiteAnnouncementCandidateCountsQueryVariables
  >(GetJobsiteAnnouncementCandidateCountsDocument, options);
}
export function useGetJobsiteAnnouncementCandidateCountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetJobsiteAnnouncementCandidateCountsQuery,
    GetJobsiteAnnouncementCandidateCountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetJobsiteAnnouncementCandidateCountsQuery,
    GetJobsiteAnnouncementCandidateCountsQueryVariables
  >(GetJobsiteAnnouncementCandidateCountsDocument, options);
}
export function useGetJobsiteAnnouncementCandidateCountsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetJobsiteAnnouncementCandidateCountsQuery,
    GetJobsiteAnnouncementCandidateCountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetJobsiteAnnouncementCandidateCountsQuery,
    GetJobsiteAnnouncementCandidateCountsQueryVariables
  >(GetJobsiteAnnouncementCandidateCountsDocument, options);
}
export type GetJobsiteAnnouncementCandidateCountsQueryHookResult = ReturnType<
  typeof useGetJobsiteAnnouncementCandidateCountsQuery
>;
export type GetJobsiteAnnouncementCandidateCountsLazyQueryHookResult = ReturnType<
  typeof useGetJobsiteAnnouncementCandidateCountsLazyQuery
>;
export type GetJobsiteAnnouncementCandidateCountsSuspenseQueryHookResult = ReturnType<
  typeof useGetJobsiteAnnouncementCandidateCountsSuspenseQuery
>;
export type GetJobsiteAnnouncementCandidateCountsQueryResult = Apollo.QueryResult<
  GetJobsiteAnnouncementCandidateCountsQuery,
  GetJobsiteAnnouncementCandidateCountsQueryVariables
>;
export const GetJobsiteAnnouncementsDocument = gql`
  query GetJobsiteAnnouncements($getJobsiteAnnouncementsInput: GetJobsiteAnnouncementsInput) {
    getJobsiteAnnouncements(input: $getJobsiteAnnouncementsInput) {
      count
      edges {
        node {
          announcementType
          jobsiteAnnouncementId
          subject
          status
          message
          sentAt
          startAt
          endAt
          timeZone
          jobsites {
            count
            edges {
              node {
                jobsiteId
                name
              }
            }
          }
          jobsiteAccessStatus
          accessWithinDayRange
          trades
          titles
        }
      }
    }
  }
`;

/**
 * __useGetJobsiteAnnouncementsQuery__
 *
 * To run a query within a React component, call `useGetJobsiteAnnouncementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobsiteAnnouncementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobsiteAnnouncementsQuery({
 *   variables: {
 *      getJobsiteAnnouncementsInput: // value for 'getJobsiteAnnouncementsInput'
 *   },
 * });
 */
export function useGetJobsiteAnnouncementsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetJobsiteAnnouncementsQuery, GetJobsiteAnnouncementsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetJobsiteAnnouncementsQuery, GetJobsiteAnnouncementsQueryVariables>(
    GetJobsiteAnnouncementsDocument,
    options,
  );
}
export function useGetJobsiteAnnouncementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetJobsiteAnnouncementsQuery, GetJobsiteAnnouncementsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetJobsiteAnnouncementsQuery, GetJobsiteAnnouncementsQueryVariables>(
    GetJobsiteAnnouncementsDocument,
    options,
  );
}
export function useGetJobsiteAnnouncementsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetJobsiteAnnouncementsQuery, GetJobsiteAnnouncementsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetJobsiteAnnouncementsQuery, GetJobsiteAnnouncementsQueryVariables>(
    GetJobsiteAnnouncementsDocument,
    options,
  );
}
export type GetJobsiteAnnouncementsQueryHookResult = ReturnType<typeof useGetJobsiteAnnouncementsQuery>;
export type GetJobsiteAnnouncementsLazyQueryHookResult = ReturnType<typeof useGetJobsiteAnnouncementsLazyQuery>;
export type GetJobsiteAnnouncementsSuspenseQueryHookResult = ReturnType<typeof useGetJobsiteAnnouncementsSuspenseQuery>;
export type GetJobsiteAnnouncementsQueryResult = Apollo.QueryResult<
  GetJobsiteAnnouncementsQuery,
  GetJobsiteAnnouncementsQueryVariables
>;
export const GetJobsiteAnnouncementsContainerContractorsSessionDocument = gql`
  query GetJobsiteAnnouncementsContainerContractorsSession($userContractorsInput: UserContractorsInput) {
    getCurrentSession {
      user {
        userAccountId
        contractors(input: $userContractorsInput) {
          count
          edges {
            node {
              contractorId
              organization {
                name
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetJobsiteAnnouncementsContainerContractorsSessionQuery__
 *
 * To run a query within a React component, call `useGetJobsiteAnnouncementsContainerContractorsSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobsiteAnnouncementsContainerContractorsSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobsiteAnnouncementsContainerContractorsSessionQuery({
 *   variables: {
 *      userContractorsInput: // value for 'userContractorsInput'
 *   },
 * });
 */
export function useGetJobsiteAnnouncementsContainerContractorsSessionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetJobsiteAnnouncementsContainerContractorsSessionQuery,
    GetJobsiteAnnouncementsContainerContractorsSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetJobsiteAnnouncementsContainerContractorsSessionQuery,
    GetJobsiteAnnouncementsContainerContractorsSessionQueryVariables
  >(GetJobsiteAnnouncementsContainerContractorsSessionDocument, options);
}
export function useGetJobsiteAnnouncementsContainerContractorsSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetJobsiteAnnouncementsContainerContractorsSessionQuery,
    GetJobsiteAnnouncementsContainerContractorsSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetJobsiteAnnouncementsContainerContractorsSessionQuery,
    GetJobsiteAnnouncementsContainerContractorsSessionQueryVariables
  >(GetJobsiteAnnouncementsContainerContractorsSessionDocument, options);
}
export function useGetJobsiteAnnouncementsContainerContractorsSessionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetJobsiteAnnouncementsContainerContractorsSessionQuery,
    GetJobsiteAnnouncementsContainerContractorsSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetJobsiteAnnouncementsContainerContractorsSessionQuery,
    GetJobsiteAnnouncementsContainerContractorsSessionQueryVariables
  >(GetJobsiteAnnouncementsContainerContractorsSessionDocument, options);
}
export type GetJobsiteAnnouncementsContainerContractorsSessionQueryHookResult = ReturnType<
  typeof useGetJobsiteAnnouncementsContainerContractorsSessionQuery
>;
export type GetJobsiteAnnouncementsContainerContractorsSessionLazyQueryHookResult = ReturnType<
  typeof useGetJobsiteAnnouncementsContainerContractorsSessionLazyQuery
>;
export type GetJobsiteAnnouncementsContainerContractorsSessionSuspenseQueryHookResult = ReturnType<
  typeof useGetJobsiteAnnouncementsContainerContractorsSessionSuspenseQuery
>;
export type GetJobsiteAnnouncementsContainerContractorsSessionQueryResult = Apollo.QueryResult<
  GetJobsiteAnnouncementsContainerContractorsSessionQuery,
  GetJobsiteAnnouncementsContainerContractorsSessionQueryVariables
>;
export const ContractorAddJobsitesDocument = gql`
  mutation ContractorAddJobsites($input: ContractorAddJobsitesInput!) {
    contractorAddJobsites(input: $input) {
      jobsiteContractors {
        id
      }
    }
  }
`;
export type ContractorAddJobsitesMutationFn = Apollo.MutationFunction<
  ContractorAddJobsitesMutation,
  ContractorAddJobsitesMutationVariables
>;

/**
 * __useContractorAddJobsitesMutation__
 *
 * To run a mutation, you first call `useContractorAddJobsitesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContractorAddJobsitesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contractorAddJobsitesMutation, { data, loading, error }] = useContractorAddJobsitesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContractorAddJobsitesMutation(
  baseOptions?: Apollo.MutationHookOptions<ContractorAddJobsitesMutation, ContractorAddJobsitesMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ContractorAddJobsitesMutation, ContractorAddJobsitesMutationVariables>(
    ContractorAddJobsitesDocument,
    options,
  );
}
export type ContractorAddJobsitesMutationHookResult = ReturnType<typeof useContractorAddJobsitesMutation>;
export type ContractorAddJobsitesMutationResult = Apollo.MutationResult<ContractorAddJobsitesMutation>;
export type ContractorAddJobsitesMutationOptions = Apollo.BaseMutationOptions<
  ContractorAddJobsitesMutation,
  ContractorAddJobsitesMutationVariables
>;
export const ContractorRemoveWorkerDocument = gql`
  mutation ContractorRemoveWorker($input: ContractorRemoveWorkerInput!) {
    contractorRemoveWorker(input: $input) {
      success {
        success
      }
    }
  }
`;
export type ContractorRemoveWorkerMutationFn = Apollo.MutationFunction<
  ContractorRemoveWorkerMutation,
  ContractorRemoveWorkerMutationVariables
>;

/**
 * __useContractorRemoveWorkerMutation__
 *
 * To run a mutation, you first call `useContractorRemoveWorkerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContractorRemoveWorkerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contractorRemoveWorkerMutation, { data, loading, error }] = useContractorRemoveWorkerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContractorRemoveWorkerMutation(
  baseOptions?: Apollo.MutationHookOptions<ContractorRemoveWorkerMutation, ContractorRemoveWorkerMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ContractorRemoveWorkerMutation, ContractorRemoveWorkerMutationVariables>(
    ContractorRemoveWorkerDocument,
    options,
  );
}
export type ContractorRemoveWorkerMutationHookResult = ReturnType<typeof useContractorRemoveWorkerMutation>;
export type ContractorRemoveWorkerMutationResult = Apollo.MutationResult<ContractorRemoveWorkerMutation>;
export type ContractorRemoveWorkerMutationOptions = Apollo.BaseMutationOptions<
  ContractorRemoveWorkerMutation,
  ContractorRemoveWorkerMutationVariables
>;
export const CreateContractorStripeInvoiceDocument = gql`
  mutation CreateContractorStripeInvoice($input: CreateContractorStripeInvoiceInput!) {
    createContractorStripeInvoice(input: $input) {
      invoiceId
      customerId
      url
      status
    }
  }
`;
export type CreateContractorStripeInvoiceMutationFn = Apollo.MutationFunction<
  CreateContractorStripeInvoiceMutation,
  CreateContractorStripeInvoiceMutationVariables
>;

/**
 * __useCreateContractorStripeInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateContractorStripeInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractorStripeInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractorStripeInvoiceMutation, { data, loading, error }] = useCreateContractorStripeInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContractorStripeInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateContractorStripeInvoiceMutation,
    CreateContractorStripeInvoiceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateContractorStripeInvoiceMutation, CreateContractorStripeInvoiceMutationVariables>(
    CreateContractorStripeInvoiceDocument,
    options,
  );
}
export type CreateContractorStripeInvoiceMutationHookResult = ReturnType<
  typeof useCreateContractorStripeInvoiceMutation
>;
export type CreateContractorStripeInvoiceMutationResult = Apollo.MutationResult<CreateContractorStripeInvoiceMutation>;
export type CreateContractorStripeInvoiceMutationOptions = Apollo.BaseMutationOptions<
  CreateContractorStripeInvoiceMutation,
  CreateContractorStripeInvoiceMutationVariables
>;
export const InitiateStripePaymentSetupSessionDocument = gql`
  mutation initiateStripePaymentSetupSession($input: InitiateStripePaymentSetupSessionInput!) {
    initiateStripePaymentSetupSession(input: $input) {
      clientSecret
      customerId
      publicKey
      sessionId
    }
  }
`;
export type InitiateStripePaymentSetupSessionMutationFn = Apollo.MutationFunction<
  InitiateStripePaymentSetupSessionMutation,
  InitiateStripePaymentSetupSessionMutationVariables
>;

/**
 * __useInitiateStripePaymentSetupSessionMutation__
 *
 * To run a mutation, you first call `useInitiateStripePaymentSetupSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateStripePaymentSetupSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateStripePaymentSetupSessionMutation, { data, loading, error }] = useInitiateStripePaymentSetupSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInitiateStripePaymentSetupSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InitiateStripePaymentSetupSessionMutation,
    InitiateStripePaymentSetupSessionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InitiateStripePaymentSetupSessionMutation,
    InitiateStripePaymentSetupSessionMutationVariables
  >(InitiateStripePaymentSetupSessionDocument, options);
}
export type InitiateStripePaymentSetupSessionMutationHookResult = ReturnType<
  typeof useInitiateStripePaymentSetupSessionMutation
>;
export type InitiateStripePaymentSetupSessionMutationResult =
  Apollo.MutationResult<InitiateStripePaymentSetupSessionMutation>;
export type InitiateStripePaymentSetupSessionMutationOptions = Apollo.BaseMutationOptions<
  InitiateStripePaymentSetupSessionMutation,
  InitiateStripePaymentSetupSessionMutationVariables
>;
export const JobsiteContractorUpdateStripePaymentMethodDocument = gql`
  mutation JobsiteContractorUpdateStripePaymentMethod($input: JobsiteContractorUpdateStripePaymentMethodInput!) {
    jobsiteContractorUpdateStripePaymentMethod(input: $input) {
      success {
        success
      }
    }
  }
`;
export type JobsiteContractorUpdateStripePaymentMethodMutationFn = Apollo.MutationFunction<
  JobsiteContractorUpdateStripePaymentMethodMutation,
  JobsiteContractorUpdateStripePaymentMethodMutationVariables
>;

/**
 * __useJobsiteContractorUpdateStripePaymentMethodMutation__
 *
 * To run a mutation, you first call `useJobsiteContractorUpdateStripePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobsiteContractorUpdateStripePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobsiteContractorUpdateStripePaymentMethodMutation, { data, loading, error }] = useJobsiteContractorUpdateStripePaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJobsiteContractorUpdateStripePaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JobsiteContractorUpdateStripePaymentMethodMutation,
    JobsiteContractorUpdateStripePaymentMethodMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    JobsiteContractorUpdateStripePaymentMethodMutation,
    JobsiteContractorUpdateStripePaymentMethodMutationVariables
  >(JobsiteContractorUpdateStripePaymentMethodDocument, options);
}
export type JobsiteContractorUpdateStripePaymentMethodMutationHookResult = ReturnType<
  typeof useJobsiteContractorUpdateStripePaymentMethodMutation
>;
export type JobsiteContractorUpdateStripePaymentMethodMutationResult =
  Apollo.MutationResult<JobsiteContractorUpdateStripePaymentMethodMutation>;
export type JobsiteContractorUpdateStripePaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  JobsiteContractorUpdateStripePaymentMethodMutation,
  JobsiteContractorUpdateStripePaymentMethodMutationVariables
>;
export const RemoveStripePaymentMethodDocument = gql`
  mutation RemoveStripePaymentMethod($input: ManageContractorStripePaymentMethodInput!) {
    removeStripePaymentMethod(input: $input) {
      id
      brand
      last4
      expMonth
      expYear
      isDefault
    }
  }
`;
export type RemoveStripePaymentMethodMutationFn = Apollo.MutationFunction<
  RemoveStripePaymentMethodMutation,
  RemoveStripePaymentMethodMutationVariables
>;

/**
 * __useRemoveStripePaymentMethodMutation__
 *
 * To run a mutation, you first call `useRemoveStripePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveStripePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeStripePaymentMethodMutation, { data, loading, error }] = useRemoveStripePaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveStripePaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveStripePaymentMethodMutation,
    RemoveStripePaymentMethodMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveStripePaymentMethodMutation, RemoveStripePaymentMethodMutationVariables>(
    RemoveStripePaymentMethodDocument,
    options,
  );
}
export type RemoveStripePaymentMethodMutationHookResult = ReturnType<typeof useRemoveStripePaymentMethodMutation>;
export type RemoveStripePaymentMethodMutationResult = Apollo.MutationResult<RemoveStripePaymentMethodMutation>;
export type RemoveStripePaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  RemoveStripePaymentMethodMutation,
  RemoveStripePaymentMethodMutationVariables
>;
export const SetDefaultStripePaymentMethodDocument = gql`
  mutation SetDefaultStripePaymentMethod($input: ManageContractorStripePaymentMethodInput!) {
    setDefaultStripePaymentMethod(input: $input) {
      id
      brand
      last4
      expMonth
      expYear
      isDefault
    }
  }
`;
export type SetDefaultStripePaymentMethodMutationFn = Apollo.MutationFunction<
  SetDefaultStripePaymentMethodMutation,
  SetDefaultStripePaymentMethodMutationVariables
>;

/**
 * __useSetDefaultStripePaymentMethodMutation__
 *
 * To run a mutation, you first call `useSetDefaultStripePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDefaultStripePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDefaultStripePaymentMethodMutation, { data, loading, error }] = useSetDefaultStripePaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetDefaultStripePaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetDefaultStripePaymentMethodMutation,
    SetDefaultStripePaymentMethodMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetDefaultStripePaymentMethodMutation, SetDefaultStripePaymentMethodMutationVariables>(
    SetDefaultStripePaymentMethodDocument,
    options,
  );
}
export type SetDefaultStripePaymentMethodMutationHookResult = ReturnType<
  typeof useSetDefaultStripePaymentMethodMutation
>;
export type SetDefaultStripePaymentMethodMutationResult = Apollo.MutationResult<SetDefaultStripePaymentMethodMutation>;
export type SetDefaultStripePaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  SetDefaultStripePaymentMethodMutation,
  SetDefaultStripePaymentMethodMutationVariables
>;
export const GetContractorStripePaymentMethodsDetailsDocument = gql`
  query getContractorStripePaymentMethodsDetails($contractorId: ID!, $includeArchived: Boolean!) {
    getContractor(contractorId: $contractorId, includeArchived: $includeArchived) {
      contractorId
      isArchived
      websiteUrl
      description
      stripeMappingDetails {
        customerId
        publicKey
        clientSecret
      }
      organization {
        organizationId
        name
        legalName
        email
        addressLine1
        addressLine2
        addressCity
        addressCity
        addressState
        addressZipCode
      }
      billingContact {
        firstName
        lastName
        email
        phoneNumber
      }
      objectHistory {
        createdAt
        createdBy {
          identity {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

/**
 * __useGetContractorStripePaymentMethodsDetailsQuery__
 *
 * To run a query within a React component, call `useGetContractorStripePaymentMethodsDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractorStripePaymentMethodsDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractorStripePaymentMethodsDetailsQuery({
 *   variables: {
 *      contractorId: // value for 'contractorId'
 *      includeArchived: // value for 'includeArchived'
 *   },
 * });
 */
export function useGetContractorStripePaymentMethodsDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractorStripePaymentMethodsDetailsQuery,
    GetContractorStripePaymentMethodsDetailsQueryVariables
  > &
    ({ variables: GetContractorStripePaymentMethodsDetailsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetContractorStripePaymentMethodsDetailsQuery,
    GetContractorStripePaymentMethodsDetailsQueryVariables
  >(GetContractorStripePaymentMethodsDetailsDocument, options);
}
export function useGetContractorStripePaymentMethodsDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractorStripePaymentMethodsDetailsQuery,
    GetContractorStripePaymentMethodsDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetContractorStripePaymentMethodsDetailsQuery,
    GetContractorStripePaymentMethodsDetailsQueryVariables
  >(GetContractorStripePaymentMethodsDetailsDocument, options);
}
export function useGetContractorStripePaymentMethodsDetailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetContractorStripePaymentMethodsDetailsQuery,
    GetContractorStripePaymentMethodsDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetContractorStripePaymentMethodsDetailsQuery,
    GetContractorStripePaymentMethodsDetailsQueryVariables
  >(GetContractorStripePaymentMethodsDetailsDocument, options);
}
export type GetContractorStripePaymentMethodsDetailsQueryHookResult = ReturnType<
  typeof useGetContractorStripePaymentMethodsDetailsQuery
>;
export type GetContractorStripePaymentMethodsDetailsLazyQueryHookResult = ReturnType<
  typeof useGetContractorStripePaymentMethodsDetailsLazyQuery
>;
export type GetContractorStripePaymentMethodsDetailsSuspenseQueryHookResult = ReturnType<
  typeof useGetContractorStripePaymentMethodsDetailsSuspenseQuery
>;
export type GetContractorStripePaymentMethodsDetailsQueryResult = Apollo.QueryResult<
  GetContractorStripePaymentMethodsDetailsQuery,
  GetContractorStripePaymentMethodsDetailsQueryVariables
>;
export const GetJobsitesAndContractorsDocument = gql`
  query GetJobsitesAndContractors($userJobsitesInput: UserJobsitesInput!) {
    getCurrentSession {
      user {
        userAccountId
        jobsites(input: $userJobsitesInput) {
          count
          edges {
            node {
              jobsiteId
              jobsiteContractors {
                count
                edges {
                  node {
                    id
                    startDate
                    contractor {
                      contractorId
                      isDefaultContractor
                      organization {
                        name
                        slug
                        legalName
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetJobsitesAndContractorsQuery__
 *
 * To run a query within a React component, call `useGetJobsitesAndContractorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobsitesAndContractorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobsitesAndContractorsQuery({
 *   variables: {
 *      userJobsitesInput: // value for 'userJobsitesInput'
 *   },
 * });
 */
export function useGetJobsitesAndContractorsQuery(
  baseOptions: Apollo.QueryHookOptions<GetJobsitesAndContractorsQuery, GetJobsitesAndContractorsQueryVariables> &
    ({ variables: GetJobsitesAndContractorsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetJobsitesAndContractorsQuery, GetJobsitesAndContractorsQueryVariables>(
    GetJobsitesAndContractorsDocument,
    options,
  );
}
export function useGetJobsitesAndContractorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetJobsitesAndContractorsQuery, GetJobsitesAndContractorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetJobsitesAndContractorsQuery, GetJobsitesAndContractorsQueryVariables>(
    GetJobsitesAndContractorsDocument,
    options,
  );
}
export function useGetJobsitesAndContractorsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetJobsitesAndContractorsQuery,
    GetJobsitesAndContractorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetJobsitesAndContractorsQuery, GetJobsitesAndContractorsQueryVariables>(
    GetJobsitesAndContractorsDocument,
    options,
  );
}
export type GetJobsitesAndContractorsQueryHookResult = ReturnType<typeof useGetJobsitesAndContractorsQuery>;
export type GetJobsitesAndContractorsLazyQueryHookResult = ReturnType<typeof useGetJobsitesAndContractorsLazyQuery>;
export type GetJobsitesAndContractorsSuspenseQueryHookResult = ReturnType<
  typeof useGetJobsitesAndContractorsSuspenseQuery
>;
export type GetJobsitesAndContractorsQueryResult = Apollo.QueryResult<
  GetJobsitesAndContractorsQuery,
  GetJobsitesAndContractorsQueryVariables
>;
export const GetContractorContainerContractorInfoDocument = gql`
  query GetContractorContainerContractorInfo($contractorId: ID!, $includeArchived: Boolean!) {
    getContractor(contractorId: $contractorId, includeArchived: $includeArchived) {
      contractorId
      isArchived
      websiteUrl
      description
      organization {
        organizationId
        slug
        name
        legalName
        email
        addressLine1
        addressLine2
        addressCity
        addressCity
        addressState
        addressZipCode
      }
      billingContact {
        firstName
        lastName
        email
        phoneNumber
      }
      objectHistory {
        createdAt
        createdBy {
          identity {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

/**
 * __useGetContractorContainerContractorInfoQuery__
 *
 * To run a query within a React component, call `useGetContractorContainerContractorInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractorContainerContractorInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractorContainerContractorInfoQuery({
 *   variables: {
 *      contractorId: // value for 'contractorId'
 *      includeArchived: // value for 'includeArchived'
 *   },
 * });
 */
export function useGetContractorContainerContractorInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractorContainerContractorInfoQuery,
    GetContractorContainerContractorInfoQueryVariables
  > &
    ({ variables: GetContractorContainerContractorInfoQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetContractorContainerContractorInfoQuery, GetContractorContainerContractorInfoQueryVariables>(
    GetContractorContainerContractorInfoDocument,
    options,
  );
}
export function useGetContractorContainerContractorInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractorContainerContractorInfoQuery,
    GetContractorContainerContractorInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetContractorContainerContractorInfoQuery,
    GetContractorContainerContractorInfoQueryVariables
  >(GetContractorContainerContractorInfoDocument, options);
}
export function useGetContractorContainerContractorInfoSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetContractorContainerContractorInfoQuery,
    GetContractorContainerContractorInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetContractorContainerContractorInfoQuery,
    GetContractorContainerContractorInfoQueryVariables
  >(GetContractorContainerContractorInfoDocument, options);
}
export type GetContractorContainerContractorInfoQueryHookResult = ReturnType<
  typeof useGetContractorContainerContractorInfoQuery
>;
export type GetContractorContainerContractorInfoLazyQueryHookResult = ReturnType<
  typeof useGetContractorContainerContractorInfoLazyQuery
>;
export type GetContractorContainerContractorInfoSuspenseQueryHookResult = ReturnType<
  typeof useGetContractorContainerContractorInfoSuspenseQuery
>;
export type GetContractorContainerContractorInfoQueryResult = Apollo.QueryResult<
  GetContractorContainerContractorInfoQuery,
  GetContractorContainerContractorInfoQueryVariables
>;
export const GetContractorContainerContractorJobsitesDocument = gql`
  query GetContractorContainerContractorJobsites($contractorId: ID!, $includeArchived: Boolean!) {
    getContractor(contractorId: $contractorId, includeArchived: $includeArchived) {
      contractorId
      isArchived
      organization {
        organizationId
        name
      }
      jobsiteContractors {
        count
        edges {
          node {
            id
            stripePaymentMethodId
            jobsite {
              name
              jobsiteId
              addressCity
              addressState
            }
            parentJobsiteContractor {
              contractor {
                contractorId
                organization {
                  name
                }
              }
            }
            startDate
            endDate
            contact {
              firstName
              lastName
              phoneNumber
              email
              title
            }
            jobsiteWorkers {
              count
            }
            subcontractedJobsiteContractors {
              count
            }
            jobsiteInvitation {
              jobsiteInvitationId
            }
          }
        }
      }
    }
    getCurrentSession {
      user {
        jobsites {
          count
          edges {
            node {
              jobsiteId
              name
              parentId
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetContractorContainerContractorJobsitesQuery__
 *
 * To run a query within a React component, call `useGetContractorContainerContractorJobsitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractorContainerContractorJobsitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractorContainerContractorJobsitesQuery({
 *   variables: {
 *      contractorId: // value for 'contractorId'
 *      includeArchived: // value for 'includeArchived'
 *   },
 * });
 */
export function useGetContractorContainerContractorJobsitesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractorContainerContractorJobsitesQuery,
    GetContractorContainerContractorJobsitesQueryVariables
  > &
    ({ variables: GetContractorContainerContractorJobsitesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetContractorContainerContractorJobsitesQuery,
    GetContractorContainerContractorJobsitesQueryVariables
  >(GetContractorContainerContractorJobsitesDocument, options);
}
export function useGetContractorContainerContractorJobsitesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractorContainerContractorJobsitesQuery,
    GetContractorContainerContractorJobsitesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetContractorContainerContractorJobsitesQuery,
    GetContractorContainerContractorJobsitesQueryVariables
  >(GetContractorContainerContractorJobsitesDocument, options);
}
export function useGetContractorContainerContractorJobsitesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetContractorContainerContractorJobsitesQuery,
    GetContractorContainerContractorJobsitesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetContractorContainerContractorJobsitesQuery,
    GetContractorContainerContractorJobsitesQueryVariables
  >(GetContractorContainerContractorJobsitesDocument, options);
}
export type GetContractorContainerContractorJobsitesQueryHookResult = ReturnType<
  typeof useGetContractorContainerContractorJobsitesQuery
>;
export type GetContractorContainerContractorJobsitesLazyQueryHookResult = ReturnType<
  typeof useGetContractorContainerContractorJobsitesLazyQuery
>;
export type GetContractorContainerContractorJobsitesSuspenseQueryHookResult = ReturnType<
  typeof useGetContractorContainerContractorJobsitesSuspenseQuery
>;
export type GetContractorContainerContractorJobsitesQueryResult = Apollo.QueryResult<
  GetContractorContainerContractorJobsitesQuery,
  GetContractorContainerContractorJobsitesQueryVariables
>;
export const GetContractorContainerContractorSubcontractorsDocument = gql`
  query GetContractorContainerContractorSubcontractors(
    $contractorId: ID!
    $includeArchived: Boolean!
    $contractorContractorWorkersInput: ContractorContractorWorkersInput
  ) {
    getContractor(contractorId: $contractorId, includeArchived: $includeArchived) {
      contractorId
      isArchived
      organization {
        name
        addressCity
        addressState
      }
      jobsiteContractors {
        count
        edges {
          node {
            jobsite {
              jobsiteId
              name
            }
            subcontractedJobsiteContractors {
              count
              edges {
                node {
                  contractor {
                    contractorId
                    organization {
                      name
                    }
                  }
                  jobsite {
                    jobsiteId
                    name
                  }
                  jobsiteWorkers {
                    count
                  }
                  endDate
                }
              }
            }
          }
        }
      }
      contractorWorkers(input: $contractorContractorWorkersInput) {
        count
      }
      contractorMembers {
        count
      }
    }
  }
`;

/**
 * __useGetContractorContainerContractorSubcontractorsQuery__
 *
 * To run a query within a React component, call `useGetContractorContainerContractorSubcontractorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractorContainerContractorSubcontractorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractorContainerContractorSubcontractorsQuery({
 *   variables: {
 *      contractorId: // value for 'contractorId'
 *      includeArchived: // value for 'includeArchived'
 *      contractorContractorWorkersInput: // value for 'contractorContractorWorkersInput'
 *   },
 * });
 */
export function useGetContractorContainerContractorSubcontractorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractorContainerContractorSubcontractorsQuery,
    GetContractorContainerContractorSubcontractorsQueryVariables
  > &
    ({ variables: GetContractorContainerContractorSubcontractorsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetContractorContainerContractorSubcontractorsQuery,
    GetContractorContainerContractorSubcontractorsQueryVariables
  >(GetContractorContainerContractorSubcontractorsDocument, options);
}
export function useGetContractorContainerContractorSubcontractorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractorContainerContractorSubcontractorsQuery,
    GetContractorContainerContractorSubcontractorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetContractorContainerContractorSubcontractorsQuery,
    GetContractorContainerContractorSubcontractorsQueryVariables
  >(GetContractorContainerContractorSubcontractorsDocument, options);
}
export function useGetContractorContainerContractorSubcontractorsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetContractorContainerContractorSubcontractorsQuery,
    GetContractorContainerContractorSubcontractorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetContractorContainerContractorSubcontractorsQuery,
    GetContractorContainerContractorSubcontractorsQueryVariables
  >(GetContractorContainerContractorSubcontractorsDocument, options);
}
export type GetContractorContainerContractorSubcontractorsQueryHookResult = ReturnType<
  typeof useGetContractorContainerContractorSubcontractorsQuery
>;
export type GetContractorContainerContractorSubcontractorsLazyQueryHookResult = ReturnType<
  typeof useGetContractorContainerContractorSubcontractorsLazyQuery
>;
export type GetContractorContainerContractorSubcontractorsSuspenseQueryHookResult = ReturnType<
  typeof useGetContractorContainerContractorSubcontractorsSuspenseQuery
>;
export type GetContractorContainerContractorSubcontractorsQueryResult = Apollo.QueryResult<
  GetContractorContainerContractorSubcontractorsQuery,
  GetContractorContainerContractorSubcontractorsQueryVariables
>;
export const GetContractorContainerContractorUsersDocument = gql`
  query GetContractorContainerContractorUsers(
    $contractorId: ID!
    $includeArchived: Boolean!
    $contractorContractorMemberInput: ContractorContractorMembersInput
    $userRolesInput: UserRolesInput
  ) {
    getContractor(contractorId: $contractorId, includeArchived: $includeArchived) {
      contractorId
      isArchived
      organization {
        organizationId
        name
      }
      contractorMembers(input: $contractorContractorMemberInput) {
        count
        edges {
          node {
            id
            user {
              userAccountId
              roles(input: $userRolesInput) {
                key
                displayName
                object {
                  objectId
                  objectDetails {
                    name
                  }
                }
              }
              identity {
                firstName
                lastName
                email
                phoneNumber
              }
              worker {
                workerId
                profilePictureCropped {
                  downloadUrl
                }
              }
              lastSession {
                createdAt
                appVersion
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetContractorContainerContractorUsersQuery__
 *
 * To run a query within a React component, call `useGetContractorContainerContractorUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractorContainerContractorUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractorContainerContractorUsersQuery({
 *   variables: {
 *      contractorId: // value for 'contractorId'
 *      includeArchived: // value for 'includeArchived'
 *      contractorContractorMemberInput: // value for 'contractorContractorMemberInput'
 *      userRolesInput: // value for 'userRolesInput'
 *   },
 * });
 */
export function useGetContractorContainerContractorUsersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractorContainerContractorUsersQuery,
    GetContractorContainerContractorUsersQueryVariables
  > &
    ({ variables: GetContractorContainerContractorUsersQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetContractorContainerContractorUsersQuery,
    GetContractorContainerContractorUsersQueryVariables
  >(GetContractorContainerContractorUsersDocument, options);
}
export function useGetContractorContainerContractorUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractorContainerContractorUsersQuery,
    GetContractorContainerContractorUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetContractorContainerContractorUsersQuery,
    GetContractorContainerContractorUsersQueryVariables
  >(GetContractorContainerContractorUsersDocument, options);
}
export function useGetContractorContainerContractorUsersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetContractorContainerContractorUsersQuery,
    GetContractorContainerContractorUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetContractorContainerContractorUsersQuery,
    GetContractorContainerContractorUsersQueryVariables
  >(GetContractorContainerContractorUsersDocument, options);
}
export type GetContractorContainerContractorUsersQueryHookResult = ReturnType<
  typeof useGetContractorContainerContractorUsersQuery
>;
export type GetContractorContainerContractorUsersLazyQueryHookResult = ReturnType<
  typeof useGetContractorContainerContractorUsersLazyQuery
>;
export type GetContractorContainerContractorUsersSuspenseQueryHookResult = ReturnType<
  typeof useGetContractorContainerContractorUsersSuspenseQuery
>;
export type GetContractorContainerContractorUsersQueryResult = Apollo.QueryResult<
  GetContractorContainerContractorUsersQuery,
  GetContractorContainerContractorUsersQueryVariables
>;
export const GetContractorContainerContractorWorkersDocument = gql`
  query GetContractorContainerContractorWorkers(
    $contractorId: ID!
    $includeArchived: Boolean!
    $contractorContractorWorkersInput: ContractorContractorWorkersInput
  ) {
    getContractor(contractorId: $contractorId, includeArchived: $includeArchived) {
      contractorId
      isArchived
      organization {
        organizationId
        name
      }
      jobsiteContractors {
        edges {
          node {
            jobsite {
              jobsiteId
              name
            }
          }
        }
      }
      contractorWorkers(input: $contractorContractorWorkersInput) {
        count
        edges {
          node {
            id
            worker {
              workerId
              birthDate
              quickCode
              trade
              user {
                userAccountId
                identity {
                  firstName
                  lastName
                  email
                }
              }
              profilePicture {
                downloadUrl
                uploadedAt
                fileId
              }
              profilePictureCropped {
                downloadUrl
                uploadedAt
                fileId
                fileType
              }
              workerCards {
                edges {
                  node {
                    workerCardId
                    cardNumber
                    cardStatus
                  }
                }
              }
            }
            jobsiteWorkers {
              count
              edges {
                node {
                  jobsiteWorkerId
                  jobsiteContractor {
                    jobsite {
                      jobsiteId
                      name
                    }
                    contractor {
                      contractorId
                      organization {
                        name
                      }
                    }
                  }
                  currentAccess {
                    isAllowed
                    notAllowedReason
                    startDate
                    endDate
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetContractorContainerContractorWorkersQuery__
 *
 * To run a query within a React component, call `useGetContractorContainerContractorWorkersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractorContainerContractorWorkersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractorContainerContractorWorkersQuery({
 *   variables: {
 *      contractorId: // value for 'contractorId'
 *      includeArchived: // value for 'includeArchived'
 *      contractorContractorWorkersInput: // value for 'contractorContractorWorkersInput'
 *   },
 * });
 */
export function useGetContractorContainerContractorWorkersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractorContainerContractorWorkersQuery,
    GetContractorContainerContractorWorkersQueryVariables
  > &
    ({ variables: GetContractorContainerContractorWorkersQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetContractorContainerContractorWorkersQuery,
    GetContractorContainerContractorWorkersQueryVariables
  >(GetContractorContainerContractorWorkersDocument, options);
}
export function useGetContractorContainerContractorWorkersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractorContainerContractorWorkersQuery,
    GetContractorContainerContractorWorkersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetContractorContainerContractorWorkersQuery,
    GetContractorContainerContractorWorkersQueryVariables
  >(GetContractorContainerContractorWorkersDocument, options);
}
export function useGetContractorContainerContractorWorkersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetContractorContainerContractorWorkersQuery,
    GetContractorContainerContractorWorkersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetContractorContainerContractorWorkersQuery,
    GetContractorContainerContractorWorkersQueryVariables
  >(GetContractorContainerContractorWorkersDocument, options);
}
export type GetContractorContainerContractorWorkersQueryHookResult = ReturnType<
  typeof useGetContractorContainerContractorWorkersQuery
>;
export type GetContractorContainerContractorWorkersLazyQueryHookResult = ReturnType<
  typeof useGetContractorContainerContractorWorkersLazyQuery
>;
export type GetContractorContainerContractorWorkersSuspenseQueryHookResult = ReturnType<
  typeof useGetContractorContainerContractorWorkersSuspenseQuery
>;
export type GetContractorContainerContractorWorkersQueryResult = Apollo.QueryResult<
  GetContractorContainerContractorWorkersQuery,
  GetContractorContainerContractorWorkersQueryVariables
>;
export const GetContractorContainerPaymentMethodsDocument = gql`
  query GetContractorContainerPaymentMethods($contractorId: ID!, $includeArchived: Boolean!) {
    getContractor(contractorId: $contractorId, includeArchived: $includeArchived) {
      contractorId
      isArchived
      websiteUrl
      description
      stripeMappingDetails {
        customerId
        publicKey
      }
      stripePaymentMethods {
        id
        brand
        last4
        expMonth
        expYear
        isDefault
      }
      organization {
        organizationId
        name
        legalName
        email
        addressLine1
        addressLine2
        addressCity
        addressCity
        addressState
        addressZipCode
      }
      billingContact {
        firstName
        lastName
        email
        phoneNumber
      }
      objectHistory {
        createdAt
        createdBy {
          identity {
            firstName
            lastName
          }
        }
      }
      jobsiteContractors {
        count
        edges {
          node {
            id
            stripePaymentMethodId
            jobsite {
              name
              jobsiteId
              addressCity
              addressState
              startDate
              endDate
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetContractorContainerPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useGetContractorContainerPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractorContainerPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractorContainerPaymentMethodsQuery({
 *   variables: {
 *      contractorId: // value for 'contractorId'
 *      includeArchived: // value for 'includeArchived'
 *   },
 * });
 */
export function useGetContractorContainerPaymentMethodsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractorContainerPaymentMethodsQuery,
    GetContractorContainerPaymentMethodsQueryVariables
  > &
    ({ variables: GetContractorContainerPaymentMethodsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetContractorContainerPaymentMethodsQuery, GetContractorContainerPaymentMethodsQueryVariables>(
    GetContractorContainerPaymentMethodsDocument,
    options,
  );
}
export function useGetContractorContainerPaymentMethodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractorContainerPaymentMethodsQuery,
    GetContractorContainerPaymentMethodsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetContractorContainerPaymentMethodsQuery,
    GetContractorContainerPaymentMethodsQueryVariables
  >(GetContractorContainerPaymentMethodsDocument, options);
}
export function useGetContractorContainerPaymentMethodsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetContractorContainerPaymentMethodsQuery,
    GetContractorContainerPaymentMethodsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetContractorContainerPaymentMethodsQuery,
    GetContractorContainerPaymentMethodsQueryVariables
  >(GetContractorContainerPaymentMethodsDocument, options);
}
export type GetContractorContainerPaymentMethodsQueryHookResult = ReturnType<
  typeof useGetContractorContainerPaymentMethodsQuery
>;
export type GetContractorContainerPaymentMethodsLazyQueryHookResult = ReturnType<
  typeof useGetContractorContainerPaymentMethodsLazyQuery
>;
export type GetContractorContainerPaymentMethodsSuspenseQueryHookResult = ReturnType<
  typeof useGetContractorContainerPaymentMethodsSuspenseQuery
>;
export type GetContractorContainerPaymentMethodsQueryResult = Apollo.QueryResult<
  GetContractorContainerPaymentMethodsQuery,
  GetContractorContainerPaymentMethodsQueryVariables
>;
export const GetJobsiteContractorsDocument = gql`
  query GetJobsiteContractors($jobsiteId: ID!) {
    getJobsite(jobsiteId: $jobsiteId) {
      jobsiteContractors {
        count
        edges {
          node {
            id
            startDate
            stripePaymentMethodId
            contractor {
              contractorId
              isDefaultContractor
              organization {
                organizationId
                name
                slug
                legalName
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetJobsiteContractorsQuery__
 *
 * To run a query within a React component, call `useGetJobsiteContractorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobsiteContractorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobsiteContractorsQuery({
 *   variables: {
 *      jobsiteId: // value for 'jobsiteId'
 *   },
 * });
 */
export function useGetJobsiteContractorsQuery(
  baseOptions: Apollo.QueryHookOptions<GetJobsiteContractorsQuery, GetJobsiteContractorsQueryVariables> &
    ({ variables: GetJobsiteContractorsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetJobsiteContractorsQuery, GetJobsiteContractorsQueryVariables>(
    GetJobsiteContractorsDocument,
    options,
  );
}
export function useGetJobsiteContractorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetJobsiteContractorsQuery, GetJobsiteContractorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetJobsiteContractorsQuery, GetJobsiteContractorsQueryVariables>(
    GetJobsiteContractorsDocument,
    options,
  );
}
export function useGetJobsiteContractorsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetJobsiteContractorsQuery, GetJobsiteContractorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetJobsiteContractorsQuery, GetJobsiteContractorsQueryVariables>(
    GetJobsiteContractorsDocument,
    options,
  );
}
export type GetJobsiteContractorsQueryHookResult = ReturnType<typeof useGetJobsiteContractorsQuery>;
export type GetJobsiteContractorsLazyQueryHookResult = ReturnType<typeof useGetJobsiteContractorsLazyQuery>;
export type GetJobsiteContractorsSuspenseQueryHookResult = ReturnType<typeof useGetJobsiteContractorsSuspenseQuery>;
export type GetJobsiteContractorsQueryResult = Apollo.QueryResult<
  GetJobsiteContractorsQuery,
  GetJobsiteContractorsQueryVariables
>;
export const ContractorCreateDocument = gql`
  mutation ContractorCreate($input: ContractorCreateInput!) {
    contractorCreate(input: $input) {
      contractor {
        contractorId
      }
    }
  }
`;
export type ContractorCreateMutationFn = Apollo.MutationFunction<
  ContractorCreateMutation,
  ContractorCreateMutationVariables
>;

/**
 * __useContractorCreateMutation__
 *
 * To run a mutation, you first call `useContractorCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContractorCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contractorCreateMutation, { data, loading, error }] = useContractorCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContractorCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<ContractorCreateMutation, ContractorCreateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ContractorCreateMutation, ContractorCreateMutationVariables>(
    ContractorCreateDocument,
    options,
  );
}
export type ContractorCreateMutationHookResult = ReturnType<typeof useContractorCreateMutation>;
export type ContractorCreateMutationResult = Apollo.MutationResult<ContractorCreateMutation>;
export type ContractorCreateMutationOptions = Apollo.BaseMutationOptions<
  ContractorCreateMutation,
  ContractorCreateMutationVariables
>;
export const ContractorUpdateDocument = gql`
  mutation ContractorUpdate($input: ContractorUpdateInput!) {
    contractorUpdate(input: $input) {
      contractor {
        contractorId
      }
    }
  }
`;
export type ContractorUpdateMutationFn = Apollo.MutationFunction<
  ContractorUpdateMutation,
  ContractorUpdateMutationVariables
>;

/**
 * __useContractorUpdateMutation__
 *
 * To run a mutation, you first call `useContractorUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContractorUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contractorUpdateMutation, { data, loading, error }] = useContractorUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContractorUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<ContractorUpdateMutation, ContractorUpdateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ContractorUpdateMutation, ContractorUpdateMutationVariables>(
    ContractorUpdateDocument,
    options,
  );
}
export type ContractorUpdateMutationHookResult = ReturnType<typeof useContractorUpdateMutation>;
export type ContractorUpdateMutationResult = Apollo.MutationResult<ContractorUpdateMutation>;
export type ContractorUpdateMutationOptions = Apollo.BaseMutationOptions<
  ContractorUpdateMutation,
  ContractorUpdateMutationVariables
>;
export const GetContractorsContainerCurrentSessionDocument = gql`
  query GetContractorsContainerCurrentSession($userContractorsInput: UserContractorsInput) {
    getCurrentSession {
      user {
        userAccountId
        contractors(input: $userContractorsInput) {
          count
          edges {
            node {
              contractorId
              organization {
                name
                addressCity
                addressState
              }
              jobsiteContractors {
                edges {
                  node {
                    jobsite {
                      name
                      jobsiteId
                    }
                    jobsiteWorkers {
                      count
                    }
                    startDate
                    endDate
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetContractorsContainerCurrentSessionQuery__
 *
 * To run a query within a React component, call `useGetContractorsContainerCurrentSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractorsContainerCurrentSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractorsContainerCurrentSessionQuery({
 *   variables: {
 *      userContractorsInput: // value for 'userContractorsInput'
 *   },
 * });
 */
export function useGetContractorsContainerCurrentSessionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetContractorsContainerCurrentSessionQuery,
    GetContractorsContainerCurrentSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetContractorsContainerCurrentSessionQuery,
    GetContractorsContainerCurrentSessionQueryVariables
  >(GetContractorsContainerCurrentSessionDocument, options);
}
export function useGetContractorsContainerCurrentSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractorsContainerCurrentSessionQuery,
    GetContractorsContainerCurrentSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetContractorsContainerCurrentSessionQuery,
    GetContractorsContainerCurrentSessionQueryVariables
  >(GetContractorsContainerCurrentSessionDocument, options);
}
export function useGetContractorsContainerCurrentSessionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetContractorsContainerCurrentSessionQuery,
    GetContractorsContainerCurrentSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetContractorsContainerCurrentSessionQuery,
    GetContractorsContainerCurrentSessionQueryVariables
  >(GetContractorsContainerCurrentSessionDocument, options);
}
export type GetContractorsContainerCurrentSessionQueryHookResult = ReturnType<
  typeof useGetContractorsContainerCurrentSessionQuery
>;
export type GetContractorsContainerCurrentSessionLazyQueryHookResult = ReturnType<
  typeof useGetContractorsContainerCurrentSessionLazyQuery
>;
export type GetContractorsContainerCurrentSessionSuspenseQueryHookResult = ReturnType<
  typeof useGetContractorsContainerCurrentSessionSuspenseQuery
>;
export type GetContractorsContainerCurrentSessionQueryResult = Apollo.QueryResult<
  GetContractorsContainerCurrentSessionQuery,
  GetContractorsContainerCurrentSessionQueryVariables
>;
export const GenerateLookerJobsiteWorkerReportDocument = gql`
  mutation GenerateLookerJobsiteWorkerReport(
    $jobsiteIds: [ID!]
    $startDate: Date!
    $endDate: Date!
    $realtime: Boolean
    $limit: Int
    $offset: Int
    $sortField: String
    $sortDir: String
    $export: Boolean
    $trade: String
    $tradeClass: String
    $contractor: String
    $active: Boolean
    $compliant: Boolean
  ) {
    generateLookerJobsiteWorkerReport(
      jobsiteIds: $jobsiteIds
      startDate: $startDate
      endDate: $endDate
      realtime: $realtime
      limit: $limit
      offset: $offset
      sortField: $sortField
      sortDir: $sortDir
      export: $export
      trade: $trade
      tradeClass: $tradeClass
      contractor: $contractor
      active: $active
      compliant: $compliant
    ) {
      fileId
      fileType
      uploadedAt
      downloadUrl
      originalFileName
    }
  }
`;
export type GenerateLookerJobsiteWorkerReportMutationFn = Apollo.MutationFunction<
  GenerateLookerJobsiteWorkerReportMutation,
  GenerateLookerJobsiteWorkerReportMutationVariables
>;

/**
 * __useGenerateLookerJobsiteWorkerReportMutation__
 *
 * To run a mutation, you first call `useGenerateLookerJobsiteWorkerReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateLookerJobsiteWorkerReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateLookerJobsiteWorkerReportMutation, { data, loading, error }] = useGenerateLookerJobsiteWorkerReportMutation({
 *   variables: {
 *      jobsiteIds: // value for 'jobsiteIds'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      realtime: // value for 'realtime'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sortField: // value for 'sortField'
 *      sortDir: // value for 'sortDir'
 *      export: // value for 'export'
 *      trade: // value for 'trade'
 *      tradeClass: // value for 'tradeClass'
 *      contractor: // value for 'contractor'
 *      active: // value for 'active'
 *      compliant: // value for 'compliant'
 *   },
 * });
 */
export function useGenerateLookerJobsiteWorkerReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateLookerJobsiteWorkerReportMutation,
    GenerateLookerJobsiteWorkerReportMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateLookerJobsiteWorkerReportMutation,
    GenerateLookerJobsiteWorkerReportMutationVariables
  >(GenerateLookerJobsiteWorkerReportDocument, options);
}
export type GenerateLookerJobsiteWorkerReportMutationHookResult = ReturnType<
  typeof useGenerateLookerJobsiteWorkerReportMutation
>;
export type GenerateLookerJobsiteWorkerReportMutationResult =
  Apollo.MutationResult<GenerateLookerJobsiteWorkerReportMutation>;
export type GenerateLookerJobsiteWorkerReportMutationOptions = Apollo.BaseMutationOptions<
  GenerateLookerJobsiteWorkerReportMutation,
  GenerateLookerJobsiteWorkerReportMutationVariables
>;
export const GetJobsiteWorkerDashboardDocument = gql`
  query GetJobsiteWorkerDashboard(
    $jobsiteIds: [ID!]
    $startDate: Date!
    $endDate: Date!
    $timeBreakdown: GetJobsiteWorkerReportsTimeBreakdown
    $realtime: Boolean
    $limit: Int
    $offset: Int
    $sortField: String
    $sortDir: String
    $export: Boolean
    $trade: String
    $tradeClass: String
    $contractor: String
    $fitoutTenant: String
    $active: Boolean
    $compliant: Boolean
    $overnight: Boolean
    $workerIds: [ID!]
  ) {
    getJobsiteWorkerDashboard(
      jobsiteIds: $jobsiteIds
      startDate: $startDate
      endDate: $endDate
      timeBreakdown: $timeBreakdown
      realtime: $realtime
      limit: $limit
      offset: $offset
      sortField: $sortField
      sortDir: $sortDir
      export: $export
      trade: $trade
      tradeClass: $tradeClass
      contractor: $contractor
      fitoutTenant: $fitoutTenant
      active: $active
      compliant: $compliant
      overnight: $overnight
      workerIds: $workerIds
    ) {
      jobsites {
        jobsiteId
        name
      }
      contractorBreakdowns {
        name
        count
        percentage
      }
      tradeBreakdowns {
        name
        count
        percentage
      }
      tradeClassBreakdowns {
        name
        count
        percentage
      }
      activeBreakdowns {
        name
        count
        percentage
      }
      compliantBreakdowns {
        name
        count
        percentage
      }
      jobsiteBreakdowns {
        name
        count
        percentage
      }
      overviewBreakdowns {
        name
        count
        compliantCount
        percentage
      }
      dailyBreakdowns {
        name
        count
      }
      languageBreakdowns {
        name
        count
        percentage
      }
      genderBreakdowns {
        name
        count
        percentage
      }
      raceBreakdowns {
        name
        count
        percentage
      }
      zipcodeBreakdowns {
        name
        count
        percentage
      }
      ageBreakdowns {
        name
        count
        percentage
      }
      onboardedBreakdowns {
        name
        count
        percentage
      }
      bannedBreakdowns {
        name
        count
        percentage
      }
      totalCount
      results {
        swipeSessionsSessionId
        swipeSessionsJobsiteWorkerId
        contractorOrganizationName
        jobsiteName
        profile
        swipeSessionsFirstInboundSwipeTimeOfDay
        swipeSessionsLastOutboundSwipeTimeOfDay
        swipeSessionsNetTotalSpanHours
        swipeSessionsDisplayValueSpanHours
        swipeSessionsOvernightSession
        swipeSessionsSessionDate
        userIdentityUserFullname
        workerWorkerId
        workerJobTitle
        workerTrade
        workerUnionAffiliation
        workerQuickCode
        croppedDownloadKey
        publicUrl
      }
    }
  }
`;

/**
 * __useGetJobsiteWorkerDashboardQuery__
 *
 * To run a query within a React component, call `useGetJobsiteWorkerDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobsiteWorkerDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobsiteWorkerDashboardQuery({
 *   variables: {
 *      jobsiteIds: // value for 'jobsiteIds'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      timeBreakdown: // value for 'timeBreakdown'
 *      realtime: // value for 'realtime'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sortField: // value for 'sortField'
 *      sortDir: // value for 'sortDir'
 *      export: // value for 'export'
 *      trade: // value for 'trade'
 *      tradeClass: // value for 'tradeClass'
 *      contractor: // value for 'contractor'
 *      fitoutTenant: // value for 'fitoutTenant'
 *      active: // value for 'active'
 *      compliant: // value for 'compliant'
 *      overnight: // value for 'overnight'
 *      workerIds: // value for 'workerIds'
 *   },
 * });
 */
export function useGetJobsiteWorkerDashboardQuery(
  baseOptions: Apollo.QueryHookOptions<GetJobsiteWorkerDashboardQuery, GetJobsiteWorkerDashboardQueryVariables> &
    ({ variables: GetJobsiteWorkerDashboardQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetJobsiteWorkerDashboardQuery, GetJobsiteWorkerDashboardQueryVariables>(
    GetJobsiteWorkerDashboardDocument,
    options,
  );
}
export function useGetJobsiteWorkerDashboardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetJobsiteWorkerDashboardQuery, GetJobsiteWorkerDashboardQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetJobsiteWorkerDashboardQuery, GetJobsiteWorkerDashboardQueryVariables>(
    GetJobsiteWorkerDashboardDocument,
    options,
  );
}
export function useGetJobsiteWorkerDashboardSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetJobsiteWorkerDashboardQuery,
    GetJobsiteWorkerDashboardQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetJobsiteWorkerDashboardQuery, GetJobsiteWorkerDashboardQueryVariables>(
    GetJobsiteWorkerDashboardDocument,
    options,
  );
}
export type GetJobsiteWorkerDashboardQueryHookResult = ReturnType<typeof useGetJobsiteWorkerDashboardQuery>;
export type GetJobsiteWorkerDashboardLazyQueryHookResult = ReturnType<typeof useGetJobsiteWorkerDashboardLazyQuery>;
export type GetJobsiteWorkerDashboardSuspenseQueryHookResult = ReturnType<
  typeof useGetJobsiteWorkerDashboardSuspenseQuery
>;
export type GetJobsiteWorkerDashboardQueryResult = Apollo.QueryResult<
  GetJobsiteWorkerDashboardQuery,
  GetJobsiteWorkerDashboardQueryVariables
>;
export const GetDeveloperContainerDeveloperInfoDocument = gql`
  query GetDeveloperContainerDeveloperInfo($developerId: ID!, $includeArchived: Boolean!) {
    getDeveloper(developerId: $developerId, includeArchived: $includeArchived) {
      developerId
      organization {
        organizationId
        name
        slug
        legalName
        email
        phoneNumber
        addressLine1
        addressLine2
        addressCity
        addressCity
        addressState
        addressZipCode
        objectHistory {
          createdAt
          createdBy {
            userAccountId
            identity {
              firstName
              lastName
            }
          }
          updatedAt
          updatedBy {
            userAccountId
            identity {
              firstName
              lastName
            }
          }
        }
      }
      jobsites {
        count
        edges {
          node {
            name
            parentId
            jobsiteId
            addressLine1
            addressLine2
            addressCity
            addressState
            addressZipCode
            startDate
            endDate
            workerAccessEnabled
            avigilonEnabled
            jobsiteWorkers {
              count
            }
            featuresModule {
              announcementsEnabled
              archivingAccessEventsEnabled
            }
            developer {
              developerId
              name
            }
            jobsiteCardFormats {
              __typename
              count
              edges {
                node {
                  jobsiteCardFormatId
                  isDefaultForCardType
                  workerCardFormat {
                    workerCardFormatId
                    name
                    description
                    cardType
                    facilityCode
                  }
                }
              }
            }
            jobsiteMembers {
              count
            }
          }
        }
      }
      objectHistory {
        createdAt
        createdBy {
          identity {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

/**
 * __useGetDeveloperContainerDeveloperInfoQuery__
 *
 * To run a query within a React component, call `useGetDeveloperContainerDeveloperInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeveloperContainerDeveloperInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeveloperContainerDeveloperInfoQuery({
 *   variables: {
 *      developerId: // value for 'developerId'
 *      includeArchived: // value for 'includeArchived'
 *   },
 * });
 */
export function useGetDeveloperContainerDeveloperInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDeveloperContainerDeveloperInfoQuery,
    GetDeveloperContainerDeveloperInfoQueryVariables
  > &
    ({ variables: GetDeveloperContainerDeveloperInfoQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDeveloperContainerDeveloperInfoQuery, GetDeveloperContainerDeveloperInfoQueryVariables>(
    GetDeveloperContainerDeveloperInfoDocument,
    options,
  );
}
export function useGetDeveloperContainerDeveloperInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDeveloperContainerDeveloperInfoQuery,
    GetDeveloperContainerDeveloperInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDeveloperContainerDeveloperInfoQuery, GetDeveloperContainerDeveloperInfoQueryVariables>(
    GetDeveloperContainerDeveloperInfoDocument,
    options,
  );
}
export function useGetDeveloperContainerDeveloperInfoSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetDeveloperContainerDeveloperInfoQuery,
    GetDeveloperContainerDeveloperInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetDeveloperContainerDeveloperInfoQuery,
    GetDeveloperContainerDeveloperInfoQueryVariables
  >(GetDeveloperContainerDeveloperInfoDocument, options);
}
export type GetDeveloperContainerDeveloperInfoQueryHookResult = ReturnType<
  typeof useGetDeveloperContainerDeveloperInfoQuery
>;
export type GetDeveloperContainerDeveloperInfoLazyQueryHookResult = ReturnType<
  typeof useGetDeveloperContainerDeveloperInfoLazyQuery
>;
export type GetDeveloperContainerDeveloperInfoSuspenseQueryHookResult = ReturnType<
  typeof useGetDeveloperContainerDeveloperInfoSuspenseQuery
>;
export type GetDeveloperContainerDeveloperInfoQueryResult = Apollo.QueryResult<
  GetDeveloperContainerDeveloperInfoQuery,
  GetDeveloperContainerDeveloperInfoQueryVariables
>;
export const GetDeveloperWorkersContainerCurrentSessionDocument = gql`
  query GetDeveloperWorkersContainerCurrentSession($userWorkersInput: UserWorkersInput) {
    getCurrentSession {
      user {
        userAccountId
        workers(input: $userWorkersInput) {
          count
          edges {
            node {
              workerId
              birthDate
              quickCode
              trade
              user {
                userAccountId
                identity {
                  firstName
                  lastName
                  email
                }
              }
              profilePictureCropped {
                downloadUrl
                uploadedAt
                fileId
                fileType
              }
              workerCards {
                count
                edges {
                  node {
                    cardNumber
                    cardStatus
                    workerCardId
                  }
                }
              }
              jobsiteWorkers {
                edges {
                  node {
                    jobsiteWorkerId
                    jobsiteContractor {
                      jobsite {
                        jobsiteId
                        name
                      }
                      contractor {
                        contractorId
                        organization {
                          name
                        }
                      }
                    }
                    currentAccess {
                      isAllowed
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetDeveloperWorkersContainerCurrentSessionQuery__
 *
 * To run a query within a React component, call `useGetDeveloperWorkersContainerCurrentSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeveloperWorkersContainerCurrentSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeveloperWorkersContainerCurrentSessionQuery({
 *   variables: {
 *      userWorkersInput: // value for 'userWorkersInput'
 *   },
 * });
 */
export function useGetDeveloperWorkersContainerCurrentSessionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDeveloperWorkersContainerCurrentSessionQuery,
    GetDeveloperWorkersContainerCurrentSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDeveloperWorkersContainerCurrentSessionQuery,
    GetDeveloperWorkersContainerCurrentSessionQueryVariables
  >(GetDeveloperWorkersContainerCurrentSessionDocument, options);
}
export function useGetDeveloperWorkersContainerCurrentSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDeveloperWorkersContainerCurrentSessionQuery,
    GetDeveloperWorkersContainerCurrentSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDeveloperWorkersContainerCurrentSessionQuery,
    GetDeveloperWorkersContainerCurrentSessionQueryVariables
  >(GetDeveloperWorkersContainerCurrentSessionDocument, options);
}
export function useGetDeveloperWorkersContainerCurrentSessionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetDeveloperWorkersContainerCurrentSessionQuery,
    GetDeveloperWorkersContainerCurrentSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetDeveloperWorkersContainerCurrentSessionQuery,
    GetDeveloperWorkersContainerCurrentSessionQueryVariables
  >(GetDeveloperWorkersContainerCurrentSessionDocument, options);
}
export type GetDeveloperWorkersContainerCurrentSessionQueryHookResult = ReturnType<
  typeof useGetDeveloperWorkersContainerCurrentSessionQuery
>;
export type GetDeveloperWorkersContainerCurrentSessionLazyQueryHookResult = ReturnType<
  typeof useGetDeveloperWorkersContainerCurrentSessionLazyQuery
>;
export type GetDeveloperWorkersContainerCurrentSessionSuspenseQueryHookResult = ReturnType<
  typeof useGetDeveloperWorkersContainerCurrentSessionSuspenseQuery
>;
export type GetDeveloperWorkersContainerCurrentSessionQueryResult = Apollo.QueryResult<
  GetDeveloperWorkersContainerCurrentSessionQuery,
  GetDeveloperWorkersContainerCurrentSessionQueryVariables
>;
export const DeveloperCreateDocument = gql`
  mutation DeveloperCreate($input: DeveloperCreateInput!) {
    developerCreate(input: $input) {
      developer {
        name
        developerId
        organization {
          name
          slug
          organizationId
        }
      }
    }
  }
`;
export type DeveloperCreateMutationFn = Apollo.MutationFunction<
  DeveloperCreateMutation,
  DeveloperCreateMutationVariables
>;

/**
 * __useDeveloperCreateMutation__
 *
 * To run a mutation, you first call `useDeveloperCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeveloperCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [developerCreateMutation, { data, loading, error }] = useDeveloperCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeveloperCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<DeveloperCreateMutation, DeveloperCreateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeveloperCreateMutation, DeveloperCreateMutationVariables>(
    DeveloperCreateDocument,
    options,
  );
}
export type DeveloperCreateMutationHookResult = ReturnType<typeof useDeveloperCreateMutation>;
export type DeveloperCreateMutationResult = Apollo.MutationResult<DeveloperCreateMutation>;
export type DeveloperCreateMutationOptions = Apollo.BaseMutationOptions<
  DeveloperCreateMutation,
  DeveloperCreateMutationVariables
>;
export const DeveloperUpdateDocument = gql`
  mutation DeveloperUpdate($input: DeveloperUpdateInput!) {
    developerUpdate(input: $input) {
      developer {
        name
        developerId
        organization {
          name
          slug
          organizationId
        }
      }
    }
  }
`;
export type DeveloperUpdateMutationFn = Apollo.MutationFunction<
  DeveloperUpdateMutation,
  DeveloperUpdateMutationVariables
>;

/**
 * __useDeveloperUpdateMutation__
 *
 * To run a mutation, you first call `useDeveloperUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeveloperUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [developerUpdateMutation, { data, loading, error }] = useDeveloperUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeveloperUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<DeveloperUpdateMutation, DeveloperUpdateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeveloperUpdateMutation, DeveloperUpdateMutationVariables>(
    DeveloperUpdateDocument,
    options,
  );
}
export type DeveloperUpdateMutationHookResult = ReturnType<typeof useDeveloperUpdateMutation>;
export type DeveloperUpdateMutationResult = Apollo.MutationResult<DeveloperUpdateMutation>;
export type DeveloperUpdateMutationOptions = Apollo.BaseMutationOptions<
  DeveloperUpdateMutation,
  DeveloperUpdateMutationVariables
>;
export const GetDevelopersContainerCurrentSessionDocument = gql`
  query GetDevelopersContainerCurrentSession($userDevelopersInput: UserDevelopersInput) {
    getCurrentSession {
      user {
        userAccountId
        developers(input: $userDevelopersInput) {
          count
          edges {
            node {
              developerId
              organization {
                organizationId
                name
                addressState
                addressCity
              }
              jobsites {
                count
                edges {
                  node {
                    jobsiteId
                    name
                    startDate
                    endDate
                    jobsiteWorkers {
                      count
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetDevelopersContainerCurrentSessionQuery__
 *
 * To run a query within a React component, call `useGetDevelopersContainerCurrentSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDevelopersContainerCurrentSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDevelopersContainerCurrentSessionQuery({
 *   variables: {
 *      userDevelopersInput: // value for 'userDevelopersInput'
 *   },
 * });
 */
export function useGetDevelopersContainerCurrentSessionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDevelopersContainerCurrentSessionQuery,
    GetDevelopersContainerCurrentSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDevelopersContainerCurrentSessionQuery, GetDevelopersContainerCurrentSessionQueryVariables>(
    GetDevelopersContainerCurrentSessionDocument,
    options,
  );
}
export function useGetDevelopersContainerCurrentSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDevelopersContainerCurrentSessionQuery,
    GetDevelopersContainerCurrentSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDevelopersContainerCurrentSessionQuery,
    GetDevelopersContainerCurrentSessionQueryVariables
  >(GetDevelopersContainerCurrentSessionDocument, options);
}
export function useGetDevelopersContainerCurrentSessionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetDevelopersContainerCurrentSessionQuery,
    GetDevelopersContainerCurrentSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetDevelopersContainerCurrentSessionQuery,
    GetDevelopersContainerCurrentSessionQueryVariables
  >(GetDevelopersContainerCurrentSessionDocument, options);
}
export type GetDevelopersContainerCurrentSessionQueryHookResult = ReturnType<
  typeof useGetDevelopersContainerCurrentSessionQuery
>;
export type GetDevelopersContainerCurrentSessionLazyQueryHookResult = ReturnType<
  typeof useGetDevelopersContainerCurrentSessionLazyQuery
>;
export type GetDevelopersContainerCurrentSessionSuspenseQueryHookResult = ReturnType<
  typeof useGetDevelopersContainerCurrentSessionSuspenseQuery
>;
export type GetDevelopersContainerCurrentSessionQueryResult = Apollo.QueryResult<
  GetDevelopersContainerCurrentSessionQuery,
  GetDevelopersContainerCurrentSessionQueryVariables
>;
export const GetChangeWorkerDocument = gql`
  query GetChangeWorker($workerId: ID!) {
    getWorker(workerId: $workerId, includeArchived: true) {
      workerId
      quickCode
      createdMethod
      isArchived
      objectHistory {
        createdAt
        createdBy {
          userAccountId
          identity {
            firstName
            lastName
            email
          }
        }
        updatedAt
        updatedBy {
          userAccountId
          identity {
            firstName
            lastName
            email
          }
        }
      }
      user {
        userAccountId
        identity {
          id
          firstName
          lastName
          email
        }
        jobsiteMembers {
          edges {
            node {
              id
              title
              jobsite {
                jobsiteId
                name
                timeZone
              }
            }
          }
        }
        roles(input: { includeArchived: true }) {
          key
          mappingId
          displayName
          object {
            objectId
            objectName
            objectDetails {
              objectMemberId
              type
              name
              title
            }
          }
        }
      }
      jobsiteWorkers(input: { includeArchived: true }) {
        edges {
          node {
            jobsiteWorkerId
            jobsiteContractor {
              jobsite {
                jobsiteId
                name
                timeZone
              }
              contractor {
                contractorId
                organization {
                  organizationId
                  name
                }
              }
            }
            currentAccess {
              id
              isAllowed
              notAllowedReason
              endDate
            }
          }
        }
      }
      documents {
        edges {
          node {
            jobsiteWorkerDocumentId
            jobsiteWorkerDocumentType {
              jobsite {
                name
                jobsiteId
                developer {
                  name
                  developerId
                }
              }
              workerDocumentType {
                name
                key
                expirationDate
                documentLevel
                isGlobal
                isPhi
              }
            }
            latestVersion {
              jobsiteWorkerDocumentVersionId
              files {
                fileId
                fileType
                downloadUrl
              }
              additionalFieldValues {
                key
                value
              }
            }
          }
        }
      }
      workerCards(input: { includeArchived: true }) {
        edges {
          node {
            workerCardId
            cardNumber
          }
        }
      }
    }
  }
`;

/**
 * __useGetChangeWorkerQuery__
 *
 * To run a query within a React component, call `useGetChangeWorkerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChangeWorkerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChangeWorkerQuery({
 *   variables: {
 *      workerId: // value for 'workerId'
 *   },
 * });
 */
export function useGetChangeWorkerQuery(
  baseOptions: Apollo.QueryHookOptions<GetChangeWorkerQuery, GetChangeWorkerQueryVariables> &
    ({ variables: GetChangeWorkerQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChangeWorkerQuery, GetChangeWorkerQueryVariables>(GetChangeWorkerDocument, options);
}
export function useGetChangeWorkerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetChangeWorkerQuery, GetChangeWorkerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChangeWorkerQuery, GetChangeWorkerQueryVariables>(GetChangeWorkerDocument, options);
}
export function useGetChangeWorkerSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetChangeWorkerQuery, GetChangeWorkerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetChangeWorkerQuery, GetChangeWorkerQueryVariables>(GetChangeWorkerDocument, options);
}
export type GetChangeWorkerQueryHookResult = ReturnType<typeof useGetChangeWorkerQuery>;
export type GetChangeWorkerLazyQueryHookResult = ReturnType<typeof useGetChangeWorkerLazyQuery>;
export type GetChangeWorkerSuspenseQueryHookResult = ReturnType<typeof useGetChangeWorkerSuspenseQuery>;
export type GetChangeWorkerQueryResult = Apollo.QueryResult<GetChangeWorkerQuery, GetChangeWorkerQueryVariables>;
export const GetEntityChangesDocument = gql`
  query GetEntityChanges($input: GetEntityChangesInput) {
    getEntityChanges(input: $input) {
      count
      edges {
        node {
          id
          tableName
          entityId
          entityDbId
          apiDbHandler
          values
          objectHistory {
            createdAt
            createdBy {
              userAccountId
              identity {
                firstName
                lastName
                email
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetEntityChangesQuery__
 *
 * To run a query within a React component, call `useGetEntityChangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntityChangesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntityChangesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEntityChangesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetEntityChangesQuery, GetEntityChangesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEntityChangesQuery, GetEntityChangesQueryVariables>(GetEntityChangesDocument, options);
}
export function useGetEntityChangesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetEntityChangesQuery, GetEntityChangesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEntityChangesQuery, GetEntityChangesQueryVariables>(GetEntityChangesDocument, options);
}
export function useGetEntityChangesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetEntityChangesQuery, GetEntityChangesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetEntityChangesQuery, GetEntityChangesQueryVariables>(
    GetEntityChangesDocument,
    options,
  );
}
export type GetEntityChangesQueryHookResult = ReturnType<typeof useGetEntityChangesQuery>;
export type GetEntityChangesLazyQueryHookResult = ReturnType<typeof useGetEntityChangesLazyQuery>;
export type GetEntityChangesSuspenseQueryHookResult = ReturnType<typeof useGetEntityChangesSuspenseQuery>;
export type GetEntityChangesQueryResult = Apollo.QueryResult<GetEntityChangesQuery, GetEntityChangesQueryVariables>;
export const RegisterWorkerForFacialRecognitionBadgeDocument = gql`
  mutation registerWorkerForFacialRecognitionBadge($workerId: ID!, $workerCardId: ID!, $newPhoneNumber: String) {
    registerWorkerForFacialRecognitionBadge(
      workerId: $workerId
      workerCardId: $workerCardId
      newPhoneNumber: $newPhoneNumber
    ) {
      workerCardId
      cardStatus
      cardNumber
      workerCardFormat {
        name
        cardType
        facilityCode
      }
    }
  }
`;
export type RegisterWorkerForFacialRecognitionBadgeMutationFn = Apollo.MutationFunction<
  RegisterWorkerForFacialRecognitionBadgeMutation,
  RegisterWorkerForFacialRecognitionBadgeMutationVariables
>;

/**
 * __useRegisterWorkerForFacialRecognitionBadgeMutation__
 *
 * To run a mutation, you first call `useRegisterWorkerForFacialRecognitionBadgeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterWorkerForFacialRecognitionBadgeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerWorkerForFacialRecognitionBadgeMutation, { data, loading, error }] = useRegisterWorkerForFacialRecognitionBadgeMutation({
 *   variables: {
 *      workerId: // value for 'workerId'
 *      workerCardId: // value for 'workerCardId'
 *      newPhoneNumber: // value for 'newPhoneNumber'
 *   },
 * });
 */
export function useRegisterWorkerForFacialRecognitionBadgeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterWorkerForFacialRecognitionBadgeMutation,
    RegisterWorkerForFacialRecognitionBadgeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegisterWorkerForFacialRecognitionBadgeMutation,
    RegisterWorkerForFacialRecognitionBadgeMutationVariables
  >(RegisterWorkerForFacialRecognitionBadgeDocument, options);
}
export type RegisterWorkerForFacialRecognitionBadgeMutationHookResult = ReturnType<
  typeof useRegisterWorkerForFacialRecognitionBadgeMutation
>;
export type RegisterWorkerForFacialRecognitionBadgeMutationResult =
  Apollo.MutationResult<RegisterWorkerForFacialRecognitionBadgeMutation>;
export type RegisterWorkerForFacialRecognitionBadgeMutationOptions = Apollo.BaseMutationOptions<
  RegisterWorkerForFacialRecognitionBadgeMutation,
  RegisterWorkerForFacialRecognitionBadgeMutationVariables
>;
export const GenerateFormSubmissionsReportDocument = gql`
  mutation GenerateFormSubmissionsReport($input: FormSubmissionsReportInput!) {
    generateFormSubmissionsReport(input: $input) {
      fileId
      fileType
      uploadedAt
      downloadUrl
      originalFileName
    }
  }
`;
export type GenerateFormSubmissionsReportMutationFn = Apollo.MutationFunction<
  GenerateFormSubmissionsReportMutation,
  GenerateFormSubmissionsReportMutationVariables
>;

/**
 * __useGenerateFormSubmissionsReportMutation__
 *
 * To run a mutation, you first call `useGenerateFormSubmissionsReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateFormSubmissionsReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateFormSubmissionsReportMutation, { data, loading, error }] = useGenerateFormSubmissionsReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateFormSubmissionsReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateFormSubmissionsReportMutation,
    GenerateFormSubmissionsReportMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GenerateFormSubmissionsReportMutation, GenerateFormSubmissionsReportMutationVariables>(
    GenerateFormSubmissionsReportDocument,
    options,
  );
}
export type GenerateFormSubmissionsReportMutationHookResult = ReturnType<
  typeof useGenerateFormSubmissionsReportMutation
>;
export type GenerateFormSubmissionsReportMutationResult = Apollo.MutationResult<GenerateFormSubmissionsReportMutation>;
export type GenerateFormSubmissionsReportMutationOptions = Apollo.BaseMutationOptions<
  GenerateFormSubmissionsReportMutation,
  GenerateFormSubmissionsReportMutationVariables
>;
export const JobsiteArchiveFormSubmissionDocument = gql`
  mutation JobsiteArchiveFormSubmission($input: JobsiteArchiveFormSubmissionInput!) {
    jobsiteArchiveFormSubmission(input: $input) {
      success {
        success
      }
    }
  }
`;
export type JobsiteArchiveFormSubmissionMutationFn = Apollo.MutationFunction<
  JobsiteArchiveFormSubmissionMutation,
  JobsiteArchiveFormSubmissionMutationVariables
>;

/**
 * __useJobsiteArchiveFormSubmissionMutation__
 *
 * To run a mutation, you first call `useJobsiteArchiveFormSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobsiteArchiveFormSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobsiteArchiveFormSubmissionMutation, { data, loading, error }] = useJobsiteArchiveFormSubmissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJobsiteArchiveFormSubmissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JobsiteArchiveFormSubmissionMutation,
    JobsiteArchiveFormSubmissionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<JobsiteArchiveFormSubmissionMutation, JobsiteArchiveFormSubmissionMutationVariables>(
    JobsiteArchiveFormSubmissionDocument,
    options,
  );
}
export type JobsiteArchiveFormSubmissionMutationHookResult = ReturnType<typeof useJobsiteArchiveFormSubmissionMutation>;
export type JobsiteArchiveFormSubmissionMutationResult = Apollo.MutationResult<JobsiteArchiveFormSubmissionMutation>;
export type JobsiteArchiveFormSubmissionMutationOptions = Apollo.BaseMutationOptions<
  JobsiteArchiveFormSubmissionMutation,
  JobsiteArchiveFormSubmissionMutationVariables
>;
export const JobsiteCreateFormSubmissionDocument = gql`
  mutation JobsiteCreateFormSubmission($input: JobsiteCreateFormSubmissionInput!) {
    jobsiteCreateFormSubmission(input: $input) {
      jobsiteFormSubmission {
        id
      }
    }
  }
`;
export type JobsiteCreateFormSubmissionMutationFn = Apollo.MutationFunction<
  JobsiteCreateFormSubmissionMutation,
  JobsiteCreateFormSubmissionMutationVariables
>;

/**
 * __useJobsiteCreateFormSubmissionMutation__
 *
 * To run a mutation, you first call `useJobsiteCreateFormSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobsiteCreateFormSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobsiteCreateFormSubmissionMutation, { data, loading, error }] = useJobsiteCreateFormSubmissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJobsiteCreateFormSubmissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JobsiteCreateFormSubmissionMutation,
    JobsiteCreateFormSubmissionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<JobsiteCreateFormSubmissionMutation, JobsiteCreateFormSubmissionMutationVariables>(
    JobsiteCreateFormSubmissionDocument,
    options,
  );
}
export type JobsiteCreateFormSubmissionMutationHookResult = ReturnType<typeof useJobsiteCreateFormSubmissionMutation>;
export type JobsiteCreateFormSubmissionMutationResult = Apollo.MutationResult<JobsiteCreateFormSubmissionMutation>;
export type JobsiteCreateFormSubmissionMutationOptions = Apollo.BaseMutationOptions<
  JobsiteCreateFormSubmissionMutation,
  JobsiteCreateFormSubmissionMutationVariables
>;
export const GetFormsDocument = gql`
  query GetForms($formsInput: UserFormsInput) {
    getCurrentSession {
      user {
        forms(input: $formsInput) {
          edges {
            node {
              id
              key
              name
              content
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetFormsQuery__
 *
 * To run a query within a React component, call `useGetFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormsQuery({
 *   variables: {
 *      formsInput: // value for 'formsInput'
 *   },
 * });
 */
export function useGetFormsQuery(baseOptions?: Apollo.QueryHookOptions<GetFormsQuery, GetFormsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFormsQuery, GetFormsQueryVariables>(GetFormsDocument, options);
}
export function useGetFormsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFormsQuery, GetFormsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFormsQuery, GetFormsQueryVariables>(GetFormsDocument, options);
}
export function useGetFormsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetFormsQuery, GetFormsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetFormsQuery, GetFormsQueryVariables>(GetFormsDocument, options);
}
export type GetFormsQueryHookResult = ReturnType<typeof useGetFormsQuery>;
export type GetFormsLazyQueryHookResult = ReturnType<typeof useGetFormsLazyQuery>;
export type GetFormsSuspenseQueryHookResult = ReturnType<typeof useGetFormsSuspenseQuery>;
export type GetFormsQueryResult = Apollo.QueryResult<GetFormsQuery, GetFormsQueryVariables>;
export const GetJobsiteFormSubmissionsDocument = gql`
  query GetJobsiteFormSubmissions($jobsiteFormSubmissionsInput: UserJobsiteFormSubmissionsInput) {
    getCurrentSession {
      user {
        jobsiteFormSubmissions(input: $jobsiteFormSubmissionsInput) {
          count
          edges {
            node {
              id
              startAt
              isStartTimeSpecified
              endAt
              timeZone
              extraData
              objectHistory {
                createdAt
                createdBy {
                  userAccountId
                  identity {
                    firstName
                    lastName
                  }
                  worker {
                    workerId
                    profilePictureCropped {
                      downloadUrl
                    }
                  }
                }
              }
              jobsiteForm {
                id
                jobsite {
                  jobsiteId
                  name
                }
                form {
                  id
                  key
                  name
                }
              }
              jobsiteContractors {
                edges {
                  node {
                    id
                    associationType
                    jobsiteContractor {
                      id
                      contractor {
                        contractorId
                        organization {
                          organizationId
                          name
                        }
                      }
                    }
                  }
                }
              }
              jobsiteWorkers {
                count
                edges {
                  node {
                    id
                    extraData
                    associationType
                    jobsiteWorker {
                      jobsiteWorkerId
                      contractorWorker {
                        worker {
                          workerId
                          user {
                            userAccountId
                            identity {
                              firstName
                              lastName
                            }
                          }
                          profilePictureCropped {
                            downloadUrl
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          filtersOptions {
            forms {
              value
              label
            }
            contractors {
              value
              label
            }
            createdByUsers {
              value
              label
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetJobsiteFormSubmissionsQuery__
 *
 * To run a query within a React component, call `useGetJobsiteFormSubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobsiteFormSubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobsiteFormSubmissionsQuery({
 *   variables: {
 *      jobsiteFormSubmissionsInput: // value for 'jobsiteFormSubmissionsInput'
 *   },
 * });
 */
export function useGetJobsiteFormSubmissionsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetJobsiteFormSubmissionsQuery, GetJobsiteFormSubmissionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetJobsiteFormSubmissionsQuery, GetJobsiteFormSubmissionsQueryVariables>(
    GetJobsiteFormSubmissionsDocument,
    options,
  );
}
export function useGetJobsiteFormSubmissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetJobsiteFormSubmissionsQuery, GetJobsiteFormSubmissionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetJobsiteFormSubmissionsQuery, GetJobsiteFormSubmissionsQueryVariables>(
    GetJobsiteFormSubmissionsDocument,
    options,
  );
}
export function useGetJobsiteFormSubmissionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetJobsiteFormSubmissionsQuery,
    GetJobsiteFormSubmissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetJobsiteFormSubmissionsQuery, GetJobsiteFormSubmissionsQueryVariables>(
    GetJobsiteFormSubmissionsDocument,
    options,
  );
}
export type GetJobsiteFormSubmissionsQueryHookResult = ReturnType<typeof useGetJobsiteFormSubmissionsQuery>;
export type GetJobsiteFormSubmissionsLazyQueryHookResult = ReturnType<typeof useGetJobsiteFormSubmissionsLazyQuery>;
export type GetJobsiteFormSubmissionsSuspenseQueryHookResult = ReturnType<
  typeof useGetJobsiteFormSubmissionsSuspenseQuery
>;
export type GetJobsiteFormSubmissionsQueryResult = Apollo.QueryResult<
  GetJobsiteFormSubmissionsQuery,
  GetJobsiteFormSubmissionsQueryVariables
>;
export const GetJobsiteFormsDocument = gql`
  query GetJobsiteForms($jobsiteFormsInput: UserJobsiteFormsInput) {
    getCurrentSession {
      user {
        jobsiteForms(input: $jobsiteFormsInput) {
          edges {
            node {
              id
              jobsite {
                jobsiteId
                name
                timeZone
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetJobsiteFormsQuery__
 *
 * To run a query within a React component, call `useGetJobsiteFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobsiteFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobsiteFormsQuery({
 *   variables: {
 *      jobsiteFormsInput: // value for 'jobsiteFormsInput'
 *   },
 * });
 */
export function useGetJobsiteFormsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetJobsiteFormsQuery, GetJobsiteFormsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetJobsiteFormsQuery, GetJobsiteFormsQueryVariables>(GetJobsiteFormsDocument, options);
}
export function useGetJobsiteFormsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetJobsiteFormsQuery, GetJobsiteFormsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetJobsiteFormsQuery, GetJobsiteFormsQueryVariables>(GetJobsiteFormsDocument, options);
}
export function useGetJobsiteFormsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetJobsiteFormsQuery, GetJobsiteFormsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetJobsiteFormsQuery, GetJobsiteFormsQueryVariables>(GetJobsiteFormsDocument, options);
}
export type GetJobsiteFormsQueryHookResult = ReturnType<typeof useGetJobsiteFormsQuery>;
export type GetJobsiteFormsLazyQueryHookResult = ReturnType<typeof useGetJobsiteFormsLazyQuery>;
export type GetJobsiteFormsSuspenseQueryHookResult = ReturnType<typeof useGetJobsiteFormsSuspenseQuery>;
export type GetJobsiteFormsQueryResult = Apollo.QueryResult<GetJobsiteFormsQuery, GetJobsiteFormsQueryVariables>;
export const GetCurrentSessionHomeContainerDocument = gql`
  query GetCurrentSessionHomeContainer {
    getCurrentSession {
      user {
        userAccountId
        jobsiteMembers {
          count
        }
        contractorMembers {
          count
        }
      }
    }
  }
`;

/**
 * __useGetCurrentSessionHomeContainerQuery__
 *
 * To run a query within a React component, call `useGetCurrentSessionHomeContainerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentSessionHomeContainerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentSessionHomeContainerQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentSessionHomeContainerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentSessionHomeContainerQuery,
    GetCurrentSessionHomeContainerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentSessionHomeContainerQuery, GetCurrentSessionHomeContainerQueryVariables>(
    GetCurrentSessionHomeContainerDocument,
    options,
  );
}
export function useGetCurrentSessionHomeContainerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentSessionHomeContainerQuery,
    GetCurrentSessionHomeContainerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentSessionHomeContainerQuery, GetCurrentSessionHomeContainerQueryVariables>(
    GetCurrentSessionHomeContainerDocument,
    options,
  );
}
export function useGetCurrentSessionHomeContainerSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetCurrentSessionHomeContainerQuery,
    GetCurrentSessionHomeContainerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCurrentSessionHomeContainerQuery, GetCurrentSessionHomeContainerQueryVariables>(
    GetCurrentSessionHomeContainerDocument,
    options,
  );
}
export type GetCurrentSessionHomeContainerQueryHookResult = ReturnType<typeof useGetCurrentSessionHomeContainerQuery>;
export type GetCurrentSessionHomeContainerLazyQueryHookResult = ReturnType<
  typeof useGetCurrentSessionHomeContainerLazyQuery
>;
export type GetCurrentSessionHomeContainerSuspenseQueryHookResult = ReturnType<
  typeof useGetCurrentSessionHomeContainerSuspenseQuery
>;
export type GetCurrentSessionHomeContainerQueryResult = Apollo.QueryResult<
  GetCurrentSessionHomeContainerQuery,
  GetCurrentSessionHomeContainerQueryVariables
>;
export const UpdateContractorWorkerDocument = gql`
  mutation UpdateContractorWorker(
    $workerId: ID!
    $workerInput: WorkerInput!
    $assignContractorWorkerToJobsiteInput: AssignContractorWorkerToJobsiteInput!
  ) {
    updateWorker(workerId: $workerId, workerInput: $workerInput) {
      birthDate
      ssnLastFour
      workerId
      user {
        userAccountId
        createdAt
        identity {
          firstName
          lastName
          createdAt
          updatedAt
        }
        roles {
          key
          displayName
          roleCreatedAt
          roleUpdatedAt
          mappingCreatedAt
        }
      }
    }
    assignContractorWorkerToJobsite(input: $assignContractorWorkerToJobsiteInput) {
      jobsiteWorker {
        jobsiteWorkerId
        orientationStatus
        contractorWorker {
          worker {
            birthDate
            ssnLastFour
            workerId
            user {
              userAccountId
              createdAt
              identity {
                email
                firstName
                lastName
                createdAt
                updatedAt
              }
              roles {
                key
                displayName
                roleCreatedAt
                roleUpdatedAt
                mappingCreatedAt
              }
            }
          }
        }
        jobsiteContractor {
          jobsite {
            description
            jobsiteId
            name
          }
        }
        startDate
        endDate
      }
    }
  }
`;
export type UpdateContractorWorkerMutationFn = Apollo.MutationFunction<
  UpdateContractorWorkerMutation,
  UpdateContractorWorkerMutationVariables
>;

/**
 * __useUpdateContractorWorkerMutation__
 *
 * To run a mutation, you first call `useUpdateContractorWorkerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractorWorkerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractorWorkerMutation, { data, loading, error }] = useUpdateContractorWorkerMutation({
 *   variables: {
 *      workerId: // value for 'workerId'
 *      workerInput: // value for 'workerInput'
 *      assignContractorWorkerToJobsiteInput: // value for 'assignContractorWorkerToJobsiteInput'
 *   },
 * });
 */
export function useUpdateContractorWorkerMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateContractorWorkerMutation, UpdateContractorWorkerMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateContractorWorkerMutation, UpdateContractorWorkerMutationVariables>(
    UpdateContractorWorkerDocument,
    options,
  );
}
export type UpdateContractorWorkerMutationHookResult = ReturnType<typeof useUpdateContractorWorkerMutation>;
export type UpdateContractorWorkerMutationResult = Apollo.MutationResult<UpdateContractorWorkerMutation>;
export type UpdateContractorWorkerMutationOptions = Apollo.BaseMutationOptions<
  UpdateContractorWorkerMutation,
  UpdateContractorWorkerMutationVariables
>;
export const GetIssueTempBadgeJobsiteDocument = gql`
  query GetIssueTempBadgeJobsite($jobsiteId: ID!) {
    getJobsite(jobsiteId: $jobsiteId) {
      jobsiteId
      name
      modules {
        ... on JobsiteOnboardingModule {
          name
          steps {
            key
            index
            isRequired
            isVisible
            fields {
              key
              name
              index
              isRequired
              isHidden
            }
          }
        }
      }
      jobsiteContractors {
        edges {
          node {
            contractor {
              contractorId
              organization {
                name
              }
              isDefaultContractor
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetIssueTempBadgeJobsiteQuery__
 *
 * To run a query within a React component, call `useGetIssueTempBadgeJobsiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIssueTempBadgeJobsiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIssueTempBadgeJobsiteQuery({
 *   variables: {
 *      jobsiteId: // value for 'jobsiteId'
 *   },
 * });
 */
export function useGetIssueTempBadgeJobsiteQuery(
  baseOptions: Apollo.QueryHookOptions<GetIssueTempBadgeJobsiteQuery, GetIssueTempBadgeJobsiteQueryVariables> &
    ({ variables: GetIssueTempBadgeJobsiteQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetIssueTempBadgeJobsiteQuery, GetIssueTempBadgeJobsiteQueryVariables>(
    GetIssueTempBadgeJobsiteDocument,
    options,
  );
}
export function useGetIssueTempBadgeJobsiteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetIssueTempBadgeJobsiteQuery, GetIssueTempBadgeJobsiteQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetIssueTempBadgeJobsiteQuery, GetIssueTempBadgeJobsiteQueryVariables>(
    GetIssueTempBadgeJobsiteDocument,
    options,
  );
}
export function useGetIssueTempBadgeJobsiteSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetIssueTempBadgeJobsiteQuery, GetIssueTempBadgeJobsiteQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetIssueTempBadgeJobsiteQuery, GetIssueTempBadgeJobsiteQueryVariables>(
    GetIssueTempBadgeJobsiteDocument,
    options,
  );
}
export type GetIssueTempBadgeJobsiteQueryHookResult = ReturnType<typeof useGetIssueTempBadgeJobsiteQuery>;
export type GetIssueTempBadgeJobsiteLazyQueryHookResult = ReturnType<typeof useGetIssueTempBadgeJobsiteLazyQuery>;
export type GetIssueTempBadgeJobsiteSuspenseQueryHookResult = ReturnType<
  typeof useGetIssueTempBadgeJobsiteSuspenseQuery
>;
export type GetIssueTempBadgeJobsiteQueryResult = Apollo.QueryResult<
  GetIssueTempBadgeJobsiteQuery,
  GetIssueTempBadgeJobsiteQueryVariables
>;
export const JobsiteAddContractorsDocument = gql`
  mutation JobsiteAddContractors($input: JobsiteAddContractorsInput!) {
    jobsiteAddContractors(input: $input) {
      jobsiteContractors {
        id
      }
    }
  }
`;
export type JobsiteAddContractorsMutationFn = Apollo.MutationFunction<
  JobsiteAddContractorsMutation,
  JobsiteAddContractorsMutationVariables
>;

/**
 * __useJobsiteAddContractorsMutation__
 *
 * To run a mutation, you first call `useJobsiteAddContractorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobsiteAddContractorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobsiteAddContractorsMutation, { data, loading, error }] = useJobsiteAddContractorsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJobsiteAddContractorsMutation(
  baseOptions?: Apollo.MutationHookOptions<JobsiteAddContractorsMutation, JobsiteAddContractorsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<JobsiteAddContractorsMutation, JobsiteAddContractorsMutationVariables>(
    JobsiteAddContractorsDocument,
    options,
  );
}
export type JobsiteAddContractorsMutationHookResult = ReturnType<typeof useJobsiteAddContractorsMutation>;
export type JobsiteAddContractorsMutationResult = Apollo.MutationResult<JobsiteAddContractorsMutation>;
export type JobsiteAddContractorsMutationOptions = Apollo.BaseMutationOptions<
  JobsiteAddContractorsMutation,
  JobsiteAddContractorsMutationVariables
>;
export const JobsiteRemoveContractorDocument = gql`
  mutation JobsiteRemoveContractor($input: JobsiteRemoveContractorInput!) {
    jobsiteRemoveContractor(input: $input) {
      success {
        success
      }
    }
  }
`;
export type JobsiteRemoveContractorMutationFn = Apollo.MutationFunction<
  JobsiteRemoveContractorMutation,
  JobsiteRemoveContractorMutationVariables
>;

/**
 * __useJobsiteRemoveContractorMutation__
 *
 * To run a mutation, you first call `useJobsiteRemoveContractorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobsiteRemoveContractorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobsiteRemoveContractorMutation, { data, loading, error }] = useJobsiteRemoveContractorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJobsiteRemoveContractorMutation(
  baseOptions?: Apollo.MutationHookOptions<JobsiteRemoveContractorMutation, JobsiteRemoveContractorMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<JobsiteRemoveContractorMutation, JobsiteRemoveContractorMutationVariables>(
    JobsiteRemoveContractorDocument,
    options,
  );
}
export type JobsiteRemoveContractorMutationHookResult = ReturnType<typeof useJobsiteRemoveContractorMutation>;
export type JobsiteRemoveContractorMutationResult = Apollo.MutationResult<JobsiteRemoveContractorMutation>;
export type JobsiteRemoveContractorMutationOptions = Apollo.BaseMutationOptions<
  JobsiteRemoveContractorMutation,
  JobsiteRemoveContractorMutationVariables
>;
export const JobsiteUpdateContractorDocument = gql`
  mutation JobsiteUpdateContractor($input: JobsiteUpdateContractorInput!) {
    jobsiteUpdateContractor(input: $input) {
      jobsiteContractor {
        id
      }
    }
  }
`;
export type JobsiteUpdateContractorMutationFn = Apollo.MutationFunction<
  JobsiteUpdateContractorMutation,
  JobsiteUpdateContractorMutationVariables
>;

/**
 * __useJobsiteUpdateContractorMutation__
 *
 * To run a mutation, you first call `useJobsiteUpdateContractorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobsiteUpdateContractorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobsiteUpdateContractorMutation, { data, loading, error }] = useJobsiteUpdateContractorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJobsiteUpdateContractorMutation(
  baseOptions?: Apollo.MutationHookOptions<JobsiteUpdateContractorMutation, JobsiteUpdateContractorMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<JobsiteUpdateContractorMutation, JobsiteUpdateContractorMutationVariables>(
    JobsiteUpdateContractorDocument,
    options,
  );
}
export type JobsiteUpdateContractorMutationHookResult = ReturnType<typeof useJobsiteUpdateContractorMutation>;
export type JobsiteUpdateContractorMutationResult = Apollo.MutationResult<JobsiteUpdateContractorMutation>;
export type JobsiteUpdateContractorMutationOptions = Apollo.BaseMutationOptions<
  JobsiteUpdateContractorMutation,
  JobsiteUpdateContractorMutationVariables
>;
export const GetContractorsAndJobsitesDocument = gql`
  query GetContractorsAndJobsites($userContractorsInput: UserContractorsInput!) {
    getCurrentSession {
      user {
        userAccountId
        contractors(input: $userContractorsInput) {
          count
          edges {
            node {
              contractorId
              jobsiteContractors {
                count
                edges {
                  node {
                    id
                    startDate
                    contractor {
                      contractorId
                      isDefaultContractor
                      organization {
                        name
                        slug
                        legalName
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetContractorsAndJobsitesQuery__
 *
 * To run a query within a React component, call `useGetContractorsAndJobsitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractorsAndJobsitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractorsAndJobsitesQuery({
 *   variables: {
 *      userContractorsInput: // value for 'userContractorsInput'
 *   },
 * });
 */
export function useGetContractorsAndJobsitesQuery(
  baseOptions: Apollo.QueryHookOptions<GetContractorsAndJobsitesQuery, GetContractorsAndJobsitesQueryVariables> &
    ({ variables: GetContractorsAndJobsitesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetContractorsAndJobsitesQuery, GetContractorsAndJobsitesQueryVariables>(
    GetContractorsAndJobsitesDocument,
    options,
  );
}
export function useGetContractorsAndJobsitesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetContractorsAndJobsitesQuery, GetContractorsAndJobsitesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetContractorsAndJobsitesQuery, GetContractorsAndJobsitesQueryVariables>(
    GetContractorsAndJobsitesDocument,
    options,
  );
}
export function useGetContractorsAndJobsitesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetContractorsAndJobsitesQuery,
    GetContractorsAndJobsitesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetContractorsAndJobsitesQuery, GetContractorsAndJobsitesQueryVariables>(
    GetContractorsAndJobsitesDocument,
    options,
  );
}
export type GetContractorsAndJobsitesQueryHookResult = ReturnType<typeof useGetContractorsAndJobsitesQuery>;
export type GetContractorsAndJobsitesLazyQueryHookResult = ReturnType<typeof useGetContractorsAndJobsitesLazyQuery>;
export type GetContractorsAndJobsitesSuspenseQueryHookResult = ReturnType<
  typeof useGetContractorsAndJobsitesSuspenseQuery
>;
export type GetContractorsAndJobsitesQueryResult = Apollo.QueryResult<
  GetContractorsAndJobsitesQuery,
  GetContractorsAndJobsitesQueryVariables
>;
export const GetJobsiteContainerDocument = gql`
  query GetJobsiteContainer($jobsiteId: ID!) {
    getJobsite(jobsiteId: $jobsiteId) {
      name
      jobsiteId
      addressCity
      addressState
      addressLine1
      addressLine2
      addressZipCode
      startDate
      endDate
      jobsiteWorkers {
        count
      }
      jobsiteMembers {
        count
      }
      developer {
        organization {
          name
        }
      }
      jobsiteContractors {
        count
        edges {
          node {
            id
            contractor {
              contractorId
              organization {
                name
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetJobsiteContainerQuery__
 *
 * To run a query within a React component, call `useGetJobsiteContainerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobsiteContainerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobsiteContainerQuery({
 *   variables: {
 *      jobsiteId: // value for 'jobsiteId'
 *   },
 * });
 */
export function useGetJobsiteContainerQuery(
  baseOptions: Apollo.QueryHookOptions<GetJobsiteContainerQuery, GetJobsiteContainerQueryVariables> &
    ({ variables: GetJobsiteContainerQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetJobsiteContainerQuery, GetJobsiteContainerQueryVariables>(
    GetJobsiteContainerDocument,
    options,
  );
}
export function useGetJobsiteContainerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetJobsiteContainerQuery, GetJobsiteContainerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetJobsiteContainerQuery, GetJobsiteContainerQueryVariables>(
    GetJobsiteContainerDocument,
    options,
  );
}
export function useGetJobsiteContainerSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetJobsiteContainerQuery, GetJobsiteContainerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetJobsiteContainerQuery, GetJobsiteContainerQueryVariables>(
    GetJobsiteContainerDocument,
    options,
  );
}
export type GetJobsiteContainerQueryHookResult = ReturnType<typeof useGetJobsiteContainerQuery>;
export type GetJobsiteContainerLazyQueryHookResult = ReturnType<typeof useGetJobsiteContainerLazyQuery>;
export type GetJobsiteContainerSuspenseQueryHookResult = ReturnType<typeof useGetJobsiteContainerSuspenseQuery>;
export type GetJobsiteContainerQueryResult = Apollo.QueryResult<
  GetJobsiteContainerQuery,
  GetJobsiteContainerQueryVariables
>;
export const GetJobsiteDetailsDocument = gql`
  query GetJobsiteDetails($jobsiteId: ID!) {
    getJobsite(jobsiteId: $jobsiteId) {
      jobsiteId
      name
      clearanceAssignmentEnabled
      cCureClearances {
        edges {
          node {
            cCureClearanceId
            name
            cCureClearanceObjectId
            isDefault
            isSelectable
            cCureEnvironment {
              cCureEnvironmentId
              namespace
              partitionId
              instanceSlug
              __typename
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetJobsiteDetailsQuery__
 *
 * To run a query within a React component, call `useGetJobsiteDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobsiteDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobsiteDetailsQuery({
 *   variables: {
 *      jobsiteId: // value for 'jobsiteId'
 *   },
 * });
 */
export function useGetJobsiteDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<GetJobsiteDetailsQuery, GetJobsiteDetailsQueryVariables> &
    ({ variables: GetJobsiteDetailsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetJobsiteDetailsQuery, GetJobsiteDetailsQueryVariables>(GetJobsiteDetailsDocument, options);
}
export function useGetJobsiteDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetJobsiteDetailsQuery, GetJobsiteDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetJobsiteDetailsQuery, GetJobsiteDetailsQueryVariables>(
    GetJobsiteDetailsDocument,
    options,
  );
}
export function useGetJobsiteDetailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetJobsiteDetailsQuery, GetJobsiteDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetJobsiteDetailsQuery, GetJobsiteDetailsQueryVariables>(
    GetJobsiteDetailsDocument,
    options,
  );
}
export type GetJobsiteDetailsQueryHookResult = ReturnType<typeof useGetJobsiteDetailsQuery>;
export type GetJobsiteDetailsLazyQueryHookResult = ReturnType<typeof useGetJobsiteDetailsLazyQuery>;
export type GetJobsiteDetailsSuspenseQueryHookResult = ReturnType<typeof useGetJobsiteDetailsSuspenseQuery>;
export type GetJobsiteDetailsQueryResult = Apollo.QueryResult<GetJobsiteDetailsQuery, GetJobsiteDetailsQueryVariables>;
export const GetJobsiteContainerContractorsDocument = gql`
  query GetJobsiteContainerContractors(
    $jobsiteId: ID!
    $jobsiteJobsiteContractorsInput: JobsiteJobsiteContractorsInput
  ) {
    getJobsite(jobsiteId: $jobsiteId) {
      jobsiteId
      name
      startDate
      jobsiteContractors(input: $jobsiteJobsiteContractorsInput) {
        count
        edges {
          node {
            id
            contractor {
              contractorId
              organization {
                name
              }
            }
            jobsite {
              name
              jobsiteId
              addressCity
              addressState
              startDate
              developer {
                organization {
                  name
                }
              }
            }
            parentJobsiteContractor {
              contractor {
                contractorId
                organization {
                  name
                }
              }
            }
            subcontractedJobsiteContractors {
              count
            }
            startDate
            endDate
            contact {
              firstName
              lastName
              phoneNumber
              email
              title
            }
            jobsiteWorkers {
              count
            }
            subcontractedJobsiteContractors {
              count
              edges {
                node {
                  contractor {
                    contractorId
                    organization {
                      name
                    }
                  }
                  jobsiteWorkers {
                    count
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetJobsiteContainerContractorsQuery__
 *
 * To run a query within a React component, call `useGetJobsiteContainerContractorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobsiteContainerContractorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobsiteContainerContractorsQuery({
 *   variables: {
 *      jobsiteId: // value for 'jobsiteId'
 *      jobsiteJobsiteContractorsInput: // value for 'jobsiteJobsiteContractorsInput'
 *   },
 * });
 */
export function useGetJobsiteContainerContractorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetJobsiteContainerContractorsQuery,
    GetJobsiteContainerContractorsQueryVariables
  > &
    ({ variables: GetJobsiteContainerContractorsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetJobsiteContainerContractorsQuery, GetJobsiteContainerContractorsQueryVariables>(
    GetJobsiteContainerContractorsDocument,
    options,
  );
}
export function useGetJobsiteContainerContractorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetJobsiteContainerContractorsQuery,
    GetJobsiteContainerContractorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetJobsiteContainerContractorsQuery, GetJobsiteContainerContractorsQueryVariables>(
    GetJobsiteContainerContractorsDocument,
    options,
  );
}
export function useGetJobsiteContainerContractorsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetJobsiteContainerContractorsQuery,
    GetJobsiteContainerContractorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetJobsiteContainerContractorsQuery, GetJobsiteContainerContractorsQueryVariables>(
    GetJobsiteContainerContractorsDocument,
    options,
  );
}
export type GetJobsiteContainerContractorsQueryHookResult = ReturnType<typeof useGetJobsiteContainerContractorsQuery>;
export type GetJobsiteContainerContractorsLazyQueryHookResult = ReturnType<
  typeof useGetJobsiteContainerContractorsLazyQuery
>;
export type GetJobsiteContainerContractorsSuspenseQueryHookResult = ReturnType<
  typeof useGetJobsiteContainerContractorsSuspenseQuery
>;
export type GetJobsiteContainerContractorsQueryResult = Apollo.QueryResult<
  GetJobsiteContainerContractorsQuery,
  GetJobsiteContainerContractorsQueryVariables
>;
export const GetJobsiteContainerEquipmentTabDocument = gql`
  query GetJobsiteContainerEquipmentTab($jobsiteId: ID!, $status: JobsiteEquipmentStatusType) {
    getJobsiteEquipmentData(jobsiteIds: [$jobsiteId], status: $status) {
      results {
        instanceSlug
        status
        name
        description
        guid
        type
        jobsite {
          jobsiteId
          name
        }
        lastOnlineDate
        lastOfflineDate
      }
      count
    }
  }
`;

/**
 * __useGetJobsiteContainerEquipmentTabQuery__
 *
 * To run a query within a React component, call `useGetJobsiteContainerEquipmentTabQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobsiteContainerEquipmentTabQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobsiteContainerEquipmentTabQuery({
 *   variables: {
 *      jobsiteId: // value for 'jobsiteId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetJobsiteContainerEquipmentTabQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetJobsiteContainerEquipmentTabQuery,
    GetJobsiteContainerEquipmentTabQueryVariables
  > &
    ({ variables: GetJobsiteContainerEquipmentTabQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetJobsiteContainerEquipmentTabQuery, GetJobsiteContainerEquipmentTabQueryVariables>(
    GetJobsiteContainerEquipmentTabDocument,
    options,
  );
}
export function useGetJobsiteContainerEquipmentTabLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetJobsiteContainerEquipmentTabQuery,
    GetJobsiteContainerEquipmentTabQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetJobsiteContainerEquipmentTabQuery, GetJobsiteContainerEquipmentTabQueryVariables>(
    GetJobsiteContainerEquipmentTabDocument,
    options,
  );
}
export function useGetJobsiteContainerEquipmentTabSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetJobsiteContainerEquipmentTabQuery,
    GetJobsiteContainerEquipmentTabQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetJobsiteContainerEquipmentTabQuery, GetJobsiteContainerEquipmentTabQueryVariables>(
    GetJobsiteContainerEquipmentTabDocument,
    options,
  );
}
export type GetJobsiteContainerEquipmentTabQueryHookResult = ReturnType<typeof useGetJobsiteContainerEquipmentTabQuery>;
export type GetJobsiteContainerEquipmentTabLazyQueryHookResult = ReturnType<
  typeof useGetJobsiteContainerEquipmentTabLazyQuery
>;
export type GetJobsiteContainerEquipmentTabSuspenseQueryHookResult = ReturnType<
  typeof useGetJobsiteContainerEquipmentTabSuspenseQuery
>;
export type GetJobsiteContainerEquipmentTabQueryResult = Apollo.QueryResult<
  GetJobsiteContainerEquipmentTabQuery,
  GetJobsiteContainerEquipmentTabQueryVariables
>;
export const GetJobsiteContainerEquipmentHistoryTabDocument = gql`
  query GetJobsiteContainerEquipmentHistoryTab(
    $jobsiteId: ID!
    $status: JobsiteEquipmentStatusType
    $fromDate: Date
    $toDate: Date
    $sortDir: OrderByDirection
    $limit: Int
    $offset: Int
  ) {
    getJobsiteEquipmentHistory(
      jobsiteIds: [$jobsiteId]
      status: $status
      fromDate: $fromDate
      toDate: $toDate
      sortDir: $sortDir
      limit: $limit
      offset: $offset
    ) {
      results {
        instanceSlug
        deviceGuid
        deviceType
        deviceStatus
        deviceName
        deviceDescription
        jobsiteId
        eventDate
      }
      count
    }
  }
`;

/**
 * __useGetJobsiteContainerEquipmentHistoryTabQuery__
 *
 * To run a query within a React component, call `useGetJobsiteContainerEquipmentHistoryTabQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobsiteContainerEquipmentHistoryTabQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobsiteContainerEquipmentHistoryTabQuery({
 *   variables: {
 *      jobsiteId: // value for 'jobsiteId'
 *      status: // value for 'status'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      sortDir: // value for 'sortDir'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetJobsiteContainerEquipmentHistoryTabQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetJobsiteContainerEquipmentHistoryTabQuery,
    GetJobsiteContainerEquipmentHistoryTabQueryVariables
  > &
    ({ variables: GetJobsiteContainerEquipmentHistoryTabQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetJobsiteContainerEquipmentHistoryTabQuery,
    GetJobsiteContainerEquipmentHistoryTabQueryVariables
  >(GetJobsiteContainerEquipmentHistoryTabDocument, options);
}
export function useGetJobsiteContainerEquipmentHistoryTabLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetJobsiteContainerEquipmentHistoryTabQuery,
    GetJobsiteContainerEquipmentHistoryTabQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetJobsiteContainerEquipmentHistoryTabQuery,
    GetJobsiteContainerEquipmentHistoryTabQueryVariables
  >(GetJobsiteContainerEquipmentHistoryTabDocument, options);
}
export function useGetJobsiteContainerEquipmentHistoryTabSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetJobsiteContainerEquipmentHistoryTabQuery,
    GetJobsiteContainerEquipmentHistoryTabQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetJobsiteContainerEquipmentHistoryTabQuery,
    GetJobsiteContainerEquipmentHistoryTabQueryVariables
  >(GetJobsiteContainerEquipmentHistoryTabDocument, options);
}
export type GetJobsiteContainerEquipmentHistoryTabQueryHookResult = ReturnType<
  typeof useGetJobsiteContainerEquipmentHistoryTabQuery
>;
export type GetJobsiteContainerEquipmentHistoryTabLazyQueryHookResult = ReturnType<
  typeof useGetJobsiteContainerEquipmentHistoryTabLazyQuery
>;
export type GetJobsiteContainerEquipmentHistoryTabSuspenseQueryHookResult = ReturnType<
  typeof useGetJobsiteContainerEquipmentHistoryTabSuspenseQuery
>;
export type GetJobsiteContainerEquipmentHistoryTabQueryResult = Apollo.QueryResult<
  GetJobsiteContainerEquipmentHistoryTabQuery,
  GetJobsiteContainerEquipmentHistoryTabQueryVariables
>;
export const GetJobsiteContainerJobsiteInfoDocument = gql`
  query GetJobsiteContainerJobsiteInfo($jobsiteId: ID!) {
    getJobsite(jobsiteId: $jobsiteId) {
      name
      jobsiteId
      addressCity
      addressState
      addressLine1
      addressLine2
      addressZipCode
      startDate
      endDate
      developer {
        organization {
          organizationId
          name
          legalName
          email
          addressLine1
          addressLine2
          addressCity
          addressCity
          addressState
          addressZipCode
        }
      }
      defaultJobsiteContractor {
        id
        contractor {
          contractorId
          organization {
            organizationId
            name
          }
        }
        jobsiteInvitation {
          jobsiteInvitationId
        }
      }
      objectHistory {
        createdAt
        createdBy {
          identity {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

/**
 * __useGetJobsiteContainerJobsiteInfoQuery__
 *
 * To run a query within a React component, call `useGetJobsiteContainerJobsiteInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobsiteContainerJobsiteInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobsiteContainerJobsiteInfoQuery({
 *   variables: {
 *      jobsiteId: // value for 'jobsiteId'
 *   },
 * });
 */
export function useGetJobsiteContainerJobsiteInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetJobsiteContainerJobsiteInfoQuery,
    GetJobsiteContainerJobsiteInfoQueryVariables
  > &
    ({ variables: GetJobsiteContainerJobsiteInfoQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetJobsiteContainerJobsiteInfoQuery, GetJobsiteContainerJobsiteInfoQueryVariables>(
    GetJobsiteContainerJobsiteInfoDocument,
    options,
  );
}
export function useGetJobsiteContainerJobsiteInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetJobsiteContainerJobsiteInfoQuery,
    GetJobsiteContainerJobsiteInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetJobsiteContainerJobsiteInfoQuery, GetJobsiteContainerJobsiteInfoQueryVariables>(
    GetJobsiteContainerJobsiteInfoDocument,
    options,
  );
}
export function useGetJobsiteContainerJobsiteInfoSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetJobsiteContainerJobsiteInfoQuery,
    GetJobsiteContainerJobsiteInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetJobsiteContainerJobsiteInfoQuery, GetJobsiteContainerJobsiteInfoQueryVariables>(
    GetJobsiteContainerJobsiteInfoDocument,
    options,
  );
}
export type GetJobsiteContainerJobsiteInfoQueryHookResult = ReturnType<typeof useGetJobsiteContainerJobsiteInfoQuery>;
export type GetJobsiteContainerJobsiteInfoLazyQueryHookResult = ReturnType<
  typeof useGetJobsiteContainerJobsiteInfoLazyQuery
>;
export type GetJobsiteContainerJobsiteInfoSuspenseQueryHookResult = ReturnType<
  typeof useGetJobsiteContainerJobsiteInfoSuspenseQuery
>;
export type GetJobsiteContainerJobsiteInfoQueryResult = Apollo.QueryResult<
  GetJobsiteContainerJobsiteInfoQuery,
  GetJobsiteContainerJobsiteInfoQueryVariables
>;
export const GetJobsiteContainerMembersDocument = gql`
  query GetJobsiteContainerMembers(
    $jobsiteId: ID!
    $jobsiteJobsiteMembersInput: JobsiteJobsiteMembersInput
    $userRolesInput: UserRolesInput
  ) {
    getJobsite(jobsiteId: $jobsiteId) {
      jobsiteId
      jobsiteMembers(input: $jobsiteJobsiteMembersInput) {
        count
        edges {
          node {
            id
            user {
              userAccountId
              roles(input: $userRolesInput) {
                key
                displayName
                object {
                  objectId
                  objectDetails {
                    name
                  }
                }
              }
              identity {
                firstName
                lastName
                email
                phoneNumber
              }
              worker {
                workerId
                profilePictureCropped {
                  downloadUrl
                }
              }
              lastSession {
                createdAt
                appVersion
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetJobsiteContainerMembersQuery__
 *
 * To run a query within a React component, call `useGetJobsiteContainerMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobsiteContainerMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobsiteContainerMembersQuery({
 *   variables: {
 *      jobsiteId: // value for 'jobsiteId'
 *      jobsiteJobsiteMembersInput: // value for 'jobsiteJobsiteMembersInput'
 *      userRolesInput: // value for 'userRolesInput'
 *   },
 * });
 */
export function useGetJobsiteContainerMembersQuery(
  baseOptions: Apollo.QueryHookOptions<GetJobsiteContainerMembersQuery, GetJobsiteContainerMembersQueryVariables> &
    ({ variables: GetJobsiteContainerMembersQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetJobsiteContainerMembersQuery, GetJobsiteContainerMembersQueryVariables>(
    GetJobsiteContainerMembersDocument,
    options,
  );
}
export function useGetJobsiteContainerMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetJobsiteContainerMembersQuery, GetJobsiteContainerMembersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetJobsiteContainerMembersQuery, GetJobsiteContainerMembersQueryVariables>(
    GetJobsiteContainerMembersDocument,
    options,
  );
}
export function useGetJobsiteContainerMembersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetJobsiteContainerMembersQuery,
    GetJobsiteContainerMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetJobsiteContainerMembersQuery, GetJobsiteContainerMembersQueryVariables>(
    GetJobsiteContainerMembersDocument,
    options,
  );
}
export type GetJobsiteContainerMembersQueryHookResult = ReturnType<typeof useGetJobsiteContainerMembersQuery>;
export type GetJobsiteContainerMembersLazyQueryHookResult = ReturnType<typeof useGetJobsiteContainerMembersLazyQuery>;
export type GetJobsiteContainerMembersSuspenseQueryHookResult = ReturnType<
  typeof useGetJobsiteContainerMembersSuspenseQuery
>;
export type GetJobsiteContainerMembersQueryResult = Apollo.QueryResult<
  GetJobsiteContainerMembersQuery,
  GetJobsiteContainerMembersQueryVariables
>;
export const GetJobsiteContainerWorkersDocument = gql`
  query GetJobsiteContainerWorkers($jobsiteId: ID!, $jobsiteJobsiteWorkersInput: JobsiteJobsiteWorkersInput) {
    getJobsite(jobsiteId: $jobsiteId) {
      jobsiteId
      jobsiteWorkers(input: $jobsiteJobsiteWorkersInput) {
        count
        edges {
          node {
            jobsiteWorkerId
            currentAccess {
              isAllowed
              notAllowedReason
              startDate
              endDate
            }
            contractorWorker {
              worker {
                workerId
                birthDate
                quickCode
                trade
                user {
                  userAccountId
                  identity {
                    firstName
                    lastName
                    email
                  }
                }
                profilePicture {
                  downloadUrl
                  uploadedAt
                  fileId
                }
                profilePictureCropped {
                  downloadUrl
                  uploadedAt
                  fileId
                  fileType
                }
                workerCards {
                  edges {
                    node {
                      workerCardId
                      cardNumber
                      cardStatus
                    }
                  }
                }
                jobsiteWorkers {
                  count
                  edges {
                    node {
                      jobsiteWorkerId
                      jobsiteContractor {
                        jobsite {
                          jobsiteId
                          name
                        }
                        contractor {
                          contractorId
                          organization {
                            name
                          }
                        }
                      }
                      currentAccess {
                        isAllowed
                        notAllowedReason
                        startDate
                        endDate
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetJobsiteContainerWorkersQuery__
 *
 * To run a query within a React component, call `useGetJobsiteContainerWorkersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobsiteContainerWorkersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobsiteContainerWorkersQuery({
 *   variables: {
 *      jobsiteId: // value for 'jobsiteId'
 *      jobsiteJobsiteWorkersInput: // value for 'jobsiteJobsiteWorkersInput'
 *   },
 * });
 */
export function useGetJobsiteContainerWorkersQuery(
  baseOptions: Apollo.QueryHookOptions<GetJobsiteContainerWorkersQuery, GetJobsiteContainerWorkersQueryVariables> &
    ({ variables: GetJobsiteContainerWorkersQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetJobsiteContainerWorkersQuery, GetJobsiteContainerWorkersQueryVariables>(
    GetJobsiteContainerWorkersDocument,
    options,
  );
}
export function useGetJobsiteContainerWorkersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetJobsiteContainerWorkersQuery, GetJobsiteContainerWorkersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetJobsiteContainerWorkersQuery, GetJobsiteContainerWorkersQueryVariables>(
    GetJobsiteContainerWorkersDocument,
    options,
  );
}
export function useGetJobsiteContainerWorkersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetJobsiteContainerWorkersQuery,
    GetJobsiteContainerWorkersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetJobsiteContainerWorkersQuery, GetJobsiteContainerWorkersQueryVariables>(
    GetJobsiteContainerWorkersDocument,
    options,
  );
}
export type GetJobsiteContainerWorkersQueryHookResult = ReturnType<typeof useGetJobsiteContainerWorkersQuery>;
export type GetJobsiteContainerWorkersLazyQueryHookResult = ReturnType<typeof useGetJobsiteContainerWorkersLazyQuery>;
export type GetJobsiteContainerWorkersSuspenseQueryHookResult = ReturnType<
  typeof useGetJobsiteContainerWorkersSuspenseQuery
>;
export type GetJobsiteContainerWorkersQueryResult = Apollo.QueryResult<
  GetJobsiteContainerWorkersQuery,
  GetJobsiteContainerWorkersQueryVariables
>;
export const GetJobsiteAccessActivityCurrentSessionDocument = gql`
  query GetJobsiteAccessActivityCurrentSession($userJobsiteAccessEventsInput: UserJobsiteAccessEventsInput) {
    getCurrentSession {
      user {
        userAccountId
        jobsiteAccessEvents(input: $userJobsiteAccessEventsInput) {
          count
          edges {
            node {
              id
              type
              status
              credentialId
              eventOccurredAt
              localizedDate
              localizedTime
              timeZone
              rejectionReason
              message
              gateway
              overnightSession
              jobsite {
                jobsiteId
                name
              }
              jobsiteWorker {
                jobsiteWorkerId
                jobsiteContractor {
                  id
                  contractor {
                    contractorId
                    organization {
                      organizationId
                      name
                    }
                  }
                }
                currentAccess {
                  isAllowed
                }
              }
              direction
              externalId
              eventType
              worker {
                workerId
                user {
                  userAccountId
                  identity {
                    firstName
                    lastName
                  }
                }
                profilePictureCropped {
                  downloadUrl
                }
              }
            }
          }
          filtersOptions {
            jobsites {
              value
              label
              count
            }
            contractors {
              value
              label
              count
            }
            gateways {
              value
              label
              count
            }
            rejectionReasons {
              value
              label
              count
            }
            locationStatuses {
              value
              label
              count
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetJobsiteAccessActivityCurrentSessionQuery__
 *
 * To run a query within a React component, call `useGetJobsiteAccessActivityCurrentSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobsiteAccessActivityCurrentSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobsiteAccessActivityCurrentSessionQuery({
 *   variables: {
 *      userJobsiteAccessEventsInput: // value for 'userJobsiteAccessEventsInput'
 *   },
 * });
 */
export function useGetJobsiteAccessActivityCurrentSessionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetJobsiteAccessActivityCurrentSessionQuery,
    GetJobsiteAccessActivityCurrentSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetJobsiteAccessActivityCurrentSessionQuery,
    GetJobsiteAccessActivityCurrentSessionQueryVariables
  >(GetJobsiteAccessActivityCurrentSessionDocument, options);
}
export function useGetJobsiteAccessActivityCurrentSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetJobsiteAccessActivityCurrentSessionQuery,
    GetJobsiteAccessActivityCurrentSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetJobsiteAccessActivityCurrentSessionQuery,
    GetJobsiteAccessActivityCurrentSessionQueryVariables
  >(GetJobsiteAccessActivityCurrentSessionDocument, options);
}
export function useGetJobsiteAccessActivityCurrentSessionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetJobsiteAccessActivityCurrentSessionQuery,
    GetJobsiteAccessActivityCurrentSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetJobsiteAccessActivityCurrentSessionQuery,
    GetJobsiteAccessActivityCurrentSessionQueryVariables
  >(GetJobsiteAccessActivityCurrentSessionDocument, options);
}
export type GetJobsiteAccessActivityCurrentSessionQueryHookResult = ReturnType<
  typeof useGetJobsiteAccessActivityCurrentSessionQuery
>;
export type GetJobsiteAccessActivityCurrentSessionLazyQueryHookResult = ReturnType<
  typeof useGetJobsiteAccessActivityCurrentSessionLazyQuery
>;
export type GetJobsiteAccessActivityCurrentSessionSuspenseQueryHookResult = ReturnType<
  typeof useGetJobsiteAccessActivityCurrentSessionSuspenseQuery
>;
export type GetJobsiteAccessActivityCurrentSessionQueryResult = Apollo.QueryResult<
  GetJobsiteAccessActivityCurrentSessionQuery,
  GetJobsiteAccessActivityCurrentSessionQueryVariables
>;
export const ArchiveJobsiteDocument = gql`
  mutation ArchiveJobsite($jobsiteId: ID!) {
    archiveJobsite(jobsiteId: $jobsiteId) {
      success
    }
  }
`;
export type ArchiveJobsiteMutationFn = Apollo.MutationFunction<ArchiveJobsiteMutation, ArchiveJobsiteMutationVariables>;

/**
 * __useArchiveJobsiteMutation__
 *
 * To run a mutation, you first call `useArchiveJobsiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveJobsiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveJobsiteMutation, { data, loading, error }] = useArchiveJobsiteMutation({
 *   variables: {
 *      jobsiteId: // value for 'jobsiteId'
 *   },
 * });
 */
export function useArchiveJobsiteMutation(
  baseOptions?: Apollo.MutationHookOptions<ArchiveJobsiteMutation, ArchiveJobsiteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveJobsiteMutation, ArchiveJobsiteMutationVariables>(ArchiveJobsiteDocument, options);
}
export type ArchiveJobsiteMutationHookResult = ReturnType<typeof useArchiveJobsiteMutation>;
export type ArchiveJobsiteMutationResult = Apollo.MutationResult<ArchiveJobsiteMutation>;
export type ArchiveJobsiteMutationOptions = Apollo.BaseMutationOptions<
  ArchiveJobsiteMutation,
  ArchiveJobsiteMutationVariables
>;
export const JobsiteUpdateDocument = gql`
  mutation JobsiteUpdate($input: JobsiteUpdateInput!) {
    jobsiteUpdate(input: $input) {
      jobsite {
        jobsiteId
      }
    }
  }
`;
export type JobsiteUpdateMutationFn = Apollo.MutationFunction<JobsiteUpdateMutation, JobsiteUpdateMutationVariables>;

/**
 * __useJobsiteUpdateMutation__
 *
 * To run a mutation, you first call `useJobsiteUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobsiteUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobsiteUpdateMutation, { data, loading, error }] = useJobsiteUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJobsiteUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<JobsiteUpdateMutation, JobsiteUpdateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<JobsiteUpdateMutation, JobsiteUpdateMutationVariables>(JobsiteUpdateDocument, options);
}
export type JobsiteUpdateMutationHookResult = ReturnType<typeof useJobsiteUpdateMutation>;
export type JobsiteUpdateMutationResult = Apollo.MutationResult<JobsiteUpdateMutation>;
export type JobsiteUpdateMutationOptions = Apollo.BaseMutationOptions<
  JobsiteUpdateMutation,
  JobsiteUpdateMutationVariables
>;
export const GetJobsiteConfigurationDocument = gql`
  query GetJobsiteConfiguration(
    $jobsiteId: ID!
    $documentTypesInput: JobsiteWorkerDocumentTypesInput
    $userFormsInput: UserFormsInput
  ) {
    getJobsite(jobsiteId: $jobsiteId) {
      jobsiteId
      name
      developer {
        developerId
      }
      parentId
      isTemplate
      templateId
      addressLine1
      addressLine2
      addressCity
      addressState
      addressZipCode
      timeZone
      latitude
      longitude
      startDate
      endDate
      onboardingGracePeriod
      swipeInactivityThreshold
      maximumSessionLength
      allowOvernightSessions
      sessionDisplayColumn
      accessChangeEmails
      escortChangeEmails
      deviceStatusChangeEmails
      assignmentChangeEmails
      rejectedSwipeEmails
      dailyAccessReportEmails
      weeklyAccessReportEmails
      reportingTimeSlot
      hidPrinterId
      fargoLocationId
      fargoOrganizationId
      hidProxyInstance
      hidCardMappingUnion
      hidCardMappingNonUnion
      hidCardMappingTest
      procoreCompanyId
      procoreProjectId
      avigilonEnabled
      cCureClearances {
        edges {
          node {
            cCureClearanceId
            name
            cCureClearanceObjectId
            isDefault
            isSelectable
            cCureEnvironment {
              cCureEnvironmentId
              namespace
            }
          }
        }
      }
      cCureEntityMappings {
        edges {
          node {
            objectId
            entityType
            cCureEnvironment {
              cCureEnvironmentId
              namespace
            }
          }
        }
      }
      jobsiteCardFormats {
        edges {
          node {
            jobsiteCardFormatId
            isDefaultForCardType
            workerCardFormat {
              workerCardFormatId
            }
          }
        }
      }
      jobsiteBadgeTemplates {
        edges {
          node {
            id
            jobsiteId
            isDefault
            badgeTemplate {
              id
              templateName
              displayName
            }
          }
        }
      }
      documentTypes(input: $documentTypesInput) {
        edges {
          node {
            id
            workerDocumentType {
              workerDocumentTypeId
              key
            }
            files {
              fileId
              fileType
              downloadUrl
              language
              title
            }
          }
        }
      }
      jobsiteForms {
        edges {
          node {
            id
            form {
              id
              key
              name
            }
          }
        }
      }
      modules {
        ... on JobsiteFeaturesModule {
          announcementsEnabled
          archivingAccessEventsEnabled
          formsEnabled
          addWorkersToDailyLog
          doNotAllowToSkipSelfOnboardingSteps
          enforceValidContractorInSelfOnboarding
          workerAppEnabled
          workerAppSelfCheckInEnabled
          workerAppAdminCheckInEnabled
          workerAppBadgeScanningEnabled
          workerAppKioskCheckInEnabled
          selfOnboardingAutoCompleteEnabled
          workerAppKioskFrontCameraEnabled
          isEnabled
          name
          slug
        }
        ... on JobsiteAppCheckInModule {
          areas {
            id
            name
            checkInCoordinate {
              lat
              lng
            }
            checkInRadius
            isInboundEnabled
            isOutboundEnabled
          }
          geofencingEnforcementType
          crewGeofencingEnforcementType
          isEnabled
          name
          slug
        }
        ... on JobsiteOnboardingModule {
          name
          badgePrintingAllowed
          badgeAssignmentAllowed
          steps {
            key
            index
            isRequired
            isVisible
            fields {
              key
              name
              index
              isRequired
              isHidden
            }
          }
          documents {
            key
            name
            index
            isRequired
            isVisible
            expiration
            gracePeriod
            fields {
              key
              name
              index
              isRequired
              isHidden
            }
          }
        }
      }
    }
    getCurrentSession {
      user {
        userAccountId
        developers {
          edges {
            node {
              developerId
              organization {
                organizationId
                name
              }
            }
          }
        }
        jobsites {
          edges {
            node {
              jobsiteId
              name
              parentId
            }
          }
        }
        workerCardFormats {
          edges {
            node {
              workerCardFormatId
              name
              facilityCode
              cardType
            }
          }
        }
        cCureEnvironments {
          edges {
            node {
              cCureEnvironmentId
              namespace
            }
          }
        }
        cCureEntityMappingOptions {
          edges {
            node {
              cCureEnvironment {
                namespace
                cCureEnvironmentId
                instanceSlug
              }
              entityType
              objectId
              name
            }
          }
        }
        hidEntityOptions {
          edges {
            node {
              id
              name
              organizationId
              organizationName
              locationId
              locationName
              entityType
              proxyInstance
            }
          }
        }
        badgeTemplates {
          edges {
            node {
              id
              templateName
              displayName
            }
          }
        }
        workerDocumentTypes {
          edges {
            node {
              workerDocumentTypeId
              name
              key
              isGlobal
              isPhi
              documentLevel
            }
          }
        }
        forms(input: $userFormsInput) {
          edges {
            node {
              id
              key
              name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetJobsiteConfigurationQuery__
 *
 * To run a query within a React component, call `useGetJobsiteConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobsiteConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobsiteConfigurationQuery({
 *   variables: {
 *      jobsiteId: // value for 'jobsiteId'
 *      documentTypesInput: // value for 'documentTypesInput'
 *      userFormsInput: // value for 'userFormsInput'
 *   },
 * });
 */
export function useGetJobsiteConfigurationQuery(
  baseOptions: Apollo.QueryHookOptions<GetJobsiteConfigurationQuery, GetJobsiteConfigurationQueryVariables> &
    ({ variables: GetJobsiteConfigurationQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetJobsiteConfigurationQuery, GetJobsiteConfigurationQueryVariables>(
    GetJobsiteConfigurationDocument,
    options,
  );
}
export function useGetJobsiteConfigurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetJobsiteConfigurationQuery, GetJobsiteConfigurationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetJobsiteConfigurationQuery, GetJobsiteConfigurationQueryVariables>(
    GetJobsiteConfigurationDocument,
    options,
  );
}
export function useGetJobsiteConfigurationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetJobsiteConfigurationQuery, GetJobsiteConfigurationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetJobsiteConfigurationQuery, GetJobsiteConfigurationQueryVariables>(
    GetJobsiteConfigurationDocument,
    options,
  );
}
export type GetJobsiteConfigurationQueryHookResult = ReturnType<typeof useGetJobsiteConfigurationQuery>;
export type GetJobsiteConfigurationLazyQueryHookResult = ReturnType<typeof useGetJobsiteConfigurationLazyQuery>;
export type GetJobsiteConfigurationSuspenseQueryHookResult = ReturnType<typeof useGetJobsiteConfigurationSuspenseQuery>;
export type GetJobsiteConfigurationQueryResult = Apollo.QueryResult<
  GetJobsiteConfigurationQuery,
  GetJobsiteConfigurationQueryVariables
>;
export const GetAddJobsiteContractorWorkerJobsiteDocument = gql`
  query GetAddJobsiteContractorWorkerJobsite($jobsiteId: ID!) {
    getJobsite(jobsiteId: $jobsiteId) {
      jobsiteId
      name
      modules {
        ... on JobsiteOnboardingModule {
          name
          steps {
            key
            index
            isRequired
            isVisible
            fields {
              key
              name
              index
              isRequired
              isHidden
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetAddJobsiteContractorWorkerJobsiteQuery__
 *
 * To run a query within a React component, call `useGetAddJobsiteContractorWorkerJobsiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddJobsiteContractorWorkerJobsiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddJobsiteContractorWorkerJobsiteQuery({
 *   variables: {
 *      jobsiteId: // value for 'jobsiteId'
 *   },
 * });
 */
export function useGetAddJobsiteContractorWorkerJobsiteQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAddJobsiteContractorWorkerJobsiteQuery,
    GetAddJobsiteContractorWorkerJobsiteQueryVariables
  > &
    ({ variables: GetAddJobsiteContractorWorkerJobsiteQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAddJobsiteContractorWorkerJobsiteQuery, GetAddJobsiteContractorWorkerJobsiteQueryVariables>(
    GetAddJobsiteContractorWorkerJobsiteDocument,
    options,
  );
}
export function useGetAddJobsiteContractorWorkerJobsiteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAddJobsiteContractorWorkerJobsiteQuery,
    GetAddJobsiteContractorWorkerJobsiteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAddJobsiteContractorWorkerJobsiteQuery,
    GetAddJobsiteContractorWorkerJobsiteQueryVariables
  >(GetAddJobsiteContractorWorkerJobsiteDocument, options);
}
export function useGetAddJobsiteContractorWorkerJobsiteSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAddJobsiteContractorWorkerJobsiteQuery,
    GetAddJobsiteContractorWorkerJobsiteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAddJobsiteContractorWorkerJobsiteQuery,
    GetAddJobsiteContractorWorkerJobsiteQueryVariables
  >(GetAddJobsiteContractorWorkerJobsiteDocument, options);
}
export type GetAddJobsiteContractorWorkerJobsiteQueryHookResult = ReturnType<
  typeof useGetAddJobsiteContractorWorkerJobsiteQuery
>;
export type GetAddJobsiteContractorWorkerJobsiteLazyQueryHookResult = ReturnType<
  typeof useGetAddJobsiteContractorWorkerJobsiteLazyQuery
>;
export type GetAddJobsiteContractorWorkerJobsiteSuspenseQueryHookResult = ReturnType<
  typeof useGetAddJobsiteContractorWorkerJobsiteSuspenseQuery
>;
export type GetAddJobsiteContractorWorkerJobsiteQueryResult = Apollo.QueryResult<
  GetAddJobsiteContractorWorkerJobsiteQuery,
  GetAddJobsiteContractorWorkerJobsiteQueryVariables
>;
export const GetAvailableJobsiteWorkersDocument = gql`
  query GetAvailableJobsiteWorkers($jobsiteId: ID!, $jobsiteJobsiteWorkersInput: JobsiteJobsiteWorkersInput) {
    getJobsite(jobsiteId: $jobsiteId) {
      jobsiteId
      jobsiteWorkers(input: $jobsiteJobsiteWorkersInput) {
        count
        edges {
          node {
            jobsiteWorkerId
            stickerNumber
            isOnSite
            currentAccess {
              isAllowed
              notAllowedReason
              startDate
              endDate
            }
            contractorWorker {
              id
              contractor {
                contractorId
                organization {
                  organizationId
                  name
                }
              }
              worker {
                workerId
                birthDate
                quickCode
                trade
                jobTitle
                user {
                  userAccountId
                  identity {
                    firstName
                    lastName
                    email
                  }
                }
                profilePicture {
                  downloadUrl
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetAvailableJobsiteWorkersQuery__
 *
 * To run a query within a React component, call `useGetAvailableJobsiteWorkersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableJobsiteWorkersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableJobsiteWorkersQuery({
 *   variables: {
 *      jobsiteId: // value for 'jobsiteId'
 *      jobsiteJobsiteWorkersInput: // value for 'jobsiteJobsiteWorkersInput'
 *   },
 * });
 */
export function useGetAvailableJobsiteWorkersQuery(
  baseOptions: Apollo.QueryHookOptions<GetAvailableJobsiteWorkersQuery, GetAvailableJobsiteWorkersQueryVariables> &
    ({ variables: GetAvailableJobsiteWorkersQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAvailableJobsiteWorkersQuery, GetAvailableJobsiteWorkersQueryVariables>(
    GetAvailableJobsiteWorkersDocument,
    options,
  );
}
export function useGetAvailableJobsiteWorkersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAvailableJobsiteWorkersQuery, GetAvailableJobsiteWorkersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAvailableJobsiteWorkersQuery, GetAvailableJobsiteWorkersQueryVariables>(
    GetAvailableJobsiteWorkersDocument,
    options,
  );
}
export function useGetAvailableJobsiteWorkersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAvailableJobsiteWorkersQuery,
    GetAvailableJobsiteWorkersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetAvailableJobsiteWorkersQuery, GetAvailableJobsiteWorkersQueryVariables>(
    GetAvailableJobsiteWorkersDocument,
    options,
  );
}
export type GetAvailableJobsiteWorkersQueryHookResult = ReturnType<typeof useGetAvailableJobsiteWorkersQuery>;
export type GetAvailableJobsiteWorkersLazyQueryHookResult = ReturnType<typeof useGetAvailableJobsiteWorkersLazyQuery>;
export type GetAvailableJobsiteWorkersSuspenseQueryHookResult = ReturnType<
  typeof useGetAvailableJobsiteWorkersSuspenseQuery
>;
export type GetAvailableJobsiteWorkersQueryResult = Apollo.QueryResult<
  GetAvailableJobsiteWorkersQuery,
  GetAvailableJobsiteWorkersQueryVariables
>;
export const GetFormSubmissionAvailableWorkersDocument = gql`
  query GetFormSubmissionAvailableWorkers(
    $id: ID!
    $availableWorkersInput: JobsiteFormSubmissionAvailableWorkersInput!
  ) {
    getJobsiteFormSubmission(id: $id) {
      id
      jobsiteForm {
        id
        jobsite {
          jobsiteId
          name
        }
      }
      availableWorkers(input: $availableWorkersInput) {
        edges {
          node {
            workerId
            birthDate
            quickCode
            trade
            jobTitle
            unionAffiliation
            user {
              userAccountId
              identity {
                firstName
                lastName
                email
                phoneNumber
              }
            }
            profilePicture {
              downloadUrl
              uploadedAt
              fileId
            }
            profilePictureCropped {
              downloadUrl
              uploadedAt
              fileId
              fileType
            }
            jobsiteWorkers {
              edges {
                node {
                  jobsiteWorkerId
                  stickerNumber
                  currentAccess {
                    isAllowed
                    notAllowedReason
                    startDate
                    endDate
                  }
                  jobsiteContractor {
                    id
                    jobsite {
                      jobsiteId
                      name
                    }
                    contractor {
                      contractorId
                      organization {
                        organizationId
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetFormSubmissionAvailableWorkersQuery__
 *
 * To run a query within a React component, call `useGetFormSubmissionAvailableWorkersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormSubmissionAvailableWorkersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormSubmissionAvailableWorkersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      availableWorkersInput: // value for 'availableWorkersInput'
 *   },
 * });
 */
export function useGetFormSubmissionAvailableWorkersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFormSubmissionAvailableWorkersQuery,
    GetFormSubmissionAvailableWorkersQueryVariables
  > &
    ({ variables: GetFormSubmissionAvailableWorkersQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFormSubmissionAvailableWorkersQuery, GetFormSubmissionAvailableWorkersQueryVariables>(
    GetFormSubmissionAvailableWorkersDocument,
    options,
  );
}
export function useGetFormSubmissionAvailableWorkersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFormSubmissionAvailableWorkersQuery,
    GetFormSubmissionAvailableWorkersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFormSubmissionAvailableWorkersQuery, GetFormSubmissionAvailableWorkersQueryVariables>(
    GetFormSubmissionAvailableWorkersDocument,
    options,
  );
}
export function useGetFormSubmissionAvailableWorkersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetFormSubmissionAvailableWorkersQuery,
    GetFormSubmissionAvailableWorkersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetFormSubmissionAvailableWorkersQuery,
    GetFormSubmissionAvailableWorkersQueryVariables
  >(GetFormSubmissionAvailableWorkersDocument, options);
}
export type GetFormSubmissionAvailableWorkersQueryHookResult = ReturnType<
  typeof useGetFormSubmissionAvailableWorkersQuery
>;
export type GetFormSubmissionAvailableWorkersLazyQueryHookResult = ReturnType<
  typeof useGetFormSubmissionAvailableWorkersLazyQuery
>;
export type GetFormSubmissionAvailableWorkersSuspenseQueryHookResult = ReturnType<
  typeof useGetFormSubmissionAvailableWorkersSuspenseQuery
>;
export type GetFormSubmissionAvailableWorkersQueryResult = Apollo.QueryResult<
  GetFormSubmissionAvailableWorkersQuery,
  GetFormSubmissionAvailableWorkersQueryVariables
>;
export const GetFormSubmissionJobsiteContractorsDocument = gql`
  query GetFormSubmissionJobsiteContractors($id: ID!) {
    getJobsiteFormSubmission(id: $id) {
      id
      jobsiteContractors {
        edges {
          node {
            id
            extraData
            associationType
            jobsiteContractor {
              id
              contractor {
                contractorId
                organization {
                  organizationId
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetFormSubmissionJobsiteContractorsQuery__
 *
 * To run a query within a React component, call `useGetFormSubmissionJobsiteContractorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormSubmissionJobsiteContractorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormSubmissionJobsiteContractorsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFormSubmissionJobsiteContractorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFormSubmissionJobsiteContractorsQuery,
    GetFormSubmissionJobsiteContractorsQueryVariables
  > &
    ({ variables: GetFormSubmissionJobsiteContractorsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFormSubmissionJobsiteContractorsQuery, GetFormSubmissionJobsiteContractorsQueryVariables>(
    GetFormSubmissionJobsiteContractorsDocument,
    options,
  );
}
export function useGetFormSubmissionJobsiteContractorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFormSubmissionJobsiteContractorsQuery,
    GetFormSubmissionJobsiteContractorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFormSubmissionJobsiteContractorsQuery,
    GetFormSubmissionJobsiteContractorsQueryVariables
  >(GetFormSubmissionJobsiteContractorsDocument, options);
}
export function useGetFormSubmissionJobsiteContractorsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetFormSubmissionJobsiteContractorsQuery,
    GetFormSubmissionJobsiteContractorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetFormSubmissionJobsiteContractorsQuery,
    GetFormSubmissionJobsiteContractorsQueryVariables
  >(GetFormSubmissionJobsiteContractorsDocument, options);
}
export type GetFormSubmissionJobsiteContractorsQueryHookResult = ReturnType<
  typeof useGetFormSubmissionJobsiteContractorsQuery
>;
export type GetFormSubmissionJobsiteContractorsLazyQueryHookResult = ReturnType<
  typeof useGetFormSubmissionJobsiteContractorsLazyQuery
>;
export type GetFormSubmissionJobsiteContractorsSuspenseQueryHookResult = ReturnType<
  typeof useGetFormSubmissionJobsiteContractorsSuspenseQuery
>;
export type GetFormSubmissionJobsiteContractorsQueryResult = Apollo.QueryResult<
  GetFormSubmissionJobsiteContractorsQuery,
  GetFormSubmissionJobsiteContractorsQueryVariables
>;
export const GetFormSubmissionJobsiteWorkersDocument = gql`
  query GetFormSubmissionJobsiteWorkers($id: ID!, $jfsInput: JobsiteFormSubmissionWorkersInput) {
    getJobsiteFormSubmission(id: $id) {
      id
      jobsiteForm {
        id
        jobsite {
          jobsiteId
          name
        }
      }
      jobsiteWorkers(input: $jfsInput) {
        edges {
          node {
            id
            extraData
            associationType
            historicalData {
              jobsiteWorkerAccess {
                isAllowedNow
              }
            }
            jobsiteWorker {
              jobsiteWorkerId
              stickerNumber
              isOnSite
              currentAccess {
                isAllowed
                notAllowedReason
                startDate
                endDate
              }
              jobsiteContractor {
                id
                jobsite {
                  jobsiteId
                  name
                }
                contractor {
                  contractorId
                  organization {
                    organizationId
                    name
                  }
                }
              }
              contractorWorker {
                id
                worker {
                  workerId
                  birthDate
                  quickCode
                  trade
                  jobTitle
                  unionAffiliation
                  user {
                    userAccountId
                    identity {
                      firstName
                      lastName
                      email
                      phoneNumber
                    }
                  }
                  profilePicture {
                    downloadUrl
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetFormSubmissionJobsiteWorkersQuery__
 *
 * To run a query within a React component, call `useGetFormSubmissionJobsiteWorkersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormSubmissionJobsiteWorkersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormSubmissionJobsiteWorkersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      jfsInput: // value for 'jfsInput'
 *   },
 * });
 */
export function useGetFormSubmissionJobsiteWorkersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFormSubmissionJobsiteWorkersQuery,
    GetFormSubmissionJobsiteWorkersQueryVariables
  > &
    ({ variables: GetFormSubmissionJobsiteWorkersQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFormSubmissionJobsiteWorkersQuery, GetFormSubmissionJobsiteWorkersQueryVariables>(
    GetFormSubmissionJobsiteWorkersDocument,
    options,
  );
}
export function useGetFormSubmissionJobsiteWorkersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFormSubmissionJobsiteWorkersQuery,
    GetFormSubmissionJobsiteWorkersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFormSubmissionJobsiteWorkersQuery, GetFormSubmissionJobsiteWorkersQueryVariables>(
    GetFormSubmissionJobsiteWorkersDocument,
    options,
  );
}
export function useGetFormSubmissionJobsiteWorkersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetFormSubmissionJobsiteWorkersQuery,
    GetFormSubmissionJobsiteWorkersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetFormSubmissionJobsiteWorkersQuery, GetFormSubmissionJobsiteWorkersQueryVariables>(
    GetFormSubmissionJobsiteWorkersDocument,
    options,
  );
}
export type GetFormSubmissionJobsiteWorkersQueryHookResult = ReturnType<typeof useGetFormSubmissionJobsiteWorkersQuery>;
export type GetFormSubmissionJobsiteWorkersLazyQueryHookResult = ReturnType<
  typeof useGetFormSubmissionJobsiteWorkersLazyQuery
>;
export type GetFormSubmissionJobsiteWorkersSuspenseQueryHookResult = ReturnType<
  typeof useGetFormSubmissionJobsiteWorkersSuspenseQuery
>;
export type GetFormSubmissionJobsiteWorkersQueryResult = Apollo.QueryResult<
  GetFormSubmissionJobsiteWorkersQuery,
  GetFormSubmissionJobsiteWorkersQueryVariables
>;
export const GetJobsiteWorkerDashboardReportDocument = gql`
  query GetJobsiteWorkerDashboardReport(
    $jobsiteIds: [ID!]!
    $startDate: Date!
    $endDate: Date!
    $timeBreakdown: GetJobsiteWorkerReportsTimeBreakdown
    $realtime: Boolean
    $limit: Int
    $offset: Int
    $sortField: String
    $sortDir: String
    $export: Boolean
    $trade: String
    $tradeClass: String
    $contractor: String
    $fitoutTenant: String
    $active: Boolean
    $compliant: Boolean
    $workerIds: [ID!]
  ) {
    getJobsiteWorkerDashboard(
      jobsiteIds: $jobsiteIds
      startDate: $startDate
      endDate: $endDate
      timeBreakdown: $timeBreakdown
      realtime: $realtime
      limit: $limit
      offset: $offset
      sortField: $sortField
      sortDir: $sortDir
      export: $export
      trade: $trade
      tradeClass: $tradeClass
      contractor: $contractor
      fitoutTenant: $fitoutTenant
      active: $active
      compliant: $compliant
      workerIds: $workerIds
    ) {
      jobsites {
        jobsiteId
        name
      }
      overviewBreakdowns {
        name
        count
        compliantCount
        percentage
      }
    }
  }
`;

/**
 * __useGetJobsiteWorkerDashboardReportQuery__
 *
 * To run a query within a React component, call `useGetJobsiteWorkerDashboardReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobsiteWorkerDashboardReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobsiteWorkerDashboardReportQuery({
 *   variables: {
 *      jobsiteIds: // value for 'jobsiteIds'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      timeBreakdown: // value for 'timeBreakdown'
 *      realtime: // value for 'realtime'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sortField: // value for 'sortField'
 *      sortDir: // value for 'sortDir'
 *      export: // value for 'export'
 *      trade: // value for 'trade'
 *      tradeClass: // value for 'tradeClass'
 *      contractor: // value for 'contractor'
 *      fitoutTenant: // value for 'fitoutTenant'
 *      active: // value for 'active'
 *      compliant: // value for 'compliant'
 *      workerIds: // value for 'workerIds'
 *   },
 * });
 */
export function useGetJobsiteWorkerDashboardReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetJobsiteWorkerDashboardReportQuery,
    GetJobsiteWorkerDashboardReportQueryVariables
  > &
    ({ variables: GetJobsiteWorkerDashboardReportQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetJobsiteWorkerDashboardReportQuery, GetJobsiteWorkerDashboardReportQueryVariables>(
    GetJobsiteWorkerDashboardReportDocument,
    options,
  );
}
export function useGetJobsiteWorkerDashboardReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetJobsiteWorkerDashboardReportQuery,
    GetJobsiteWorkerDashboardReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetJobsiteWorkerDashboardReportQuery, GetJobsiteWorkerDashboardReportQueryVariables>(
    GetJobsiteWorkerDashboardReportDocument,
    options,
  );
}
export function useGetJobsiteWorkerDashboardReportSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetJobsiteWorkerDashboardReportQuery,
    GetJobsiteWorkerDashboardReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetJobsiteWorkerDashboardReportQuery, GetJobsiteWorkerDashboardReportQueryVariables>(
    GetJobsiteWorkerDashboardReportDocument,
    options,
  );
}
export type GetJobsiteWorkerDashboardReportQueryHookResult = ReturnType<typeof useGetJobsiteWorkerDashboardReportQuery>;
export type GetJobsiteWorkerDashboardReportLazyQueryHookResult = ReturnType<
  typeof useGetJobsiteWorkerDashboardReportLazyQuery
>;
export type GetJobsiteWorkerDashboardReportSuspenseQueryHookResult = ReturnType<
  typeof useGetJobsiteWorkerDashboardReportSuspenseQuery
>;
export type GetJobsiteWorkerDashboardReportQueryResult = Apollo.QueryResult<
  GetJobsiteWorkerDashboardReportQuery,
  GetJobsiteWorkerDashboardReportQueryVariables
>;
export const GetJobsiteFormSubmissionDocument = gql`
  query GetJobsiteFormSubmission($id: ID!) {
    getJobsiteFormSubmission(id: $id) {
      id
      startAt
      isStartTimeSpecified
      endAt
      timeZone
      extraData
      weatherConditions {
        timestamp
        description
        icon
        temp
        windSpeed
        windDirectionCardinal
      }
      jobsiteForm {
        id
        form {
          id
          key
          content
        }
        jobsite {
          jobsiteId
          name
          modules {
            ... on JobsiteFeaturesModule {
              formsEnabled
              addWorkersToDailyLog
              isEnabled
              name
              slug
            }
          }
        }
      }
      jobsiteContractors {
        edges {
          node {
            id
            extraData
            associationType
            jobsiteContractor {
              id
              contractor {
                contractorId
                organization {
                  organizationId
                  name
                }
              }
            }
          }
        }
      }
      jobsiteWorkers {
        count
        edges {
          node {
            id
            extraData
            associationType
            jobsiteWorker {
              jobsiteWorkerId
            }
          }
        }
      }
      documents {
        count
        edges {
          node {
            id
            description
            files {
              fileId
              fileType
              downloadUrl
              originalFileName
            }
            objectHistory {
              createdAt
              createdBy {
                identity {
                  firstName
                  lastName
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetJobsiteFormSubmissionQuery__
 *
 * To run a query within a React component, call `useGetJobsiteFormSubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobsiteFormSubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobsiteFormSubmissionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetJobsiteFormSubmissionQuery(
  baseOptions: Apollo.QueryHookOptions<GetJobsiteFormSubmissionQuery, GetJobsiteFormSubmissionQueryVariables> &
    ({ variables: GetJobsiteFormSubmissionQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetJobsiteFormSubmissionQuery, GetJobsiteFormSubmissionQueryVariables>(
    GetJobsiteFormSubmissionDocument,
    options,
  );
}
export function useGetJobsiteFormSubmissionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetJobsiteFormSubmissionQuery, GetJobsiteFormSubmissionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetJobsiteFormSubmissionQuery, GetJobsiteFormSubmissionQueryVariables>(
    GetJobsiteFormSubmissionDocument,
    options,
  );
}
export function useGetJobsiteFormSubmissionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetJobsiteFormSubmissionQuery, GetJobsiteFormSubmissionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetJobsiteFormSubmissionQuery, GetJobsiteFormSubmissionQueryVariables>(
    GetJobsiteFormSubmissionDocument,
    options,
  );
}
export type GetJobsiteFormSubmissionQueryHookResult = ReturnType<typeof useGetJobsiteFormSubmissionQuery>;
export type GetJobsiteFormSubmissionLazyQueryHookResult = ReturnType<typeof useGetJobsiteFormSubmissionLazyQuery>;
export type GetJobsiteFormSubmissionSuspenseQueryHookResult = ReturnType<
  typeof useGetJobsiteFormSubmissionSuspenseQuery
>;
export type GetJobsiteFormSubmissionQueryResult = Apollo.QueryResult<
  GetJobsiteFormSubmissionQuery,
  GetJobsiteFormSubmissionQueryVariables
>;
export const GetJobsiteFormSubmissionHistoricalDataDocument = gql`
  query GetJobsiteFormSubmissionHistoricalData($id: ID!) {
    getJobsiteFormSubmission(id: $id) {
      id
      jobsiteWorkers {
        count
        edges {
          node {
            id
            extraData
            objectHistory {
              createdAt
            }
            historicalData {
              jobsiteWorkerAccess {
                isAllowedNow
                notAllowedReason
                jobsiteIsDisabledAccess
                jobsiteAccessReadmissionDate
                endDate
                isAntipassbackExempt
                siteSpecificTrainingCompletedAt
                siteSpecificTrainingExpiredAt
                siteSpecificTrainingSkipReason
                safetyDocumentsCompletedAt
                safetyDocumentsExpiredAt
                safetyDocumentsSkipReason
                orientationMedicalCompletedAt
                orientationMedicalExpiredAt
                orientationMedicalSkipReason
                nycSstExemptionReason
                safetyDocumentStatus
                jobsiteWorker {
                  jobsiteWorkerId
                  isBanned
                  bannedReason
                  bannedAt
                  banRemovedReason
                  startDate
                  endDate
                  payrollId
                  siteSpecificTrainingCompletedAt
                  stickerNumber
                  orientationDate
                  orientationStatus
                  documentsCompletedSkipExpiresAt
                  urineTestConditionalPassExpiresAt
                  banExpiresAt
                  badgingCompletedAt
                  medicalNotes
                  nycSstExemptionReason
                  siteAccessChangeApprovedBy
                  isExemptFromSwipeInactivity
                  isExemptFromSiteSpecificRefresher
                  jobsiteContractor {
                    jobsite {
                      name
                      jobsiteId
                      parentId
                      developer {
                        developerId
                        name
                      }
                    }
                  }
                  contractorWorker {
                    worker {
                      workerId
                      trade
                      jobTitle
                      documents {
                        edges {
                          node {
                            jobsiteWorkerDocumentId
                            jobsiteWorkerDocumentType {
                              expiration
                              jobsite {
                                name
                                jobsiteId
                                developer {
                                  name
                                  developerId
                                }
                              }
                              workerDocumentType {
                                name
                                key
                                expirationDate
                                isGlobal
                                documentLevel
                              }
                            }
                            jobsiteWorker {
                              jobsiteContractor {
                                jobsite {
                                  jobsiteId
                                }
                              }
                              jobsiteWorkerId
                            }
                            latestVersion {
                              jobsiteWorkerDocumentVersionId
                              files {
                                fileId
                                fileType
                                downloadUrl
                              }
                              additionalFieldValues {
                                key
                                value
                              }
                              objectHistory {
                                createdAt
                                createdBy {
                                  userAccountId
                                  identity {
                                    firstName
                                    lastName
                                    email
                                  }
                                }
                                updatedAt
                                updatedBy {
                                  userAccountId
                                  identity {
                                    firstName
                                    lastName
                                    email
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      jobsiteAccessEvents {
                        edges {
                          node {
                            id
                            status
                            timeStamp
                            eventOccurredAt
                            timeZone
                            overnightSession
                            gateway
                            direction
                            eventType
                            credentialId
                          }
                        }
                      }
                      objectHistory {
                        createdAt
                        createdBy {
                          userAccountId
                          identity {
                            firstName
                            lastName
                            email
                          }
                        }
                        updatedAt
                        updatedBy {
                          userAccountId
                          identity {
                            firstName
                            lastName
                            email
                          }
                        }
                      }
                    }
                    contractor {
                      contractorId
                      organization {
                        organizationId
                        name
                      }
                    }
                  }
                  objectHistory {
                    createdAt
                    createdBy {
                      userAccountId
                      identity {
                        firstName
                        lastName
                        email
                      }
                    }
                    updatedAt
                    updatedBy {
                      userAccountId
                      identity {
                        firstName
                        lastName
                        email
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetJobsiteFormSubmissionHistoricalDataQuery__
 *
 * To run a query within a React component, call `useGetJobsiteFormSubmissionHistoricalDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobsiteFormSubmissionHistoricalDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobsiteFormSubmissionHistoricalDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetJobsiteFormSubmissionHistoricalDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetJobsiteFormSubmissionHistoricalDataQuery,
    GetJobsiteFormSubmissionHistoricalDataQueryVariables
  > &
    ({ variables: GetJobsiteFormSubmissionHistoricalDataQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetJobsiteFormSubmissionHistoricalDataQuery,
    GetJobsiteFormSubmissionHistoricalDataQueryVariables
  >(GetJobsiteFormSubmissionHistoricalDataDocument, options);
}
export function useGetJobsiteFormSubmissionHistoricalDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetJobsiteFormSubmissionHistoricalDataQuery,
    GetJobsiteFormSubmissionHistoricalDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetJobsiteFormSubmissionHistoricalDataQuery,
    GetJobsiteFormSubmissionHistoricalDataQueryVariables
  >(GetJobsiteFormSubmissionHistoricalDataDocument, options);
}
export function useGetJobsiteFormSubmissionHistoricalDataSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetJobsiteFormSubmissionHistoricalDataQuery,
    GetJobsiteFormSubmissionHistoricalDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetJobsiteFormSubmissionHistoricalDataQuery,
    GetJobsiteFormSubmissionHistoricalDataQueryVariables
  >(GetJobsiteFormSubmissionHistoricalDataDocument, options);
}
export type GetJobsiteFormSubmissionHistoricalDataQueryHookResult = ReturnType<
  typeof useGetJobsiteFormSubmissionHistoricalDataQuery
>;
export type GetJobsiteFormSubmissionHistoricalDataLazyQueryHookResult = ReturnType<
  typeof useGetJobsiteFormSubmissionHistoricalDataLazyQuery
>;
export type GetJobsiteFormSubmissionHistoricalDataSuspenseQueryHookResult = ReturnType<
  typeof useGetJobsiteFormSubmissionHistoricalDataSuspenseQuery
>;
export type GetJobsiteFormSubmissionHistoricalDataQueryResult = Apollo.QueryResult<
  GetJobsiteFormSubmissionHistoricalDataQuery,
  GetJobsiteFormSubmissionHistoricalDataQueryVariables
>;
export const GetJobsiteFormSubmissionReportDocument = gql`
  query GetJobsiteFormSubmissionReport($id: ID!) {
    getJobsiteFormSubmission(id: $id) {
      id
      startAt
      isStartTimeSpecified
      endAt
      timeZone
      extraData
      weatherConditions {
        timestamp
        description
        icon
        temp
        windSpeed
        windDirectionCardinal
      }
      jobsiteForm {
        id
        form {
          id
          key
          name
          content
        }
        jobsite {
          jobsiteId
          name
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZipCode
        }
      }
      jobsiteContractors {
        edges {
          node {
            id
            extraData
            associationType
            jobsiteContractor {
              id
              contractor {
                contractorId
                organization {
                  organizationId
                  name
                  addressLine1
                  addressLine2
                  addressCity
                  addressState
                  addressZipCode
                }
              }
            }
          }
        }
      }
      jobsiteWorkers {
        count
        edges {
          node {
            id
            extraData
            associationType
            jobsiteWorker {
              jobsiteWorkerId
              contractorWorker {
                id
                contractor {
                  contractorId
                  organization {
                    organizationId
                    name
                  }
                }
                worker {
                  workerId
                  birthDate
                  quickCode
                  trade
                  jobTitle
                  user {
                    userAccountId
                    identity {
                      firstName
                      lastName
                    }
                  }
                  profilePictureCropped {
                    downloadUrl
                  }
                }
              }
            }
          }
        }
      }
      documents {
        count
        edges {
          node {
            id
            description
            files {
              fileId
              fileType
              downloadUrl
              originalFileName
            }
            objectHistory {
              createdAt
              createdBy {
                identity {
                  firstName
                  lastName
                }
              }
            }
          }
        }
      }
      objectHistory {
        createdAt
        createdBy {
          userAccountId
          identity {
            firstName
            lastName
            phoneNumber
            email
          }
          worker {
            workerId
            trade
            jobTitle
            profilePictureCropped {
              downloadUrl
            }
          }
        }
        updatedAt
        updatedBy {
          identity {
            firstName
            lastName
          }
          worker {
            workerId
            profilePictureCropped {
              downloadUrl
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetJobsiteFormSubmissionReportQuery__
 *
 * To run a query within a React component, call `useGetJobsiteFormSubmissionReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobsiteFormSubmissionReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobsiteFormSubmissionReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetJobsiteFormSubmissionReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetJobsiteFormSubmissionReportQuery,
    GetJobsiteFormSubmissionReportQueryVariables
  > &
    ({ variables: GetJobsiteFormSubmissionReportQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetJobsiteFormSubmissionReportQuery, GetJobsiteFormSubmissionReportQueryVariables>(
    GetJobsiteFormSubmissionReportDocument,
    options,
  );
}
export function useGetJobsiteFormSubmissionReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetJobsiteFormSubmissionReportQuery,
    GetJobsiteFormSubmissionReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetJobsiteFormSubmissionReportQuery, GetJobsiteFormSubmissionReportQueryVariables>(
    GetJobsiteFormSubmissionReportDocument,
    options,
  );
}
export function useGetJobsiteFormSubmissionReportSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetJobsiteFormSubmissionReportQuery,
    GetJobsiteFormSubmissionReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetJobsiteFormSubmissionReportQuery, GetJobsiteFormSubmissionReportQueryVariables>(
    GetJobsiteFormSubmissionReportDocument,
    options,
  );
}
export type GetJobsiteFormSubmissionReportQueryHookResult = ReturnType<typeof useGetJobsiteFormSubmissionReportQuery>;
export type GetJobsiteFormSubmissionReportLazyQueryHookResult = ReturnType<
  typeof useGetJobsiteFormSubmissionReportLazyQuery
>;
export type GetJobsiteFormSubmissionReportSuspenseQueryHookResult = ReturnType<
  typeof useGetJobsiteFormSubmissionReportSuspenseQuery
>;
export type GetJobsiteFormSubmissionReportQueryResult = Apollo.QueryResult<
  GetJobsiteFormSubmissionReportQuery,
  GetJobsiteFormSubmissionReportQueryVariables
>;
export const FormUpdateDocument = gql`
  mutation FormUpdate($input: FormUpdateInput!) {
    formUpdate(input: $input) {
      form {
        id
      }
    }
  }
`;
export type FormUpdateMutationFn = Apollo.MutationFunction<FormUpdateMutation, FormUpdateMutationVariables>;

/**
 * __useFormUpdateMutation__
 *
 * To run a mutation, you first call `useFormUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFormUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [formUpdateMutation, { data, loading, error }] = useFormUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFormUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<FormUpdateMutation, FormUpdateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FormUpdateMutation, FormUpdateMutationVariables>(FormUpdateDocument, options);
}
export type FormUpdateMutationHookResult = ReturnType<typeof useFormUpdateMutation>;
export type FormUpdateMutationResult = Apollo.MutationResult<FormUpdateMutation>;
export type FormUpdateMutationOptions = Apollo.BaseMutationOptions<FormUpdateMutation, FormUpdateMutationVariables>;
export const JobsiteUpdateFormSubmissionDocument = gql`
  mutation JobsiteUpdateFormSubmission($input: JobsiteUpdateFormSubmissionInput!) {
    jobsiteUpdateFormSubmission(input: $input) {
      jobsiteFormSubmission {
        id
      }
    }
  }
`;
export type JobsiteUpdateFormSubmissionMutationFn = Apollo.MutationFunction<
  JobsiteUpdateFormSubmissionMutation,
  JobsiteUpdateFormSubmissionMutationVariables
>;

/**
 * __useJobsiteUpdateFormSubmissionMutation__
 *
 * To run a mutation, you first call `useJobsiteUpdateFormSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobsiteUpdateFormSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobsiteUpdateFormSubmissionMutation, { data, loading, error }] = useJobsiteUpdateFormSubmissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJobsiteUpdateFormSubmissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JobsiteUpdateFormSubmissionMutation,
    JobsiteUpdateFormSubmissionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<JobsiteUpdateFormSubmissionMutation, JobsiteUpdateFormSubmissionMutationVariables>(
    JobsiteUpdateFormSubmissionDocument,
    options,
  );
}
export type JobsiteUpdateFormSubmissionMutationHookResult = ReturnType<typeof useJobsiteUpdateFormSubmissionMutation>;
export type JobsiteUpdateFormSubmissionMutationResult = Apollo.MutationResult<JobsiteUpdateFormSubmissionMutation>;
export type JobsiteUpdateFormSubmissionMutationOptions = Apollo.BaseMutationOptions<
  JobsiteUpdateFormSubmissionMutation,
  JobsiteUpdateFormSubmissionMutationVariables
>;
export const JobsiteCloseOutDocument = gql`
  mutation JobsiteCloseOut($input: JobsiteCloseOutInput!) {
    jobsiteCloseOut(input: $input) {
      jobsite {
        jobsiteId
      }
    }
  }
`;
export type JobsiteCloseOutMutationFn = Apollo.MutationFunction<
  JobsiteCloseOutMutation,
  JobsiteCloseOutMutationVariables
>;

/**
 * __useJobsiteCloseOutMutation__
 *
 * To run a mutation, you first call `useJobsiteCloseOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobsiteCloseOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobsiteCloseOutMutation, { data, loading, error }] = useJobsiteCloseOutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJobsiteCloseOutMutation(
  baseOptions?: Apollo.MutationHookOptions<JobsiteCloseOutMutation, JobsiteCloseOutMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<JobsiteCloseOutMutation, JobsiteCloseOutMutationVariables>(
    JobsiteCloseOutDocument,
    options,
  );
}
export type JobsiteCloseOutMutationHookResult = ReturnType<typeof useJobsiteCloseOutMutation>;
export type JobsiteCloseOutMutationResult = Apollo.MutationResult<JobsiteCloseOutMutation>;
export type JobsiteCloseOutMutationOptions = Apollo.BaseMutationOptions<
  JobsiteCloseOutMutation,
  JobsiteCloseOutMutationVariables
>;
export const JobsiteCreateDocument = gql`
  mutation JobsiteCreate($input: JobsiteCreateInput!) {
    jobsiteCreate(input: $input) {
      jobsite {
        jobsiteId
      }
    }
  }
`;
export type JobsiteCreateMutationFn = Apollo.MutationFunction<JobsiteCreateMutation, JobsiteCreateMutationVariables>;

/**
 * __useJobsiteCreateMutation__
 *
 * To run a mutation, you first call `useJobsiteCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobsiteCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobsiteCreateMutation, { data, loading, error }] = useJobsiteCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJobsiteCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<JobsiteCreateMutation, JobsiteCreateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<JobsiteCreateMutation, JobsiteCreateMutationVariables>(JobsiteCreateDocument, options);
}
export type JobsiteCreateMutationHookResult = ReturnType<typeof useJobsiteCreateMutation>;
export type JobsiteCreateMutationResult = Apollo.MutationResult<JobsiteCreateMutation>;
export type JobsiteCreateMutationOptions = Apollo.BaseMutationOptions<
  JobsiteCreateMutation,
  JobsiteCreateMutationVariables
>;
export const GetJobsiteTemplatesDocument = gql`
  query GetJobsiteTemplates {
    getCurrentSession {
      user {
        userAccountId
        jobsites(input: { isTemplate: true }) {
          edges {
            node {
              jobsiteId
              name
              developer {
                developerId
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetJobsiteTemplatesQuery__
 *
 * To run a query within a React component, call `useGetJobsiteTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobsiteTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobsiteTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetJobsiteTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetJobsiteTemplatesQuery, GetJobsiteTemplatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetJobsiteTemplatesQuery, GetJobsiteTemplatesQueryVariables>(
    GetJobsiteTemplatesDocument,
    options,
  );
}
export function useGetJobsiteTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetJobsiteTemplatesQuery, GetJobsiteTemplatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetJobsiteTemplatesQuery, GetJobsiteTemplatesQueryVariables>(
    GetJobsiteTemplatesDocument,
    options,
  );
}
export function useGetJobsiteTemplatesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetJobsiteTemplatesQuery, GetJobsiteTemplatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetJobsiteTemplatesQuery, GetJobsiteTemplatesQueryVariables>(
    GetJobsiteTemplatesDocument,
    options,
  );
}
export type GetJobsiteTemplatesQueryHookResult = ReturnType<typeof useGetJobsiteTemplatesQuery>;
export type GetJobsiteTemplatesLazyQueryHookResult = ReturnType<typeof useGetJobsiteTemplatesLazyQuery>;
export type GetJobsiteTemplatesSuspenseQueryHookResult = ReturnType<typeof useGetJobsiteTemplatesSuspenseQuery>;
export type GetJobsiteTemplatesQueryResult = Apollo.QueryResult<
  GetJobsiteTemplatesQuery,
  GetJobsiteTemplatesQueryVariables
>;
export const GetJobsitesContainerCurrentSessionDocument = gql`
  query GetJobsitesContainerCurrentSession($userJobsitesInput: UserJobsitesInput) {
    getCurrentSession {
      user {
        userAccountId
        jobsites(input: $userJobsitesInput) {
          count
          templatesCount
          edges {
            node {
              jobsiteId
              name
              addressCity
              addressState
              developer {
                developerId
                organization {
                  organizationId
                  name
                }
              }
              startDate
              endDate
            }
          }
          filtersOptions {
            developers {
              value
              label
              count
            }
            cities {
              value
              label
              count
            }
            states {
              value
              label
              count
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetJobsitesContainerCurrentSessionQuery__
 *
 * To run a query within a React component, call `useGetJobsitesContainerCurrentSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobsitesContainerCurrentSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobsitesContainerCurrentSessionQuery({
 *   variables: {
 *      userJobsitesInput: // value for 'userJobsitesInput'
 *   },
 * });
 */
export function useGetJobsitesContainerCurrentSessionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetJobsitesContainerCurrentSessionQuery,
    GetJobsitesContainerCurrentSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetJobsitesContainerCurrentSessionQuery, GetJobsitesContainerCurrentSessionQueryVariables>(
    GetJobsitesContainerCurrentSessionDocument,
    options,
  );
}
export function useGetJobsitesContainerCurrentSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetJobsitesContainerCurrentSessionQuery,
    GetJobsitesContainerCurrentSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetJobsitesContainerCurrentSessionQuery, GetJobsitesContainerCurrentSessionQueryVariables>(
    GetJobsitesContainerCurrentSessionDocument,
    options,
  );
}
export function useGetJobsitesContainerCurrentSessionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetJobsitesContainerCurrentSessionQuery,
    GetJobsitesContainerCurrentSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetJobsitesContainerCurrentSessionQuery,
    GetJobsitesContainerCurrentSessionQueryVariables
  >(GetJobsitesContainerCurrentSessionDocument, options);
}
export type GetJobsitesContainerCurrentSessionQueryHookResult = ReturnType<
  typeof useGetJobsitesContainerCurrentSessionQuery
>;
export type GetJobsitesContainerCurrentSessionLazyQueryHookResult = ReturnType<
  typeof useGetJobsitesContainerCurrentSessionLazyQuery
>;
export type GetJobsitesContainerCurrentSessionSuspenseQueryHookResult = ReturnType<
  typeof useGetJobsitesContainerCurrentSessionSuspenseQuery
>;
export type GetJobsitesContainerCurrentSessionQueryResult = Apollo.QueryResult<
  GetJobsitesContainerCurrentSessionQuery,
  GetJobsitesContainerCurrentSessionQueryVariables
>;
export const AuthenticateLimitedTokenDetailsDocument = gql`
  mutation AuthenticateLimitedTokenDetails($input: AuthenticateLimitedTokenDetailsInput!) {
    authenticateLimitedTokenDetails(input: $input) {
      token
    }
  }
`;
export type AuthenticateLimitedTokenDetailsMutationFn = Apollo.MutationFunction<
  AuthenticateLimitedTokenDetailsMutation,
  AuthenticateLimitedTokenDetailsMutationVariables
>;

/**
 * __useAuthenticateLimitedTokenDetailsMutation__
 *
 * To run a mutation, you first call `useAuthenticateLimitedTokenDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateLimitedTokenDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateLimitedTokenDetailsMutation, { data, loading, error }] = useAuthenticateLimitedTokenDetailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthenticateLimitedTokenDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthenticateLimitedTokenDetailsMutation,
    AuthenticateLimitedTokenDetailsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AuthenticateLimitedTokenDetailsMutation, AuthenticateLimitedTokenDetailsMutationVariables>(
    AuthenticateLimitedTokenDetailsDocument,
    options,
  );
}
export type AuthenticateLimitedTokenDetailsMutationHookResult = ReturnType<
  typeof useAuthenticateLimitedTokenDetailsMutation
>;
export type AuthenticateLimitedTokenDetailsMutationResult =
  Apollo.MutationResult<AuthenticateLimitedTokenDetailsMutation>;
export type AuthenticateLimitedTokenDetailsMutationOptions = Apollo.BaseMutationOptions<
  AuthenticateLimitedTokenDetailsMutation,
  AuthenticateLimitedTokenDetailsMutationVariables
>;
export const GetRecentUserSearchQueriesDocument = gql`
  query GetRecentUserSearchQueries($searchQueryType: SearchQueryType, $count: Int) {
    getRecentUserSearchQueries(searchQueryType: $searchQueryType, count: $count) {
      results {
        searchQuery
        searchQueryType
        createdAt
      }
    }
  }
`;

/**
 * __useGetRecentUserSearchQueriesQuery__
 *
 * To run a query within a React component, call `useGetRecentUserSearchQueriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentUserSearchQueriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentUserSearchQueriesQuery({
 *   variables: {
 *      searchQueryType: // value for 'searchQueryType'
 *      count: // value for 'count'
 *   },
 * });
 */
export function useGetRecentUserSearchQueriesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRecentUserSearchQueriesQuery, GetRecentUserSearchQueriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRecentUserSearchQueriesQuery, GetRecentUserSearchQueriesQueryVariables>(
    GetRecentUserSearchQueriesDocument,
    options,
  );
}
export function useGetRecentUserSearchQueriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRecentUserSearchQueriesQuery, GetRecentUserSearchQueriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRecentUserSearchQueriesQuery, GetRecentUserSearchQueriesQueryVariables>(
    GetRecentUserSearchQueriesDocument,
    options,
  );
}
export function useGetRecentUserSearchQueriesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetRecentUserSearchQueriesQuery,
    GetRecentUserSearchQueriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetRecentUserSearchQueriesQuery, GetRecentUserSearchQueriesQueryVariables>(
    GetRecentUserSearchQueriesDocument,
    options,
  );
}
export type GetRecentUserSearchQueriesQueryHookResult = ReturnType<typeof useGetRecentUserSearchQueriesQuery>;
export type GetRecentUserSearchQueriesLazyQueryHookResult = ReturnType<typeof useGetRecentUserSearchQueriesLazyQuery>;
export type GetRecentUserSearchQueriesSuspenseQueryHookResult = ReturnType<
  typeof useGetRecentUserSearchQueriesSuspenseQuery
>;
export type GetRecentUserSearchQueriesQueryResult = Apollo.QueryResult<
  GetRecentUserSearchQueriesQuery,
  GetRecentUserSearchQueriesQueryVariables
>;
export const SaveUserSearchQueryDocument = gql`
  mutation SaveUserSearchQuery($input: UserSearchQueryInput!) {
    saveUserSearchQuery(input: $input) {
      success
    }
  }
`;
export type SaveUserSearchQueryMutationFn = Apollo.MutationFunction<
  SaveUserSearchQueryMutation,
  SaveUserSearchQueryMutationVariables
>;

/**
 * __useSaveUserSearchQueryMutation__
 *
 * To run a mutation, you first call `useSaveUserSearchQueryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserSearchQueryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserSearchQueryMutation, { data, loading, error }] = useSaveUserSearchQueryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveUserSearchQueryMutation(
  baseOptions?: Apollo.MutationHookOptions<SaveUserSearchQueryMutation, SaveUserSearchQueryMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveUserSearchQueryMutation, SaveUserSearchQueryMutationVariables>(
    SaveUserSearchQueryDocument,
    options,
  );
}
export type SaveUserSearchQueryMutationHookResult = ReturnType<typeof useSaveUserSearchQueryMutation>;
export type SaveUserSearchQueryMutationResult = Apollo.MutationResult<SaveUserSearchQueryMutation>;
export type SaveUserSearchQueryMutationOptions = Apollo.BaseMutationOptions<
  SaveUserSearchQueryMutation,
  SaveUserSearchQueryMutationVariables
>;
export const DeleteOrganizationDocument = gql`
  mutation DeleteOrganization($organizationId: ID!) {
    organizationDelete(organizationId: $organizationId) {
      success {
        success
      }
    }
  }
`;
export type DeleteOrganizationMutationFn = Apollo.MutationFunction<
  DeleteOrganizationMutation,
  DeleteOrganizationMutationVariables
>;

/**
 * __useDeleteOrganizationMutation__
 *
 * To run a mutation, you first call `useDeleteOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganizationMutation, { data, loading, error }] = useDeleteOrganizationMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useDeleteOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>(
    DeleteOrganizationDocument,
    options,
  );
}
export type DeleteOrganizationMutationHookResult = ReturnType<typeof useDeleteOrganizationMutation>;
export type DeleteOrganizationMutationResult = Apollo.MutationResult<DeleteOrganizationMutation>;
export type DeleteOrganizationMutationOptions = Apollo.BaseMutationOptions<
  DeleteOrganizationMutation,
  DeleteOrganizationMutationVariables
>;
export const GetOrganizationContainerOrganizationInfoDocument = gql`
  query GetOrganizationContainerOrganizationInfo($organizationId: ID!, $includeArchived: Boolean!) {
    getOrganization(organizationId: $organizationId, includeArchived: $includeArchived) {
      organizationId
      name
      slug
      legalName
      email
      phoneNumber
      addressLine1
      addressLine2
      addressCity
      addressCity
      addressState
      addressZipCode
      objectHistory {
        createdAt
        createdBy {
          userAccountId
          identity {
            firstName
            lastName
          }
        }
        updatedAt
        updatedBy {
          userAccountId
          identity {
            firstName
            lastName
          }
        }
      }
      developers {
        __typename
        count
        edges {
          node {
            __typename
            developerId
            name
          }
        }
      }
      contractors {
        __typename
        count
        edges {
          __typename
          node {
            __typename
            contractorId
            description
            websiteUrl
            jobsiteContractors {
              __typename
              count
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetOrganizationContainerOrganizationInfoQuery__
 *
 * To run a query within a React component, call `useGetOrganizationContainerOrganizationInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationContainerOrganizationInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationContainerOrganizationInfoQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      includeArchived: // value for 'includeArchived'
 *   },
 * });
 */
export function useGetOrganizationContainerOrganizationInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrganizationContainerOrganizationInfoQuery,
    GetOrganizationContainerOrganizationInfoQueryVariables
  > &
    ({ variables: GetOrganizationContainerOrganizationInfoQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOrganizationContainerOrganizationInfoQuery,
    GetOrganizationContainerOrganizationInfoQueryVariables
  >(GetOrganizationContainerOrganizationInfoDocument, options);
}
export function useGetOrganizationContainerOrganizationInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrganizationContainerOrganizationInfoQuery,
    GetOrganizationContainerOrganizationInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrganizationContainerOrganizationInfoQuery,
    GetOrganizationContainerOrganizationInfoQueryVariables
  >(GetOrganizationContainerOrganizationInfoDocument, options);
}
export function useGetOrganizationContainerOrganizationInfoSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetOrganizationContainerOrganizationInfoQuery,
    GetOrganizationContainerOrganizationInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetOrganizationContainerOrganizationInfoQuery,
    GetOrganizationContainerOrganizationInfoQueryVariables
  >(GetOrganizationContainerOrganizationInfoDocument, options);
}
export type GetOrganizationContainerOrganizationInfoQueryHookResult = ReturnType<
  typeof useGetOrganizationContainerOrganizationInfoQuery
>;
export type GetOrganizationContainerOrganizationInfoLazyQueryHookResult = ReturnType<
  typeof useGetOrganizationContainerOrganizationInfoLazyQuery
>;
export type GetOrganizationContainerOrganizationInfoSuspenseQueryHookResult = ReturnType<
  typeof useGetOrganizationContainerOrganizationInfoSuspenseQuery
>;
export type GetOrganizationContainerOrganizationInfoQueryResult = Apollo.QueryResult<
  GetOrganizationContainerOrganizationInfoQuery,
  GetOrganizationContainerOrganizationInfoQueryVariables
>;
export const GetOrganizationsContainerCurrentSessionDocument = gql`
  query GetOrganizationsContainerCurrentSession($userOrganizationsInput: GetAllOrganizationsInput) {
    getCurrentSession {
      user {
        userAccountId
        organizations(input: $userOrganizationsInput) {
          count
          edges {
            node {
              name
              legalName
              organizationId
              slug
              addressLine1
              addressLine2
              addressCity
              addressState
              addressZipCode
              email
              phoneNumber
              contractors {
                __typename
                count
                edges {
                  node {
                    contractorId
                    __typename
                  }
                }
              }
              developers {
                __typename
                count
                edges {
                  node {
                    developerId
                    __typename
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetOrganizationsContainerCurrentSessionQuery__
 *
 * To run a query within a React component, call `useGetOrganizationsContainerCurrentSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationsContainerCurrentSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationsContainerCurrentSessionQuery({
 *   variables: {
 *      userOrganizationsInput: // value for 'userOrganizationsInput'
 *   },
 * });
 */
export function useGetOrganizationsContainerCurrentSessionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOrganizationsContainerCurrentSessionQuery,
    GetOrganizationsContainerCurrentSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOrganizationsContainerCurrentSessionQuery,
    GetOrganizationsContainerCurrentSessionQueryVariables
  >(GetOrganizationsContainerCurrentSessionDocument, options);
}
export function useGetOrganizationsContainerCurrentSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrganizationsContainerCurrentSessionQuery,
    GetOrganizationsContainerCurrentSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrganizationsContainerCurrentSessionQuery,
    GetOrganizationsContainerCurrentSessionQueryVariables
  >(GetOrganizationsContainerCurrentSessionDocument, options);
}
export function useGetOrganizationsContainerCurrentSessionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetOrganizationsContainerCurrentSessionQuery,
    GetOrganizationsContainerCurrentSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetOrganizationsContainerCurrentSessionQuery,
    GetOrganizationsContainerCurrentSessionQueryVariables
  >(GetOrganizationsContainerCurrentSessionDocument, options);
}
export type GetOrganizationsContainerCurrentSessionQueryHookResult = ReturnType<
  typeof useGetOrganizationsContainerCurrentSessionQuery
>;
export type GetOrganizationsContainerCurrentSessionLazyQueryHookResult = ReturnType<
  typeof useGetOrganizationsContainerCurrentSessionLazyQuery
>;
export type GetOrganizationsContainerCurrentSessionSuspenseQueryHookResult = ReturnType<
  typeof useGetOrganizationsContainerCurrentSessionSuspenseQuery
>;
export type GetOrganizationsContainerCurrentSessionQueryResult = Apollo.QueryResult<
  GetOrganizationsContainerCurrentSessionQuery,
  GetOrganizationsContainerCurrentSessionQueryVariables
>;
export const GetPrintWorkerDataDocument = gql`
  query GetPrintWorkerData($workerId: ID!) {
    getWorker(workerId: $workerId) {
      workerId
      middleInitial
      suffix
      addressLine1
      addressLine2
      addressCity
      addressState
      addressZipCode
      addressZipCode
      birthDate
      emergencyContactName
      emergencyContactPhone
      emergencyContactRelationship
      gender
      jobTitle
      primaryLanguage
      race
      isSkilled
      ssnLastFour
      trade
      unionAffiliation
      isVeteran
      quickCode
      profilePicture {
        downloadUrl
        uploadedAt
      }
      profilePictureCropped {
        downloadUrl
        uploadedAt
        fileId
        fileType
      }
      user {
        userAccountId
        identity {
          firstName
          lastName
          email
          phoneNumber
        }
      }
      contractorWorkers {
        edges {
          node {
            id
            startDate
            endDate
            payrollId
            contractor {
              contractorId
              organization {
                organizationId
                name
              }
            }
          }
        }
      }
      jobsiteWorkers {
        edges {
          node {
            jobsiteWorkerId
            isBanned
            bannedReason
            bannedAt
            startDate
            endDate
            payrollId
            stickerNumber
            orientationDate
            orientationStatus
            jobsiteContractor {
              jobsite {
                name
                jobsiteId
              }
              contractor {
                contractorId
                organization {
                  name
                }
              }
            }
            currentAccess {
              endDate
              isAllowed
            }
          }
        }
      }
      workerComments {
        edges {
          node {
            workerCommentId
            comment
            jobsite {
              name
              jobsiteId
            }
            objectHistory {
              createdAt
              createdBy {
                userAccountId
                identity {
                  firstName
                  lastName
                  email
                }
              }
              updatedAt
              updatedBy {
                userAccountId
                identity {
                  firstName
                  lastName
                  email
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetPrintWorkerDataQuery__
 *
 * To run a query within a React component, call `useGetPrintWorkerDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrintWorkerDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrintWorkerDataQuery({
 *   variables: {
 *      workerId: // value for 'workerId'
 *   },
 * });
 */
export function useGetPrintWorkerDataQuery(
  baseOptions: Apollo.QueryHookOptions<GetPrintWorkerDataQuery, GetPrintWorkerDataQueryVariables> &
    ({ variables: GetPrintWorkerDataQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPrintWorkerDataQuery, GetPrintWorkerDataQueryVariables>(
    GetPrintWorkerDataDocument,
    options,
  );
}
export function useGetPrintWorkerDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPrintWorkerDataQuery, GetPrintWorkerDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPrintWorkerDataQuery, GetPrintWorkerDataQueryVariables>(
    GetPrintWorkerDataDocument,
    options,
  );
}
export function useGetPrintWorkerDataSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetPrintWorkerDataQuery, GetPrintWorkerDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetPrintWorkerDataQuery, GetPrintWorkerDataQueryVariables>(
    GetPrintWorkerDataDocument,
    options,
  );
}
export type GetPrintWorkerDataQueryHookResult = ReturnType<typeof useGetPrintWorkerDataQuery>;
export type GetPrintWorkerDataLazyQueryHookResult = ReturnType<typeof useGetPrintWorkerDataLazyQuery>;
export type GetPrintWorkerDataSuspenseQueryHookResult = ReturnType<typeof useGetPrintWorkerDataSuspenseQuery>;
export type GetPrintWorkerDataQueryResult = Apollo.QueryResult<
  GetPrintWorkerDataQuery,
  GetPrintWorkerDataQueryVariables
>;
export const SearchWorkersDocument = gql`
  query SearchWorkers($searchString: String!, $limit: Int, $offset: Int) {
    searchWorkers(searchString: $searchString, limit: $limit, offset: $offset) {
      count
      results {
        worker {
          workerId
          quickCode
          trade
          jobTitle
          unionAffiliation
          user {
            userAccountId
            identity {
              firstName
              lastName
            }
          }
          profilePictureCropped {
            downloadUrl
          }
        }
      }
    }
  }
`;

/**
 * __useSearchWorkersQuery__
 *
 * To run a query within a React component, call `useSearchWorkersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchWorkersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchWorkersQuery({
 *   variables: {
 *      searchString: // value for 'searchString'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useSearchWorkersQuery(
  baseOptions: Apollo.QueryHookOptions<SearchWorkersQuery, SearchWorkersQueryVariables> &
    ({ variables: SearchWorkersQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchWorkersQuery, SearchWorkersQueryVariables>(SearchWorkersDocument, options);
}
export function useSearchWorkersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchWorkersQuery, SearchWorkersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchWorkersQuery, SearchWorkersQueryVariables>(SearchWorkersDocument, options);
}
export function useSearchWorkersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SearchWorkersQuery, SearchWorkersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SearchWorkersQuery, SearchWorkersQueryVariables>(SearchWorkersDocument, options);
}
export type SearchWorkersQueryHookResult = ReturnType<typeof useSearchWorkersQuery>;
export type SearchWorkersLazyQueryHookResult = ReturnType<typeof useSearchWorkersLazyQuery>;
export type SearchWorkersSuspenseQueryHookResult = ReturnType<typeof useSearchWorkersSuspenseQuery>;
export type SearchWorkersQueryResult = Apollo.QueryResult<SearchWorkersQuery, SearchWorkersQueryVariables>;
export const CompletePhoneNumberVerificationDocument = gql`
  mutation CompletePhoneNumberVerification($input: CompletePhoneNumberVerificationInput!) {
    completePhoneNumberVerification(input: $input) {
      success
      worker {
        workerId
        editableFields {
          phone
          email
        }
      }
    }
  }
`;
export type CompletePhoneNumberVerificationMutationFn = Apollo.MutationFunction<
  CompletePhoneNumberVerificationMutation,
  CompletePhoneNumberVerificationMutationVariables
>;

/**
 * __useCompletePhoneNumberVerificationMutation__
 *
 * To run a mutation, you first call `useCompletePhoneNumberVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompletePhoneNumberVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completePhoneNumberVerificationMutation, { data, loading, error }] = useCompletePhoneNumberVerificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompletePhoneNumberVerificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompletePhoneNumberVerificationMutation,
    CompletePhoneNumberVerificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CompletePhoneNumberVerificationMutation, CompletePhoneNumberVerificationMutationVariables>(
    CompletePhoneNumberVerificationDocument,
    options,
  );
}
export type CompletePhoneNumberVerificationMutationHookResult = ReturnType<
  typeof useCompletePhoneNumberVerificationMutation
>;
export type CompletePhoneNumberVerificationMutationResult =
  Apollo.MutationResult<CompletePhoneNumberVerificationMutation>;
export type CompletePhoneNumberVerificationMutationOptions = Apollo.BaseMutationOptions<
  CompletePhoneNumberVerificationMutation,
  CompletePhoneNumberVerificationMutationVariables
>;
export const CompletePhoneNumberVerificationAndSignInDocument = gql`
  mutation CompletePhoneNumberVerificationAndSignIn($input: CompletePhoneNumberVerificationAndSignInInput!) {
    completePhoneNumberVerificationAndSignIn(input: $input) {
      success
      jobsiteWorker {
        jobsiteWorkerId
        contractorWorker {
          worker {
            workerId
            editableFields {
              phone
              email
            }
          }
        }
      }
      auth {
        user {
          userAccountId
          identity {
            firstName
            lastName
          }
        }
        session {
          sessionToken
        }
      }
    }
  }
`;
export type CompletePhoneNumberVerificationAndSignInMutationFn = Apollo.MutationFunction<
  CompletePhoneNumberVerificationAndSignInMutation,
  CompletePhoneNumberVerificationAndSignInMutationVariables
>;

/**
 * __useCompletePhoneNumberVerificationAndSignInMutation__
 *
 * To run a mutation, you first call `useCompletePhoneNumberVerificationAndSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompletePhoneNumberVerificationAndSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completePhoneNumberVerificationAndSignInMutation, { data, loading, error }] = useCompletePhoneNumberVerificationAndSignInMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompletePhoneNumberVerificationAndSignInMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompletePhoneNumberVerificationAndSignInMutation,
    CompletePhoneNumberVerificationAndSignInMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompletePhoneNumberVerificationAndSignInMutation,
    CompletePhoneNumberVerificationAndSignInMutationVariables
  >(CompletePhoneNumberVerificationAndSignInDocument, options);
}
export type CompletePhoneNumberVerificationAndSignInMutationHookResult = ReturnType<
  typeof useCompletePhoneNumberVerificationAndSignInMutation
>;
export type CompletePhoneNumberVerificationAndSignInMutationResult =
  Apollo.MutationResult<CompletePhoneNumberVerificationAndSignInMutation>;
export type CompletePhoneNumberVerificationAndSignInMutationOptions = Apollo.BaseMutationOptions<
  CompletePhoneNumberVerificationAndSignInMutation,
  CompletePhoneNumberVerificationAndSignInMutationVariables
>;
export const SelfOnboardingWorkerCreateDocument = gql`
  mutation SelfOnboardingWorkerCreate($input: SelfOnboardingWorkerAccountCreateInput!) {
    selfOnboardingWorkerCreate(input: $input) {
      jobsiteWorker {
        jobsiteWorkerId
        contractorWorker {
          worker {
            workerId
            quickCode
          }
        }
      }
      auth {
        user {
          userAccountId
          identity {
            firstName
            lastName
          }
        }
        session {
          sessionToken
        }
      }
    }
  }
`;
export type SelfOnboardingWorkerCreateMutationFn = Apollo.MutationFunction<
  SelfOnboardingWorkerCreateMutation,
  SelfOnboardingWorkerCreateMutationVariables
>;

/**
 * __useSelfOnboardingWorkerCreateMutation__
 *
 * To run a mutation, you first call `useSelfOnboardingWorkerCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelfOnboardingWorkerCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selfOnboardingWorkerCreateMutation, { data, loading, error }] = useSelfOnboardingWorkerCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSelfOnboardingWorkerCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SelfOnboardingWorkerCreateMutation,
    SelfOnboardingWorkerCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SelfOnboardingWorkerCreateMutation, SelfOnboardingWorkerCreateMutationVariables>(
    SelfOnboardingWorkerCreateDocument,
    options,
  );
}
export type SelfOnboardingWorkerCreateMutationHookResult = ReturnType<typeof useSelfOnboardingWorkerCreateMutation>;
export type SelfOnboardingWorkerCreateMutationResult = Apollo.MutationResult<SelfOnboardingWorkerCreateMutation>;
export type SelfOnboardingWorkerCreateMutationOptions = Apollo.BaseMutationOptions<
  SelfOnboardingWorkerCreateMutation,
  SelfOnboardingWorkerCreateMutationVariables
>;
export const SelfOnboardingWorkerUpdateDocument = gql`
  mutation SelfOnboardingWorkerUpdate($input: SelfOnboardingWorkerAccountUpdateInput!) {
    selfOnboardingWorkerUpdate(input: $input) {
      worker {
        workerId
      }
    }
  }
`;
export type SelfOnboardingWorkerUpdateMutationFn = Apollo.MutationFunction<
  SelfOnboardingWorkerUpdateMutation,
  SelfOnboardingWorkerUpdateMutationVariables
>;

/**
 * __useSelfOnboardingWorkerUpdateMutation__
 *
 * To run a mutation, you first call `useSelfOnboardingWorkerUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelfOnboardingWorkerUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selfOnboardingWorkerUpdateMutation, { data, loading, error }] = useSelfOnboardingWorkerUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSelfOnboardingWorkerUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SelfOnboardingWorkerUpdateMutation,
    SelfOnboardingWorkerUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SelfOnboardingWorkerUpdateMutation, SelfOnboardingWorkerUpdateMutationVariables>(
    SelfOnboardingWorkerUpdateDocument,
    options,
  );
}
export type SelfOnboardingWorkerUpdateMutationHookResult = ReturnType<typeof useSelfOnboardingWorkerUpdateMutation>;
export type SelfOnboardingWorkerUpdateMutationResult = Apollo.MutationResult<SelfOnboardingWorkerUpdateMutation>;
export type SelfOnboardingWorkerUpdateMutationOptions = Apollo.BaseMutationOptions<
  SelfOnboardingWorkerUpdateMutation,
  SelfOnboardingWorkerUpdateMutationVariables
>;
export const StartPhoneNumberVerificationDocument = gql`
  mutation StartPhoneNumberVerification($input: StartPhoneNumberVerificationInput!) {
    startPhoneNumberVerification(input: $input) {
      success
    }
  }
`;
export type StartPhoneNumberVerificationMutationFn = Apollo.MutationFunction<
  StartPhoneNumberVerificationMutation,
  StartPhoneNumberVerificationMutationVariables
>;

/**
 * __useStartPhoneNumberVerificationMutation__
 *
 * To run a mutation, you first call `useStartPhoneNumberVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartPhoneNumberVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startPhoneNumberVerificationMutation, { data, loading, error }] = useStartPhoneNumberVerificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartPhoneNumberVerificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartPhoneNumberVerificationMutation,
    StartPhoneNumberVerificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StartPhoneNumberVerificationMutation, StartPhoneNumberVerificationMutationVariables>(
    StartPhoneNumberVerificationDocument,
    options,
  );
}
export type StartPhoneNumberVerificationMutationHookResult = ReturnType<typeof useStartPhoneNumberVerificationMutation>;
export type StartPhoneNumberVerificationMutationResult = Apollo.MutationResult<StartPhoneNumberVerificationMutation>;
export type StartPhoneNumberVerificationMutationOptions = Apollo.BaseMutationOptions<
  StartPhoneNumberVerificationMutation,
  StartPhoneNumberVerificationMutationVariables
>;
export const UpsertJobsiteWorkerDocumentDocument = gql`
  mutation UpsertJobsiteWorkerDocument($input: JobsiteWorkerDocumentUpsertInput!) {
    upsertJobsiteWorkerDocument(input: $input) {
      jobsiteWorkerDocumentVersionId
      files {
        fileId
        fileType
        downloadUrl
        originalFileName
        uploadedAt
      }
      additionalFieldValues {
        key
        value
      }
    }
  }
`;
export type UpsertJobsiteWorkerDocumentMutationFn = Apollo.MutationFunction<
  UpsertJobsiteWorkerDocumentMutation,
  UpsertJobsiteWorkerDocumentMutationVariables
>;

/**
 * __useUpsertJobsiteWorkerDocumentMutation__
 *
 * To run a mutation, you first call `useUpsertJobsiteWorkerDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertJobsiteWorkerDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertJobsiteWorkerDocumentMutation, { data, loading, error }] = useUpsertJobsiteWorkerDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertJobsiteWorkerDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertJobsiteWorkerDocumentMutation,
    UpsertJobsiteWorkerDocumentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpsertJobsiteWorkerDocumentMutation, UpsertJobsiteWorkerDocumentMutationVariables>(
    UpsertJobsiteWorkerDocumentDocument,
    options,
  );
}
export type UpsertJobsiteWorkerDocumentMutationHookResult = ReturnType<typeof useUpsertJobsiteWorkerDocumentMutation>;
export type UpsertJobsiteWorkerDocumentMutationResult = Apollo.MutationResult<UpsertJobsiteWorkerDocumentMutation>;
export type UpsertJobsiteWorkerDocumentMutationOptions = Apollo.BaseMutationOptions<
  UpsertJobsiteWorkerDocumentMutation,
  UpsertJobsiteWorkerDocumentMutationVariables
>;
export const GetSelfOnboardingContainerSessionDocument = gql`
  query GetSelfOnboardingContainerSession(
    $jobsiteWorkersInput: WorkerJobsiteWorkersInput!
    $workerDocumentsInput: WorkerDocumentsInput!
  ) {
    getCurrentSession {
      user {
        userAccountId
        identity {
          firstName
          lastName
          email
          phoneNumber
        }
        worker {
          workerId
          quickCode
          middleInitial
          suffix
          birthDate
          ssnLastFour
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZipCode
          primaryLanguage
          citizenshipStatus
          race
          gender
          isVeteran
          trade
          jobTitle
          unionAffiliation
          emergencyContactName
          emergencyContactRelationship
          emergencyContactPhone
          selfOnboardingCurrentStep
          isAssignedToMultipleJobsites
          profilePicture {
            fileId
            fileType
            downloadUrl
            uploadedAt
          }
          editableFields {
            email
            phone
          }
          jobsiteWorkers(input: $jobsiteWorkersInput) {
            edges {
              node {
                jobsiteWorkerId
                jobsiteContractor {
                  id
                  contractor {
                    contractorId
                    isDefaultContractor
                    organization {
                      organizationId
                      name
                    }
                  }
                }
              }
            }
          }
          documents(input: $workerDocumentsInput) {
            edges {
              node {
                jobsiteWorkerDocumentId
                jobsiteWorkerDocumentType {
                  id
                  workerDocumentType {
                    key
                  }
                }
                latestVersion {
                  jobsiteWorkerDocumentVersionId
                  additionalFieldValues {
                    key
                    value
                  }
                  files {
                    fileId
                    fileType
                    downloadUrl
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetSelfOnboardingContainerSessionQuery__
 *
 * To run a query within a React component, call `useGetSelfOnboardingContainerSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelfOnboardingContainerSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelfOnboardingContainerSessionQuery({
 *   variables: {
 *      jobsiteWorkersInput: // value for 'jobsiteWorkersInput'
 *      workerDocumentsInput: // value for 'workerDocumentsInput'
 *   },
 * });
 */
export function useGetSelfOnboardingContainerSessionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSelfOnboardingContainerSessionQuery,
    GetSelfOnboardingContainerSessionQueryVariables
  > &
    ({ variables: GetSelfOnboardingContainerSessionQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSelfOnboardingContainerSessionQuery, GetSelfOnboardingContainerSessionQueryVariables>(
    GetSelfOnboardingContainerSessionDocument,
    options,
  );
}
export function useGetSelfOnboardingContainerSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSelfOnboardingContainerSessionQuery,
    GetSelfOnboardingContainerSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSelfOnboardingContainerSessionQuery, GetSelfOnboardingContainerSessionQueryVariables>(
    GetSelfOnboardingContainerSessionDocument,
    options,
  );
}
export function useGetSelfOnboardingContainerSessionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetSelfOnboardingContainerSessionQuery,
    GetSelfOnboardingContainerSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetSelfOnboardingContainerSessionQuery,
    GetSelfOnboardingContainerSessionQueryVariables
  >(GetSelfOnboardingContainerSessionDocument, options);
}
export type GetSelfOnboardingContainerSessionQueryHookResult = ReturnType<
  typeof useGetSelfOnboardingContainerSessionQuery
>;
export type GetSelfOnboardingContainerSessionLazyQueryHookResult = ReturnType<
  typeof useGetSelfOnboardingContainerSessionLazyQuery
>;
export type GetSelfOnboardingContainerSessionSuspenseQueryHookResult = ReturnType<
  typeof useGetSelfOnboardingContainerSessionSuspenseQuery
>;
export type GetSelfOnboardingContainerSessionQueryResult = Apollo.QueryResult<
  GetSelfOnboardingContainerSessionQuery,
  GetSelfOnboardingContainerSessionQueryVariables
>;
export const GetSelfOnboardingCurrentStepDocument = gql`
  query GetSelfOnboardingCurrentStep {
    getCurrentSession {
      user {
        userAccountId
        worker {
          workerId
          selfOnboardingCurrentStep
        }
      }
    }
  }
`;

/**
 * __useGetSelfOnboardingCurrentStepQuery__
 *
 * To run a query within a React component, call `useGetSelfOnboardingCurrentStepQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelfOnboardingCurrentStepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelfOnboardingCurrentStepQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSelfOnboardingCurrentStepQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSelfOnboardingCurrentStepQuery, GetSelfOnboardingCurrentStepQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSelfOnboardingCurrentStepQuery, GetSelfOnboardingCurrentStepQueryVariables>(
    GetSelfOnboardingCurrentStepDocument,
    options,
  );
}
export function useGetSelfOnboardingCurrentStepLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSelfOnboardingCurrentStepQuery,
    GetSelfOnboardingCurrentStepQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSelfOnboardingCurrentStepQuery, GetSelfOnboardingCurrentStepQueryVariables>(
    GetSelfOnboardingCurrentStepDocument,
    options,
  );
}
export function useGetSelfOnboardingCurrentStepSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetSelfOnboardingCurrentStepQuery,
    GetSelfOnboardingCurrentStepQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetSelfOnboardingCurrentStepQuery, GetSelfOnboardingCurrentStepQueryVariables>(
    GetSelfOnboardingCurrentStepDocument,
    options,
  );
}
export type GetSelfOnboardingCurrentStepQueryHookResult = ReturnType<typeof useGetSelfOnboardingCurrentStepQuery>;
export type GetSelfOnboardingCurrentStepLazyQueryHookResult = ReturnType<
  typeof useGetSelfOnboardingCurrentStepLazyQuery
>;
export type GetSelfOnboardingCurrentStepSuspenseQueryHookResult = ReturnType<
  typeof useGetSelfOnboardingCurrentStepSuspenseQuery
>;
export type GetSelfOnboardingCurrentStepQueryResult = Apollo.QueryResult<
  GetSelfOnboardingCurrentStepQuery,
  GetSelfOnboardingCurrentStepQueryVariables
>;
export const UserCancelPendingChangeDocument = gql`
  mutation UserCancelPendingChange($input: UserCancelPendingChangeInput!) {
    userCancelPendingChange(input: $input) {
      success
    }
  }
`;
export type UserCancelPendingChangeMutationFn = Apollo.MutationFunction<
  UserCancelPendingChangeMutation,
  UserCancelPendingChangeMutationVariables
>;

/**
 * __useUserCancelPendingChangeMutation__
 *
 * To run a mutation, you first call `useUserCancelPendingChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserCancelPendingChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userCancelPendingChangeMutation, { data, loading, error }] = useUserCancelPendingChangeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserCancelPendingChangeMutation(
  baseOptions?: Apollo.MutationHookOptions<UserCancelPendingChangeMutation, UserCancelPendingChangeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UserCancelPendingChangeMutation, UserCancelPendingChangeMutationVariables>(
    UserCancelPendingChangeDocument,
    options,
  );
}
export type UserCancelPendingChangeMutationHookResult = ReturnType<typeof useUserCancelPendingChangeMutation>;
export type UserCancelPendingChangeMutationResult = Apollo.MutationResult<UserCancelPendingChangeMutation>;
export type UserCancelPendingChangeMutationOptions = Apollo.BaseMutationOptions<
  UserCancelPendingChangeMutation,
  UserCancelPendingChangeMutationVariables
>;
export const UserConfirmPendingChangeDocument = gql`
  mutation UserConfirmPendingChange($input: UserConfirmPendingChangeInput!) {
    userConfirmPendingChange(input: $input) {
      user {
        userAccountId
      }
    }
  }
`;
export type UserConfirmPendingChangeMutationFn = Apollo.MutationFunction<
  UserConfirmPendingChangeMutation,
  UserConfirmPendingChangeMutationVariables
>;

/**
 * __useUserConfirmPendingChangeMutation__
 *
 * To run a mutation, you first call `useUserConfirmPendingChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserConfirmPendingChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userConfirmPendingChangeMutation, { data, loading, error }] = useUserConfirmPendingChangeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserConfirmPendingChangeMutation(
  baseOptions?: Apollo.MutationHookOptions<UserConfirmPendingChangeMutation, UserConfirmPendingChangeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UserConfirmPendingChangeMutation, UserConfirmPendingChangeMutationVariables>(
    UserConfirmPendingChangeDocument,
    options,
  );
}
export type UserConfirmPendingChangeMutationHookResult = ReturnType<typeof useUserConfirmPendingChangeMutation>;
export type UserConfirmPendingChangeMutationResult = Apollo.MutationResult<UserConfirmPendingChangeMutation>;
export type UserConfirmPendingChangeMutationOptions = Apollo.BaseMutationOptions<
  UserConfirmPendingChangeMutation,
  UserConfirmPendingChangeMutationVariables
>;
export const GetUserPendingChangeDocument = gql`
  query GetUserPendingChange($workerId: ID!, $changeType: UserPendingChangeType!) {
    getUserPendingChange(workerId: $workerId, changeType: $changeType) {
      change {
        currentValue
        newValue
        expiresAt
        submittedBy {
          userAccountId
          identity {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

/**
 * __useGetUserPendingChangeQuery__
 *
 * To run a query within a React component, call `useGetUserPendingChangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPendingChangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPendingChangeQuery({
 *   variables: {
 *      workerId: // value for 'workerId'
 *      changeType: // value for 'changeType'
 *   },
 * });
 */
export function useGetUserPendingChangeQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserPendingChangeQuery, GetUserPendingChangeQueryVariables> &
    ({ variables: GetUserPendingChangeQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserPendingChangeQuery, GetUserPendingChangeQueryVariables>(
    GetUserPendingChangeDocument,
    options,
  );
}
export function useGetUserPendingChangeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserPendingChangeQuery, GetUserPendingChangeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserPendingChangeQuery, GetUserPendingChangeQueryVariables>(
    GetUserPendingChangeDocument,
    options,
  );
}
export function useGetUserPendingChangeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserPendingChangeQuery, GetUserPendingChangeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetUserPendingChangeQuery, GetUserPendingChangeQueryVariables>(
    GetUserPendingChangeDocument,
    options,
  );
}
export type GetUserPendingChangeQueryHookResult = ReturnType<typeof useGetUserPendingChangeQuery>;
export type GetUserPendingChangeLazyQueryHookResult = ReturnType<typeof useGetUserPendingChangeLazyQuery>;
export type GetUserPendingChangeSuspenseQueryHookResult = ReturnType<typeof useGetUserPendingChangeSuspenseQuery>;
export type GetUserPendingChangeQueryResult = Apollo.QueryResult<
  GetUserPendingChangeQuery,
  GetUserPendingChangeQueryVariables
>;
export const UserAssignDocument = gql`
  mutation UserAssign($input: UserAssignInput!) {
    userAssign(input: $input) {
      user {
        userAccountId
      }
    }
  }
`;
export type UserAssignMutationFn = Apollo.MutationFunction<UserAssignMutation, UserAssignMutationVariables>;

/**
 * __useUserAssignMutation__
 *
 * To run a mutation, you first call `useUserAssignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserAssignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userAssignMutation, { data, loading, error }] = useUserAssignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserAssignMutation(
  baseOptions?: Apollo.MutationHookOptions<UserAssignMutation, UserAssignMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UserAssignMutation, UserAssignMutationVariables>(UserAssignDocument, options);
}
export type UserAssignMutationHookResult = ReturnType<typeof useUserAssignMutation>;
export type UserAssignMutationResult = Apollo.MutationResult<UserAssignMutation>;
export type UserAssignMutationOptions = Apollo.BaseMutationOptions<UserAssignMutation, UserAssignMutationVariables>;
export const UserCreateDocument = gql`
  mutation UserCreate($input: UserCreateInput!) {
    userCreate(input: $input) {
      user {
        userAccountId
      }
    }
  }
`;
export type UserCreateMutationFn = Apollo.MutationFunction<UserCreateMutation, UserCreateMutationVariables>;

/**
 * __useUserCreateMutation__
 *
 * To run a mutation, you first call `useUserCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userCreateMutation, { data, loading, error }] = useUserCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<UserCreateMutation, UserCreateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UserCreateMutation, UserCreateMutationVariables>(UserCreateDocument, options);
}
export type UserCreateMutationHookResult = ReturnType<typeof useUserCreateMutation>;
export type UserCreateMutationResult = Apollo.MutationResult<UserCreateMutation>;
export type UserCreateMutationOptions = Apollo.BaseMutationOptions<UserCreateMutation, UserCreateMutationVariables>;
export const UserRemoveAssignmentDocument = gql`
  mutation UserRemoveAssignment($input: UserRemoveAssignmentInput!) {
    userRemoveAssignment(input: $input) {
      user {
        userAccountId
      }
    }
  }
`;
export type UserRemoveAssignmentMutationFn = Apollo.MutationFunction<
  UserRemoveAssignmentMutation,
  UserRemoveAssignmentMutationVariables
>;

/**
 * __useUserRemoveAssignmentMutation__
 *
 * To run a mutation, you first call `useUserRemoveAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserRemoveAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userRemoveAssignmentMutation, { data, loading, error }] = useUserRemoveAssignmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserRemoveAssignmentMutation(
  baseOptions?: Apollo.MutationHookOptions<UserRemoveAssignmentMutation, UserRemoveAssignmentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UserRemoveAssignmentMutation, UserRemoveAssignmentMutationVariables>(
    UserRemoveAssignmentDocument,
    options,
  );
}
export type UserRemoveAssignmentMutationHookResult = ReturnType<typeof useUserRemoveAssignmentMutation>;
export type UserRemoveAssignmentMutationResult = Apollo.MutationResult<UserRemoveAssignmentMutation>;
export type UserRemoveAssignmentMutationOptions = Apollo.BaseMutationOptions<
  UserRemoveAssignmentMutation,
  UserRemoveAssignmentMutationVariables
>;
export const UserUpdateAssignmentDocument = gql`
  mutation UserUpdateAssignment($input: UserUpdateAssignmentInput!) {
    userUpdateAssignment(input: $input) {
      user {
        userAccountId
      }
    }
  }
`;
export type UserUpdateAssignmentMutationFn = Apollo.MutationFunction<
  UserUpdateAssignmentMutation,
  UserUpdateAssignmentMutationVariables
>;

/**
 * __useUserUpdateAssignmentMutation__
 *
 * To run a mutation, you first call `useUserUpdateAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateAssignmentMutation, { data, loading, error }] = useUserUpdateAssignmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserUpdateAssignmentMutation(
  baseOptions?: Apollo.MutationHookOptions<UserUpdateAssignmentMutation, UserUpdateAssignmentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UserUpdateAssignmentMutation, UserUpdateAssignmentMutationVariables>(
    UserUpdateAssignmentDocument,
    options,
  );
}
export type UserUpdateAssignmentMutationHookResult = ReturnType<typeof useUserUpdateAssignmentMutation>;
export type UserUpdateAssignmentMutationResult = Apollo.MutationResult<UserUpdateAssignmentMutation>;
export type UserUpdateAssignmentMutationOptions = Apollo.BaseMutationOptions<
  UserUpdateAssignmentMutation,
  UserUpdateAssignmentMutationVariables
>;
export const GetUserRoleModalContractorsDocument = gql`
  query GetUserRoleModalContractors(
    $workerId: ID!
    $includeArchived: Boolean
    $availableRoleObjectsInput: UserAvailableRoleObjectsInput
  ) {
    getWorker(workerId: $workerId, includeArchived: $includeArchived) {
      user {
        userAccountId
        availableRoleObjects(input: $availableRoleObjectsInput) {
          contractors {
            contractorId
            organization {
              organizationId
              name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetUserRoleModalContractorsQuery__
 *
 * To run a query within a React component, call `useGetUserRoleModalContractorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRoleModalContractorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserRoleModalContractorsQuery({
 *   variables: {
 *      workerId: // value for 'workerId'
 *      includeArchived: // value for 'includeArchived'
 *      availableRoleObjectsInput: // value for 'availableRoleObjectsInput'
 *   },
 * });
 */
export function useGetUserRoleModalContractorsQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserRoleModalContractorsQuery, GetUserRoleModalContractorsQueryVariables> &
    ({ variables: GetUserRoleModalContractorsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserRoleModalContractorsQuery, GetUserRoleModalContractorsQueryVariables>(
    GetUserRoleModalContractorsDocument,
    options,
  );
}
export function useGetUserRoleModalContractorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserRoleModalContractorsQuery,
    GetUserRoleModalContractorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserRoleModalContractorsQuery, GetUserRoleModalContractorsQueryVariables>(
    GetUserRoleModalContractorsDocument,
    options,
  );
}
export function useGetUserRoleModalContractorsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetUserRoleModalContractorsQuery,
    GetUserRoleModalContractorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetUserRoleModalContractorsQuery, GetUserRoleModalContractorsQueryVariables>(
    GetUserRoleModalContractorsDocument,
    options,
  );
}
export type GetUserRoleModalContractorsQueryHookResult = ReturnType<typeof useGetUserRoleModalContractorsQuery>;
export type GetUserRoleModalContractorsLazyQueryHookResult = ReturnType<typeof useGetUserRoleModalContractorsLazyQuery>;
export type GetUserRoleModalContractorsSuspenseQueryHookResult = ReturnType<
  typeof useGetUserRoleModalContractorsSuspenseQuery
>;
export type GetUserRoleModalContractorsQueryResult = Apollo.QueryResult<
  GetUserRoleModalContractorsQuery,
  GetUserRoleModalContractorsQueryVariables
>;
export const GetUserRoleModalJobsitesDocument = gql`
  query GetUserRoleModalJobsites(
    $workerId: ID!
    $includeArchived: Boolean
    $availableRoleObjectsInput: UserAvailableRoleObjectsInput
  ) {
    getWorker(workerId: $workerId, includeArchived: $includeArchived) {
      user {
        userAccountId
        availableRoleObjects(input: $availableRoleObjectsInput) {
          jobsites {
            jobsiteId
            name
          }
        }
      }
    }
  }
`;

/**
 * __useGetUserRoleModalJobsitesQuery__
 *
 * To run a query within a React component, call `useGetUserRoleModalJobsitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRoleModalJobsitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserRoleModalJobsitesQuery({
 *   variables: {
 *      workerId: // value for 'workerId'
 *      includeArchived: // value for 'includeArchived'
 *      availableRoleObjectsInput: // value for 'availableRoleObjectsInput'
 *   },
 * });
 */
export function useGetUserRoleModalJobsitesQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserRoleModalJobsitesQuery, GetUserRoleModalJobsitesQueryVariables> &
    ({ variables: GetUserRoleModalJobsitesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserRoleModalJobsitesQuery, GetUserRoleModalJobsitesQueryVariables>(
    GetUserRoleModalJobsitesDocument,
    options,
  );
}
export function useGetUserRoleModalJobsitesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserRoleModalJobsitesQuery, GetUserRoleModalJobsitesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserRoleModalJobsitesQuery, GetUserRoleModalJobsitesQueryVariables>(
    GetUserRoleModalJobsitesDocument,
    options,
  );
}
export function useGetUserRoleModalJobsitesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserRoleModalJobsitesQuery, GetUserRoleModalJobsitesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetUserRoleModalJobsitesQuery, GetUserRoleModalJobsitesQueryVariables>(
    GetUserRoleModalJobsitesDocument,
    options,
  );
}
export type GetUserRoleModalJobsitesQueryHookResult = ReturnType<typeof useGetUserRoleModalJobsitesQuery>;
export type GetUserRoleModalJobsitesLazyQueryHookResult = ReturnType<typeof useGetUserRoleModalJobsitesLazyQuery>;
export type GetUserRoleModalJobsitesSuspenseQueryHookResult = ReturnType<
  typeof useGetUserRoleModalJobsitesSuspenseQuery
>;
export type GetUserRoleModalJobsitesQueryResult = Apollo.QueryResult<
  GetUserRoleModalJobsitesQuery,
  GetUserRoleModalJobsitesQueryVariables
>;
export const GetUsersDocument = gql`
  query GetUsers($usersInput: UserUsersInput) {
    getCurrentSession {
      user {
        users(input: $usersInput) {
          jobsiteUsersCount
          contractorUsersCount
          developerUsersCount
          edges {
            node {
              userAccountId
              roles {
                key
                displayName
                object {
                  objectId
                  objectDetails {
                    name
                  }
                }
              }
              identity {
                firstName
                lastName
                email
                phoneNumber
              }
              worker {
                workerId
                profilePictureCropped {
                  downloadUrl
                }
              }
              lastSession {
                createdAt
                appVersion
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      usersInput: // value for 'usersInput'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
}
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
}
export function useGetUsersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
}
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersSuspenseQueryHookResult = ReturnType<typeof useGetUsersSuspenseQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const ArchiveJobsiteWorkerAccessEventDocument = gql`
  mutation ArchiveJobsiteWorkerAccessEvent($input: ArchiveJobsiteWorkerAccessEventInput!) {
    archiveJobsiteWorkerAccessEvent(input: $input) {
      success {
        success
      }
      accessEventId
    }
  }
`;
export type ArchiveJobsiteWorkerAccessEventMutationFn = Apollo.MutationFunction<
  ArchiveJobsiteWorkerAccessEventMutation,
  ArchiveJobsiteWorkerAccessEventMutationVariables
>;

/**
 * __useArchiveJobsiteWorkerAccessEventMutation__
 *
 * To run a mutation, you first call `useArchiveJobsiteWorkerAccessEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveJobsiteWorkerAccessEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveJobsiteWorkerAccessEventMutation, { data, loading, error }] = useArchiveJobsiteWorkerAccessEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveJobsiteWorkerAccessEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveJobsiteWorkerAccessEventMutation,
    ArchiveJobsiteWorkerAccessEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveJobsiteWorkerAccessEventMutation, ArchiveJobsiteWorkerAccessEventMutationVariables>(
    ArchiveJobsiteWorkerAccessEventDocument,
    options,
  );
}
export type ArchiveJobsiteWorkerAccessEventMutationHookResult = ReturnType<
  typeof useArchiveJobsiteWorkerAccessEventMutation
>;
export type ArchiveJobsiteWorkerAccessEventMutationResult =
  Apollo.MutationResult<ArchiveJobsiteWorkerAccessEventMutation>;
export type ArchiveJobsiteWorkerAccessEventMutationOptions = Apollo.BaseMutationOptions<
  ArchiveJobsiteWorkerAccessEventMutation,
  ArchiveJobsiteWorkerAccessEventMutationVariables
>;
export const ChangeContractorForJobsiteWorkersDocument = gql`
  mutation ChangeContractorForJobsiteWorkers($input: ChangeContractorForJobsiteWorkersInput) {
    changeContractorForJobsiteWorkers(input: $input) {
      changeContractorForJobsiteWorkersSuccess {
        jobsiteWorker {
          jobsiteWorkerId
          startDate
          contractorWorker {
            worker {
              workerId
              user {
                identity {
                  firstName
                  lastName
                }
              }
            }
          }
          jobsiteContractor {
            jobsite {
              name
            }
          }
        }
      }
      changeContractorForJobsiteWorkersError {
        errorMessage
      }
    }
  }
`;
export type ChangeContractorForJobsiteWorkersMutationFn = Apollo.MutationFunction<
  ChangeContractorForJobsiteWorkersMutation,
  ChangeContractorForJobsiteWorkersMutationVariables
>;

/**
 * __useChangeContractorForJobsiteWorkersMutation__
 *
 * To run a mutation, you first call `useChangeContractorForJobsiteWorkersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeContractorForJobsiteWorkersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeContractorForJobsiteWorkersMutation, { data, loading, error }] = useChangeContractorForJobsiteWorkersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeContractorForJobsiteWorkersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeContractorForJobsiteWorkersMutation,
    ChangeContractorForJobsiteWorkersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangeContractorForJobsiteWorkersMutation,
    ChangeContractorForJobsiteWorkersMutationVariables
  >(ChangeContractorForJobsiteWorkersDocument, options);
}
export type ChangeContractorForJobsiteWorkersMutationHookResult = ReturnType<
  typeof useChangeContractorForJobsiteWorkersMutation
>;
export type ChangeContractorForJobsiteWorkersMutationResult =
  Apollo.MutationResult<ChangeContractorForJobsiteWorkersMutation>;
export type ChangeContractorForJobsiteWorkersMutationOptions = Apollo.BaseMutationOptions<
  ChangeContractorForJobsiteWorkersMutation,
  ChangeContractorForJobsiteWorkersMutationVariables
>;
export const ChangeJobsiteWorkersJobsiteAssignmentDocument = gql`
  mutation ChangeJobsiteWorkersJobsiteAssignment($input: ChangeJobsiteWorkersJobsiteAssignmentInput!) {
    changeJobsiteWorkersJobsiteAssignment(input: $input) {
      totalCount
      edges {
        jobsiteWorkerId
        jobsiteContractor {
          jobsite {
            name
          }
        }
      }
    }
  }
`;
export type ChangeJobsiteWorkersJobsiteAssignmentMutationFn = Apollo.MutationFunction<
  ChangeJobsiteWorkersJobsiteAssignmentMutation,
  ChangeJobsiteWorkersJobsiteAssignmentMutationVariables
>;

/**
 * __useChangeJobsiteWorkersJobsiteAssignmentMutation__
 *
 * To run a mutation, you first call `useChangeJobsiteWorkersJobsiteAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeJobsiteWorkersJobsiteAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeJobsiteWorkersJobsiteAssignmentMutation, { data, loading, error }] = useChangeJobsiteWorkersJobsiteAssignmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeJobsiteWorkersJobsiteAssignmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeJobsiteWorkersJobsiteAssignmentMutation,
    ChangeJobsiteWorkersJobsiteAssignmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangeJobsiteWorkersJobsiteAssignmentMutation,
    ChangeJobsiteWorkersJobsiteAssignmentMutationVariables
  >(ChangeJobsiteWorkersJobsiteAssignmentDocument, options);
}
export type ChangeJobsiteWorkersJobsiteAssignmentMutationHookResult = ReturnType<
  typeof useChangeJobsiteWorkersJobsiteAssignmentMutation
>;
export type ChangeJobsiteWorkersJobsiteAssignmentMutationResult =
  Apollo.MutationResult<ChangeJobsiteWorkersJobsiteAssignmentMutation>;
export type ChangeJobsiteWorkersJobsiteAssignmentMutationOptions = Apollo.BaseMutationOptions<
  ChangeJobsiteWorkersJobsiteAssignmentMutation,
  ChangeJobsiteWorkersJobsiteAssignmentMutationVariables
>;
export const CheckInJobsiteWorkersDocument = gql`
  mutation CheckInJobsiteWorkers($input: CheckInJobsiteWorkersInput!) {
    checkInJobsiteWorkers(input: $input) {
      totalCount
      edges {
        checkInDateTime
        area {
          id
          name
        }
        jobsiteWorkerCheckInId
        jobsiteWorker {
          jobsiteWorkerId
          jobsiteContractor {
            jobsite {
              name
              jobsiteId
            }
          }
          contractorWorker {
            worker {
              workerId
            }
          }
        }
      }
    }
  }
`;
export type CheckInJobsiteWorkersMutationFn = Apollo.MutationFunction<
  CheckInJobsiteWorkersMutation,
  CheckInJobsiteWorkersMutationVariables
>;

/**
 * __useCheckInJobsiteWorkersMutation__
 *
 * To run a mutation, you first call `useCheckInJobsiteWorkersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckInJobsiteWorkersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkInJobsiteWorkersMutation, { data, loading, error }] = useCheckInJobsiteWorkersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckInJobsiteWorkersMutation(
  baseOptions?: Apollo.MutationHookOptions<CheckInJobsiteWorkersMutation, CheckInJobsiteWorkersMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CheckInJobsiteWorkersMutation, CheckInJobsiteWorkersMutationVariables>(
    CheckInJobsiteWorkersDocument,
    options,
  );
}
export type CheckInJobsiteWorkersMutationHookResult = ReturnType<typeof useCheckInJobsiteWorkersMutation>;
export type CheckInJobsiteWorkersMutationResult = Apollo.MutationResult<CheckInJobsiteWorkersMutation>;
export type CheckInJobsiteWorkersMutationOptions = Apollo.BaseMutationOptions<
  CheckInJobsiteWorkersMutation,
  CheckInJobsiteWorkersMutationVariables
>;
export const CompleteSelfOnBoardingDocument = gql`
  mutation CompleteSelfOnBoarding($jobsiteWorkerId: ID!) {
    completeSelfOnBoarding(jobsiteWorkerId: $jobsiteWorkerId) {
      jobsiteWorkerId
      orientationStatus
      orientationDate
      contractorWorker {
        worker {
          workerId
          selfOnboardingCurrentStep
        }
      }
    }
  }
`;
export type CompleteSelfOnBoardingMutationFn = Apollo.MutationFunction<
  CompleteSelfOnBoardingMutation,
  CompleteSelfOnBoardingMutationVariables
>;

/**
 * __useCompleteSelfOnBoardingMutation__
 *
 * To run a mutation, you first call `useCompleteSelfOnBoardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteSelfOnBoardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeSelfOnBoardingMutation, { data, loading, error }] = useCompleteSelfOnBoardingMutation({
 *   variables: {
 *      jobsiteWorkerId: // value for 'jobsiteWorkerId'
 *   },
 * });
 */
export function useCompleteSelfOnBoardingMutation(
  baseOptions?: Apollo.MutationHookOptions<CompleteSelfOnBoardingMutation, CompleteSelfOnBoardingMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CompleteSelfOnBoardingMutation, CompleteSelfOnBoardingMutationVariables>(
    CompleteSelfOnBoardingDocument,
    options,
  );
}
export type CompleteSelfOnBoardingMutationHookResult = ReturnType<typeof useCompleteSelfOnBoardingMutation>;
export type CompleteSelfOnBoardingMutationResult = Apollo.MutationResult<CompleteSelfOnBoardingMutation>;
export type CompleteSelfOnBoardingMutationOptions = Apollo.BaseMutationOptions<
  CompleteSelfOnBoardingMutation,
  CompleteSelfOnBoardingMutationVariables
>;
export const EnableJobsiteWorkerSelfOnboardingDocument = gql`
  mutation EnableJobsiteWorkerSelfOnboarding($jobsiteWorkerId: ID!) {
    enableJobsiteWorkerSelfOnboarding(jobsiteWorkerId: $jobsiteWorkerId) {
      jobsiteWorkerId
      orientationStatus
    }
  }
`;
export type EnableJobsiteWorkerSelfOnboardingMutationFn = Apollo.MutationFunction<
  EnableJobsiteWorkerSelfOnboardingMutation,
  EnableJobsiteWorkerSelfOnboardingMutationVariables
>;

/**
 * __useEnableJobsiteWorkerSelfOnboardingMutation__
 *
 * To run a mutation, you first call `useEnableJobsiteWorkerSelfOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableJobsiteWorkerSelfOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableJobsiteWorkerSelfOnboardingMutation, { data, loading, error }] = useEnableJobsiteWorkerSelfOnboardingMutation({
 *   variables: {
 *      jobsiteWorkerId: // value for 'jobsiteWorkerId'
 *   },
 * });
 */
export function useEnableJobsiteWorkerSelfOnboardingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EnableJobsiteWorkerSelfOnboardingMutation,
    EnableJobsiteWorkerSelfOnboardingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EnableJobsiteWorkerSelfOnboardingMutation,
    EnableJobsiteWorkerSelfOnboardingMutationVariables
  >(EnableJobsiteWorkerSelfOnboardingDocument, options);
}
export type EnableJobsiteWorkerSelfOnboardingMutationHookResult = ReturnType<
  typeof useEnableJobsiteWorkerSelfOnboardingMutation
>;
export type EnableJobsiteWorkerSelfOnboardingMutationResult =
  Apollo.MutationResult<EnableJobsiteWorkerSelfOnboardingMutation>;
export type EnableJobsiteWorkerSelfOnboardingMutationOptions = Apollo.BaseMutationOptions<
  EnableJobsiteWorkerSelfOnboardingMutation,
  EnableJobsiteWorkerSelfOnboardingMutationVariables
>;
export const ManuallyEnterCardNumberForPrintJobDocument = gql`
  mutation ManuallyEnterCardNumberForPrintJob(
    $cardPrintJobId: ID!
    $cardNumber: String!
    $workerCardFormatId: ID!
    $forceCreate: Boolean
  ) {
    manuallyEnterCardNumberForPrintJob(
      cardPrintJobId: $cardPrintJobId
      cardNumber: $cardNumber
      workerCardFormatId: $workerCardFormatId
      forceCreate: $forceCreate
    ) {
      workerCardId
      cardNumber
      workerCardFormat {
        workerCardFormatId
        name
      }
    }
  }
`;
export type ManuallyEnterCardNumberForPrintJobMutationFn = Apollo.MutationFunction<
  ManuallyEnterCardNumberForPrintJobMutation,
  ManuallyEnterCardNumberForPrintJobMutationVariables
>;

/**
 * __useManuallyEnterCardNumberForPrintJobMutation__
 *
 * To run a mutation, you first call `useManuallyEnterCardNumberForPrintJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManuallyEnterCardNumberForPrintJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manuallyEnterCardNumberForPrintJobMutation, { data, loading, error }] = useManuallyEnterCardNumberForPrintJobMutation({
 *   variables: {
 *      cardPrintJobId: // value for 'cardPrintJobId'
 *      cardNumber: // value for 'cardNumber'
 *      workerCardFormatId: // value for 'workerCardFormatId'
 *      forceCreate: // value for 'forceCreate'
 *   },
 * });
 */
export function useManuallyEnterCardNumberForPrintJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ManuallyEnterCardNumberForPrintJobMutation,
    ManuallyEnterCardNumberForPrintJobMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ManuallyEnterCardNumberForPrintJobMutation,
    ManuallyEnterCardNumberForPrintJobMutationVariables
  >(ManuallyEnterCardNumberForPrintJobDocument, options);
}
export type ManuallyEnterCardNumberForPrintJobMutationHookResult = ReturnType<
  typeof useManuallyEnterCardNumberForPrintJobMutation
>;
export type ManuallyEnterCardNumberForPrintJobMutationResult =
  Apollo.MutationResult<ManuallyEnterCardNumberForPrintJobMutation>;
export type ManuallyEnterCardNumberForPrintJobMutationOptions = Apollo.BaseMutationOptions<
  ManuallyEnterCardNumberForPrintJobMutation,
  ManuallyEnterCardNumberForPrintJobMutationVariables
>;
export const ReactivateWorkerCardDocument = gql`
  mutation ReactivateWorkerCard($workerCardIds: [String!]!, $forceCreate: Boolean) {
    reactivateWorkerCard(workerCardIds: $workerCardIds, forceCreate: $forceCreate) {
      success
    }
  }
`;
export type ReactivateWorkerCardMutationFn = Apollo.MutationFunction<
  ReactivateWorkerCardMutation,
  ReactivateWorkerCardMutationVariables
>;

/**
 * __useReactivateWorkerCardMutation__
 *
 * To run a mutation, you first call `useReactivateWorkerCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateWorkerCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateWorkerCardMutation, { data, loading, error }] = useReactivateWorkerCardMutation({
 *   variables: {
 *      workerCardIds: // value for 'workerCardIds'
 *      forceCreate: // value for 'forceCreate'
 *   },
 * });
 */
export function useReactivateWorkerCardMutation(
  baseOptions?: Apollo.MutationHookOptions<ReactivateWorkerCardMutation, ReactivateWorkerCardMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ReactivateWorkerCardMutation, ReactivateWorkerCardMutationVariables>(
    ReactivateWorkerCardDocument,
    options,
  );
}
export type ReactivateWorkerCardMutationHookResult = ReturnType<typeof useReactivateWorkerCardMutation>;
export type ReactivateWorkerCardMutationResult = Apollo.MutationResult<ReactivateWorkerCardMutation>;
export type ReactivateWorkerCardMutationOptions = Apollo.BaseMutationOptions<
  ReactivateWorkerCardMutation,
  ReactivateWorkerCardMutationVariables
>;
export const RemoveWorkerFromJobsiteDocument = gql`
  mutation RemoveWorkerFromJobsite($input: RemoveWorkerFromJobsiteInput!) {
    removeWorkerFromJobsite(input: $input) {
      success {
        success
      }
    }
  }
`;
export type RemoveWorkerFromJobsiteMutationFn = Apollo.MutationFunction<
  RemoveWorkerFromJobsiteMutation,
  RemoveWorkerFromJobsiteMutationVariables
>;

/**
 * __useRemoveWorkerFromJobsiteMutation__
 *
 * To run a mutation, you first call `useRemoveWorkerFromJobsiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveWorkerFromJobsiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeWorkerFromJobsiteMutation, { data, loading, error }] = useRemoveWorkerFromJobsiteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveWorkerFromJobsiteMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveWorkerFromJobsiteMutation, RemoveWorkerFromJobsiteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveWorkerFromJobsiteMutation, RemoveWorkerFromJobsiteMutationVariables>(
    RemoveWorkerFromJobsiteDocument,
    options,
  );
}
export type RemoveWorkerFromJobsiteMutationHookResult = ReturnType<typeof useRemoveWorkerFromJobsiteMutation>;
export type RemoveWorkerFromJobsiteMutationResult = Apollo.MutationResult<RemoveWorkerFromJobsiteMutation>;
export type RemoveWorkerFromJobsiteMutationOptions = Apollo.BaseMutationOptions<
  RemoveWorkerFromJobsiteMutation,
  RemoveWorkerFromJobsiteMutationVariables
>;
export const UpdateJobsiteWorkerDocument = gql`
  mutation UpdateJobsiteWorker($jobsiteWorkerId: ID!, $jobsiteWorkerInput: JobsiteWorkerInput!, $skipACSSync: Boolean) {
    updateJobsiteWorker(
      jobsiteWorkerId: $jobsiteWorkerId
      jobsiteWorkerInput: $jobsiteWorkerInput
      skipACSSync: $skipACSSync
    ) {
      orientationDate
    }
  }
`;
export type UpdateJobsiteWorkerMutationFn = Apollo.MutationFunction<
  UpdateJobsiteWorkerMutation,
  UpdateJobsiteWorkerMutationVariables
>;

/**
 * __useUpdateJobsiteWorkerMutation__
 *
 * To run a mutation, you first call `useUpdateJobsiteWorkerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobsiteWorkerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobsiteWorkerMutation, { data, loading, error }] = useUpdateJobsiteWorkerMutation({
 *   variables: {
 *      jobsiteWorkerId: // value for 'jobsiteWorkerId'
 *      jobsiteWorkerInput: // value for 'jobsiteWorkerInput'
 *      skipACSSync: // value for 'skipACSSync'
 *   },
 * });
 */
export function useUpdateJobsiteWorkerMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateJobsiteWorkerMutation, UpdateJobsiteWorkerMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateJobsiteWorkerMutation, UpdateJobsiteWorkerMutationVariables>(
    UpdateJobsiteWorkerDocument,
    options,
  );
}
export type UpdateJobsiteWorkerMutationHookResult = ReturnType<typeof useUpdateJobsiteWorkerMutation>;
export type UpdateJobsiteWorkerMutationResult = Apollo.MutationResult<UpdateJobsiteWorkerMutation>;
export type UpdateJobsiteWorkerMutationOptions = Apollo.BaseMutationOptions<
  UpdateJobsiteWorkerMutation,
  UpdateJobsiteWorkerMutationVariables
>;
export const UpdateWorkerDocument = gql`
  mutation UpdateWorker($workerId: ID!, $workerInput: WorkerInput!) {
    updateWorker(workerId: $workerId, workerInput: $workerInput) {
      birthDate
      ssnLastFour
      workerId
      trade
      jobTitle
      profilePictureCropped {
        downloadUrl
      }
      user {
        userAccountId
        createdAt
        identity {
          firstName
          lastName
          createdAt
          updatedAt
        }
        roles {
          key
          displayName
          roleCreatedAt
          roleUpdatedAt
          mappingCreatedAt
        }
      }
    }
  }
`;
export type UpdateWorkerMutationFn = Apollo.MutationFunction<UpdateWorkerMutation, UpdateWorkerMutationVariables>;

/**
 * __useUpdateWorkerMutation__
 *
 * To run a mutation, you first call `useUpdateWorkerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkerMutation, { data, loading, error }] = useUpdateWorkerMutation({
 *   variables: {
 *      workerId: // value for 'workerId'
 *      workerInput: // value for 'workerInput'
 *   },
 * });
 */
export function useUpdateWorkerMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateWorkerMutation, UpdateWorkerMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateWorkerMutation, UpdateWorkerMutationVariables>(UpdateWorkerDocument, options);
}
export type UpdateWorkerMutationHookResult = ReturnType<typeof useUpdateWorkerMutation>;
export type UpdateWorkerMutationResult = Apollo.MutationResult<UpdateWorkerMutation>;
export type UpdateWorkerMutationOptions = Apollo.BaseMutationOptions<
  UpdateWorkerMutation,
  UpdateWorkerMutationVariables
>;
export const UserUpdateIdentityDocument = gql`
  mutation UserUpdateIdentity($input: UserUpdateIdentityInput!) {
    userUpdateIdentity(input: $input) {
      user {
        identity {
          firstName
          lastName
          email
        }
      }
    }
  }
`;
export type UserUpdateIdentityMutationFn = Apollo.MutationFunction<
  UserUpdateIdentityMutation,
  UserUpdateIdentityMutationVariables
>;

/**
 * __useUserUpdateIdentityMutation__
 *
 * To run a mutation, you first call `useUserUpdateIdentityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateIdentityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateIdentityMutation, { data, loading, error }] = useUserUpdateIdentityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserUpdateIdentityMutation(
  baseOptions?: Apollo.MutationHookOptions<UserUpdateIdentityMutation, UserUpdateIdentityMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UserUpdateIdentityMutation, UserUpdateIdentityMutationVariables>(
    UserUpdateIdentityDocument,
    options,
  );
}
export type UserUpdateIdentityMutationHookResult = ReturnType<typeof useUserUpdateIdentityMutation>;
export type UserUpdateIdentityMutationResult = Apollo.MutationResult<UserUpdateIdentityMutation>;
export type UserUpdateIdentityMutationOptions = Apollo.BaseMutationOptions<
  UserUpdateIdentityMutation,
  UserUpdateIdentityMutationVariables
>;
export const WorkerUnlockProfileDocument = gql`
  mutation WorkerUnlockProfile($input: WorkerUnlockProfileInput!) {
    workerUnlockProfile(input: $input) {
      userWorkerProfileAccess {
        id
        isUnlocked
      }
    }
  }
`;
export type WorkerUnlockProfileMutationFn = Apollo.MutationFunction<
  WorkerUnlockProfileMutation,
  WorkerUnlockProfileMutationVariables
>;

/**
 * __useWorkerUnlockProfileMutation__
 *
 * To run a mutation, you first call `useWorkerUnlockProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWorkerUnlockProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [workerUnlockProfileMutation, { data, loading, error }] = useWorkerUnlockProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWorkerUnlockProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<WorkerUnlockProfileMutation, WorkerUnlockProfileMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<WorkerUnlockProfileMutation, WorkerUnlockProfileMutationVariables>(
    WorkerUnlockProfileDocument,
    options,
  );
}
export type WorkerUnlockProfileMutationHookResult = ReturnType<typeof useWorkerUnlockProfileMutation>;
export type WorkerUnlockProfileMutationResult = Apollo.MutationResult<WorkerUnlockProfileMutation>;
export type WorkerUnlockProfileMutationOptions = Apollo.BaseMutationOptions<
  WorkerUnlockProfileMutation,
  WorkerUnlockProfileMutationVariables
>;
export const ExtractIdDataFromFileDocument = gql`
  mutation ExtractIdDataFromFile($extractionInput: DataExtractionInput!) {
    extractIdDataFromFile(extractionInput: $extractionInput) {
      file {
        fileId
      }
      extractedData {
        identifier
        idType
        issueDate
        expirationDate
        dateOfBirth
        fullName
        firstName
        lastName
        address
        title
      }
    }
  }
`;
export type ExtractIdDataFromFileMutationFn = Apollo.MutationFunction<
  ExtractIdDataFromFileMutation,
  ExtractIdDataFromFileMutationVariables
>;

/**
 * __useExtractIdDataFromFileMutation__
 *
 * To run a mutation, you first call `useExtractIdDataFromFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExtractIdDataFromFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [extractIdDataFromFileMutation, { data, loading, error }] = useExtractIdDataFromFileMutation({
 *   variables: {
 *      extractionInput: // value for 'extractionInput'
 *   },
 * });
 */
export function useExtractIdDataFromFileMutation(
  baseOptions?: Apollo.MutationHookOptions<ExtractIdDataFromFileMutation, ExtractIdDataFromFileMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ExtractIdDataFromFileMutation, ExtractIdDataFromFileMutationVariables>(
    ExtractIdDataFromFileDocument,
    options,
  );
}
export type ExtractIdDataFromFileMutationHookResult = ReturnType<typeof useExtractIdDataFromFileMutation>;
export type ExtractIdDataFromFileMutationResult = Apollo.MutationResult<ExtractIdDataFromFileMutation>;
export type ExtractIdDataFromFileMutationOptions = Apollo.BaseMutationOptions<
  ExtractIdDataFromFileMutation,
  ExtractIdDataFromFileMutationVariables
>;
export const GetJobsiteAccessSessionsDocument = gql`
  query GetJobsiteAccessSessions($userJobsiteAccessSessionsInput: UserJobsiteAccessSessionsInput) {
    getCurrentSession {
      user {
        userAccountId
        jobsiteAccessSessions(input: $userJobsiteAccessSessionsInput) {
          count
          edges {
            node {
              sessionId
              jobsiteWorker {
                contractorWorker {
                  worker {
                    workerId
                    user {
                      userAccountId
                      identity {
                        firstName
                        lastName
                      }
                    }
                    profilePictureCropped {
                      downloadUrl
                    }
                  }
                }
                jobsiteContractor {
                  id
                  jobsite {
                    jobsiteId
                    name
                  }
                }
              }
              proximitySession {
                id
                day
                start
                end
                active
                compliant
                closed
                locale {
                  time {
                    start
                    end
                  }
                }
                span {
                  hours
                }
                reads {
                  count
                  counts {
                    inbound
                    outbound
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetJobsiteAccessSessionsQuery__
 *
 * To run a query within a React component, call `useGetJobsiteAccessSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobsiteAccessSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobsiteAccessSessionsQuery({
 *   variables: {
 *      userJobsiteAccessSessionsInput: // value for 'userJobsiteAccessSessionsInput'
 *   },
 * });
 */
export function useGetJobsiteAccessSessionsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetJobsiteAccessSessionsQuery, GetJobsiteAccessSessionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetJobsiteAccessSessionsQuery, GetJobsiteAccessSessionsQueryVariables>(
    GetJobsiteAccessSessionsDocument,
    options,
  );
}
export function useGetJobsiteAccessSessionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetJobsiteAccessSessionsQuery, GetJobsiteAccessSessionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetJobsiteAccessSessionsQuery, GetJobsiteAccessSessionsQueryVariables>(
    GetJobsiteAccessSessionsDocument,
    options,
  );
}
export function useGetJobsiteAccessSessionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetJobsiteAccessSessionsQuery, GetJobsiteAccessSessionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetJobsiteAccessSessionsQuery, GetJobsiteAccessSessionsQueryVariables>(
    GetJobsiteAccessSessionsDocument,
    options,
  );
}
export type GetJobsiteAccessSessionsQueryHookResult = ReturnType<typeof useGetJobsiteAccessSessionsQuery>;
export type GetJobsiteAccessSessionsLazyQueryHookResult = ReturnType<typeof useGetJobsiteAccessSessionsLazyQuery>;
export type GetJobsiteAccessSessionsSuspenseQueryHookResult = ReturnType<
  typeof useGetJobsiteAccessSessionsSuspenseQuery
>;
export type GetJobsiteAccessSessionsQueryResult = Apollo.QueryResult<
  GetJobsiteAccessSessionsQuery,
  GetJobsiteAccessSessionsQueryVariables
>;
export const GetWorkerCCureDataDocument = gql`
  query GetWorkerCCureData($workerId: ID!) {
    getWorkerCCureData(workerId: $workerId) {
      data
    }
  }
`;

/**
 * __useGetWorkerCCureDataQuery__
 *
 * To run a query within a React component, call `useGetWorkerCCureDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkerCCureDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkerCCureDataQuery({
 *   variables: {
 *      workerId: // value for 'workerId'
 *   },
 * });
 */
export function useGetWorkerCCureDataQuery(
  baseOptions: Apollo.QueryHookOptions<GetWorkerCCureDataQuery, GetWorkerCCureDataQueryVariables> &
    ({ variables: GetWorkerCCureDataQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWorkerCCureDataQuery, GetWorkerCCureDataQueryVariables>(
    GetWorkerCCureDataDocument,
    options,
  );
}
export function useGetWorkerCCureDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWorkerCCureDataQuery, GetWorkerCCureDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWorkerCCureDataQuery, GetWorkerCCureDataQueryVariables>(
    GetWorkerCCureDataDocument,
    options,
  );
}
export function useGetWorkerCCureDataSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetWorkerCCureDataQuery, GetWorkerCCureDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetWorkerCCureDataQuery, GetWorkerCCureDataQueryVariables>(
    GetWorkerCCureDataDocument,
    options,
  );
}
export type GetWorkerCCureDataQueryHookResult = ReturnType<typeof useGetWorkerCCureDataQuery>;
export type GetWorkerCCureDataLazyQueryHookResult = ReturnType<typeof useGetWorkerCCureDataLazyQuery>;
export type GetWorkerCCureDataSuspenseQueryHookResult = ReturnType<typeof useGetWorkerCCureDataSuspenseQuery>;
export type GetWorkerCCureDataQueryResult = Apollo.QueryResult<
  GetWorkerCCureDataQuery,
  GetWorkerCCureDataQueryVariables
>;
export const GetWorkerDataDocument = gql`
  query GetWorkerData(
    $workerId: ID!
    $includeArchivedWorker: Boolean
    $jobsiteWorkersInput: WorkerJobsiteWorkersInput
  ) {
    getWorker(workerId: $workerId, includeArchived: $includeArchivedWorker) {
      workerId
      middleInitial
      suffix
      addressLine1
      addressLine2
      addressCity
      addressState
      addressZipCode
      addressZipCode
      birthDate
      emergencyContactName
      emergencyContactPhone
      emergencyContactRelationship
      gender
      jobTitle
      primaryLanguage
      race
      isSkilled
      ssnLastFour
      trade
      unionAffiliation
      citizenshipStatus
      isVeteran
      quickCode
      selfOnboardingCurrentStep
      createdMethod
      isArchived
      objectHistory {
        createdAt
        createdBy {
          userAccountId
          identity {
            firstName
            lastName
            email
          }
        }
        updatedAt
        updatedBy {
          userAccountId
          identity {
            firstName
            lastName
            email
          }
        }
      }
      accessHistory {
        changeCategory
        changeReason
        changeApprovedBy
        changeDate
        changeToAccess
        updatedBy
        banEndDate
        jobsiteName
      }
      profilePicture {
        downloadUrl
        uploadedAt
      }
      profilePictureCropped {
        downloadUrl
        uploadedAt
        fileId
        fileType
      }
      user {
        userAccountId
        isMobileUser
        identity {
          firstName
          lastName
          email
          phoneNumber
          fusionAuthId
          pendingChanges {
            phoneNumber {
              newValue
              submittedBy {
                userAccountId
              }
              expiresAt
            }
            email {
              newValue
              submittedBy {
                userAccountId
              }
              expiresAt
            }
          }
        }
        lastSession {
          createdAt
          appVersion
        }
      }
      profileAccess {
        id
        isUnlocked
      }
      editableFields {
        email
        phone
      }
      workerComments {
        edges {
          node {
            workerCommentId
            comment
            jobsite {
              name
              jobsiteId
            }
            objectHistory {
              createdAt
              createdBy {
                userAccountId
                identity {
                  firstName
                  lastName
                  email
                }
              }
              updatedAt
              updatedBy {
                userAccountId
                identity {
                  firstName
                  lastName
                  email
                }
              }
            }
          }
        }
      }
      jobsiteWorkers(input: $jobsiteWorkersInput) {
        edges {
          node {
            jobsiteWorkerId
            isBanned
            banCategory
            bannedReason
            bannedAt
            banRemovedReason
            startDate
            endDate
            payrollId
            siteSpecificTrainingCompletedAt
            siteSpecificTrainingSkipReason
            siteSpecificTrainingSkipExpiresAt
            stickerNumber
            orientationDate
            orientationStatus
            documentsCompletedAt
            documentsCompletedSkipExpiresAt
            documentsCompletedSkipReason
            urineTestConditionalPassExpiresAt
            banExpiresAt
            badgingCompletedAt
            medicalNotes
            nycSstExemptionReason
            siteAccessChangeApprovedBy
            isExemptFromSwipeInactivity
            isExemptFromSiteSpecificRefresher
            passedMedicalExamAt
            medicalExamSkipReason
            medicalExamSkipExpiresAt
            isAntipassbackExempt
            documentExemptionReason
            clearanceAssignments {
              count
              edges {
                node {
                  id
                  clearance {
                    cCureClearanceId
                    name
                    cCureEnvironment {
                      cCureEnvironmentId
                      namespace
                      instanceSlug
                    }
                  }
                }
              }
            }
            objectHistory {
              createdAt
              createdBy {
                userAccountId
                identity {
                  firstName
                  lastName
                  email
                }
              }
              updatedAt
              updatedBy {
                userAccountId
                identity {
                  firstName
                  lastName
                  email
                }
              }
            }
            jobsiteContractor {
              jobsite {
                name
                jobsiteId
                timeZone
                parentId
                clearanceAssignmentEnabled
                developer {
                  developerId
                  name
                }
                modules {
                  ... on JobsiteFeaturesModule {
                    announcementsEnabled
                    archivingAccessEventsEnabled
                    isEnabled
                    name
                    slug
                  }
                  ... on JobsiteAppCheckInModule {
                    areas {
                      checkInCoordinate {
                        lat
                        lng
                      }
                      checkInRadius
                      id
                      isInboundEnabled
                      isOutboundEnabled
                    }
                    isEnabled
                    name
                    slug
                  }
                  ... on JobsiteOnboardingModule {
                    name
                    badgePrintingAllowed
                    badgeAssignmentAllowed
                  }
                }
              }
              contractor {
                contractorId
                organization {
                  organizationId
                  name
                }
              }
            }
            currentAccess {
              isAllowed
              notAllowedReason
              endDate
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetWorkerDataQuery__
 *
 * To run a query within a React component, call `useGetWorkerDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkerDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkerDataQuery({
 *   variables: {
 *      workerId: // value for 'workerId'
 *      includeArchivedWorker: // value for 'includeArchivedWorker'
 *      jobsiteWorkersInput: // value for 'jobsiteWorkersInput'
 *   },
 * });
 */
export function useGetWorkerDataQuery(
  baseOptions: Apollo.QueryHookOptions<GetWorkerDataQuery, GetWorkerDataQueryVariables> &
    ({ variables: GetWorkerDataQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWorkerDataQuery, GetWorkerDataQueryVariables>(GetWorkerDataDocument, options);
}
export function useGetWorkerDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWorkerDataQuery, GetWorkerDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWorkerDataQuery, GetWorkerDataQueryVariables>(GetWorkerDataDocument, options);
}
export function useGetWorkerDataSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetWorkerDataQuery, GetWorkerDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetWorkerDataQuery, GetWorkerDataQueryVariables>(GetWorkerDataDocument, options);
}
export type GetWorkerDataQueryHookResult = ReturnType<typeof useGetWorkerDataQuery>;
export type GetWorkerDataLazyQueryHookResult = ReturnType<typeof useGetWorkerDataLazyQuery>;
export type GetWorkerDataSuspenseQueryHookResult = ReturnType<typeof useGetWorkerDataSuspenseQuery>;
export type GetWorkerDataQueryResult = Apollo.QueryResult<GetWorkerDataQuery, GetWorkerDataQueryVariables>;
export const GetWorkerJobsiteAccessActivityDocument = gql`
  query GetWorkerJobsiteAccessActivity(
    $workerId: ID!
    $includeArchived: Boolean
    $workerJobsiteAccessEventsInput: WorkerJobsiteAccessEventsInput
  ) {
    getWorker(workerId: $workerId, includeArchived: $includeArchived) {
      quickCode
      jobsiteAccessEvents(input: $workerJobsiteAccessEventsInput) {
        count
        edges {
          node {
            id
            type
            status
            credentialId
            eventOccurredAt
            localizedDate
            localizedTime
            sessionDate
            timeZone
            rejectionReason
            message
            gateway
            overnightSession
            jobsite {
              jobsiteId
              name
            }
            jobsiteWorker {
              jobsiteWorkerId
              jobsiteContractor {
                id
              }
              contractorWorker {
                id
                contractor {
                  contractorId
                  organization {
                    organizationId
                    name
                  }
                }
              }
              currentAccess {
                isAllowed
              }
            }
            direction
            externalId
            eventType
            locationStatus
            credentialId
          }
        }
        filtersOptions {
          jobsites {
            value
            label
            count
          }
          contractors {
            value
            label
            count
          }
          gateways {
            value
            label
            count
          }
          rejectionReasons {
            value
            label
            count
          }
          locationStatuses {
            value
            label
            count
          }
        }
      }
    }
  }
`;

/**
 * __useGetWorkerJobsiteAccessActivityQuery__
 *
 * To run a query within a React component, call `useGetWorkerJobsiteAccessActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkerJobsiteAccessActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkerJobsiteAccessActivityQuery({
 *   variables: {
 *      workerId: // value for 'workerId'
 *      includeArchived: // value for 'includeArchived'
 *      workerJobsiteAccessEventsInput: // value for 'workerJobsiteAccessEventsInput'
 *   },
 * });
 */
export function useGetWorkerJobsiteAccessActivityQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWorkerJobsiteAccessActivityQuery,
    GetWorkerJobsiteAccessActivityQueryVariables
  > &
    ({ variables: GetWorkerJobsiteAccessActivityQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWorkerJobsiteAccessActivityQuery, GetWorkerJobsiteAccessActivityQueryVariables>(
    GetWorkerJobsiteAccessActivityDocument,
    options,
  );
}
export function useGetWorkerJobsiteAccessActivityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkerJobsiteAccessActivityQuery,
    GetWorkerJobsiteAccessActivityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWorkerJobsiteAccessActivityQuery, GetWorkerJobsiteAccessActivityQueryVariables>(
    GetWorkerJobsiteAccessActivityDocument,
    options,
  );
}
export function useGetWorkerJobsiteAccessActivitySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetWorkerJobsiteAccessActivityQuery,
    GetWorkerJobsiteAccessActivityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetWorkerJobsiteAccessActivityQuery, GetWorkerJobsiteAccessActivityQueryVariables>(
    GetWorkerJobsiteAccessActivityDocument,
    options,
  );
}
export type GetWorkerJobsiteAccessActivityQueryHookResult = ReturnType<typeof useGetWorkerJobsiteAccessActivityQuery>;
export type GetWorkerJobsiteAccessActivityLazyQueryHookResult = ReturnType<
  typeof useGetWorkerJobsiteAccessActivityLazyQuery
>;
export type GetWorkerJobsiteAccessActivitySuspenseQueryHookResult = ReturnType<
  typeof useGetWorkerJobsiteAccessActivitySuspenseQuery
>;
export type GetWorkerJobsiteAccessActivityQueryResult = Apollo.QueryResult<
  GetWorkerJobsiteAccessActivityQuery,
  GetWorkerJobsiteAccessActivityQueryVariables
>;
export const GetWorkerJobsiteAccessSessionsDocument = gql`
  query GetWorkerJobsiteAccessSessions(
    $workerId: ID!
    $workerJobsiteAccessSessionsInput: WorkerJobsiteAccessSessionsInput
  ) {
    getWorker(workerId: $workerId) {
      jobsiteAccessSessions(input: $workerJobsiteAccessSessionsInput) {
        count
        edges {
          node {
            sessionId
            overnightSession
            jobsiteWorker {
              contractorWorker {
                contractor {
                  contractorId
                  organization {
                    organizationId
                    name
                  }
                }
              }
              jobsiteContractor {
                id
                jobsite {
                  jobsiteId
                  name
                }
              }
              currentAccess {
                isAllowed
              }
            }
            proximitySession {
              id
              day
              start
              end
              active
              compliant
              closed
              locale {
                time {
                  start
                  end
                }
              }
              span {
                hours
              }
              reads {
                count
                counts {
                  inbound
                  outbound
                }
              }
            }
          }
        }
        filtersOptions {
          jobsites {
            value
            label
            count
          }
          contractors {
            value
            label
            count
          }
        }
      }
    }
  }
`;

/**
 * __useGetWorkerJobsiteAccessSessionsQuery__
 *
 * To run a query within a React component, call `useGetWorkerJobsiteAccessSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkerJobsiteAccessSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkerJobsiteAccessSessionsQuery({
 *   variables: {
 *      workerId: // value for 'workerId'
 *      workerJobsiteAccessSessionsInput: // value for 'workerJobsiteAccessSessionsInput'
 *   },
 * });
 */
export function useGetWorkerJobsiteAccessSessionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWorkerJobsiteAccessSessionsQuery,
    GetWorkerJobsiteAccessSessionsQueryVariables
  > &
    ({ variables: GetWorkerJobsiteAccessSessionsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWorkerJobsiteAccessSessionsQuery, GetWorkerJobsiteAccessSessionsQueryVariables>(
    GetWorkerJobsiteAccessSessionsDocument,
    options,
  );
}
export function useGetWorkerJobsiteAccessSessionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkerJobsiteAccessSessionsQuery,
    GetWorkerJobsiteAccessSessionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWorkerJobsiteAccessSessionsQuery, GetWorkerJobsiteAccessSessionsQueryVariables>(
    GetWorkerJobsiteAccessSessionsDocument,
    options,
  );
}
export function useGetWorkerJobsiteAccessSessionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetWorkerJobsiteAccessSessionsQuery,
    GetWorkerJobsiteAccessSessionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetWorkerJobsiteAccessSessionsQuery, GetWorkerJobsiteAccessSessionsQueryVariables>(
    GetWorkerJobsiteAccessSessionsDocument,
    options,
  );
}
export type GetWorkerJobsiteAccessSessionsQueryHookResult = ReturnType<typeof useGetWorkerJobsiteAccessSessionsQuery>;
export type GetWorkerJobsiteAccessSessionsLazyQueryHookResult = ReturnType<
  typeof useGetWorkerJobsiteAccessSessionsLazyQuery
>;
export type GetWorkerJobsiteAccessSessionsSuspenseQueryHookResult = ReturnType<
  typeof useGetWorkerJobsiteAccessSessionsSuspenseQuery
>;
export type GetWorkerJobsiteAccessSessionsQueryResult = Apollo.QueryResult<
  GetWorkerJobsiteAccessSessionsQuery,
  GetWorkerJobsiteAccessSessionsQueryVariables
>;
export const GetWorkerUserRolesDocument = gql`
  query GetWorkerUserRoles($workerId: ID!, $includeArchivedWorker: Boolean, $userRolesInput: UserRolesInput) {
    getWorker(workerId: $workerId, includeArchived: $includeArchivedWorker) {
      workerId
      user {
        userAccountId
        roles(input: $userRolesInput) {
          key
          displayName
          mappingCreatedAt
          mappingUpdatedAt
          object {
            objectId
            objectName
            objectDetails {
              name
              title
              objectMemberId
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetWorkerUserRolesQuery__
 *
 * To run a query within a React component, call `useGetWorkerUserRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkerUserRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkerUserRolesQuery({
 *   variables: {
 *      workerId: // value for 'workerId'
 *      includeArchivedWorker: // value for 'includeArchivedWorker'
 *      userRolesInput: // value for 'userRolesInput'
 *   },
 * });
 */
export function useGetWorkerUserRolesQuery(
  baseOptions: Apollo.QueryHookOptions<GetWorkerUserRolesQuery, GetWorkerUserRolesQueryVariables> &
    ({ variables: GetWorkerUserRolesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWorkerUserRolesQuery, GetWorkerUserRolesQueryVariables>(
    GetWorkerUserRolesDocument,
    options,
  );
}
export function useGetWorkerUserRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWorkerUserRolesQuery, GetWorkerUserRolesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWorkerUserRolesQuery, GetWorkerUserRolesQueryVariables>(
    GetWorkerUserRolesDocument,
    options,
  );
}
export function useGetWorkerUserRolesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetWorkerUserRolesQuery, GetWorkerUserRolesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetWorkerUserRolesQuery, GetWorkerUserRolesQueryVariables>(
    GetWorkerUserRolesDocument,
    options,
  );
}
export type GetWorkerUserRolesQueryHookResult = ReturnType<typeof useGetWorkerUserRolesQuery>;
export type GetWorkerUserRolesLazyQueryHookResult = ReturnType<typeof useGetWorkerUserRolesLazyQuery>;
export type GetWorkerUserRolesSuspenseQueryHookResult = ReturnType<typeof useGetWorkerUserRolesSuspenseQuery>;
export type GetWorkerUserRolesQueryResult = Apollo.QueryResult<
  GetWorkerUserRolesQuery,
  GetWorkerUserRolesQueryVariables
>;
export const WorkerOnboardingUpdateUserIdentityDocument = gql`
  mutation WorkerOnboardingUpdateUserIdentity($input: UserUpdateIdentityInput!) {
    userUpdateIdentity(input: $input) {
      user {
        identity {
          firstName
          lastName
        }
      }
    }
  }
`;
export type WorkerOnboardingUpdateUserIdentityMutationFn = Apollo.MutationFunction<
  WorkerOnboardingUpdateUserIdentityMutation,
  WorkerOnboardingUpdateUserIdentityMutationVariables
>;

/**
 * __useWorkerOnboardingUpdateUserIdentityMutation__
 *
 * To run a mutation, you first call `useWorkerOnboardingUpdateUserIdentityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWorkerOnboardingUpdateUserIdentityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [workerOnboardingUpdateUserIdentityMutation, { data, loading, error }] = useWorkerOnboardingUpdateUserIdentityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWorkerOnboardingUpdateUserIdentityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    WorkerOnboardingUpdateUserIdentityMutation,
    WorkerOnboardingUpdateUserIdentityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    WorkerOnboardingUpdateUserIdentityMutation,
    WorkerOnboardingUpdateUserIdentityMutationVariables
  >(WorkerOnboardingUpdateUserIdentityDocument, options);
}
export type WorkerOnboardingUpdateUserIdentityMutationHookResult = ReturnType<
  typeof useWorkerOnboardingUpdateUserIdentityMutation
>;
export type WorkerOnboardingUpdateUserIdentityMutationResult =
  Apollo.MutationResult<WorkerOnboardingUpdateUserIdentityMutation>;
export type WorkerOnboardingUpdateUserIdentityMutationOptions = Apollo.BaseMutationOptions<
  WorkerOnboardingUpdateUserIdentityMutation,
  WorkerOnboardingUpdateUserIdentityMutationVariables
>;
export const WorkerOnboardingUpdateWorkerDocument = gql`
  mutation WorkerOnboardingUpdateWorker($workerId: ID!, $workerInput: WorkerInput!) {
    updateWorker(workerId: $workerId, workerInput: $workerInput) {
      middleInitial
      user {
        identity {
          firstName
        }
      }
    }
  }
`;
export type WorkerOnboardingUpdateWorkerMutationFn = Apollo.MutationFunction<
  WorkerOnboardingUpdateWorkerMutation,
  WorkerOnboardingUpdateWorkerMutationVariables
>;

/**
 * __useWorkerOnboardingUpdateWorkerMutation__
 *
 * To run a mutation, you first call `useWorkerOnboardingUpdateWorkerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWorkerOnboardingUpdateWorkerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [workerOnboardingUpdateWorkerMutation, { data, loading, error }] = useWorkerOnboardingUpdateWorkerMutation({
 *   variables: {
 *      workerId: // value for 'workerId'
 *      workerInput: // value for 'workerInput'
 *   },
 * });
 */
export function useWorkerOnboardingUpdateWorkerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    WorkerOnboardingUpdateWorkerMutation,
    WorkerOnboardingUpdateWorkerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<WorkerOnboardingUpdateWorkerMutation, WorkerOnboardingUpdateWorkerMutationVariables>(
    WorkerOnboardingUpdateWorkerDocument,
    options,
  );
}
export type WorkerOnboardingUpdateWorkerMutationHookResult = ReturnType<typeof useWorkerOnboardingUpdateWorkerMutation>;
export type WorkerOnboardingUpdateWorkerMutationResult = Apollo.MutationResult<WorkerOnboardingUpdateWorkerMutation>;
export type WorkerOnboardingUpdateWorkerMutationOptions = Apollo.BaseMutationOptions<
  WorkerOnboardingUpdateWorkerMutation,
  WorkerOnboardingUpdateWorkerMutationVariables
>;
export const GetWorkerOnboardingDocumentReportDocument = gql`
  query GetWorkerOnboardingDocumentReport($jobsiteWorkerDocumentId: ID!) {
    getJobsiteWorkerDocument(jobsiteWorkerDocumentId: $jobsiteWorkerDocumentId) {
      jobsiteWorkerDocumentId
      jobsiteWorkerDocumentType {
        jobsite {
          name
          jobsiteId
          developer {
            name
            developerId
          }
        }
        workerDocumentType {
          name
          key
          expirationDate
          isGlobal
          documentLevel
        }
      }
      latestVersion {
        jobsiteWorkerDocumentVersionId
        files {
          fileId
          fileType
          downloadUrl
        }
        additionalFieldValues {
          key
          value
        }
        objectHistory {
          createdAt
          createdBy {
            userAccountId
            identity {
              firstName
              lastName
              email
            }
          }
          updatedAt
          updatedBy {
            userAccountId
            identity {
              firstName
              lastName
              email
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetWorkerOnboardingDocumentReportQuery__
 *
 * To run a query within a React component, call `useGetWorkerOnboardingDocumentReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkerOnboardingDocumentReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkerOnboardingDocumentReportQuery({
 *   variables: {
 *      jobsiteWorkerDocumentId: // value for 'jobsiteWorkerDocumentId'
 *   },
 * });
 */
export function useGetWorkerOnboardingDocumentReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWorkerOnboardingDocumentReportQuery,
    GetWorkerOnboardingDocumentReportQueryVariables
  > &
    ({ variables: GetWorkerOnboardingDocumentReportQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWorkerOnboardingDocumentReportQuery, GetWorkerOnboardingDocumentReportQueryVariables>(
    GetWorkerOnboardingDocumentReportDocument,
    options,
  );
}
export function useGetWorkerOnboardingDocumentReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkerOnboardingDocumentReportQuery,
    GetWorkerOnboardingDocumentReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWorkerOnboardingDocumentReportQuery, GetWorkerOnboardingDocumentReportQueryVariables>(
    GetWorkerOnboardingDocumentReportDocument,
    options,
  );
}
export function useGetWorkerOnboardingDocumentReportSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetWorkerOnboardingDocumentReportQuery,
    GetWorkerOnboardingDocumentReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetWorkerOnboardingDocumentReportQuery,
    GetWorkerOnboardingDocumentReportQueryVariables
  >(GetWorkerOnboardingDocumentReportDocument, options);
}
export type GetWorkerOnboardingDocumentReportQueryHookResult = ReturnType<
  typeof useGetWorkerOnboardingDocumentReportQuery
>;
export type GetWorkerOnboardingDocumentReportLazyQueryHookResult = ReturnType<
  typeof useGetWorkerOnboardingDocumentReportLazyQuery
>;
export type GetWorkerOnboardingDocumentReportSuspenseQueryHookResult = ReturnType<
  typeof useGetWorkerOnboardingDocumentReportSuspenseQuery
>;
export type GetWorkerOnboardingDocumentReportQueryResult = Apollo.QueryResult<
  GetWorkerOnboardingDocumentReportQuery,
  GetWorkerOnboardingDocumentReportQueryVariables
>;
export const GetJobsiteWorkerDocument = gql`
  query GetJobsiteWorker($jobsiteWorkerId: ID!) {
    getJobsiteWorker(jobsiteWorkerId: $jobsiteWorkerId) {
      jobsiteWorkerId
      isBanned
      bannedReason
      bannedAt
      startDate
      endDate
      payrollId
      stickerNumber
      orientationDate
      orientationStatus
      documentsCompletedAt
      orientationStatus
      orientationDate
      medicalExamSkipReason
      passedMedicalExamAt
      documentsCompletedAt
      documentsCompletedSkipReason
      badgingCompletedAt
      badgingSkipReason
      signatureCompletedAt
      signatureSkipReason
      onboardingSignedAt
      onboardingSkipReason
      profileCompletedAt
      profileCompleteSkipReason
      bannedReason
      siteSpecificTrainingCompletedAt
      siteSpecificTrainingSkipReason
      medicalNotes
      isAntipassbackExempt
      nycSstExemptionReason
      clearanceAssignments {
        count
        edges {
          node {
            id
            clearance {
              cCureClearanceId
              name
              cCureEnvironment {
                cCureEnvironmentId
                namespace
                instanceSlug
              }
            }
          }
        }
      }
      jobsiteContractor {
        contractor {
          contractorId
          organization {
            name
          }
        }
        jobsite {
          jobsiteId
          name
          clearanceAssignmentEnabled
          developer {
            name
          }
          modules {
            ... on JobsiteOnboardingModule {
              name
              steps {
                key
                index
                name
                isRequired
                isVisible
                fields {
                  key
                  name
                  index
                  isRequired
                  isHidden
                }
              }
              documents {
                key
                name
                index
                isRequired
                isVisible
                fields {
                  key
                  name
                  index
                  isRequired
                  isHidden
                }
              }
            }
          }
        }
      }
      contractorWorker {
        contractor {
          contractorId
          organization {
            name
          }
        }
        worker {
          workerId
          middleInitial
          suffix
          addressLine1
          addressLine2
          addressCity
          addressState
          addressZipCode
          addressZipCode
          quickCode
          birthDate
          emergencyContactName
          emergencyContactPhone
          emergencyContactRelationship
          gender
          jobTitle
          primaryLanguage
          race
          isSkilled
          ssnLastFour
          trade
          unionAffiliation
          citizenshipStatus
          isVeteran
          profilePicture {
            downloadUrl
            uploadedAt
            fileId
            fileType
          }
          profilePictureCropped {
            downloadUrl
            uploadedAt
            fileId
            fileType
          }
          editableFields {
            email
            phone
          }
          user {
            userAccountId
            identity {
              firstName
              lastName
              email
              phoneNumber
            }
          }
          jobsiteWorkers {
            count
          }
        }
      }
      isAntipassbackExempt
    }
  }
`;

/**
 * __useGetJobsiteWorkerQuery__
 *
 * To run a query within a React component, call `useGetJobsiteWorkerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobsiteWorkerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobsiteWorkerQuery({
 *   variables: {
 *      jobsiteWorkerId: // value for 'jobsiteWorkerId'
 *   },
 * });
 */
export function useGetJobsiteWorkerQuery(
  baseOptions: Apollo.QueryHookOptions<GetJobsiteWorkerQuery, GetJobsiteWorkerQueryVariables> &
    ({ variables: GetJobsiteWorkerQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetJobsiteWorkerQuery, GetJobsiteWorkerQueryVariables>(GetJobsiteWorkerDocument, options);
}
export function useGetJobsiteWorkerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetJobsiteWorkerQuery, GetJobsiteWorkerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetJobsiteWorkerQuery, GetJobsiteWorkerQueryVariables>(GetJobsiteWorkerDocument, options);
}
export function useGetJobsiteWorkerSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetJobsiteWorkerQuery, GetJobsiteWorkerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetJobsiteWorkerQuery, GetJobsiteWorkerQueryVariables>(
    GetJobsiteWorkerDocument,
    options,
  );
}
export type GetJobsiteWorkerQueryHookResult = ReturnType<typeof useGetJobsiteWorkerQuery>;
export type GetJobsiteWorkerLazyQueryHookResult = ReturnType<typeof useGetJobsiteWorkerLazyQuery>;
export type GetJobsiteWorkerSuspenseQueryHookResult = ReturnType<typeof useGetJobsiteWorkerSuspenseQuery>;
export type GetJobsiteWorkerQueryResult = Apollo.QueryResult<GetJobsiteWorkerQuery, GetJobsiteWorkerQueryVariables>;
export const GetOnboardingJobsiteWorkersDocument = gql`
  query GetOnboardingJobsiteWorkers(
    $userContractorsInput: UserContractorsInput
    $jobsiteIds: [ID!]
    $contractorIds: [ID!]
    $limit: Int
    $offset: Int
    $activeOnly: Boolean
    $orientationDate: Date
    $orientationStartDate: Date
    $orientationEndDate: Date
    $orientationStatus: JobsiteWorkerOrientationStatusFilter
    $needsMedical: Boolean
    $orderBy: [GetJobsiteWorkersOrderBy]
  ) {
    getCurrentSession {
      user {
        userAccountId
        contractors(input: $userContractorsInput) {
          edges {
            node {
              contractorId
              organization {
                name
              }
            }
          }
        }
      }
    }
    getJobsiteWorkers(
      jobsiteIds: $jobsiteIds
      contractorIds: $contractorIds
      offset: $offset
      limit: $limit
      activeOnly: $activeOnly
      orientationDate: $orientationDate
      orientationStartDate: $orientationStartDate
      orientationEndDate: $orientationEndDate
      orientationStatus: $orientationStatus
      needsMedical: $needsMedical
      orderBy: $orderBy
    ) {
      totalCount
      jobsiteStats {
        numberOfOrientationWorkersTotal
        numberOfOrientationWorkersSelfOnboarding
        numberOfOrientationWorkersInProgress
        numberOfOrientationWorkersCompleted
        numberOfOrientationWorkersNeedingMedical
        numberOfOrientationWorkersNeedingBadging
      }
      edges {
        endDate
        isAllowedNow
        jobsiteWorker {
          jobsiteWorkerId
          orientationStatus
          orientationDate
          medicalExamSkipReason
          passedMedicalExamAt
          documentsCompletedAt
          documentsCompletedSkipReason
          badgingCompletedAt
          badgingSkipReason
          signatureCompletedAt
          signatureSkipReason
          onboardingSignedAt
          onboardingSkipReason
          profileCompletedAt
          profileCompleteSkipReason
          banCategory
          bannedReason
          siteSpecificTrainingCompletedAt
          siteSpecificTrainingSkipReason
          jobsiteContractor {
            jobsite {
              jobsiteId
              name
              modules {
                ... on JobsiteOnboardingModule {
                  name
                  steps {
                    key
                    index
                    isVisible
                  }
                }
              }
            }
          }
          contractorWorker {
            worker {
              trade
              workerId
              profilePicture {
                downloadUrl
                uploadedAt
                fileId
              }
              profilePictureCropped {
                downloadUrl
                uploadedAt
                fileId
                fileType
              }
              user {
                userAccountId
                identity {
                  firstName
                  lastName
                }
              }
            }
            contractor {
              contractorId
              organization {
                name
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetOnboardingJobsiteWorkersQuery__
 *
 * To run a query within a React component, call `useGetOnboardingJobsiteWorkersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnboardingJobsiteWorkersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnboardingJobsiteWorkersQuery({
 *   variables: {
 *      userContractorsInput: // value for 'userContractorsInput'
 *      jobsiteIds: // value for 'jobsiteIds'
 *      contractorIds: // value for 'contractorIds'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      activeOnly: // value for 'activeOnly'
 *      orientationDate: // value for 'orientationDate'
 *      orientationStartDate: // value for 'orientationStartDate'
 *      orientationEndDate: // value for 'orientationEndDate'
 *      orientationStatus: // value for 'orientationStatus'
 *      needsMedical: // value for 'needsMedical'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetOnboardingJobsiteWorkersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetOnboardingJobsiteWorkersQuery, GetOnboardingJobsiteWorkersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOnboardingJobsiteWorkersQuery, GetOnboardingJobsiteWorkersQueryVariables>(
    GetOnboardingJobsiteWorkersDocument,
    options,
  );
}
export function useGetOnboardingJobsiteWorkersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOnboardingJobsiteWorkersQuery,
    GetOnboardingJobsiteWorkersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOnboardingJobsiteWorkersQuery, GetOnboardingJobsiteWorkersQueryVariables>(
    GetOnboardingJobsiteWorkersDocument,
    options,
  );
}
export function useGetOnboardingJobsiteWorkersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetOnboardingJobsiteWorkersQuery,
    GetOnboardingJobsiteWorkersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetOnboardingJobsiteWorkersQuery, GetOnboardingJobsiteWorkersQueryVariables>(
    GetOnboardingJobsiteWorkersDocument,
    options,
  );
}
export type GetOnboardingJobsiteWorkersQueryHookResult = ReturnType<typeof useGetOnboardingJobsiteWorkersQuery>;
export type GetOnboardingJobsiteWorkersLazyQueryHookResult = ReturnType<typeof useGetOnboardingJobsiteWorkersLazyQuery>;
export type GetOnboardingJobsiteWorkersSuspenseQueryHookResult = ReturnType<
  typeof useGetOnboardingJobsiteWorkersSuspenseQuery
>;
export type GetOnboardingJobsiteWorkersQueryResult = Apollo.QueryResult<
  GetOnboardingJobsiteWorkersQuery,
  GetOnboardingJobsiteWorkersQueryVariables
>;
export const UpdateOnboardingJobsiteWorkerDocument = gql`
  mutation UpdateOnboardingJobsiteWorker($jobsiteWorkerId: ID!, $jobsiteWorkerInput: JobsiteWorkerInput!) {
    updateJobsiteWorker(jobsiteWorkerId: $jobsiteWorkerId, jobsiteWorkerInput: $jobsiteWorkerInput) {
      orientationDate
    }
  }
`;
export type UpdateOnboardingJobsiteWorkerMutationFn = Apollo.MutationFunction<
  UpdateOnboardingJobsiteWorkerMutation,
  UpdateOnboardingJobsiteWorkerMutationVariables
>;

/**
 * __useUpdateOnboardingJobsiteWorkerMutation__
 *
 * To run a mutation, you first call `useUpdateOnboardingJobsiteWorkerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOnboardingJobsiteWorkerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOnboardingJobsiteWorkerMutation, { data, loading, error }] = useUpdateOnboardingJobsiteWorkerMutation({
 *   variables: {
 *      jobsiteWorkerId: // value for 'jobsiteWorkerId'
 *      jobsiteWorkerInput: // value for 'jobsiteWorkerInput'
 *   },
 * });
 */
export function useUpdateOnboardingJobsiteWorkerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOnboardingJobsiteWorkerMutation,
    UpdateOnboardingJobsiteWorkerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOnboardingJobsiteWorkerMutation, UpdateOnboardingJobsiteWorkerMutationVariables>(
    UpdateOnboardingJobsiteWorkerDocument,
    options,
  );
}
export type UpdateOnboardingJobsiteWorkerMutationHookResult = ReturnType<
  typeof useUpdateOnboardingJobsiteWorkerMutation
>;
export type UpdateOnboardingJobsiteWorkerMutationResult = Apollo.MutationResult<UpdateOnboardingJobsiteWorkerMutation>;
export type UpdateOnboardingJobsiteWorkerMutationOptions = Apollo.BaseMutationOptions<
  UpdateOnboardingJobsiteWorkerMutation,
  UpdateOnboardingJobsiteWorkerMutationVariables
>;
export const GetSiteSafetyOrientationJobsiteWorkersDocument = gql`
  query GetSiteSafetyOrientationJobsiteWorkers(
    $userContractorsInput: UserContractorsInput
    $jobsiteIds: [ID!]
    $contractorIds: [ID!]
    $limit: Int
    $offset: Int
    $orderBy: [GetJobsiteWorkersOrderBy]
    $activeOnly: Boolean
    $orientationStartDate: Date
    $orientationEndDate: Date
    $withContractorOnly: Boolean
    $orientationStatus: JobsiteWorkerOrientationStatusFilter
    $needsMedical: Boolean
    $hideBanned: Boolean
    $completedSiteSpecificTraining: Boolean
    $completedSiteSpecificTrainingDate: Date
    $siteSpecificOrientationStatus: SiteSpecificOrientationStatus
  ) {
    getCurrentSession {
      user {
        userAccountId
        contractors(input: $userContractorsInput) {
          count
          edges {
            node {
              contractorId
              organization {
                name
              }
            }
          }
        }
      }
    }
    getJobsiteWorkers(
      jobsiteIds: $jobsiteIds
      contractorIds: $contractorIds
      limit: $limit
      offset: $offset
      orderBy: $orderBy
      activeOnly: $activeOnly
      orientationStartDate: $orientationStartDate
      orientationEndDate: $orientationEndDate
      withContractorOnly: $withContractorOnly
      orientationStatus: $orientationStatus
      needsMedical: $needsMedical
      hideBanned: $hideBanned
      completedSiteSpecificTraining: $completedSiteSpecificTraining
      completedSiteSpecificTrainingDate: $completedSiteSpecificTrainingDate
      siteSpecificOrientationStatus: $siteSpecificOrientationStatus
    ) {
      totalCount
      edges {
        endDate
        isAllowedNow
        jobsiteWorker {
          jobsiteWorkerId
          orientationStatus
          orientationDate
          medicalExamSkipReason
          passedMedicalExamAt
          documentsCompletedAt
          documentsCompletedSkipReason
          badgingCompletedAt
          badgingSkipReason
          signatureCompletedAt
          signatureSkipReason
          onboardingSignedAt
          onboardingSkipReason
          profileCompletedAt
          profileCompleteSkipReason
          siteSpecificTrainingCompletedAt
          isSiteSpecificTrainingExpired
          bannedReason
          jobsiteContractor {
            jobsite {
              name
            }
          }
          contractorWorker {
            worker {
              trade
              workerId
              jobTitle
              profilePicture {
                downloadUrl
                uploadedAt
                fileId
              }
              profilePictureCropped {
                downloadUrl
                uploadedAt
                fileId
                fileType
              }
              user {
                userAccountId
                identity {
                  firstName
                  lastName
                }
              }
            }
            contractor {
              contractorId
              organization {
                name
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetSiteSafetyOrientationJobsiteWorkersQuery__
 *
 * To run a query within a React component, call `useGetSiteSafetyOrientationJobsiteWorkersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteSafetyOrientationJobsiteWorkersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteSafetyOrientationJobsiteWorkersQuery({
 *   variables: {
 *      userContractorsInput: // value for 'userContractorsInput'
 *      jobsiteIds: // value for 'jobsiteIds'
 *      contractorIds: // value for 'contractorIds'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      activeOnly: // value for 'activeOnly'
 *      orientationStartDate: // value for 'orientationStartDate'
 *      orientationEndDate: // value for 'orientationEndDate'
 *      withContractorOnly: // value for 'withContractorOnly'
 *      orientationStatus: // value for 'orientationStatus'
 *      needsMedical: // value for 'needsMedical'
 *      hideBanned: // value for 'hideBanned'
 *      completedSiteSpecificTraining: // value for 'completedSiteSpecificTraining'
 *      completedSiteSpecificTrainingDate: // value for 'completedSiteSpecificTrainingDate'
 *      siteSpecificOrientationStatus: // value for 'siteSpecificOrientationStatus'
 *   },
 * });
 */
export function useGetSiteSafetyOrientationJobsiteWorkersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSiteSafetyOrientationJobsiteWorkersQuery,
    GetSiteSafetyOrientationJobsiteWorkersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSiteSafetyOrientationJobsiteWorkersQuery,
    GetSiteSafetyOrientationJobsiteWorkersQueryVariables
  >(GetSiteSafetyOrientationJobsiteWorkersDocument, options);
}
export function useGetSiteSafetyOrientationJobsiteWorkersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSiteSafetyOrientationJobsiteWorkersQuery,
    GetSiteSafetyOrientationJobsiteWorkersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSiteSafetyOrientationJobsiteWorkersQuery,
    GetSiteSafetyOrientationJobsiteWorkersQueryVariables
  >(GetSiteSafetyOrientationJobsiteWorkersDocument, options);
}
export function useGetSiteSafetyOrientationJobsiteWorkersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetSiteSafetyOrientationJobsiteWorkersQuery,
    GetSiteSafetyOrientationJobsiteWorkersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetSiteSafetyOrientationJobsiteWorkersQuery,
    GetSiteSafetyOrientationJobsiteWorkersQueryVariables
  >(GetSiteSafetyOrientationJobsiteWorkersDocument, options);
}
export type GetSiteSafetyOrientationJobsiteWorkersQueryHookResult = ReturnType<
  typeof useGetSiteSafetyOrientationJobsiteWorkersQuery
>;
export type GetSiteSafetyOrientationJobsiteWorkersLazyQueryHookResult = ReturnType<
  typeof useGetSiteSafetyOrientationJobsiteWorkersLazyQuery
>;
export type GetSiteSafetyOrientationJobsiteWorkersSuspenseQueryHookResult = ReturnType<
  typeof useGetSiteSafetyOrientationJobsiteWorkersSuspenseQuery
>;
export type GetSiteSafetyOrientationJobsiteWorkersQueryResult = Apollo.QueryResult<
  GetSiteSafetyOrientationJobsiteWorkersQuery,
  GetSiteSafetyOrientationJobsiteWorkersQueryVariables
>;
export const GetWorkersContainerCurrentSessionDocument = gql`
  query GetWorkersContainerCurrentSession(
    $userWorkersInput: UserWorkersInput
    $userContractorsInput: UserContractorsInput
  ) {
    getCurrentSession {
      user {
        userAccountId
        workers(input: $userWorkersInput) {
          count
          edges {
            node {
              workerId
              birthDate
              quickCode
              trade
              jobTitle
              user {
                userAccountId
                identity {
                  firstName
                  lastName
                  email
                }
              }
              profilePictureCropped {
                downloadUrl
                uploadedAt
                fileId
                fileType
              }
              jobsiteWorkers {
                edges {
                  node {
                    jobsiteWorkerId
                    jobsiteContractor {
                      jobsite {
                        jobsiteId
                        name
                      }
                      contractor {
                        contractorId
                        organization {
                          name
                        }
                      }
                    }
                    currentAccess {
                      isAllowed
                    }
                  }
                }
              }
            }
          }
          filtersOptions {
            trades {
              value
              label
              count
            }
            tradeClasses {
              value
              label
              count
            }
          }
        }
        contractors(input: $userContractorsInput) {
          edges {
            node {
              contractorId
              organization {
                name
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetWorkersContainerCurrentSessionQuery__
 *
 * To run a query within a React component, call `useGetWorkersContainerCurrentSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkersContainerCurrentSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkersContainerCurrentSessionQuery({
 *   variables: {
 *      userWorkersInput: // value for 'userWorkersInput'
 *      userContractorsInput: // value for 'userContractorsInput'
 *   },
 * });
 */
export function useGetWorkersContainerCurrentSessionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetWorkersContainerCurrentSessionQuery,
    GetWorkersContainerCurrentSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWorkersContainerCurrentSessionQuery, GetWorkersContainerCurrentSessionQueryVariables>(
    GetWorkersContainerCurrentSessionDocument,
    options,
  );
}
export function useGetWorkersContainerCurrentSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkersContainerCurrentSessionQuery,
    GetWorkersContainerCurrentSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWorkersContainerCurrentSessionQuery, GetWorkersContainerCurrentSessionQueryVariables>(
    GetWorkersContainerCurrentSessionDocument,
    options,
  );
}
export function useGetWorkersContainerCurrentSessionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetWorkersContainerCurrentSessionQuery,
    GetWorkersContainerCurrentSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetWorkersContainerCurrentSessionQuery,
    GetWorkersContainerCurrentSessionQueryVariables
  >(GetWorkersContainerCurrentSessionDocument, options);
}
export type GetWorkersContainerCurrentSessionQueryHookResult = ReturnType<
  typeof useGetWorkersContainerCurrentSessionQuery
>;
export type GetWorkersContainerCurrentSessionLazyQueryHookResult = ReturnType<
  typeof useGetWorkersContainerCurrentSessionLazyQuery
>;
export type GetWorkersContainerCurrentSessionSuspenseQueryHookResult = ReturnType<
  typeof useGetWorkersContainerCurrentSessionSuspenseQuery
>;
export type GetWorkersContainerCurrentSessionQueryResult = Apollo.QueryResult<
  GetWorkersContainerCurrentSessionQuery,
  GetWorkersContainerCurrentSessionQueryVariables
>;
export const AddAndIssueFacialRecognitionBadgeDocument = gql`
  mutation addAndIssueFacialRecognitionBadge(
    $workerId: ID!
    $jobsiteId: ID!
    $workerCardInput: WorkerCardInput!
    $newPhoneNumber: String
    $newEmail: String
  ) {
    addAndIssueFacialRecognitionBadge(
      workerId: $workerId
      jobsiteId: $jobsiteId
      workerCardInput: $workerCardInput
      newPhoneNumber: $newPhoneNumber
      newEmail: $newEmail
    ) {
      workerCardId
      cardNumber
    }
  }
`;
export type AddAndIssueFacialRecognitionBadgeMutationFn = Apollo.MutationFunction<
  AddAndIssueFacialRecognitionBadgeMutation,
  AddAndIssueFacialRecognitionBadgeMutationVariables
>;

/**
 * __useAddAndIssueFacialRecognitionBadgeMutation__
 *
 * To run a mutation, you first call `useAddAndIssueFacialRecognitionBadgeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAndIssueFacialRecognitionBadgeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAndIssueFacialRecognitionBadgeMutation, { data, loading, error }] = useAddAndIssueFacialRecognitionBadgeMutation({
 *   variables: {
 *      workerId: // value for 'workerId'
 *      jobsiteId: // value for 'jobsiteId'
 *      workerCardInput: // value for 'workerCardInput'
 *      newPhoneNumber: // value for 'newPhoneNumber'
 *      newEmail: // value for 'newEmail'
 *   },
 * });
 */
export function useAddAndIssueFacialRecognitionBadgeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddAndIssueFacialRecognitionBadgeMutation,
    AddAndIssueFacialRecognitionBadgeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddAndIssueFacialRecognitionBadgeMutation,
    AddAndIssueFacialRecognitionBadgeMutationVariables
  >(AddAndIssueFacialRecognitionBadgeDocument, options);
}
export type AddAndIssueFacialRecognitionBadgeMutationHookResult = ReturnType<
  typeof useAddAndIssueFacialRecognitionBadgeMutation
>;
export type AddAndIssueFacialRecognitionBadgeMutationResult =
  Apollo.MutationResult<AddAndIssueFacialRecognitionBadgeMutation>;
export type AddAndIssueFacialRecognitionBadgeMutationOptions = Apollo.BaseMutationOptions<
  AddAndIssueFacialRecognitionBadgeMutation,
  AddAndIssueFacialRecognitionBadgeMutationVariables
>;
export const AddAndIssueMobileBadgeDocument = gql`
  mutation addAndIssueMobileBadge(
    $workerId: ID!
    $jobsiteId: ID!
    $workerCardInput: WorkerCardInput!
    $newPhoneNumber: String
  ) {
    addAndIssueMobileBadge(
      workerId: $workerId
      jobsiteId: $jobsiteId
      workerCardInput: $workerCardInput
      newPhoneNumber: $newPhoneNumber
    ) {
      workerCardId
      cardNumber
    }
  }
`;
export type AddAndIssueMobileBadgeMutationFn = Apollo.MutationFunction<
  AddAndIssueMobileBadgeMutation,
  AddAndIssueMobileBadgeMutationVariables
>;

/**
 * __useAddAndIssueMobileBadgeMutation__
 *
 * To run a mutation, you first call `useAddAndIssueMobileBadgeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAndIssueMobileBadgeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAndIssueMobileBadgeMutation, { data, loading, error }] = useAddAndIssueMobileBadgeMutation({
 *   variables: {
 *      workerId: // value for 'workerId'
 *      jobsiteId: // value for 'jobsiteId'
 *      workerCardInput: // value for 'workerCardInput'
 *      newPhoneNumber: // value for 'newPhoneNumber'
 *   },
 * });
 */
export function useAddAndIssueMobileBadgeMutation(
  baseOptions?: Apollo.MutationHookOptions<AddAndIssueMobileBadgeMutation, AddAndIssueMobileBadgeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddAndIssueMobileBadgeMutation, AddAndIssueMobileBadgeMutationVariables>(
    AddAndIssueMobileBadgeDocument,
    options,
  );
}
export type AddAndIssueMobileBadgeMutationHookResult = ReturnType<typeof useAddAndIssueMobileBadgeMutation>;
export type AddAndIssueMobileBadgeMutationResult = Apollo.MutationResult<AddAndIssueMobileBadgeMutation>;
export type AddAndIssueMobileBadgeMutationOptions = Apollo.BaseMutationOptions<
  AddAndIssueMobileBadgeMutation,
  AddAndIssueMobileBadgeMutationVariables
>;
export const AddCardToWorkerDocument = gql`
  mutation AddCardToWorker($workerId: ID!, $jobsiteId: ID!, $workerCardInput: WorkerCardInput!, $forceCreate: Boolean) {
    addCardToWorker(
      workerId: $workerId
      jobsiteId: $jobsiteId
      workerCardInput: $workerCardInput
      forceCreate: $forceCreate
    ) {
      workerCardId
      cardNumber
      matchingWorkerCardId
      cardStatus
      worker {
        workerId
      }
    }
  }
`;
export type AddCardToWorkerMutationFn = Apollo.MutationFunction<
  AddCardToWorkerMutation,
  AddCardToWorkerMutationVariables
>;

/**
 * __useAddCardToWorkerMutation__
 *
 * To run a mutation, you first call `useAddCardToWorkerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCardToWorkerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCardToWorkerMutation, { data, loading, error }] = useAddCardToWorkerMutation({
 *   variables: {
 *      workerId: // value for 'workerId'
 *      jobsiteId: // value for 'jobsiteId'
 *      workerCardInput: // value for 'workerCardInput'
 *      forceCreate: // value for 'forceCreate'
 *   },
 * });
 */
export function useAddCardToWorkerMutation(
  baseOptions?: Apollo.MutationHookOptions<AddCardToWorkerMutation, AddCardToWorkerMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddCardToWorkerMutation, AddCardToWorkerMutationVariables>(
    AddCardToWorkerDocument,
    options,
  );
}
export type AddCardToWorkerMutationHookResult = ReturnType<typeof useAddCardToWorkerMutation>;
export type AddCardToWorkerMutationResult = Apollo.MutationResult<AddCardToWorkerMutation>;
export type AddCardToWorkerMutationOptions = Apollo.BaseMutationOptions<
  AddCardToWorkerMutation,
  AddCardToWorkerMutationVariables
>;
export const AssignContractorWorkerToJobsiteDocument = gql`
  mutation AssignContractorWorkerToJobsite($input: AssignContractorWorkerToJobsiteInput!) {
    assignContractorWorkerToJobsite(input: $input) {
      isAlreadyAssigned
      jobsiteWorker {
        jobsiteWorkerId
        orientationStatus
        contractorWorker {
          id
          worker {
            workerId
            isAssignedToMultipleJobsites
          }
        }
        jobsiteContractor {
          id
          contractor {
            contractorId
            isDefaultContractor
            organization {
              organizationId
              name
            }
          }
        }
      }
    }
  }
`;
export type AssignContractorWorkerToJobsiteMutationFn = Apollo.MutationFunction<
  AssignContractorWorkerToJobsiteMutation,
  AssignContractorWorkerToJobsiteMutationVariables
>;

/**
 * __useAssignContractorWorkerToJobsiteMutation__
 *
 * To run a mutation, you first call `useAssignContractorWorkerToJobsiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignContractorWorkerToJobsiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignContractorWorkerToJobsiteMutation, { data, loading, error }] = useAssignContractorWorkerToJobsiteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignContractorWorkerToJobsiteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignContractorWorkerToJobsiteMutation,
    AssignContractorWorkerToJobsiteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AssignContractorWorkerToJobsiteMutation, AssignContractorWorkerToJobsiteMutationVariables>(
    AssignContractorWorkerToJobsiteDocument,
    options,
  );
}
export type AssignContractorWorkerToJobsiteMutationHookResult = ReturnType<
  typeof useAssignContractorWorkerToJobsiteMutation
>;
export type AssignContractorWorkerToJobsiteMutationResult =
  Apollo.MutationResult<AssignContractorWorkerToJobsiteMutation>;
export type AssignContractorWorkerToJobsiteMutationOptions = Apollo.BaseMutationOptions<
  AssignContractorWorkerToJobsiteMutation,
  AssignContractorWorkerToJobsiteMutationVariables
>;
export const CreateCardPrintJobDocument = gql`
  mutation CreateCardPrintJob(
    $workerId: ID!
    $jobsiteId: ID!
    $cardTemplateName: String
    $trade: String
    $jobTitle: String
  ) {
    createCardPrintJob(
      workerId: $workerId
      jobsiteId: $jobsiteId
      cardTemplateName: $cardTemplateName
      trade: $trade
      jobTitle: $jobTitle
    ) {
      cardPrintJobId
      status
      statusMessage
      completion {
        cardNumber
        facilityCode
        completedAt
      }
      startedAt
      jobsite {
        jobsiteId
        name
      }
      worker {
        workerId
        user {
          identity {
            firstName
            lastName
          }
        }
      }
    }
  }
`;
export type CreateCardPrintJobMutationFn = Apollo.MutationFunction<
  CreateCardPrintJobMutation,
  CreateCardPrintJobMutationVariables
>;

/**
 * __useCreateCardPrintJobMutation__
 *
 * To run a mutation, you first call `useCreateCardPrintJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCardPrintJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCardPrintJobMutation, { data, loading, error }] = useCreateCardPrintJobMutation({
 *   variables: {
 *      workerId: // value for 'workerId'
 *      jobsiteId: // value for 'jobsiteId'
 *      cardTemplateName: // value for 'cardTemplateName'
 *      trade: // value for 'trade'
 *      jobTitle: // value for 'jobTitle'
 *   },
 * });
 */
export function useCreateCardPrintJobMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCardPrintJobMutation, CreateCardPrintJobMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCardPrintJobMutation, CreateCardPrintJobMutationVariables>(
    CreateCardPrintJobDocument,
    options,
  );
}
export type CreateCardPrintJobMutationHookResult = ReturnType<typeof useCreateCardPrintJobMutation>;
export type CreateCardPrintJobMutationResult = Apollo.MutationResult<CreateCardPrintJobMutation>;
export type CreateCardPrintJobMutationOptions = Apollo.BaseMutationOptions<
  CreateCardPrintJobMutation,
  CreateCardPrintJobMutationVariables
>;
export const CreateJobsiteWorkerDocumentDocument = gql`
  mutation CreateJobsiteWorkerDocument(
    $jobsiteWorkerDocumentInput: JobsiteWorkerDocumentInput!
    $jobsiteWorkerDocumentVersionInput: JobsiteWorkerDocumentVersionInput!
  ) {
    createJobsiteWorkerDocument(
      jobsiteWorkerDocumentInput: $jobsiteWorkerDocumentInput
      jobsiteWorkerDocumentVersionInput: $jobsiteWorkerDocumentVersionInput
    ) {
      jobsiteWorkerDocumentType {
        workerDocumentType {
          workerDocumentTypeId
        }
      }
    }
  }
`;
export type CreateJobsiteWorkerDocumentMutationFn = Apollo.MutationFunction<
  CreateJobsiteWorkerDocumentMutation,
  CreateJobsiteWorkerDocumentMutationVariables
>;

/**
 * __useCreateJobsiteWorkerDocumentMutation__
 *
 * To run a mutation, you first call `useCreateJobsiteWorkerDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJobsiteWorkerDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJobsiteWorkerDocumentMutation, { data, loading, error }] = useCreateJobsiteWorkerDocumentMutation({
 *   variables: {
 *      jobsiteWorkerDocumentInput: // value for 'jobsiteWorkerDocumentInput'
 *      jobsiteWorkerDocumentVersionInput: // value for 'jobsiteWorkerDocumentVersionInput'
 *   },
 * });
 */
export function useCreateJobsiteWorkerDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateJobsiteWorkerDocumentMutation,
    CreateJobsiteWorkerDocumentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateJobsiteWorkerDocumentMutation, CreateJobsiteWorkerDocumentMutationVariables>(
    CreateJobsiteWorkerDocumentDocument,
    options,
  );
}
export type CreateJobsiteWorkerDocumentMutationHookResult = ReturnType<typeof useCreateJobsiteWorkerDocumentMutation>;
export type CreateJobsiteWorkerDocumentMutationResult = Apollo.MutationResult<CreateJobsiteWorkerDocumentMutation>;
export type CreateJobsiteWorkerDocumentMutationOptions = Apollo.BaseMutationOptions<
  CreateJobsiteWorkerDocumentMutation,
  CreateJobsiteWorkerDocumentMutationVariables
>;
export const CreateJobsiteWorkerDocumentVersionDocument = gql`
  mutation CreateJobsiteWorkerDocumentVersion(
    $jobsiteWorkerDocumentId: ID!
    $jobsiteWorkerDocumentVersionInput: JobsiteWorkerDocumentVersionInput!
  ) {
    createJobsiteWorkerDocumentVersion(
      jobsiteWorkerDocumentId: $jobsiteWorkerDocumentId
      jobsiteWorkerDocumentVersionInput: $jobsiteWorkerDocumentVersionInput
    ) {
      jobsiteWorkerDocumentVersionId
    }
  }
`;
export type CreateJobsiteWorkerDocumentVersionMutationFn = Apollo.MutationFunction<
  CreateJobsiteWorkerDocumentVersionMutation,
  CreateJobsiteWorkerDocumentVersionMutationVariables
>;

/**
 * __useCreateJobsiteWorkerDocumentVersionMutation__
 *
 * To run a mutation, you first call `useCreateJobsiteWorkerDocumentVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJobsiteWorkerDocumentVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJobsiteWorkerDocumentVersionMutation, { data, loading, error }] = useCreateJobsiteWorkerDocumentVersionMutation({
 *   variables: {
 *      jobsiteWorkerDocumentId: // value for 'jobsiteWorkerDocumentId'
 *      jobsiteWorkerDocumentVersionInput: // value for 'jobsiteWorkerDocumentVersionInput'
 *   },
 * });
 */
export function useCreateJobsiteWorkerDocumentVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateJobsiteWorkerDocumentVersionMutation,
    CreateJobsiteWorkerDocumentVersionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateJobsiteWorkerDocumentVersionMutation,
    CreateJobsiteWorkerDocumentVersionMutationVariables
  >(CreateJobsiteWorkerDocumentVersionDocument, options);
}
export type CreateJobsiteWorkerDocumentVersionMutationHookResult = ReturnType<
  typeof useCreateJobsiteWorkerDocumentVersionMutation
>;
export type CreateJobsiteWorkerDocumentVersionMutationResult =
  Apollo.MutationResult<CreateJobsiteWorkerDocumentVersionMutation>;
export type CreateJobsiteWorkerDocumentVersionMutationOptions = Apollo.BaseMutationOptions<
  CreateJobsiteWorkerDocumentVersionMutation,
  CreateJobsiteWorkerDocumentVersionMutationVariables
>;
export const JobsiteArchiveInvitationDocument = gql`
  mutation JobsiteArchiveInvitation($input: JobsiteArchiveInvitationInput!) {
    jobsiteArchiveInvitation(input: $input) {
      success {
        success
      }
    }
  }
`;
export type JobsiteArchiveInvitationMutationFn = Apollo.MutationFunction<
  JobsiteArchiveInvitationMutation,
  JobsiteArchiveInvitationMutationVariables
>;

/**
 * __useJobsiteArchiveInvitationMutation__
 *
 * To run a mutation, you first call `useJobsiteArchiveInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobsiteArchiveInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobsiteArchiveInvitationMutation, { data, loading, error }] = useJobsiteArchiveInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJobsiteArchiveInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<JobsiteArchiveInvitationMutation, JobsiteArchiveInvitationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<JobsiteArchiveInvitationMutation, JobsiteArchiveInvitationMutationVariables>(
    JobsiteArchiveInvitationDocument,
    options,
  );
}
export type JobsiteArchiveInvitationMutationHookResult = ReturnType<typeof useJobsiteArchiveInvitationMutation>;
export type JobsiteArchiveInvitationMutationResult = Apollo.MutationResult<JobsiteArchiveInvitationMutation>;
export type JobsiteArchiveInvitationMutationOptions = Apollo.BaseMutationOptions<
  JobsiteArchiveInvitationMutation,
  JobsiteArchiveInvitationMutationVariables
>;
export const JobsiteCreateInvitationDocument = gql`
  mutation JobsiteCreateInvitation($input: JobsiteCreateInvitationInput!) {
    jobsiteCreateInvitation(input: $input) {
      jobsiteInvitation {
        jobsiteInvitationId
        expirationDate
      }
    }
  }
`;
export type JobsiteCreateInvitationMutationFn = Apollo.MutationFunction<
  JobsiteCreateInvitationMutation,
  JobsiteCreateInvitationMutationVariables
>;

/**
 * __useJobsiteCreateInvitationMutation__
 *
 * To run a mutation, you first call `useJobsiteCreateInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobsiteCreateInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobsiteCreateInvitationMutation, { data, loading, error }] = useJobsiteCreateInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJobsiteCreateInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<JobsiteCreateInvitationMutation, JobsiteCreateInvitationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<JobsiteCreateInvitationMutation, JobsiteCreateInvitationMutationVariables>(
    JobsiteCreateInvitationDocument,
    options,
  );
}
export type JobsiteCreateInvitationMutationHookResult = ReturnType<typeof useJobsiteCreateInvitationMutation>;
export type JobsiteCreateInvitationMutationResult = Apollo.MutationResult<JobsiteCreateInvitationMutation>;
export type JobsiteCreateInvitationMutationOptions = Apollo.BaseMutationOptions<
  JobsiteCreateInvitationMutation,
  JobsiteCreateInvitationMutationVariables
>;
export const ReissueMobileBadgeDocument = gql`
  mutation reissueMobileBadge($workerCardId: ID!, $newPhoneNumber: String) {
    reissueMobileBadge(workerCardId: $workerCardId, newPhoneNumber: $newPhoneNumber) {
      workerCardId
      cardNumber
    }
  }
`;
export type ReissueMobileBadgeMutationFn = Apollo.MutationFunction<
  ReissueMobileBadgeMutation,
  ReissueMobileBadgeMutationVariables
>;

/**
 * __useReissueMobileBadgeMutation__
 *
 * To run a mutation, you first call `useReissueMobileBadgeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReissueMobileBadgeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reissueMobileBadgeMutation, { data, loading, error }] = useReissueMobileBadgeMutation({
 *   variables: {
 *      workerCardId: // value for 'workerCardId'
 *      newPhoneNumber: // value for 'newPhoneNumber'
 *   },
 * });
 */
export function useReissueMobileBadgeMutation(
  baseOptions?: Apollo.MutationHookOptions<ReissueMobileBadgeMutation, ReissueMobileBadgeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ReissueMobileBadgeMutation, ReissueMobileBadgeMutationVariables>(
    ReissueMobileBadgeDocument,
    options,
  );
}
export type ReissueMobileBadgeMutationHookResult = ReturnType<typeof useReissueMobileBadgeMutation>;
export type ReissueMobileBadgeMutationResult = Apollo.MutationResult<ReissueMobileBadgeMutation>;
export type ReissueMobileBadgeMutationOptions = Apollo.BaseMutationOptions<
  ReissueMobileBadgeMutation,
  ReissueMobileBadgeMutationVariables
>;
export const SendPasswordResetEmailDocument = gql`
  mutation SendPasswordResetEmail($email: String!, $sendForgotPasswordEmail: Boolean) {
    sendPasswordResetEmail(email: $email, sendForgotPasswordEmail: $sendForgotPasswordEmail)
  }
`;
export type SendPasswordResetEmailMutationFn = Apollo.MutationFunction<
  SendPasswordResetEmailMutation,
  SendPasswordResetEmailMutationVariables
>;

/**
 * __useSendPasswordResetEmailMutation__
 *
 * To run a mutation, you first call `useSendPasswordResetEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPasswordResetEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPasswordResetEmailMutation, { data, loading, error }] = useSendPasswordResetEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *      sendForgotPasswordEmail: // value for 'sendForgotPasswordEmail'
 *   },
 * });
 */
export function useSendPasswordResetEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<SendPasswordResetEmailMutation, SendPasswordResetEmailMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendPasswordResetEmailMutation, SendPasswordResetEmailMutationVariables>(
    SendPasswordResetEmailDocument,
    options,
  );
}
export type SendPasswordResetEmailMutationHookResult = ReturnType<typeof useSendPasswordResetEmailMutation>;
export type SendPasswordResetEmailMutationResult = Apollo.MutationResult<SendPasswordResetEmailMutation>;
export type SendPasswordResetEmailMutationOptions = Apollo.BaseMutationOptions<
  SendPasswordResetEmailMutation,
  SendPasswordResetEmailMutationVariables
>;
export const UpdateJobsiteWorkerDocumentVersionDocument = gql`
  mutation UpdateJobsiteWorkerDocumentVersion(
    $jobsiteWorkerDocumentVersionId: ID!
    $jobsiteWorkerDocumentVersionInput: JobsiteWorkerDocumentVersionInput!
  ) {
    updateJobsiteWorkerDocumentVersion(
      jobsiteWorkerDocumentVersionId: $jobsiteWorkerDocumentVersionId
      jobsiteWorkerDocumentVersionInput: $jobsiteWorkerDocumentVersionInput
    ) {
      jobsiteWorkerDocumentVersionId
    }
  }
`;
export type UpdateJobsiteWorkerDocumentVersionMutationFn = Apollo.MutationFunction<
  UpdateJobsiteWorkerDocumentVersionMutation,
  UpdateJobsiteWorkerDocumentVersionMutationVariables
>;

/**
 * __useUpdateJobsiteWorkerDocumentVersionMutation__
 *
 * To run a mutation, you first call `useUpdateJobsiteWorkerDocumentVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobsiteWorkerDocumentVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobsiteWorkerDocumentVersionMutation, { data, loading, error }] = useUpdateJobsiteWorkerDocumentVersionMutation({
 *   variables: {
 *      jobsiteWorkerDocumentVersionId: // value for 'jobsiteWorkerDocumentVersionId'
 *      jobsiteWorkerDocumentVersionInput: // value for 'jobsiteWorkerDocumentVersionInput'
 *   },
 * });
 */
export function useUpdateJobsiteWorkerDocumentVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateJobsiteWorkerDocumentVersionMutation,
    UpdateJobsiteWorkerDocumentVersionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateJobsiteWorkerDocumentVersionMutation,
    UpdateJobsiteWorkerDocumentVersionMutationVariables
  >(UpdateJobsiteWorkerDocumentVersionDocument, options);
}
export type UpdateJobsiteWorkerDocumentVersionMutationHookResult = ReturnType<
  typeof useUpdateJobsiteWorkerDocumentVersionMutation
>;
export type UpdateJobsiteWorkerDocumentVersionMutationResult =
  Apollo.MutationResult<UpdateJobsiteWorkerDocumentVersionMutation>;
export type UpdateJobsiteWorkerDocumentVersionMutationOptions = Apollo.BaseMutationOptions<
  UpdateJobsiteWorkerDocumentVersionMutation,
  UpdateJobsiteWorkerDocumentVersionMutationVariables
>;
export const UploadSingleFileDocument = gql`
  mutation UploadSingleFile($fileInput: FileInput!) {
    uploadSingleFile(fileInput: $fileInput) {
      fileId
      fileType
      downloadUrl
    }
  }
`;
export type UploadSingleFileMutationFn = Apollo.MutationFunction<
  UploadSingleFileMutation,
  UploadSingleFileMutationVariables
>;

/**
 * __useUploadSingleFileMutation__
 *
 * To run a mutation, you first call `useUploadSingleFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadSingleFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadSingleFileMutation, { data, loading, error }] = useUploadSingleFileMutation({
 *   variables: {
 *      fileInput: // value for 'fileInput'
 *   },
 * });
 */
export function useUploadSingleFileMutation(
  baseOptions?: Apollo.MutationHookOptions<UploadSingleFileMutation, UploadSingleFileMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadSingleFileMutation, UploadSingleFileMutationVariables>(
    UploadSingleFileDocument,
    options,
  );
}
export type UploadSingleFileMutationHookResult = ReturnType<typeof useUploadSingleFileMutation>;
export type UploadSingleFileMutationResult = Apollo.MutationResult<UploadSingleFileMutation>;
export type UploadSingleFileMutationOptions = Apollo.BaseMutationOptions<
  UploadSingleFileMutation,
  UploadSingleFileMutationVariables
>;
export const GetContractorsDocument = gql`
  query GetContractors($userContractorsInput: UserContractorsInput) {
    getCurrentSession {
      user {
        userAccountId
        contractors(input: $userContractorsInput) {
          edges {
            node {
              contractorId
              organization {
                name
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetContractorsQuery__
 *
 * To run a query within a React component, call `useGetContractorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractorsQuery({
 *   variables: {
 *      userContractorsInput: // value for 'userContractorsInput'
 *   },
 * });
 */
export function useGetContractorsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetContractorsQuery, GetContractorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetContractorsQuery, GetContractorsQueryVariables>(GetContractorsDocument, options);
}
export function useGetContractorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetContractorsQuery, GetContractorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetContractorsQuery, GetContractorsQueryVariables>(GetContractorsDocument, options);
}
export function useGetContractorsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetContractorsQuery, GetContractorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetContractorsQuery, GetContractorsQueryVariables>(GetContractorsDocument, options);
}
export type GetContractorsQueryHookResult = ReturnType<typeof useGetContractorsQuery>;
export type GetContractorsLazyQueryHookResult = ReturnType<typeof useGetContractorsLazyQuery>;
export type GetContractorsSuspenseQueryHookResult = ReturnType<typeof useGetContractorsSuspenseQuery>;
export type GetContractorsQueryResult = Apollo.QueryResult<GetContractorsQuery, GetContractorsQueryVariables>;
export const GetDevelopersDocument = gql`
  query GetDevelopers {
    getCurrentSession {
      user {
        userAccountId
        developers {
          count
          edges {
            node {
              developerId
              organization {
                organizationId
                name
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetDevelopersQuery__
 *
 * To run a query within a React component, call `useGetDevelopersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDevelopersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDevelopersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDevelopersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetDevelopersQuery, GetDevelopersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDevelopersQuery, GetDevelopersQueryVariables>(GetDevelopersDocument, options);
}
export function useGetDevelopersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDevelopersQuery, GetDevelopersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDevelopersQuery, GetDevelopersQueryVariables>(GetDevelopersDocument, options);
}
export function useGetDevelopersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetDevelopersQuery, GetDevelopersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetDevelopersQuery, GetDevelopersQueryVariables>(GetDevelopersDocument, options);
}
export type GetDevelopersQueryHookResult = ReturnType<typeof useGetDevelopersQuery>;
export type GetDevelopersLazyQueryHookResult = ReturnType<typeof useGetDevelopersLazyQuery>;
export type GetDevelopersSuspenseQueryHookResult = ReturnType<typeof useGetDevelopersSuspenseQuery>;
export type GetDevelopersQueryResult = Apollo.QueryResult<GetDevelopersQuery, GetDevelopersQueryVariables>;
export const GetJobsiteCardFormatsDocument = gql`
  query GetJobsiteCardFormats($jobsiteId: ID!, $cardType: WorkerCardType, $isDefaultForCardType: Boolean) {
    getJobsiteCardFormats(jobsiteId: $jobsiteId, cardType: $cardType, isDefaultForCardType: $isDefaultForCardType) {
      jobsiteId
      isDefaultForCardType
      workerCardFormat {
        workerCardFormatId
        name
        description
        cardType
        facilityCode
      }
    }
  }
`;

/**
 * __useGetJobsiteCardFormatsQuery__
 *
 * To run a query within a React component, call `useGetJobsiteCardFormatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobsiteCardFormatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobsiteCardFormatsQuery({
 *   variables: {
 *      jobsiteId: // value for 'jobsiteId'
 *      cardType: // value for 'cardType'
 *      isDefaultForCardType: // value for 'isDefaultForCardType'
 *   },
 * });
 */
export function useGetJobsiteCardFormatsQuery(
  baseOptions: Apollo.QueryHookOptions<GetJobsiteCardFormatsQuery, GetJobsiteCardFormatsQueryVariables> &
    ({ variables: GetJobsiteCardFormatsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetJobsiteCardFormatsQuery, GetJobsiteCardFormatsQueryVariables>(
    GetJobsiteCardFormatsDocument,
    options,
  );
}
export function useGetJobsiteCardFormatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetJobsiteCardFormatsQuery, GetJobsiteCardFormatsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetJobsiteCardFormatsQuery, GetJobsiteCardFormatsQueryVariables>(
    GetJobsiteCardFormatsDocument,
    options,
  );
}
export function useGetJobsiteCardFormatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetJobsiteCardFormatsQuery, GetJobsiteCardFormatsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetJobsiteCardFormatsQuery, GetJobsiteCardFormatsQueryVariables>(
    GetJobsiteCardFormatsDocument,
    options,
  );
}
export type GetJobsiteCardFormatsQueryHookResult = ReturnType<typeof useGetJobsiteCardFormatsQuery>;
export type GetJobsiteCardFormatsLazyQueryHookResult = ReturnType<typeof useGetJobsiteCardFormatsLazyQuery>;
export type GetJobsiteCardFormatsSuspenseQueryHookResult = ReturnType<typeof useGetJobsiteCardFormatsSuspenseQuery>;
export type GetJobsiteCardFormatsQueryResult = Apollo.QueryResult<
  GetJobsiteCardFormatsQuery,
  GetJobsiteCardFormatsQueryVariables
>;
export const GetJobsiteInvitationDocument = gql`
  query GetJobsiteInvitation($jobsiteInvitationId: ID!) {
    getJobsiteInvitation(jobsiteInvitationId: $jobsiteInvitationId) {
      jobsiteInvitationId
      jobsiteContractor {
        jobsite {
          jobsiteId
          name
          jobsiteCardFormats {
            count
            edges {
              node {
                workerCardFormat {
                  cardType
                  facilityCode
                  name
                  workerCardFormatId
                }
              }
            }
          }
          modules {
            ... on JobsiteOnboardingModule {
              name
              steps {
                key
                index
                isRequired
                isVisible
                fields {
                  key
                  name
                  index
                  isRequired
                  isHidden
                }
              }
              documents {
                key
                name
                index
                isRequired
                isVisible
                expiration
                gracePeriod
                fields {
                  key
                  name
                  index
                  isRequired
                  isHidden
                }
              }
            }
            ... on JobsitePreOnboardingModule {
              name
              steps {
                key
                name
                index
                isRequired
                isVisible
                fields {
                  key
                  name
                  index
                  isRequired
                  isHidden
                }
              }
            }
            ... on JobsiteFeaturesModule {
              name
              slug
              workerAppEnabled
              doNotAllowToSkipSelfOnboardingSteps
              enforceValidContractorInSelfOnboarding
            }
          }
          documentTypes {
            edges {
              node {
                id
                workerDocumentType {
                  workerDocumentTypeId
                  key
                }
                files {
                  fileId
                  fileType
                  downloadUrl
                  language
                  title
                }
              }
            }
          }
          jobsiteContractors {
            edges {
              node {
                id
                startDate
                contractor {
                  contractorId
                  isDefaultContractor
                  organization {
                    organizationId
                    name
                  }
                }
              }
            }
          }
        }
        contractor {
          contractorId
          isDefaultContractor
          organization {
            organizationId
            name
          }
        }
      }
    }
  }
`;

/**
 * __useGetJobsiteInvitationQuery__
 *
 * To run a query within a React component, call `useGetJobsiteInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobsiteInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobsiteInvitationQuery({
 *   variables: {
 *      jobsiteInvitationId: // value for 'jobsiteInvitationId'
 *   },
 * });
 */
export function useGetJobsiteInvitationQuery(
  baseOptions: Apollo.QueryHookOptions<GetJobsiteInvitationQuery, GetJobsiteInvitationQueryVariables> &
    ({ variables: GetJobsiteInvitationQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetJobsiteInvitationQuery, GetJobsiteInvitationQueryVariables>(
    GetJobsiteInvitationDocument,
    options,
  );
}
export function useGetJobsiteInvitationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetJobsiteInvitationQuery, GetJobsiteInvitationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetJobsiteInvitationQuery, GetJobsiteInvitationQueryVariables>(
    GetJobsiteInvitationDocument,
    options,
  );
}
export function useGetJobsiteInvitationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetJobsiteInvitationQuery, GetJobsiteInvitationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetJobsiteInvitationQuery, GetJobsiteInvitationQueryVariables>(
    GetJobsiteInvitationDocument,
    options,
  );
}
export type GetJobsiteInvitationQueryHookResult = ReturnType<typeof useGetJobsiteInvitationQuery>;
export type GetJobsiteInvitationLazyQueryHookResult = ReturnType<typeof useGetJobsiteInvitationLazyQuery>;
export type GetJobsiteInvitationSuspenseQueryHookResult = ReturnType<typeof useGetJobsiteInvitationSuspenseQuery>;
export type GetJobsiteInvitationQueryResult = Apollo.QueryResult<
  GetJobsiteInvitationQuery,
  GetJobsiteInvitationQueryVariables
>;
export const GetJobsiteWithBadgeTemplatesDocument = gql`
  query GetJobsiteWithBadgeTemplates($jobsiteId: ID!) {
    getJobsite(jobsiteId: $jobsiteId) {
      jobsiteBadgeTemplates {
        edges {
          node {
            id
            isDefault
            badgeTemplate {
              id
              templateName
              displayName
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetJobsiteWithBadgeTemplatesQuery__
 *
 * To run a query within a React component, call `useGetJobsiteWithBadgeTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobsiteWithBadgeTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobsiteWithBadgeTemplatesQuery({
 *   variables: {
 *      jobsiteId: // value for 'jobsiteId'
 *   },
 * });
 */
export function useGetJobsiteWithBadgeTemplatesQuery(
  baseOptions: Apollo.QueryHookOptions<GetJobsiteWithBadgeTemplatesQuery, GetJobsiteWithBadgeTemplatesQueryVariables> &
    ({ variables: GetJobsiteWithBadgeTemplatesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetJobsiteWithBadgeTemplatesQuery, GetJobsiteWithBadgeTemplatesQueryVariables>(
    GetJobsiteWithBadgeTemplatesDocument,
    options,
  );
}
export function useGetJobsiteWithBadgeTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetJobsiteWithBadgeTemplatesQuery,
    GetJobsiteWithBadgeTemplatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetJobsiteWithBadgeTemplatesQuery, GetJobsiteWithBadgeTemplatesQueryVariables>(
    GetJobsiteWithBadgeTemplatesDocument,
    options,
  );
}
export function useGetJobsiteWithBadgeTemplatesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetJobsiteWithBadgeTemplatesQuery,
    GetJobsiteWithBadgeTemplatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetJobsiteWithBadgeTemplatesQuery, GetJobsiteWithBadgeTemplatesQueryVariables>(
    GetJobsiteWithBadgeTemplatesDocument,
    options,
  );
}
export type GetJobsiteWithBadgeTemplatesQueryHookResult = ReturnType<typeof useGetJobsiteWithBadgeTemplatesQuery>;
export type GetJobsiteWithBadgeTemplatesLazyQueryHookResult = ReturnType<
  typeof useGetJobsiteWithBadgeTemplatesLazyQuery
>;
export type GetJobsiteWithBadgeTemplatesSuspenseQueryHookResult = ReturnType<
  typeof useGetJobsiteWithBadgeTemplatesSuspenseQuery
>;
export type GetJobsiteWithBadgeTemplatesQueryResult = Apollo.QueryResult<
  GetJobsiteWithBadgeTemplatesQuery,
  GetJobsiteWithBadgeTemplatesQueryVariables
>;
export const GetJobsitesDocument = gql`
  query GetJobsites($userJobsitesInput: UserJobsitesInput!) {
    getCurrentSession {
      user {
        userAccountId
        roles {
          key
          displayName
        }
        jobsites(input: $userJobsitesInput) {
          edges {
            node {
              jobsiteId
              name
              parentId
              startDate
              workerAccessEnabled
              avigilonEnabled
              clearanceAssignmentEnabled
              timeZone
              featuresModule {
                announcementsEnabled
                archivingAccessEventsEnabled
              }
              developer {
                developerId
                name
              }
              jobsiteCardFormats {
                edges {
                  node {
                    jobsiteCardFormatId
                    isDefaultForCardType
                    workerCardFormat {
                      workerCardFormatId
                      name
                      description
                      cardType
                      facilityCode
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetJobsitesQuery__
 *
 * To run a query within a React component, call `useGetJobsitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobsitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobsitesQuery({
 *   variables: {
 *      userJobsitesInput: // value for 'userJobsitesInput'
 *   },
 * });
 */
export function useGetJobsitesQuery(
  baseOptions: Apollo.QueryHookOptions<GetJobsitesQuery, GetJobsitesQueryVariables> &
    ({ variables: GetJobsitesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetJobsitesQuery, GetJobsitesQueryVariables>(GetJobsitesDocument, options);
}
export function useGetJobsitesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetJobsitesQuery, GetJobsitesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetJobsitesQuery, GetJobsitesQueryVariables>(GetJobsitesDocument, options);
}
export function useGetJobsitesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetJobsitesQuery, GetJobsitesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetJobsitesQuery, GetJobsitesQueryVariables>(GetJobsitesDocument, options);
}
export type GetJobsitesQueryHookResult = ReturnType<typeof useGetJobsitesQuery>;
export type GetJobsitesLazyQueryHookResult = ReturnType<typeof useGetJobsitesLazyQuery>;
export type GetJobsitesSuspenseQueryHookResult = ReturnType<typeof useGetJobsitesSuspenseQuery>;
export type GetJobsitesQueryResult = Apollo.QueryResult<GetJobsitesQuery, GetJobsitesQueryVariables>;
export const GetRolesDocument = gql`
  query GetRoles {
    getRoles {
      key
      displayName
      isSuper
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetRolesQuery__
 *
 * To run a query within a React component, call `useGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
}
export function useGetRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
}
export function useGetRolesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
}
export type GetRolesQueryHookResult = ReturnType<typeof useGetRolesQuery>;
export type GetRolesLazyQueryHookResult = ReturnType<typeof useGetRolesLazyQuery>;
export type GetRolesSuspenseQueryHookResult = ReturnType<typeof useGetRolesSuspenseQuery>;
export type GetRolesQueryResult = Apollo.QueryResult<GetRolesQuery, GetRolesQueryVariables>;
export const GetWorkerCardDocument = gql`
  query GetWorkerCard($workerCardId: ID, $cardNumber: String, $cardType: WorkerCardType, $includeArchived: Boolean) {
    getWorkerCard(
      workerCardId: $workerCardId
      cardNumber: $cardNumber
      cardType: $cardType
      includeArchived: $includeArchived
    ) {
      workerCardId
      cardNumber
      worker {
        workerId
        user {
          identity {
            phoneNumber
          }
        }
      }
      workerCardFormat {
        name
        workerCardFormatId
        cardType
        facilityCode
      }
    }
  }
`;

/**
 * __useGetWorkerCardQuery__
 *
 * To run a query within a React component, call `useGetWorkerCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkerCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkerCardQuery({
 *   variables: {
 *      workerCardId: // value for 'workerCardId'
 *      cardNumber: // value for 'cardNumber'
 *      cardType: // value for 'cardType'
 *      includeArchived: // value for 'includeArchived'
 *   },
 * });
 */
export function useGetWorkerCardQuery(
  baseOptions?: Apollo.QueryHookOptions<GetWorkerCardQuery, GetWorkerCardQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWorkerCardQuery, GetWorkerCardQueryVariables>(GetWorkerCardDocument, options);
}
export function useGetWorkerCardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWorkerCardQuery, GetWorkerCardQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWorkerCardQuery, GetWorkerCardQueryVariables>(GetWorkerCardDocument, options);
}
export function useGetWorkerCardSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetWorkerCardQuery, GetWorkerCardQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetWorkerCardQuery, GetWorkerCardQueryVariables>(GetWorkerCardDocument, options);
}
export type GetWorkerCardQueryHookResult = ReturnType<typeof useGetWorkerCardQuery>;
export type GetWorkerCardLazyQueryHookResult = ReturnType<typeof useGetWorkerCardLazyQuery>;
export type GetWorkerCardSuspenseQueryHookResult = ReturnType<typeof useGetWorkerCardSuspenseQuery>;
export type GetWorkerCardQueryResult = Apollo.QueryResult<GetWorkerCardQuery, GetWorkerCardQueryVariables>;
export const GetWorkerCardsDocument = gql`
  query GetWorkerCards($workerId: ID!, $includeArchived: Boolean) {
    getWorkerCards(workerId: $workerId, includeArchived: $includeArchived) {
      workerCardId
      cardNumber
      matchingWorkerCardId
      cardStatus
      isTemporary
      expiredAt
      issuedAt
      worker {
        workerId
        user {
          identity {
            phoneNumber
          }
        }
      }
      workerCardFormat {
        name
        workerCardFormatId
        cardType
        facilityCode
      }
    }
  }
`;

/**
 * __useGetWorkerCardsQuery__
 *
 * To run a query within a React component, call `useGetWorkerCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkerCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkerCardsQuery({
 *   variables: {
 *      workerId: // value for 'workerId'
 *      includeArchived: // value for 'includeArchived'
 *   },
 * });
 */
export function useGetWorkerCardsQuery(
  baseOptions: Apollo.QueryHookOptions<GetWorkerCardsQuery, GetWorkerCardsQueryVariables> &
    ({ variables: GetWorkerCardsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWorkerCardsQuery, GetWorkerCardsQueryVariables>(GetWorkerCardsDocument, options);
}
export function useGetWorkerCardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWorkerCardsQuery, GetWorkerCardsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWorkerCardsQuery, GetWorkerCardsQueryVariables>(GetWorkerCardsDocument, options);
}
export function useGetWorkerCardsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetWorkerCardsQuery, GetWorkerCardsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetWorkerCardsQuery, GetWorkerCardsQueryVariables>(GetWorkerCardsDocument, options);
}
export type GetWorkerCardsQueryHookResult = ReturnType<typeof useGetWorkerCardsQuery>;
export type GetWorkerCardsLazyQueryHookResult = ReturnType<typeof useGetWorkerCardsLazyQuery>;
export type GetWorkerCardsSuspenseQueryHookResult = ReturnType<typeof useGetWorkerCardsSuspenseQuery>;
export type GetWorkerCardsQueryResult = Apollo.QueryResult<GetWorkerCardsQuery, GetWorkerCardsQueryVariables>;
export const GetAppLayoutCurrentSessionDocument = gql`
  query GetAppLayoutCurrentSession {
    getCurrentSession {
      user {
        userAccountId
        roles {
          key
        }
      }
      session {
        urlLock
        appVersion
      }
    }
  }
`;

/**
 * __useGetAppLayoutCurrentSessionQuery__
 *
 * To run a query within a React component, call `useGetAppLayoutCurrentSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppLayoutCurrentSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppLayoutCurrentSessionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAppLayoutCurrentSessionQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAppLayoutCurrentSessionQuery, GetAppLayoutCurrentSessionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAppLayoutCurrentSessionQuery, GetAppLayoutCurrentSessionQueryVariables>(
    GetAppLayoutCurrentSessionDocument,
    options,
  );
}
export function useGetAppLayoutCurrentSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAppLayoutCurrentSessionQuery, GetAppLayoutCurrentSessionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAppLayoutCurrentSessionQuery, GetAppLayoutCurrentSessionQueryVariables>(
    GetAppLayoutCurrentSessionDocument,
    options,
  );
}
export function useGetAppLayoutCurrentSessionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAppLayoutCurrentSessionQuery,
    GetAppLayoutCurrentSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetAppLayoutCurrentSessionQuery, GetAppLayoutCurrentSessionQueryVariables>(
    GetAppLayoutCurrentSessionDocument,
    options,
  );
}
export type GetAppLayoutCurrentSessionQueryHookResult = ReturnType<typeof useGetAppLayoutCurrentSessionQuery>;
export type GetAppLayoutCurrentSessionLazyQueryHookResult = ReturnType<typeof useGetAppLayoutCurrentSessionLazyQuery>;
export type GetAppLayoutCurrentSessionSuspenseQueryHookResult = ReturnType<
  typeof useGetAppLayoutCurrentSessionSuspenseQuery
>;
export type GetAppLayoutCurrentSessionQueryResult = Apollo.QueryResult<
  GetAppLayoutCurrentSessionQuery,
  GetAppLayoutCurrentSessionQueryVariables
>;
export const UpdateSessionAppVersionDocument = gql`
  mutation UpdateSessionAppVersion($appVersion: String!) {
    updateSessionAppVersion(appVersion: $appVersion) {
      session {
        authType
        createdAt
        updatedAt
        lastActiveAt
        appVersion
      }
    }
  }
`;
export type UpdateSessionAppVersionMutationFn = Apollo.MutationFunction<
  UpdateSessionAppVersionMutation,
  UpdateSessionAppVersionMutationVariables
>;

/**
 * __useUpdateSessionAppVersionMutation__
 *
 * To run a mutation, you first call `useUpdateSessionAppVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSessionAppVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSessionAppVersionMutation, { data, loading, error }] = useUpdateSessionAppVersionMutation({
 *   variables: {
 *      appVersion: // value for 'appVersion'
 *   },
 * });
 */
export function useUpdateSessionAppVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSessionAppVersionMutation, UpdateSessionAppVersionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSessionAppVersionMutation, UpdateSessionAppVersionMutationVariables>(
    UpdateSessionAppVersionDocument,
    options,
  );
}
export type UpdateSessionAppVersionMutationHookResult = ReturnType<typeof useUpdateSessionAppVersionMutation>;
export type UpdateSessionAppVersionMutationResult = Apollo.MutationResult<UpdateSessionAppVersionMutation>;
export type UpdateSessionAppVersionMutationOptions = Apollo.BaseMutationOptions<
  UpdateSessionAppVersionMutation,
  UpdateSessionAppVersionMutationVariables
>;
