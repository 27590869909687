import React, { ReactElement } from 'react';
import { Button, SortingRule, Table, TableContainer } from '@odin-labs/components';
import {
  GetUserRolesOrderByField,
  UserRoleKey,
  useGetRolesQuery,
  useGetWorkerUserRolesQuery,
} from 'apollo/generated/client-operations';
import { AuthContext } from 'auth';
import { LockedWorkerAlert } from 'containers/worker/tabs/LockedWorkerAlert';
import { WorkerTabProps } from 'containers/worker/types';
import { PlusIcon } from 'components/icons';
import { NewHeader } from 'components/header/NewHeader';
import { useModalChangeActions } from 'utils/useModalChangeActions';
import { Editable } from 'types';
import { AddUserRoleModal, EditUserRoleModal, RemoveUserRoleModal } from 'containers/users/modals';
import { useAvailableJobsites } from 'graphql/client/useAvailableJobsites';
import { getUserRoleType, getUserRoleTypes } from 'containers/users/modals/utils';
import { LoadingError } from 'components/loadingError';
import { usePageQueryParams } from 'utils/usePageQueryParams';
import { useQueryOrderBy } from 'utils';
import { SortOrder } from 'utils/useQueryOrderBy';
import { getColumns, orderByFields } from './tables';
import { WorkerUserRole } from './types';

export function WorkerUserRoles(props: WorkerTabProps): ReactElement {
  const { worker, isLocked, refetchWorkerData, onTabApiChange } = props;

  const {
    orderBy: defaultSortField,
    orderByDesc: isDescending,
    updateUrl,
    loading: isUrlLoading,
  } = usePageQueryParams();

  const { tableSortInfo, orderBy, setNewOrderBy } = useQueryOrderBy<string, GetUserRolesOrderByField>(
    defaultSortField,
    isDescending,
    (tableField: string): GetUserRolesOrderByField => orderByFields[tableField],
  );

  const tableSortBy: Array<SortingRule<string>> = tableSortInfo?.dataField
    ? [{ id: tableSortInfo.dataField, desc: tableSortInfo.order === 'desc' }]
    : [];

  const { change, closeModal, openAddModal, openEditModal, openDeleteModal } =
    useModalChangeActions<Editable<WorkerUserRole>>();

  const { data, loading, error, refetch } = useGetWorkerUserRolesQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip: isUrlLoading || !worker || worker?.isArchived || isLocked,
    variables: {
      workerId: worker.workerId,
      userRolesInput: { orderBy },
    },
  });

  const workerUserRoles = data?.getWorker.user.roles;
  const workerUserRolesCount = workerUserRoles?.length;

  const { currentUser: user } = React.useContext(AuthContext);

  const { data: rolesData } = useGetRolesQuery({ fetchPolicy: 'network-only' });
  const userRoles = rolesData?.getRoles.filter(({ key }) => key !== UserRoleKey.Worker);

  const columns = React.useMemo(() => getColumns({ openEditModal, openDeleteModal }), [openEditModal, openDeleteModal]);
  const assignedObjectIds = React.useMemo(() => workerUserRoles?.map((ur) => ur.object.objectId), [workerUserRoles]);

  const closeModalAndRefreshData = (): void => {
    if (user.userAccountId === worker?.user?.userAccountId) {
      useAvailableJobsites.resetCacheOnNextCall();
    }
    closeModal();
    refetch();
  };

  React.useEffect(() => onTabApiChange({ refetchData: refetchWorkerData }), [refetchWorkerData]);

  if (error) {
    return <LoadingError error={error} />;
  }

  if (isLocked) {
    return <LockedWorkerAlert worker={worker} />;
  }

  const onSortByChangeHandler = (sortBy: Array<SortingRule<WorkerUserRole>>): void => {
    const [firstSortBy] = sortBy ?? [];
    const sortField = firstSortBy?.id ?? null;
    const sortOrder: SortOrder = firstSortBy?.desc ? 'desc' : 'asc';
    const newOrderBy = sortField && { field: sortField, order: sortOrder };
    setNewOrderBy(newOrderBy);
    updateUrl({
      orderBy: sortField,
      orderByDesc: sortField && sortOrder === 'desc' ? true : null,
    });
  };

  const { userAccountId } = worker?.user ?? {};

  const { objectMemberId, title, name: memberUserAssignmentObjectName } = change?.item?.object?.objectDetails ?? {};

  return (
    <>
      <TableContainer>
        <NewHeader
          size="md"
          title="User Roles"
          titleInfo={workerUserRolesCount}
          actionsProps={{
            headerActions: null,
            children: (
              <Button
                icon={PlusIcon}
                text="Add User Role"
                hideTextOnMobile
                size={['base', 'md:sm']}
                onClick={openAddModal}
              />
            ),
          }}
        />
        <Table
          loading={loading}
          columns={columns}
          data={workerUserRoles}
          initialState={{ sortBy: tableSortBy }}
          onSortByChange={onSortByChangeHandler}
          cellClassName="!odin-pl-5"
          remote
          disableGlobalFilter
        />
      </TableContainer>
      <RemoveUserRoleModal
        isOpen={change?.type === 'remove'}
        onCancel={closeModal}
        onConfirm={closeModalAndRefreshData}
        userRoleType={getUserRoleType(change?.item?.key)}
        memberUserAccountId={userAccountId}
        memberUserAssignmentId={objectMemberId}
      />
      <AddUserRoleModal
        isOpen={change?.type === 'create'}
        onCancel={closeModal}
        onConfirm={closeModalAndRefreshData}
        workerId={worker.workerId}
        userRoleTypes={getUserRoleTypes(workerUserRoles, user.isAdmin)}
        userRoles={userRoles}
        memberUserAccountId={userAccountId}
        assignedObjectIds={assignedObjectIds}
      />
      <EditUserRoleModal
        isOpen={change?.type === 'update'}
        onCancel={closeModal}
        onConfirm={closeModalAndRefreshData}
        workerId={worker.workerId}
        userRoleType={getUserRoleType(change?.item?.key)}
        userRoles={userRoles}
        memberUserAccountId={userAccountId}
        memberUserAssignmentId={objectMemberId}
        memberUserAssignmentObjectId={change?.item?.object?.objectId}
        memberUserAssignmentObjectName={memberUserAssignmentObjectName}
        title={title}
        userRoleKey={change?.item?.key}
      />
    </>
  );
}
