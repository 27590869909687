import cn from 'classnames';
import { getCurrentDomain } from 'utils';
import { SidebarLayout, SidebarProps } from './types';

const environments = ['prd', 'demo', 'stg', 'qa', 'dev', 'local'] as const;
export type Env = typeof environments[number];

export const getEnv = (): Env => {
  const domain = getCurrentDomain();
  return environments.find((env) => domain.includes(`.${env}.`)) ?? 'prd';
};

const sidebarClasses: Record<SidebarLayout | 'default', { item: string; header: string; footer: string }> = {
  default: {
    item: cn(
      'odin-group odin-flex odin-items-center odin-rounded-md',
      'hover:odin-text-black hover:odin-transition-all hover:odin-duration-300',
    ),
    header: cn('odin-flex odin-items-center odin-shrink-0'),
    footer: cn('odin-flex odin-justify-center odin-px-6 odin-py-5 odin-text-white odin-opacity-60'),
  },
  desktop: {
    item: cn('odin-p-3 odin-text-base'),
    header: cn('odin-px-2'),
    footer: '',
  },
  tablet: {
    item: cn('odin-flex-col odin-p-2'),
    header: cn('odin-px-3'),
    footer: '',
  },
  mobile: {
    item: cn('odin-p-3 odin-text-base'),
    header: cn('odin-px-2'),
    footer: '',
  },
};

const backgroundClasses: Record<'default' | Env, string> = {
  default: cn('odin-bg-odin-env-prod'),
  prd: cn('odin-bg-odin-env-prod'),
  demo: cn('odin-bg-odin-env-demo'),
  stg: cn('odin-bg-odin-env-stg'),
  qa: cn('odin-bg-odin-env-qa'),
  dev: cn('odin-bg-odin-env-dev'),
  local: cn('odin-bg-odin-env-local'),
};

const iconClasses: Record<'default' | Env, string> = {
  default: cn('odin-text-odin-primary'),
  prd: cn('odin-text-odin-primary'),
  demo: cn('odin-text-odin-primary'),
  stg: cn('odin-text-green-600'),
  qa: cn('odin-text-yellow-600'),
  dev: cn('odin-text-odin-env-dev'),
  local: cn('odin-text-odin-env-local'),
};

const getItemClassesFn = (
  colorClass: string = cn('odin-text-white'),
  bgColor: string = cn('odin-bg-[#F0F0FF]'),
  bgHoverClass: string = cn('hover:odin-bg-[#F0F0FF]'),
): SidebarProps['classes']['item'] => {
  return (isActive: boolean, layout: SidebarLayout): string =>
    cn(
      sidebarClasses.default.item,
      sidebarClasses[layout].item,
      // colorClass,
      isActive ? 'odin-text-black' : colorClass,
      isActive ? bgColor : ['odin-opacity-60', 'hover:odin-opacity-70', bgHoverClass],
    );
};

const headerClassesFn = (layout: SidebarLayout): string => {
  return cn(sidebarClasses.default.header, sidebarClasses[layout].header);
};

const footerClassesFn = (layout: SidebarLayout): string => {
  return cn(sidebarClasses.default.footer, sidebarClasses[layout].footer);
};

export const getClasses = (): SidebarProps['classes'] => {
  const env = getEnv();
  return {
    background: backgroundClasses[env] ?? backgroundClasses.default,
    item: getItemClassesFn(),
    icon: (isActive: boolean): string => cn(isActive && (iconClasses[env] ?? iconClasses.default)),
    header: headerClassesFn,
    footer: footerClassesFn,
  };
};
