import { SelectOptionElement } from '@odin-labs/components';
import { AnnouncementStatus, AnnouncementType } from 'apollo/generated/client-operations';
import { getSelectOptions } from 'components/utils';
import { CreateAnnouncementData } from './types';

export const announcementTypeOptions: SelectOptionElement<AnnouncementType>[] = [
  { label: 'SMS', value: AnnouncementType.Sms },
  { label: 'Email', value: AnnouncementType.Email },
];

export const announcementStatusOptions = getSelectOptions<AnnouncementStatus>({
  values: Object.values(AnnouncementStatus),
  labelGetter: null, // pass values as labels
});

export const emptyAnnouncementData: CreateAnnouncementData = {
  announcementType: AnnouncementType.Sms,
  subject: undefined,
  message: undefined,
  status: undefined,
  filterJobsiteIds: [],
  filterContractorIds: [],
  filterJobsiteAccessStatus: undefined,
  filterAccessWithinDayRange: undefined,
  filterTrades: [],
  filterTitles: [],
  timeZone: undefined,
};

export const defaultFromExisting = <T>(options: SelectOptionElement<T>[], values: T[]): SelectOptionElement<T>[] => {
  return options?.length === 1 ? [options[0]] : options?.filter((o) => values?.includes(o.value)) ?? null;
};
