import React from 'react';
import { Dialog } from 'dialog';
import { getTempId } from 'utils';
import { EditableEnhancedWorker } from 'containers/jobsiteFormSubmission/components/formSubmissionEditableWorkers/types';
import { FormSubmissionWorkerEditSearch } from 'containers/jobsiteFormSubmission/components/formSubmissionWorkerEditSearch';
import { AddFormSubmissionWorkerModalProps } from './types';

export function AddFormSubmissionWorkerModal(props: AddFormSubmissionWorkerModalProps): React.ReactElement {
  const { isOpen, closeModal, onConfirm, jobsiteId, jobsiteFormSubmissionId, associationType } = props;

  return (
    <Dialog id="editFormSubmissionWorker" open={isOpen} setOpen={closeModal} title="Add Worker" actionsPanel={null}>
      <FormSubmissionWorkerEditSearch
        jobsiteFormSubmissionId={jobsiteFormSubmissionId}
        onSelected={(searchWorkerResult): void => {
          const { jobsitesInfo } = searchWorkerResult;
          const jobsiteWorker =
            jobsitesInfo.find((ji) => ji.jobsiteWorker.jobsiteContractor.jobsite.jobsiteId === jobsiteId)
              ?.jobsiteWorker ?? jobsitesInfo[0]?.jobsiteWorker;

          const jfsWorker: EditableEnhancedWorker = {
            id: getTempId(),
            jobsiteWorker,
            changeType: 'created',
            associationType,
          };

          onConfirm(jfsWorker, 'create');
        }}
      />
    </Dialog>
  );
}
