import { DeepMap } from 'react-hook-form';
import { SelectOptionElement } from '@odin-labs/components';
import { UserAssignmentType, UserCreateInput, UserRoleKey } from 'apollo/generated/client-operations';
import { EnvelopeIcon, WrenchIcon, BriefcaseIcon } from 'components/icons';
import { FormInputTypes, GridColSpan, TypedFormInputs, getUpdateInputValueFunction } from 'components/form';
import { emailValidation, phoneNumberValidation } from 'utils/validation';
import { ensureNonUndefinedFields, getPhoneNumberAsE164 } from 'utils';
import { AddUserFormData, UserRoleType } from './types';

export type AddUserInputsArgs = {
  userRolesOptions: SelectOptionElement<UserRoleKey>[];
  contractorsOptions: SelectOptionElement[];
  developersOptions: SelectOptionElement[];
  jobsitesOptions: SelectOptionElement[];
  userRoleType?: UserRoleType;
};

export const getFormInputs = ({
  userRoleType,
  userRolesOptions,
  jobsitesOptions,
  contractorsOptions,
  developersOptions,
}: AddUserInputsArgs): TypedFormInputs<AddUserFormData> => ({
  contractorId: {
    element: FormInputTypes.Select,
    label: 'Contractor',
    elementProps: {
      placeholder: 'Select contractor',
      options: contractorsOptions,
      disabled: contractorsOptions?.length === 1,
      icon: WrenchIcon,
    },
    validation: {
      required: userRoleType === UserAssignmentType.Contractor,
    },
    layout: userRoleType === UserAssignmentType.Contractor ? GridColSpan.SpanFull : 'odin-hidden',
  },
  developerId: {
    element: FormInputTypes.Select,
    label: 'Client',
    elementProps: {
      placeholder: 'Select Client',
      options: developersOptions,
      disabled: developersOptions?.length === 1,
      icon: WrenchIcon,
    },
    validation: {
      required: userRoleType === UserAssignmentType.Developer,
    },
    layout: userRoleType === UserAssignmentType.Developer ? GridColSpan.SpanFull : 'odin-hidden',
  },
  jobsiteIds: {
    element: FormInputTypes.Select,
    label: 'Jobsite',
    elementProps: {
      options: jobsitesOptions,
      disabled: jobsitesOptions?.length === 1,
      isMulti: true,
      clearToNull: true,
      icon: BriefcaseIcon,
    },
    validation: {
      required: userRoleType === UserAssignmentType.Jobsite,
    },
    layout: userRoleType === UserAssignmentType.Jobsite ? GridColSpan.SpanFull : 'odin-hidden',
  },
  firstName: {
    element: FormInputTypes.Field,
    label: 'First Name',
    validation: {
      required: true,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
  },
  lastName: {
    element: FormInputTypes.Field,
    label: 'Last Name',
    validation: {
      required: true,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
  },
  email: {
    element: FormInputTypes.Field,
    label: 'Email Address',
    elementProps: {
      icon: EnvelopeIcon,
    },
    validation: {
      required: true,
      pattern: emailValidation,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
  },
  phoneNumber: {
    element: FormInputTypes.Field,
    label: 'Phone Number',
    elementProps: {
      fieldType: 'phone',
      showDefaultIcon: true,
    },
    validation: {
      required: true,
      pattern: phoneNumberValidation,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
  },
  userRoleKey: {
    element: FormInputTypes.Select,
    label: 'User Role',
    elementProps: {
      options: userRolesOptions,
      disabled: (userRolesOptions?.length ?? 0) < 2,
    },
    validation: {
      required: true,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
  },
  title: {
    element: FormInputTypes.Field,
    label: 'Job Title',
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan3],
  },
});

export const getDefaultValues = ({
  userRolesOptions,
  jobsitesOptions,
  contractorsOptions,
  developersOptions,
}: {
  userRolesOptions: SelectOptionElement<UserRoleKey>[];
  jobsitesOptions: SelectOptionElement[];
  contractorsOptions: SelectOptionElement[];
  developersOptions: SelectOptionElement[];
}): AddUserFormData => ({
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  title: '',
  userRoleKey: userRolesOptions?.length === 1 ? userRolesOptions[0] : null,
  contractorId: contractorsOptions?.length === 1 ? contractorsOptions[0] : null,
  developerId: developersOptions?.length === 1 ? developersOptions[0] : null,
  jobsiteIds: jobsitesOptions?.length === 1 ? jobsitesOptions : null,
});

export const getAddInput = (
  userAssignmentType: UserAssignmentType,
  data: AddUserFormData,
  dirtyFields: DeepMap<AddUserFormData, true>,
): UserCreateInput => {
  const getUpdateInputValue = getUpdateInputValueFunction(data, dirtyFields);

  const phoneInputValue = dirtyFields.phoneNumber ? getPhoneNumberAsE164(data.phoneNumber) || null : undefined;

  let userAssignmentObjectIds: string[] = [];
  switch (userAssignmentType) {
    case UserAssignmentType.Contractor:
      userAssignmentObjectIds = [getUpdateInputValue('contractorId', true)];
      break;
    case UserAssignmentType.Jobsite:
      userAssignmentObjectIds = getUpdateInputValue('jobsiteIds', true);
      break;
    case UserAssignmentType.Developer:
      userAssignmentObjectIds = [getUpdateInputValue('developerId', true)];
      break;
    default:
      userAssignmentObjectIds = [];
      break;
  }

  return ensureNonUndefinedFields<UserCreateInput>({
    userInviteInput: {
      firstName: getUpdateInputValue('firstName'),
      lastName: getUpdateInputValue('lastName'),
      email: getUpdateInputValue('email'),
      phoneNumber: phoneInputValue,
    },
    userAssignmentInput: {
      userRoleKey: getUpdateInputValue('userRoleKey', true),
      title: getUpdateInputValue('title'),
      userAssignmentType,
      userAssignmentObjectIds,
    },
  });
};
