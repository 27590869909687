import { ElasticFacetEntry } from 'apollo/generated/client-operations';

export function bucketizeEntries(facetEntries: ElasticFacetEntry[], numBuckets: number): ElasticFacetEntry[][] {
  // Find the min and max values in the data
  const counts = facetEntries.map((e) => e.count);
  const min = Math.min(...counts);
  const max = Math.max(...counts);

  // Calculate the range of each bucket
  const bucketSize = (max - min) / numBuckets;

  // Create an array of buckets
  const buckets: ElasticFacetEntry[][] = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < numBuckets; i++) {
    buckets.push([]);
  }

  // Assign each data point to its corresponding bucket
  facetEntries.forEach((e) => {
    const value = e.count;
    let bucketIndex = Math.floor((value - min) / bucketSize);

    // Handle the edge case where the value is equal to max
    if (bucketIndex === numBuckets) {
      // eslint-disable-next-line no-plusplus
      bucketIndex--;
    }

    buckets[bucketIndex].push(e);
  });
  return buckets;
}

export function getBucketRanges(counts: number[], numBuckets: number): number[][] {
  // Find the min and max values in the data
  const min = Math.min(...counts);
  const max = Math.max(...counts);

  // Calculate the range of each bucket
  const bucketSize = (max - min) / numBuckets;

  // Create an array of bucket ranges
  const buckets: number[][] = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < numBuckets; i++) {
    buckets.push([i * bucketSize, (i + 1) * bucketSize]);
  }
  return buckets;
}

export function inBucketRange(value: number, bucketRange: number[]): boolean {
  return value >= bucketRange[0] && value < bucketRange[1];
}
