import moment from 'moment';
import { DeepMap } from 'react-hook-form';
import { SelectOptionElement } from '@odin-labs/components';
import { JobsiteUpdateInput } from 'apollo/generated/client-operations';
import { FormInputTypes, getUpdateInputValueFunction, GridColSpan, TypedFormInputs } from 'components/form';
import { fillJobsiteAddressDetails } from 'components/placesAutocomplete/utils';
import { EditJobsiteConfigurationFormData, Jobsite } from 'containers/jobsiteConfiguration/types';
import { nullifyEmptyFields, stringifyEmptyFields } from 'utils';
import { statesOptions, timeZonesOptions } from 'utils/constants';
import { zipCodeValidation } from 'utils/validation';
import { BuildingColumnsIcon, HandsHoldingChildIcon, MapMarkerAltIcon } from 'components/icons';

export const getJobsiteInfoSectionInputs = ({
  developersOptions,
  jobsitesOptions,
  formsOptions,
  isTemplate,
}: {
  developersOptions: SelectOptionElement[];
  jobsitesOptions: SelectOptionElement[];
  formsOptions: SelectOptionElement[];
  isTemplate: boolean;
}): TypedFormInputs<EditJobsiteConfigurationFormData['jobsite']> => ({
  name: {
    element: FormInputTypes.Field,
    label: 'Name',
    validation: { required: true },
    layout: [GridColSpan.SpanFull, isTemplate && GridColSpan.SmSpan2],
  },
  developerId: {
    element: FormInputTypes.Select,
    label: 'Client',
    elementProps: {
      options: developersOptions,
      icon: BuildingColumnsIcon,
    },
    validation: { required: true },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
  },
  parentId: {
    element: FormInputTypes.Select,
    label: 'Parent Jobsite',
    elementProps: {
      options: jobsitesOptions,
      icon: HandsHoldingChildIcon,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
  },
  addressLine1: {
    element: FormInputTypes.PlacesAutocomplete,
    label: 'Address',
    elementProps: {
      placeholder: 'Address',
      icon: MapMarkerAltIcon,
      onCommit: fillJobsiteAddressDetails,
    },
    validation: { required: !isTemplate },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
  },
  addressLine2: {
    element: FormInputTypes.Field,
    label: 'Address, Line 2',
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
  },
  addressCity: {
    element: FormInputTypes.Field,
    label: 'City',
    validation: { required: !isTemplate },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
  },
  addressState: {
    element: FormInputTypes.Select,
    label: 'State',
    elementProps: {
      options: statesOptions,
    },
    validation: { required: !isTemplate },
    layout: [GridColSpan.Span2, GridColSpan.SmSpan1],
  },
  addressZipCode: {
    element: FormInputTypes.Field,
    label: 'ZIP Code',
    layout: [GridColSpan.Span2, GridColSpan.SmSpan1],
    elementProps: {
      fieldType: 'zipcode',
    },
    validation: {
      required: !isTemplate,
      pattern: zipCodeValidation,
    },
  },
  timeZone: {
    element: FormInputTypes.Select,
    label: 'Timezone',
    elementProps: {
      options: timeZonesOptions,
    },
    validation: { required: !isTemplate },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
  },
  latitude: {
    element: FormInputTypes.Field,
    label: 'Latitude',
    elementProps: { fieldType: 'gpsCoordinate' },
    validation: { required: !isTemplate },
    layout: [GridColSpan.Span2, GridColSpan.SmSpan1],
  },
  longitude: {
    element: FormInputTypes.Field,
    label: 'Longitude',
    elementProps: { fieldType: 'gpsCoordinate' },
    validation: { required: !isTemplate },
    layout: [GridColSpan.Span2, GridColSpan.SmSpan1],
  },
  startDate: {
    element: FormInputTypes.DatePicker,
    label: 'Start Date',
    elementProps: {
      showDefaultIcon: true,
      numberOfMonths: 1,
    },
    validation: { required: !isTemplate },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
  },
  endDate: {
    element: FormInputTypes.DatePicker,
    label: 'End Date',
    elementProps: {
      showDefaultIcon: true,
      numberOfMonths: 1,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
  },
  forms: {
    element: FormInputTypes.Select,
    label: 'Custom Forms',
    layout: [GridColSpan.SpanFull],
    elementProps: {
      isMulti: true,
      clearToNull: true,
      options: formsOptions ?? [],
    },
  },
});

export const getJobsiteInfoSectionDefaultValues = (
  jobsite: Jobsite,
  developersOptions: SelectOptionElement[],
  jobsitesOptions: SelectOptionElement[],
  formsOptions: SelectOptionElement[],
): EditJobsiteConfigurationFormData['jobsite'] => {
  const {
    name,
    developer,
    parentId,
    addressLine1,
    addressLine2,
    addressCity,
    addressState,
    addressZipCode,
    timeZone,
    latitude,
    longitude,
    startDate,
    endDate,
    jobsiteForms,
  } = jobsite ?? {};
  const { developerId } = developer ?? {};

  return {
    ...stringifyEmptyFields({
      name,
      addressLine1,
      addressLine2,
      addressCity,
      addressZipCode,
    }),
    latitude: latitude?.toString() ?? '',
    longitude: longitude?.toString() ?? '',
    ...nullifyEmptyFields({
      startDate: startDate && moment.utc(startDate),
      endDate: endDate && moment.utc(endDate),
      addressState: statesOptions.find((opt) => opt.value === addressState),
      developerId: developersOptions?.find((opt) => opt.value === developerId),
      parentId: jobsitesOptions?.find((opt) => opt.value === parentId),
      timeZone: timeZonesOptions.find((opt) => opt.value === timeZone),
      forms: formsOptions?.filter((opt) => jobsiteForms?.edges.some(({ node }) => node.form.id === opt.value)),
    }),
  };
};

type JobsiteInfoSectionUpdateInput = Required<
  Pick<
    JobsiteUpdateInput,
    | 'name'
    | 'developerId'
    | 'parentId'
    | 'addressLine1'
    | 'addressLine2'
    | 'addressCity'
    | 'addressState'
    | 'addressZipCode'
    | 'timeZone'
    | 'latitude'
    | 'longitude'
    | 'startDate'
    | 'endDate'
    | 'formIds'
  >
>;

export const getJobsiteInfoSectionUpdateInput = (
  jobsiteInfo: EditJobsiteConfigurationFormData['jobsite'],
  dirtyFields: DeepMap<EditJobsiteConfigurationFormData['jobsite'], true>,
): JobsiteInfoSectionUpdateInput => {
  const getUpdateInputValue = getUpdateInputValueFunction(jobsiteInfo, dirtyFields);
  const getUpdateInputValueAsNumber = (
    field: keyof Pick<EditJobsiteConfigurationFormData['jobsite'], 'latitude' | 'longitude'>,
  ): number => {
    const value = getUpdateInputValue(field);
    if (value === undefined) return undefined;
    if (value === null || value === '') return null;
    return Number(value);
  };

  return {
    name: getUpdateInputValue('name'),
    developerId: getUpdateInputValue('developerId'),
    parentId: getUpdateInputValue('parentId'),
    addressLine1: getUpdateInputValue('addressLine1'),
    addressLine2: getUpdateInputValue('addressLine2'),
    addressCity: getUpdateInputValue('addressCity'),
    addressState: getUpdateInputValue('addressState'),
    addressZipCode: getUpdateInputValue('addressZipCode'),
    timeZone: getUpdateInputValue('timeZone'),
    latitude: getUpdateInputValueAsNumber('latitude'),
    longitude: getUpdateInputValueAsNumber('longitude'),
    startDate: getUpdateInputValue('startDate'),
    endDate: getUpdateInputValue('endDate'),
    formIds: getUpdateInputValue('forms'),
  };
};
