import { useMap } from '@vis.gl/react-google-maps';
import React, { useEffect, useState } from 'react';
import { ElasticFacetEntry } from 'apollo/generated/client-operations';
import { ensureNonEmptyItems } from 'utils';
import { odinChartColors } from 'containers/dashboard/types';
import { getBucketRanges, inBucketRange } from './utils';

export function ZipcodesOverlay(props: { zipCodeBreakdowns: ElasticFacetEntry[] }): React.ReactElement {
  const map = useMap();
  const { zipCodeBreakdowns } = props;
  const [featureLayer, setFeatureLayer] = useState<google.maps.FeatureLayer>(null);
  const [bucketRanges, setBucketRanges] = useState<number[][]>([[]]);
  const hoverWindowRef = React.useRef<google.maps.InfoWindow>();
  const lastInteractedFeatureIdsRef = React.useRef<string[]>([]);
  const lastClickedFeatureIds = React.useRef<string[]>([]);

  function applyStyle(
    featureStyleFunctionOptions: google.maps.FeatureStyleFunctionOptions,
  ): google.maps.FeatureStyleOptions {
    if (!zipCodeBreakdowns) return null;
    const placeFeature = featureStyleFunctionOptions.feature as google.maps.PlaceFeature;
    const { placeId } = placeFeature;
    // const actualZipcodes = zipCodeBreakdowns?.filter((e) => !e.name.startsWith('{"west') && !(e.name === 'Unknown'));
    // filter for zipcodes with a percentage >= 0 - the rest are not valid zipcodes but rather manufactured entries
    const actualZipcodes = zipCodeBreakdowns?.filter((e) => e.percentage >= 0);
    const matchingEntry = actualZipcodes?.find((pb) => {
      const [, zipcodePlaceId] = pb.name.split(':');
      return zipcodePlaceId === placeId;
    });
    if (!matchingEntry) return null;
    const { count } = matchingEntry;

    const bucketIndex = bucketRanges.findIndex((br) => inBucketRange(count, br)) ?? 0;
    const matchingColor = odinChartColors[bucketIndex] ?? odinChartColors[0];
    const { background, border } = matchingColor;

    return {
      fillColor: background,
      fillOpacity: 0.4,
      strokeColor: lastInteractedFeatureIdsRef.current?.includes(placeId) ? background : border,
      strokeOpacity: 1.0,
      strokeWeight: 0.75,
      ...(lastInteractedFeatureIdsRef.current?.includes(placeId) ? { strokeWeight: 3.0 } : {}),
      ...(lastClickedFeatureIds.current?.includes(placeId) ? { fillOpacity: 0.8 } : {}),
    };
  }

  function updateInfoWindow(header: string, content: string | Element, center: google.maps.LatLng): void {
    hoverWindowRef.current?.setHeaderContent(header);
    hoverWindowRef.current?.setContent(`${content}`);
    hoverWindowRef.current?.setPosition(center);
    hoverWindowRef.current?.open({
      map,
      shouldFocus: false,
    });
  }

  async function createInfoWindow(event: google.maps.FeatureMouseEvent): Promise<void> {
    const feature = event?.features?.[0] as google.maps.PlaceFeature;
    if (!feature?.placeId) return;
    const entry = zipCodeBreakdowns?.find((pb) => {
      const [, zipcodePlaceId] = pb.name.split(':');
      return zipcodePlaceId === feature.placeId;
    });

    if (entry) {
      const { count, percentage: hijackedLabel, name } = entry;
      const [label] = name?.split(':') || [];
      updateInfoWindow(hijackedLabel?.toString() ?? label, count?.toString(), event.latLng);
    }
  }

  function handleFeatureClick(e: google.maps.FeatureMouseEvent): void {
    lastClickedFeatureIds.current = ensureNonEmptyItems(
      e.features.map((f) => (f as google.maps.PlaceFeature)?.placeId),
    );
    lastInteractedFeatureIdsRef.current = [];
    if (featureLayer) featureLayer.style = applyStyle;
    if (lastClickedFeatureIds.current?.length) {
      createInfoWindow(e);
    }
  }

  function handleFeatureMouseMove(e: google.maps.FeatureMouseEvent): void {
    lastInteractedFeatureIdsRef.current = ensureNonEmptyItems(
      e.features.map((f) => (f as google.maps.PlaceFeature)?.placeId),
    );
    if (featureLayer) featureLayer.style = applyStyle;
    if (lastInteractedFeatureIdsRef.current?.length) {
      createInfoWindow(e);
    }
  }

  function handleMapMouseMove(): void {
    if (lastInteractedFeatureIdsRef.current?.length) {
      lastInteractedFeatureIdsRef.current = [];
      if (featureLayer) featureLayer.style = applyStyle;
      hoverWindowRef.current?.close();
    }
  }

  function handleMapMouseClick(): void {
    if (lastClickedFeatureIds.current?.length) {
      lastClickedFeatureIds.current = [];
      if (featureLayer) featureLayer.style = applyStyle;
      hoverWindowRef.current?.close();
    }
  }

  useEffect(() => {
    if (map && zipCodeBreakdowns) {
      setFeatureLayer(map.getFeatureLayer(google.maps.FeatureType.POSTAL_CODE));
      const boundariesEntry = zipCodeBreakdowns.find((pb) => pb.name.startsWith('{'));
      if (boundariesEntry?.name?.startsWith('{')) {
        const boundaries = JSON.parse(boundariesEntry.name);
        if (boundaries) {
          setTimeout(() => {
            map.fitBounds(boundaries);
          }, 50);
        }
      }
      // TODO: add a legend for at least the colors - possibly allow it to be interactive to help identify spots on map
      // const legend = document.getElementById('mapLegend');
      // if (legend) {
      //   legend.childNodes.forEach((c) => c.remove());
      //   const newDiv = document.createElement('div');
      //   newDiv.innerHTML = `here goes the legend..`;
      //   legend.appendChild(newDiv);
      //   map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(legend);
      // }
      hoverWindowRef.current?.close();
      hoverWindowRef.current = new google.maps.InfoWindow({ headerDisabled: false });
    }
    const moveListener = map?.addListener('mousemove', handleMapMouseMove);
    const clickListener = map?.addListener('click', handleMapMouseClick);
    return (): void => {
      if (clickListener) google.maps.event.removeListener(clickListener);
      if (moveListener) google.maps.event.removeListener(moveListener);
    };
  }, [map, zipCodeBreakdowns]);

  useEffect(() => {
    const clickListener = featureLayer?.addListener('click', handleFeatureClick);
    const moveListener = featureLayer?.addListener('mousemove', handleFeatureMouseMove);
    // const zipCodes = zipCodeBreakdowns?.filter((pb) => !pb.name.startsWith('{"west') && pb.name !== 'Unknown');
    const zipCodes = zipCodeBreakdowns?.filter((pb) => pb.percentage >= 0);
    if (zipCodes?.length) {
      setBucketRanges(
        getBucketRanges(
          zipCodes.map((z) => z.count),
          3,
        ),
      );
    }
    if (featureLayer) featureLayer.style = applyStyle;
    return (): void => {
      if (clickListener) google.maps.event.removeListener(clickListener);
      if (moveListener) google.maps.event.removeListener(moveListener);
    };
  }, [featureLayer, zipCodeBreakdowns]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
}
