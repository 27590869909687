import { DropdownFilterOption, FilterItems } from '@odin-labs/components';
import { GetJobsiteWorkersOrderByField } from 'apollo/generated/client-operations';
import { PageSearchParams, ensureDistinctItems, ensureNonEmptyItems } from 'utils';
import { getSelectOptions } from 'components/utils';

export type WorkersFilters = Pick<PageSearchParams, 'currentlyOnsite' | 'trades' | 'tradeClasses' | 'search'>;

export type WorkerFiltersOptions = {
  trades: DropdownFilterOption[];
  tradeClasses: DropdownFilterOption[];
};

export const getFiltersOptions = (values: string[]): DropdownFilterOption[] =>
  getSelectOptions({
    values: ensureDistinctItems(ensureNonEmptyItems(values ?? [])),
  });

export const getFilterItems = ({
  filtersOptions,
  currentlyOnsite,
  trades,
  tradeClasses,
  search,
}: {
  filtersOptions: WorkerFiltersOptions;
  currentlyOnsite: string;
  trades: string[];
  tradeClasses: string[];
  search: string;
}): FilterItems<WorkersFilters> => ({
  currentlyOnsite: {
    header: 'On site',
    type: 'checkbox',
    defaultValue: currentlyOnsite,
  },
  trades: {
    header: 'Trade',
    type: 'dropdown',
    defaultValue: trades,
    componentProps: { options: filtersOptions.trades },
  },
  tradeClasses: {
    header: 'Title',
    type: 'dropdown',
    defaultValue: tradeClasses,
    componentProps: { options: filtersOptions.tradeClasses },
  },
  search: {
    header: '',
    type: 'searchInput',
    defaultValue: search,
    isVisibleOutsideFilterOnMobile: true,
  },
});

export const orderByFields: Record<string, GetJobsiteWorkersOrderByField> = {
  name: GetJobsiteWorkersOrderByField.Name,
  jobsiteName: GetJobsiteWorkersOrderByField.JobsiteName,
  birthDate: GetJobsiteWorkersOrderByField.BirthDate,
  trade: GetJobsiteWorkersOrderByField.Trade,
  contractor: GetJobsiteWorkersOrderByField.Contractor,
  orientationDate: GetJobsiteWorkersOrderByField.OrientationDate,
  orientationStatus: GetJobsiteWorkersOrderByField.OrientationStatus,
  siteSpecificStatus: GetJobsiteWorkersOrderByField.SiteSpecificStatus,
};
