import { SelectOptionElement } from '@odin-labs/components';
import { UserAssignmentType, UserRole, UserRoleKey } from 'apollo/generated/client-operations';
import { adminRoles } from 'acl';
import { ensureNonEmptyItems } from 'utils';
import { isContractorMemberRoleKey } from 'utils/contractorMemberUser';
import { JobsiteForOption, UserRoleType, WorkerUserRole } from './types';

export type { JobsiteForOption };
export const getJobsitesOptions = (jobsites: JobsiteForOption[]): SelectOptionElement[] =>
  jobsites
    ?.map(({ jobsiteId: value, name: label }) => ({ value, label }))
    .sort((a, b) => a.label.localeCompare(b.label));

export const getUserRoleType = (userRoleKey: UserRoleKey): UserRoleType | undefined => {
  if (userRoleKey) {
    if (isContractorMemberRoleKey(userRoleKey)) {
      return UserAssignmentType.Contractor;
    }
    if (userRoleKey === UserRoleKey.ClientAdmin) {
      return UserAssignmentType.Developer;
    }
    return UserAssignmentType.Jobsite;
  }
  return undefined;
};

/**
 * Get available user role types based on the existing user roles and the user's admin status.
 * @param workerUserRoles Existing user roles.
 * @param isAdminUser
 */
export const getUserRoleTypes = (
  workerUserRoles: WorkerUserRole[],
  isAdminUser: boolean,
): UserRoleType[] | undefined => {
  if (!workerUserRoles) return undefined;

  // available user role types are calculated based on the first of the user's existing roles
  const [firstRole] = workerUserRoles ?? [];
  const userRoleKey = firstRole?.key;

  if (userRoleKey) {
    if (isContractorMemberRoleKey(userRoleKey)) {
      return [UserAssignmentType.Contractor];
    }
    return ensureNonEmptyItems([UserAssignmentType.Jobsite, isAdminUser && UserAssignmentType.Developer]);
  }
  // if there is no user role key, all user role types are available based on the user's admin status
  return ensureNonEmptyItems([
    UserAssignmentType.Jobsite,
    UserAssignmentType.Contractor,
    isAdminUser && UserAssignmentType.Developer,
  ]);
};

export type UserRoleForOption = Pick<UserRole, 'displayName' | 'key'>;
export const getUserRolesOptions = ({
  userRoles,
  userRoleTypes,
  showAdminRoles,
}: {
  userRoles: UserRoleForOption[];
  userRoleTypes?: UserRoleType[];
  showAdminRoles?: boolean;
}): SelectOptionElement<UserRoleKey>[] => {
  const filteredUserRoles = (
    userRoleTypes ? userRoles?.filter(({ key }) => userRoleTypes.includes(getUserRoleType(key))) : userRoles
  )?.filter(({ key }) => showAdminRoles || !adminRoles.includes(key));
  return filteredUserRoles?.map(({ key: value, displayName: label }) => ({ value, label }));
};
